/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppLanguage, AppRoute } from '../../../const';
import { appStrings } from '../../../modules/i18n';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

import { FilteringQueryParams } from '../../../const/filtering-query-params';
import useCreateCourseStore from '../../../hooks/state-management/useCreateCourseStore';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import useCreateCourse from '../../../hooks/course/useCreateCourse';
import { components } from '../../../types/openapi/CourseService';
import useProviderSchools from '../../../hooks/schools/useProviderSchools';
import { isCentreAdmin } from '../../../utils/permissions';
import SchoolsTable from '../../schools/SchoolsTable';

function Step4() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const navigate = useNavigate();

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filtering, setFiltering] = useState<ColumnFiltersState>([]);
  const queryParamsLocation = FilteringQueryParams.Schools;

  const {
    name,
    description,
    frameworkVersionId,
    stageIds,
    totalNumberOfPeople,
    instructor,
    centre,
    school,
    facilityId,
    setSchool,
    reset
  } = useCreateCourseStore();

  const schoolId = school?.schoolId;

  const schoolsQuery = useProviderSchools({
    providerId: profile?.providerId,
    centreId: centre?.centreId,
    isCentreAdmin: !!profile && isCentreAdmin(profile),
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  const { createNewCourse } = useCreateCourse({
    displayLocale
  });

  const saveStep4 = () => {
    if (
      profile?.providerId &&
      instructor?.userId &&
      centre?.centreId &&
      frameworkVersionId &&
      !!stageIds
    ) {
      const course = {
        name,
        description,
        providerId: profile.providerId,
        frameworkVersionId,
        courseStages: stageIds,
        instructorId: instructor.userId,
        totalNumberOfPeople,
        centreId: centre.centreId,
        schoolId: school?.schoolId,
        facilityId
      };

      createNewCourse.mutate(course as components['schemas']['CourseDto'], {
        onSuccess: async (course) => {
          reset();
          navigate(
            `/${displayLocale?.toLowerCase()}/${
              appStrings[displayLocale as AppLanguage][AppRoute.Courses]
            }/${
              appStrings[displayLocale as AppLanguage][AppRoute.CreateCourse]
            }/${
              appStrings[displayLocale as AppLanguage][AppRoute.Success]
            }?course=${course.courseId}&provider=${course.providerId}`
          );
        }
      });
    }
  };

  return (
    <div>
      <h2>
        <FormattedMessage id="step4" defaultMessage="Step 4" />
      </h2>
      <p>
        <FormattedMessage
          id="course.create.step4.instructions"
          defaultMessage="Optional - select a school for this course."
        />
      </p>
      <SchoolsTable
        data={schoolsQuery.data}
        error={schoolsQuery.error}
        isLoading={schoolsQuery.isLoading}
        refetching={schoolsQuery.isRefetching}
        pagination={pagination}
        sorting={sorting}
        filtering={filtering}
        setPagination={setPagination}
        setSorting={setSorting}
        setFiltering={setFiltering}
        queryParamsLocation={queryParamsLocation}
        renderLink={({ school }) =>
          schoolId === school.schoolId ? (
            <span>
              <FormattedMessage id="assigned" defaultMessage="Assigned" />
              <FontAwesomeIcon icon={faCheck} className="text-success ms-2" />
            </span>
          ) : (
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => setSchool(school)}
            >
              <FormattedMessage id="assign" defaultMessage="Assign" />
            </button>
          )
        }
      />

      <div className="d-flex gap-2 mt-3">
        <Link
          className="btn btn-outline-secondary"
          to={`/${displayLocale?.toLowerCase()}/${
            appStrings[displayLocale as AppLanguage][AppRoute.Courses]
          }/${
            appStrings[displayLocale as AppLanguage][AppRoute.CreateCourse]
          }/${appStrings[displayLocale as AppLanguage][AppRoute.Step3]}`}
        >
          <FormattedMessage id="back" defaultMessage="Back" />
        </Link>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveStep4()}
        >
          {/* <FormattedMessage id="next" defaultMessage="Next" /> */}
          <FormattedMessage id="finish" defaultMessage="Finish" />
        </button>
      </div>
    </div>
  );
}

export default Step4;
