import { useQuery } from '@tanstack/react-query';
import stageKeys from '../query-keys/stage-key-factory';
import { getStages } from '../services/api/framework.service';

// hook for stages
const useStages = ({
  frameworkId,
  frameworkVersionId,
  displayLocale
}: {
  frameworkId: number | undefined | null;
  frameworkVersionId: number | undefined;
  displayLocale: string | undefined;
}) => {
  // query is only enabled if frameworkId and frameworkVersionId exist
  const stagesQuery = useQuery({
    queryKey: stageKeys.list(
      frameworkId || 0,
      frameworkVersionId || 0,
      displayLocale
    ),
    queryFn: () => getStages(frameworkId, frameworkVersionId, displayLocale),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!frameworkId && !!frameworkVersionId
  });

  const stagesCount = stagesQuery.data?.length || 0;

  return {
    stagesQuery,
    stagesCount
  };
};

export default useStages;
