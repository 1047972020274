/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import useUserCourseSessions from '../../hooks/useUserCourseSessions';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../common';
import Loading from '../common/Loading';
import AttendancePercentageNumber from './AttendancePercentageNumber';

function AttendancePercentage({
  courseId,
  learnerId
}: {
  courseId: number;
  learnerId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);

  const { profile } = profileContext;

  if (!profile?.userId || !displayLocale) {
    return <GeneralError />;
  }

  const {
    userCourseSessionsQuery,
    attendancePercentage,
    totalNumberOfCompletedSessions
  } = useUserCourseSessions({
    userId: learnerId,
    courseId,
    displayLocale
  });

  return (
    <>
      {userCourseSessionsQuery.isFetching ? (
        <Loading />
      ) : userCourseSessionsQuery.error ? (
        <GeneralError
          message={(userCourseSessionsQuery.error as any).response?.data}
        />
      ) : userCourseSessionsQuery.data ? (
        totalNumberOfCompletedSessions === 0 ? (
          <FormattedMessage
            id="completed_sessions.none"
            defaultMessage="Attendance information will be available when the course starts."
            description="Attendance information will be available when the course starts"
          />
        ) : (
          <AttendancePercentageNumber percentage={attendancePercentage} />
        )
      ) : (
        <GeneralError
          message={
            <FormattedMessage
              id="sessions.none_found"
              defaultMessage="No sessions found"
              description="No Sessions Found"
            />
          }
        />
      )}
    </>
  );
}

export default AttendancePercentage;
