import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import useSession from '../../../../hooks/useSession';
import { components } from '../../../../types/openapi/CourseService';
import useAddLearnerToSession from '../../../../hooks/session/useAddLearnerToSession';

function AddLearnerToSessionBtn({
  courseId,
  sessionId,
  displayLocale,
  learner
}: {
  courseId: number;
  sessionId: number;
  displayLocale: string | undefined;
  learner: components['schemas']['UserDto'];
}) {
  const intl = useIntl();

  const { sessionMembersQuery } = useSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    displayLocale
  });
  const { bookUserOnToCourseSessionMutation } = useAddLearnerToSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    displayLocale
  });

  const handleAddLearnerToCourseSession = (
    selectedLearner: components['schemas']['UserDto']
  ) => {
    bookUserOnToCourseSessionMutation.mutate(selectedLearner, {
      onSuccess: () => {
        const toastId = 'successToast';
        toast.success(
          intl.formatMessage({
            id: 'session.learner.booking.success',
            defaultMessage:
              'The selected learners have successfully been booked onto the session'
          }),
          { delay: 200, toastId }
        );
      },
      onError: (error) => {
        const errorToastId = 'errorToast';
        console.error(error);
        toast.error(
          intl.formatMessage({
            id: 'session.learner.booking.error',
            defaultMessage:
              'There was an error booking this learner onto this session'
          }),
          { delay: 200, toastId: errorToastId }
        );
      }
    });
  };
  return (
    <button
      type="button"
      className={`btn btn-outline-secondary ${
        sessionMembersQuery.data &&
        sessionMembersQuery.data.find(
          (previouslyAddedLearner: components['schemas']['UserDto']) =>
            previouslyAddedLearner.userId === learner.userId
        ) &&
        'disabled'
      }`}
      disabled={bookUserOnToCourseSessionMutation.isLoading}
      onClick={() => handleAddLearnerToCourseSession(learner)}
    >
      <FormattedMessage id="add" defaultMessage="Add" description="Add" />
    </button>
  );
}

export default AddLearnerToSessionBtn;
