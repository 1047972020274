import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { AppRoute } from '../../const';
import localiseRoutePath from '../../utils/localiseRoutePath';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function ViewCentreViewLink({ courseId }: { courseId: number }) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  return (
    <Link
      to={`/${displayLocale?.toLowerCase()}/${localiseRoutePath(
        AppRoute.Courses
      )}/${courseId}`}
      className="btn btn-outline-secondary"
    >
      <FormattedMessage id="view" defaultMessage="View" description="View" />
    </Link>
  );
}

export default ViewCentreViewLink;
