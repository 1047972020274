/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { components } from '../../types/openapi/CourseService';
import { components as providerComponents } from '../../types/openapi/ProviderService';
import FrameworkSelect from './form/FrameworkSelect';
import StageSelect from './form/StageSelect';
import Asterisk from '../Asterisk';
import StageSelectWrapper from './form/StageSelectWrapper';
import InlineFormError from '../ui/InlineFormError';

function CourseForm({
  onSubmit,
  submitButtonMessage,
  name,
  description,
  allProviderFrameworks,
  externalCourseId,
  frameworkVersionId,
  stageIds,
  providerId,
  instructorId,
  totalNumberOfPeople,
  isEdit,
  formDisabled,
  helpText
}: {
  onSubmit: (
    value:
      | Pick<
          components['schemas']['CourseDto'],
          'name' | 'description' | 'totalNumberOfPeople'
        >
      | Pick<
          components['schemas']['CourseDto'],
          | 'name'
          | 'description'
          | 'totalNumberOfPeople'
          | 'centreId'
          | 'frameworkVersionId'
          | 'courseStages'
          | 'instructorId'
          | 'facilityId'
          | 'externalCourseId'
        >
  ) => void;
  submitButtonMessage: any;
  name?: string | null;
  description?: string | null;
  allProviderFrameworks?: components['schemas']['FrameworkDto'][];
  allProviderCentres?: providerComponents['schemas']['CentreDto'][];
  externalCourseId?: string | undefined;
  frameworkVersionId?: number;
  stageIds?: { stageId: number }[];
  providerId?: number;
  instructorId?: number;
  totalNumberOfPeople?: number;
  formDisabled?: boolean;
  isEdit?: boolean;
  helpText?: {
    name: string | undefined;
    description: string | undefined;
  };
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: name || '',
      description: description || '',
      frameworkVersionId: frameworkVersionId || null,
      stageIds: stageIds || null,
      centreId: null,
      facilityId: null,
      providerId: providerId || null,
      instructorId,
      totalNumberOfPeople,
      externalCourseId
    }
  });

  // const userProviderId = profile?.providerId;
  const selectedCentreId = watch('centreId');
  const selectedFrameworkVersionId = watch('frameworkVersionId');
  const frameworkId = allProviderFrameworks?.find(
    (framework) =>
      framework.frameworkVersion?.frameworkVersionId ===
      selectedFrameworkVersionId
  )?.frameworkId;

  useEffect(() => {
    if (selectedCentreId) {
      resetField('instructorId');
      resetField('facilityId');
    }
  }, [selectedCentreId]);

  useEffect(() => {
    if (selectedFrameworkVersionId) {
      resetField('stageIds');
    }
  }, [selectedFrameworkVersionId]);

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label w-100">
          <Asterisk />
          <FormattedMessage
            id="form.name"
            defaultMessage="Name"
            description="Name"
          />{' '}
        </label>
        <input
          id="name"
          type="text"
          className="form-control"
          autoComplete="off"
          aria-invalid={errors.name ? 'true' : 'false'}
          {...register('name', { required: true })}
        />

        {errors.name?.type === 'required' && (
          <InlineFormError
            message={
              <FormattedMessage
                id="form.name.required"
                defaultMessage="Name is required"
                description="Name is required"
              />
            }
          />
        )}
        {helpText && helpText.name && (
          <div id="nameHelpBlock" className="form-text">
            {helpText.name}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          <Asterisk />
          <FormattedMessage
            id="form.description"
            defaultMessage="Description"
            description="Description"
          />{' '}
        </label>
        <textarea
          id="description"
          className="form-control"
          aria-invalid={errors.description ? 'true' : 'false'}
          {...register('description', { required: true })}
        />
        {errors.description?.type === 'required' && (
          <InlineFormError
            message={
              <FormattedMessage
                id="form.description.required"
                defaultMessage="Description is required"
                description="Description is required"
              />
            }
          />
        )}
        {helpText && helpText.description && (
          <div id="descriptionHelpBlock" className="form-text">
            {helpText.description}
          </div>
        )}
      </div>
      {!isEdit && (
        <>
          {allProviderFrameworks ? (
            <FrameworkSelect
              control={control}
              frameworks={allProviderFrameworks}
              frameworkVersionId={frameworkVersionId}
              errors={errors}
            />
          ) : (
            <FormattedMessage
              id="error.no_frameworks_available"
              defaultMessage="No Frameworks available to select. Please contact your provider"
              description="No Frameworks available to select. Please contact your provider"
            />
          )}
          {frameworkId && selectedFrameworkVersionId ? (
            <StageSelectWrapper
              frameworkId={frameworkId}
              frameworkVersionId={selectedFrameworkVersionId}
              renderControl={(stages: components['schemas']['StageDto'][]) => {
                return (
                  <StageSelect
                    control={control}
                    frameworkVersionId={selectedFrameworkVersionId}
                    stages={stages}
                    stageIds={stageIds}
                    errors={errors}
                  />
                );
              }}
            />
          ) : (
            <StageSelect
              control={control}
              frameworkVersionId={Number(selectedFrameworkVersionId)}
              stages={[]}
              stageIds={stageIds}
              errors={errors}
            />
          )}
        </>
      )}

      <div className="mb-3">
        <label htmlFor="totalNumberOfPeople" className="form-label">
          <Asterisk />
          <FormattedMessage
            id="capacity"
            defaultMessage="Capacity"
            description="Capacity"
          />
        </label>
        <input
          id="totalNumberOfPeople"
          type="number"
          className="form-control w-auto"
          aria-invalid={errors.totalNumberOfPeople ? 'true' : 'false'}
          {...register('totalNumberOfPeople', { required: true, min: 0 })}
        />

        {errors.totalNumberOfPeople?.type === 'required' && (
          <InlineFormError
            message={
              <FormattedMessage
                id="form.capacity.required"
                defaultMessage="Capacity is required"
                description="Capacity is required"
              />
            }
          />
        )}
        {errors.totalNumberOfPeople?.type === 'min' && (
          <InlineFormError
            message={
              <FormattedMessage
                id="form.capacity.min"
                defaultMessage="Capacity can't be less than zero"
                description="Capacity can't be less than zero"
              />
            }
          />
        )}
      </div>
      <button
        type="submit"
        className="btn btn-primary align-self-start"
        disabled={formDisabled}
      >
        {submitButtonMessage}
      </button>
    </form>
  );
}

export default CourseForm;

CourseForm.defaultProps = {
  name: '',
  description: '',
  externalCourseId: '',
  frameworkVersionId: null,
  stageIds: [],
  providerId: null,
  instructorId: null,
  totalNumberOfPeople: null,
  formDisabled: false,
  allProviderFrameworks: [],
  allProviderCentres: [],
  isEdit: false,
  helpText: {
    name: '',
    description: ''
  }
};
