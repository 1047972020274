import { useQuery } from '@tanstack/react-query';
import resourceKeys from '../query-keys/resource-key-factory';
import { getResources } from '../services/api/resource.service';

// hook for managing resources
const useResources = ({
  frameworkVersionId,
  displayLocale
}: {
  frameworkVersionId: number | undefined;
  displayLocale: string | undefined;
}) => {
  if (!frameworkVersionId) {
    throw Error('invalid framework version id');
  }

  const resourcesQuery = useQuery({
    queryKey: resourceKeys.resources(frameworkVersionId, displayLocale),
    queryFn: () => getResources(Number(frameworkVersionId)),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!frameworkVersionId
  });

  return {
    resourcesQuery
  };
};

export default useResources;
