const isErrorBothTokensExpired = (error: any) => {
  const msg = error.response?.data?.debugMessage;

  const bothExpired =
    typeof msg === 'string'
      ? msg?.includes(
          'Both tokens have expired (Refresh Token Expired needed to renew)'
        )
      : false;

  return bothExpired;
};

export default isErrorBothTokensExpired;
