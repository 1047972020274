import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { Helmet } from 'react-helmet-async';

import useGroup from '../../hooks/useGroup';
import useLearners from '../../hooks/useLearners';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';

import LearnersList from '../learners-admin/LearnersList';
import Loading from '../common/Loading';
import AddGroupLearnerBtn from './AddGroupLearnerBtn';
import { FilteringQueryParams } from '../../const/filtering-query-params';

function AddLearnerToGroup() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const { groupId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  const queryParamsLocation = FilteringQueryParams.GroupLearners;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  const { learnersListQuery } = useLearners({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  if (!profile?.providerId) {
    return <Loading />;
  }
  const { groupQuery } = useGroup({
    providerId: profile?.providerId,
    groupId: Number(groupId),
    displayLocale
  });

  return (
    <div className="card">
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.learners.group.add',
              defaultMessage: 'Add learners to {group}'
            },
            {
              group: groupQuery.data?.name
            }
          )}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="learners.group.add"
            defaultMessage="Add Learners to {group}"
            description="Add Learners to {name}"
            values={{
              group: groupQuery.data?.name
            }}
          />
        </h1>
      </div>
      <div className="card-body">
        <LearnersList
          data={learnersListQuery.data}
          error={learnersListQuery.error}
          isLoading={learnersListQuery.isLoading}
          refetching={learnersListQuery.isRefetching}
          pagination={pagination}
          sorting={sorting}
          filtering={filtering}
          setPagination={setPagination}
          setSorting={setSorting}
          setFiltering={setFiltering}
          queryParamsLocation={queryParamsLocation}
          renderActions={({ row }) => {
            return (
              <AddGroupLearnerBtn
                providerId={profile?.providerId!}
                groupId={Number(groupId)}
                displayLocale={displayLocale}
                learner={row.original}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
export default AddLearnerToGroup;
