import React, { Suspense, useContext } from 'react';

import { Outlet } from 'react-router-dom';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import Loading from '../common/Loading';

function MainContainer() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  return (
    <div className="container-fluid">
      <div className="row gap-2 gap-md-0">
        <div className="col-12">
          {profile && <Breadcrumbs />}
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default MainContainer;
