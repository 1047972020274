import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import './styles/main.scss';

import IntlWrapper from './modules/i18n/components/IntlWrapper';
import AuthProvider from './modules/auth/AuthProvider';
import ProfileProvider from './modules/profile/ProfileProvider';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import OfflineProvider from './modules/offline/OfflineProvider';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <IntlWrapper>
      <AuthProvider>
        <ProfileProvider>
          <OfflineProvider>
            <App />
          </OfflineProvider>
        </ProfileProvider>
      </AuthProvider>
    </IntlWrapper>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
