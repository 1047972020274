import React, { useContext, useMemo } from 'react';
import { Calendar, Views, Event } from 'react-big-calendar';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { cy } from '../../modules/i18n/calendar/cy';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import SessionAgenda from './SessionAgenda';
import { getSessionLinkUrl, getSessionBookingLinkUrl } from './helpers';
import dayjsLocalizerTimezone from '../../modules/calendar/dayjsLocalizerTimezone';

// set up project defaults for calendar
function CalendarAgendaViewOnly({ events }: { events: Event[] | undefined }) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const localizer = dayjsLocalizerTimezone(dayjs);
  const intl = useIntl();

  const lang: any = {
    en: null,
    'en-GB': {
      noEventsInRange: 'No Sessions scheduled today'
    },
    cy: {
      ...cy,
      showMore: (total: number) =>
        intl.formatMessage(
          {
            id: 'show_more',
            defaultMessage: '{total} more',
            description: '{total} more'
          },
          { total }
        )
    }
  };
  // React Big Calendar customisations

  const { components } = useMemo(
    () => ({
      components: {
        agenda: {
          event: (event: Event) => {
            const url = (event as any).event.bookable
              ? getSessionBookingLinkUrl(
                  (event as any).event,
                  profile,
                  displayLocale
                )
              : getSessionLinkUrl(
                  (event as any).event,
                  profile,
                  displayLocale,
                  true
                );
            return SessionAgenda({
              event,
              url
            });
          }
        }
      }
    }),
    [profile]
  );

  const { messages } = useMemo(() => {
    const newLocale = displayLocale || 'en';
    return {
      messages: lang[newLocale]
    };
  }, [displayLocale]);

  return (
    <Calendar
      components={components}
      className="flex-grow-1 mh-vh-70"
      culture={displayLocale}
      messages={messages}
      defaultView={Views.AGENDA}
      events={events}
      localizer={localizer}
      step={15}
      timeslots={4}
      popup
      toolbar={false}
    />
  );
}

export default CalendarAgendaViewOnly;
