/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { Column } from '@tanstack/react-table';
import FilterInput from './FilterInput';

function FilterInputWrapper({
  column,
  columnDef,
  filtering
}: {
  column: Column<any, unknown>;
  columnDef: any;
  filtering: any;
}) {
  const getFilterValue = (_filtering: string | any[]): string => {
    return _filtering
      ? typeof _filtering === 'string'
        ? _filtering
        : _filtering?.find((el: { id: string }) => el.id === column.id)?.value
      : '';
  };

  const initialFilterValue = getFilterValue(filtering);

  const [filterValue, setFilterValue] = useState(initialFilterValue);

  useEffect(() => {
    // filters have been updated externally
    if (filtering) {
      const newFilterValue = getFilterValue(filtering);
      setFilterValue(newFilterValue);
    }
  }, [filtering]);

  useEffect(() => {
    // internal filtervalue updated - side effect update the column filter value
    column.setFilterValue(filterValue);
  }, [filterValue]);

  const handleBasicInputChange = (value: string) => {
    if (value) {
      if (typeof value === 'string') {
        if (value.length >= 3) {
          setFilterValue(value);
        }
      } else {
        setFilterValue(value);
      }
    } else if (!value) {
      setFilterValue('');
    }
  };

  const handleSportPassportIdInputChange = (value: string) => {
    if (value) {
      if (value.length === 11) {
        const numbersFromId = value
          .split('')
          .filter((char) => char >= '0' && char <= '9')
          .join('');
        column.setFilterValue(numbersFromId);
      }
    } else if (!value) {
      column.setFilterValue('');
    }
  };

  const handleBasicNumericInputChange = (value: string) => {
    if (value) {
      column.setFilterValue(value);
    } else if (!value) {
      column.setFilterValue('');
    }
  };

  const handleDropdownInputChange = (selectedOption: any) => {
    column.setFilterValue(selectedOption.value);
  };

  return (
    <FilterInput
      column={column}
      columnDef={columnDef}
      filterValue={filterValue}
      initialFilterValue={initialFilterValue}
      handleBasicInputChange={handleBasicInputChange}
      handleSportPassportIdInputChange={handleSportPassportIdInputChange}
      handleBasicNumericInputChange={handleBasicNumericInputChange}
      handleDropdownInputChange={handleDropdownInputChange}
    />
  );
}

export default FilterInputWrapper;
