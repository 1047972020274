/* eslint-disable @typescript-eslint/no-shadow */
import React, { ReactNode, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import UserDetailsTable from '../users/UserGeneralDetailsTable';
import { components } from '../../types/openapi/UserService';
import { ProfileContext } from '../../modules/profile/ProfileProvider';

import useProviderConfig from '../../hooks/useProviderConfig';
import { AppRoute } from '../../const';
import localiseRoutePath from '../../utils/localiseRoutePath';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import GeneralError from '../common/GeneralError';

function AccountDetails({
  user,
  renderEmergencyContactDetails,
  renderSystemDetails
}: {
  user: components['schemas']['UserDto'];
  renderEmergencyContactDetails: () => JSX.Element | ReactNode;
  renderSystemDetails: () => JSX.Element | ReactNode;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  if (!profile) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'profile.error.please_select',
          defaultMessage: 'Please select a profile'
        })}
      />
    );
  }

  const { providerId } = profile;

  if (!providerId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'error.no_provider_id',
          defaultMessage: 'No valid provider id provided'
        })}
      />
    );
  }

  const { profileEditingEnabled } = useProviderConfig({
    providerId
  });
  return (
    <>
      <div className="row mb-3">
        <div className="col-12 col-lg-6">
          <div className="card mb-3 mb-lg-0">
            <div className="card-header d-flex">
              <div className="flex-grow-1">
                <h3>
                  <FormattedMessage
                    id="details.mine"
                    defaultMessage="My Details"
                    description="My Details"
                  />
                </h3>
              </div>
              {profileEditingEnabled && (
                <div>
                  <Link
                    to={`/${displayLocale}/${localiseRoutePath(
                      AppRoute.Profile
                    )}/${localiseRoutePath(AppRoute.EditProfile)}`}
                    className="btn btn-primary mx-auto"
                  >
                    <FormattedMessage
                      id="profile.edit.details"
                      defaultMessage="Edit Your Details"
                      description="Edit Your Details"
                    />
                  </Link>
                </div>
              )}
            </div>
            <div className="card-body">
              <UserDetailsTable user={user} />
            </div>
          </div>
        </div>

        {renderEmergencyContactDetails && renderEmergencyContactDetails()}
      </div>
      {renderSystemDetails && renderSystemDetails()}
    </>
  );
}

export default AccountDetails;
