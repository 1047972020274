import { useQuery } from '@tanstack/react-query';
import courseKeys from '../../query-keys/course-key-factory';
import { getActiveCoursesCount } from '../../services/api/course.service';
import { UserType } from '../../const/user-type';

// hook for managing courses
const useActiveCoursesCount = ({
  providerId,
  userType,
  displayLocale
}: {
  providerId: number | null | undefined;
  userType?: UserType | null;
  displayLocale: string | undefined;
}) => {
  const activeCoursesCountQuery = useQuery({
    queryKey: courseKeys.count(providerId!, displayLocale),
    queryFn: () => getActiveCoursesCount(providerId, displayLocale),
    refetchOnMount: true,
    enabled: userType === UserType.ADMIN
  });

  return {
    activeCoursesCountQuery
  };
};

export default useActiveCoursesCount;
