import { AppLanguage } from '../const';

const sessionKeys = {
  // everything to do with sessions
  all: (displayLocale: string | undefined) =>
    ['sessions', displayLocale || AppLanguage.English] as const,
  //   list of sessions
  list: (displayLocale: string | undefined) =>
    [...sessionKeys.all(displayLocale || AppLanguage.English), 'list'] as const,
  course: (courseId: number, displayLocale: string | undefined) =>
    [
      ...sessionKeys.list(displayLocale || AppLanguage.English),
      'course',
      courseId
    ] as const,
  sessionsForCourseForUser: (
    userId: number,
    courseId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.list(displayLocale || AppLanguage.English),
      'user',
      userId,
      'course',
      courseId
    ] as const,
  sessionsForCourseFrameworkForUser: (
    userId: number,
    frameworkVersionId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.list(displayLocale || AppLanguage.English),
      'user',
      userId,
      'frameworkVersion',
      frameworkVersionId
    ] as const,
  listForCourse: (courseId: number, displayLocale: string | undefined) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      'list',
      'course',
      courseId
    ] as const,
  today: (userId: number, displayLocale: string | undefined) =>
    [
      ...sessionKeys.list(displayLocale || AppLanguage.English),
      userId,
      'today'
    ] as const,
  todayInstructor: (userId: number, displayLocale: string | undefined) =>
    [
      ...sessionKeys.list(displayLocale || AppLanguage.English),
      userId,
      'today',
      'instructor'
    ] as const,
  todayLearner: (userId: number, displayLocale: string | undefined) =>
    [
      ...sessionKeys.list(displayLocale || AppLanguage.English),
      userId,
      'today',
      'learner'
    ] as const,
  session: (
    courseId: number,
    courseSessionId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      courseId,
      courseSessionId
    ] as const,
  userSession: (
    courseId: number,
    courseSessionId: number,
    userId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.session(
        courseId,
        courseSessionId,
        displayLocale || AppLanguage.English
      ),
      userId
    ] as const,
  courseMembers: (courseId: number) => [courseId, 'members'] as const,
  members: (
    courseId: number,
    courseSessionId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      ...sessionKeys.courseMembers(courseId),
      courseSessionId
    ] as const,
  attendance: (
    sessionId: number | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      'attendance',
      sessionId
    ] as const,

  member: (
    courseId: number,
    courseSessionId: number,
    userId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      courseId,
      courseSessionId,
      userId
    ] as const,
  learnerBookedSessionsForDateRange: (
    userId: number,
    startDate: string,
    endDate: string,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      userId,
      startDate,
      endDate,
      'booked'
    ] as const,
  bookableSessionsForDateRange: (
    userId: number,
    startDate: string,
    endDate: string,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      userId,
      startDate,
      endDate,
      'bookable'
    ] as const,
  fullyBookedSessionsForDateRange: (
    userId: number,
    providerId: number,
    startDate: string,
    endDate: string,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      userId,
      providerId,
      startDate,
      endDate,
      'fully-booked'
    ] as const,
  instructorSessionsForDateRange: (
    userId: number,
    startDate: string | undefined,
    endDate: string | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      userId,
      startDate,
      endDate,
      'instructor'
    ] as const,
  courseSessionBookingForUser: (
    courseId: number,
    courseSessionId: number,
    userId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      'course',
      courseId,
      'courseSession',
      courseSessionId,
      'user',
      userId,
      'booking'
    ] as const,
  centreCourseSessionsForDateRange: (
    providerId: number,
    centreId: number | undefined,
    startDate: string,
    endDate: string,
    displayLocale: string | undefined
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      providerId,
      centreId,
      startDate,
      endDate,
      'centre'
    ] as const,
  learnerSessionBookingsWithInstructor: (
    courseId: number,
    learnerId: number | undefined,
    instructorId: number | undefined,
    displayLocale: string
  ) =>
    [
      ...sessionKeys.all(displayLocale || AppLanguage.English),
      courseId,
      learnerId,
      instructorId,
      'learner-instructor-bookings'
    ] as const,
  offlineData: (sessionId: number, displayLocale: string | undefined) => [
    ...sessionKeys.all(displayLocale || AppLanguage.English),
    sessionId,
    'offline'
  ]
};

export default sessionKeys;
