import { flexRender, Row, Table } from '@tanstack/react-table';
import React from 'react';

function SelectableTableBody({
  table,
  dataId
}: {
  table: Table<any>;
  dataId: string;
}) {
  return (
    <tbody>
      {table.getRowModel().rows.map((row: Row<any>) => {
        return (
          <tr key={row.id} data-id={row.original[dataId]}>
            {row.getVisibleCells().map((cell) => {
              return (
                <td
                  key={cell.id}
                  className={`${
                    cell.column.id === 'objectives' && 'border-start'
                  }`}
                  data-title={cell.column.columnDef.header}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

export default SelectableTableBody;
