import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/ProviderService';
import CentreAddress from './CentreAddress';

function CentreDetailsTable({
  centre
}: {
  centre: components['schemas']['CentreDto'];
}) {
  return (
    <table className="table">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="name"
              defaultMessage="Name"
              description="Name"
            />
          </th>
          <td>{centre.name}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="description"
              defaultMessage="Description"
              description="Description"
            />
          </th>
          <td>{centre.description}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="address"
              defaultMessage="Address"
              description="Address"
            />
          </th>
          <td>
            {centre.address && <CentreAddress address={centre.address} />}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="externalId"
              defaultMessage="External Id"
              description="External Id"
            />
          </th>
          <td>
            {centre.externalId ? (
              centre.externalId
            ) : (
              <span className="text-muted">
                <FormattedMessage
                  id="externalId.none"
                  defaultMessage="No External Id provided"
                  description="No External Id provided"
                />
              </span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default CentreDetailsTable;
