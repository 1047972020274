import { AppLanguage } from '../const';

const resourceKeys = {
  // resource assets for this framework version
  resources: (
    frameworkVersionId: number | undefined,
    displayLocale: string | undefined
  ) =>
    [
      'resource',
      frameworkVersionId,
      displayLocale || AppLanguage.English
    ] as const
};

export default resourceKeys;
