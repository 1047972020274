import React, { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

import { components } from '../../../../../../types/openapi/UserService';
import { AppLanguage, AppRoute } from '../../../../../../const';
import { appStrings } from '../../../../../../modules/i18n';
import { LangContext } from '../../../../../../modules/i18n/components/IntlWrapper';
import useLearnerSessionBookingsWithInstructor from '../../../../../../hooks/useLearnerSessionBookingsWithInstructor';
import { ProfileContext } from '../../../../../../modules/profile/ProfileProvider';

function LearnerCell({
  row,
  currentSessionStartTime
}: {
  row: Row<components['schemas']['UserDto']>;
  currentSessionStartTime?: string;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { courseId } = useParams();
  const { original: learner } = row;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  let learnerIsNew = false;

  const { learnerSessionBookingsWithInstructorQuery } =
    useLearnerSessionBookingsWithInstructor({
      courseId: Number(courseId),
      learnerId: learner.userId,
      instructorId: profile?.userId,
      displayLocale
    });

  // If there are no bookings for that instructor on that stage
  if (
    learnerSessionBookingsWithInstructorQuery.data &&
    learnerSessionBookingsWithInstructorQuery.data.length === 0
  ) {
    learnerIsNew = true;
    // if there are bookings, and there are no attended sessions before selected session (irrespective of whether selected session is attended or not)
  } else if (
    currentSessionStartTime &&
    learnerSessionBookingsWithInstructorQuery.data &&
    !learnerSessionBookingsWithInstructorQuery.data.find(
      (booking) =>
        dayjs(booking.courseSession?.sessionStartTime).isBefore(
          dayjs(currentSessionStartTime)
        ) && booking.attended === true
    )
  ) {
    learnerIsNew = true;
  }

  return (
    <Link
      data-user-id={
        ((row as any).original as components['schemas']['UserDto']).userId
      }
      to={`${appStrings[displayLocale as AppLanguage][AppRoute.Learners]}/${
        ((row as any).original as components['schemas']['UserDto']).userId
      }`}
      className="btn btn-outline-secondary w-100"
    >
      <div className="d-flex">
        <div>
          {' '}
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div
          className="flex-grow-1 text-start ms-2"
          data-username={
            ((row as any).original as components['schemas']['UserDto']).username
          }
          data-gdpr="true"
        >
          {((row as any).original as components['schemas']['UserDto']).fullname}
        </div>
        {learnerIsNew && (
          <span className="badge bg-primary">
            <FormattedMessage
              id="learner.stage.new"
              defaultMessage="New"
              description="New"
            />
          </span>
        )}
      </div>
    </Link>
  );
}

export default LearnerCell;

LearnerCell.defaultProps = {
  currentSessionStartTime: undefined
};
