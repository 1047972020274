import React, { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { faMedkit, faWheelchair } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { components } from '../../../../../../types/openapi/UserService';
import { ModalContext } from '../../../../../../modules/modal/ModalProvider';

function MedicalDetailsCell({
  row
}: {
  row: Row<components['schemas']['UserDto']>;
}) {
  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const intl = useIntl();

  function openModal(learner: components['schemas']['UserDto']) {
    const header = intl.formatMessage(
      {
        id: 'learner.colon.name',
        defaultMessage: 'Learner: {name}',
        description: 'Learner: {name}'
      },
      { name: learner.username }
    );

    const modalContent = learner.medicalConditions || '';

    modal(modalContent, {
      header,
      hideFooter: true
    });
  }
  const learner = (row as any).original as components['schemas']['UserDto'];
  return (
    <>
      {!!learner.medicalConditions && (
        <button
          className="btn btn-light bg-white border-0"
          type="button"
          onClick={() => openModal(learner)}
          aria-label={intl.formatMessage(
            {
              id: 'aria-label.medical details',
              defaultMessage: '{name} medical details',
              description: 'Name medical details'
            },
            { name: learner.fullname }
          )}
        >
          <FontAwesomeIcon icon={faMedkit} />
        </button>
      )}
      {!!learner.isClassifiedDisabled && (
        <FontAwesomeIcon icon={faWheelchair} />
      )}
    </>
  );
}

export default MedicalDetailsCell;
