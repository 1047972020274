import React from 'react';
import { PropsValue } from 'react-select';

import { components } from '../../../types/openapi/UserService';
import useCoursesFrameworks from '../../../hooks/frameworks/useCoursesFrameworks';
import SelectFilter from './inputs/selects/SelectFilter';

function FrameworkFilter({
  profile,
  displayLocale,
  columnDef,
  initialFilterValue,
  handleDropdownInputChange
}: {
  profile: components['schemas']['UserProvidersDto'];
  displayLocale: string;
  columnDef: any;
  initialFilterValue: number;
  handleDropdownInputChange: (selectedOption: any) => void;
}) {
  const { coursesFrameworksQuery } = useCoursesFrameworks({
    providerId: profile?.providerId,
    displayLocale
  });

  const options: PropsValue<any> = coursesFrameworksQuery.data
    ?.map((m: any) => {
      return {
        value: m.frameworkId,
        label: `${m.name} (${m.coursesCount})`
      };
    })
    .sort((a: { label: string }, b: { label: string }) =>
      a.label.localeCompare(b.label)
    );

  return (
    <SelectFilter
      columnDef={columnDef}
      initialFilterValue={initialFilterValue}
      handleDropdownInputChange={handleDropdownInputChange}
      options={options}
      columnName="Framework"
    />
  );
}

export default FrameworkFilter;
