import dayjs from 'dayjs';
import AttendanceStatus from '../const/attendance-status.enum';
import { components } from '../types/openapi/CourseService';

const calculateAttendanceStatus = (
  _session: components['schemas']['UserCourseSessionBookingDto'] | undefined
) => {
  if (_session) {
    if (_session.courseSession) {
      const startTime = dayjs(_session.courseSession.sessionStartTime);
      const now = dayjs(new Date());
      if (startTime.isAfter(now)) {
        return AttendanceStatus.FUTURE;
      }
    }
    if (_session.attended) {
      return AttendanceStatus.ATTENDED;
    }
  }
  return AttendanceStatus.ABSENT;
};
export default calculateAttendanceStatus;
