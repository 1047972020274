import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import StageSelect from '../StageSelect';
import { GeneralError } from '../../common';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import useFrameworkProgress from '../../../hooks/useFrameworkProgress';
import buildSelectFrameworkStageOptions from '../../../utils/react-select-helpers/buildSelectFrameworkStageOptions';
import { components } from '../../../types/openapi/FrameworkService';
import stageKeys from '../../../query-keys/stage-key-factory';

function FrameworkStageOptionsWrapper({
  frameworkId,
  frameworkVersionId,
  learnerId,
  onChange
}: {
  frameworkId: number;
  frameworkVersionId: number;
  learnerId: number;
  onChange: (value: number) => void;
}) {
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const queryClient = useQueryClient();

  if (!frameworkId || !frameworkVersionId) {
    return <GeneralError />;
  }

  const { currentFrameworkStageQuery } = useFrameworkProgress({
    frameworkVersionId,
    learnerId,
    displayLocale
  });

  const frameworkStagesData = queryClient.getQueryData<
    components['schemas']['StageDto'][]
  >(stageKeys.list(frameworkId, frameworkVersionId, displayLocale));

  if (!frameworkStagesData?.length) {
    console.warn('framework has no stages');
    return <GeneralError />;
  }

  const stageOptions = buildSelectFrameworkStageOptions(
    frameworkStagesData,
    intl
  );

  if (frameworkStagesData && currentFrameworkStageQuery.data) {
    const matchingStage = stageOptions.find(
      (f) => f.value === currentFrameworkStageQuery.data.stageId
    );

    return (
      <StageSelect
        frameworkVersionId={frameworkVersionId}
        stageOptions={stageOptions}
        defaultStageOption={matchingStage || stageOptions[0]}
        onChange={onChange}
      />
    );
  }
  return <GeneralError />;
}

export default FrameworkStageOptionsWrapper;
