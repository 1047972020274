/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { Dispatch, SetStateAction } from 'react';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { components } from '../../types/openapi/CourseService';
import EditNote from './EditNote';
import { deleteNote } from '../../services/api/course.service';
import noteKeys from '../../query-keys/note-key-factory';

function NoteListItem({
  courseId,
  sessionId,
  userId,
  showEditId,
  setShowEditId,
  note
}: {
  courseId: number;
  sessionId: number;
  userId: number;
  showEditId: number;
  setShowEditId?: Dispatch<SetStateAction<number>>;
  note: components['schemas']['SessionUserNoteDto'];
}) {
  const intl = useIntl();
  const queryClient = useQueryClient();

  // Show the Edit note form for this note
  const handleShowEdit = (
    note: components['schemas']['SessionUserNoteDto']
  ) => {
    if (setShowEditId && note.noteId) setShowEditId(note.noteId);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: removeNote, isLoading } = useMutation(
    (noteId: number) => deleteNote(noteId),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'note.delete.success',
            defaultMessage: 'Note has been removed'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: noteKeys.list(courseId, sessionId, userId)
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'note.delete.error',
            defaultMessage: 'There was an error removing this note'
          }),
          { delay: 200 }
        );
      }
    }
  );

  async function onModalConfirm(
    note: components['schemas']['SessionUserNoteDto']
  ) {
    removeNote(note.noteId!);
  }

  // If there is a note currently being edited but it's not this one, don't show
  if (showEditId > 0 && showEditId !== note.noteId) return <div />;

  return (
    <li key={note.noteId} className="d-flex mb-2 flex-column notes-list__item">
      <div className="d-flex mb-3">
        <FontAwesomeIcon icon={faCommentAlt} className="notes-list__icon" />
        <span className="fw-bold mt-2">
          {note.courseSession?.instructor?.firstName}
        </span>
      </div>
      {/* If this note is not currently being edited, show note with buttons */}
      {showEditId === 0 && (
        <>
          <div>{parse(note.note?.noteText!)}</div>
          <div className="btn-group align-self-end mt-3">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handleShowEdit(note)}
              type="button"
            >
              <FormattedMessage
                id="notes.edit"
                defaultMessage="Edit"
                description="Edit"
              />
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => onModalConfirm(note)}
              type="button"
            >
              <FormattedMessage
                id="notes.delete"
                defaultMessage="Delete"
                description="Delete"
              />
            </button>
          </div>
        </>
      )}
      {/* If this note is being edited, show note form */}
      {showEditId === note.noteId && setShowEditId && (
        <EditNote
          courseId={courseId}
          sessionId={sessionId}
          userId={userId}
          noteId={note.noteId!}
          oldNoteText={note.note?.noteText!}
          setShowEditId={setShowEditId}
        />
      )}
    </li>
  );
}

export default NoteListItem;
