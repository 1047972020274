import {
  ColumnDef,
  flexRender,
  HeaderGroup,
  Table
} from '@tanstack/react-table';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import OffcanvasFilterWrapper from './OffcanvasFilterWrapper';
import OffcanvasSortingCheckboxes from './OffcanvasSortingCheckboxes';

function OffCanvasFilters({
  table,
  show,
  columns,
  filtering,
  sorting,
  onSubmit,
  resetFilters,
  handleCloseFilter
}: {
  table: Table<any>;
  show: boolean;
  columns: ColumnDef<any>[];
  filtering: any[] | undefined;
  sorting: any;
  resetFilters: () => void;
  onSubmit: (values: FieldValues) => void;
  handleCloseFilter: () => void;
}) {
  const { handleSubmit, getValues, reset, control } = useForm({
    mode: 'onBlur'
  });

  const handleSubmitForm = () => {
    const values = getValues();
    onSubmit(values);
    handleCloseFilter();
  };

  const clearFilters = () => {
    resetFilters();
    reset();
    handleCloseFilter();
  };
  return (
    <Offcanvas show={show} onHide={handleCloseFilter} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {' '}
          <FormattedMessage
            id="results.filter"
            defaultMessage="Filter results"
            description="Filter results"
          />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
            <div key={headerGroup.id}>
              <fieldset className="border p-3 mb-2">
                <legend>
                  <FormattedMessage
                    id="filters"
                    defaultMessage="Filters"
                    description="Filters"
                  />
                </legend>
                {headerGroup.headers.map((header) => {
                  const { column } = header;
                  if (
                    column.getCanFilter() &&
                    (column.columnDef as any).filterType
                  )
                    return (
                      <div key={header.id} className="mb-3">
                        <div className="d-flex justify-content-between align-items-end">
                          {flexRender(
                            column.columnDef.header,
                            header.getContext()
                          )}
                        </div>

                        {column.getCanFilter() ? (
                          <div>
                            <OffcanvasFilterWrapper
                              column={column}
                              columnDef={columns.find(
                                (f) => f.id === column.id
                              )}
                              filtering={filtering}
                              control={control}
                            />
                          </div>
                        ) : null}
                      </div>
                    );
                  return <div />;
                })}
              </fieldset>
              <fieldset className="border p-3 mb-2">
                <legend>
                  <FormattedMessage
                    id="sort-by"
                    defaultMessage="Sort by..."
                    description="Sort by..."
                  />
                </legend>
                <OffcanvasSortingCheckboxes
                  headerGroup={headerGroup}
                  control={control}
                  sorting={sorting}
                />
              </fieldset>
            </div>
          ))}
          <div className="d-flex gap-2 justify-content-center">
            <button
              type="button"
              className="btn btn-outline-secondary clear-filter"
              onClick={clearFilters}
            >
              <FormattedMessage
                id="filters.clear"
                defaultMessage="Clear filters"
                description="Clear filters"
              />
            </button>
            <button type="submit" className="btn btn-primary align-self-center">
              <FormattedMessage
                id="results.view.button"
                defaultMessage="View results"
                description="View results"
              />
            </button>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffCanvasFilters;
