import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import PublicPageLayout from '../components/layout/PublicPageLayout';
import RequestPasswordResetForm from '../components/auth/password-reset/PasswordResetRequestForm';

function RequestResetPasswordPage() {
  const intl = useIntl();
  return (
    <PublicPageLayout
      header={
        <FormattedMessage
          id="password.request"
          defaultMessage="Request Password Reset"
        />
      }
    >
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.request_password_reset',
            defaultMessage: 'Request password reset'
          })}
        </title>
      </Helmet>
      <p>
        <FormattedMessage
          id="request.password.instructions"
          defaultMessage=" Please fill in your username. An email containing a link to reset your
        password will be sent to the email address associated with this
        username."
        />
      </p>
      <RequestPasswordResetForm />
    </PublicPageLayout>
  );
}

export default RequestResetPasswordPage;
