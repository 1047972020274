import { useQuery } from '@tanstack/react-query';
import sessionKeys from '../query-keys/session-key-factory';
import { getCourseSessionBookingForUser } from '../services/api/course.service';
import calculateAttendanceStatus from '../utils/calculateAttendanceStatus';

const useUserSessionBooking = ({
  courseId,
  courseSessionId,
  userId,
  displayLocale
}: {
  courseId: number;
  courseSessionId: number;
  userId: number;
  displayLocale: string | undefined;
}) => {
  const courseSessionBookingForUserQuery = useQuery({
    queryKey: sessionKeys.courseSessionBookingForUser(
      courseId,
      courseSessionId,
      userId,
      displayLocale
    ),
    queryFn: () =>
      getCourseSessionBookingForUser(
        courseId,
        courseSessionId,
        userId,
        displayLocale
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!userId
  });

  const attendanceStatus = calculateAttendanceStatus(
    courseSessionBookingForUserQuery.data
  );

  return {
    courseSessionBookingForUserQuery,
    attendanceStatus
  };
};

export default useUserSessionBooking;
