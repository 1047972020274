import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { UserType } from '../const/user-type';
import ViewUser from '../components/users/ViewUser';
import EditAdminCentres from '../components/centres/admins/EditAdminCentres';
import EditInstructorCentres from '../components/instructors-admin/EditInstructorCentres';
import EditLearnerCentres from '../components/learners-admin/view-learner/EditLearnerCentres';

const ManageUsers = lazy(() => import('../components/users/ManageUsers'));

export default function instructorRoutes(
  lang: AppLanguage | string | undefined
) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Users],
    element: (
      <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="users"
          defaultMessage="Users"
          description="Users"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageUsers />,
        errorElement: <ErrorPage />
      },
      {
        path: `:userId`,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="user"
              defaultMessage="User"
              description="User"
            />
          )
        },
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}
              >
                <ViewUser />
              </ProtectedRoute>
            ),
            errorElement: <ErrorPage />
          },
          {
            path: `${appStrings[langVal][AppRoute.EditAdminCentres]}`,
            element: <EditAdminCentres />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="admin.edit.centres"
                  defaultMessage="Edit Admin Centres"
                  description="Edit Admin Centres"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.EditInstructorCentres]}`,
            element: <EditInstructorCentres />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="instructor.edit.centres"
                  defaultMessage="Edit Instructor Centres"
                  description="Edit Instructor Centres"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.EditLearnerCentres]}`,
            element: <EditLearnerCentres />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="learner.edit.centres"
                  defaultMessage="Edit Learner Centres"
                  description="Edit Learner Centres"
                />
              )
            }
          }
        ]
      }
    ]
  };
  return routes;
}
