import { useQuery } from '@tanstack/react-query';
import centreKeys from '../../query-keys/centre-key-factory';
import { getCentresForUser } from '../../services/api/user.service';

const useCentresForUser = ({
  userId,
  userTypeId,
  displayLocale
}: {
  userId: number | null | undefined;
  userTypeId: number | null | undefined;
  displayLocale: string | undefined;
}) => {
  const centresForUserListQuery = useQuery({
    queryKey: centreKeys.userList(userId, userTypeId, displayLocale),
    queryFn: () => getCentresForUser(userId, userTypeId, displayLocale),
    refetchOnMount: true,
    enabled: !!userId
  });

  const centreIds: number[] = centresForUserListQuery.data
    ? centresForUserListQuery.data
        .map((centre) => centre.centreId)
        .filter((value: number | null | undefined): value is number => {
          return !!value;
        })
    : [];

  return {
    centresForUserListQuery,
    centreIds
  };
};

export default useCentresForUser;
