/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/dot-notation */
import axios from 'axios';
// @ts-ignore
import pascalcaseKeys from 'pascalcase-keys';

import { components } from '../../types/openapi/FrameworkService';
import { components as providerComponents } from '../../types/openapi/ProviderService';

import { bearerToken } from '../bearer-token';
import camelCaseResponse from '../camel-case-interceptor';
import expiredTokenInterceptor from '../refresh-access-token.interceptor';
import { Subset } from '../../types/Subset.type';
import { FilteringObject } from '../../const/filtering-object';

const BASE_URL = `${process.env.REACT_APP_FRAMEWORK_API_URL}/api`;

export const frameworksApi = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

frameworksApi.interceptors.request.use(
  (config) => {
    const token: string = bearerToken();
    if (token && config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

frameworksApi.interceptors.response.use(
  (response) => camelCaseResponse(response),
  (error) => expiredTokenInterceptor(frameworksApi, error)
);

export const createFramework = async (
  framework: components['schemas']['FrameworkDto'],
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  const params = {
    lang
  };
  const frameworkObj = {
    framework
  };

  const body = pascalcaseKeys(frameworkObj, { deep: true });

  const response = await frameworksApi.post<
    components['schemas']['FrameworkDto']
  >('frameworks', body, { params });

  return response.data;
};

export const updateFramework = async (
  frameworkId: number | undefined,
  framework: Subset<components['schemas']['FrameworkDto']>,
  lang: string | undefined
) => {
  if (!frameworkId) {
    throw Error('no id provided');
  }
  if (!lang) {
    throw Error('no lang provided');
  }
  const params = {
    lang
  };

  const frameworkObj = {
    framework
  };

  const body = pascalcaseKeys(frameworkObj, { deep: true });

  const response = await frameworksApi.put<
    components['schemas']['FrameworkDto']
  >(`frameworks/${frameworkId}`, body, { params });

  return response.data;
};

export const createFrameworkVersion = async (
  frameworkId: number,
  frameworkVersion: components['schemas']['FrameworkVersionDto'],
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no language provided');
  }
  const params = {
    lang
  };
  const body = pascalcaseKeys({ frameworkVersion }, { deep: false });

  const response = await frameworksApi.post<
    components['schemas']['FrameworkVersionDto']
  >(`frameworks/${frameworkId}/versions`, body, { params });

  return response.data;
};

export const cloneFrameworkVersion = async (
  frameworkId: number,
  frameworkVersionId: number,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no language provided');
  }
  const params = {
    lang
  };

  const response = await frameworksApi.post<any>(
    `frameworks/${frameworkId}/frameworkVersions/${frameworkVersionId}/clone`,
    {
      params
    }
  );

  return response.data;
};

export const updateFrameworkVersion = async (
  frameworkId: number,
  frameworkVersionId: number,
  frameworkVersion: Subset<components['schemas']['FrameworkVersionDto']>,
  lang: string | undefined
) => {
  if (!frameworkId || !frameworkVersionId) {
    throw Error('no id provided');
  }
  if (!lang) {
    throw Error('no language provided');
  }
  const params = {
    lang
  };
  const body = pascalcaseKeys({ frameworkVersion }, { deep: false });

  const response = await frameworksApi.put<any>(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}`,
    body,
    {
      params
    }
  );

  return response.data;
};

export const activateFrameworkVersion = async (
  frameworkId: number,
  frameworkVersionId: number,
  lang?: string | undefined
) => {
  const params = {
    lang
  };

  const response = await frameworksApi.put<any>(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/active`,
    { params }
  );

  return response.data;
};

export const getFrameworks = async (
  lang: string | undefined,
  providerId?: number | null | undefined
) => {
  if (!lang) {
    throw Error('no lang defined');
  }

  const params = {
    lang,
    providerId
  };

  const response = await frameworksApi.get<
    components['schemas']['FrameworkDto'][]
  >('frameworks', { params });

  return response.data;
};

export const getFrameworksPaged = async (
  providerId: number | null | undefined,
  lang?: string,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  rawFiltering?: any
) => {
  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const params = {
    providerId,
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await frameworksApi.get<
    components['schemas']['PagedFrameworkDto']
  >('frameworksPaged', { params });

  return response.data;
};

export const getFramework = async (frameworkId: number, lang?: string) => {
  if (!lang) {
    throw Error('no lang defined');
  }
  const params = {
    lang
  };

  const response = await frameworksApi.get<
    components['schemas']['FrameworkDto']
  >(`frameworks/${frameworkId}`, { params });

  return response.data;
};

export const getFrameworkVersions = async (
  frameworkId: number | undefined,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang defined');
  }
  const params = {
    lang
  };
  if (!frameworkId) {
    throw Error('no id provided');
  }

  const response = await frameworksApi.get<
    components['schemas']['FrameworkVersionDto'][]
  >(`frameworks/${frameworkId}/versions`, { params });

  return response.data;
};

export const deleteFrameworkVersion = async (
  frameworkId: number | undefined,
  versionId: number | undefined
) => {
  if (!frameworkId) {
    throw Error('no id provided');
  }

  const response = await frameworksApi.delete<
    components['schemas']['FrameworkVersionDto'][]
  >(`frameworks/${frameworkId}/versions/${versionId}`);

  return response.data;
};

export const deleteFrameworkStage = async (
  frameworkId: number | undefined,
  versionId: number | undefined,
  stageId: number | undefined
) => {
  if (!frameworkId) {
    throw Error('no id provided');
  }

  const response = await frameworksApi.delete<
    components['schemas']['FrameworkVersionDto'][]
  >(`frameworks/${frameworkId}/versions/${versionId}/stages/${stageId}`);

  return response.data;
};

export const deleteFrameworkStageObjective = async (
  frameworkId: number | undefined,
  versionId: number | undefined,
  stageId: number | undefined,
  objectiveId: number | undefined
) => {
  if (!frameworkId) {
    throw Error('no id provided');
  }
  if (!versionId) {
    throw Error('no id provided');
  }
  if (!stageId) {
    throw Error('no id provided');
  }
  if (!objectiveId) {
    throw Error('no id provided');
  }

  const response = await frameworksApi.delete<
    components['schemas']['FrameworkVersionDto'][]
  >(
    `frameworks/${frameworkId}/versions/${versionId}/stages/${stageId}/objectives/${objectiveId}`
  );

  return response.data;
};

export const getFrameworkVersionForFramework = async (
  frameworkId: number | undefined,
  frameworkVersionId: number | undefined,
  lang?: string
) => {
  const params = {
    lang
  };
  if (!frameworkId || !frameworkVersionId) {
    throw Error('no id provided');
  }

  const response = await frameworksApi.get<
    components['schemas']['FrameworkVersionDto']
  >(`frameworks/${frameworkId}/versions/${frameworkVersionId}`, { params });

  return response.data;
};

/**
 * @deprecated The method should not be used - it has been replaced with getFrameworks
 */
export const getFrameworksForProvider = async (
  providerId: number | null | undefined,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await frameworksApi.get<
    components['schemas']['FrameworkDto'][]
  >(`frameworks/providers/${providerId}`, { params });

  return response.data;
};

export const getFrameworkProviders = async (
  frameworkId: number | null | undefined,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!frameworkId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await frameworksApi.get<
    providerComponents['schemas']['ProviderDto'][]
  >(`frameworks/${frameworkId}/providers`, { params });

  return response.data;
};

export const getFrameworkProvidersNotAssignedForFramework = async (
  frameworkId: number | null | undefined,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!frameworkId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await frameworksApi.get<
    providerComponents['schemas']['ProviderDto'][]
  >(`frameworks/${frameworkId}/providers/not-assigned`, { params });

  return response.data;
};

export const createFrameworkStage = async (
  frameworkId: number,
  frameworkVersionId: number,
  stage: components['schemas']['StageDto'],
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!frameworkId || !frameworkVersionId) {
    throw Error('missing ID');
  }
  const params = {
    lang
  };
  const stageObj = {
    stage
  };

  const body = pascalcaseKeys(stageObj, { deep: true });

  const response = await frameworksApi.post<components['schemas']['StageDto']>(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages`,
    body,
    {
      params
    }
  );

  return response.data;
};

export const updateFrameworkStage = async (
  frameworkId: number | undefined,
  frameworkVersionId: number | undefined,
  stageId: number | undefined,
  stage: Subset<components['schemas']['StageDto']>,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!frameworkId || !frameworkVersionId || !stageId) {
    throw Error('missing ID');
  }
  const params = {
    lang
  };

  const stageObj = {
    stage
  };

  const body = pascalcaseKeys(stageObj, { deep: true });

  const response = await frameworksApi.put<components['schemas']['StageDto']>(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages/${stageId}`,
    body,
    {
      params
    }
  );

  return response.data;
};

export const getStages = async (
  frameworkId: number | undefined | null,
  frameworkVersionId: number | undefined,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!frameworkId || !frameworkVersionId) {
    throw Error('no id provided');
  }

  const params = {
    lang
  };

  const response = await frameworksApi.get<components['schemas']['StageDto'][]>(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages`,
    {
      params
    }
  );

  return response.data;
};

export const getStage = async (
  frameworkId: number | undefined,
  frameworkVersionId: number | undefined,
  stageId: number | undefined,
  lang?: string
) => {
  if (!frameworkId || !frameworkVersionId || !stageId) {
    throw Error('no id provided');
  }

  const params = {
    lang
  };

  const response = await frameworksApi.get<components['schemas']['StageDto']>(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages/${stageId}`,
    {
      params
    }
  );

  return response.data;
};

export const getStageObjectives = async (
  frameworkId: number | undefined | null,
  frameworkVersionId: number | undefined | null,
  stageId: number | undefined,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!frameworkId || !frameworkVersionId || !stageId) {
    throw Error('no id provided');
  }

  const params = {
    lang
  };

  const response = await frameworksApi.get<
    components['schemas']['ObjectiveDto'][]
  >(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages/${stageId}/objectives/`,
    {
      params
    }
  );

  return response.data;
};

export const getStageObjective = async (
  frameworkId: number | undefined,
  frameworkVersionId: number | undefined,
  stageId: number | undefined,
  objectiveId: number | undefined,
  lang?: string
) => {
  if (!frameworkId || !frameworkVersionId || !stageId || !objectiveId) {
    throw Error('no id provided');
  }

  const params = {
    lang
  };

  const response = await frameworksApi.get<
    components['schemas']['ObjectiveDto']
  >(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages/${stageId}/objectives/${objectiveId}`,
    {
      params
    }
  );

  return response.data;
};

export const createStageObjective = async (
  frameworkId: number | undefined,
  frameworkVersionId: number | undefined,
  stageId: number | undefined,
  objective: components['schemas']['ObjectiveDto'],
  lang?: string
) => {
  if (!frameworkId || !frameworkVersionId || !stageId) {
    throw Error('missing ID');
  }
  const params = {
    lang
  };
  const objObj = {
    objective
  };

  const body = pascalcaseKeys(objObj, { deep: true });

  const response = await frameworksApi.post<
    components['schemas']['ObjectiveDto']
  >(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages/${stageId}/objectives`,
    body,
    {
      params
    }
  );

  return response.data;
};

export const updateFrameworkStageObjective = async (
  frameworkId: number,
  frameworkVersionId: number,
  stageId: number,
  objectiveId: number,
  objective: Subset<components['schemas']['ObjectiveDto']>,
  lang?: string
) => {
  if (!frameworkId || !frameworkVersionId || !stageId || !objectiveId) {
    throw Error('missing ID');
  }
  const params = {
    lang
  };
  const objObj = {
    objective
  };

  const body = pascalcaseKeys(objObj, { deep: true });

  const response = await frameworksApi.put<
    components['schemas']['ObjectiveDto']
  >(
    `frameworks/${frameworkId}/versions/${frameworkVersionId}/stages/${stageId}/objectives/${objectiveId}`,
    body,
    {
      params
    }
  );

  return response.data;
};
// This does not create a framework
// It creates a permission for a framework for a provider
// used for addProviderToFramework
export const createFrameworkForProvider = async (
  frameworkId: number,
  providerId: number,
  lang: string | undefined
) => {
  if (!lang) {
    throw Error('no lang provided');
  }
  if (!frameworkId || !providerId) {
    throw Error('missing ID');
  }
  const params = {
    lang
  };

  const response = await frameworksApi.post(
    `frameworks/${frameworkId}/providers/${providerId}`,
    {
      params
    }
  );

  return response.data;
};
