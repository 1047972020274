import React, { useContext, useReducer, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import useCourse from '../../../hooks/course/useCourse';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import SessionsTable from '../sessions/SessionsTable';
import { GeneralError } from '../../common';
import Loading from '../../common/Loading';
import { ScheduleSeriesFormValues } from './ScheduleSeriesFormValues.type';
import { ModalContext } from '../../../modules/modal/ModalProvider';
import ScheduleSeriesForm from './ScheduleSeriesForm';
import ViewSessionLink from '../sessions/ViewSessionLink';
import { components } from '../../../types/openapi/CourseService';
import SessionsTableForm from '../sessions/SessionsTableForm';
import ScheduleSingleSessionForm from './ScheduleSingleSessionForm';
import UnsavedSession from './unsaved-session.type';
import sessionKeys from '../../../query-keys/session-key-factory';
import { createCourseSessionWithUsers } from '../../../services/api/course.service';
import { UserType } from '../../../const/user-type';
import isAllowed from '../../../utils/permissions/isAllowed';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';

const reducer = (
  newSessions: UnsavedSession[],
  action: { type: any; session: any }
) => {
  switch (action.type) {
    case 'add_session': {
      const updatedAdd = [...newSessions];
      updatedAdd.push(action.session);
      return updatedAdd;
    }

    case 'remove_session': {
      const updatedRemoved = newSessions.filter(
        (session: UnsavedSession) => session.uuid !== action.session.uuid
      );
      return updatedRemoved;
    }
    case 'reset': {
      return [];
    }
    default:
      return newSessions;
  }
};

function SessionsScheduling({
  course
}: {
  course: components['schemas']['CourseDto'];
}) {
  const { courseId } = useParams();
  const courseIdNum = Number(courseId);
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const modalCtx = useContext(ModalContext);
  const { modal, closeModal } = modalCtx;
  const intl = useIntl();
  const queryClient = useQueryClient();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const [newestSessionId, setNewestSessionId] = useState<string>();

  const [newSessions, dispatch] = useReducer(reducer, []);

  const { courseSessionsForCourseQuery: sessions } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const { mutate: scheduleNewSessions } = useMutation(
    (courseSessions: any) =>
      createCourseSessionWithUsers(courseIdNum, courseSessions),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'course.schedule_success',
            defaultMessage: 'All sessions have been scheduled correctly'
          }),
          { delay: 200 }
        );

        queryClient.invalidateQueries({
          queryKey: sessionKeys.course(courseIdNum, displayLocale)
        });

        dispatch({ type: 'reset', session: null });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'courses.schedule_error',
            defaultMessage: 'There was an error scheduling the sessions'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const setUpSessions = (values: ScheduleSeriesFormValues) => {
    const { numberOfWeeks, startDate, daysOfWeek, times } = values;
    const { startTime, endTime } = times;
    const startDayJs = dayjs(startDate);
    const [startHour, startMinute] = startTime.split(':');
    const [endHour, endMinute] = endTime.split(':');

    const { centreId, facilityId, instructorId } = course;
    // loop through all starting days to set up sessions
    daysOfWeek
      .filter((day) => !!day)
      .forEach((day: number) => {
        // get the next day on or after the start date that matches
        // @see https://day.js.org/docs/en/get-set/weekday
        const firstSessionDay = startDayJs.weekday(day);

        const firstSessionStart = firstSessionDay
          .hour(Number(startHour))
          .minute(Number(startMinute));
        const firstSessionEnd = firstSessionDay
          .hour(Number(endHour))
          .minute(Number(endMinute));
        /* eslint-disable-next-line no-plusplus */
        for (let i = 0; i < numberOfWeeks; i++) {
          const currentSessionStartTime = firstSessionStart.add(i, 'week');
          const currentSessionEndTime = firstSessionEnd.add(i, 'week');
          const session: UnsavedSession = {
            // temp ID for client side editing
            uuid: uuidv4(),
            courseId: courseIdNum,
            sessionStartTime: currentSessionStartTime.toISOString(),
            sessionEndTime: currentSessionEndTime.toISOString(),
            centreId,
            facilityId: facilityId || null,
            instructorId
          };
          dispatch({ type: 'add_session', session });
        }
      });
  };

  async function onModalConfirm(values: ScheduleSeriesFormValues) {
    closeModal();
    setUpSessions(values);
  }

  function openSeriesScheduleModal() {
    const header = intl.formatMessage({
      id: 'sessions.schedule.series',
      defaultMessage: 'Schedule Series',
      description: 'Schedule Series'
    });

    modal(
      <ScheduleSeriesForm
        onHandleSubmit={(values) => onModalConfirm(values)}
        onCancel={() => closeModal()}
      />,
      {
        header,
        hideFooter: true
      }
    );
  }

  const removeSession = (
    session: Partial<components['schemas']['CourseSessionDto']>
  ) => {
    dispatch({ type: 'remove_session', session });
  };

  const addSession = (
    values: Pick<ScheduleSeriesFormValues, 'startDate' | 'times'>
  ) => {
    const { startDate, times } = values;
    const { startTime, endTime } = times;
    const { centreId, facilityId, instructorId } = course;
    const sessionStart = dayjs(startDate + startTime);
    const sessionEnd = dayjs(startDate + endTime);
    const tempId = uuidv4();
    setNewestSessionId(tempId);
    const newSession: UnsavedSession = {
      // temp ID for client side editing
      uuid: tempId,
      courseId: courseIdNum,
      sessionStartTime: sessionStart.toISOString(),
      sessionEndTime: sessionEnd.toISOString(),
      centreId,
      facilityId: facilityId || null,
      instructorId
    };
    dispatch({ type: 'add_session', session: newSession });
  };

  const saveSessions = () => {
    const sessionsToSave: components['schemas']['CourseSessionDto'][] =
      newSessions.map((session) => {
        const { uuid, ...rest } = session;
        return rest;
      });
    scheduleNewSessions(sessionsToSave);
  };

  return (
    <div className="card border-0 rounded-top-right-lg mb-2">
      <div className="card-header d-flex">
        <div className="flex-grow-1">
          <h2>
            <FormattedMessage
              id="sessions"
              defaultMessage="Sessions"
              description="Sessions"
            />
          </h2>
        </div>
        {isAllowed([UserType.ADMIN], profile?.userTypeId as UserType) && (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => openSeriesScheduleModal()}
            >
              <FormattedMessage
                id="sessions.schedule.add.series"
                defaultMessage="Add series"
                description="Add series"
              />
            </button>
          </div>
        )}
      </div>
      <div className="card-body">
        {newSessions.length > 0 && (
          <div className="border p-2 bg-light ">
            <h3>
              <FormattedMessage
                id="sessions.new"
                defaultMessage="New Sessions"
                description="New Sessions"
              />
            </h3>
            <div className="mb-3">
              <SessionsTableForm
                sessions={newSessions}
                newestSessionId={newestSessionId}
                renderSessionActions={({ session }) => {
                  return (
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => removeSession(session)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      <span className="visually-hidden">
                        <FormattedMessage
                          id="session.remove"
                          defaultMessage="Remove Session"
                          description="Remove Session"
                        />
                      </span>
                    </button>
                  );
                }}
              />
              <ScheduleSingleSessionForm
                onHandleSubmit={(session) => addSession(session)}
              />
            </div>
            <div className="d-flex justify-content-center gap-2">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={newSessions.length === 0}
                onClick={() => dispatch({ type: 'reset', session: null })}
              >
                <FormattedMessage
                  id="sessions.cancel"
                  defaultMessage="Cancel"
                  description="Cancel"
                />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={newSessions.length === 0}
                onClick={saveSessions}
              >
                <FormattedMessage
                  id="sessions.save"
                  defaultMessage="Save Sessions"
                  description="Save Sessions"
                />
              </button>
            </div>
          </div>
        )}

        {/* existing sessions from server */}
        {sessions.isFetching ? (
          <Loading />
        ) : sessions.error ? (
          (sessions.error as any).response?.data !==
            'Course Sessions not Found' && (
            <GeneralError error={sessions.error} />
          )
        ) : (
          sessions.data && (
            <SessionsTable
              sessions={sessions.data}
              renderSessionActions={({ session }) => {
                return <ViewSessionLink session={session} />;
              }}
            />
          )
        )}
      </div>
    </div>
  );
}

export default SessionsScheduling;
