import { UserType } from '../../../const/user-type';

type UserRoleStrings = Record<UserType, string>;
// ids for translation
const UserTypeTranslationIds: UserRoleStrings = {
  [UserType.SUPER_ADMIN]: 'usertype.superadmin',
  [UserType.SPORT_MANAGER]: 'usertype.sportmanager',
  [UserType.ADMIN]: 'usertype.admin',
  [UserType.INSTRUCTOR]: 'usertype.instructor',
  [UserType.LEARNER]: 'usertype.learner',
  [UserType.GROUP_ADMIN]: 'usertype.groupadmin',
  [UserType.SCHOOL_COORDINATOR]: 'usertype.school_coordinator'
};

export default UserTypeTranslationIds;
