/* eslint-disable no-inner-declarations */
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import LearnersList from './LearnersList';
import LearnersListLink from './LearnerListActions';
import useOfflineLearnersTable from '../../hooks/useOfflineLearnersTable';
import { components } from '../../types/openapi/UserService';
import { FilteringQueryParams } from '../../const/filtering-query-params';

function LearnersListInstructorOffline() {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const todaysLearnersQueries: any = queryClient
    .getQueryCache()
    .findAll(['single-learner']);

  const todaysLearners = {
    numberOfUsers: todaysLearnersQueries.length,
    users: todaysLearnersQueries
      .map((query: any) => query.state.data)
      .filter(
        (learner: components['schemas']['UserDto'] | undefined) => !!learner
      )
  };

  const { todaysFilteredLearners } = useOfflineLearnersTable({
    learners: todaysLearners
  });

  const queryParamsLocation = FilteringQueryParams.Learners;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.learners',
            defaultMessage: 'Learners'
          })}
        </title>
      </Helmet>
      <LearnersList
        data={todaysFilteredLearners}
        error={false}
        isLoading={false}
        refetching={false}
        pagination={undefined}
        sorting={undefined}
        filtering={undefined}
        setPagination={undefined}
        setSorting={undefined}
        setFiltering={undefined}
        queryParamsLocation={queryParamsLocation}
        renderActions={({ row }) => {
          return <LearnersListLink selectedLearner={row.original} />;
        }}
      />
    </>
  );
}

export default LearnersListInstructorOffline;
