import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import { components } from '../../types/openapi/CourseService';
import localiseRoutePath from '../../utils/localiseRoutePath';

function ResourcesLink({
  objective
}: {
  objective:
    | components['schemas']['ObjectiveDto']
    | components['schemas']['ObjectiveSlimDto'];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  return (
    <Link
      to={`/${displayLocale}/${localiseRoutePath(AppRoute.Resources)}/${
        objective.stage?.frameworkVersion?.frameworkId
      }/${localiseRoutePath(AppRoute.Versions)}/${
        objective.stage?.frameworkVersionId
      }/${localiseRoutePath(AppRoute.Stages)}/${
        objective.stageId
      }/${localiseRoutePath(AppRoute.InstructorViewObjectives)}/${
        objective.objectiveId
      }`}
      className={`btn btn-chevron mt-4 ${
        appMode === AppMode.OFFLINE ? 'disabled' : ''
      }`}
    >
      <FormattedMessage
        id="resources.view.button"
        defaultMessage="View resources"
        description="View resources"
      />
    </Link>
  );
}

export default ResourcesLink;
