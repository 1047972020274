import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/UserService';
import { components as courseComponents } from '../../types/openapi/CourseService';

function UserEmergencyDetailsTable({
  user
}: {
  user:
    | components['schemas']['UserDto']
    | courseComponents['schemas']['LearnerDto'];
}) {
  const isUserDto = (
    value:
      | courseComponents['schemas']['UserDto']
      | courseComponents['schemas']['LearnerDto']
  ): value is courseComponents['schemas']['UserDto'] => {
    return (
      (value as courseComponents['schemas']['UserDto']).emergencyContactName !==
      undefined
    );
  };
  return (
    <table className="table">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="name"
              defaultMessage="Name"
              description="Name"
            />
          </th>
          <td data-gdpr="true">
            {(isUserDto(user) && user.emergencyContactName) || (
              <span className="text-muted">
                <FormattedMessage
                  id="emergency_name.none"
                  defaultMessage="No Emergency Contact Name Provided"
                  description="No Emergency Contact Name Provided"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="phone_number"
              defaultMessage="Phone number"
              description="Phone number"
            />
          </th>
          <td data-gdpr="true">
            {(isUserDto(user) && user.emergencyPhone1) || (
              <span className="text-muted">
                <FormattedMessage
                  id="phone_number.none"
                  defaultMessage="No Phone Number Provided"
                  description="No Phone Number Provided"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="phone_number.second"
              defaultMessage="Second Phone number"
              description="Second Phone number"
            />
          </th>
          <td data-gdpr="true">
            {(isUserDto(user) && user.emergencyPhone2) || (
              <span className="text-muted">
                <FormattedMessage
                  id="phone_number.none"
                  defaultMessage="No Phone Number Provided"
                  description="No Phone Number Provided"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="medical-conditions"
              defaultMessage="Medical Conditions"
              description="Medical Conditions"
            />
          </th>
          <td data-gdpr="true">
            {user.medicalConditions || (
              <span className="text-muted">
                <FormattedMessage
                  id="medical-conditions.none"
                  defaultMessage="No Medical Conditions Provided"
                  description="No Medical Conditions Provided"
                />
              </span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default UserEmergencyDetailsTable;
