import { useQuery } from '@tanstack/react-query';
import groupKeys from '../query-keys/group-key-factory';
import {
  getProviderGroups,
  getProviderGroupsPaged
} from '../services/api/provider.service';

// hook for managing groups
const useGroups = ({
  providerId,
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  providerId: number | null | undefined;
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  if (!providerId) {
    throw Error('invalid provider id');
  }

  const groupsQuery = useQuery({
    queryKey: groupKeys.list(providerId, displayLocale),
    queryFn: () => getProviderGroups(providerId, displayLocale),
    refetchOnMount: true,
    enabled: !!providerId
  });

  const groupsListPagedQuery = useQuery({
    queryKey: groupKeys.groupList(
      providerId,
      displayLocale,
      pageIndex,
      pageSize,
      sorting,
      rawFiltering
    ),
    queryFn: () =>
      getProviderGroupsPaged(
        providerId,
        displayLocale,
        pageIndex,
        pageSize,
        sorting,
        rawFiltering
      ),
    refetchOnMount: true,
    enabled: !!providerId
  });

  return {
    groupsQuery,
    groupsListPagedQuery
  };
};

export default useGroups;
