/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
import React, { ReactNode, useMemo } from 'react';
import dayjs from 'dayjs';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table';

import { TIMEZONE, convertDateTime } from '../../../utils/date-time';
import UnsavedSession from '../scheduling/unsaved-session.type';

function SessionsTableForm({
  sessions,
  newestSessionId,
  renderSessionActions
}: {
  sessions: UnsavedSession[];
  newestSessionId: string | undefined;
  renderSessionActions?: ({
    session
  }: {
    session: UnsavedSession;
  }) => JSX.Element | ReactNode;
}) {
  const intl = useIntl();
  const sortedSessions = sessions.sort((a: UnsavedSession, b: UnsavedSession) =>
    dayjs(a.sessionStartTime).isAfter(dayjs(b.sessionStartTime)) ? 1 : -1
  );
  const columns = useMemo<ColumnDef<UnsavedSession>[]>(() => {
    const cols = [
      {
        id: 'date',
        header: intl.formatMessage({
          id: 'date',
          defaultMessage: 'Date',
          description: 'Date'
        }),
        cell: ({ row }: { row: any }) => {
          const session = row.original;
          const displayDate = convertDateTime(session.sessionStartTime);
          return (
            <FormattedDate value={displayDate.toDate()} timeZone={TIMEZONE} />
          );
        }
      },
      {
        id: 'startTme',
        header: intl.formatMessage({
          id: 'time.start',
          defaultMessage: 'Start Time',
          description: 'Start Time'
        }),
        cell: ({ row }: { row: any }) => {
          const session = row.original;
          const displayStartTime = convertDateTime(session.sessionStartTime);
          const startTimeAsDate = displayStartTime.toDate();
          return <FormattedTime value={startTimeAsDate} timeZone={TIMEZONE} />;
        }
      },
      {
        id: 'endTime',
        header: intl.formatMessage({
          id: 'time.end',
          defaultMessage: 'End Time',
          description: 'End Time'
        }),
        cell: ({ row }: { row: any }) => {
          const session = row.original;
          const displayEndTime = convertDateTime(session.sessionEndTime);
          const endTimeAsDate = displayEndTime.toDate();
          return <FormattedTime value={endTimeAsDate} timeZone={TIMEZONE} />;
        }
      },

      {
        id: 'actions',
        header: intl.formatMessage({
          id: 'actions',
          defaultMessage: 'Actions',
          description: 'Actions'
        }),
        cell: ({ row }: { row: any }) => {
          const sessionActions =
            renderSessionActions &&
            renderSessionActions({
              session: row.original
            });
          return <div className="btn-group">{sessionActions}</div>;
        }
      }
    ];

    return cols;
  }, [sortedSessions]);

  const table = useReactTable({
    data: sortedSessions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false
  });

  return (
    <table className="table card-list-table">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          const { original } = row;
          return (
            <tr
              key={row.id}
              className={
                original.uuid === newestSessionId ? 'fade-out-highlight ' : ''
              }
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default SessionsTableForm;

SessionsTableForm.defaultProps = {
  renderSessionActions: () => {}
};
