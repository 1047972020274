// import dayjs plugins
// Note that the timezone plugin *is* imported here which differs from the original dayjsLocaliser
import { Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';

import { Culture, DateLocalizer, DateRange, Event } from 'react-big-calendar';
import { Unit, StartOfWeek } from 'date-arithmetic';

const weekRangeFormat = (
  range: DateRange,
  culture?: Culture,
  localizer?: DateLocalizer
): string => {
  return `${localizer?.format(range.start, 'MMMM DD', culture)} – 
  ${localizer?.format(
    range.end,
    localizer.eq(range.start, range.end, 'month') ? 'DD' : 'MMMM DD',
    culture
  )}`;
};

const dateRangeFormat = (
  range: DateRange,
  culture?: Culture,
  localizer?: DateLocalizer
) => {
  const rStart = localizer?.format(range.start, 'L', culture);
  const rEnd = localizer?.format(range.start, 'L', culture);
  return `${rStart} – ${rEnd}`;
};

const timeRangeFormat = (
  range: DateRange,
  culture?: Culture,
  localizer?: DateLocalizer
) => {
  const rStart = localizer?.format(range.start, 'LT', culture);
  const rEnd = localizer?.format(range.end, 'LT', culture);
  return `${rStart} – ${rEnd}`;
};

const timeRangeStartFormat = (
  range: DateRange,
  culture?: Culture,
  localizer?: DateLocalizer
) => `${localizer?.format(range.start, 'LT', culture)} – `;

const timeRangeEndFormat = (
  range: DateRange,
  culture?: Culture,
  localizer?: DateLocalizer
) => ` – ${localizer?.format(range.end, 'LT', culture)}`;

const timeGutterFormat = (
  date: Date,
  culture?: Culture,
  localizer?: DateLocalizer
) => {
  return ` – ${localizer?.format(date, 'LT', culture)}`;
};

export const formats = {
  dateFormat: 'DD',
  dayFormat: 'DD ddd',
  weekdayFormat: 'ddd',
  timeGutterFormat,
  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  monthHeaderFormat: 'MMMM YYYY',
  dayHeaderFormat: 'dddd MMM DD',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: 'ddd MMM DD',
  agendaTimeFormat: 'LT',
  agendaTimeRangeFormat: timeRangeFormat
};

function fixUnit(unit: string | undefined) {
  let datePart = unit ? unit.toLowerCase() : unit;
  if (datePart === 'FullYear') {
    datePart = 'year';
  } else if (!datePart) {
    return undefined;
  }
  return datePart as OpUnitType;
}

export default function dayjsLocalizerTimezone(
  dayjsLib: typeof import('dayjs')
) {
  // load dayjs plugins
  dayjsLib.extend(isBetween);
  dayjsLib.extend(isSameOrAfter);
  dayjsLib.extend(isSameOrBefore);
  dayjsLib.extend(localeData);
  dayjsLib.extend(localizedFormat);
  dayjsLib.extend(minMax);
  dayjsLib.extend(utc);

  const locale = (dj: { locale: (arg0: any) => any }, c: string | undefined) =>
    c ? dj.locale(c) : dj;

  // This localizer assumes that timezone is loaded
  const dayjs = dayjsLib;

  function getTimezoneOffset(date: any) {
    // ensures this gets cast to timezone
    return dayjs(date).toDate().getTimezoneOffset();
  }

  function calculateTimezone(dateTime: Dayjs) {
    return (dateTime.tz() as any).$x.$timezone ?? dayjsLib.tz.guess();
  }

  function getDstOffset(start: any, end: any) {
    // convert to dayjs, in case
    const st = dayjs(start);
    const ed = dayjs(end);

    // if not using the dayjs timezone plugin
    // if (!dayjs.tz) {
    //   return st.toDate().getTimezoneOffset() - ed.toDate().getTimezoneOffset();
    // }
    /**
     * If a default timezone has been applied, then
     * use this to get the proper timezone offset, otherwise default
     * the timezone to the browser local
     */
    const tzName = calculateTimezone(st);

    // invert offsets to be inline with moment.js
    const startOffset = -dayjs.tz(st.toISOString(), tzName).utcOffset();
    const endOffset = -dayjs.tz(ed.toISOString(), tzName).utcOffset();

    return startOffset - endOffset;
  }

  function getDayStartDstOffset(start: any) {
    const dayStart = dayjs(start).startOf('day');
    return getDstOffset(dayStart, start);
  }

  /* BEGIN localized date arithmetic methods with dayjs */
  function defineComparators(a: any, b: any, unit: any) {
    const datePart = fixUnit(unit);
    const dtA = datePart ? dayjs(a).startOf(datePart) : dayjs(a);
    const dtB = datePart ? dayjs(b).startOf(datePart) : dayjs(b);
    return { dtA, dtB, datePart };
  }
  // eslint-disable-next-line @typescript-eslint/default-param-last
  function startOf(date: Date, unit: Exclude<Unit, 'week'>): Date;
  // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
  function startOf(date: Date, unit: 'week', firstOfWeek: StartOfWeek): Date;
  function startOf(
    date: Date,
    unit: Exclude<Unit, 'week'> | 'week',
    // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
    firstOfWeek?: StartOfWeek
  ) {
    const datePart = fixUnit(unit);
    if (datePart) {
      const dayJsDate = dayjs(date);
      const startOfDate = dayJsDate.startOf(datePart);
      return startOfDate.toDate();
    }
    return dayjs(date).toDate();
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function endOf(date: Date, unit: 'week', firstOfWeek: StartOfWeek): Date;
  function endOf(date: Date, unit: Exclude<Unit, 'week'>): Date;
  function endOf(
    date: Date,
    unit: Exclude<Unit, 'week'> | 'week',
    // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
    firstOfWeek?: StartOfWeek
  ) {
    const datePart = fixUnit(unit);
    if (datePart) {
      return dayjs(date).endOf(datePart).toDate();
    }
    if (date) {
      return dayjs(date).toDate();
    }
    return dayjs().toDate();
  }

  // dayjs comparison operations *always* convert both sides to dayjs objects
  // prior to running the comparisons
  function eq(a: any, b: any, unit: string | undefined) {
    const { dtA, dtB, datePart } = defineComparators(a, b, unit);
    return dtA.isSame(dtB, datePart);
  }

  function neq(a: any, b: any, unit: any) {
    return !eq(a, b, unit);
  }

  function gt(a: any, b: any, unit: any) {
    const { dtA, dtB, datePart } = defineComparators(a, b, unit);
    return dtA.isAfter(dtB, datePart);
  }

  function lt(a: any, b: any, unit: any) {
    const { dtA, dtB, datePart } = defineComparators(a, b, unit);
    return dtA.isBefore(dtB, datePart);
  }

  function gte(date: Date, date2: Date, unit?: Unit) {
    const { dtA, dtB, datePart } = defineComparators(date, date2, unit);
    return dtA.isSameOrBefore(dtB, datePart);
  }

  function lte(date: Date, date2: Date, unit?: Unit) {
    const { dtA, dtB, datePart } = defineComparators(date, date2, unit);
    return dtA.isSameOrBefore(dtB, datePart);
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function inRange(day: any, min: any, max: any, unit = 'day') {
    const datePart = fixUnit(unit);
    const djDay = dayjs(day);
    const djMin = dayjs(min);
    const djMax = dayjs(max);
    return djDay.isBetween(djMin, djMax, datePart, '[]');
  }

  function min(dateA: any, dateB: any) {
    const dtA = dayjs(dateA);
    const dtB = dayjs(dateB);
    const minDt = dayjsLib.min(dtA, dtB);
    return minDt.toDate();
  }

  function max(dateA: any, dateB: any) {
    const dtA = dayjs(dateA);
    const dtB = dayjs(dateB);
    const maxDt = dayjsLib.max(dtA, dtB);
    return maxDt.toDate();
  }

  function merge(date: any, time: any) {
    if (!date && !time) return null;

    const tm = dayjs(time).format('HH:mm:ss');
    const dt = dayjs(date).startOf('day').format('MM/DD/YYYY');
    // We do it this way to avoid issues when timezone switching
    return dayjsLib(`${dt} ${tm}`, 'MM/DD/YYYY HH:mm:ss').toDate();
  }

  function add(date: any, adder: number, unit?: string) {
    if (unit) {
      const datePart = fixUnit(unit) as ManipulateType;
      if (datePart) {
        return dayjs(date).add(adder, datePart).toDate();
      }
      return dayjs(date).add(adder).toDate();
    }
    return dayjs(date).add(adder).toDate();
  }

  function range(start: any, end: any, unit = 'day') {
    const datePart = fixUnit(unit);
    // because the add method will put these in tz, we have to start that way
    let current = dayjs(start).toDate();
    const days = [];

    while (lte(current, end)) {
      days.push(current);
      current = add(current, 1, datePart);
    }

    return days;
  }

  function ceil(date: Date, unit: string) {
    const datePart = fixUnit(unit) || (unit as Unit);
    const floor = startOf(date, datePart as any);

    return eq(floor, date, datePart) ? floor : add(floor, 1, datePart);
  }

  function diff(a: any, b: any, unit = 'day') {
    const datePart = fixUnit(unit);
    // don't use 'defineComparators' here, as we don't want to mutate the values
    const dtA = dayjs(a);
    const dtB = dayjs(b);
    return dtB.diff(dtA, datePart);
  }

  function minutes(date: Date): number;
  function minutes(date: Date, value: number): Date;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function minutes(date: Date, value?: number): number | Date {
    const dt = dayjs(date);
    return dt.minute();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function firstOfWeek(culture: Culture) {
    const instanceLocaleData = dayjs().localeData();
    return instanceLocaleData ? instanceLocaleData.firstDayOfWeek() : 0;
  }

  function firstVisibleDay(date: any) {
    return dayjs(date).startOf('month').startOf('week').toDate();
  }

  function lastVisibleDay(date: any) {
    return dayjs(date).endOf('month').endOf('week').toDate();
  }

  function visibleDays(date: any) {
    let current = firstVisibleDay(date);
    const last = lastVisibleDay(date);
    const days = [];

    while (lte(current, last)) {
      days.push(current);
      current = add(current, 1, 'd');
    }

    return days;
  }
  /* END localized date arithmetic methods with dayjs */

  /**
   * Moved from TimeSlots.js, this method overrides the method of the same name
   * in the localizer.js, using dayjs to construct the js Date
   * @param {Date} dt - date to start with
   * @param {Number} minutesFromMidnight
   * @param {Number} offset
   * @returns {Date}
   */
  function getSlotDate(
    date: Date,
    minutesFromMidnight: number,
    offset: number
  ) {
    const dayJsDate = dayjs(date);
    const startOfDate = dayJsDate.startOf('day');
    const minuteOfDate = startOfDate.minute(minutesFromMidnight + offset);
    return minuteOfDate.toDate();
  }

  // dayjs will automatically handle DST differences in it's calculations
  function getTotalMin(dateA: Date, dateB: Date) {
    return diff(dateA, dateB, 'minutes');
  }

  function getMinutesFromMidnight(start: any) {
    const dayStart = dayjs(start).startOf('day');
    const day = dayjs(start);
    const dayDiff = day.diff(dayStart, 'minutes');
    const startOffset = getDayStartDstOffset(start);
    return dayDiff + startOffset;
  }

  // These two are used by DateSlotMetrics
  function continuesPrior(start: any, first: any) {
    const djStart = dayjs(start);
    const djFirst = dayjs(first);
    return djStart.isBefore(djFirst, 'day');
  }

  function continuesAfter(start: any, end: any, last: any): boolean {
    const djEnd = dayjs(end);
    const djLast = dayjs(last);
    return djEnd.isSameOrAfter(djLast, 'minutes');
  }

  // These two are used by eventLevels
  function sortEvents(eventA: Event, eventB: Event) {
    if (eventA.start && eventA.end && eventB.start && eventB.end) {
      const startSort = dayjs(startOf(eventA.start, 'day')).isBefore(
        startOf(eventB.start, 'day')
      );

      const startSortMinutes = dayjs(startOf(eventA.start, 'minutes')).isBefore(
        startOf(eventB.start, 'minutes')
      );

      const endSortMinutes = dayjs(startOf(eventA.end, 'minutes')).isBefore(
        startOf(eventB.end, 'minutes')
      );

      const durA = diff(eventA, ceil(eventA.end, 'day'), 'day');

      const durB = diff(eventA, ceil(eventB.end, 'day'), 'day');

      return (
        startSort || // sort by start Day first
        !!(Math.max(durB, 1) - Math.max(durA, 1)) || // events spanning multiple days go first
        (!!eventB.allDay && !!eventA.allDay) || // then allDay single day events
        startSortMinutes || // then sort by start time *don't need dayjs conversion here
        endSortMinutes // then sort by end time *don't need dayjs conversion here either
      );
    }
    return false;
  }

  function inEventRange({
    event,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    range
  }: {
    event: Event;
    range: DateRange;
  }): boolean {
    const startOfDay = dayjs(event.start).startOf('day');
    const eEnd = dayjs(event.end);
    const rStart = dayjs(range.start);
    const rEnd = dayjs(range.end);

    const startsBeforeEnd = startOfDay.isSameOrBefore(rEnd, 'day');
    // when the event is zero duration we need to handle a bit differently
    const sameMin = !startOfDay.isSame(eEnd, 'minutes');
    const endsAfterStart = sameMin
      ? eEnd.isAfter(rStart, 'minutes')
      : eEnd.isSameOrAfter(rStart, 'minutes');

    return startsBeforeEnd && endsAfterStart;
  }

  function isSameDate(date1: any, date2: any) {
    const dt = dayjs(date1);
    const dt2 = dayjs(date2);
    return dt.isSame(dt2, 'day');
  }

  /**
   * This method, called once in the localizer constructor, is used by eventLevels
   * 'eventSegments()' to assist in determining the 'span' of the event in the display,
   * specifically when using a timezone that is greater than the browser native timezone.
   * @returns number
   */
  // function browserTZOffset() {
  //   /**
  //    * Date.prototype.getTimezoneOffset horrifically flips the positive/negative from
  //    * what you see in it's string, so we have to jump through some hoops to get a value
  //    * we can actually compare.
  //    */
  //   const dt = new Date();
  //   const neg = /-/.test(dt.toString()) ? '-' : '';
  //   const dtOffset = dt.getTimezoneOffset();
  //   const comparator = Number(`${neg}${Math.abs(dtOffset)}`);
  //   // dayjs correctly provides positive/negative offset, as expected
  //   const mtOffset = dayjs().utcOffset();
  //   return mtOffset > comparator ? 1 : 0;
  // }

  // Check if it is an all day event
  function startAndEndAreDateOnly(dateA: Date, dateB: Date) {
    const dayStart = dayjs(dateA).startOf('day');
    const eventStartIsDayStart = eq(dayStart, dateA, 'minute');
    const dayEnd = dayjs(dateA).startOf('day');
    const eventEndIsDayEnd = eq(dayEnd, dateB, 'minute');

    return eventStartIsDayStart && eventEndIsDayEnd;
  }

  const startOfWeek = 0 as StartOfWeek;
  const segmentOffset = 0;

  return new DateLocalizer({
    formats,
    startOfWeek,
    segmentOffset,
    startAndEndAreDateOnly,
    firstOfWeek,
    firstVisibleDay,
    lastVisibleDay,
    visibleDays,

    format(value, format, culture) {
      const valueAsDate = new Date(value);
      const actualCulture = culture === 'en' ? 'en-GB' : culture;
      const timeZone = calculateTimezone(dayjs(valueAsDate));
      const shortTime = new Intl.DateTimeFormat(culture, {
        timeStyle: 'short',
        timeZone
      });
      const shortDate = new Intl.DateTimeFormat(culture, {
        dateStyle: 'short'
      });
      switch (format) {
        case 'MMMM DD':
          return new Intl.DateTimeFormat(culture, {
            month: 'long',
            day: 'numeric',
            timeZone
          }).format(valueAsDate);
          break;
        case 'MMMM YYYY':
          return new Intl.DateTimeFormat(culture, {
            month: 'long',
            year: 'numeric',
            timeZone
          }).format(valueAsDate);
          break;
        case 'DD ddd':
          return new Intl.DateTimeFormat(culture, {
            weekday: 'short',
            day: 'numeric',
            timeZone
          }).format(valueAsDate);
          break;
        case 'dddd MMM DD':
        case 'ddd MMM DD':
          return new Intl.DateTimeFormat(actualCulture, {
            weekday: 'long',
            day: 'numeric',
            month: 'short',
            timeZone
          }).format(valueAsDate);
          break;
        case 'ddd':
          return new Intl.DateTimeFormat(culture, {
            weekday: 'short',
            timeZone
          }).format(valueAsDate);
          break;
        case 'dd':
          return new Intl.DateTimeFormat(culture, {
            weekday: 'narrow',
            timeZone
          }).format(valueAsDate);
          break;
        case 'ddd D':
          return new Intl.DateTimeFormat(culture, {
            weekday: 'short',
            day: '2-digit',
            timeZone
          }).format(valueAsDate);
          break;
        case 'L':
          return shortDate.format(valueAsDate);
          break;
        case 'hh:mm a':
          return shortTime.format(valueAsDate);
        case 'LT':
          return shortTime.format(valueAsDate);

        default:
          // DD
          return locale(dayjs(value), culture).format(format);
      }
    },

    lt,
    lte,
    gt,
    gte,
    eq,
    neq,
    merge,
    inRange,
    startOf,
    endOf,
    range,
    add,
    diff,
    ceil,
    min,
    max,
    minutes,
    getSlotDate,
    getTimezoneOffset,
    getDstOffset,
    getTotalMin,
    getMinutesFromMidnight,
    continuesPrior,
    continuesAfter,
    sortEvents,
    inEventRange,
    isSameDate
  });
}
