import { useQuery } from '@tanstack/react-query';
import { createStripeUrlForPayment } from '../services/api/course.service';
import { appStrings } from '../modules/i18n';
import { AppLanguage, AppRoute } from '../const';

const useStripeUrlForPayment = ({
  courseId,
  courseSessionId,
  userId,
  providerId,
  startDate,
  displayLocale
}: {
  courseId: number;
  courseSessionId: number;
  userId: number;
  providerId: number;
  startDate: string | undefined;
  displayLocale: string | undefined;
}) => {
  const baseUrl = window.location.toString().split(/\/(en|cy)/)[0];

  const stripePaymentUrlQuery = useQuery({
    queryKey: [
      'stripe-payment-url',
      providerId,
      courseId,
      courseSessionId,
      `start-date-${startDate}.`,
      displayLocale
    ],
    queryFn: () =>
      createStripeUrlForPayment({
        courseSessionId,
        courseId,
        userId,
        providerId,
        char22Descriptor: `start-date-${startDate}.`,
        frontEndHost: `${baseUrl}/${displayLocale?.toLowerCase()}/${
          appStrings[displayLocale as AppLanguage][AppRoute.Payment]
        }`,
        langCode: displayLocale
      }),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 2, // 2 minutes
    enabled: !!startDate
  });

  return {
    stripePaymentUrlQuery
  };
};

export default useStripeUrlForPayment;
