import React from 'react';
import { useNetworkState } from '@uidotdev/usehooks';
import { FormattedMessage } from 'react-intl';

function DiagnosticInformation() {
  const network = useNetworkState();
  return (
    <div className="card border-0 rounded-top-right-lg">
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="offline.sync.diagnostics"
            defaultMessage="Diagnostic Information"
            description="Diagnostic Information"
          />
        </h2>
      </div>
      <div className="card-body">
        <table className="table table-sm">
          <tbody>
            {Object.keys(network).map((key: string) => {
              return (
                <tr key={key} className={key}>
                  <th>{key}</th>
                  <td>{`${network[key as keyof typeof network]}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DiagnosticInformation;
