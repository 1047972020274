import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ResourceType } from '../../const/resource-type';
import { ObjectiveUserStars } from '../../types/ObjectiveUserStars.type';
import { ObjectiveWithStars } from '../../types/ObjectiveWithStars.type';
import { components } from '../../types/openapi/CourseService';
import Objective from '../courses/sessions/objectives/Objective';
import useResources from '../../hooks/useResources';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function ObjectiveSnake({
  objectives,
  course,
  userId,
  userStars,
  renderStarElement,
  renderLinkElement
}: {
  objectives: components['schemas']['ObjectiveDto'][];
  course: components['schemas']['CourseDto'];
  userId: number;
  userStars?: ObjectiveUserStars[] | null;
  renderStarElement: (props: {
    userStarValue: number;
    inputStarValue: number;
    objective: ObjectiveWithStars;
  }) => JSX.Element | ReactNode;
  renderLinkElement?: (props: {
    objective:
      | components['schemas']['ObjectiveDto']
      | components['schemas']['ObjectiveSlimDto'];
  }) => JSX.Element | ReactNode;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [objectivesWithStars, setObjectivesWithStars] = useState<
    ObjectiveWithStars[] | null
  >();

  const { resourcesQuery } = useResources({
    frameworkVersionId: course.frameworkVersionId,
    displayLocale
  });

  useEffect(() => {
    if (objectives && resourcesQuery.data) {
      const trophies = resourcesQuery.data.filter(
        (resource) => resource.resourceTypeId === ResourceType.TROPHY
      );
      const newObjectives = objectives.map(
        (objective: components['schemas']['ObjectiveDto']) => {
          // resources
          const img = trophies.find(
            (trophy: any) => trophy.objectiveId === objective.objectiveId
          );
          const imgSrc = img
            ? `${process.env.REACT_APP_BLOB_BASE_URL}/${img.resourceString}?${img.sasToken}`
            : '';

          // extend the objectives object with stars and user data
          const objectiveWithStars: ObjectiveWithStars = {
            ...objective,
            frameworkVersionId: course.frameworkVersionId,
            userId,
            stars: userStars
              ? userStars.find(
                  (userStar) => userStar.objectiveId === objective.objectiveId
                )?.numberOfStars || 0
              : 0,
            imgSrc
          };
          return objectiveWithStars;
        }
      );
      setObjectivesWithStars(newObjectives);
    }
  }, [objectives, resourcesQuery.data, userStars]);
  return (
    <ol className="snake-ol">
      {objectivesWithStars ? (
        objectivesWithStars.map((objectiveWithStars: ObjectiveWithStars) => {
          return (
            <Objective
              key={objectiveWithStars.objectiveId}
              objective={objectiveWithStars}
              renderStarElement={renderStarElement}
              renderLinkElement={renderLinkElement}
            />
          );
        })
      ) : (
        <FormattedMessage
          id="objectives.learner.none"
          defaultMessage="No objectives available for this learner"
          description="No objectives available for this learner"
        />
      )}
    </ol>
  );
}

export default ObjectiveSnake;

ObjectiveSnake.defaultProps = {
  userStars: 0,
  renderLinkElement: () => {}
};
