import { useQuery } from '@tanstack/react-query';
import { getStarsForUsersCourseSession } from '../services/api/course.service';
import userObjectivesKeys from '../query-keys/user-objectives-key-factory';
import { components } from '../types/openapi/CourseService';

// hook for getting all the stars for particular session
const useStarsForUsersCourseSession = ({ session }: { session: any }) => {
  // the session objective must have the members populated - they aren't always included from the get sessions API call
  // so be careful as you may have to add them on the FE
  const userIds =
    session.members
      ?.map((user: components['schemas']['UserDto']) => user.userId)
      .filter((item: any): item is number => !!item) || [];

  // get all the stars for all users on a session
  const starsForUsersCourseSessionQuery = useQuery({
    queryKey: userObjectivesKeys.starsForCourseSessionUsers(
      session.courseId,
      Number(session.courseSessionId)
    ),
    queryFn: () =>
      getStarsForUsersCourseSession(
        session.courseId,
        Number(session.courseSessionId),
        userIds
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  // const starsForIndividualUser = starsForUsersCourseSessionQuery.data?.filter(courseUserStars => courseUserStars.userId === userId);

  return {
    starsForUsersCourseSessionQuery
  };
};

export default useStarsForUsersCourseSession;
