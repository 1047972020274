import { flexRender, Row, Table } from '@tanstack/react-table';
import React from 'react';

function TableBody({
  table,
  refetching,
  dataId
}: {
  table: Table<any>;
  refetching: boolean;
  dataId: string;
}) {
  return (
    <tbody>
      {table.getRowModel().rows.map((row: Row<any>) => {
        return (
          <tr key={row.id} data-id={row.original[dataId]}>
            {row.getVisibleCells().map((cell) => {
              return (
                <td
                  className={refetching ? 'refetching-table-cell' : ''}
                  key={cell.id}
                  data-title={cell.column.columnDef.header}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
export default TableBody;
