import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';

import { GeneralError } from '../common';
import { ModalContext } from '../../modules/modal/ModalProvider';
import { components } from '../../types/openapi/UserService';
import { components as courseComponents } from '../../types/openapi/CourseService';
import useFrameworkProgress from '../../hooks/useFrameworkProgress';

function UndoStageCompletionBtn({
  frameworkId,
  frameworkVersionId,
  learner,
  stages,
  displayLocale,
  lastCompletedStageIndex,
  currentStage
}: {
  frameworkId: number;
  frameworkVersionId: number | undefined;
  learner:
    | components['schemas']['UserDto']
    | courseComponents['schemas']['LearnerDto'];
  stages: components['schemas']['StageDto'][];
  displayLocale: string;
  lastCompletedStageIndex: number | undefined;
  // currentStage may be null if the user has has finished all stages of a framework
  currentStage: components['schemas']['StageDto'] | null;
}) {
  if (!frameworkVersionId) return <GeneralError message="no framework id" />;
  const { userId: learnerId } = learner;

  if (!learnerId) return <GeneralError />;
  const intl = useIntl();
  const modalCtx = useContext(ModalContext);

  const { modal } = modalCtx;

  const { updateStageOrder, setStageOrder } = useFrameworkProgress({
    frameworkVersionId,
    learnerId: Number(learnerId),
    displayLocale
  });

  const sendMutation = () => {
    if (!lastCompletedStageIndex) return;
    // if there is not current stage, it is because the learner is on the last stage
    const prevStageOrder = currentStage
      ? currentStage.stageOrder - 1
      : stages[stages.length - 1].stageOrder;

    setStageOrder(prevStageOrder);
    updateStageOrder.mutate(
      {
        frameworkVersionId,
        frameworkId,
        learnerId,
        stageId: stages[lastCompletedStageIndex - 1].stageId,
        isProgress: false
      },
      {
        onSuccess: () => {
          toast.success(
            intl.formatMessage({
              id: 'progress.undo.success',
              defaultMessage: 'Undo progression was successful'
            })
          );
        }
      }
    );
  };

  const openModal = () => {
    const header = intl.formatMessage(
      {
        id: 'learner.undoProgress.confirm.header',
        defaultMessage: 'Undo Stage: {name}',
        description: 'Undo Stage completion Confirm Modal Header'
      },
      { name: learner.fullname || learner.username }
    );
    const modalContent = intl.formatMessage({
      id: 'learner.undoProgress.confirm.body',
      defaultMessage: 'Are you sure you want to undo this stage?',
      description: 'Undo stage completion Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'learner.undoProgress.confirm.button',
      defaultMessage: 'Undo stage',
      description: 'Undo stage completion Confirm Button'
    });

    modal(modalContent, {
      confirm: async () => {
        sendMutation();
      },
      header,
      confirmText
    });
  };

  return (
    <button
      className="btn btn-circle-primary"
      type="button"
      onClick={openModal}
      disabled={lastCompletedStageIndex === 0}
    >
      <FontAwesomeIcon icon={faCircleMinus} />
      <span className="visually-hidden">Minus - undo stage completion</span>
    </button>
  );
}

export default UndoStageCompletionBtn;
