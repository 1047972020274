const passesCharacterValidationRules = (password: string) => {
  const hasLowerCase = /\p{Ll}/u.test(password);
  const hasUpperCase = /\p{Lu}/u.test(password);
  const hasNumber = /\p{N}/u.test(password);
  const hasPunctuationOrSymbol =
    /[\p{P}\p{Pc}\p{Pd}\p{Pe}\p{Pf}\p{Pi}\p{Po}\p{Ps}\p{Sc}\p{Sk}\p{Sm}]/u.test(
      password
    );

  return (
    Number(hasLowerCase) +
      Number(hasUpperCase) +
      Number(hasNumber) +
      Number(hasPunctuationOrSymbol) >=
    3
  );
};

export default passesCharacterValidationRules;
