import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { components } from '../../types/openapi/CourseService';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useUserStarsForFramework from '../../hooks/useUserStarsForFramework';
import { GeneralError } from '../common';
import FrameworkObjectiveSnake from './framework/FrameworkObjectiveSnake';
import InputStar from './InputStar';
import ReadOnlyStar from './ReadOnlyStar';
import useUserObjectiveStarsNoSession from '../../hooks/useUserObjectiveStarsNoSession';
import ResourcesLink from './ResourcesLink';
import useFrameworkProgress from '../../hooks/useFrameworkProgress';

function StageObjectiveUserStars({
  frameworkVersionId,
  userId,
  objectives,
  isEditable
}: {
  frameworkVersionId: number;
  userId: number;
  objectives: components['schemas']['ObjectiveDto'][];
  isEditable: boolean;
}) {
  if (!frameworkVersionId) {
    return <GeneralError />;
  }

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { courseId } = useParams();

  const { allUserStarsArray } = useUserStarsForFramework({
    frameworkVersionId,
    userId,
    displayLocale
  });

  const { updateUserObjectiveStarsNoSession } = useUserObjectiveStarsNoSession({
    courseId: Number(courseId),
    userId,
    frameworkVersionId
  });

  const { currentStageId } = useFrameworkProgress({
    frameworkVersionId,
    learnerId: userId,
    displayLocale
  });

  if (isEditable && courseId) {
    const handleChange = ({
      objectiveId,
      star,
      stageId
    }: {
      objectiveId: number;
      star: number;
      stageId: number;
    }) => {
      if (allUserStarsArray) {
        const objectiveUserStars = allUserStarsArray.find(
          (userStar) => userStar.objectiveId === objectiveId
        );
        // remove star if selecting 1 and already selected
        let newStar = star;
        if (objectiveUserStars?.numberOfStars === 1 && star === 1) {
          newStar = 0;
        }
        updateUserObjectiveStarsNoSession.mutate({
          objectiveId,
          numberOfStars: newStar,
          stageId
        });
      }
    };

    return (
      <FrameworkObjectiveSnake
        objectives={objectives}
        userStars={allUserStarsArray}
        frameworkVersionId={frameworkVersionId}
        userId={userId}
        renderStarElement={({ userStarValue, inputStarValue, objective }) => {
          return (
            objective.objectiveId &&
            objective.stageId && (
              <InputStar
                userStarValue={userStarValue}
                inputStarValue={inputStarValue}
                objectiveId={objective.objectiveId}
                objectiveStageId={objective.stageId}
                userCurrentStageId={currentStageId}
                userId={userId}
                handleChange={({ objectiveId, star }) =>
                  objective.stageId &&
                  handleChange({
                    objectiveId,
                    star,
                    stageId: objective.stageId
                  })
                }
              />
            )
          );
        }}
        renderLinkElement={({ objective }) => {
          return <ResourcesLink objective={objective} />;
        }}
      />
    );
  }

  return (
    <FrameworkObjectiveSnake
      objectives={objectives}
      userStars={allUserStarsArray}
      frameworkVersionId={frameworkVersionId}
      userId={userId}
      renderStarElement={({ userStarValue, inputStarValue }) => {
        return (
          <ReadOnlyStar usersStar={userStarValue} starValue={inputStarValue} />
        );
      }}
      renderLinkElement={({ objective }) => {
        return <ResourcesLink objective={objective} />;
      }}
    />
  );
}

export default StageObjectiveUserStars;
