import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';

import CreateGroup from '../components/groups/CreateGroup';
import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import ViewGroup from '../components/groups/ViewGroup';
import CreateGroupAdmin from '../components/groups/admins/CreateGroupAdmin';
import EditGroupAdmin from '../components/groups/admins/EditGroupAdmin';
import EditGroup from '../components/groups/EditGroup';
import AddLearnerToGroup from '../components/groups/AddLearnerToGroup';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import ManageGroups from '../components/groups/ManageGroups';

export default function groupRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Groups],
    element: (
      <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="groups.breadcrumb"
          defaultMessage="Groups"
          description="Groups"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageGroups />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.CreateGroup],
        element: <CreateGroup />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="group.create"
              defaultMessage="Create Group"
              description="Create Group"
            />
          )
        }
      },
      {
        path: appStrings[langVal][AppRoute.CreateGroupAdmin],
        element: <CreateGroupAdmin />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="group.admin.create"
              defaultMessage="Create Group Admin"
              description="Create Group Admin"
            />
          )
        }
      },
      {
        path: `${appStrings[langVal][AppRoute.EditGroupAdmin]}/:groupAdminId`,
        element: <EditGroupAdmin />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="group.admin.edit.header"
              defaultMessage="Edit Group Admin"
              description="Edit Group Admin"
            />
          )
        }
      },
      {
        path: ':groupId',
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="group"
              defaultMessage="Group"
              description="Group"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ViewGroup />,
            errorElement: <ErrorPage />
          },
          {
            path: appStrings[langVal][AppRoute.EditGroup],
            element: <EditGroup />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="group.edit.header"
                  defaultMessage="Edit Group"
                  description="Edit Group"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.AddLearnerToGroup],
            element: <AddLearnerToGroup />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="group.add.learner"
                  defaultMessage="Add learner"
                  description="Add learner"
                />
              )
            }
          }
        ]
      }
    ]
  };
  return routes;
}
