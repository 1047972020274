import React, { lazy } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import PublicPageLayout from '../components/layout/PublicPageLayout';

const ResetPasswordWrapper = lazy(
  () => import('../components/auth/password-reset/ResetPasswordWrapper')
);

function ResetPasswordPage() {
  const intl = useIntl();
  return (
    <PublicPageLayout
      header={
        <FormattedMessage id="password.set" defaultMessage="Set Password" />
      }
    >
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.reset_password',
            defaultMessage: 'Reset password'
          })}
        </title>
      </Helmet>
      <ResetPasswordWrapper />
    </PublicPageLayout>
  );
}

export default ResetPasswordPage;
