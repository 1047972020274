import React from 'react';

function PrefetchStars() {
  return (
    <>
      <div className="readonly-star visually-hidden readonly-star--unfilled" />
      <div className="readonly-star visually-hidden" />
    </>
  );
}

export default PrefetchStars;
