import { useQuery } from '@tanstack/react-query';
import { UserType } from '../const/user-type';
import groupKeys from '../query-keys/group-key-factory';
import {
  getUsersForProviderCentreOfUserType,
  getUsersForProviderOfUserType
} from '../services/api/user.service';
import { FilteringObject } from '../const/filtering-object';

// hook for managing group admins
const useGroupAdmins = ({
  providerId,
  centreId,
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  providerId: number | null | undefined;
  centreId: number | null | undefined;
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  if (!providerId) {
    throw Error('invalid group id');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  // eslint-disable-next-line prefer-const
  let filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const groupAdminsQuery = useQuery({
    queryKey: groupKeys.groupAdmins(providerId, displayLocale, {
      pageIndex,
      pageSize,
      sorting,
      filtering
    }),
    queryFn: () => {
      if (centreId) {
        return getUsersForProviderCentreOfUserType(
          providerId,
          centreId,
          UserType.GROUP_ADMIN,
          pageIndex,
          pageSize,
          sorting,
          filtering,
          displayLocale
        );
      }
      return getUsersForProviderOfUserType(
        providerId,
        UserType.GROUP_ADMIN,
        pageIndex,
        pageSize,
        sortField,
        sortDirection,
        filtering,
        displayLocale
      );
    },
    refetchOnMount: true
  });

  return {
    groupAdminsQuery
  };
};

export default useGroupAdmins;
