import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ProgressCircle from './ProgressCircle';

function ProgressCircleWrapper({
  numberOfStages,
  lastCompletedStageIndex,
  isFinished
}: {
  numberOfStages: number;
  lastCompletedStageIndex: number;
  isFinished: boolean;
}) {
  const [strokeDashOffset, setStrokeDashOffset] = useState<number>();

  const displayStage =
    lastCompletedStageIndex === numberOfStages
      ? lastCompletedStageIndex
      : lastCompletedStageIndex + 1;

  useEffect(() => {
    if (numberOfStages) {
      const complete =
        lastCompletedStageIndex === numberOfStages
          ? lastCompletedStageIndex
          : lastCompletedStageIndex + 1;
      const progressPercentage = ((complete / numberOfStages) * 100).toString(
        10
      );
      const initialDashOffset = 565;
      setStrokeDashOffset(
        initialDashOffset -
          (initialDashOffset * Number(progressPercentage)) / 100
      );
    }
  }, [numberOfStages, lastCompletedStageIndex]);

  const turns = 1 / numberOfStages;

  return isFinished ? (
    <div className="d-flex flex-column align-items-center">
      <FontAwesomeIcon
        icon={faTrophy}
        className="fa-4x mb-3 color-brand-pink"
      />
      <p>
        <FormattedMessage
          id="framework.completed"
          defaultMessage="Framework completed"
          description="Framework completed"
        />
      </p>
    </div>
  ) : (
    <ProgressCircle
      displayStage={displayStage}
      strokeDashOffset={strokeDashOffset}
      numberOfStages={numberOfStages}
      turns={turns}
    />
  );
}

export default ProgressCircleWrapper;
