import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUser } from '../services/api/user.service';
import userKeys from '../query-keys/user-key-factory';
import { components } from '../types/openapi/UserService';

const useUser = ({
  userId,
  displayLocale
}: {
  userId: number;
  displayLocale: string | undefined;
}) => {
  const queryClient = useQueryClient();

  if (!userId) {
    throw Error('invalid user id');
  }

  const userQuery = useQuery({
    queryKey: userKeys.user(userId, displayLocale),
    queryFn: () => getUser(Number(userId)),
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week - stays the same until manually invalidated or refreshed
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!userId
  });

  const username = queryClient.getQueryData<components['schemas']['UserDto']>(
    userKeys.user(userId, displayLocale)
  )?.username;

  return {
    userQuery,
    username
  };
};

export default useUser;
