import React from 'react';
import { faLock, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ObjectiveSnakeImage({
  imgSrc,
  isUnavailable,
  isLatest,
  isSmall
}: {
  imgSrc: string | undefined;
  isUnavailable?: boolean;
  isLatest?: boolean;
  isSmall?: boolean;
}) {
  return (
    <div
      className={`list-item-image ${isLatest ? 'trophy-snake__latest' : ''}`}
    >
      {imgSrc ? (
        <img alt="" src={imgSrc} />
      ) : isUnavailable ? (
        <FontAwesomeIcon icon={faLock} className="fa-2x text-primary" />
      ) : (
        <FontAwesomeIcon
          icon={faTrophy}
          className={`${!isSmall && 'fa-2x '} text-primary`}
        />
      )}
    </div>
  );
}

export default ObjectiveSnakeImage;

ObjectiveSnakeImage.defaultProps = {
  isUnavailable: false,
  isLatest: false,
  isSmall: false
};
