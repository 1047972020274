import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { Helmet } from 'react-helmet-async';

import GroupForm from './GroupForm';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import Loading from '../common/Loading';
import { AppLanguage, AppRoute } from '../../const';
import { GeneralError } from '../common';
import { createGroup } from '../../services/api/provider.service';
import { components } from '../../types/openapi/ProviderService';
import { appStrings } from '../../modules/i18n';
import useLearners from '../../hooks/useLearners';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import useGroupAdmins from '../../hooks/useGroupAdmins';
import groupKeys from '../../query-keys/group-key-factory';

function CreateGroup() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userProviderId = profile?.providerId;
  const navLinkGroups = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Groups]
  }`;

  const { groupAdminsQuery } = useGroupAdmins({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    displayLocale,
    pageIndex: null,
    pageSize: null,
    sorting: null,
    rawFiltering: null
  });

  const queryParamsLocation = FilteringQueryParams.GroupLearners;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  const { learnersListQuery } = useLearners({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: createNewGroup, isLoading } = useMutation(
    (group: components['schemas']['GroupDto']) =>
      createGroup(userProviderId, group, displayLocale),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data: any) => {
        toast.success(
          intl.formatMessage({
            id: 'group.create.success',
            defaultMessage: 'A new group has been created'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.list(profile?.providerId, displayLocale)
        });
        navigate(navLinkGroups);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.create.error',
            defaultMessage:
              'There was an error creating a group with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (group: any) => {
    const { name, description, groupAdmins, userGroupMembers } = group;
    const newGroup: any = {
      groupName: name,
      groupDescription: description,
      groupAdmins: groupAdmins.map((admin: any) => admin.value),
      learners: userGroupMembers.map((learner: any) => learner.userId)
    };
    createNewGroup(newGroup);
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.group.header.create_new',
            defaultMessage: 'Create new group'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="group.header.create_new"
            defaultMessage="Create New Group"
            description="Create New Group"
          />
        </h1>
      </div>

      <div className="card-body">
        {groupAdminsQuery.isFetching ? (
          <Loading />
        ) : groupAdminsQuery.error ? (
          (groupAdminsQuery.error as any)?.response?.status === 404 ? (
            <FormattedMessage
              id="group.admins.error.none"
              defaultMessage="No Group Admins found"
              description="No Group Admins found"
            />
          ) : (
            <GeneralError />
          )
        ) : (
          groupAdminsQuery.data &&
          learnersListQuery && (
            <GroupForm
              onSubmit={onSubmit}
              pagination={pagination}
              sorting={sorting}
              filtering={filtering}
              setPagination={setPagination}
              setSorting={setSorting}
              setFiltering={setFiltering}
              queryParamsLocation={queryParamsLocation}
              providerGroupAdmins={groupAdminsQuery.data.users}
              submitButtonMessage={
                <FormattedMessage
                  id="group.button.create_new"
                  defaultMessage="Create new group"
                  description="Create new group"
                />
              }
            />
          )
        )}
      </div>
    </div>
  );
}

export default CreateGroup;
