import React, { useContext } from 'react';
import useIncompleteObjectives from '../../../hooks/objective/useIncompleteObjectives';
import useFrameworkProgress from '../../../hooks/useFrameworkProgress';
import useLearner from '../../../hooks/useLearner';
import useStages from '../../../hooks/useStages';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { GeneralError, Loading } from '../../common';
import FrameworkProgress from '../../progress/framework/FrameworkProgress';

function OnlineFrameworkProgress({
  frameworkId,
  frameworkVersionId,
  userId
}: {
  frameworkId: number;
  frameworkVersionId: number;
  userId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { stagesQuery } = useStages({
    frameworkId,
    frameworkVersionId,
    displayLocale
  });

  const { learnerQuery: learner } = useLearner({
    userId: Number(userId),
    displayLocale
  });

  const { currentStageId } = useFrameworkProgress({
    frameworkVersionId,
    learnerId: userId,
    displayLocale
  });

  const { incompleteCount, userFrameworkStarsQuery } = useIncompleteObjectives({
    frameworkVersionId,
    userId,
    displayLocale,
    currentStageId
  });
  if (stagesQuery.data && learner.data)
    return (
      <FrameworkProgress
        stages={stagesQuery.data}
        currentStageId={currentStageId}
        frameworkId={frameworkId}
        frameworkVersionId={frameworkVersionId}
        learner={learner.data}
        incompleteCount={incompleteCount}
        queriesLoading={userFrameworkStarsQuery.isLoading}
      />
    );
  if (stagesQuery.error || learner.error) return <GeneralError />;
  return <Loading />;
}

export default OnlineFrameworkProgress;
