import { AppLanguage } from '../const';

const courseKeys = {
  // everything to do with courses
  all: (displayLocale: string | undefined) =>
    ['course', displayLocale || AppLanguage.English] as const,
  // list of all courses
  list: (
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    sortDirection: string | null | undefined,
    filtering: any
  ) =>
    [
      ...courseKeys.all(displayLocale || AppLanguage.English),
      pageIndex,
      pageSize,
      sorting,
      sortDirection,
      filtering,
      'list'
    ] as const,
  // count of the active courses
  count: (providerId: number, displayLocale: string | undefined) =>
    [
      ...courseKeys.all(displayLocale || AppLanguage.English),
      'provider',
      providerId,
      'count'
    ] as const,
  // list of courses for a super admin
  superAdminList: (
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    sortDirection: string | null,
    rawFiltering: any | null | undefined
  ) =>
    [
      'superAdmin',
      ...courseKeys.list(
        displayLocale || AppLanguage.English,
        pageIndex,
        pageSize,
        sorting,
        sortDirection,
        rawFiltering
      )
    ] as const,
  // list of courses for a particular instructor
  instructorList: (
    instructorId: number,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    sortDirection: string | null,
    rawFiltering: any | null | undefined
  ) =>
    [
      'instructor',
      instructorId,
      ...courseKeys.list(
        displayLocale || AppLanguage.English,
        pageIndex,
        pageSize,
        sorting,
        sortDirection,
        rawFiltering
      )
    ] as const,
  // list of courses for a particular provider
  providerList: (
    providerId: number,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    sortDirection: string | null,
    rawFiltering: any | null | undefined
  ) =>
    [
      'provider',
      providerId,
      ...courseKeys.list(
        displayLocale || AppLanguage.English,
        pageIndex,
        pageSize,
        sorting,
        sortDirection,
        rawFiltering
      )
    ] as const,
  // list of courses for a particular centre
  providerCentreList: (
    providerId: number,
    centreId: number,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    sortDirection: string | null,
    rawFiltering: any | null | undefined
  ) =>
    [
      'provider',
      providerId,
      'centre',
      centreId,
      ...courseKeys.list(
        displayLocale || AppLanguage.English,
        pageIndex,
        pageSize,
        sorting,
        sortDirection,
        rawFiltering
      )
    ] as const,

  // list of courses for a particular learner
  learnerList: (
    userId: number,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    sortDirection: string | null,
    filtering: any | null | undefined
  ) =>
    [
      'learner',
      userId,
      ...courseKeys.list(
        displayLocale || AppLanguage.English,
        pageIndex,
        pageSize,
        sorting,
        sortDirection,
        filtering
      )
    ] as const,

  // list of learners for a particular course
  learnersOnCourseList: (courseId: number, displayLocale: string | undefined) =>
    ['learnersOnCourse', courseId, displayLocale] as const,
  // a single course
  course: (courseId: number, displayLocale: string | undefined) =>
    [
      'single-course',
      ...courseKeys.all(displayLocale || AppLanguage.English),
      courseId
    ] as const,
  courseStages: (courseId: number, displayLocale: string | undefined) =>
    [
      ...courseKeys.all(displayLocale || AppLanguage.English),
      courseId,
      'courseStages'
    ] as const
};

export default courseKeys;
