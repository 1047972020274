import React, { useContext } from 'react';
import { faCircleNotch, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useProviderConfig from '../../hooks/useProviderConfig';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import LanguageSwitchNavDropdown from './header/LanguageSwitcherNavDropdown';

// after user logs in, check what languages are actually available in the provider
// for their selected profile
function LanguageSwitcherWrapper({ providerId }: { providerId: number }) {
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const { providerConfigQuery, providerLanguages } = useProviderConfig({
    providerId
  });

  if (providerConfigQuery.data) {
    // no switch if only 1 language or offline
    if (providerLanguages?.length > 1 && appMode !== AppMode.OFFLINE) {
      // if more than one language available and app is online then show switcher
      return (
        <LanguageSwitchNavDropdown providerLanguages={providerLanguages} />
      );
    }
    /* eslint-disable-next-line react/jsx-no-useless-fragment */
    return <></>;
  }

  if (providerConfigQuery.isLoading) {
    return <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />;
  }

  if (providerConfigQuery.isError) {
    return <FontAwesomeIcon icon={faWarning} className="fa-spin" />;
  }
  /* eslint-disable-next-line react/jsx-no-useless-fragment */
  return <></>;
}

export default LanguageSwitcherWrapper;
