/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';
import Select, { components } from 'react-select';

import { components as frameworkComponents } from '../../../types/openapi/FrameworkService';
import Asterisk from '../../Asterisk';
import InlineFormError from '../../ui/InlineFormError';

function NoOptionsMessage(props: any) {
  return (
    <components.NoOptionsMessage {...props}>
      <span>
        <FormattedMessage
          id="form.stage.options.none"
          defaultMessage="No options. Select a framework to see stages"
          description="No options. Select a framework to see stages"
        />{' '}
      </span>
    </components.NoOptionsMessage>
  );
}

function StageSelect({
  control,
  frameworkVersionId,
  stages,
  stageIds,
  errors
}: {
  control: Control<any>;
  frameworkVersionId: number;
  stages: frameworkComponents['schemas']['StageDto'][];
  stageIds: { stageId: number }[] | undefined;
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      description: string;
      frameworkVersionId: number;
      stageIds: {
        stageId: number;
      }[];
      centreId: never;
      facilityId: never;
      providerId: number;
      instructorId: number;
      totalNumberOfPeople: number;
      externalCourseId: string;
    }>
  >;
}) {
  const stageOptions = stages.map((stage) => {
    const { name, stageId } = stage;
    return {
      label: name,
      value: stageId
    };
  });
  return (
    <div className="mb-3" id="stage-selection">
      <label htmlFor="stageIds" className="form-label">
        <Asterisk />
        <FormattedMessage
          id="form.stage"
          defaultMessage="Stage(s)"
          description="Select from list of stages"
        />{' '}
      </label>
      <Controller
        control={control}
        name="stageIds"
        rules={{ required: true }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Select
            key={`stages_select_key__${frameworkVersionId}`}
            inputId="stageIds"
            isMulti
            aria-invalid={error ? 'true' : 'false'}
            components={{ NoOptionsMessage }}
            onChange={(event) =>
              onChange(
                event.map((option) => {
                  return {
                    stageId: option.value
                  };
                })
              )
            }
            placeholder={
              <FormattedMessage
                id="form.select_stage.placeholder"
                defaultMessage="Select a stage"
                description="Select from list of Stages"
              />
            }
            defaultValue={stageOptions.filter((option) =>
              stageIds?.find((stageId) => stageId.stageId === option.value)
            )}
            options={stageOptions}
          />
        )}
      />
      {errors.stageIds?.type === 'required' && (
        <InlineFormError
          message={
            <FormattedMessage
              id="form.stage.required"
              defaultMessage="At least one stage is required"
              description="At least one stage is required"
            />
          }
        />
      )}
    </div>
  );
}
export default StageSelect;
