/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { AuthContext } from '../../modules/auth/AuthProvider';
import isErrorTokenExpired from '../../utils/auth/isErrorTokenExpired';
import isRefreshErrorTokenExpired from '../../utils/auth/isRefreshErrorTokenExpired';

function GeneralError({
  message,
  error
}: {
  message?: string | React.ReactElement;
  error?: any;
}) {
  const authContext = useContext(AuthContext);
  const { handleLogout } = authContext;
  const intl = useIntl();

  const errMessage =
    typeof error?.response?.data === 'string' ? error?.response?.data : '';
  const expired = isErrorTokenExpired(errMessage);
  const refreshExpired = error && isRefreshErrorTokenExpired(error);
  // For a specific error that the token has expired - log the user out
  if (
    (error?.response?.status === 401 && expired) ||
    (error?.response?.status === 401 && refreshExpired)
  ) {
    const toastId = 'generalErrorToastId';
    console.error(error);
    toast.error(
      intl.formatMessage(
        {
          id: 'error.expired_token',
          defaultMessage: 'Your session has expired. Please log in again'
        },
        { autoClose: true }
      ),
      {
        delay: 200,
        toastId
      }
    );
    handleLogout();
    console.warn('user has been logged out');
    // navigate(loginUrl);
  }
  return (
    <section className="alert alert-warning">
      {errMessage || message || (
        <FormattedMessage
          id="error.content"
          defaultMessage="An error has occurred"
        />
      )}
    </section>
  );
}

export default GeneralError;

GeneralError.defaultProps = {
  message: '',
  error: undefined
};
