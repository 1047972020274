import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import MainContainer from '../components/layout/MainContainer';

import ProgressOverview from '../components/progress/ProgressOverview';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import ViewSession from '../components/courses/sessions/session/ViewSession';

export default function progressRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.LearnerProgress],
    element: (
      <ProtectedRoute allowedRoles={[UserType.LEARNER]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="progress"
          defaultMessage="Progress"
          description="Progress"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ProgressOverview />,
        errorElement: <ErrorPage />
      },
      {
        path: `${appStrings[langVal][AppRoute.Courses]}/:courseId/${
          appStrings[langVal][AppRoute.ViewSession]
        }/:sessionId/${appStrings[langVal][AppRoute.Learners]}/:learnerId`,
        element: <ViewSession />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="progress.session.learner"
              defaultMessage="Session Progress"
              description="Session Progress"
            />
          )
        }
      }
    ]
  };
  return routes;
}
