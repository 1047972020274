/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable */
import { Mutation } from '@tanstack/react-query';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import LoadingButton from '../../ui/LoadingButton';

function RetryMutationBtn({
  mutation,
  successfullyRetriedMutations,
  setSuccessfullyRetriedMutations
}: {
  mutation: Mutation<unknown, unknown, void, unknown>;
  successfullyRetriedMutations:
    | Mutation<unknown, unknown, void, unknown>[]
    | undefined;
  setSuccessfullyRetriedMutations: Dispatch<
    SetStateAction<Mutation<unknown, unknown, void, unknown>[] | undefined>
  >;
}) {
  const intl = useIntl();

  const [mutationIsRetrying, setMutationIsRetrying] = useState<boolean>(false);

  const retryMutation = () => {
    setMutationIsRetrying(true);
    if (mutation.options)
      (mutation.options as any)
        .mutationFn(mutation.state.variables)
        .then(() => {
          toast.success(
            intl.formatMessage({
              id: 'retry.successful',
              defaultMessage: 'Retry successful'
            }),
            { delay: 200 }
          );
          if (successfullyRetriedMutations?.length) {
            setSuccessfullyRetriedMutations([
              ...successfullyRetriedMutations,
              mutation
            ]);
          } else {
            setSuccessfullyRetriedMutations([mutation]);
          }
          setMutationIsRetrying(false);
        })
        .catch(() => {
          toast.error(
            intl.formatMessage({
              id: 'retry.unsuccessful',
              defaultMessage: 'Retry unsuccessful'
            }),
            { delay: 200 }
          );
          setMutationIsRetrying(false);
        });
  };

  return (
    <LoadingButton
      isLoading={mutationIsRetrying}
      loadingMessage={
        <FormattedMessage id="error.retrying" defaultMessage="Retrying" />
      }
      onClick={retryMutation}
      className="btn btn-secondary"
      type="button"
      buttonMessage={<FormattedMessage id="retry" defaultMessage="Retry" />}
    />
  );
}

export default RetryMutationBtn;
