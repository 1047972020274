import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useOfflineDataDownload from '../../../hooks/offline/useOfflineDataDownload';
import { components } from '../../../types/openapi/CourseService';
import { GeneralError } from '../../common';
import FrameworkProgress from '../../progress/framework/FrameworkProgress';

function OfflineFrameworkProgress({
  frameworkId,
  frameworkVersionId,
  learner
}: {
  frameworkId: number;
  frameworkVersionId: number;
  learner:
    | components['schemas']['UserDto']
    | components['schemas']['LearnerDto'];
}) {
  const NUMBER_OF_STARS = 3;

  const { sessionId } = useParams();
  const {
    getCurrentFrameworkStageIdForLearner,
    learnerDetails,
    getCurrentStageFrameworkStars,
    frameworkStages,
    offlineDataDownloadQuery
  } = useOfflineDataDownload({
    sessionId: Number(sessionId),
    downloadSessionData: true
  });

  if (!learnerDetails) return <GeneralError />;

  const currentStageFrameworkStars = getCurrentStageFrameworkStars(
    learner.userId
  );

  const getIncompleteObjectives = () => {
    return (
      currentStageFrameworkStars?.filter(
        (f) => f?.numberOfStars && f.numberOfStars < NUMBER_OF_STARS
      ).length || 0
    );
  };

  const incompleteCount = getIncompleteObjectives();

  const stages = frameworkStages;

  if (!stages) return <GeneralError />;

  const [currentFrameworkStageId, setCurrentFrameworkStageId] = useState<
    number | null
  >(getCurrentFrameworkStageIdForLearner(learner.userId) || null);

  useEffect(() => {
    setCurrentFrameworkStageId(
      getCurrentFrameworkStageIdForLearner(learner.userId) || null
    );
  }, [offlineDataDownloadQuery.dataUpdatedAt]);

  return (
    <FrameworkProgress
      stages={stages}
      currentStageId={currentFrameworkStageId}
      frameworkId={frameworkId}
      frameworkVersionId={frameworkVersionId}
      learner={learner}
      incompleteCount={incompleteCount}
    />
  );
}

export default OfflineFrameworkProgress;
