/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PaginationState,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';

import { components } from '../../types/openapi/CourseService';
import PaginatedTable from '../tables/PaginatedTable';
import useTableColumns from '../../hooks/table/useTableColumns';

function CoursesTableForInstructor({
  data,
  error,
  renderCourseLink,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation
}: {
  data: components['schemas']['PagedCourseDto'] | undefined | null;
  error: any;
  renderCourseLink?: ({
    courseId
  }: {
    courseId: number;
  }) => JSX.Element | ReactNode;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  queryParamsLocation: string;
}) {
  const intl = useIntl();

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  const { courseNameColumn } = useTableColumns({});

  const columns = [
    courseNameColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const courseLink =
          renderCourseLink &&
          renderCourseLink({
            courseId: row.original.courseId
          });
        return <div className="btn-group">{courseLink}</div>;
      }
    }
  ];

  const table = useReactTable({
    data: data?.courses ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfCourses
        ? Math.ceil(data.numberOfCourses / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{ pagination, sorting, filtering }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfCourses}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="courseId"
      noneFoundMessage={
        <FormattedMessage
          id="courses.error.none"
          defaultMessage="No courses found"
        />
      }
      showFooter={(data?.numberOfCourses ?? 0) > 10}
    />
  );
}

export default CoursesTableForInstructor;

CoursesTableForInstructor.defaultProps = {
  renderCourseLink: () => {}
};
