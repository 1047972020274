import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Dayjs } from 'dayjs';

import useStripeUrlForPayment from '../../../hooks/useStripeUrlForPayment';
import useUserSessionBooking from '../../../hooks/useUserSessionBooking';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { AppLanguage, AppRoute } from '../../../const';
import { appStrings } from '../../../modules/i18n';
import { GeneralError } from '../../common';
import Loading from '../../common/Loading';

function SessionPaymentLink({
  providerId,
  sessionStartTime
}: {
  providerId: number;
  sessionStartTime: Dayjs;
}) {
  const { courseId, sessionId } = useParams();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const navigate = useNavigate();

  const redirect = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal as AppLanguage][AppRoute.Courses]
  }/${courseId}/${appStrings[langVal][AppRoute.ViewSession]}/${sessionId}`;

  const { courseSessionBookingForUserQuery } = useUserSessionBooking({
    courseId: Number(courseId),
    courseSessionId: Number(sessionId),
    userId: profile?.userId!,
    displayLocale
  });

  if (courseSessionBookingForUserQuery.isLoading) {
    return <Loading />;
  }

  const hasPaid = courseSessionBookingForUserQuery.data?.hasPaid;

  if (hasPaid) {
    navigate(redirect);
  }

  const { stripePaymentUrlQuery } = useStripeUrlForPayment({
    courseId: Number(courseId),
    courseSessionId: Number(sessionId),
    userId: profile?.userId!,
    providerId,
    startDate: sessionStartTime?.format('DD-MM-YYYY'),
    displayLocale
  });

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-12">
          {stripePaymentUrlQuery.isLoading ||
          courseSessionBookingForUserQuery.isLoading ? (
            <Loading />
          ) : stripePaymentUrlQuery.error ||
            courseSessionBookingForUserQuery.error ? (
            <GeneralError
              message={
                <FormattedMessage
                  id="payment.not_available"
                  defaultMessage="Payment not available"
                  description="Payment not available"
                />
              }
            />
          ) : (
            <>
              <a
                href={stripePaymentUrlQuery.data || ''}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="payment.pay"
                  defaultMessage="Pay"
                  description="Pay"
                />
                <FontAwesomeIcon
                  className="ms-2"
                  icon={solid('arrow-up-right-from-square')}
                />
              </a>
              <div className="mt-2 alert border text-muted">
                <FormattedMessage
                  id="payment.external_link_warning"
                  defaultMessage="This link will take you out of Sport Passport to our trusted payment partner Stripe to handle payment"
                  description="This link will take you out of Sport Passport to our trusted payment partner Stripe to handle payment"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default SessionPaymentLink;
