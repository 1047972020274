import React from 'react';
import { UserType } from '../../../../../const/user-type';
import getFormattedUserType from '../../../../../utils/getFormattedUserType';
import SelectFilter from './SelectFilter';

function UserTypeFilter({
  columnDef,
  initialFilterValue,
  handleDropdownInputChange
}: {
  columnDef: any;
  initialFilterValue: number;
  handleDropdownInputChange: (selectedOption: any) => void;
}) {
  const userTypesToFilter = [
    UserType.LEARNER,
    UserType.INSTRUCTOR,
    UserType.ADMIN,
    UserType.GROUP_ADMIN,
    UserType.SCHOOL_COORDINATOR
  ];

  const options = userTypesToFilter.map((userTypeId) => {
    return { label: getFormattedUserType(userTypeId), value: userTypeId };
  });

  return (
    <SelectFilter
      columnDef={columnDef}
      initialFilterValue={initialFilterValue}
      handleDropdownInputChange={handleDropdownInputChange}
      options={options}
      columnName="User Type"
      menuPositionRight={200}
    />
  );
}

export default UserTypeFilter;
