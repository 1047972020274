/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

import { components as userComponents } from '../../types/openapi/UserService';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import Loading from '../common/Loading';
import { GeneralError } from '../common';
import generateSportPassportId from '../../utils/generateSportPassportId';
import useInstructors from '../../hooks/useInstructors';
import AssignInstructorToCentreButton from './AssignInstructorToCentreButton';

function AssignInstructorToCentre() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const userProviderId = profile?.providerId;

  const params = useParams();
  const { centreId } = params;
  const intl = useIntl();

  const { instructorsListQuery } = useInstructors({
    providerId: Number(userProviderId),
    centreId: null,
    displayLocale
  });

  return (
    <div className="card">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.instructors.assign',
            defaultMessage: 'Assign instructor to centre'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="instructors.assign.header"
            defaultMessage="Assign Instructor to Centre"
            description="Assign instructor to centre"
          />
        </h1>
      </div>
      <div className="card-body">
        {instructorsListQuery.isFetching ? (
          <Loading />
        ) : instructorsListQuery.error ? (
          <GeneralError />
        ) : instructorsListQuery.data ? (
          <table className="table card-list-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="name"
                    defaultMessage="Name"
                    description="Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="dob"
                    defaultMessage="Date of Birth"
                    description="Date of Birth"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="passport_id"
                    defaultMessage="Passport Id"
                    description="Sport Passport Id"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="actions.table.header"
                    defaultMessage="Actions"
                    description="Actions"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {instructorsListQuery.data.users
                ?.filter((instructor: userComponents['schemas']['UserDto']) => {
                  return instructor.userProviders!.every(
                    (
                      userProvider: userComponents['schemas']['UserProvidersDto']
                    ) => userProvider.centreId !== Number(centreId)
                  );
                })
                .map((instructor: userComponents['schemas']['UserDto']) => {
                  return (
                    <tr key={instructor.userId}>
                      <td data-title="Name" data-gdpr="true">
                        {instructor.username}
                      </td>
                      <td data-title="Date of Birth" data-gdpr="true">
                        {instructor.dateOfBirth === '0001-01-01T00:00:00' ? (
                          <span className="text-muted">
                            <FormattedMessage
                              id="dob.none"
                              defaultMessage="No Date of Birth Provided"
                              description="No Date of Birth Provided"
                            />
                          </span>
                        ) : (
                          dayjs(instructor.dateOfBirth).format('DD/MM/YYYY')
                        )}
                      </td>
                      <td data-title="Sport Passport Id">
                        {instructor.sportPassportId ? (
                          generateSportPassportId(instructor.sportPassportId)
                        ) : (
                          <span className="text-muted">
                            <FormattedMessage
                              id="id.sport_passport.none"
                              defaultMessage="No Sport Passport Id provided"
                              description="No Sport Passport Id provided"
                            />
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="btn-group">
                          {userProviderId && instructor.userId && (
                            <AssignInstructorToCentreButton
                              user={instructor}
                              providerId={userProviderId}
                              centreId={Number(centreId)}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <FormattedMessage
            id="instructors.none_found"
            defaultMessage="No instructors"
            description="No instructors found"
          />
        )}
      </div>
    </div>
  );
}
export default AssignInstructorToCentre;
