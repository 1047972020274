import { useMutation, useQueryClient } from '@tanstack/react-query';

import { components } from '../../types/openapi/ProviderService';
import { AppLanguage } from '../../const';
import { createCourse } from '../../services/api/course.service';
import courseKeys from '../../query-keys/course-key-factory';

const useCreateCourse = ({ displayLocale }: { displayLocale: string }) => {
  const queryClient = useQueryClient();

  const createNewCourse = useMutation(
    (newCourse: components['schemas']['CourseDto']) => {
      return createCourse(newCourse, displayLocale);
    },
    {
      onSuccess: (newCourse) => {
        // add new course as a query
        queryClient.setQueryData(
          courseKeys.course(
            Number(newCourse.courseId),
            displayLocale as AppLanguage
          ),
          { ...newCourse }
        );
        // invalidate list of courses
        queryClient.invalidateQueries({
          queryKey: courseKeys.all(displayLocale)
        });
      }
    }
  );

  return {
    createNewCourse
  };
};

export default useCreateCourse;
