/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import Select, { PropsValue } from 'react-select';
import { FormattedMessage, useIntl } from 'react-intl';

import { GeneralError } from '../common';

function StageSelect({
  frameworkVersionId,
  stageOptions,
  defaultStageOption,
  onChange
}: {
  frameworkVersionId: number;
  stageOptions: PropsValue<any> | null;
  defaultStageOption: PropsValue<any> | null;
  onChange: (value: number) => void;
}) {
  const intl = useIntl();
  if (!stageOptions?.length) {
    return <GeneralError />;
  }

  const handleStageChange = (newValue: { value: number; label: string }) => {
    onChange(newValue.value);
  };

  return (
    <>
      <label htmlFor="courseStage">
        <FormattedMessage id="framework.stage.label" defaultMessage="Stage" />
      </label>
      <Select
        key={`react-select-key-${frameworkVersionId}`}
        instanceId={frameworkVersionId}
        inputId="courseStage"
        menuPlacement="top"
        options={stageOptions}
        defaultValue={defaultStageOption}
        value={defaultStageOption}
        placeholder={intl.formatMessage({
          id: 'stage.select.placeholder',
          defaultMessage: 'Select stage'
        })}
        onChange={handleStageChange}
      />
    </>
  );
}

export default StageSelect;
