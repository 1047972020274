import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { GeneralError } from '../common';
import Loading from '../common/Loading';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useUserObjectives from '../../hooks/useUserObjectives';
import StageObjectiveUserStars from './StageObjectiveUserStars';

function StageObjectives({
  frameworkVersionId,
  stageId,
  userId,
  isEditable
}: {
  frameworkVersionId: number | null;
  stageId: number;
  userId: number;
  isEditable: boolean;
}) {
  if (!frameworkVersionId) {
    return <GeneralError />;
  }

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { userObjectivesQuery } = useUserObjectives({
    frameworkVersionId,
    stageId,
    userId,
    displayLocale
  });

  if (userObjectivesQuery.data) {
    return (
      <StageObjectiveUserStars
        frameworkVersionId={frameworkVersionId}
        userId={userId}
        objectives={userObjectivesQuery.data}
        isEditable={isEditable}
      />
    );
  }
  if (userObjectivesQuery.error) {
    return <GeneralError />;
  }

  if (userObjectivesQuery.isFetching || userObjectivesQuery.isLoading) {
    return <Loading />;
  }
  return (
    <FormattedMessage
      id="offline.objectives.not-available"
      defaultMessage="Only learner's current stage data is available in offline mode"
      description="Only learner's current stage data is available in offline mode"
    />
  );
}

export default StageObjectives;
