import React from 'react';
import useProviderConfig from '../../../hooks/useProviderConfig';
import EditNotesCard from './EditNotesCard';

function EditNotesWrapper({ providerId }: { providerId: number }) {
  const { notesEnabled } = useProviderConfig({
    providerId
  });

  if (!notesEnabled) {
    return <div />;
  }

  return (
    <div className="row g-2">
      <div className="col-12">
        <EditNotesCard />
      </div>
    </div>
  );
}
export default EditNotesWrapper;
