import React, { useContext } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { UserType } from '../../../const/user-type';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import instructorKeys from '../../../query-keys/instructor-key-factory';
import { removeUserPermission } from '../../../services/api/user.service';
import { components } from '../../../types/openapi/UserService';
import { ModalContext } from '../../../modules/modal/ModalProvider';

function RemoveInstructorButton({
  profile,
  instructor
}: {
  profile: components['schemas']['UserProvidersDto'];
  instructor: components['schemas']['UserDto'];
}) {
  const queryClient = useQueryClient();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const intl = useIntl();

  const { mutate: newRemoveInstructorPermission } = useMutation(
    (instructorPermission: any) => removeUserPermission(instructorPermission),
    {
      onSuccess: () => {
        const toastId = 'successToast';
        toast.success(
          intl.formatMessage({
            id: 'instructor.removed.success',
            defaultMessage: 'This instructor was successfully removed'
          }),
          { delay: 200, toastId }
        );
        // Centre admin - invalidate instructors for their centre
        if (profile?.centreId) {
          queryClient.invalidateQueries({
            queryKey: instructorKeys.providerCentreList(
              Number(profile.providerId),
              profile.centreId,
              displayLocale
            )
          });
        } else {
          queryClient.invalidateQueries({
            queryKey: instructorKeys.providerList(
              Number(profile.providerId),
              displayLocale
            )
          });
        }
      },
      onError: (error) => {
        const errorToastId = 'errorToast';
        console.error(error);
        toast.error(
          intl.formatMessage({
            id: 'instructor.removed.error',
            defaultMessage: 'There was an error removing this instructor'
          }),
          { delay: 200, toastId: errorToastId }
        );
      }
    }
  );

  async function onModalConfirm(_instructor: components['schemas']['UserDto']) {
    if (profile && profile.centreId) {
      _instructor.userProviders!.forEach((userProvider) => {
        if (
          userProvider.userTypeId === UserType.INSTRUCTOR &&
          userProvider.providerId === Number(profile?.providerId) &&
          userProvider.centreId === profile.centreId
        ) {
          const instructorPermission: Partial<
            components['schemas']['UserProvidersDto']
          > = {
            userId: instructor.userId,
            userProviderId: userProvider.userProviderId,
            centreId: userProvider.centreId
          };
          newRemoveInstructorPermission(instructorPermission);
          // pre-emptively delete stored query for this instructor
          queryClient.invalidateQueries({
            queryKey: instructorKeys.instructor(
              instructor.userId,
              displayLocale
            )
          });
        }
      });
    } else
      _instructor.userProviders!.forEach((userProvider) => {
        if (
          userProvider.userTypeId === UserType.INSTRUCTOR &&
          userProvider.providerId === Number(profile.providerId)
        ) {
          const instructorPermission: Partial<
            components['schemas']['UserProvidersDto']
          > = {
            userId: instructor.userId!,
            userProviderId: userProvider.userProviderId
          };
          newRemoveInstructorPermission(instructorPermission);
        }
      });
  }

  function openModal(_instructor: components['schemas']['UserDto']) {
    const header = intl.formatMessage(
      {
        id: 'instructor.delete.confirm.header',
        defaultMessage: 'Delete Instructor: {name}',
        description: 'Delete Instructor Confirm Modal Header'
      },
      { name: instructor.username }
    );
    const modalContent = intl.formatMessage({
      id: 'instructor.delete.confirm.body',
      defaultMessage:
        'Are you sure you want to delete this instructor? This action cannot be undone',
      description: 'Delete instructor Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'instructor.delete.confirm.button',
      defaultMessage: 'Delete Instructor',
      description: 'Delete instructor Confirm Button'
    });
    modal(modalContent, {
      confirm: () => onModalConfirm(_instructor),
      header,
      confirmText
    });
  }

  return (
    <button
      className="btn btn-outline-secondary"
      type="button"
      onClick={() => openModal(instructor)}
    >
      <FormattedMessage
        id="instructor.delete"
        defaultMessage="Remove instructor"
        description="Remove instructor from provider"
      />
    </button>
  );
}

export default RemoveInstructorButton;
