/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import Loading from '../common/Loading';
import { GeneralError } from '../common';
import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';
import GroupAdminsInGroup from './GroupAdminsInGroup';
import LearnersInGroup from './LearnersInGroup';
import useGroup from '../../hooks/useGroup';

function ViewGroup() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;

  const { groupId } = useParams();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  const { groupQuery } = useGroup({
    providerId: profile?.providerId!,
    groupId: Number(groupId),
    displayLocale
  });

  if (groupQuery.isFetching) return <Loading />;
  if (groupQuery.error) return <GeneralError />;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.group.name',
              defaultMessage: 'Group: {name}'
            },
            {
              name: groupQuery.data?.name
            }
          )}
        </title>
      </Helmet>
      <div className="card">
        <div className="card-header d-flex">
          <div className="flex-grow-1">
            <h1>
              {' '}
              <FormattedMessage
                id="group.name_title"
                defaultMessage="Group: {name}"
                description="Group: name"
                values={{
                  name: groupQuery.data?.name
                }}
              />
            </h1>
          </div>
          <Link
            to={`${appStrings[langVal][AppRoute.EditGroup]}`}
            className="btn btn-primary"
          >
            <FormattedMessage
              id="group.edit.button"
              defaultMessage="Edit group"
              description="Edit group"
            />
          </Link>
        </div>
        <div className="card-body">{groupQuery.data?.description}</div>
      </div>

      {groupQuery.data && (
        <>
          <GroupAdminsInGroup group={groupQuery.data} />
          <LearnersInGroup group={groupQuery.data} />
        </>
      )}
    </>
  );
}

export default ViewGroup;
