import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import InstructorTimetable from './InstructorTimetable';
import LearnerTimetable from './LearnerTimetable';
import Loading from '../common/Loading';
import InstructorAgendaWrapper from './InstructorAgendaWrapper';
import { isCentreAdmin } from '../../utils/permissions';
import CentreAdminTimetable from './CentreAdminTimetable';
import ProviderAdminTimetable from './ProviderAdminTimetable';

function Timetable({ showAgendaOnly }: { showAgendaOnly?: boolean }) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  return (
    <div className="card mh-vh-70 border-0 rounded-top-right-lg ">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.timetable',
            defaultMessage: 'Timetable'
          })}
        </title>
      </Helmet>
      <div className="card-header rounded-top-right-lg">
        <h1>
          {showAgendaOnly ? (
            <FormattedMessage
              id="timetable.todays_agenda"
              defaultMessage="Today's Agenda"
              description="Today's Agenda"
            />
          ) : (
            <FormattedMessage
              id="timetable"
              defaultMessage="Timetable"
              description="Timetable"
            />
          )}
        </h1>
      </div>
      <div className="card-body">
        {profile?.userTypeId === UserType.LEARNER && profile.userId && (
          <LearnerTimetable
            userId={profile.userId}
            providerId={profile.providerId}
          />
        )}
        {profile?.userTypeId === UserType.ADMIN &&
          profile.userId &&
          isCentreAdmin(profile) && <CentreAdminTimetable />}
        {profile?.userTypeId === UserType.ADMIN &&
          profile.userId &&
          !isCentreAdmin(profile) && <ProviderAdminTimetable />}
        {profile?.userTypeId === UserType.INSTRUCTOR &&
          profile.userId &&
          !showAgendaOnly && <InstructorTimetable userId={profile.userId} />}
        {profile?.userTypeId === UserType.INSTRUCTOR &&
          profile.userId &&
          showAgendaOnly && <InstructorAgendaWrapper />}
      </div>
    </div>
  );
}

export default Timetable;

Timetable.defaultProps = {
  showAgendaOnly: false
};
