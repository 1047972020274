import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import EditInstructor from '../components/instructors-admin/EditInstructor';
import EditInstructorCentres from '../components/instructors-admin/EditInstructorCentres';
import ViewInstructor from '../components/instructors-admin/view-instructor/ViewInstructor';
import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { UserType } from '../const/user-type';

const ManageInstructors = lazy(
  () => import('../components/instructors-admin/ManageInstructors')
);

const CreateInstructor = lazy(
  () =>
    import('../components/instructors-admin/create-instructor/CreateInstructor')
);

const Step1 = lazy(
  () => import('../components/instructors-admin/create-instructor/Step1')
);

const Step2 = lazy(
  () => import('../components/instructors-admin/create-instructor/Step2')
);

const Success = lazy(
  () => import('../components/instructors-admin/create-instructor/Success')
);

export default function instructorRoutes(
  lang: AppLanguage | string | undefined
) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Instructors],
    element: (
      <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="instructors"
          defaultMessage="Instructors"
          description="Instructors"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageInstructors />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.CreateInstructor],
        element: <CreateInstructor />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="instructors.create"
              defaultMessage="Create Instructor"
              description="Create Instructor"
            />
          )
        },
        children: [
          {
            index: true,
            element: <Step1 />,
            errorElement: <ErrorPage />
          },
          {
            path: appStrings[langVal][AppRoute.Step2],
            element: <Step2 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage id="step2" defaultMessage="Step 2" />
              )
            }
          },

          {
            path: appStrings[langVal][AppRoute.Success],
            element: <Success />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="success"
                  defaultMessage="Success"
                  description="Success"
                />
              )
            }
          }
        ]
      },
      {
        path: `:instructorId`,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="instructor"
              defaultMessage="Instructor"
              description="Instructor"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ViewInstructor />,
            errorElement: <ErrorPage />
          },
          {
            path: `${appStrings[langVal][AppRoute.EditInstructor]}`,
            element: <EditInstructor />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="instructor.edit"
                  defaultMessage="Edit Instructor"
                  description="Edit Instructor"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.EditCentres]}`,
            element: <EditInstructorCentres />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="instructor.edit.centres"
                  defaultMessage="Edit Instructor Centres"
                  description="Edit Instructor Centres"
                />
              )
            }
          }
        ]
      }
    ]
  };
  return routes;
}
