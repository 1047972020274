import { AppLanguage } from '../const';

const userKeys = {
  // everything to do with this user
  all: () => ['user'] as const,
  user: (userId: number | undefined, displayLocale?: string | undefined) =>
    [
      userKeys.all(),
      userId,
      userKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  lang: (displayLocale: string | AppLanguage | undefined) =>
    [displayLocale] as const,
  // list of permissions for this user
  permissions: (userId: number, displayLocale?: string | undefined) =>
    [
      ...userKeys.all(),
      'permissions',
      userId,
      userKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  billingReport: (
    providerId: number | null | undefined,
    displayLocale?: string | undefined
  ) =>
    [
      ...userKeys.all(),
      providerId,
      'billing-report',
      userKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  providerList: (
    providerId: number,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [...userKeys.all(), 'list', providerId];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  }
};

export default userKeys;
