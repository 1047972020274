const baseStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.admin': 'admin',
  'routes.login': 'login',
  'routes.contact': 'contact-us',
  'routes.about': 'about-us',
  'routes.launchEvent': 'launch-event',
  'routes.launchEventCy': 'digwyddiad-lansio',
  'routes.loginSSORedirect': 'login-redirect',
  'routes.roleChange': 'change-role',
  'routes.role.select': 'select-role',
  'routes.unauthorised': 'unauthorised',
  'routes.login.forgotUsername': 'forgot-username',
  // DO NOT CHANGE THIS ROUTE - it gets sent to users
  'routes.login.forgotPassword': 'forgot-password',
  'routes.login.request.forgotPassword': 'request-new-password',
  'routes.frameworks': 'frameworks',
  'routes.framework': 'framework',
  'routes.createFramework': 'create-framework',
  'routes.manageFrameworkTranslation': 'manage-framework-translation',
  'routes.manageTranslation': 'manage-translation',
  'routes.version': 'version',
  'routes.editVersion': 'edit',
  'routes.stages': 'stages',
  'routes.addStage': 'add-stage',
  'routes.editStage': 'edit-stage',
  'routes.addObjective': 'add-objective',
  'routes.editObjective': 'edit-objective',
  'routes.cloneVersion': 'clone-version',
  'routes.courses': 'courses',
  'routes.createCourse': 'create-course',
  'routes.editCourse': 'edit-course',
  'routes.learners': 'learners',
  'routes.createLearner': 'create-learner',
  'routes.step1': 'step-1',
  'routes.step2': 'step-2',
  'routes.step3': 'step-3',
  'routes.step4': 'step-4',
  'routes.success': 'success',
  'routes.editLearner': 'edit-learner',
  'routes.instructors': 'instructors',
  'routes.createInstructor': 'create-instructor',
  'routes.editInstructor': 'edit-instructor',
  'routes.providers': 'providers',
  'routes.createProvider': 'create-provider',
  'routes.editProvider': 'edit-provider',
  'routes.editCentres': 'edit-centres',
  'routes.centres': 'centres',
  'routes.createCentre': 'create-centre',
  'routes.viewCentre': 'centre',
  'routes.editCentre': 'edit',
  'routes.assignInstructorToCentre': 'assign-instructor',
  'routes.addFrameworkProvider': 'add-provider',
  'routes.createFacility': 'create-facility',
  'routes.editFacility': 'edit-facility',
  'routes.viewSession': 'view-session',
  'routes.bookSession': 'book-session',
  'routes.timetable': 'timetable',
  'routes.resources': 'resources',
  'routes.instructorViewObjectives': 'objectives',
  'routes.viewLearnerObjective': 'users',
  'routes.learnerProgress': 'progress',
  'routes.profile': 'profile',
  'routes.editProfile': 'edit-profile',
  'routes.versions': 'versions',
  'routes.payment-success': 'payment-success',
  'routes.groups': 'groups',
  'routes.createGroup': 'create-group',
  'routes.viewGroup': 'group',
  'routes.editGroup': 'edit',
  'routes.createGroupAdmin': 'create-group-admin',
  'routes.editGroupAdmin': 'edit-group-admin',
  'routes.addGroupToCourseSession': 'add-group',
  'routes.addGroupToCourse': 'add-group',
  'routes.addLearnerToCourse': 'add-learner',
  'routes.addLearnerToCourseSession': 'add-learner',
  'routes.addLearnerToGroup': 'add-learner',
  'routes.dragonGame': 'dragon-game',
  'routes.systemDiagnostics': 'system-diagnostics',
  'routes.createCentreAdmin': 'create-admin',
  'routes.createProviderAdmin': 'create-admin',
  'routes.privacyPolicy': 'privacy-policy',
  'routes.accessibilityStatement': 'accessibility-statement',
  'routes.termsAndConditions': 'terms-and-conditions',
  'routes.cookiePolicy': 'cookie-policy',
  'routes.schools': 'schools',
  'routes.createSchool': 'create-school',
  'routes.editSchool': 'edit-school',
  'routes.createSchoolAdmin': 'create-school-admin',
  'routes.addCentreToSchool': 'add-centre',
  'routes.changePassword': 'change-password',
  'routes.reports': 'reports',
  'routes.users': 'users',
  'routes.editAdminCentres': 'edit-admin-centres',
  'routes.editInstructorCentres': 'edit-instructor-centres',
  'routes.editLearnerCentres': 'edit-learner-centres',
  'routes.offlineSession': 'offline-session',
  'routes.userImpersonation': 'user-impersonation'
};

export type LanguageStrings = typeof baseStrings;
export type RouteString = keyof typeof baseStrings;
export const en = baseStrings;
