/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import Unauthorised from '../../pages/Unauthorised';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import TimetableWrapper from './TimetableWrapper';
import useSessionsBetweenDates from '../../hooks/useSessionsBetweenDates';

function InstructorTimetable({ userId }: { userId: number }) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const [startDate, setStartDate] = useState<string>(
    appMode === AppMode.OFFLINE
      ? dayjs().format('YYYY-MM-DD')
      : dayjs().subtract(1, 'week').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    appMode === AppMode.OFFLINE
      ? dayjs().format('YYYY-MM-DD')
      : dayjs().add(1, 'week').format('YYYY-MM-DD')
  );

  const { instructorSessionsDateRangeQuery, instructorSessionsTodayQuery } =
    useSessionsBetweenDates({
      userId,
      userType: UserType.INSTRUCTOR,
      displayLocale,
      startDate,
      endDate
    });

  if (profile?.userTypeId !== UserType.INSTRUCTOR) {
    return <Unauthorised />;
  }

  return (
    <TimetableWrapper
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      sessions={
        instructorSessionsDateRangeQuery.data ||
        instructorSessionsTodayQuery.data
      }
      isLoading={instructorSessionsDateRangeQuery.isFetching}
      error={instructorSessionsDateRangeQuery.error}
    />
  );
}

export default InstructorTimetable;
