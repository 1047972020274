import { useQueries } from '@tanstack/react-query';
import { getStageObjectives } from '../services/api/framework.service';
import objectivesKeys from '../query-keys/objectives-key-factory';

// hook for stages
const useMultipleStageObjectives = ({
  frameworkId,
  frameworkVersionId,
  displayLocale,
  stageIds
}: {
  frameworkId: number | undefined | null;
  frameworkVersionId: number | undefined | null;
  displayLocale: string;
  stageIds: number[];
}) => {
  const multipleStagesObjectivesQueries = stageIds?.length
    ? useQueries({
        queries: stageIds.map((id) => {
          return {
            queryKey: objectivesKeys.listForStage(
              frameworkId,
              frameworkVersionId,
              id,
              displayLocale
            ),
            queryFn: () =>
              getStageObjectives(
                frameworkId,
                frameworkVersionId,
                id,
                displayLocale
              ),
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: !!frameworkId && !!frameworkVersionId && !!stageIds.length
          };
        })
      })
    : undefined;

  const someIsLoading = multipleStagesObjectivesQueries?.some(
    (data) => data.isLoading
  );

  const isSuccess = multipleStagesObjectivesQueries?.every(
    (data) => data.isSuccess
  );

  return {
    multipleStagesObjectivesQueries,
    someIsLoading,
    isSuccess
  };
};

export default useMultipleStageObjectives;
