import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import InstructorSession from '../../courses/sessions/session/instructor/InstructorSession';
import useSession from '../../../hooks/useSession';
import { AppLanguage } from '../../../const';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { GeneralError, Loading } from '../../common';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

function OnlineInstructorSession() {
  const { courseId, sessionId } = useParams();
  const profileContext = useContext(ProfileContext);
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { profile } = profileContext;
  if (!profile?.userId) {
    console.error('no user id');
    return <GeneralError />;
  }
  const {
    sessionQuery,
    sessionCapacity,
    availableSpaces,
    sessionMembersQuery,
    sessionMembersQuerySorted
  } = useSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    userId: profile.userId,
    displayLocale: displayLocale || AppLanguage.English
  });
  if (sessionQuery.data && sessionQuery.data.course)
    return (
      <InstructorSession
        session={sessionQuery.data}
        sessionCapacity={sessionCapacity}
        availableSpaces={availableSpaces}
        sessionMembersSorted={sessionMembersQuerySorted}
        sessionMembersError={sessionMembersQuery.error}
        sessionMembersIsLoading={sessionMembersQuery.isLoading}
        sessionDataUpdatedAt={sessionMembersQuery.dataUpdatedAt}
        course={sessionQuery.data?.course}
        courseName={sessionQuery.data?.course?.name}
        centreName={sessionQuery.data?.centre?.name}
        facilityName={sessionQuery.data?.facility?.name}
        courseStages={sessionQuery.data?.course?.courseStages}
        frameworkName={sessionQuery.data?.course?.frameworkVersion?.name}
      />
    );
  if (sessionQuery.error) return <GeneralError />;
  return <Loading />;
}

export default OnlineInstructorSession;
