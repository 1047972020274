import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserType } from '../const/user-type';
import { ProfileContext } from '../modules/profile/ProfileProvider';
import centreKeys from '../query-keys/centre-key-factory';
import providerKeys from '../query-keys/provider-key-factory';
import {
  getProvider,
  getProviderCentresCount
} from '../services/api/provider.service';
import { getUsersForProviderOfUserType } from '../services/api/user.service';
import { FilteringObject } from '../const/filtering-object';

// hook for managing learners, instructors, centres summaries within provider
const useProvider = ({
  providerId,
  displayLocale,
  rawFiltering
}: {
  providerId: number | null | undefined;
  displayLocale: string | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  if (!providerId && profile?.userTypeId === UserType.ADMIN) {
    throw Error('invalid id');
  }

  const providerQuery = useQuery({
    queryKey: providerKeys.provider(Number(providerId), displayLocale),
    queryFn: () => getProvider(Number(providerId), displayLocale),
    refetchOnMount: true
  });

  const adminsForProviderListQuery = useQuery({
    queryKey: providerKeys.adminsForProvider(Number(providerId), displayLocale),
    queryFn: () =>
      getUsersForProviderOfUserType(
        Number(providerId),
        UserType.ADMIN,
        null,
        null,
        null,
        null,
        null,
        displayLocale
      ),
    refetchOnMount: true
  });
  // eslint-disable-next-line prefer-const
  let filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const centresForProviderListForSummaryQuery = useQuery({
    queryKey: centreKeys.summaryCount(providerId, displayLocale, filtering),
    queryFn: () =>
      getProviderCentresCount(providerId, displayLocale, filtering),
    refetchOnMount: true,
    enabled: !!providerId
  });

  return {
    providerQuery,
    adminsForProviderListQuery,
    centresForProviderListForSummaryQuery
  };
};

export default useProvider;
