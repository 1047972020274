import React, { useContext, useEffect, useState } from 'react';
import { onlineManager } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { mapSessionToEvent } from '../timetable/helpers';
import CalendarAgendaViewOnly from '../timetable/CalendarAgendaViewOnly';
import { SPCalendarEvent } from '../timetable/SPCalendarEvent.type';
import { UserType } from '../../const/user-type';
import useSessionsBetweenDates from '../../hooks/useSessionsBetweenDates';
import ResourcesLinkCard from './ResourcesLinkCard';
import FeaturedResource from './FeaturedResource';
import ProgressLinkCard from './ProgressLinkCard';
import LatestTrophies from './learner-trophy-snake/LatestTrophies';
import { GeneralError } from '../common';
import DragonGameNavCard from '../dragon-game/DragonGameNavCard';
// import { AppLanguage, AppRoute } from '../../const';
// import { appStrings } from '../../modules/i18n';
import useCoursesForLearner from '../../hooks/courses/useCoursesForLearner';
import { components } from '../../types/openapi/CourseService';

function LearnerDashboard() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const isOnline = onlineManager.isOnline();

  const [events, setEvents] = useState<SPCalendarEvent[] | undefined>();

  const [featuredResourcesFrameworkId, setFeaturedResourcesFrameworkId] =
    useState<number>();

  if (!profile?.userId) {
    return <GeneralError message="no user id" />;
  }

  const { learnerSessionsTodayQuery } = useSessionsBetweenDates({
    userId: profile?.userId,
    userType: UserType.LEARNER,
    displayLocale,
    isOnline
  });

  // ALL courses the learner is on - this is an unfiltered list
  const { coursesForLearnerListQuery } = useCoursesForLearner({
    userId: profile?.userId,
    displayLocale
  });

  useEffect(() => {
    if (learnerSessionsTodayQuery?.data) {
      const allSessions = learnerSessionsTodayQuery.data?.map((session) => {
        return mapSessionToEvent(session, {
          learnerBookedOnSession: false,
          bookable: false,
          fullyBooked: false,
          userIsInstructor: true
        });
      });
      setEvents(allSessions);
    }

    if (
      learnerSessionsTodayQuery?.data &&
      coursesForLearnerListQuery.data?.courses
    ) {
      if (learnerSessionsTodayQuery.data[0].course?.frameworkVersionId) {
        setFeaturedResourcesFrameworkId(
          learnerSessionsTodayQuery.data[0].course?.frameworkVersionId
        );
      } else {
        setFeaturedResourcesFrameworkId(
          coursesForLearnerListQuery.data.courses[0].frameworkVersionId
        );
      }
    }
  }, [
    learnerSessionsTodayQuery.data,
    coursesForLearnerListQuery.data,
    profile
  ]);

  return (
    <div className="dashboard">
      <div className="row mb-3">
        <div className="col-12">
          <div className="card border-0 rounded-top-right-lg">
            <div className="card-header rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="timetable.todays_agenda"
                  defaultMessage="Today's Agenda"
                  description="Today's Agenda"
                />
              </h2>
            </div>
            <div className="card-body ">
              <div className="overflow-auto">
                <div style={{ maxHeight: '300px' }}>
                  <CalendarAgendaViewOnly events={events} />
                </div>
              </div>
              {/* <div className="d-flex justify-content-center mt-3">
                <Link
                  type="button"
                  className="btn btn-chevron btn-chevron-navy w-50"
                  to={
                    appStrings[displayLocale as AppLanguage][AppRoute.Timetable]
                  }
                >
                  <FormattedMessage
                    id="timetable.button.learner"
                    defaultMessage="My timetable"
                    description="My timetable"
                  />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-12 col-lg-4">
          <div className="col col-12 col-lg-12">
            {profile?.userId && (
              <ProgressLinkCard
                userId={profile.userId}
                renderLatestTrophy={(
                  selectedFrameworkVersion: components['schemas']['FrameworkVersionDto']
                ) => {
                  return (
                    <LatestTrophies
                      userId={profile?.userId}
                      frameworkVersion={selectedFrameworkVersion}
                    />
                  );
                }}
              />
            )}
          </div>
        </div>
        <div className="col col-12 col-lg-4">
          <ResourcesLinkCard
            renderFeaturedResource={() => {
              if (featuredResourcesFrameworkId) {
                return (
                  <FeaturedResource
                    frameworkVersionId={featuredResourcesFrameworkId}
                  />
                );
              }
              return (
                <FormattedMessage
                  id="courses.none.learner.dashboard"
                  defaultMessage="No featured resource available."
                  description="No featured resource"
                />
              );
            }}
          />
        </div>
        <div className="col col-12 col-lg-4">
          <DragonGameNavCard />
        </div>
      </div>
    </div>
  );
}

export default LearnerDashboard;
