import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

import Navbar from 'react-bootstrap/Navbar';
import { Button, Dropdown, DropdownButton, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import NavUserInfo from './NavUserInfo';
import { AuthContext } from '../../../modules/auth/AuthProvider';
import AdminSidebar from '../sidebar/AdminSidebar';
import NavUserInfoMobile from './NavUserInfoMobile';
import { FilteringQueryParams } from '../../../const/filtering-query-params';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import UserMenu from './UserMenu';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import LanguageSwitcherWrapper from '../LanguageSwitcherWrapper';
import AVAILABLE_LOCALES from '../../../const/available-locales';
import LanguageSwitchNavDropdown from './LanguageSwitcherNavDropdown';
import WhiteLogo from '../../../assets/images/logos/sport-passport-logo-white.png';

function Header() {
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    // Clear any stored filters
    const values = Object.values(FilteringQueryParams);

    values.forEach((value) => {
      window.sessionStorage.removeItem(`${value}.pageIndex`);
      window.sessionStorage.removeItem(`${value}.pageSize`);
      window.sessionStorage.removeItem(`${value}.sorting`);
      window.sessionStorage.removeItem(`${value}.filters`);
    });

    setShow(false);
  };

  return (
    <header className="header px-3">
      <Navbar expand="lg" className="gap-1">
        {/* change sidebar menu to offcanvas version at small screen sizes */}
        <div className="flex-grow-1 text-start logo">
          <Link to="/">
            <img
              style={{ width: '163.655px' }}
              className="logo"
              src={WhiteLogo}
              alt={intl.formatMessage({
                id: 'sport_passport',
                defaultMessage: 'Sport Passport'
              })}
            />
          </Link>
        </div>
        {/* // If no provider ID, and no providerconfig it is likely that user is not
        logged in at this point so provide all available languages. If a
        language is not provided by the provider after login the homepage
        will redirect to a supported language */}
        {profile?.providerId ? (
          <LanguageSwitcherWrapper providerId={profile?.providerId} />
        ) : (
          <LanguageSwitchNavDropdown providerLanguages={AVAILABLE_LOCALES} />
        )}
        <div>
          {token && (
            <Button
              className="d-block d-sm-none"
              variant="primary"
              onClick={handleShow}
            >
              <FontAwesomeIcon icon={icon({ name: 'bars', style: 'solid' })} />

              <span className="visually-hidden">
                <FormattedMessage
                  id="menu.toggle"
                  defaultMessage="Toggle Menu"
                />
              </span>
            </Button>
          )}
        </div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton className="bg-primary">
            <Offcanvas.Title>
              <Link to="/" className="flex-grow-1 text-start">
                <img
                  style={{ width: '163.655px' }}
                  className="logo"
                  src={WhiteLogo}
                  alt={intl.formatMessage({
                    id: 'sport_passport',
                    defaultMessage: 'Sport Passport'
                  })}
                />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {token && <NavUserInfoMobile />}
            <AdminSidebar
              handleClick={handleClose}
              urlPrepend={displayLocale}
            />
            <UserMenu handleClick={handleClose} isOffCanvas />
          </Offcanvas.Body>
        </Offcanvas>
        <Dropdown
          className="me-auto flex-row align-items-center gap-2 profile-info"
          as="nav"
          aria-label={intl.formatMessage({
            id: 'user.nav',
            defaultMessage: 'User'
          })}
        >
          {token && (
            <DropdownButton
              title={<NavUserInfo />}
              id="user-dropdown"
              className="text-start"
              variant=""
            >
              <UserMenu />
            </DropdownButton>
          )}
        </Dropdown>
      </Navbar>
    </header>
  );
}

export default Header;
