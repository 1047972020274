/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { redirect } from 'react-router-dom';
// import { QueryClient } from '@tanstack/react-query';

import { AppLanguage, AppRoute } from '../const';
import AVAILABLE_LOCALES from '../const/available-locales';
import { appStrings } from '../modules/i18n';
import { AdminHome } from '../pages';

import Layout from '../pages/layout';
import ErrorPage from '../pages/ErrorPage';
import Home from '../pages/Home';
import coursesRoutes from './CoursesRoutes';
import frameworkRoutes from './FrameworksRoutes';
import learnerRoutes from './LearnerRoutes';
import providerRoutes from './ProviderRoutes';
import LanguageRedirect from './LanguageRedirect';
import accountRoutes from './AccountRoutes';
import NotFound from '../pages/PageNotFound';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import instructorRoutes from './InstructorRoutes';
import userRoutes from './UserRoutes';
import centreRoutes from './CentreRoutes';
import groupRoutes from './GroupRoutes';
import timetableRoutes from './TimetableRoutes';
import resourcesRoutes from './ResourcesRoutes';
import progressRoutes from './ProgressRoutes';
import gameRoutes from './DragonGameRoutes';
import schoolsRoutes from './SchoolsRoutes';
import { StorageId } from '../const/storage-id';
import reportRoutes from './ReportRoutes';
import userImpersonationRoutes from './UserImpersonationRoutes';
import offlineSessionRoutes from './OfflineSessionRoutes';
// import providerConfigLoader from '../loaders/providerConfigLoader';

// const queryClient = new QueryClient();

const frameworks = AVAILABLE_LOCALES.map((lang) => {
  return { ...frameworkRoutes(lang) };
});
const providers = AVAILABLE_LOCALES.map((lang) => {
  return { ...providerRoutes(lang) };
});
const courses = AVAILABLE_LOCALES.map((lang) => {
  return { ...coursesRoutes(lang) };
});

const learners = AVAILABLE_LOCALES.map((lang) => {
  return { ...learnerRoutes(lang) };
});

const instructors = AVAILABLE_LOCALES.map((lang) => {
  return { ...instructorRoutes(lang) };
});

const users = AVAILABLE_LOCALES.map((lang) => {
  return { ...userRoutes(lang) };
});

const centres = AVAILABLE_LOCALES.map((lang) => {
  return { ...centreRoutes(lang) };
});

const groups = AVAILABLE_LOCALES.map((lang) => {
  return { ...groupRoutes(lang) };
});

const timetable = AVAILABLE_LOCALES.map((lang) => {
  return { ...timetableRoutes(lang) };
});

const resources = AVAILABLE_LOCALES.map((lang) => {
  return { ...resourcesRoutes(lang) };
});

const progress = AVAILABLE_LOCALES.map((lang) => {
  return { ...progressRoutes(lang) };
});

const game = AVAILABLE_LOCALES.map((lang) => {
  return { ...gameRoutes(lang) };
});

const schools = AVAILABLE_LOCALES.map((lang) => {
  return { ...schoolsRoutes(lang) };
});

const reports = AVAILABLE_LOCALES.map((lang) => {
  return { ...reportRoutes(lang) };
});

const offlineSession = AVAILABLE_LOCALES.map((lang) => {
  return { ...offlineSessionRoutes(lang) };
});

const userImpersonation = AVAILABLE_LOCALES.map((lang) => {
  return { ...userImpersonationRoutes(lang) };
});

export default function privateRoutes(
  defaultLanguage: AppLanguage // ,
  // providerId: number | null | undefined
) {
  let currentLang = defaultLanguage;

  return {
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/:lang',
        // loader: providerConfigLoader({ providerId, queryClient }),
        loader: ({ params }: { params: any }) => {
          const { lang } = params;
          // check if lang is one we support
          if (AVAILABLE_LOCALES.includes(lang)) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            currentLang = params['lang'];
          } else {
            console.warn(
              `${lang} is NOT a valid language, redirecting to default lang...`
            );
            return redirect(`/${currentLang || AppLanguage.English}`);
          }
          return lang;
        },
        errorElement: <ErrorPage />,
        children: [
          {
            element: <AdminHome />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="home.breadcrumb"
                  defaultMessage="Home"
                  description="Home"
                />
              )
            },
            children: [
              {
                index: true,
                element: <Home />,
                errorElement: <ErrorPage />,
                loader: () => {
                  // eslint-disable-next-line @typescript-eslint/dot-notation
                  const profile = window.localStorage.getItem(
                    StorageId.PROFILE
                  );
                  // check if lang is one we support
                  if (!profile) {
                    console.warn(`NO PROFILE! redirecting to profile page...`);
                    return redirect(
                      `/${defaultLanguage || AppLanguage.English}/${
                        appStrings[defaultLanguage][AppRoute.RoleSelect]
                      }`
                    );
                  }
                  return profile ? JSON.parse(profile) : null;
                }
              },
              ...frameworks,
              ...providers,
              ...courses,
              ...learners,
              ...instructors,
              ...users,
              ...centres,
              ...groups,
              ...timetable,
              ...resources,
              ...progress,
              ...game,
              ...schools,
              ...reports,
              ...offlineSession,
              ...userImpersonation,
              ...AVAILABLE_LOCALES.map((lang) => accountRoutes(lang)).flat()
            ]
          }
        ]
      },
      {
        index: true,
        element: <LanguageRedirect />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ],
    errorElement: <ErrorPage />
  };
}
