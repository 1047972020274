import { useQuery } from '@tanstack/react-query';
import { AppLanguage } from '../const';
import learnerKeys from '../query-keys/learner-key-factory';
import { getLearnersForInstructor } from '../services/api/user.service';
import { FilteringObject } from '../const/filtering-object';

// hook for managing learners
// if you are looking to manage a user within a session - try userUseSession hook instead
const useLearnersForInstructors = ({
  userId,
  displayLocale = AppLanguage.English,
  enabled = true,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  userId: number | null | undefined;
  displayLocale: string | undefined;
  enabled?: boolean;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  if (!userId) {
    throw Error('invalid user id');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  // eslint-disable-next-line prefer-const
  let filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const learnersForInstructorListQuery = useQuery({
    queryKey: learnerKeys.instructorList(userId, displayLocale, {
      pageIndex,
      pageSize,
      sorting,
      filtering
    }),
    queryFn: () =>
      getLearnersForInstructor(
        userId,
        pageIndex,
        pageSize,
        sortField,
        sortDirection,
        filtering,
        displayLocale
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: enabled && !!userId
  });

  return {
    learnersForInstructorListQuery
  };
};

export default useLearnersForInstructors;
