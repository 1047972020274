import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppLanguage, AppRoute } from '../../../const';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import {
  deleteFrameworkStage,
  getStages
} from '../../../services/api/framework.service';
import { components } from '../../../types/openapi/FrameworkService';
import Loading from '../../common/Loading';
import { ModalContext } from '../../../modules/modal/ModalProvider';
import { appStrings } from '../../../modules/i18n';
import localiseRoutePath from '../../../utils/localiseRoutePath';
import stageKeys from '../../../query-keys/stage-key-factory';
import { GeneralError } from '../../common';

function StagesTable({
  framework,
  version,
  showEditAndDelete
}: {
  framework: components['schemas']['FrameworkDto'];
  version: components['schemas']['FrameworkVersionDto'];
  showEditAndDelete: boolean;
}) {
  if (!version.frameworkVersionId) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="error.no_framework_version_id"
            defaultMessage="Cannot read version ID"
            description="Cannot read versionID"
          />
        }
      />
    );
  }
  if (!version.frameworkId) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="error.no_framework_id"
            defaultMessage="Cannot read framework ID"
            description="Cannot read framework ID"
          />
        }
      />
    );
  }
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const queryClient = useQueryClient();
  const intl = useIntl();

  const { isLoading, error, data } = useQuery({
    queryKey: stageKeys.list(
      version.frameworkId,
      version.frameworkVersionId,
      displayLocale
    ),
    queryFn: () =>
      getStages(
        version.frameworkId!,
        version.frameworkVersionId,
        displayLocale
      ),
    refetchOnMount: `always`
  });

  const { mutate: deleteFrameworkStageById } = useMutation(
    (stage: number) =>
      deleteFrameworkStage(
        framework.frameworkId,
        version.frameworkVersionId,
        stage
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            'getStages',
            version.frameworkId,
            version.frameworkVersionId
          ]
        });
        toast.success(
          intl.formatMessage({
            id: 'stage.delete.successful',
            defaultMessage: 'Stage Deleted Successfully'
          }),
          { delay: 200 }
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: () => {
        toast.error(
          intl.formatMessage({
            id: 'stage.delete.error',
            defaultMessage: 'There was an error deleting this stage'
          }),
          { delay: 200 }
        );
      }
    }
  );
  async function onModalConfirm(stage: any) {
    deleteFrameworkStageById(stage.stageId);
  }

  function openModal(stage: components['schemas']['StageDto']) {
    const header = intl.formatMessage(
      {
        id: 'stage.delete.confirm.header',
        defaultMessage: 'Delete Stage: {name}',
        description: 'Delete Stage Confirm Modal Header'
      },
      { name: stage.name }
    );

    const modalContent = intl.formatMessage({
      id: 'stage.delete.confirm.body',
      defaultMessage:
        'Are you sure you want to delete this stage? This action cannot be undone',
      description: 'Delete Stage Confirm Modal Body Message'
    });

    const confirmText = intl.formatMessage({
      id: 'stage.delete.confirm.button',
      defaultMessage: 'Delete Stage',
      description: 'Delete Stage Confirm Button'
    });

    modal(modalContent, {
      confirm: () => onModalConfirm(stage),
      header,
      confirmText
    });
  }

  if (isLoading) return <Loading />;

  if (error)
    return (
      <FormattedMessage
        id="version.stages.error"
        defaultMessage="No stages were found for this version"
        description="Name"
      />
    );

  return (
    <table className="table card-list-table">
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="name"
              defaultMessage="Name"
              description="Name"
            />
          </th>
          <th>
            <FormattedMessage
              id="actions.table.header"
              defaultMessage="Actions"
              description="Actions"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((stage: components['schemas']['StageDto']) => {
          return (
            <tr key={stage.stageId}>
              <td data-title="Name">{stage.name}</td>
              <td>
                <div className="btn-group">
                  {!showEditAndDelete && (
                    <Link
                      to={`${localiseRoutePath(AppRoute.Stages)}/${
                        stage.stageId
                      }/${localiseRoutePath(
                        AppRoute.InstructorViewObjectives
                      )}`}
                      className="btn btn-outline-secondary"
                    >
                      <FormattedMessage
                        id="view"
                        defaultMessage="View"
                        description="View"
                      />
                    </Link>
                  )}
                  {showEditAndDelete && (
                    <>
                      <Link
                        to={`${
                          appStrings[displayLocale as AppLanguage][
                            AppRoute.EditStage
                          ]
                        }/${stage.stageId}`}
                        className="btn btn-outline-secondary"
                      >
                        <FormattedMessage
                          id="stage.view"
                          defaultMessage="View"
                          description="View a Framework version Stage"
                        />
                      </Link>
                      <button
                        type="button"
                        disabled={version.isUsed!}
                        className="btn btn-outline-secondary"
                        onClick={() => openModal(stage)}
                      >
                        <FormattedMessage
                          id="stage.delete"
                          defaultMessage="Delete"
                          description="Delete"
                        />
                      </button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default StagesTable;
