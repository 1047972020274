import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useLoaderData } from 'react-router-dom';

import InstructorDashboard from '../components/dashboards/InstructorDashboard';
import isAllowed from '../utils/permissions/isAllowed';
import { UserType } from '../const/user-type';
import AdminDashboard from '../components/dashboards/AdminDashboard';
import SportManagerDashboard from '../components/dashboards/SportManagerDashboard';
import LearnerDashboard from '../components/dashboards/LearnerDashboard';
import useUser from '../hooks/useUser';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import GeneralError from '../components/common/GeneralError';
import { components } from '../types/openapi/UserService';

function Home() {
  const profile = useLoaderData() as components['schemas']['UserProvidersDto'];
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  if (!profile?.userId) {
    console.error('no user id');
    return <GeneralError message="no user id" />;
  }

  const { userQuery } = useUser({
    userId: profile?.userId,
    displayLocale
  });

  return (
    <div className="container-fluid">
      <div className="row gap-2 gap-md-0">
        <div className="col-12">
          {userQuery.data && (
            <h1 className="py-2" data-gdpr="true">
              {dayjs().hour() < 12 ? (
                <FormattedMessage
                  id="user.welcome.morning"
                  defaultMessage="Good Morning {username}"
                  values={{ username: userQuery.data.username }}
                />
              ) : dayjs().hour() < 18 ? (
                <FormattedMessage
                  id="user.welcome.afternoon"
                  defaultMessage="Good Afternoon {username}"
                  values={{ username: userQuery.data.username }}
                />
              ) : (
                <FormattedMessage
                  id="user.welcome.evening"
                  defaultMessage="Good Evening {username}"
                  values={{ username: userQuery.data.username }}
                />
              )}
            </h1>
          )}
          {isAllowed(
            [UserType.INSTRUCTOR],
            profile?.userTypeId as UserType
          ) && <InstructorDashboard />}
          {isAllowed([UserType.ADMIN], profile?.userTypeId as UserType) && (
            <AdminDashboard />
          )}
          {isAllowed(
            [UserType.SPORT_MANAGER],
            profile?.userTypeId as UserType
          ) && <SportManagerDashboard />}
          {isAllowed([UserType.LEARNER], profile?.userTypeId as UserType) && (
            <LearnerDashboard />
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
