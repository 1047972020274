import { StylesConfig } from 'react-select';

const selectStyles: StylesConfig<any, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
};

export default selectStyles;
