import { useIntl } from 'react-intl';
import { UserType } from '../const/user-type';

export default function getFormattedUserType(userTypeId: number) {
  const intl = useIntl();

  switch (userTypeId) {
    case UserType.ADMIN:
      return intl.formatMessage({
        id: 'admin',
        defaultMessage: 'Admin'
      });
      break;
    case UserType.LEARNER:
      return intl.formatMessage({
        id: 'learner',
        defaultMessage: 'Learner'
      });
      break;
    case UserType.INSTRUCTOR:
      return intl.formatMessage({
        id: 'instructor',
        defaultMessage: 'Instructor'
      });
    case UserType.GROUP_ADMIN:
      return intl.formatMessage({
        id: 'groupAdmin',
        defaultMessage: 'Group Admin'
      });
    case UserType.SCHOOL_COORDINATOR:
      return intl.formatMessage({
        id: 'schoolCoordinator',
        defaultMessage: 'School Coordinator'
      });
    default:
      return '';
      break;
  }
}
