import React from 'react';
import { Event } from 'react-big-calendar';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

function SessionAgenda({ event, url }: { event: Event; url: string }) {
  return (
    <div className="d-flex flex-wrap flex-md-nowrap">
      <div className="w-100">
        <span>
          <p className="mb-0 fw-bold">{event.title}</p>
          <p>{(event as any).desc}</p>
        </span>
      </div>
      {url && (
        <div>
          <div className="btn-group">
            <Link
              to={url}
              className={`btn ${
                (event as any).event.bookable
                  ? 'btn-outline-secondary'
                  : 'btn-secondary'
              }`}
            >
              {(event as any).event.bookable ? (
                <FormattedMessage
                  id="book"
                  defaultMessage="Book"
                  description="Book"
                />
              ) : (
                <FormattedMessage
                  id="view"
                  defaultMessage="View"
                  description="View"
                />
              )}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default SessionAgenda;
