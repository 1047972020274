import { useQuery } from '@tanstack/react-query';
import centreKeys from '../../query-keys/centre-key-factory';
import { getProviderCentres } from '../../services/api/provider.service';
import { FilteringObject } from '../../const/filtering-object';

const useProviderCentres = ({
  providerId,
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  providerId: number | null | undefined;
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const centresForProviderListQuery = useQuery({
    queryKey: centreKeys.providerList(
      providerId,
      displayLocale,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ),
    queryFn: () =>
      getProviderCentres(
        providerId,
        displayLocale,
        pageIndex,
        pageSize,
        sorting,
        rawFiltering
      ),
    refetchOnMount: true,
    enabled: !!providerId
  });

  const defaultFirstCentre = centresForProviderListQuery.data?.centres
    ? centresForProviderListQuery.data.centres[0]
    : undefined;

  return {
    centresForProviderListQuery,
    defaultFirstCentre
  };
};

export default useProviderCentres;
