import { HeaderGroup } from '@tanstack/react-table';
import React from 'react';
import { Control, useController } from 'react-hook-form';

function OffcanvasSortingCheckboxes({
  headerGroup,
  control,
  sorting
}: {
  headerGroup: HeaderGroup<any>;
  control: Control<any, any>;
  sorting: any[] | undefined;
}) {
  const { field } = useController({
    control,
    name: 'sorting'
  });

  return (
    <>
      {headerGroup.headers
        .filter((header) => header.column.getCanSort())
        .map((header) => {
          return (
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="sorting"
                defaultChecked={sorting?.find(
                  (ob) => ob.id === header.column.id
                )}
                id={header.id}
                onChange={() => field.onChange(header.id)}
              />
              <label htmlFor={header.id} className="form-check-label">
                {header.column.columnDef.header?.toString()}
              </label>
            </div>
          );
        })}
    </>
  );
}
export default OffcanvasSortingCheckboxes;
