/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';

import { AppRoute } from '../../../const';
import localiseRoutePath from '../../../utils/localiseRoutePath';
import InstructorsTable from '../../instructors-admin/InstructorsTable';
import useInstructors from '../../../hooks/useInstructors';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { FilteringQueryParams } from '../../../const/filtering-query-params';
import useTableColumns from '../../../hooks/table/useTableColumns';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import RemoveInstructorButton from '../../instructors-admin/instructor-list-actions/RemoveInstructorButton';

function CentreInstructorsCard({
  userProviderId,
  centreId
}: {
  userProviderId: number;
  centreId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const [instructorsPagination, setInstructorsPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10
    });
  const [instructorsSorting, setInstructorsSorting] = useState<SortingState>(
    []
  );
  const [instructorsFiltering, setInstructorsFiltering] =
    useState<ColumnFiltersState>([]);

  const { instructorsListQuery } = useInstructors({
    providerId: userProviderId,
    centreId: Number(centreId),
    displayLocale,
    pageIndex: instructorsPagination.pageIndex,
    pageSize: instructorsPagination.pageSize,
    sorting: instructorsSorting,
    rawFiltering: instructorsFiltering
  });

  const {
    usernameColumn,
    emailColumn,
    sportPassportColumn,
    userExternalIdColumn
  } = useTableColumns({ providerId: profile?.providerId });

  const columns = [
    usernameColumn,
    emailColumn,
    sportPassportColumn,
    userExternalIdColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        return (
          profile && (
            <RemoveInstructorButton
              profile={profile}
              instructor={row.original}
            />
          )
        );
      },
      filterType: undefined
    }
  ];

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="instructors"
            defaultMessage="Instructors"
            description="Instructors"
          />
        </h2>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-end">
          <Link
            to={`${localiseRoutePath(AppRoute.AssignInstructorToCentre)}`}
            className="btn btn-primary"
          >
            <FormattedMessage
              id="instructor.assign_to_centre.button"
              defaultMessage="Assign instructor"
              description="Assign Instructor to centre"
            />
          </Link>
        </div>
        {instructorsListQuery && (
          <InstructorsTable
            data={instructorsListQuery.data}
            error={instructorsListQuery.error}
            columns={columns}
            isLoading={instructorsListQuery.isLoading}
            refetching={instructorsListQuery.isRefetching}
            pagination={instructorsPagination}
            sorting={instructorsSorting}
            filtering={instructorsFiltering}
            setPagination={setInstructorsPagination}
            setSorting={setInstructorsSorting}
            setFiltering={setInstructorsFiltering}
            queryParamsLocation={FilteringQueryParams.CentreInstructors}
          />
        )}
      </div>
    </div>
  );
}

export default CentreInstructorsCard;
