import React from 'react';
import AsteriskSolid from '../assets/images/asterisk-solid.svg';

function Asterisk() {
  return (
    <img src={AsteriskSolid} width="8px" className="pb-3 me-1" alt="required" />
  );
}

export default Asterisk;
