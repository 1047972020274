/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { components } from '../../../../../../types/openapi/CourseService';
import { components as frameworkComponents } from '../../../../../../types/openapi/FrameworkService';
import ReadOnlyStar from '../../../../../progress/ReadOnlyStar';

import LearnerCell from '../instructor-table/LearnerCell';
import ObjectiveStars from './ObjectiveStars';
import ObjectivesOverviewTableHead from './ObjectivesOverviewTableHead';

function ObjectivesOverviewTable({
  frameworkId,
  frameworkVersionId,
  stageId,
  learners,
  objectives,
  starsForUsers
}: {
  frameworkId: number;
  frameworkVersionId: number;
  stageId: number;
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][];
  objectives:
    | frameworkComponents['schemas']['ObjectiveDto'][]
    | components['schemas']['ObjectiveSlimDto'][];
  starsForUsers: components['schemas']['StarsForUserDto'][];
}) {
  const intl = useIntl();
  const getObjectiveStarsForLearnerForStage = (
    learnerId: number,
    objectiveId: number | undefined
  ) => {
    const learnerStarsForStage = starsForUsers.find(
      (courseStars) =>
        courseStars.userId === learnerId && courseStars.stageId === stageId
    );
    if (learnerStarsForStage?.objectiveStars) {
      return learnerStarsForStage.objectiveStars.find(
        (stars) => stars.objectiveId === objectiveId
      );
    }
    return undefined;
  };

  const getCompletedObjectiveCount = (learnerId: number) => {
    const learnerStarsForStage = starsForUsers.find(
      (courseStars) =>
        courseStars.userId === learnerId && courseStars.stageId === stageId
    );
    const completedStars = learnerStarsForStage?.objectiveStars?.filter(
      (objectiveStar) =>
        objectiveStar.numberOfStars && objectiveStar.numberOfStars >= 3
    );
    return completedStars?.length;
  };
  const objectivesColumns: ColumnDef<any>[] = objectives.map(
    (objective, index) => {
      return {
        id: `objective-${objective.objectiveId}`,
        header: `${index + 1}`,
        accessorKey: `${objective.objectiveId}`,
        enableSorting: false,
        // eslint-disable-next-line
        cell: ({ row }: { row: any }) => {
          const starsForUser = getObjectiveStarsForLearnerForStage(
            row.original.userId,
            objective.objectiveId
          );
          if (starsForUser)
            return (
              <ObjectiveStars
                starsForUser={starsForUser}
                renderStarElement={({ userStarValue, inputStarValue }) => {
                  return (
                    <ReadOnlyStar
                      usersStar={userStarValue}
                      starValue={inputStarValue}
                    />
                  );
                }}
              />
            );
        }
      };
    }
  );

  const columns = [
    {
      id: 'name',
      header: intl.formatMessage({
        id: 'name',
        defaultMessage: 'Name',
        description: 'Name'
      }),
      accessorKey: 'username',
      enableSorting: true,
      sortDescFirst: true,
      cell: ({ row }: { row: any }) => (
        <div className="me-3">
          <LearnerCell row={row} />
          <p className="mt-3">
            <FormattedMessage
              id="stage.objectives.complete"
              defaultMessage="{userCompleteCount}/{numberOfObjectives } objectives {objectivesComplete}"
              description="objectives complete"
              values={{
                userCompleteCount: getCompletedObjectiveCount(
                  row.original.userId
                ),
                numberOfObjectives: objectives.length,
                objectivesComplete: (
                  <FormattedMessage
                    id="complete"
                    defaultMessage="complete"
                    description="complete"
                  />
                )
              }}
            />
          </p>
        </div>
      )
    },
    objectivesColumns
  ].flat();

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'username', desc: true }
  ]);

  const table = useReactTable({
    data: learners as components['schemas']['UserDto'][],
    columns,
    state: {
      sorting
    },
    enableRowSelection: true,
    enableSorting: true,
    sortDescFirst: true,

    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false
  });

  return (
    <div className="table-responsive">
      <table className="table objectives-overview-table">
        <ObjectivesOverviewTableHead
          frameworkId={frameworkId}
          frameworkVersionId={frameworkVersionId}
          stageId={stageId}
          table={table}
        />
        <tbody>
          {table.getRowModel().rows.map((row: Row<any>) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={`${
                        cell.column.id === 'objectives' ? 'border-start' : ''
                      } align-middle`}
                      data-title={cell.column.columnDef.header}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ObjectivesOverviewTable;
