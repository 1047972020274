/* eslint-disable no-nested-ternary */
import React, { ReactNode, useContext } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import {
  faClock,
  faClone,
  faBarsStaggered
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GeneralError } from '../common';
import Loading from '../common/Loading';
import useFramework from '../../hooks/frameworks/useFramework';
import useFrameworkVersion from '../../hooks/frameworkVersion/useFrameworkVersion';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import InfoPanel from '../ui/InfoPanel';

function FrameworkAndVersionInfoPanels({
  frameworkId,
  frameworkVersionId,
  frameworkInfoPanel
}: {
  frameworkId: number;
  frameworkVersionId: number;
  frameworkInfoPanel?: JSX.Element | ReactNode;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { frameworkQuery } = useFramework({
    frameworkId: Number(frameworkId),
    displayLocale
  });
  const { frameworkVersionQuery } = useFrameworkVersion({
    frameworkId: Number(frameworkId),
    frameworkVersionId: Number(frameworkVersionId),
    displayLocale
  });

  return (
    <div className="row">
      <div className="col-12 d-flex flex-column flex-sm-row gap-3">
        {frameworkInfoPanel}

        <InfoPanel faIcon={<FontAwesomeIcon icon={faClone} />}>
          {frameworkVersionQuery.data ? (
            frameworkVersionQuery.data?.name
          ) : frameworkQuery.isError ? (
            <GeneralError />
          ) : (
            frameworkQuery.isLoading && <Loading />
          )}
        </InfoPanel>
        <InfoPanel faIcon={<FontAwesomeIcon icon={faBarsStaggered} />}>
          {frameworkVersionQuery.data ? (
            frameworkVersionQuery.data?.description
          ) : frameworkQuery.isError ? (
            <GeneralError />
          ) : (
            frameworkQuery.isLoading && <Loading />
          )}
        </InfoPanel>
        <InfoPanel faIcon={<FontAwesomeIcon icon={faClock} />}>
          {frameworkVersionQuery.data ? (
            frameworkVersionQuery.data.createdDate ? (
              <FormattedMessage
                id="created date"
                defaultMessage="Created {date}"
                description="Created {date}"
                values={{
                  date: (
                    <FormattedDate
                      value={new Date(frameworkVersionQuery.data.createdDate)}
                      year="numeric"
                      month="numeric"
                      day="2-digit"
                    />
                  )
                }}
              />
            ) : (
              <span>&ndash;</span>
            )
          ) : frameworkQuery.isError ? (
            <GeneralError />
          ) : (
            frameworkQuery.isLoading && <Loading />
          )}
        </InfoPanel>
      </div>
    </div>
  );
}

export default FrameworkAndVersionInfoPanels;

FrameworkAndVersionInfoPanels.defaultProps = {
  frameworkInfoPanel: null
};
