import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppLanguage, AppRoute } from '../../../const';
import { UserType } from '../../../const/user-type';
import { appStrings } from '../../../modules/i18n';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { components } from '../../../types/openapi/UserService';
import { isAllowed } from '../../../utils/permissions';
import { Loading, GeneralError } from '../../common';
import CardHeader from '../../ui/CardHeader';
import UserEmergencyDetailsTable from '../../users/UserEmergencyDetailsTable';
import UserDetailsTable from '../../users/UserGeneralDetailsTable';
import LearnerCentres from './LearnerCentres';
import LearnerCourses from './LearnerCourses';
import useUserPermissions from '../../../hooks/permissions/useUserPermissions';
import LearnerSchool from './LearnerSchools';

function ViewLearnerDashboard({
  learnerId,
  providerId,
  isLoading,
  error,
  data,
  profileEditingEnabled,
  editCentresLink
}: {
  learnerId: number;
  providerId: number;
  isLoading: boolean;
  error: any;
  data: components['schemas']['UserDto'] | undefined;
  profileEditingEnabled: boolean | undefined;
  editCentresLink: string;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { permissionsQuery } = useUserPermissions({
    userId: learnerId,
    displayLocale
  });

  const schoolId = permissionsQuery.data?.find(
    (userProvider) => !!userProvider.schoolId
  )?.schoolId;
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="card">
            <CardHeader
              button={
                isAllowed(
                  [
                    UserType.ADMIN,
                    UserType.SUPER_ADMIN,
                    UserType.SPORT_MANAGER
                  ],
                  profile?.userTypeId as UserType
                ) &&
                profileEditingEnabled && (
                  <div>
                    <Link
                      to={`/${displayLocale}/${
                        appStrings[displayLocale as AppLanguage][
                          AppRoute.Learners
                        ]
                      }/${learnerId}/${
                        appStrings[displayLocale as AppLanguage][
                          AppRoute.EditLearner
                        ]
                      }`}
                      className="btn btn-primary mx-auto"
                    >
                      <FormattedMessage
                        id="edit.details.button"
                        defaultMessage="Edit details"
                        description="Edit details"
                      />
                    </Link>
                  </div>
                )
              }
            >
              <h3>
                <FormattedMessage
                  id="details"
                  defaultMessage="Details"
                  description="Details"
                />
              </h3>
            </CardHeader>

            <div className="card-body">
              {isLoading ? (
                <Loading />
              ) : error ? (
                (error as any)?.response.status === 401 ? (
                  <FormattedMessage
                    id="users.error.unauthorised"
                    defaultMessage="You are not authorised to access this user"
                    description="You are not authorised to access this user"
                  />
                ) : (
                  <GeneralError />
                )
              ) : (
                data && <UserDetailsTable user={data} />
              )}
            </div>
          </div>
        </div>
        <div className=" col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3>
                <FormattedMessage
                  id="emergency_details"
                  defaultMessage="Emergency Contact Details"
                  description="Emergency Contact Details"
                />
              </h3>
            </div>
            <div className="card-body ">
              {isLoading ? (
                <Loading />
              ) : error ? (
                (error as any)?.response.status === 401 ? (
                  <FormattedMessage
                    id="users.error.unauthorised"
                    defaultMessage="You are not authorised to access this user"
                    description="You are not authorised to access this user"
                  />
                ) : (
                  <GeneralError />
                )
              ) : (
                data && <UserEmergencyDetailsTable user={data} />
              )}
            </div>
          </div>
        </div>
      </div>
      <LearnerCourses userId={Number(learnerId)} />
      {learnerId && (
        <LearnerCentres
          providerId={providerId}
          userId={Number(learnerId)}
          editCentresLink={editCentresLink}
        />
      )}

      <div className="card">
        <CardHeader>
          <h3>
            <FormattedMessage
              id="school.header"
              defaultMessage="School"
              description="School"
            />
          </h3>
        </CardHeader>
        {permissionsQuery.isLoading ? (
          <Loading />
        ) : permissionsQuery.error ? (
          <GeneralError />
        ) : permissionsQuery.data && schoolId ? (
          <LearnerSchool providerId={providerId} schoolId={schoolId} />
        ) : (
          <div className="card-body">
            <p className="alert alert-warning">
              <FormattedMessage
                id="school.error.none"
                defaultMessage="No school found"
              />
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default ViewLearnerDashboard;
