import { useQuery } from '@tanstack/react-query';
import { AppLanguage } from '../../const';
import frameworkKeys from '../../query-keys/framework-key-factory';
import { getFrameworks } from '../../services/api/framework.service';

// hook for managing frameworks for Sport Manager - Global frameworks
const useFrameworks = ({
  displayLocale = AppLanguage.English,
  providerId
}: {
  displayLocale: string | undefined;
  providerId?: number | null | undefined;
}) => {
  const frameworksListQuery = useQuery({
    queryKey: frameworkKeys.frameworksByProvider(providerId, displayLocale),
    queryFn: () => getFrameworks(displayLocale, providerId),
    refetchOnMount: true
  });

  const activeFrameworksList = frameworksListQuery.data?.filter(
    (framework) => framework.frameworkVersion
  );

  return {
    frameworksListQuery,
    activeFrameworksList
  };
};

export default useFrameworks;
