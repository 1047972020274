import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import SummaryInfo from './SummaryInfo';
import useFrameworks from '../../hooks/frameworks/useFrameworks';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function SportManagerDashboard() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { frameworksListQuery } = useFrameworks({ displayLocale });
  return (
    <div className="dashboard mt-3">
      <div className="row mb-3">
        <div className="col-12">
          <div className="card border-0 rounded-top-right-lg ">
            <div className="card-header rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="manager.summary"
                  defaultMessage="Sport Summary"
                  description="Summary for Sport Managers"
                />
              </h2>
            </div>
            <div className="card-body ">
              <SummaryInfo frameworksListQuery={frameworksListQuery} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SportManagerDashboard;
