import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import { ProfileContext } from '../modules/profile/ProfileProvider';
import GeneralError from '../components/common/GeneralError';
import SyncInfo from '../components/offline/SyncInfo';
import { UserType } from '../const/user-type';
import isAllowed from '../utils/permissions/isAllowed';
import SyncLog from '../components/offline/SyncLog';
import DiagnosticInformation from '../components/system-diagnostics/DiagnosticInformation';

function SystemDiagnostics() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  if (!profile?.userId) {
    console.error('no user id');
    return <GeneralError message="no user id" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.system_diagnostics',
            defaultMessage: 'System diagnostics'
          })}
        </title>
      </Helmet>
      <div className="row">
        <div className="col">
          <div className="card mh-vh-70 border-0 rounded-top-right-lg">
            <div className="card-header rounded-top-right-lg d-flex justify-content-between">
              <h1>
                <FormattedMessage
                  id="system_diagnostics"
                  defaultMessage="System Diagnostics"
                  description="System Diagnostics"
                />
              </h1>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-2 gap-md-0">
        {isAllowed([UserType.INSTRUCTOR], profile?.userTypeId as UserType) && (
          <div className="col-12 col-md-6">
            <div className="card border-0 rounded-top-right-lg">
              <div className="card-header">
                <h2>
                  <FormattedMessage
                    id="offline.sync-info"
                    defaultMessage="Sync info"
                    description="Sync info"
                  />
                </h2>
              </div>
              <div className="card-body">
                <SyncInfo />
              </div>
            </div>
          </div>
        )}

        <div className="col-12 col-md-6">
          <DiagnosticInformation />
        </div>
      </div>
      {/* sync errors */}
      {isAllowed([UserType.INSTRUCTOR], profile?.userTypeId as UserType) && (
        <div className="row mt-2">
          <div className="col-12">
            <SyncLog />
          </div>
        </div>
      )}
    </>
  );
}

export default SystemDiagnostics;
