import { ProviderConfigAppLanguage } from '../../const';
import { components } from '../../types/openapi/ProviderService';

const getProviderLanguagesFromConfig = (
  config: components['schemas']['ProviderConfigurationDto'][] | undefined
) => {
  return (
    (config &&
      config.length > 0 &&
      config
        .filter((el) => el.key.includes('Language') && el.value === '1')
        .map((el) => {
          const splitStr = el.key.split('-');
          return ProviderConfigAppLanguage[
            splitStr[0] as keyof typeof ProviderConfigAppLanguage
          ];
        })) || [
      ProviderConfigAppLanguage.English,
      ProviderConfigAppLanguage.Welsh
    ]
  );
};

export default getProviderLanguagesFromConfig;
