/* eslint-disable react/jsx-no-useless-fragment */

import {
  faDownload,
  faCircleCheck,
  faClockRotateLeft,
  faWarning
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QueryKey, useIsFetching, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function QueryStatus({
  queryKey,
  detailsText
}: {
  queryKey: QueryKey;
  detailsText: JSX.Element;
}) {
  const queryClient = useQueryClient();
  const isQueryFetching = useIsFetching(queryKey);

  const queryData = queryClient.getQueryData(queryKey);

  const queryStatus = queryClient.getQueryState(queryKey);
  return (
    <li>
      {' '}
      {queryData ? (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="me-1 color-brand-green"
        />
      ) : isQueryFetching ? (
        <FontAwesomeIcon icon={faDownload} className="me-1 color-brand-amber" />
      ) : queryStatus?.error ? (
        <FontAwesomeIcon
          icon={faWarning}
          className="me-1"
          style={{ color: 'red' }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faClockRotateLeft}
          className="me-1"
          style={{ color: 'grey' }}
        />
      )}
      <>
        {detailsText}
        {queryData ? (
          <span className="text-muted small fst-italic">
            {' '}
            - <FormattedMessage id="downloaded" defaultMessage="downloaded" />
          </span>
        ) : isQueryFetching ? (
          <span className="text-muted small fst-italic">
            {' '}
            - <FormattedMessage id="downloading" defaultMessage="downloading" />
          </span>
        ) : queryStatus?.error &&
          (queryStatus.error as any).response?.data ===
            'No Members Found Found' ? (
          <span className="text-muted small fst-italic">
            {' '}
            -{' '}
            <FormattedMessage
              id="error.no-members"
              defaultMessage="Error, no learners on this session."
            />
          </span>
        ) : queryStatus?.error ? (
          <span className="text-muted small fst-italic">
            {' '}
            -{' '}
            <FormattedMessage
              id="error.retry"
              defaultMessage="Error, please retry downloading the session"
            />
          </span>
        ) : (
          <span className="text-muted small fst-italic">
            {' '}
            -{' '}
            <FormattedMessage
              id="downloaded.not"
              defaultMessage="Ready for download"
            />
          </span>
        )}
      </>
    </li>
  );
}

export default QueryStatus;
