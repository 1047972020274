import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import LanguageSwitcher from '../../../modules/i18n/components/LanguageSwitcher';
import { ProviderConfigAppLanguage } from '../../../const';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

function LanguageSwitchNavDropdown({
  providerLanguages
}: {
  providerLanguages: ProviderConfigAppLanguage[];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  return (
    <Dropdown
      as="nav"
      aria-label={intl.formatMessage({
        id: 'language.nav',
        defaultMessage: 'Language'
      })}
    >
      <DropdownButton
        title={
          <div>
            <FontAwesomeIcon icon={icon({ name: 'globe', style: 'solid' })} />
            &nbsp;
            {displayLocale}
          </div>
        }
        id="language-switch-dropdown"
        className="nav-link--icon"
        drop="down"
        align="end"
      >
        <LanguageSwitcher languages={providerLanguages} />
      </DropdownButton>
    </Dropdown>
  );
}

export default LanguageSwitchNavDropdown;
