import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useCourse from '../../../hooks/course/useCourse';
import useFrameworkProgress from '../../../hooks/useFrameworkProgress';
import useLearner from '../../../hooks/useLearner';
import useStages from '../../../hooks/useStages';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import AppMode from '../../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../../modules/offline/OfflineProvider';
import { Loading, GeneralError } from '../../common';
import CourseDetailsCard from '../../learners-admin/learner-on-course/CourseDetailsCard';
import EditNotesWrapper from '../../learners-admin/learner-on-course/EditNotesWrapper';
import LearnerCard from '../../learners-admin/learner-on-course/LearnerCard';
import ProgressCard from '../../learners-admin/learner-on-course/ProgressCard';
import AttendancePercentage from '../../progress/AttendancePercentage';
import CourseStageObjectivesWrapper from '../../progress/CourseStageObjectivesWrapper';
import UserEmergencyDetailsTable from '../../users/UserEmergencyDetailsTable';
import UserDetailsTable from '../../users/UserGeneralDetailsTable';

function OnlineViewLearnerOnCourse() {
  const { courseId, learnerId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  const { learnerQuery: learner } = useLearner({
    userId: Number(learnerId),
    displayLocale
  });

  const { courseQuery: course, courseStagesQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const { stagesQuery } = useStages({
    frameworkId: course.data?.frameworkVersion?.frameworkId,
    frameworkVersionId: course.data?.frameworkVersionId,
    displayLocale
  });

  const { currentFrameworkStageQuery } = useFrameworkProgress({
    frameworkVersionId: course.data?.frameworkVersionId,
    learnerId: Number(learnerId),
    displayLocale
  });

  if (course.data)
    return (
      <>
        <Helmet>
          <title>
            {learner.data?.username ||
              intl.formatMessage({
                id: 'title.learner',
                defaultMessage: 'Learner'
              })}
            {}
          </title>
        </Helmet>
        <div className="row">
          <div className="col-12">
            <div className="card col-12 rounded-top-right-lg border-0 mb-2">
              <div className="card-header rounded-top-right-lg border-0">
                <h2 data-gdpr="true">
                  {' '}
                  <LearnerCard learnerId={Number(learnerId)} />
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 g-md-2">
          <div className="col-12 col-lg-4 d-flex">
            <CourseDetailsCard
              course={course.data}
              courseStages={courseStagesQuery.data}
              className="mb-2 mb-md-0 w-100"
            />
          </div>

          <div className="col col-12 col-md-6 col-lg-4 d-flex">
            {course.data && learner.data ? (
              <ProgressCard
                className="mb-2 mb-md-0 w-100"
                course={course.data}
                learner={learner.data}
              />
            ) : course.error || learner.error ? (
              <GeneralError />
            ) : (
              <Loading />
            )}
          </div>
          <div className="col col-12 col-md-6 col-lg-4">
            <div className="card rounded-top-right-lg h-100 border-0">
              <div className="card-header rounded-top-right-lg">
                <h2>
                  <FormattedMessage
                    id="learner.attendance"
                    defaultMessage="Attendance to date"
                    description="Attendance to date"
                  />
                </h2>
              </div>
              <div className="card-body d-flex justify-content-center align-items-center">
                <div>
                  <AttendancePercentage
                    courseId={Number(courseId)}
                    learnerId={Number(learnerId)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {course.data && (
          <EditNotesWrapper providerId={course.data.providerId} />
        )}
        <div className="row">
          <div className="col-12">
            <CourseStageObjectivesWrapper
              courseId={Number(courseId)}
              learnerId={Number(learnerId)}
              isEditable={appMode !== AppMode.OFFLINE}
              stages={stagesQuery.data}
              currentFrameworkStage={currentFrameworkStageQuery.data}
              currentFrameworkStageError={currentFrameworkStageQuery.error}
              course={course.data}
              courseStages={courseStagesQuery.data}
            />
          </div>
        </div>

        <div className="row g-2">
          <div className="col col-12 col-lg-6">
            <div className="card h-100 border-0 rounded-top-right-lg">
              <div className="card-header rounded-top-right-lg">
                <h2>
                  <FormattedMessage
                    id="details"
                    defaultMessage="Details"
                    description="Details"
                  />
                </h2>
              </div>
              <div className="card-body">
                {learner.isFetching ? (
                  <Loading />
                ) : learner.error &&
                  (learner.error as any).message !== 'Network Error' ? (
                  (learner.error as any)?.response.status === 401 ? (
                    <FormattedMessage
                      id="users.error.unauthorised"
                      defaultMessage="You are not authorised to access this user"
                      description="You are not authorised to access this user"
                    />
                  ) : (
                    <GeneralError />
                  )
                ) : (
                  learner.data && <UserDetailsTable user={learner.data} />
                )}
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-6 ">
            <div className="card border-0 h-100 rounded-top-right-lg">
              <div className="card-header rounded-top-right-lg">
                <h2>
                  <FormattedMessage
                    id="emergency_details"
                    defaultMessage="Emergency Contact Details"
                    description="Emergency Contact Details"
                  />
                </h2>
              </div>
              <div className="card-body ">
                {learner.isFetching ? (
                  <Loading />
                ) : learner.error &&
                  (learner.error as any).message !== 'Network Error' ? (
                  (learner.error as any)?.response.status === 401 ? (
                    <FormattedMessage
                      id="users.error.unauthorised"
                      defaultMessage="You are not authorised to access this user"
                      description="You are not authorised to access this user"
                    />
                  ) : (
                    <GeneralError />
                  )
                ) : (
                  learner.data && (
                    <UserEmergencyDetailsTable user={learner.data} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return <Loading />;
}

export default OnlineViewLearnerOnCourse;
