import { AppLanguage } from '../const';

const centreKeys = {
  // everything to do with centres
  all: (displayLocale: string | undefined) =>
    ['centres', displayLocale || AppLanguage.English] as const,
  // list of centres
  list: (displayLocale: string | undefined) =>
    [...centreKeys.all(displayLocale || AppLanguage.English), 'list'] as const,
  summaryCount: (
    providerId: number | undefined | null,
    displayLocale: string | undefined,
    filtering: any | null | undefined
  ) =>
    [
      ...centreKeys.list(displayLocale || AppLanguage.English),
      'summary',
      providerId,
      filtering
    ] as const,
  providerList: (
    providerId: number | undefined | null,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    filtering: any | null | undefined
  ) =>
    [
      ...centreKeys.list(displayLocale || AppLanguage.English),
      'provider',
      providerId,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ] as const,
  centre: (centreId: number, displayLocale: string | undefined) =>
    [
      ...centreKeys.all(displayLocale || AppLanguage.English),
      centreId
    ] as const,
  userProviderList: (
    providerId: number | undefined | null,
    userId: number | undefined | null,
    userTypeId: number | undefined | null,
    displayLocale: string | undefined,
    pageIndex?: number | null | undefined,
    pageSize?: number | null | undefined,
    sorting?: any | null | undefined,
    filtering?: any | null | undefined
  ) =>
    [
      ...centreKeys.list(displayLocale || AppLanguage.English),
      userTypeId,
      userId,
      providerId,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ] as const,
  userList: (
    userId: number | undefined | null,
    userTypeId: number | undefined | null,
    displayLocale: string | undefined
  ) =>
    [
      ...centreKeys.list(displayLocale || AppLanguage.English),
      userTypeId,
      userId
    ] as const,
  instructors: (
    centreId: number | null | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...centreKeys.all(displayLocale || AppLanguage.English),
      centreId,
      'instructors'
    ] as const,
  adminsForCentre: (centreId: number, displayLocale: string | undefined) => [
    ...centreKeys.all(displayLocale || AppLanguage.English),
    centreId,
    'admins'
  ]
};

export default centreKeys;
