import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import GameDragon from '../../assets/images/game-dragon.jpg';
import { appStrings } from '../../modules/i18n';
import { AppLanguage, AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function DragonGameNavCard() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  return (
    <div className="card rounded-top-right-lg border-0 mb-3 mb-lg-0">
      <div className="card-header rounded-top-right-lg">
        <h2>
          <FormattedMessage
            id="game.header"
            defaultMessage="Game"
            description="Game"
          />
        </h2>
      </div>

      <div className="card-body container align-content-center">
        <img
          src={GameDragon}
          className="mx-auto d-block h-25 w-25"
          alt="The purple 'Dragon Chase game' dragon"
        />
        <div className="text-center m-3">
          <p>
            <FormattedMessage
              id="game.navtext"
              defaultMessage="The more goals you complete, the more game levels are unlocked!"
              description="text for game nav card"
            />
          </p>
          <Link
            type="button"
            className="btn btn-chevron btn-chevron-navy col-6"
            to={appStrings[displayLocale as AppLanguage][AppRoute.DragonGame]}
          >
            <FormattedMessage
              id="game.button"
              defaultMessage="My game"
              description="My game"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DragonGameNavCard;
