import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import MainContainer from '../components/layout/MainContainer';
import UserFrameworkList from '../components/resources/UserFrameworkList';
import UserViewFramework from '../components/resources/UserViewFramework';
import UserViewObjectives from '../components/resources/UserViewObjectives';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import ErrorPage from '../pages/ErrorPage';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';

export default function resourcesRoutes(
  lang: AppLanguage | string | undefined
) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Resources],
    element: (
      <ProtectedRoute allowedRoles={[UserType.INSTRUCTOR, UserType.LEARNER]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="resources.breadcrumb"
          defaultMessage="Resources"
          description="Resources"
        />
      )
    },
    children: [
      {
        index: true,
        element: <UserFrameworkList />,
        errorElement: <ErrorPage />
      },
      {
        path: `:frameworkId/${
          appStrings[langVal][AppRoute.Versions]
        }/:frameworkVersionId`,

        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="stages.breadcrumb"
              defaultMessage="Stages"
              description="Stages"
            />
          )
        },
        children: [
          {
            index: true,
            element: <UserViewFramework />,
            errorElement: <ErrorPage />
          },

          {
            path: `${appStrings[langVal][AppRoute.Stages]}/:stageId/${
              appStrings[langVal][AppRoute.InstructorViewObjectives]
            }`,
            element: <UserViewObjectives />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="objectives.breadcrumb"
                  defaultMessage="Objectives"
                  description="Objectives"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.Stages]}/:stageId/${
              appStrings[langVal][AppRoute.InstructorViewObjectives]
            }/:objectiveId`,
            element: <UserViewObjectives />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="objectives.breadcrumb"
                  defaultMessage="Objectives"
                  description="Objectives"
                />
              )
            }
          }
        ]
      }
    ]
  };
  return routes;
}
