import React, { useContext } from 'react';

import RoleSelectCard from './RoleSelectCard';
import useUserPermissions from '../../hooks/permissions/useUserPermissions';
import { AuthContext } from '../../modules/auth/AuthProvider';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function RolesList() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const authContext = useContext(AuthContext);
  const { decodedUserFromToken } = authContext;
  const { filteredRoles } = useUserPermissions({
    userId: Number(decodedUserFromToken?.userId),
    displayLocale
  });

  return filteredRoles ? (
    <div
      className={`roles-list d-flex gap-3 mx-auto justify-content-evenly ${
        filteredRoles.length > 4 ? 'roles-list-vertical' : ''
      }`}
    >
      {filteredRoles.map((role) => (
        <RoleSelectCard
          roleType={role.userType}
          userPermissions={role.permissions}
          key={`role-select-card__${role.permissions[0].userProviderId}`}
        />
      ))}
    </div>
  ) : (
    <div>no data</div>
  );
}

export default RolesList;
