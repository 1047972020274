import { useQueryClient } from '@tanstack/react-query';
import courseKeys from '../query-keys/course-key-factory';
import learnerKeys from '../query-keys/learner-key-factory';
import sessionKeys from '../query-keys/session-key-factory';
import stageKeys from '../query-keys/stage-key-factory';
import { components } from '../types/openapi/UserService';

const useSyncLogQueryData = (
  variables: {
    [key: string]: number | undefined;
  },
  displayLocale: string
) => {
  const queryClient = useQueryClient();

  const {
    learnerId,
    courseId,
    sessionId,
    stageId,
    frameworkId,
    frameworkVersionId
  } = variables;

  const learnerData =
    learnerId &&
    queryClient.getQueryData<components['schemas']['UserDto']>(
      learnerKeys.learner(learnerId, displayLocale)
    );

  const courseData =
    courseId &&
    queryClient.getQueryData<components['schemas']['CourseDto']>(
      courseKeys.course(courseId, displayLocale)
    );

  const sessionData =
    courseId &&
    sessionId &&
    queryClient.getQueryData<components['schemas']['CourseSessionDto']>(
      sessionKeys.session(courseId, sessionId, displayLocale)
    );

  const stagesData =
    frameworkId &&
    frameworkVersionId &&
    queryClient.getQueryData<components['schemas']['StageDto'][]>(
      stageKeys.list(frameworkId, frameworkVersionId, displayLocale)
    );
  return {
    learnerId,
    courseId,
    sessionId,
    stageId,
    frameworkId,
    frameworkVersionId,
    learnerData,
    courseData,
    sessionData,
    stagesData
  };
};

export default useSyncLogQueryData;
