import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import Loading from '../../common/Loading';
import { AppLanguage } from '../../../const';
import MultilingualCentreForm from './MultilingualCentreForm';
import { components } from '../../../types/openapi/ProviderService';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { GeneralError } from '../../common';
import useCentre from '../../../hooks/centre/useCentre';
import { Subset } from '../../../types/Subset.type';

function ManageCentreTranslationQuery() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const { centreId } = useParams();

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const userProviderId = profile?.providerId;

  if (!userProviderId) {
    return <GeneralError message="no provider id" />;
  }
  const [translationLocale, setTranslationLocale] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (displayLocale === AppLanguage.English) {
      setTranslationLocale(AppLanguage.Welsh);
    }
    if (displayLocale === AppLanguage.Welsh) {
      setTranslationLocale(AppLanguage.English);
    }
  }, [displayLocale]);

  const { centreQuery, updateCentre } = useCentre({
    centreId: Number(centreId),
    providerId: userProviderId,
    displayLocale
  });

  const {
    centreQuery: centreTranslationQuery,
    updateCentre: updateCentreTranslation
  } = useCentre({
    centreId: Number(centreId),
    providerId: userProviderId,
    displayLocale: translationLocale
  });

  const onSubmit = (value: {
    defaultLang: Pick<
      components['schemas']['CentreDto'],
      'name' | 'description' | 'address'
    >;
    translation: Pick<
      components['schemas']['CentreDto'],
      'name' | 'description' | 'address'
    >;
  }) => {
    const { defaultLang, translation } = value;
    // original exists and has changes
    if (userProviderId && displayLocale && defaultLang.name) {
      const newCentre: Subset<components['schemas']['CentreDto']> = {
        name: defaultLang.name,
        description: defaultLang.description,
        address: {
          ...defaultLang.address,
          addressId: centreQuery.data?.address?.addressId
        },
        providerId: userProviderId,
        centreId: Number(centreId)
      };

      updateCentre.mutate(newCentre, {
        onSuccess: () => {
          toast.success(
            intl.formatMessage({
              id: 'centre.translation.success',
              defaultMessage: 'Centre translation has been updated'
            }),
            { delay: 200 }
          );
        }
      });
    }
    // translation exists
    if (userProviderId && translationLocale && translation.name) {
      const translatedCentre: Subset<components['schemas']['CentreDto']> = {
        name: translation.name,
        description: translation.description,
        address: {
          ...translation.address,
          addressId: centreQuery.data?.address?.addressId
        },
        providerId: userProviderId,
        centreId: Number(centreId)
      };

      updateCentreTranslation.mutate(translatedCentre, {
        onSuccess: () => {
          toast.success(
            intl.formatMessage({
              id: 'centre.translation.success',
              defaultMessage: 'Centre translation has been updated'
            }),
            { delay: 200 }
          );
        }
      });
    }
  };

  if (centreQuery.isFetching || centreTranslationQuery.isFetching)
    return <Loading />;

  //   if (centreQuery.error || centreTranslationQuery.error) return <GeneralError />;

  return (
    <div className="card">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.manage.translation',
            defaultMessage: 'Manage translation'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="manage.translation"
            defaultMessage="Manage Translation"
            description="Manage Translation"
          />
        </h2>
        <div>
          {centreQuery.isError && (
            <div className="alert alert-warning" role="alert">
              <FormattedMessage
                id="manage.translation.loading.data.error"
                defaultMessage="There was a problem loading the data for {language}"
                description="There was a problem loading the data for language"
                values={{ language: displayLocale }}
              />{' '}
            </div>
          )}

          {centreTranslationQuery.isError && (
            <div className="alert alert-warning" role="alert">
              <FormattedMessage
                id="manage.translation.loading.data.error"
                defaultMessage="There was a problem loading the data for {language}"
                description="There was a problem loading the data for language"
                values={{ language: translationLocale }}
              />{' '}
            </div>
          )}
        </div>
      </div>

      <div className="card-body">
        {centreQuery.data && (
          <MultilingualCentreForm
            onSubmit={onSubmit}
            formDisabled={
              centreQuery.isFetching ||
              centreTranslationQuery.isFetching ||
              centreQuery.isLoading ||
              centreTranslationQuery.isLoading
            }
            defaultLangName={displayLocale || ''}
            defaultLang={{
              name: centreQuery.data.name,
              description: centreQuery.data.description,
              address: centreQuery.data?.address
            }}
            translationLangName={translationLocale || ''}
            translation={{
              name: centreTranslationQuery?.data?.name || '',
              description: centreTranslationQuery?.data?.description || '',
              address: centreTranslationQuery.data?.address
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ManageCentreTranslationQuery;
