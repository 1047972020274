import { lazy } from 'react';
import RequestResetPasswordPage from './RequestResetPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';

const ErrorPage = lazy(() => import('./ErrorPage'));
const LoginPage = lazy(() => import('./LoginPage'));
const HoldingPage = lazy(() => import('./HoldingPage'));
const LoginSSORedirect = lazy(() => import('./LoginSSORedirect'));
const AdminHome = lazy(() => import('./AdminHome'));

export {
  AdminHome,
  LoginPage,
  HoldingPage,
  LoginSSORedirect,
  ErrorPage,
  ResetPasswordPage,
  RequestResetPasswordPage
};
