import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import MainContainer from '../components/layout/MainContainer';
import ViewLearnerOnCourse from '../components/learners-admin/ViewLearnerOnCourse';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { UserType } from '../const/user-type';
import OfflineInstructorSession from '../components/offline/offlineModePages/OfflineInstructorSession';

export default function offlineSessionRoutes(
  lang: AppLanguage | string | undefined
) {
  const langVal = lang as AppLanguage;

  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.OfflineSession],
    element: (
      <ProtectedRoute
        allowedRoles={[UserType.SUPER_ADMIN, UserType.INSTRUCTOR]}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,

    children: [
      {
        // index: true,
        path: `:sessionId`,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="offlineSession.breadcrumb"
              defaultMessage="Offline session"
              description="Offline session"
            />
          )
        },

        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <OfflineInstructorSession />,
            errorElement: <ErrorPage />
          },
          {
            path: `${appStrings[langVal][AppRoute.Learners]}/:learnerId`,
            element: <ViewLearnerOnCourse />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="session.learner"
                  defaultMessage="Learner"
                  description="Learner"
                />
              )
            }
          }
        ]
      }
    ]
  };

  return routes;
}
