import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import useLearner from '../../../hooks/useLearner';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../../common';
import Loading from '../../common/Loading';

function LearnerCard({ learnerId }: { learnerId: number }) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { learnerQuery: learner } = useLearner({
    userId: Number(learnerId),
    displayLocale
  });

  if (learner.data) {
    return (
      <FormattedMessage
        id="learner.name"
        defaultMessage="Learner: {name}"
        description="Learner {name}"
        values={{ name: learner.data.username || '' }}
      />
    );
  }

  if (learner.error) {
    return <GeneralError />;
  }

  if (learner.isLoading) {
    return <Loading />;
  }

  return <GeneralError />;
}

export default LearnerCard;
