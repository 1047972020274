import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

import { AppLanguage, AppRoute } from '../../../../const';
import { LangContext } from '../../../../modules/i18n/components/IntlWrapper';
import { appStrings } from '../../../../modules/i18n';
import { components } from '../../../../types/openapi/CourseService';
import AppMode from '../../../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../../../modules/offline/OfflineProvider';

function ObjectiveResourceLink({
  course,
  stageId,
  objectiveId
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  stageId: number;
  objectiveId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  return (
    <Link
      to={`/${displayLocale}/${
        appStrings[displayLocale as AppLanguage][AppRoute.Resources]
      }/${course?.frameworkVersion?.frameworkId}/${
        appStrings[displayLocale as AppLanguage][AppRoute.Versions]
      }/${course.frameworkVersionId}/${
        appStrings[displayLocale as AppLanguage][AppRoute.Stages]
      }/${stageId}/${
        appStrings[displayLocale as AppLanguage][
          AppRoute.InstructorViewObjectives
        ]
      }/${objectiveId}`}
      className={`btn btn-light py-0 ${
        appMode === AppMode.OFFLINE ? 'disabled' : ''
      }`}
    >
      <FontAwesomeIcon icon={faBookOpen} />
      <span className="visually-hidden">
        {' '}
        <FormattedMessage
          id="resources.view.button"
          defaultMessage="View resources"
          description="View resources"
        />
      </span>
    </Link>
  );
}

export default ObjectiveResourceLink;
