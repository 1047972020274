import { useQuery } from '@tanstack/react-query';
import sessionKeys from '../query-keys/session-key-factory';
import { getAllUserCourseSessionBookingsByCourseAndStage } from '../services/api/course.service';

// hook for managing learner's course session bookings for a specified stage and instructor
// Used to show if learner is new or not
const useLearnerSessionBookingsWithInstructor = ({
  courseId,
  learnerId,
  instructorId,
  displayLocale
}: {
  courseId: number;
  learnerId: number | undefined;
  instructorId: number | undefined;
  displayLocale: string;
}) => {
  const learnerSessionBookingsWithInstructorQuery = useQuery({
    queryKey: sessionKeys.learnerSessionBookingsWithInstructor(
      courseId,
      learnerId,
      instructorId,
      displayLocale
    ),
    queryFn: () =>
      getAllUserCourseSessionBookingsByCourseAndStage(
        courseId,
        learnerId,
        instructorId
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!courseId && !!learnerId && !!instructorId
  });

  return {
    learnerSessionBookingsWithInstructorQuery
  };
};

export default useLearnerSessionBookingsWithInstructor;
