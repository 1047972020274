import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { AppLanguage, AppRoute } from '../../../const';
import { appStrings } from '../../../modules/i18n';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import FooterLayout from './FooterLayout';

function Footer() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { pathname } = useLocation();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  return (
    <FooterLayout
      footerLinks={
        <ul className="list-unstyled d-block">
          <li className="d-md-inline-block me-3">
            <Link
              to={`/${displayLocale}/${
                appStrings[displayLocale as AppLanguage][AppRoute.PrivacyPolicy]
              }`}
            >
              <FormattedMessage
                id="link.privacy_policy"
                defaultMessage="Privacy policy"
                description="Privacy policy"
              />
            </Link>
          </li>
          <li className="d-md-inline-block me-3">
            <Link
              to={`/${displayLocale}/${
                appStrings[displayLocale as AppLanguage][
                  AppRoute.AccessibilityStatement
                ]
              }`}
            >
              <FormattedMessage
                id="link.accessibility_statement"
                defaultMessage="Accessibility statement"
                description="Accessibility statement"
              />
            </Link>
          </li>
          <li className="d-md-inline-block me-3">
            <Link
              to={`/${displayLocale}/${
                appStrings[displayLocale as AppLanguage][
                  AppRoute.TermsAndConditions
                ]
              }`}
            >
              <FormattedMessage
                id="link.terms_and_conditions"
                defaultMessage="Terms and conditions"
                description="Terms and conditions"
              />
            </Link>
          </li>
          <li className="d-md-inline-block me-3">
            <Link
              to={`/${displayLocale}/${
                appStrings[displayLocale as AppLanguage][AppRoute.CookiePolicy]
              }`}
              state={{ prevPath: pathname }}
            >
              <FormattedMessage
                id="link.cookie_policy"
                defaultMessage="Cookie policy"
                description="Cookie policy"
              />
            </Link>
          </li>
        </ul>
      }
      versionInfo={`v
              ${process.env.REACT_APP_VERSION}`}
      secondaryLinks={
        profile && (
          <div className="col-6 d-flex">
            <Link
              to={`/${displayLocale?.toLowerCase()}/${
                appStrings[displayLocale as AppLanguage][
                  AppRoute.SystemDiagnostics
                ]
              }`}
              className="ms-auto"
            >
              <FormattedMessage
                id="system_diagnostics.link"
                defaultMessage="System diagnostics"
                description="System diagnostics"
              />
            </Link>
          </div>
        )
      }
    />
  );
}

export default Footer;
