/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
import React, { ReactNode, useMemo } from 'react';
import dayjs from 'dayjs';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table';

import { components } from '../../../types/openapi/CourseService';
import { TIMEZONE, convertDateTime } from '../../../utils/date-time';

function SessionsTable({
  sessions,
  renderSessionActions
}: {
  sessions: components['schemas']['CourseSessionDto'][];
  renderSessionActions?: ({
    session
  }: {
    session: components['schemas']['CourseSessionDto'];
  }) => JSX.Element | ReactNode;
}) {
  const intl = useIntl();
  const sortedSessions = sessions.sort(
    (
      a: components['schemas']['CourseSessionDto'],
      b: components['schemas']['CourseSessionDto']
    ) => (dayjs(a.sessionStartTime).isAfter(dayjs(b.sessionStartTime)) ? 1 : -1)
  );
  const columns = useMemo<
    ColumnDef<components['schemas']['CourseSessionDto']>[]
  >(() => {
    const cols = [
      {
        id: 'date',
        header: intl.formatMessage({
          id: 'date',
          defaultMessage: 'Date',
          description: 'Date'
        }),
        cell: ({ row }: { row: any }) => {
          const session = row.original;
          const displayDate = convertDateTime(session.sessionStartTime);
          return (
            <FormattedDate value={displayDate.toDate()} timeZone={TIMEZONE} />
          );
        }
      },
      {
        id: 'time',
        header: intl.formatMessage({
          id: 'time',
          defaultMessage: 'Time',
          description: 'Time'
        }),
        cell: ({ row }: { row: any }) => {
          const session = row.original;
          const displayStartTime = convertDateTime(session.sessionStartTime);
          const startTimeAsDate = displayStartTime.toDate();
          const displayEndTime = convertDateTime(session.sessionEndTime);
          const endTimeAsDate = displayEndTime.toDate();
          return (
            <>
              <FormattedTime value={startTimeAsDate} timeZone={TIMEZONE} />
              &mdash;
              <FormattedTime value={endTimeAsDate} timeZone={TIMEZONE} />
            </>
          );
        }
      },

      {
        id: 'actions',
        header: intl.formatMessage({
          id: 'actions',
          defaultMessage: 'Actions',
          description: 'Actions'
        }),
        cell: ({ row }: { row: any }) => {
          const sessionLink =
            renderSessionActions &&
            renderSessionActions({
              session: row.original
            });
          return <div className="btn-group">{sessionLink}</div>;
        }
      }
    ];

    return cols;
  }, [sortedSessions]);

  const table = useReactTable({
    data: sortedSessions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false
  });

  return (
    <table className="table card-list-table">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id} data-title={cell.column.columnDef.header}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default SessionsTable;

SessionsTable.defaultProps = {
  renderSessionActions: () => {}
};
