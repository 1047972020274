import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/UserService';
import { GeneralError } from '../common';

function UsersTable({
  users,
  areGroupAdmins,
  actions
}: {
  users: components['schemas']['UserDto'][] | undefined | null;
  areGroupAdmins?: boolean;
  actions?: any[];
}) {
  if (!users || users?.length === 0) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="users.none"
            defaultMessage="No Users Found"
            description="No Users Found"
          />
        }
      />
    );
  }
  return (
    <table className="table card-list-table" data-gdpr="true">
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="name"
              defaultMessage="Name"
              description="Name"
            />
          </th>
          <th>
            <FormattedMessage
              id="email"
              defaultMessage="Email"
              description="Email"
            />
          </th>
          {areGroupAdmins && (
            <th>
              <FormattedMessage
                id="phone_number"
                defaultMessage="Phone number"
                description="Phone number"
              />
            </th>
          )}
          {!!actions?.length && (
            <th>
              <FormattedMessage
                id="actions.table.header"
                defaultMessage="Actions"
                description="Actions"
              />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {users ? (
          users.map((user: components['schemas']['UserDto'], index) => {
            return (
              <tr key={user.userId}>
                <td data-title="Name">{user.username}</td>
                <td data-title="Email">
                  {user.email || (
                    <span className="text-muted">
                      <FormattedMessage
                        id="email.none"
                        defaultMessage="No email provided"
                        description="No email provided"
                      />
                    </span>
                  )}
                </td>
                {areGroupAdmins && (
                  <td data-title="Phone number">
                    {user.telephone || (
                      <span className="text-muted">
                        <FormattedMessage
                          id="phone.none"
                          defaultMessage="No Phone Number Provided"
                          description="No Phone Number Provided"
                        />
                      </span>
                    )}
                  </td>
                )}
                {!!actions?.length && <td>{actions[index]}</td>}
              </tr>
            );
          })
        ) : (
          <FormattedMessage
            id="users.none"
            defaultMessage="No Users Found"
            description="No Users Found"
          />
        )}
      </tbody>
    </table>
  );
}

export default UsersTable;

UsersTable.defaultProps = {
  areGroupAdmins: false,
  actions: []
};
