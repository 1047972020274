import React from 'react';

function AttendancePercentageNumber({ percentage }: { percentage: number }) {
  return (
    <div className="attendance-percentage bg-primary mx-auto rounded-circle text-white">
      {percentage}%
    </div>
  );
}

export default AttendancePercentageNumber;
