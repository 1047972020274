import { UserType } from '../../const/user-type';

const isAllowed = (allowedRoles: UserType[], userRole: UserType) => {
  if (!userRole) {
    return false;
  }
  return !!allowedRoles?.includes(userRole);
};

export default isAllowed;
