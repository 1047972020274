/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import Loading from '../common/Loading';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import Unauthorised from '../../pages/Unauthorised';
import useSessionsBetweenDates from '../../hooks/useSessionsBetweenDates';
import DataLastUpdated from '../offline/DataLastUpdated';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import InstructorAgenda from './InstructorAgenda';

function InstructorAgendaWrapper() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const isNetworkOnline = useNetworkStatus();

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  const { instructorSessionsTodayQuery } = useSessionsBetweenDates({
    userId: profile?.userId,
    userType: profile?.userTypeId as UserType,
    displayLocale
  });

  if (profile?.userTypeId !== UserType.INSTRUCTOR) {
    return <Unauthorised />;
  }

  if (instructorSessionsTodayQuery.isLoading) {
    return (
      <div className="row">
        <Loading />
      </div>
    );
  }

  return (
    <div className="row">
      {instructorSessionsTodayQuery.data ? (
        <InstructorAgenda
          sessions={instructorSessionsTodayQuery.data.sort((a, b) =>
            dayjs(a.sessionStartTime).isAfter(dayjs(b.sessionStartTime))
              ? 1
              : -1
          )}
        />
      ) : (
        <div className="d-flex flex-column">
          <span>
            <FormattedMessage
              id="sessions.none_found_today"
              defaultMessage="No sessions found for today"
              description="No Sessions Found"
            />
          </span>
          <button
            type="button"
            className="btn btn-outline-secondary my-3"
            disabled={!isNetworkOnline}
            onClick={() => instructorSessionsTodayQuery.refetch()}
          >
            <FormattedMessage
              id="try_again"
              defaultMessage="Try Again"
              description="Try Again"
            />
          </button>
        </div>
      )}

      <DataLastUpdated
        dataUpdatedAt={
          instructorSessionsTodayQuery.dataUpdatedAt ||
          instructorSessionsTodayQuery.errorUpdatedAt
        }
        updatedMessage={
          <FormattedMessage
            id="agenda.updated"
            defaultMessage="Agenda updated"
          />
        }
        className="text-muted"
      />
    </div>
  );
}

export default InstructorAgendaWrapper;
