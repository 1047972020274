/* eslint-disable no-inner-declarations */
import React, { useContext } from 'react';

import { UserType } from '../../const/user-type';
import Unauthorised from '../../pages/Unauthorised';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import { components } from '../../types/openapi/UserService';
import LearnersListInstructorOffline from './LearnersListInstructorOffline';
import LearnersListInstructorOnline from './LearnersListInstructorOnline';

function LearnersListInstructor({
  profile
}: {
  profile: components['schemas']['UserProvidersDto'];
}) {
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  if (profile?.userTypeId === UserType.INSTRUCTOR) {
    if (appMode === AppMode.OFFLINE) {
      return <LearnersListInstructorOffline />;
    }
    return <LearnersListInstructorOnline profile={profile} />;
  }

  return <Unauthorised />;
}

export default LearnersListInstructor;
