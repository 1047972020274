import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { components } from '../../types/openapi/CourseService';
import CourseStagesList from './CourseStagesList';

function CourseDetailsTable({
  course,
  courseStages
}: {
  course: components['schemas']['CourseDto'];
  courseStages?: components['schemas']['CourseStageDto'][];
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  return (
    <table className="table table-sm">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="description"
              defaultMessage="Description"
              description="Description"
            />
          </th>
          <td>{course.description}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="framework.table.header"
              defaultMessage="Framework"
              description="Framework"
            />
          </th>
          <td>{course.frameworkVersion?.framework?.name}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="frameworks.version.table.header"
              defaultMessage="Framework Version"
              description="Framework Version"
            />
          </th>
          <td>{course.frameworkVersion?.name}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="stage.plural_in_brackets"
              defaultMessage="Stage(s)"
              description="Stage(s)"
            />
          </th>
          <td>
            {courseStages ? (
              <CourseStagesList courseStages={courseStages} />
            ) : (
              <span>
                <FormattedMessage
                  id="stages.none"
                  defaultMessage="No stages found for this course"
                  description="No stages found for this course"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="instructor"
              defaultMessage="Instructor"
              description="Instructor"
            />
          </th>
          <td data-gdpr="true">{course.instructor?.fullname}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="centre"
              defaultMessage="Centre"
              description="Centre"
            />
          </th>
          <td>{course.centre?.name}</td>
        </tr>
        <tr>
          <th>
            <FormattedMessage
              id="facility"
              defaultMessage="Facility"
              description="Facility"
            />
          </th>
          <td>
            {course.facility?.name || (
              <span className="text-muted">
                <FormattedMessage
                  id="facility.none"
                  defaultMessage="No facility assigned"
                  description="No facility assigned to course"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th>
            <FormattedMessage
              id="school"
              defaultMessage="School"
              description="School"
            />
          </th>
          <td>
            {course.school ? (
              `${course.school?.name} - ${course.school?.address?.postalCode} `
            ) : (
              <span className="text-muted">
                <FormattedMessage
                  id="school.none"
                  defaultMessage="No school assigned"
                  description="No school assigned to course"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th>
            <FormattedMessage
              id="capacity"
              defaultMessage="Capacity"
              description="Capacity"
            />
          </th>
          <td>{course.totalNumberOfPeople}</td>
        </tr>
        {profile?.userTypeId === UserType.ADMIN && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="externalId"
                defaultMessage="External Id"
                description="External Id"
              />
            </th>
            <td>
              {course.externalCourseId ? (
                course.externalCourseId
              ) : (
                <span className="text-muted">
                  <FormattedMessage
                    id="externalId.none"
                    defaultMessage="No External Id provided"
                    description="No External Id provided"
                  />
                </span>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default CourseDetailsTable;

CourseDetailsTable.defaultProps = {
  courseStages: []
};
