/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import Unauthorised from '../../pages/Unauthorised';
import GeneralError from '../common/GeneralError';
import useCentreCourseSessions from '../../hooks/useCentreCourseSessions';
import TimetableWrapper from './TimetableWrapper';

function CentreAdminTimetable() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  if (!profile?.providerId || !profile.centreId) {
    return <GeneralError message="no provider id or centre id" />;
  }

  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(2, 'week').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().add(2, 'week').format('YYYY-MM-DD')
  );

  const { courseSessionsForCentreQuery } = useCentreCourseSessions({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    startDate,
    endDate,
    displayLocale
  });

  if (profile?.userTypeId !== UserType.ADMIN) {
    return <Unauthorised />;
  }

  return (
    <TimetableWrapper
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      sessions={courseSessionsForCentreQuery.data}
      isLoading={courseSessionsForCentreQuery.isLoading}
      error={courseSessionsForCentreQuery.error}
    />
  );
}

export default CentreAdminTimetable;
