import { useSearchParams } from 'react-router-dom';

import { components } from '../types/openapi/UserService';
import deserialiseFilterParam from '../utils/deserialiseFilterParam';
import generateSportPassportId from '../utils/generateSportPassportId';

const useOfflineLearnersTable = ({
  learners
}: {
  learners: components['schemas']['PagedUserDto'] | undefined;
}) => {
  const [searchParams] = useSearchParams();

  const filterParams = deserialiseFilterParam(searchParams.get('filtering'));
  const sportPassportIdFilter = filterParams.find(
    (param) => param.id === 'sportPassportId'
  );

  const learnerNameFilter = filterParams.find(
    (param) => param.id === 'username'
  );

  let todaysFilteredLearners;

  if (learners && learners.users) {
    if (sportPassportIdFilter || learnerNameFilter) {
      const filteredlearnersBySportPassportId = learners.users.filter(
        (learner: components['schemas']['UserDto']) => {
          if (sportPassportIdFilter && learner.sportPassportId) {
            return (
              generateSportPassportId(learner.sportPassportId) ===
              generateSportPassportId(Number(sportPassportIdFilter.value))
            );
          }
          return true;
        }
      );
      if (learnerNameFilter) {
        const filteredlearnersByName = filteredlearnersBySportPassportId.filter(
          (learner: components['schemas']['UserDto']) =>
            learner.username &&
            learner.username
              .toUpperCase()
              .includes(learnerNameFilter.value.toUpperCase())
        );
        todaysFilteredLearners = {
          numberOfUsers: filteredlearnersByName.length,
          users: filteredlearnersByName
        };
      } else {
        todaysFilteredLearners = {
          numberOfUsers: filteredlearnersBySportPassportId.length,
          users: filteredlearnersBySportPassportId
        };
      }
    } else {
      todaysFilteredLearners = learners;
    }
  }

  return {
    todaysFilteredLearners
  };
};

export default useOfflineLearnersTable;
