import { components } from '../../types/openapi/CourseService';

// type guard for checking if a response has the correct value and type
const isUserCourseSessionObjectiveDto = (
  value: components['schemas']['UserCourseSessionObjectiveDto'] | undefined
): value is components['schemas']['UserCourseSessionObjectiveDto'] => {
  return !!value;
};

export default isUserCourseSessionObjectiveDto;
