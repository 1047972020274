import React, { useContext } from 'react';

import { ProfileContext } from '../../../../modules/profile/ProfileProvider';
import { UserType } from '../../../../const/user-type';
import AdminSession from './AdminSession';

import Unauthorised from '../../../../pages/Unauthorised';
import LearnerSessionProgress from '../../../progress/LearnerSessionProgress';
import { OfflineContext } from '../../../../modules/offline/OfflineProvider';
import AppMode from '../../../../modules/offline/app-mode.enum';
import OfflineInstructorSession from '../../../offline/offlineModePages/OfflineInstructorSession';
import OnlineInstructorSession from '../../../offline/onlineModePages/OnlineInstructorSession';

function ViewSession() {
  const profileContext = useContext(ProfileContext);
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const { profile } = profileContext;

  if (
    profile?.userTypeId === UserType.SUPER_ADMIN ||
    profile?.userTypeId === UserType.ADMIN
  ) {
    return <AdminSession />;
  }

  if (profile?.userTypeId === UserType.INSTRUCTOR) {
    if (appMode === AppMode.OFFLINE) return <OfflineInstructorSession />;

    return <OnlineInstructorSession />;
  }

  if (profile?.userTypeId === UserType.LEARNER) {
    return <LearnerSessionProgress />;
  }
  return <Unauthorised />;
}

export default ViewSession;
