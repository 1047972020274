/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState
} from '@tanstack/react-table';

import {
  components,
  components as userComponents
} from '../../types/openapi/UserService';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import isAllowed from '../../utils/permissions/isAllowed';
import getFormattedDateOfBirth from '../../utils/getFormattedDateOfBirth';
import generateSportPassportId from '../../utils/generateSportPassportId';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import PaginatedTable from '../tables/PaginatedTable';
import useTableColumns from '../../hooks/table/useTableColumns';

function LearnersList({
  data,
  error,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation,
  renderActions,
  handleRowSelectionChange
}: {
  data: userComponents['schemas']['PagedUserDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState | undefined;
  sorting: any[] | undefined;
  filtering: any[] | undefined;
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  queryParamsLocation: string;
  renderActions?: ({ row }: { row: any }) => JSX.Element | ReactNode;
  handleRowSelectionChange?: (rowSelection: any) => void;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const intl = useIntl();

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const [rowSelection, setRowSelection] = React.useState({});

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  const AGE_COLUMN = {
    id: 'age',
    header: intl.formatMessage({
      id: 'age',
      defaultMessage: 'Age',
      description: 'Age'
    }),
    cell: ({ row }: { row: any }) => {
      const learner = row.original;

      if (learner.dateOfBirth === '0001-01-01T00:00:00') {
        return intl.formatMessage({
          id: 'age.none',
          defaultMessage: 'No Age Provided',
          description: 'No Age Provided'
        });
      }
      return (
        <span data-gdpr="true">
          {getFormattedDateOfBirth(`${learner.dateOfBirth}`)}
        </span>
      );
    },
    accessorKey: 'age',
    enableSorting: false,
    sortDescFirst: true,
    filterType: undefined
  };

  const CENTRES_COLUMN = {
    id: 'centre',
    header: intl.formatMessage({
      id: 'centre',
      defaultMessage: 'Centre',
      description: 'Centre'
    }),
    cell: ({ row }: { row: any }) => {
      const learner = row.original;
      return (
        <span>
          {learner?.userProviders
            .map((up: any) => up.centre?.name ?? '')
            .filter((up: any) => !!up)
            .join(', ')}
        </span>
      );
    },
    accessorKey: 'centre',
    enableSorting: false,
    sortDescFirst: true,
    filterType: 'dropdown'
  };

  const SPORT_PASSPORT_ID_COLUMN = {
    id: 'sportPassportId',
    header: intl.formatMessage({
      id: 'user.sportPassportId',
      defaultMessage: 'Sport Passport Id',
      description: 'Sport Passport Id'
    }),
    cell: ({ row }: { row: any }) => {
      const learner = row.original;
      if (!learner.sportPassportId) {
        return (
          <span>
            {intl.formatMessage({
              id: 'id.none',
              defaultMessage: 'No Sport Passport Id Provided',
              description: 'No Sport Passport Id Provided'
            })}
          </span>
        );
      }
      return <span>{generateSportPassportId(learner.sportPassportId)}</span>;
    },
    accessorKey: 'sportPassportId',
    filterType: 'sportPassportIdInput',
    enableSorting: false,
    sortDescFirst: true
  };

  const EXTERNAL_ID_COLUMN = {
    id: 'externalId',
    header: intl.formatMessage({
      id: 'user.externalId',
      defaultMessage: 'External Id',
      description: 'External Id'
    }),
    cell: ({ row }: { row: any }) => {
      const learner = row.original;
      // Get first userPermission for the provider that has an external ID (will be same for other permissions of provider)
      const userPermission = learner.userProviders.find(
        (userProvider: components['schemas']['UserProvidersDto']) =>
          userProvider.userTypeId === UserType.LEARNER &&
          userProvider.providerId === profile?.providerId &&
          userProvider.externalId
      );
      if (!userPermission || !userPermission.externalId) {
        return <div className="text-center w-50">&ndash;</div>;
      }
      return userPermission.externalId;
    },
    accessorKey: 'externalId',
    filterType: 'basicInput',
    enableSorting: false,
    sortDescFirst: true
  };

  const { usernameColumn, dobColumn } = useTableColumns({
    providerId: profile?.providerId
  });

  const columns = [
    usernameColumn,
    dobColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const learnerLink =
          renderActions &&
          row &&
          renderActions({
            row
          });
        return <div>{learnerLink}</div>;
      },
      filterType: undefined
    }
  ];

  if (isAllowed([UserType.INSTRUCTOR], profile?.userTypeId as UserType)) {
    columns.splice(columns.length - 2, 0, AGE_COLUMN, SPORT_PASSPORT_ID_COLUMN);
  }

  if (
    isAllowed(
      [UserType.SUPER_ADMIN, UserType.SPORT_MANAGER, UserType.ADMIN],
      profile?.userTypeId as UserType
    ) &&
    !profile?.centreId
  ) {
    columns.splice(
      columns.length - 1,
      0,
      SPORT_PASSPORT_ID_COLUMN,
      EXTERNAL_ID_COLUMN,
      CENTRES_COLUMN
    );
  }

  const table = useReactTable({
    data: data?.users ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: appMode !== AppMode.OFFLINE,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfUsers
        ? Math.ceil(
            data.numberOfUsers / (pagination ? pagination.pageSize : 10)
          )
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    enableRowSelection: true,

    onRowSelectionChange: (selectedRowsFn) => setRowSelection(selectedRowsFn),
    state: {
      rowSelection,
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  useEffect(() => {
    if (handleRowSelectionChange)
      handleRowSelectionChange(
        table.getSelectedRowModel().flatRows.map((row) => row.original)
      );
  }, [rowSelection]);

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        {appMode === AppMode.OFFLINE && (
          <span className="alert alert-info flex-grow-1 me-3 mb-0">
            <FormattedMessage
              id="offline.data_unavailable"
              defaultMessage="You are currently in offline mode. Only data for today is available."
              description="You are currently in offline mode. Only data for today is available."
            />
          </span>
        )}
      </div>
      <PaginatedTable
        table={table}
        error={error}
        filtering={filtering}
        sorting={sorting}
        resetFilters={resetFilters}
        columns={columns}
        dataCount={data?.numberOfUsers}
        refetching={refetching}
        isLoading={isLoading}
        queryParamsLocation={queryParamsLocation}
        dataId="userId"
        noneFoundMessage={
          <FormattedMessage
            id="learners.none_found"
            defaultMessage="No learners"
            description="No learners found"
          />
        }
        showFooter={(data?.numberOfUsers ?? 0) > 10}
      />
    </div>
  );
}

export default LearnersList;

LearnersList.defaultProps = {
  renderActions: () => {},
  handleRowSelectionChange: () => {}
};
