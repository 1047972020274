import {
  ColumnFiltersState,
  OnChangeFn,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type TableStateActions = {
  setPagination: (value: PaginationState) => void;
  setSorting: (value: SortingState) => void;
  setFiltering: OnChangeFn<ColumnFiltersState>;
  reset: () => void;
};

type TableState = {
  pagination: PaginationState;
  sorting: SortingState;
  filtering: ColumnFiltersState;
};
const initialState: TableState = {
  pagination: { pageIndex: 0, pageSize: 10 },
  sorting: [],
  filtering: []
};

const useCreateTableState = ({ name }: { name: string }) => {
  // console.log('create ');
  const useTableStateStore = create<
    TableState & TableStateActions,
    [['zustand/persist', unknown]]
  >(
    persist(
      (set) => ({
        ...initialState,
        setPagination: (value: PaginationState) =>
          set({ pagination: { ...value } }),
        setSorting: (value: SortingState) => set({ sorting: value }),
        setFiltering: (value: any) => {
          // console.log(value());
          return set({ filtering: value() });
        },
        reset: () => {
          set(initialState);
        }
      }),
      {
        name, // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
      }
    )
  );

  return {
    useTableStateStore
  };
};

export default useCreateTableState;
