import { useReducer } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getStarsForFramework } from '../services/api/course.service';
import { ObjectiveUserStars } from '../types/ObjectiveUserStars.type';
import { CourseUserStars } from '../types/CourseUserStars.type';
import userObjectivesKeys from '../query-keys/user-objectives-key-factory';

// hook for managing resources
const useUserStarsForFramework = ({
  frameworkVersionId,
  userId,
  displayLocale
}: {
  frameworkVersionId: number | undefined;
  userId: number | undefined;
  displayLocale: string | undefined;
}) => {
  if (!userId || !frameworkVersionId) {
    throw Error('invalid id');
  }

  const reducer = (
    userStars: ObjectiveUserStars[],
    action: { type: string; objectiveId: number; star: number }
  ) => {
    switch (action.type) {
      case 'add_userStar': {
        const arrCopy = [...userStars];
        // get updated item
        let updatedItem = arrCopy.find(
          (userStar) => userStar.objectiveId === action.objectiveId
        );
        // if it isnt in existing array so therefore it is a new star
        if (!updatedItem) {
          const newItem = {
            numberOfStars: action.star || 0,
            objectiveId: action.objectiveId
          };
          updatedItem = { ...newItem };
        }
        // remove updated item from array if it exists
        const userStarsQueryArray = arrCopy.filter(
          (userStar) => userStar.objectiveId !== action.objectiveId
        );
        // update item
        if (updatedItem) {
          updatedItem.numberOfStars = action.star;
        }
        // add back to new array
        if (updatedItem) {
          userStarsQueryArray.push(updatedItem);
        }

        return userStarsQueryArray;
      }

      default:
        return userStars;
    }
    throw Error('Unknown action.');
  };

  // This query brings back ALL the stars for every session on a course
  // therefore it can be quite slow...so make sure you really, really need all this data
  // if you just want a specific session you can use getStarsForCourseSession
  // which is a lot smaller and quicker
  const userFrameworkStarsQuery = useQuery({
    queryKey: userObjectivesKeys.starsForFramework(frameworkVersionId, userId),
    queryFn: () =>
      getStarsForFramework(
        Number(frameworkVersionId),
        Number(userId),
        displayLocale
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  const allUserStarsArray = userFrameworkStarsQuery.data
    ?.map((objectiveStar: CourseUserStars) => {
      return objectiveStar.objectiveStars.map((star) => star);
    })
    .flat();

  const [userStars, userStarsDispatch] = useReducer(
    reducer,
    allUserStarsArray || []
  );

  return {
    userFrameworkStarsQuery,
    allUserStarsArray,
    userStars,
    userStarsDispatch
  };
};

export default useUserStarsForFramework;
