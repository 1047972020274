import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { createProviderCentre } from '../../services/api/provider.service';
import { components } from '../../types/openapi/ProviderService';
import CentreForm from './CentreForm';
import { AppRoute } from '../../const';
import localiseRoutePath from '../../utils/localiseRoutePath';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import centreKeys from '../../query-keys/centre-key-factory';

function CreateCentre() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const userProviderId = profile?.providerId;

  const navLinkCentres = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Centres
  )}`;

  const { mutate: createNewCentre, isLoading } = useMutation(
    (centre: Partial<components['schemas']['CentreDto']>) =>
      createProviderCentre(userProviderId, centre, displayLocale),
    {
      onSuccess: (data: components['schemas']['CentreDto']) => {
        const { name } = data;
        toast.success(
          intl.formatMessage(
            {
              id: 'centre.create.success',
              defaultMessage: 'A new centre {name} has been created'
            },
            { name }
          ),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: centreKeys.all(displayLocale)
        });
        navigate(navLinkCentres);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'centre.create.error',
            defaultMessage:
              'There was an error creating a centre with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (
    centre: Pick<
      components['schemas']['CentreDto'],
      'name' | 'description' | 'address'
    >
  ) => {
    const { name, description, address } = centre;
    if (centre.name && userProviderId) {
      const newCentre: Partial<components['schemas']['CentreDto']> = {
        name,
        description,
        address,
        providerId: userProviderId
      };
      createNewCentre(newCentre);
    }
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.centre.create_new',
            defaultMessage: 'Create new centre'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="header.centre.create_new"
            defaultMessage="Create New Centre"
            description="Create New Centre"
          />
        </h1>
      </div>
      <div className="card-body">
        <CentreForm
          onSubmit={onSubmit}
          submitButtonMessage={
            <FormattedMessage
              id="centre.button.create_new"
              defaultMessage="Create new centre"
              description="Create new centre"
            />
          }
          helpText={{
            name: intl.formatMessage({
              id: 'name.required',
              defaultMessage: 'Name is required'
            })
          }}
          formDisabled={isLoading}
        />
      </div>
    </div>
  );
}

export default CreateCentre;
