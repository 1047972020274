const baseStrings = {
  'gender.male': 'Male',
  'gender.female': 'Female',
  'gender.nonbinary': 'Non-binary',
  'gender.other': 'Other',
  'gender.notsay': 'Prefer not to say'
};

export type GenderStrings = typeof baseStrings;
export const en = baseStrings;
