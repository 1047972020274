import React, { Suspense, useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { CookieConsentProvider } from '@use-cookie-consent/react';

import Header from '../components/layout/header/header';
import Footer from '../components/layout/footer/footer';
import { UserType } from '../const/user-type';
import OfflineSwitcher from '../components/offline/OfflineSwitcher';
import isAllowed from '../utils/permissions/isAllowed';
import { ProfileContext } from '../modules/profile/ProfileProvider';

import { LangContext } from '../modules/i18n/components/IntlWrapper';
import Loading from '../components/common/Loading';
import CookieBanner from '../components/cookies/CookieBanner';

export interface LayoutProps {
  children: React.ReactNode;
}

function Layout() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale, updateLocale } = langCtx;
  const { lang } = useParams();

  useEffect(() => {
    if (!!lang && updateLocale && lang !== displayLocale) {
      updateLocale(lang);
    }
  }, [lang]);

  const hasOfflineSwitcher = isAllowed(
    [UserType.INSTRUCTOR],
    profile?.userTypeId as UserType
  );

  return (
    <CookieConsentProvider
      useCookieConsentHooksOptions={{
        consentCookieAttributes: { expires: 365 }
      }}
    >
      <div className="layout">
        <CookieBanner />
        <Header />
        {hasOfflineSwitcher && <OfflineSwitcher />}
        <main id="maincontent" className="d-flex flex-grow-1 admin-main">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </main>
        <Footer />
      </div>
    </CookieConsentProvider>
  );
}

export default Layout;
