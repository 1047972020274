import React, { useContext } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../../../modules/i18n/components/IntlWrapper';
import { AppLanguage, AppRoute } from '../../../../const';
import { appStrings } from '../../../../modules/i18n';

function PaymentSuccess() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  return (
    <div className="card">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.payment.succes',
            defaultMessage: 'Payment Success'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h3 className="text-center">
          <FormattedMessage
            id="payment.success"
            defaultMessage="Payment Success"
            description="Payment Success"
          />
        </h3>
      </div>
      <div className="card-body">
        <div className="d-flex flex-column">
          <FontAwesomeIcon
            className="fa-3x text-success"
            icon={icon({ name: 'circle-check', style: 'solid' })}
          />
        </div>
        <div className="d-flex flex-column text-center">
          <p>
            <FormattedMessage
              id="payment.success.message"
              defaultMessage="Your payment was successful."
              description="Your payment was successful."
            />
          </p>
          <p>
            {' '}
            <FormattedMessage
              id="payment.success_email"
              defaultMessage="You will receive an email to confirm your booking details"
              description="You will receive an email to confirm your booking details"
            />
          </p>

          <Link
            className="btn btn-outline-secondary"
            to={`/${displayLocale?.toLowerCase()}/${
              appStrings[displayLocale as AppLanguage][AppRoute.Timetable]
            }`}
          >
            <FormattedMessage
              id="timetable.go_to"
              defaultMessage="Go to Timetable"
              description="Go to Timetable"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
