import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';

import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import CreateProviderAdmin from '../components/providers/CreateProviderAdmin';

const ErrorPage = lazy(() => import('../pages/ErrorPage'));

const MainContainer = lazy(() => import('../components/layout/MainContainer'));
const CreateProvider = lazy(
  () => import('../components/providers/CreateProvider')
);
const EditProvider = lazy(() => import('../components/providers/EditProvider'));
const ManageProviders = lazy(
  () => import('../components/providers/ManageProviders')
);
const ViewProvider = lazy(() => import('../components/providers/ViewProvider'));

export default function providerRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Providers],
    element: (
      <ProtectedRoute
        allowedRoles={[UserType.SUPER_ADMIN, UserType.SPORT_MANAGER]}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="providers"
          defaultMessage="Providers"
          description="Providers"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageProviders />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.CreateProvider],
        element: (
          <ProtectedRoute
            allowedRoles={[UserType.SUPER_ADMIN, UserType.SPORT_MANAGER]}
          >
            <CreateProvider />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="provider.create"
              defaultMessage="Create Provider"
              description="Create Provider"
            />
          )
        }
      },
      {
        path: `:providerId`,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="provider"
              defaultMessage="Provider"
              description="Provider"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ViewProvider />,
            errorElement: <ErrorPage />
          },
          {
            path: `${appStrings[langVal][AppRoute.EditProvider]}`,
            element: <EditProvider />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="provider.edit"
                  defaultMessage="Edit Provider"
                  description="Edit Provider"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.CreateProviderAdmin]}`,
            element: <CreateProviderAdmin />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="breadcrumb.admin.create"
                  defaultMessage="Create Admin"
                  description="Create Admin"
                />
              )
            }
          }
        ]
      }
    ]
  };

  return routes;
}
