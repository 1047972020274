import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import useOfflineDataDownload from '../../../hooks/offline/useOfflineDataDownload';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../../common';
import InstructorSession from '../../courses/sessions/session/instructor/InstructorSession';

function OfflineInstructorSession() {
  const { sessionId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const {
    offlineDataDownloadQuery,
    session,
    courseCapacity,
    learnerDetails,
    course
  } = useOfflineDataDownload({
    sessionId: Number(sessionId),
    downloadSessionData: true
  });

  if (!offlineDataDownloadQuery.data || !course || !session) {
    return <GeneralError message="no data" />;
  }

  return (
    <InstructorSession
      session={session}
      sessionCapacity={courseCapacity}
      availableSpaces={
        course && courseCapacity
          ? courseCapacity - (learnerDetails?.length || 0)
          : 0
      }
      sessionMembersSorted={
        learnerDetails &&
        learnerDetails.sort((a: any, b: any) =>
          a.username.localeCompare(b.username, displayLocale, {
            ignorePunctuation: true,
            sensitivity: 'base'
          })
        )
      }
      sessionMembersError={null}
      sessionMembersIsLoading={false}
      sessionDataUpdatedAt={offlineDataDownloadQuery.dataUpdatedAt}
      course={course}
      courseName={course?.name}
      facilityName={course?.facility?.name}
      courseStages={course?.courseStages}
      frameworkName={course?.frameworkVersion?.name}
      centreName={course?.centre?.name}
    />
  );
}

export default OfflineInstructorSession;
