import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ResourceType } from '../../../const/resource-type';
import { ObjectiveUserStars } from '../../../types/ObjectiveUserStars.type';
import { ObjectiveWithStars } from '../../../types/ObjectiveWithStars.type';
import { components } from '../../../types/openapi/CourseService';
import Objective from '../../courses/sessions/objectives/Objective';
import useResources from '../../../hooks/useResources';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

const isStarsForUserDto = (
  value: components['schemas']['StarsForUserDto'] | ObjectiveUserStars
): value is components['schemas']['StarsForUserDto'] => {
  return (
    (value as components['schemas']['StarsForUserDto']).courseId !== undefined
  );
};
function FrameworkObjectiveSnake({
  objectives,
  frameworkVersionId,
  userId,
  userStars,
  renderStarElement,
  renderLinkElement
}: {
  objectives:
    | components['schemas']['ObjectiveDto'][]
    | components['schemas']['ObjectiveSlimDto'][];
  frameworkVersionId: number;
  userId: number;
  userStars?:
    | ObjectiveUserStars[]
    | components['schemas']['StarsForUserDto'][]
    | null;
  renderStarElement: (props: {
    userStarValue: number;
    inputStarValue: number;
    objective: ObjectiveWithStars;
  }) => JSX.Element | ReactNode;
  renderLinkElement?: (props: {
    objective:
      | components['schemas']['ObjectiveDto']
      | components['schemas']['ObjectiveSlimDto'];
  }) => JSX.Element | ReactNode;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [objectivesWithStars, setObjectivesWithStars] = useState<
    ObjectiveWithStars[] | null
  >();

  const { resourcesQuery } = useResources({
    frameworkVersionId,
    displayLocale
  });

  const getStars = (objectiveId: number | undefined) => {
    if (userStars && isStarsForUserDto(userStars[0])) {
      const objectiveStars = (
        userStars as components['schemas']['StarsForUserDto'][]
      )
        .map((userStar) => userStar.objectiveStars)
        .flat();
      return objectiveStars.find(
        (objectiveStar) => objectiveStar?.objectiveId === objectiveId
      );
    }
    if (userStars && !isStarsForUserDto(userStars[0])) {
      const objectiveStars = (userStars as ObjectiveUserStars[]).find(
        (userStar) => userStar.objectiveId === objectiveId
      );
      return objectiveStars;
    }
    return undefined;
  };

  useEffect(() => {
    if (objectives) {
      const trophies = resourcesQuery.data?.filter(
        (resource) => resource.resourceTypeId === ResourceType.TROPHY
      );
      const newObjectives = objectives.map((objective) => {
        // resources
        const img = trophies?.find(
          (trophy: any) => trophy.objectiveId === objective.objectiveId
        );
        const imgSrc = img
          ? `${process.env.REACT_APP_BLOB_BASE_URL}/${img.resourceString}?${img.sasToken}`
          : '';

        // extend the objectives object with stars and user data
        const objectiveWithStars: ObjectiveWithStars = {
          ...objective,
          frameworkVersionId,
          userId,
          stars: userStars
            ? getStars(objective.objectiveId)?.numberOfStars || 0
            : 0,
          imgSrc
        };
        return objectiveWithStars;
      });
      setObjectivesWithStars(
        newObjectives.sort((a: any, b: any) => (a.order < b.order ? -1 : 1))
      );
    }
  }, [objectives, resourcesQuery.data, userStars]);
  return (
    <ol className="snake-ol">
      {objectivesWithStars ? (
        objectivesWithStars.map((objectiveWithStars: ObjectiveWithStars) => {
          return (
            objectiveWithStars.objectiveId && (
              <Objective
                key={objectiveWithStars.objectiveId}
                objective={objectiveWithStars}
                renderStarElement={renderStarElement}
                renderLinkElement={renderLinkElement}
              />
            )
          );
        })
      ) : (
        <FormattedMessage
          id="objectives.learner.none"
          defaultMessage="No objectives available for this learner"
          description="No objectives available for this learner"
        />
      )}
    </ol>
  );
}

export default FrameworkObjectiveSnake;

FrameworkObjectiveSnake.defaultProps = {
  userStars: 0,
  renderLinkElement: () => {}
};
