/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { bearerToken } from '../bearer-token';
import { components } from '../../types/openapi/UserService';
import camelCaseResponse from '../camel-case-interceptor';

import { UserType } from '../../const/user-type';
import expiredTokenInterceptor from '../refresh-access-token.interceptor';
import { Subset } from '../../types/Subset.type';

const BASE_URL = `${process.env.REACT_APP_USER_API_URL}/api/users`;

export const userApi = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

userApi.interceptors.request.use(
  (config) => {
    const token: string = bearerToken();
    if (token && config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

userApi.defaults.headers.common['Content-Type'] = 'application/json';

userApi.interceptors.response.use(
  (response) => camelCaseResponse(response),
  (error) => expiredTokenInterceptor(userApi, error)
);

export const enhancedLogEmail = async (data: any) => {
  if (!data) {
    throw Error('no data provided');
  }

  const response = await userApi.post(`/enhanced-error-email`, data);

  return response.data;
};

export const getUser = async (userId: number | null | undefined) => {
  if (!userId) {
    throw Error('no id provided');
  }

  const response = await userApi.get<components['schemas']['UserDto']>(
    `${userId.toString(10)}`
  );
  return response.data;
};

export const getUsersForProviderOfUserType = async (
  providerId: number | null | undefined,
  userTypeId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!providerId || !userTypeId) {
    throw Error('no id provided');
  }
  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await userApi.get<components['schemas']['PagedUserDto']>(
    `providers/${providerId}/user_type/${userTypeId}`,
    { params }
  );

  return response.data;
};

export const getUsersForProviderOfUserTypeCount = async (
  providerId: number | null | undefined,
  userTypeId: number | null | undefined,
  lang?: string
) => {
  if (!providerId || !userTypeId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await userApi.get<number>(
    `providers/${providerId}/user_type/${userTypeId}/count`,
    { params }
  );

  return response.data;
};

export const getLearnersForInstructor = async (
  instructorId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!instructorId) {
    throw Error('no id provided');
  }
  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await userApi.get<components['schemas']['PagedUserDto']>(
    `instructors/${instructorId}/learners`,
    { params }
  );

  return response.data;
};

export const getUsersForProvider = async (
  providerId: number | null | undefined,

  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await userApi.get<components['schemas']['PagedUserDto']>(
    `providers/${providerId}/Paged`,
    { params }
  );

  return response.data;
};

export const getUsersForProviderCentreOfUserType = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  userTypeId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  filtering?: any,
  lang?: string
) => {
  if (!providerId || !centreId || !userTypeId) {
    throw Error('no id provided');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await userApi.get<components['schemas']['PagedUserDto']>(
    `providers/${providerId}/centre/${centreId}/user_type/${userTypeId}`,
    { params }
  );

  return response.data;
};

export const getUsersForProviderCentreOfUserTypeCount = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  userTypeId: number | null | undefined,
  lang?: string
) => {
  if (!providerId || !centreId || !userTypeId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await userApi.get<number>(
    `providers/${providerId}/centre/${centreId}/user_type/${userTypeId}/count`,
    { params }
  );

  return response.data;
};

// createUser for creating both learners and instructors
export const createUser = async (
  User: Subset<components['schemas']['UserDto']>
) => {
  const body = {
    User
  };

  const response = await userApi.post<components['schemas']['UserDto']>(
    '',
    body
  );
  return response.data;
};

export const createLearnerPermissions = async (
  providerId: number | null | undefined,
  learner: components['schemas']['UserDto']
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const body = {
    userProviders: [
      {
        UserId: learner.userId,
        ProviderId: providerId,
        UserTypeId: UserType.LEARNER,
        ExternalId: 'externalId'
      }
    ]
  };

  const response = await userApi.post<components['schemas']['UserDto']>(
    `/${learner.userId}/user_permissions`,
    body
  );

  return response.data;
};

export const createInstructorPermissions = async (
  providerId: number | null | undefined,
  instructor: components['schemas']['UserDto']
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const body = {
    userProviders: [
      {
        UserId: instructor.userId,
        ProviderId: providerId,
        UserTypeId: UserType.INSTRUCTOR,
        ExternalId: 'externalId',
        UserTypeName: null,
        CentreId: null,
        FrameworkId: 1,
        UserType: null
      }
    ]
  };

  const response = await userApi.post<components['schemas']['UserDto']>(
    `/${instructor.userId}/user_permissions`,
    body
  );

  return response.data;
};

export const addUserPermissions = async (
  userId: number,
  userProviders: components['schemas']['UserProvidersDto'][]
) => {
  if (!userId) {
    throw Error('no id provided');
  }

  const body = { userProviders };

  const response = await userApi.post<
    components['schemas']['UserProvidersDto']
  >(`/${userId}/user_permissions`, body);

  return response.data;
};

export const deleteUserPermissions = async (
  userId: number,
  permissionId: number
) => {
  if (!userId) {
    throw Error('no id provided');
  }

  const response = await userApi.delete<
    components['schemas']['UserProvidersDto']
  >(`/${userId}/user_permissions/${permissionId}`);

  return response.data;
};

export const replaceUserPermissionsForProviderAndCentres = async (
  userId: number,
  providerId: number | null | undefined,
  userTypeId: number,
  userProviders: components['schemas']['UserProvidersDto'][]
) => {
  if (!userId || !providerId || !userTypeId) {
    throw Error('no id provided');
  }

  const body = { userProviders };

  const response = await userApi.put<components['schemas']['UserProvidersDto']>(
    `/${userId}/providers/${providerId}/usertypes/${userTypeId}/user_permissions/replace`,
    body
  );

  return response.data;
};

export const getUserPermissions = async (userId: number | undefined | null) => {
  if (!userId) {
    throw Error('no id provided');
  }
  const response = await userApi.get<
    components['schemas']['UserProvidersDto'][]
  >(`${userId.toString(10)}/user_permissions`);
  return response.data;
};

export const updateUser = async (
  updatedUser: components['schemas']['UserDto']
) => {
  if (!updatedUser) {
    throw Error('no user');
  }
  const body = { User: updatedUser };

  const response = await userApi.put<components['schemas']['UserDto']>(
    `/${updatedUser.userId}`,
    body
  );

  return response.data;
};

export const removeUserPermission = async (
  userPermission: Partial<components['schemas']['UserProvidersDto']>
) => {
  const response = await userApi.delete<components['schemas']['UserDto']>(
    `/${userPermission.userId}/user_permissions/${userPermission.userProviderId}`
  );
  return response.data;
};

export const getUsersForProviderSchoolOfUserType = async (
  providerId: number | null | undefined,
  schoolId: number | null | undefined,
  userTypeId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!providerId || !schoolId || !userTypeId) {
    throw Error('no id provided');
  }
  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await userApi.get<components['schemas']['PagedUserDto']>(
    `providers/${providerId}/school/${schoolId}/user_type/${userTypeId}`,
    { params }
  );

  return response.data;
};

export const getCentresForUser = async (
  userId: number | null | undefined,
  userTypeId: number | null | undefined,
  lang: string | undefined
) => {
  const params = {
    lang
  };

  const response = await userApi.get<components['schemas']['CentreDto'][]>(
    `${userId}/type/${userTypeId}/centres`,
    {
      params
    }
  );

  return response.data;
};

export const getUsersForProviderBillingReport = async (
  providerId: number | null | undefined,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await userApi.get<any>(
    `providers/${providerId}/billingReport`,
    {
      params
    }
  );

  return response.data;
};
