import { AppLanguage } from '../const';

const schoolKeys = {
  // everything to do with schools
  all: () => ['schools'] as const,
  lang: (displayLocale: string | AppLanguage | undefined) =>
    [displayLocale] as const,
  providerCount: (
    providerId: number | undefined | null,
    displayLocale: string | undefined
  ) =>
    [
      'count',
      providerId,
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  centreCount: (
    providerId: number | undefined | null,
    centreId: number | undefined | null,
    displayLocale: string | undefined
  ) =>
    [
      'count',
      providerId,
      centreId,
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  list: () => [...schoolKeys.all(), 'list'] as const,
  summaryCount: (
    providerId: number | undefined | null,
    displayLocale: string | undefined,
    filtering: any | null | undefined
  ) =>
    [
      ...schoolKeys.list(),
      'summary',
      providerId,
      filtering,
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  providerList: (
    providerId: number | undefined | null,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    filtering: any | null | undefined
  ) =>
    [
      ...schoolKeys.list(),
      'provider',
      providerId,
      pageIndex,
      pageSize,
      sorting,
      filtering,
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,

  centreList: (
    providerId: number | undefined | null,
    centreId: number | undefined | null,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    filtering: any | null | undefined
  ) =>
    [
      ...schoolKeys.list(),
      'centre',
      providerId,
      centreId,
      pageIndex,
      pageSize,
      sorting,
      filtering,
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  courseList: (
    schoolId: number | undefined | null,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    filtering: any | null | undefined
  ) =>
    [
      ...schoolKeys.list(),
      'courses',
      schoolId,
      pageIndex,
      pageSize,
      sorting,
      filtering,
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  school: (schoolId: number, displayLocale: string | undefined) =>
    [
      ...schoolKeys.all(),
      schoolId,
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,

  admins: (schoolId: number, displayLocale?: string | undefined) =>
    [
      ...schoolKeys.all(),
      schoolId,
      'admins',
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ] as const,
  adminsForProvider: (
    providerId: number,
    displayLocale?: string | undefined,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [
      ...schoolKeys.all(),
      providerId,
      'admins-for-provider',
      schoolKeys.lang(displayLocale || AppLanguage.English)
    ];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  }
};

export default schoolKeys;
