import { AppLanguage } from '../const';

const frameworkKeys = {
  // everything to do with frameworks
  all: (displayLocale: string | undefined) =>
    ['frameworks', displayLocale || AppLanguage.English] as const,
  lang: (displayLocale: string | AppLanguage | undefined) =>
    [displayLocale] as const,
  // list of frameworks
  list: (displayLocale: string | undefined) =>
    [
      ...frameworkKeys.all(displayLocale || AppLanguage.English),
      'list'
    ] as const,
  listPaged: (
    providerId: number | null | undefined,
    displayLocale: string | undefined,
    pageIndex?: number | null | undefined,
    pageSize?: number | null | undefined,
    sorting?: any | null | undefined,
    filtering?: any | null | undefined
  ) =>
    [
      ...frameworkKeys.all(displayLocale || AppLanguage.English),
      providerId,
      pageIndex,
      pageSize,
      sorting,
      filtering,
      'list'
    ] as const,
  framework: (frameworkId: number, displayLocale: string) =>
    [
      ...frameworkKeys.all(displayLocale || AppLanguage.English),
      frameworkId,
      ...frameworkKeys.lang(displayLocale)
    ] as const,
  frameworksByProvider: (
    providerId: number | undefined | null,
    displayLocale: string | undefined
  ) =>
    [
      ...frameworkKeys.list(displayLocale),
      providerId,
      ...frameworkKeys.lang(displayLocale)
    ] as const,
  frameworkProviders: (frameworkId: number, displayLocale?: string) =>
    [
      ...frameworkKeys.all(displayLocale || AppLanguage.English),
      frameworkId,
      'providers',
      ...frameworkKeys.lang(displayLocale)
    ] as const,
  coursesFrameworks: (providerId: number, displayLocale: string | undefined) =>
    [
      ...frameworkKeys.all(displayLocale || AppLanguage.English),
      providerId,
      'coursesFramework',
      ...frameworkKeys.lang(displayLocale)
    ] as const,
  version: (
    frameworkId: number,
    frameworkVersionId: number,
    displayLocale?: string | undefined
  ) =>
    [
      ...frameworkKeys.all(displayLocale || AppLanguage.English),
      frameworkId,
      'version',
      frameworkVersionId,
      ...frameworkKeys.lang(displayLocale)
    ] as const,
  versionList: (frameworkId: number, displayLocale?: string | undefined) =>
    [
      ...frameworkKeys.list(displayLocale),
      frameworkId,
      'versions',
      ...frameworkKeys.lang(displayLocale)
    ] as const
};

export default frameworkKeys;
