import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

function Unauthorised() {
  const intl = useIntl();
  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.unauthorised',
            defaultMessage: 'Unauthorised'
          })}
        </title>
      </Helmet>
      <div className="alert alert-dark" role="alert">
        {' '}
        <FormattedMessage
          id="unauthorised"
          defaultMessage="You do not have permission to view this page. Please contact admin if you think you should have access"
          description="User not authorised to view page message"
        />
      </div>
    </div>
  );
}

export default Unauthorised;
