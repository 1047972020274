import { useCookieConsentContext } from '@use-cookie-consent/react';
import ReactGA from 'react-ga4';
import { clarity } from 'react-microsoft-clarity';

const useCookies = () => {
  const { cookies } = useCookieConsentContext();

  const acceptGACookies = () => {
    ReactGA.gtag('consent', 'default', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
      wait_for_update: 2000
    });
  };

  const acceptClarityCookies = () => {
    clarity.init(`${process.env.REACT_APP_CLARITY_ID}`);
    clarity.consent();
  };

  const removeGACookies = () => {
    cookies.remove('_ga', {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    });
    cookies.remove('_gid', {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    });
    cookies.remove(`_gat_gtag_${process.env.REACT_APP_GA_TRACKING_ID}`, {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    });
    cookies.remove(`_ga_${process.env.REACT_APP_GA_MEASUREMENT_ID}`, {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    });

    ReactGA.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 2000
    });
  };

  const removeClarityCookies = () => {
    cookies.remove('MUID', {
      path: '/',
      domain: '.clarity.ms'
    });

    cookies.remove('CLID', {
      path: '/',
      domain: 'www.clarity.ms'
    });

    cookies.remove('_clsk', {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    });

    cookies.remove('_clck', {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    });
  };

  return {
    removeGACookies,
    removeClarityCookies,
    acceptGACookies,
    acceptClarityCookies
  };
};

export default useCookies;
