import React, { Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ObjectiveUserStars } from '../../../../../../types/ObjectiveUserStars.type';
import { components } from '../../../../../../types/openapi/CourseService';

function ObjectiveStars({
  starsForUser,
  renderStarElement
}: {
  starsForUser: ObjectiveUserStars | components['schemas']['ObjectiveAndStar'];
  renderStarElement: ({
    userStarValue,
    inputStarValue
  }: {
    userStarValue: number | undefined;
    inputStarValue: number;
  }) => JSX.Element | ReactNode;
}) {
  const NUMBER_OF_STARS = 3;
  return (
    <div className="stars vertical my-3">
      {Array.from({ length: NUMBER_OF_STARS }, (v, i) => i).map((e) => {
        const starValue = e + 1;
        return (
          <Fragment key={e}>
            {renderStarElement({
              inputStarValue: starValue,
              userStarValue: starsForUser.numberOfStars
            })}
          </Fragment>
        );
      })}
      <span className="visually-hidden">
        <FormattedMessage
          id="star.count"
          defaultMessage="{starsForUser} / {numberOfStars} stars"
          values={{
            starsForUser: starsForUser.numberOfStars,
            numberOfStars: NUMBER_OF_STARS
          }}
        />
      </span>
    </div>
  );
}

export default ObjectiveStars;
