// eslint-disable @typescript-eslint/dot-notation
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { redirect } from 'react-router-dom';

import { AppLanguage, AppRoute } from '../const';
import AVAILABLE_LOCALES from '../const/available-locales';
import { appStrings } from '../modules/i18n';
import Layout from '../pages/layout';
import LanguageRedirect from './LanguageRedirect';

import { ErrorPage, HoldingPage } from '../pages';

import {
  AccessibilityStatement,
  CookiePolicy,
  PrivacyPolicy,
  TermsAndConditions
} from '../pages/footer-links';

const children = AVAILABLE_LOCALES.map((lang) => {
  return [
    {
      index: true,
      element: <HoldingPage />,
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[lang][AppRoute.AccessibilityStatement],
      element: <AccessibilityStatement />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="accessibility_statement.breadcrumb"
            defaultMessage="Accessibility Statement"
            description="Accessibility Statement"
          />
        )
      }
    },
    {
      path: appStrings[lang][AppRoute.CookiePolicy],
      element: <CookiePolicy />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="cookie_policy.breadcrumb"
            defaultMessage="Cookie Policy"
            description="Cookie Policy"
          />
        )
      }
    },
    {
      path: appStrings[lang][AppRoute.PrivacyPolicy],
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="privacy_policy.breadcrumb"
            defaultMessage="Privacy Policy"
            description="Privacy Policy"
          />
        )
      }
    },
    {
      path: appStrings[lang][AppRoute.TermsAndConditions],
      element: <TermsAndConditions />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="terms_and_conditions.breadcrumb"
            defaultMessage="Terms and Conditions"
            description="Terms and Conditions"
          />
        )
      }
    }
  ];
}).flat();

export default function routes(defaultLanguage: AppLanguage) {
  let currentLang = defaultLanguage;
  return {
    element: <Layout />,
    children: [
      {
        path: '/:lang',
        loader: ({ params }: { params: any }) => {
          const { lang } = params;
          // check if lang is one we support
          if (AVAILABLE_LOCALES.includes(lang)) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            currentLang = params['lang'];
          } else {
            console.warn(
              `${lang} is NOT a valid language, redirecting to default lang...`
            );
            return redirect(`/${currentLang || AppLanguage.English}`);
          }
          return lang;
        },
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="home.breadcrumb"
              defaultMessage="Home"
              description="Home"
            />
          )
        },
        children: [...children]
      },
      // user will be re-routed to language
      {
        index: true,
        element: <LanguageRedirect />
      },
      {
        path: '*',
        element: <HoldingPage />
      }
    ],
    errorElement: <ErrorPage />
  };
}
