/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface MyModalContext {
  modal: (
    content: string | JSX.Element,
    { ...options }: { [x: string]: any },
    props?: {}
  ) => void;
  closeModal: () => void;
}

export const ModalContext = React.createContext<MyModalContext>({
  modal: () => {},
  closeModal: () => {}
});

function ModalProvider({ children }: { children: any }) {
  const [show, setShow] = useState(false);
  // const [hideFooter, setHideFooter] = useState(false);
  const [modalProps, setModalProps] = useState<any>(null);
  const [handlers, setHandlers] = useState<any>(null);

  const [modalHeader, setModalHeader] = useState<string | null>('');
  const [modalBody, setModalBody] = useState<string | JSX.Element | null>('');
  const [confirmButtonText, setConfirmButtonText] = useState<string | null>('');
  const [cancelButtonText, setCancelButtonText] = useState<string | null>('');

  function modal(content: string | JSX.Element, { ...options }, props = {}) {
    const {
      title,
      close,
      confirm,
      header,
      footer,
      confirmText,
      cancelText,
      hideFooter,
      dialogClassName,
      classNames
    } = options;
    setHandlers({ close, confirm });
    setModalBody(content);
    setModalHeader(header);
    setConfirmButtonText(confirmText);
    setCancelButtonText(cancelText);
    setModalProps({
      title,
      content: {
        type: content,
        props
      },
      header,
      footer,
      hideFooter,
      dialogClassName,
      classNames
    });
  }

  function onCloseModal() {
    setShow(false);
  }

  function onConfirmModal() {
    if (handlers?.confirm) {
      handlers.confirm().then((result: any) => {
        if (result == null) return onCloseModal();

        if (typeof result === 'object') {
          if (result.success) return onCloseModal();

          if (result.props != null) {
            const newProps = { ...modalProps };
            newProps.content.props = {
              ...newProps.content.props,
              ...result.props
            }; // shallow copy in the originals, then override them with any new ones.
            setModalProps(newProps);
          }

          return;
        }
        onCloseModal();
      });
    } else onCloseModal(); // close anyway; no confirm handler
  }

  useEffect(() => {
    if (!modalProps || !handlers) return;
    setShow(true);
  }, [modalProps, handlers]);

  useEffect(() => {
    if (!show) {
      // NOTE: closed, call close handler if avail.
      if (handlers?.close) {
        handlers.close(); // may be async. Not expecting a result
      }

      // NOTE: clear state
      setModalProps(null);
      setHandlers(null);
    }
  }, [show]);

  const closeModal = () => {
    onCloseModal();
  };

  const handleConfirm = () => {
    onConfirmModal();
  };

  return (
    <ModalContext.Provider
      value={{
        modal,
        closeModal
      }}
    >
      <Modal
        show={show}
        onHide={closeModal}
        dialogClassName={modalProps?.dialogClassName}
      >
        <Modal.Header closeButton className={modalProps?.classNames?.header}>
          <Modal.Title as="h1">{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        {modalProps && !modalProps?.hideFooter && (
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              {cancelButtonText || (
                <FormattedMessage
                  id="cancel"
                  defaultMessage="Cancel"
                  description="Cancel"
                />
              )}
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              {confirmButtonText || (
                <FormattedMessage
                  id="save.button"
                  defaultMessage="Save"
                  description="Save"
                />
              )}
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
