/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */

import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import { FormattedMessage } from 'react-intl';

import { components } from '../../types/openapi/CourseService';

function NoteForm({
  onSubmit,
  submitButtonMessage,
  noteText,
  formDisabled,
  setShowNoteForm,
  setShowEditId,
  helpText
}: {
  onSubmit: (value: Pick<components['schemas']['NoteDto'], 'noteText'>) => void;
  submitButtonMessage: any;
  noteText?: string;
  formDisabled?: boolean;
  setShowNoteForm?: Dispatch<SetStateAction<boolean | undefined>>;
  setShowEditId?: Dispatch<SetStateAction<number>>;
  helpText?: {
    noteText: string | null | undefined;
  };
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      noteText: noteText || ''
    }
  });
  const editorRef = useRef(null);

  return (
    <div>
      <form
        className="d-flex flex-column flex-grow-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-flex">
          <div className="mb-3 w-100 editor-container">
            <Controller
              control={control}
              name="noteText"
              rules={{ required: true }}
              render={({ field }) => (
                <Editor
                  apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                  {...field}
                  {...register('noteText')}
                  aria-invalid={errors.noteText ? 'true' : 'false'}
                  onInit={(evt, editor) =>
                    ((editorRef as any).current = editor)
                  }
                  initialValue={noteText}
                  onEditorChange={(event) => {
                    field.onChange(event);
                  }}
                  id="text-editor"
                  init={{
                    height: 200,
                    menubar: false,
                    elementpath: false,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'help',
                      'wordcount'
                    ],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help'
                  }}
                />
              )}
            />

            {helpText && helpText.noteText && (
              <div id="noteTextHelpBlock" className="form-text">
                {helpText.noteText}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="btn-group gap-1">
            <button
              type="button"
              className="btn btn-secondary align-self-center mt-1 rounded-0"
              onClick={() => {
                // Hide the forms
                if (setShowNoteForm) setShowNoteForm(false);
                if (setShowEditId) setShowEditId(0);
              }}
            >
              <FormattedMessage
                id="cancel"
                defaultMessage="Cancel"
                description="Cancel"
              />
            </button>
            <button
              type="submit"
              className="btn btn-primary align-self-center mt-1 rounded-0"
              disabled={formDisabled || !isDirty || !isValid}
            >
              {submitButtonMessage}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NoteForm;

NoteForm.defaultProps = {
  noteText: '',
  formDisabled: false,
  helpText: {
    noteText: ''
  },
  setShowEditId: () => {},
  setShowNoteForm: () => {}
};
