/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import GroupAdminsTable from './GroupAdminsTable';
import useGroupAdmins from '../../../hooks/useGroupAdmins';
import { FilteringQueryParams } from '../../../const/filtering-query-params';
import { components } from '../../../types/openapi/UserService';
import { ModalContext } from '../../../modules/modal/ModalProvider';
import { AppRoute } from '../../../const';
import localiseRoutePath from '../../../utils/localiseRoutePath';
import { UserType } from '../../../const/user-type';
import groupKeys from '../../../query-keys/group-key-factory';
import { removeUserPermission } from '../../../services/api/user.service';

function GroupAdminsListPaged({
  providerId,
  centreId,
  profile
}: {
  providerId: number | null | undefined;
  centreId?: number | null | undefined;
  profile: components['schemas']['UserProvidersDto'] | null | undefined;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const queryClient = useQueryClient();

  const queryParamsLocation = FilteringQueryParams.GroupAdmins;

  const [adminPagination, setAdminPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [adminSorting, setAdminSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [adminFiltering, setAdminFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  const { groupAdminsQuery } = useGroupAdmins({
    providerId,
    centreId,
    displayLocale,
    pageIndex: adminPagination.pageIndex,
    pageSize: adminPagination.pageSize,
    sorting: adminSorting,
    rawFiltering: adminFiltering
  });

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(adminPagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(adminPagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(adminSorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(adminFiltering)
    );
  }, [adminPagination, adminSorting, adminFiltering]);

  const { mutate: newRemoveGroupAdminPermission } = useMutation(
    (groupAdminPermission: any) => removeUserPermission(groupAdminPermission),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'group.admin.removed.success',
            defaultMessage: 'This group admin was removed from your provider'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.groupAdmins(profile?.providerId, displayLocale)
        });
      },
      onError: () => {
        toast.error(
          intl.formatMessage({
            id: 'group.admin.removed.error',
            defaultMessage: 'There was an error removing this group admin'
          }),
          { delay: 200 }
        );
      }
    }
  );

  async function onModalConfirm(groupAdmin: components['schemas']['UserDto']) {
    groupAdmin.userProviders!.forEach((userProvider) => {
      if (
        Number(userProvider.userTypeId) === UserType.GROUP_ADMIN &&
        Number(userProvider.providerId) === Number(profile?.providerId)
      ) {
        const groupAdminPermission: Partial<
          components['schemas']['UserProvidersDto']
        > = {
          userId: groupAdmin.userId!,
          userProviderId: userProvider.userProviderId
        };
        newRemoveGroupAdminPermission(groupAdminPermission);
      }
    });
  }

  function openModal(groupAdmin: components['schemas']['UserDto']) {
    const header = intl.formatMessage(
      {
        id: 'group.admin.delete.confirm.header',
        defaultMessage: 'Delete Group Admin: {name}',
        description: 'Delete Group Admin Confirm Modal Header'
      },
      { name: groupAdmin.username }
    );
    const modalContent = intl.formatMessage({
      id: 'group.admin.delete.confirm.body',
      defaultMessage:
        'Are you sure you want to delete this group admin? This action cannot be undone',
      description: 'Delete group admin Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'group.admin.delete.confirm.button',
      defaultMessage: 'Delete Group Admin',
      description: 'Delete Group Admin Confirm Button'
    });
    modal(modalContent, {
      confirm: () => onModalConfirm(groupAdmin),
      header,
      confirmText
    });
  }

  return (
    <div className="card border-0">
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="group.admins"
            defaultMessage="Group Admins"
            description="Group Admins"
          />
        </h2>
      </div>
      <div className="card-body">
        <GroupAdminsTable
          data={groupAdminsQuery.data}
          error={groupAdminsQuery.error}
          isLoading={groupAdminsQuery.isLoading}
          refetching={groupAdminsQuery.isRefetching}
          pagination={adminPagination}
          sorting={adminSorting}
          filtering={adminFiltering}
          setPagination={setAdminPagination}
          setSorting={setAdminSorting}
          setFiltering={setAdminFiltering}
          queryParamsLocation={queryParamsLocation}
          renderActions={(groupAdmin) => {
            return (
              <div className="btn-group-vertical">
                <Link
                  to={`${localiseRoutePath(AppRoute.EditGroupAdmin)}/${
                    groupAdmin.userId
                  }`}
                  className="btn btn-outline-secondary"
                >
                  <FormattedMessage
                    id="group.admins.edit"
                    defaultMessage="Edit"
                    description="Edit a group admin"
                  />
                </Link>

                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => openModal(groupAdmin)}
                >
                  <FormattedMessage
                    id="groupAdmin.delete"
                    defaultMessage="Remove Group Admin"
                    description="Remove Group Admin from provider"
                  />
                </button>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}

export default GroupAdminsListPaged;

GroupAdminsListPaged.defaultProps = {
  centreId: undefined
};
