/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/dot-notation */
import { AnonymousCredential, BlockBlobClient } from '@azure/storage-blob';
import axios from 'axios';
// @ts-ignore
import pascalcaseKeys from 'pascalcase-keys';
import { AppLanguage } from '../../const';
import { ResourceType } from '../../const/resource-type';
import { components } from '../../types/openapi/ResourceService';

import { bearerToken } from '../bearer-token';
import camelCaseResponse from '../camel-case-interceptor';
import expiredTokenInterceptor from '../refresh-access-token.interceptor';

const BASE_URL = `${process.env.REACT_APP_RESOURCES_API_URL}/api/resources`;
const BLOB_BASE_URL = `${process.env.REACT_APP_BLOB_BASE_URL}`;

export const resourcesApi = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

resourcesApi.interceptors.request.use(
  (config) => {
    const token: string = bearerToken();
    if (token && config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

resourcesApi.interceptors.response.use(
  (response) => camelCaseResponse(response),
  (error) => expiredTokenInterceptor(resourcesApi, error)
);

export const uploadVideoResource = async (
  embedCode: any,
  resourceTypeID: number,
  objectiveID: string,
  frameworkVersionID: string,
  displayLocale: any
) => {
  const resourceObj = {
    Resource: {
      ObjectiveID: Number(objectiveID),
      ResourceTypeID: resourceTypeID,
      ResourceString: embedCode,
      FrameworkVersionID: Number(frameworkVersionID),
      ResourceLang: displayLocale
    }
  };

  const body = pascalcaseKeys(resourceObj, { deep: true });

  const response = await resourcesApi.post<any>(``, body);

  return response.data;
};

// eslint-disable-next-line func-names
const blobUpload = async function (
  file: any,
  url: string | undefined,
  container: any,
  blobName: any,
  sasKey: string
) {
  const login = `${url}/${container}/${blobName}?${sasKey}`;
  const blockBlobClient = new BlockBlobClient(login, new AnonymousCredential());
  try {
    return await blockBlobClient.uploadBrowserData(file);
  } catch (e) {
    return e;
  }
};

export const uploadResourceFile = async (
  file: FileList | null,
  resourceTypeID: number,
  objectiveID: string,
  frameworkVersionID: string,
  displayLocale: any
) => {
  const body = {
    Resource: {
      ObjectiveID: Number(objectiveID),
      ResourceTypeID: resourceTypeID,
      FileName: file!.item(0)!.name,
      FrameworkVersionID: Number(frameworkVersionID),
      ResourceLang: displayLocale
    }
  };
  if (resourceTypeID === ResourceType.TROPHY) {
    const fileResponse = await resourcesApi
      .post<any>(``, body)
      .then((response) =>
        blobUpload(
          file!.item(0),
          BLOB_BASE_URL,
          `frameworkversion-${frameworkVersionID}`,
          `objective-${objectiveID}/${body.Resource.FileName}`,
          response.data.sasToken
        )
      );
    if (fileResponse) {
      const translatedBody = body;
      translatedBody.Resource.ResourceLang =
        displayLocale === AppLanguage.English
          ? AppLanguage.Welsh
          : AppLanguage.English;

      const translatedFileResponse = await resourcesApi
        .post<any>(``, translatedBody)
        .then((response) =>
          blobUpload(
            file!.item(0),
            BLOB_BASE_URL,
            `frameworkversion-${frameworkVersionID}`,
            `objective-${objectiveID}/${translatedBody.Resource.FileName}`,
            response.data.sasToken
          )
        );
      return translatedFileResponse;
    }
    return fileResponse;
  }

  const fileResponse = await resourcesApi
    .post<any>(``, body)
    .then((response) =>
      blobUpload(
        file!.item(0),
        BLOB_BASE_URL,
        `frameworkversion-${frameworkVersionID}`,
        `objective-${objectiveID}/${body.Resource.FileName}`,
        response.data.sasToken
      )
    );

  return fileResponse;
};

export const getResources = async (
  frameworkVersionID: number | undefined,
  lang?: string
) => {
  if (!frameworkVersionID) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };
  const response = await resourcesApi.get<
    components['schemas']['ResourceDto'][]
  >(`/${frameworkVersionID}`, {
    params
  });
  return response.data;
};

export const deleteResource = async (resourceId: number) => {
  const response = await resourcesApi.delete<any>(`/${resourceId}`);
  return response.data;
};
