import { SPCalendarEvent } from '../SPCalendarEvent.type';
import { EventFilters } from './EventFilters.type';

const filterEvents = (
  allEvents: SPCalendarEvent[],
  filterParams: EventFilters
) => {
  const { bookable, name, centreId, frameworkId } = filterParams;
  return allEvents
    .filter((event) => {
      // show all
      if (bookable) {
        return true;
      }
      // hide bookable
      if (!bookable && (event as any).bookable) {
        return false;
      }
      return true;
    })
    .filter((event) => {
      // dont filter if no filter applied
      if (!name) {
        return true;
      }
      // filter by name
      if ((event as any).session?.course?.name) {
        // make filter case insensitive
        return (event as any).session.course.name
          ?.toUpperCase()
          .includes(name?.toUpperCase());
      }
      return false;
    })
    .filter((event) => {
      // dont filter if no filter applied
      if (!centreId) {
        return true;
      }
      // filter by centre
      if ((event as any).session?.centreId) {
        return (event as any).session.centreId === Number(centreId);
      }
      return false;
    })
    .filter((event) => {
      // dont filter if no filter applied
      if (!frameworkId) {
        return true;
      }
      // filter by frameworkID
      if ((event as any).session?.course?.frameworkVersion.frameworkId) {
        return (
          (event as any).session.course?.frameworkVersion.frameworkId ===
          Number(frameworkId)
        );
      }
      return false;
    });
};

export default filterEvents;
