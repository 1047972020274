import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { LangContext } from '../modules/i18n/components/IntlWrapper';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';

function LoginRedirect() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  return (
    <Navigate
      to={`/${displayLocale}/${
        appStrings[displayLocale as AppLanguage][AppRoute.Login]
      }`}
      replace
    />
  );
}

export default LoginRedirect;
