import React from 'react';

function ReadOnlyStar({
  usersStar,
  starValue
}: {
  usersStar: number | undefined;
  starValue: number;
}) {
  const isFilled = usersStar && usersStar >= starValue;
  return (
    <div
      className={`readonly-star ${isFilled ? '' : 'readonly-star--unfilled'}`}
    >
      {isFilled}
    </div>
  );
}

export default ReadOnlyStar;
