import React, { Suspense } from 'react';

function PublicPageLayout({
  header,
  children
}: {
  header: any;
  children: any;
}) {
  return (
    <div className="container-fluid">
      <div className="row gap-2 gap-md-0 my-3 ">
        <div className="col-12">
          <div className="card rounded-top-right-lg border-0 bg-white d-inline-block bg-white d-flex flex-grow-1 h-100 w-100 w-lg-50 mx-auto">
            <div className="card-header">
              <h1 className="mb-0">{header}</h1>
            </div>
            <div className="card-body">
              <Suspense>{children}</Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicPageLayout;
