import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function InlineFormError({
  className,
  message
}: {
  message: JSX.Element | string;
  className?: string;
}) {
  // if you are using this for inline form validation as opposed to validation on submit
  // then you should wrap this component in an aria-live="assertive" that is present in the DOM on page load
  return (
    <p className={`invalid-feedback d-inline ${className}`}>
      <FontAwesomeIcon icon={faTriangleExclamation} /> {message}
    </p>
  );
}

export default InlineFormError;

InlineFormError.defaultProps = {
  className: ''
};
