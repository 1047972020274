import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { deleteProviderGroup } from '../../services/api/provider.service';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ModalContext } from '../../modules/modal/ModalProvider';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import groupKeys from '../../query-keys/group-key-factory';
import { components } from '../../types/openapi/ProviderService';
import { appStrings } from '../../modules/i18n';
import { AppLanguage, AppRoute } from '../../const';

function GroupListActions({
  group,
  queryParamsLocation,
  linkTo,
  canDelete
}: {
  group: components['schemas']['GroupDto'];
  queryParamsLocation: string;
  linkTo: any;
  canDelete?: boolean;
}) {
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const queryClient = useQueryClient();

  const { profile } = profileContext;

  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const userProviderId = profile?.providerId;

  const { mutate: deleteGroup } = useMutation(
    () => deleteProviderGroup(userProviderId, group.groupId),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'group.delete.success',
            defaultMessage: 'Group has been removed'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.groupList(
            profile?.providerId,
            displayLocale,
            Number(
              window.sessionStorage.getItem(
                `${queryParamsLocation}.pageIndex`
              ) ?? 0
            ),
            Number(
              window.sessionStorage.getItem(
                `${queryParamsLocation}.pageSize`
              ) ?? 10
            ),
            JSON.parse(
              window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ??
                '[]'
            ),
            JSON.parse(
              window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ??
                '[]'
            )
          )
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.delete.error',
            defaultMessage: 'There was an error removing this group'
          }),
          { delay: 200 }
        );
      }
    }
  );

  async function onModalConfirm() {
    deleteGroup();
  }

  function openModal() {
    const header = intl.formatMessage(
      {
        id: 'group.remove.confirm.header',
        defaultMessage: 'Remove Group: {name}',
        description: 'Remove Group Confirm Modal Header'
      },
      { name: group.name }
    );
    const modalContent = intl.formatMessage({
      id: 'group.remove.confirm.body',
      defaultMessage:
        'Are you sure you want to remove this group? This action cannot be undone',
      description: 'Remove Group Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'group.remove.confirm.button',
      defaultMessage: 'Remove Group',
      description: 'Remove group Confirm Button'
    });
    modal(modalContent, {
      confirm: () => onModalConfirm(),
      header,
      confirmText
    });
  }

  return (
    <div className="btn-group-vertical">
      {profile?.groupId && profile.groupId !== group.groupId ? (
        <Link
          to={`/${displayLocale}/${
            appStrings[displayLocale as AppLanguage][AppRoute.RoleChange]
          }`}
          className="btn btn-outline-secondary"
        >
          <FormattedMessage
            id="group.administrate"
            defaultMessage="Manage this group"
            description="Manage this group"
          />
        </Link>
      ) : (
        <Link to={linkTo} className="btn btn-outline-secondary">
          <FormattedMessage
            id="view"
            defaultMessage="View"
            description="View"
          />
        </Link>
      )}

      {canDelete && (
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => openModal()}
        >
          <FormattedMessage
            id="group.delete"
            defaultMessage="Remove Group"
            description="Remove group from provider"
          />
        </button>
      )}
    </div>
  );
}

export default GroupListActions;
GroupListActions.defaultProps = {
  canDelete: () => false
};
