import { AppLanguage } from '../const';

const groupKeys = {
  // everything to do with groups
  all: (displayLocale: string | undefined) =>
    ['groups', displayLocale || AppLanguage.English] as const,
  // list of groups
  list: (
    providerId: number | undefined | null,
    displayLocale: string | undefined
  ) =>
    [
      ...groupKeys.all(displayLocale || AppLanguage.English),
      providerId,
      'list'
    ] as const,
  groupList: (
    providerId: number | undefined | null,
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    filtering: any | null | undefined
  ) =>
    [
      ...groupKeys.list(providerId, displayLocale || AppLanguage.English),
      'provider',
      providerId,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ] as const,
  group: (
    providerId: number | undefined | null,
    groupId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...groupKeys.all(displayLocale || AppLanguage.English),
      providerId,
      groupId
    ] as const,
  groupAdmins: (
    providerId: number | undefined | null,
    displayLocale: string | undefined,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [
      ...groupKeys.all(displayLocale || AppLanguage.English),
      'provider',
      providerId
    ];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  },
  groupAdmin: (groupAdminId: number, displayLocale: string | undefined) =>
    [
      ...groupKeys.all(displayLocale || AppLanguage.English),
      'admin',
      groupAdminId
    ] as const
};

export default groupKeys;
