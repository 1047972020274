import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { AppLanguage, AppRoute } from '../../../const';
import { FilteringQueryParams } from '../../../const/filtering-query-params';
import { UserType } from '../../../const/user-type';
import useTableState from '../../../hooks/state-management/useCreateTableStore';
import { appStrings } from '../../../modules/i18n';
import AppMode from '../../../modules/offline/app-mode.enum';
import CoursesTableForAdmin from '../../courses/CoursesTableForAdmin';
import CoursesTableForInstructor from '../../courses/CoursesTableForInstructor';
import LearnerCoursesListCourseLink from '../../courses/LearnerCoursesListCourseLink';
import useCoursesForLearner from '../../../hooks/courses/useCoursesForLearner';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { OfflineContext } from '../../../modules/offline/OfflineProvider';
import CardHeader from '../../ui/CardHeader';

function LearnerCourses({ userId }: { userId: number }) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const langVal = displayLocale as AppLanguage;

  const queryParamsLocation = FilteringQueryParams.LearnerCourses;

  const { useTableStateStore } = useTableState({
    name: queryParamsLocation
  });

  const {
    pagination,
    sorting,
    filtering,
    setPagination,
    setSorting,
    setFiltering
  } = useTableStateStore();

  const { coursesForLearnerListQuery } = useCoursesForLearner({
    userId,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering,
    displayLocale
  });

  return (
    <div className="card">
      <CardHeader
        badgeContent={
          coursesForLearnerListQuery.data &&
          coursesForLearnerListQuery.data.numberOfCourses
        }
      >
        <h3>
          <FormattedMessage
            id="courses.header"
            defaultMessage="Courses"
            description="Courses"
          />
        </h3>
      </CardHeader>

      <div className="card-body">
        {appMode === AppMode.OFFLINE ? (
          <div className="d-flex justify-content-end mb-2">
            <span className="alert alert-info flex-grow-1 me-3 mb-0">
              <FormattedMessage
                id="offline.course_data_unavailable"
                defaultMessage="You are currently in offline mode. Courses data is not available."
                description="You are currently in offline mode. Courses data is not available."
              />
            </span>
          </div>
        ) : profile?.userTypeId === UserType.ADMIN ? (
          <CoursesTableForAdmin
            data={coursesForLearnerListQuery.data}
            error={coursesForLearnerListQuery.error}
            seeCentres
            isLoading={coursesForLearnerListQuery.isLoading}
            refetching={coursesForLearnerListQuery.isRefetching}
            pagination={pagination}
            sorting={sorting}
            filtering={filtering}
            setPagination={setPagination}
            setSorting={setSorting}
            setFiltering={setFiltering}
            queryParamsLocation={queryParamsLocation}
            isLearnerPage
            renderActions={({ courseId }) => {
              return (
                <div className="btn-group-vertical">
                  <Link
                    to={`/${displayLocale?.toLowerCase()}/${
                      appStrings[langVal][AppRoute.Courses]
                    }/${courseId}`}
                    className="btn btn-outline-secondary"
                  >
                    <FormattedMessage
                      id="course"
                      defaultMessage="Course"
                      description="Course"
                    />
                  </Link>
                  <Link
                    to={`/${displayLocale?.toLowerCase()}/${
                      appStrings[langVal][AppRoute.Learners]
                    }/${userId}/${
                      appStrings[langVal][AppRoute.Courses]
                    }/${courseId}`}
                    className="btn btn-outline-secondary"
                  >
                    <FormattedMessage
                      id="progress"
                      defaultMessage="Progress"
                      description="Progress"
                    />
                  </Link>
                </div>
              );
            }}
          />
        ) : (
          <CoursesTableForInstructor
            data={coursesForLearnerListQuery.data}
            error={coursesForLearnerListQuery.error}
            isLoading={coursesForLearnerListQuery.isLoading}
            refetching={coursesForLearnerListQuery.isRefetching}
            pagination={pagination}
            sorting={sorting}
            filtering={filtering}
            setPagination={setPagination}
            setSorting={setSorting}
            setFiltering={setFiltering}
            queryParamsLocation={queryParamsLocation}
            renderCourseLink={({ courseId }) => {
              return <LearnerCoursesListCourseLink courseId={courseId} />;
            }}
          />
        )}
      </div>
    </div>
  );
}

export default LearnerCourses;
