import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { FilteringQueryParams } from '../../../const/filtering-query-params';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import CentresTable from '../../centres/CentresTable';
import { isAllowed } from '../../../utils/permissions';
import { UserType } from '../../../const/user-type';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import CardHeader from '../../ui/CardHeader';
import { components } from '../../../types/openapi/ProviderService';
import useUserPermissions from '../../../hooks/permissions/useUserPermissions';
import useStoreTableFilters from '../../../hooks/state-management/useStoreTableFilters';
import useAddUserPermissions from '../../../hooks/permissions/useAddUserPermissions';
import useProviderCentresForUser from '../../../hooks/centres/useProviderCentresForUser';

function LearnerCentres({
  providerId,
  userId,
  editCentresLink
}: {
  providerId: number;
  userId: number;
  editCentresLink: string;
}) {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const queryParamsLocation = FilteringQueryParams.InstructorCentres;

  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    filtering,
    setFiltering
  } = useStoreTableFilters({ queryParamsLocation });

  const { centresForUserListQuery } = useProviderCentresForUser({
    providerId,
    userId,
    userTypeId: UserType.LEARNER,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  const { permissionsQuery } = useUserPermissions({
    userId,
    displayLocale
  });

  const { deletePermission } = useAddUserPermissions();

  const removeCentre = (centre: components['schemas']['CentreDto']) => {
    const centrePermission = permissionsQuery.data?.find(
      (permission) => permission.centreId === centre.centreId
    );
    if (!centrePermission?.userProviderId) {
      toast.error(
        intl.formatMessage({
          id: 'learner.permissions.centre.permission.remove.error',
          defaultMessage:
            'There was an error removing selected centres from the learner. Related permission not found'
        }),
        { delay: 200 }
      );
      return;
    }
    deletePermission.mutate(
      { userId, permissionId: centrePermission.userProviderId },
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSuccess: (data) => {
          const toastId = 'removeSuccessToast';
          queryClient.invalidateQueries({
            queryKey: ['centres']
          });
          toast.success(
            intl.formatMessage({
              id: 'learner.permissions.centre.single.remove.success',
              defaultMessage: 'Selected centre has been removed'
            }),
            { delay: 200, toastId }
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onError: (error: any) => {
          const errorToast = 'removeErrorToast';

          toast.error(
            intl.formatMessage({
              id: 'learner.permissions.centre.remove.error',
              defaultMessage:
                'There was an error removing selected centres from the learner'
            }),
            { delay: 200, toastId: errorToast }
          );
        }
      }
    );
  };

  return (
    <div className="card">
      <CardHeader
        badgeContent={
          centresForUserListQuery.data &&
          centresForUserListQuery.data.numberOfCentres
        }
        button={
          isAllowed(
            [UserType.SUPER_ADMIN, UserType.SPORT_MANAGER, UserType.ADMIN],
            profile?.userTypeId as UserType
          ) && (
            <div>
              <Link to={editCentresLink} className="btn btn-primary mx-auto">
                <FormattedMessage
                  id="edit.centres.button"
                  defaultMessage="Edit centres"
                  description="Edit centres"
                />
              </Link>
            </div>
          )
        }
      >
        <h3>
          <FormattedMessage
            id="centres.header"
            defaultMessage="Centres"
            description="Centres"
          />
        </h3>
      </CardHeader>

      <div className="card-body">
        {centresForUserListQuery.data && centresForUserListQuery.data?.centres && (
          <CentresTable
            data={centresForUserListQuery.data}
            error={centresForUserListQuery.error}
            isLoading={centresForUserListQuery.isLoading}
            refetching={centresForUserListQuery.isRefetching}
            pagination={pagination}
            sorting={sorting}
            filtering={filtering}
            setPagination={setPagination}
            setSorting={setSorting}
            setFiltering={setFiltering}
            queryParamsLocation={queryParamsLocation}
            renderCentresLink={({ centre }) => (
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => removeCentre(centre)}
                data-id={centre.centreId}
              >
                <FormattedMessage id="centre.remove" defaultMessage="Remove" />
              </button>
            )}
          />
        )}
      </div>
    </div>
  );
}

export default LearnerCentres;
