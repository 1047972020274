import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import centreKeys from '../../query-keys/centre-key-factory';
import {
  getProviderCentre,
  updateProviderCentre
} from '../../services/api/provider.service';
import { components } from '../../types/openapi/ProviderService';
import { Subset } from '../../types/Subset.type';

// hook for managing a single course
const useCentre = ({
  centreId,
  providerId,
  displayLocale
}: {
  centreId: number;
  providerId: number;
  displayLocale: string | undefined;
}) => {
  if (!centreId) {
    throw Error('invalid course id');
  }

  const queryClient = useQueryClient();

  const centreQuery = useQuery({
    queryKey: centreKeys.centre(centreId, displayLocale),
    queryFn: () => getProviderCentre(providerId, centreId, displayLocale),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!centreId && !!providerId
  });

  const updateCentre = useMutation(
    (newCentre: Subset<components['schemas']['CentreDto']>) => {
      return updateProviderCentre(
        providerId,
        centreId,
        newCentre,
        displayLocale
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          centreKeys.centre(centreId, displayLocale),
          data
        );
      }
    }
  );

  return {
    centreQuery,
    updateCentre
  };
};

export default useCentre;
