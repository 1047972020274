/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../../../modules/i18n/components/IntlWrapper';
import Loading from '../../../common/Loading';
import { GeneralError } from '../../../common';
import LearnersTable from '../../../learners-admin/LearnersTable';
import CourseSessionDetailsTable from '../CourseSessionDetailsTable';
import { components } from '../../../../types/openapi/CourseService';
import localiseRoutePath from '../../../../utils/localiseRoutePath';
import { AppRoute } from '../../../../const/app-routes';
import { ProfileContext } from '../../../../modules/profile/ProfileProvider';
import { appStrings } from '../../../../modules/i18n';
import { AppLanguage } from '../../../../const';
import useSession from '../../../../hooks/useSession';
import RemoveLearnerFromSessionBtn from './RemoveLearnerFromCourseButton';
import useCourse from '../../../../hooks/course/useCourse';

function AdminSession() {
  const { courseId, sessionId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const intl = useIntl();

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  const [startDate, setStartDate] = useState<string>();
  const [availableSpaces, setAvailableSpaces] = useState<number>(-1);

  const { sessionQuery, sessionMembersQuery } = useSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    userId: profile.userId,
    displayLocale: displayLocale || AppLanguage.English
  });

  const { courseQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  useEffect(() => {
    if (sessionQuery.data) {
      const sessionStartTime = dayjs(sessionQuery.data.sessionStartTime);
      setStartDate(sessionStartTime.format('DD/MM/YYYY'));
    }
    if (
      sessionQuery.data &&
      sessionMembersQuery.data &&
      sessionQuery.data.course?.totalNumberOfPeople
    ) {
      setAvailableSpaces(
        sessionQuery.data.course.totalNumberOfPeople -
          sessionMembersQuery.data.length
      );
    }
  }, [sessionQuery.data, sessionMembersQuery.data, availableSpaces]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.session.date',
              defaultMessage: 'Session: {date}'
            },
            { date: startDate }
          )}
        </title>
      </Helmet>
      <div className="card">
        <div className="card-header">
          <h1>
            <FormattedMessage
              id="session.id"
              defaultMessage="Session: {date}"
              description="Session: {date}"
              values={{ date: startDate }}
            />
          </h1>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h3>
            <FormattedMessage
              id="details"
              defaultMessage="Details"
              description="Details"
            />
          </h3>
        </div>
        <div className="card-body">
          {sessionQuery.isFetching ? (
            <Loading />
          ) : sessionQuery.error ? (
            <GeneralError error={sessionQuery.error} />
          ) : (
            sessionQuery.data && (
              <CourseSessionDetailsTable
                session={sessionQuery.data}
                course={courseQuery.data}
              />
            )
          )}
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h3>
            <FormattedMessage
              id="learners"
              defaultMessage="Learners"
              description="Learners"
            />
          </h3>
          <span>
            {sessionQuery.isFetching || sessionMembersQuery.isFetching ? (
              <Loading />
            ) : sessionQuery.error ? (
              <GeneralError />
            ) : sessionQuery.data &&
              sessionQuery.data.course?.totalNumberOfPeople &&
              sessionMembersQuery.data ? (
              <FormattedMessage
                id="session.spaces.available"
                defaultMessage="Spaces available: {availableSpaces} / {capacity}"
                description="Spaces on course session that are available"
                values={{
                  availableSpaces,
                  capacity: sessionQuery.data.course.totalNumberOfPeople
                }}
              />
            ) : (
              sessionQuery.data &&
              sessionQuery.data.course && (
                <FormattedMessage
                  id="session.spaces.available"
                  defaultMessage="Spaces available: {availableSpaces} / {capacity}"
                  description="Spaces on course session that are available"
                  values={{
                    availableSpaces:
                      sessionQuery.data.course.totalNumberOfPeople,
                    capacity: sessionQuery.data.course.totalNumberOfPeople
                  }}
                />
              )
            )}
          </span>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <div className="btn-group gap-2">
              <Link
                type="button"
                className={`btn btn-primary ${
                  availableSpaces <= 0 &&
                  !sessionMembersQuery.error &&
                  'disabled'
                }`}
                to={appStrings[langVal][AppRoute.AddLearnerToCourseSession]}
              >
                <FormattedMessage
                  id="learner.course.session.add"
                  defaultMessage="Add learner"
                  description="Add learner to course"
                />
              </Link>
              <Link
                type="button"
                className={
                  availableSpaces <= 0 && !sessionMembersQuery.error
                    ? 'btn btn-primary disabled'
                    : 'btn btn-primary'
                }
                to={appStrings[langVal][AppRoute.AddGroupToCourseSession]}
              >
                <FormattedMessage
                  id="group.course.session.add"
                  defaultMessage="Add group"
                  description="Add group to course session"
                />
              </Link>
            </div>
          </div>
          {/* )} */}
          {sessionMembersQuery.isFetching ? (
            <Loading />
          ) : sessionMembersQuery.data ? (
            <LearnersTable
              learners={sessionMembersQuery.data}
              actions={sessionMembersQuery.data.map(
                (learner: components['schemas']['UserDto']) => {
                  return (
                    <div className="btn-group-vertical">
                      <Link
                        to={`/${displayLocale}/${localiseRoutePath(
                          AppRoute.Learners
                        )}/${learner.userId}`}
                        className="btn btn-outline-secondary"
                      >
                        <FormattedMessage
                          id="view"
                          defaultMessage="View"
                          description="View"
                        />
                      </Link>
                      <RemoveLearnerFromSessionBtn
                        courseId={Number(courseId)}
                        sessionId={Number(sessionId)}
                        displayLocale={displayLocale}
                        learner={learner}
                      />
                    </div>
                  );
                }
              )}
            />
          ) : (
            <div>
              <FormattedMessage
                id="learners.course.session.none.added"
                defaultMessage="No learners have been added to this course session"
                description="No learners have been added to this course session"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminSession;
