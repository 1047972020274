import { AppLanguage } from '../const';

const providerKeys = {
  // everything to do with providers
  all: (displayLocale: string | undefined) =>
    ['providers', displayLocale || AppLanguage.English] as const,
  provider: (providerId: number, displayLocale: string | undefined) =>
    [
      ...providerKeys.all(displayLocale || AppLanguage.English),
      providerId
    ] as const,
  // list of all providers
  list: (
    displayLocale: string | undefined,
    pageIndex: number | null | undefined,
    pageSize: number | null | undefined,
    sorting: any | null | undefined,
    filtering: any
  ) =>
    [
      ...providerKeys.all(displayLocale || AppLanguage.English),
      pageIndex,
      pageSize,
      sorting,
      filtering,
      'list'
    ] as const,
  providerConfig: (providerId: number | null | undefined) =>
    ['provider-config', providerId] as const,
  adminsForProvider: (
    providerId: number,
    displayLocale: string | undefined
  ) => [
    ...providerKeys.all(displayLocale || AppLanguage.English),
    providerId,
    'admins'
  ]
};

export default providerKeys;
