import React, { useContext } from 'react';
import {
  faCheck,
  faExclamation,
  faRotate
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';

import { UserType } from '../../const/user-type';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import useSessionsBetweenDates from '../../hooks/useSessionsBetweenDates';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import useDataSyncInfoStore from '../../hooks/state-management/useDataSyncInfoStore';

function SyncDataBtn({
  instructorId,
  isSyncing
}: {
  instructorId: number;
  isSyncing: boolean;
}) {
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const isNetworkOnline = useNetworkStatus();
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  const pendingMutations = mutationCache.getAll();

  const setLastUploaded = useDataSyncInfoStore.use.setLastUploaded();

  const { instructorSessionsTodayQuery } = useSessionsBetweenDates({
    userId: instructorId,
    userType: UserType.INSTRUCTOR,
    displayLocale
  });

  const refreshData = () => {
    if (
      pendingMutations.length > 0 &&
      pendingMutations.some((mutation) => mutation.state.status === 'loading')
    ) {
      const promises = pendingMutations
        .filter((mutation) => mutation.state.status === 'loading')
        .map((mutation) =>
          mutation.execute().then(() => {
            // eslint-disable-next-line no-console
            console.log('Data sync success');
            const now = new Date();
            setLastUploaded(now.getTime());
          })
        );
      Promise.all(promises).then(() => {
        // eslint-disable-next-line no-console
        console.log('All mutations complete');
        const now = new Date();
        setLastUploaded(now.getTime());
      });
    }
  };

  const isMutationPending =
    pendingMutations &&
    pendingMutations.length > 0 &&
    pendingMutations.some((mutation) => mutation.state.status === 'loading');

  return (
    <>
      {isMutationPending && appMode === AppMode.ONLINE && (
        <section className="alert alert-warning my-2 w-100 mb-2">
          <FontAwesomeIcon icon={faExclamation} className="me-1" />
          <FormattedMessage
            id="mutations.unsynced"
            defaultMessage="You have unsynced changes. Sync data to save your changes"
          />
        </section>
      )}
      {instructorSessionsTodayQuery.data && (
        <div className="d-flex flex-column">
          <div className="text-center">
            <span className="fa-stack fa-2x position-relative">
              <FontAwesomeIcon
                icon={faRotate}
                className={`fa-stack-2x ${
                  appMode === AppMode.OFFLINE ? 'text-warning' : 'text-success'
                }`}
              />
              {appMode === AppMode.OFFLINE ? (
                <FontAwesomeIcon
                  icon={faExclamation}
                  className="fa-stack-1x text-warning sync-info__small-icon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="fa-stack-1x text-success sync-info__small-icon"
                />
              )}
            </span>
          </div>
          <div>
            {' '}
            <button
              type="button"
              className="btn btn-lg btn-warning fw-bold"
              disabled={
                appMode === AppMode.OFFLINE || !isNetworkOnline || isSyncing
              }
              onClick={() => refreshData()}
            >
              <FormattedMessage
                id="offline.sync-data"
                defaultMessage="Sync data"
                description="Sync data"
              />
            </button>
            {!isNetworkOnline && (
              <FormattedMessage
                id="offline.sync-data.not_available_offline"
                defaultMessage="Sync not available. No network detected."
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SyncDataBtn;
