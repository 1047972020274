import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/CourseService';
import CourseStagesList from './CourseStagesList';

function CourseDetailsTableShort({
  course,
  courseStages,
  showCourseName,
  showCentreName
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  courseStages?:
    | components['schemas']['CourseStageDto'][]
    | components['schemas']['CourseStageSlimDto'][]
    | null;
  showCourseName?: boolean;
  showCentreName?: boolean;
}) {
  return (
    <table className="table table-sm">
      <tbody>
        {showCourseName && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="course"
                defaultMessage="Course"
                description="Course"
              />
            </th>
            <td>{course.name}</td>
          </tr>
        )}

        <tr>
          <th scope="row">
            <FormattedMessage
              id="framework.table.header"
              defaultMessage="Framework"
              description="Framework"
            />
          </th>
          <td>{course.frameworkVersion?.framework?.name}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="stage.plural_in_brackets"
              defaultMessage="Stage(s)"
              description="Stage(s)"
            />
          </th>
          <td>
            {courseStages ? (
              <CourseStagesList courseStages={courseStages} />
            ) : (
              <span>
                <FormattedMessage
                  id="stages.none"
                  defaultMessage="No stages found for this course"
                  description="No stages found for this course"
                />
              </span>
            )}
          </td>
        </tr>
        {showCentreName && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="centre"
                defaultMessage="Centre"
                description="Centre"
              />
            </th>
            <td>{course.centre?.name}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default CourseDetailsTableShort;

CourseDetailsTableShort.defaultProps = {
  courseStages: [],
  showCourseName: false,
  showCentreName: true
};
