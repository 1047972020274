import React from 'react';

function CardHeader({
  children,
  button,
  badgeContent
}: {
  children: React.ReactNode;
  button?: React.ReactNode | null | undefined;
  badgeContent?: any | null | undefined;
}) {
  return (
    <div className="card-header d-flex">
      <div className="flex-grow-1 d-flex">
        {children}
        {badgeContent !== undefined && (
          <div className="px-1">
            <span className="badge bg-secondary">{badgeContent}</span>
          </div>
        )}
      </div>
      <div>{button}</div>
    </div>
  );
}

export default CardHeader;

CardHeader.defaultProps = {
  button: null,
  badgeContent: ''
};
