import { isCentre } from '../../../utils/type-guards';
import { SPCalendarEvent } from '../SPCalendarEvent.type';

const uniqueCentres = (allSessions: SPCalendarEvent[]) => {
  return allSessions
    .map((event) => event.session.centre)
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t?.centreId === value?.centreId)
    )
    .filter(isCentre);
};

export default uniqueCentres;
