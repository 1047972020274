import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError, Loading } from '../common';
import StageOptionsWrapper from './StageOptionsWrapper';
import StageObjectives from './StageObjectives';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import { components } from '../../types/openapi/FrameworkService';
import { components as courseComponents } from '../../types/openapi/CourseService';

const isCourseStageSlimDto = (
  value:
    | courseComponents['schemas']['CourseStageDto']
    | courseComponents['schemas']['CourseStageSlimDto']
): value is courseComponents['schemas']['CourseStageSlimDto'] => {
  return (
    (value as courseComponents['schemas']['CourseStageSlimDto']).stage
      ?.objectives !== undefined
  );
};

function CourseStageObjectivesWrapper({
  courseId,
  learnerId,
  isEditable,
  stages,
  currentFrameworkStage,
  currentFrameworkStageError,
  course,
  courseStages
}: {
  courseId: number;
  learnerId: number;
  isEditable: boolean;
  stages:
    | components['schemas']['StageDto'][]
    | courseComponents['schemas']['StageSlimDto'][]
    | undefined
    | null;
  currentFrameworkStage:
    | components['schemas']['StageDto']
    | courseComponents['schemas']['StageSlimDto']
    | undefined
    | null;
  currentFrameworkStageError: any;
  course:
    | components['schemas']['CourseDto']
    | courseComponents['schemas']['CourseSlimDto'];
  courseStages:
    | courseComponents['schemas']['CourseStageSlimDto'][]
    | courseComponents['schemas']['CourseStageDto'][]
    | undefined
    | null;
}) {
  const profileContext = useContext(ProfileContext);

  const { profile } = profileContext;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const [currentStageId, setCurrentStageId] = useState<number>();

  useEffect(() => {
    if (courseStages && currentFrameworkStage) {
      if (
        (isCourseStageSlimDto(courseStages[0]) &&
          (
            courseStages as courseComponents['schemas']['CourseStageSlimDto'][]
          ).find(
            (courseStage) =>
              courseStage.stageId === currentFrameworkStage.stageId
          )) ||
        (!isCourseStageSlimDto(courseStages[0]) &&
          (
            courseStages as courseComponents['schemas']['CourseStageDto'][]
          ).find(
            (courseStage) =>
              courseStage.stageId === currentFrameworkStage.stageId
          ))
      ) {
        setCurrentStageId(currentFrameworkStage.stageId);
      } else {
        setCurrentStageId(courseStages[0].stageId);
      }
    }
    if (currentFrameworkStageError && courseStages) {
      setCurrentStageId(courseStages[0].stageId);
    }
  }, [course, currentFrameworkStage]);

  const handleStageChange = (value: number) => {
    setCurrentStageId(value);
  };

  if (!course) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="course.not_loaded"
            defaultMessage="Could not load course"
          />
        }
      />
    );
  }
  if (course && course?.courseStages) {
    return (
      <div className="card border-0 rounded-top-right-lg mb-2 mt-2">
        <div className="card-header rounded-top-right-lg">
          <h2>
            <FormattedMessage
              id="objectives"
              defaultMessage="Objectives"
              description="Objectives"
            />
          </h2>
        </div>
        <div className="card-body">
          {appMode === AppMode.OFFLINE && (
            <p>
              <FormattedMessage
                id="offline.objectives.not-available-uneditable"
                defaultMessage="In offline mode, only learner's current stage data is available and you cannot update stars from this page."
                description="In offline mode, only learner's current stage data is available and you cannot update stars from this page."
              />
            </p>
          )}

          {!!courseId &&
            !!learnerId &&
            stages &&
            currentStageId &&
            course.frameworkVersionId &&
            appMode !== AppMode.OFFLINE && (
              <StageOptionsWrapper
                frameworkVersionId={course.frameworkVersionId}
                stages={stages}
                currentStageId={currentStageId}
                onChange={handleStageChange}
              />
            )}

          {!!courseId &&
            !!profile?.userId &&
            currentStageId &&
            course.frameworkVersionId && (
              <StageObjectives
                frameworkVersionId={course.frameworkVersionId}
                stageId={currentStageId}
                userId={learnerId || profile?.userId}
                isEditable={
                  isEditable &&
                  !!currentFrameworkStage &&
                  !!currentFrameworkStage.stageId
                }
              />
            )}
        </div>
      </div>
    );
  }
  return <Loading />;
}
export default CourseStageObjectivesWrapper;
