import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppLanguage, AppRoute } from '../../const';
import { UserType } from '../../const/user-type';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';

function ProviderListActions({ providerId }: { providerId: any }) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  return (
    <div className="btn-group-vertical">
      <Link
        to={`/${displayLocale?.toLowerCase()}/${
          appStrings[displayLocale as AppLanguage][AppRoute.Providers]
        }/${providerId}`}
        className="btn btn-outline-secondary"
      >
        <FormattedMessage id="view" defaultMessage="View" description="View" />
      </Link>
      {profile?.userTypeId === UserType.SUPER_ADMIN && (
        <Link
          to={`/${displayLocale?.toLowerCase()}/${
            appStrings[displayLocale as AppLanguage][AppRoute.UserImpersonation]
          }/${providerId}`}
          className="btn btn-outline-secondary"
        >
          <FormattedMessage
            id="user.provider.impersonate"
            defaultMessage="Impersonate user from this provider"
          />
        </Link>
      )}
    </div>
  );
}

export default ProviderListActions;
