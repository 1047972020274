import React, { useContext, useState } from 'react';
import { useCookieConsentContext } from '@use-cookie-consent/react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { appStrings } from '../../modules/i18n';
import { AppLanguage, AppRoute } from '../../const';
import useCookies from '../../hooks/cookies/useCookies';

function CookieBanner() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { pathname } = useLocation();

  const [showConfirmationBanner, setShowConfirmationBanner] =
    useState<boolean>(false);
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);
  const [cookiesRejected, setCookiesRejected] = useState<boolean>(false);

  const { consent, acceptAllCookies, declineAllCookies } =
    useCookieConsentContext();

  const {
    removeGACookies,
    acceptGACookies,
    acceptClarityCookies,
    removeClarityCookies
  } = useCookies();

  const acceptAll = () => {
    acceptAllCookies();
    acceptGACookies();
    acceptClarityCookies();
    setShowConfirmationBanner(true);
    setCookiesAccepted(true);
    ReactGA.initialize(`G-${process.env.REACT_APP_GA_MEASUREMENT_ID}`);
  };

  const rejectAll = () => {
    declineAllCookies();
    removeGACookies();
    removeClarityCookies();
    setShowConfirmationBanner(true);
    setCookiesRejected(true);
  };

  const hideConfirmationBanner = () => {
    setShowConfirmationBanner(false);
  };

  const isCookiePreferenceUndefined = consent.statistics === undefined;

  if (
    (isCookiePreferenceUndefined || showConfirmationBanner) &&
    pathname !==
      `/${displayLocale}/${
        appStrings[displayLocale as AppLanguage][AppRoute.CookiePolicy]
      }`
  )
    return (
      <div
        role="region"
        aria-label="Cookies on Sport Passport"
        className="alert alert-info cookie-banner"
      >
        <div hidden={!isCookiePreferenceUndefined}>
          <h2>
            <FormattedMessage
              id="cookieBanner.heading"
              defaultMessage="Cookies on Sport Passport"
              description="Cookies on Sport Passport"
            />
          </h2>
          <p>
            <FormattedMessage
              id="cookieBanner.essential"
              defaultMessage="We use some essential cookies to make this service work."
              description="We use some essential cookies to make this service work."
            />
          </p>
          <p>
            <FormattedMessage
              id="cookieBanner.additional"
              defaultMessage="We'd like to set additional cookies so we can understand how people
          use the service and make improvements."
              description="We'd like to set additional cookies so we can understand how people
          use the service and make improvements."
            />
          </p>
          {isCookiePreferenceUndefined && (
            <div className="btn-group gap-3">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={acceptAll}
              >
                <FormattedMessage
                  id="cookieBanner.accept"
                  defaultMessage="Accept additional cookies"
                  description="Accept additional cookies"
                />
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={rejectAll}
              >
                <FormattedMessage
                  id="cookieBanner.reject"
                  defaultMessage="Reject additional cookies"
                  description="Reject additional cookies"
                />
              </button>
              <Link
                to={`/${displayLocale}/${
                  appStrings[displayLocale as AppLanguage][
                    AppRoute.CookiePolicy
                  ]
                }`}
                state={{ prevPath: pathname }}
                // className="btn btn-secondary btn-sm"
              >
                <FormattedMessage
                  id="cookieBanner.manage"
                  defaultMessage="Manage preferences"
                  description="Manage preferences"
                />
              </Link>
            </div>
          )}
        </div>
        <div hidden={!showConfirmationBanner} role="alert">
          {cookiesAccepted && (
            <p>
              <FormattedMessage
                id="cookieBanner.confirm.accepted"
                defaultMessage="You've accepted additional cookies. You can {link} at any time."
                description="You've accepted additional cookies. You can {link} at any time."
                values={{
                  link: (
                    <Link
                      to={`/${displayLocale}/${
                        appStrings[displayLocale as AppLanguage][
                          AppRoute.CookiePolicy
                        ]
                      }`}
                      state={{ prevPath: pathname }}
                    >
                      <FormattedMessage
                        id="cookieBanner.confirm.link"
                        defaultMessage="change your settings"
                        description="You've accepted/rejected additional cookies. You can {link} at any time."
                      />
                    </Link>
                  )
                }}
              />
            </p>
          )}
          {cookiesRejected && (
            <p>
              <FormattedMessage
                id="cookieBanner.confirm.rejected"
                defaultMessage="You've rejected additional cookies. You can {link} at any time."
                description="You've rejected additional cookies. You can {link} at any time."
                values={{
                  link: (
                    <Link
                      to={`/${displayLocale}/${
                        appStrings[displayLocale as AppLanguage][
                          AppRoute.CookiePolicy
                        ]
                      }`}
                      state={{ prevPath: pathname }}
                    >
                      <FormattedMessage
                        id="cookieBanner.confirm.link"
                        defaultMessage="change your settings"
                        description="You've accepted/rejected additional cookies. You can {link} at any time."
                      />
                    </Link>
                  )
                }}
              />
            </p>
          )}
          <button
            type="button"
            className="btn btn-secondary btn-sm rounded-0"
            onClick={hideConfirmationBanner}
          >
            <FormattedMessage
              id="cookieBanner.hide"
              defaultMessage="Hide"
              description="Hide"
            />
          </button>
        </div>
      </div>
    );
  return <div />;
}

export default CookieBanner;
