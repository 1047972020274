const generateSportPassportId = (id: number | undefined) => {
  if (id) {
    const base = 'SP000000000';
    const numDigits = id.toString().split('').length;
    const shortenedBase = base
      .split('')
      .slice(0, base.split('').length - numDigits)
      .join('');
    const sportPassportId = `${shortenedBase}${id}`;
    return sportPassportId;
  }
  return '-';
};
export default generateSportPassportId;
