import React from 'react';
import PaymentSuccess from '../components/courses/sessions/payment/PaymentSuccess';

function Payment() {
  return (
    <div>
      <div className="alert alert-light" role="alert">
        {' '}
        <PaymentSuccess />
      </div>
    </div>
  );
}

export default Payment;
