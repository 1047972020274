import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../common';
import { getFrameworkVersionForFramework } from '../../services/api/framework.service';
import FrameworkAndVersionInfoPanels from '../frameworks/FrameworkAndVersionInfoPanels';
import StagesTable from '../frameworks/stages/StagesTable';
import Loading from '../common/Loading';
import frameworkKeys from '../../query-keys/framework-key-factory';

function UserViewFramework() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const { frameworkId, frameworkVersionId } = useParams();
  const { isLoading, error, data } = useQuery({
    queryKey: frameworkKeys.version(
      Number(frameworkId),
      Number(frameworkVersionId),
      displayLocale
    ),
    queryFn: () =>
      getFrameworkVersionForFramework(
        Number(frameworkId),
        Number(frameworkVersionId),
        displayLocale
      ),
    refetchOnMount: true
  });
  if (isLoading) return <Loading />;
  if (error) return <GeneralError />;
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.framework.name',
              defaultMessage: 'Framework: {name}'
            },
            {
              name: data?.name
            }
          )}
        </title>
      </Helmet>
      <div className="card border-0 mb-3">
        <div className="card-header">
          <h2>
            {' '}
            <FormattedMessage
              id="framework.name_title"
              defaultMessage="Framework: {name}"
              description="Framework: name"
              values={{
                name: data?.name
              }}
            />
          </h2>
        </div>
        <div className="card-body">
          {data && (
            <FrameworkAndVersionInfoPanels
              frameworkId={Number(frameworkId)}
              frameworkVersionId={Number(frameworkVersionId)}
            />
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h2>
            {' '}
            <FormattedMessage
              id="stages"
              defaultMessage="Stages"
              description="Stages"
            />
          </h2>
        </div>
        <div className="card-body">
          <StagesTable
            framework={data?.framework!}
            version={data!}
            showEditAndDelete={false}
          />
        </div>
      </div>
    </>
  );
}
export default UserViewFramework;
