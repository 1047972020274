import React, { useContext } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { components } from '../../../types/openapi/CourseService';
import isAllowed from '../../../utils/permissions/isAllowed';
import { UserType } from '../../../const/user-type';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { TIMEZONE, convertDateTime } from '../../../utils/date-time';

function CourseSessionDetailsTable({
  course,
  session
}: {
  session: components['schemas']['CourseSessionDto'];
  course?: components['schemas']['CourseDto'] | undefined;
}) {
  const { sessionStartTime, sessionEndTime } = session;
  const displayStartTime = convertDateTime(sessionStartTime);
  const startTimeAsDate = displayStartTime.toDate();
  const displayEndTime = convertDateTime(sessionEndTime);
  const endTimeAsDate = displayEndTime.toDate();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  return (
    <table className="table table-sm">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="date"
              defaultMessage="Date"
              description="Date"
            />
          </th>
          <td>
            <FormattedDate value={sessionStartTime} timeZone={TIMEZONE} />
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="session.time"
              defaultMessage="Time"
              description="Time"
            />
          </th>
          <td>
            <FormattedTime value={startTimeAsDate} timeZone={TIMEZONE} />
            &mdash;
            <FormattedTime value={endTimeAsDate} timeZone={TIMEZONE} />
          </td>
        </tr>
        {!isAllowed([UserType.INSTRUCTOR], profile?.userTypeId as UserType) && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="instructor"
                defaultMessage="Instructor"
                description="Instructor"
              />
            </th>
            <td data-gdpr="true">{session.instructor?.fullname}</td>
          </tr>
        )}
        <tr>
          <th scope="row">
            <FormattedMessage
              id="centre"
              defaultMessage="Centre"
              description="Centre"
            />
          </th>
          <td>{session.centre?.name}</td>
        </tr>

        {isAllowed([UserType.INSTRUCTOR], profile?.userTypeId as UserType) && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="people_booked"
                defaultMessage="People Booked"
                description="People Booked"
              />
            </th>
            <td>{session?.currentLearnersBooked}</td>
          </tr>
        )}

        {session.facility && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="facility"
                defaultMessage="Facility"
                description="Facility"
              />
            </th>
            <td>{session.facility?.name}</td>
          </tr>
        )}

        <tr>
          <th scope="row">
            <FormattedMessage
              id="school"
              defaultMessage="School"
              description="School"
            />
          </th>
          <td>
            {course?.school ? (
              `${course.school?.name} - ${course.school?.address?.postalCode} `
            ) : (
              <span className="text-muted">
                <FormattedMessage
                  id="school.none"
                  defaultMessage="No school assigned"
                  description="No school assigned to course"
                />
              </span>
            )}
          </td>
        </tr>

        {isAllowed(
          [UserType.ADMIN, UserType.SUPER_ADMIN],
          profile?.userTypeId as UserType
        ) && (
          <>
            <tr>
              <th scope="row">
                <FormattedMessage
                  id="capacity"
                  defaultMessage="Capacity"
                  description="Capacity"
                />
              </th>
              <td>{session.course?.totalNumberOfPeople}</td>
            </tr>
            <tr>
              <th scope="row">
                <FormattedMessage
                  id="people_booked"
                  defaultMessage="People Booked"
                  description="People Booked"
                />
              </th>
              <td>{session?.currentLearnersBooked}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
}

export default CourseSessionDetailsTable;

CourseSessionDetailsTable.defaultProps = {
  course: undefined
};
