import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import CreateCentre from '../components/centres/CreateCentre';
import EditCentre from '../components/centres/EditCentre';
import CreateFacility from '../components/centres/facilities/CreateFacility';
import EditFacility from '../components/centres/facilities/EditFacility';
import ManageCentreTranslation from '../components/centres/translation/ManageCentreTranslation';
import ViewCentre from '../components/centres/ViewCentre';
import AssignInstructorToCentre from '../components/instructors-admin/AssignInstructorToCentre';
import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { UserType } from '../const/user-type';
import ManageCentres from '../components/centres/ManageCentres';
import CreateCentreAdmin from '../components/centres/admins/CreateCenterAdmin';

export default function centreRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Centres],
    element: (
      <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="centres.breadcrumb"
          defaultMessage="Centres"
          description="Centres"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageCentres />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.CreateCentre],
        element: <CreateCentre />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="centre.create"
              defaultMessage="Create Centre"
              description="Create Centre"
            />
          )
        }
      },
      {
        path: ':centreId',
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="centre"
              defaultMessage="Centre"
              description="Centre"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ViewCentre />,
            errorElement: <ErrorPage />
          },
          {
            path: appStrings[langVal][AppRoute.EditCentre],
            element: <EditCentre />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="centre.edit"
                  defaultMessage="Edit Centre"
                  description="Edit Centre"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.CreateCentreAdmin]}`,
            element: <CreateCentreAdmin />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="breadcrumb.admin.create"
                  defaultMessage="Create Admin"
                  description="Create Admin"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.ManageTranslation],
            element: <ManageCentreTranslation />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="manage_translation"
                  defaultMessage="Manage Translation"
                  description="Manage Translation"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.AssignInstructorToCentre],
            element: <AssignInstructorToCentre />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="centre.assign.breadcrumb"
                  defaultMessage="Assign Instructor to Centre"
                  description="Assign Instructor to Centre"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.CreateFacility],
            element: <CreateFacility />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="facility.create"
                  defaultMessage="Create Facility"
                  description="Create Facility"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.EditFacility]}/:facilityId`,
            element: <EditFacility />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="facility.edit"
                  defaultMessage="Edit Facility"
                  description="Edit Facility"
                />
              )
            }
          }
        ]
      }
    ]
  };
  return routes;
}
