/* eslint-disable @typescript-eslint/no-shadow */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
/* eslint-disable no-param-reassign */

import { useContext, useReducer } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CourseUserStars } from '../types/CourseUserStars.type';
import userObjectivesKeys from '../query-keys/user-objectives-key-factory';
import sessionKeys from '../query-keys/session-key-factory';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import { components } from '../types/openapi/CourseService';

export type ObjectiveStar = {
  learnerId: number;
  stageId: number;
  objectiveId: number;
  numberOfStars: number;
};

export type ObjectiveAction =
  | ({
      type: 'add_objective_star';
    } & { objectiveStar: ObjectiveStar })
  | { type: 'clear_objective_stars' };

const objectiveReducer = (state: ObjectiveStar[], action: ObjectiveAction) => {
  switch (action.type) {
    case 'add_objective_star': {
      const { objectiveStar } = action;
      const copy = [...state, objectiveStar];
      return copy;
    }
    case 'clear_objective_stars': {
      return [];
    }
    default:
      return state;
  }
};

// hook for managing user session objectives
const useUserSessionObjectiveStars = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  frameworkVersionId,
  courseId,
  sessionId
}: {
  frameworkVersionId: number | undefined | null;
  courseId: number | undefined;
  sessionId: number | undefined;
}) => {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [state, dispatch] = useReducer(objectiveReducer, []);

  if (!courseId || !sessionId) {
    throw Error('invalid id');
  }

  const queryClient = useQueryClient();

  const updateUserSessionObjectiveStars = useMutation({
    mutationKey: userObjectivesKeys.sessionStars(sessionId),
    retry: 3,
    // these arguments need to match the default mutation in App.tsx
    onMutate: async ({
      courseId,
      sessionId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      objectiveStars
    }: {
      courseId: number;
      sessionId: number;
      objectiveStars: ObjectiveStar[];
    }) => {
      // cancel any existing queries for stars related to this session
      // cancel fetching if in progress
      // we are about to update it so dont need new data coming in that will be out of date
      await queryClient.cancelQueries({
        queryKey: userObjectivesKeys.starsForCourseSessionUsers(
          courseId,
          sessionId
        )
      });

      const previousOnlineData = queryClient.getQueryData(
        userObjectivesKeys.starsForCourseSessionUsers(
          Number(courseId),
          Number(sessionId)
        )
      ) as CourseUserStars[];

      const previousOfflineData = queryClient.getQueryData<
        components['schemas']['OfflineDownloadDto']
      >(sessionKeys.offlineData(sessionId, displayLocale));

      dispatch({
        type: 'clear_objective_stars'
      });

      return { previousOnlineData, previousOfflineData };
    },
    onError: (_, __, context) => {
      // if something goes wrong then reset to the previous data
      console.error(context?.previousOnlineData);

      if (context?.previousOnlineData) {
        queryClient.setQueryData(
          userObjectivesKeys.starsForCourseSessionUsers(
            Number(courseId),
            Number(sessionId)
          ),
          [...context.previousOnlineData]
        );
      }
      if (context?.previousOfflineData) {
        queryClient.setQueryData(
          sessionKeys.offlineData(sessionId, displayLocale),
          context.previousOfflineData
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'user-objectives'
      });
    }
  });

  return {
    updateUserSessionObjectiveStars,
    dispatch,
    objectiveStars: state !== undefined ? state : []
  };
};

export default useUserSessionObjectiveStars;
