import { useQuery } from '@tanstack/react-query';
import { getUserPermissions } from '../../services/api/user.service';
import userKeys from '../../query-keys/user-key-factory';
import filterPermissions from '../../utils/filterPermissions';

const useUserPermissions = ({
  userId,
  displayLocale
}: {
  userId: number | undefined;
  displayLocale: string | undefined;
}) => {
  if (!userId) {
    throw Error('invalid user id');
  }

  const permissionsQuery = useQuery({
    queryKey: userKeys.permissions(userId, displayLocale),
    queryFn: () => getUserPermissions(userId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!userId
  });

  const permittedCentreIds: number[] = permissionsQuery.data
    ? permissionsQuery.data
        .map((permission) => permission.centreId)
        .filter((value: number | null | undefined): value is number => {
          return !!value;
        })
    : [];

  const filteredPermissions = filterPermissions(permissionsQuery.data);
  const filteredRoles =
    filteredPermissions &&
    filteredPermissions.filter(
      (role) => role.permissions && role.permissions.length > 0
    );

  return {
    permissionsQuery,
    permittedCentreIds,
    filteredRoles
  };
};

export default useUserPermissions;
