/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../common';
import { deleteCourse } from '../../services/api/course.service';

import Loading from '../common/Loading';
import CourseDetailsTable from './CourseDetailsTable';
import { components } from '../../types/openapi/CourseService';
import localiseRoutePath from '../../utils/localiseRoutePath';
import { AppLanguage, AppRoute } from '../../const';
import { UserType } from '../../const/user-type';
import { ModalContext } from '../../modules/modal/ModalProvider';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import isAllowed from '../../utils/permissions/isAllowed';
import { appStrings } from '../../modules/i18n';
import LearnersOnCourse from './LearnersOnCourse';
import InstructorOnCourse from './InstructorOnCourse';
import useCourse from '../../hooks/course/useCourse';
import SessionsScheduling from './scheduling/SessionsScheduling';

function ViewCourse() {
  const { courseId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const intl = useIntl();
  const queryClient = useQueryClient();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langVal = displayLocale as AppLanguage;

  const navLinkCourses = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Courses]
  }`;
  const navigate = useNavigate();

  const {
    courseQuery,
    learnersForCourseQuery: courseLearners,
    courseStagesQuery
  } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  if (courseQuery.isFetching) {
    <Loading />;
  }
  if (courseQuery.error) {
    <GeneralError error={courseQuery.error} />;
  }
  if (!courseQuery.data) {
    <GeneralError message="Course has no data" />;
  }
  const { mutate: deleteProviderCourse } = useMutation(
    (deletedCourseId: number) => deleteCourse(deletedCourseId),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'course.delete.success',
            defaultMessage: 'Course has been removed'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({ queryKey: ['courses'] });
        navigate(navLinkCourses);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'course.delete.error',
            defaultMessage: 'There was an error removing this course'
          }),
          { delay: 200 }
        );
      }
    }
  );

  async function onModalConfirm(course: components['schemas']['CourseDto']) {
    deleteProviderCourse(Number(course.courseId));
  }
  function openDeleteCourseModal(course: components['schemas']['CourseDto']) {
    const header = intl.formatMessage(
      {
        id: 'course.remove.confirm.header',
        defaultMessage: 'Remove Course: {name}',
        description: 'Remove course Confirm Modal Header'
      },
      { name: course.name }
    );
    const modalContent = intl.formatMessage({
      id: 'course.remove.confirm.body',
      defaultMessage:
        'Are you sure you want to remove this course? This action cannot be undone',
      description: 'Remove course Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'course.remove.confirm.button',
      defaultMessage: 'Remove Course',
      description: 'Remove Course Confirm Button'
    });
    modal(modalContent, {
      confirm: () => onModalConfirm(course),
      header,
      confirmText
    });
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.course.colon.name',
              defaultMessage: 'Course: {name}'
            },
            { name: courseQuery.data?.name }
          )}
        </title>
      </Helmet>

      <div className="card border-0 rounded-top-right-lg mb-2">
        <div className="card-header d-flex">
          <div className="flex-grow-1">
            {' '}
            <h1>
              <FormattedMessage
                id="course.colon.name"
                defaultMessage="Course: {name}"
                description="Course: {name}"
                values={{ name: courseQuery.data?.name }}
              />
            </h1>
          </div>
          <div>
            {/* Delete course if no learners assigned to course */}
            {isAllowed([UserType.ADMIN], profile?.userTypeId as UserType) &&
              courseQuery.data && (
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!courseLearners.error}
                  onClick={() => openDeleteCourseModal(courseQuery.data)}
                >
                  <FormattedMessage
                    id="course.delete"
                    defaultMessage="Delete course"
                    description="Delete course"
                  />
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="card border-0 rounded-top-right-lg mb-2">
        <div className="card-header d-flex">
          <div className="flex-grow-1">
            {' '}
            <h2>
              <FormattedMessage
                id="details"
                defaultMessage="Details"
                description="Details"
              />
            </h2>
          </div>
          <div>
            {isAllowed([UserType.ADMIN], profile?.userTypeId as UserType) && (
              <Link
                to={localiseRoutePath(AppRoute.EditCourse)}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="course.details.edit"
                  defaultMessage="Edit course details"
                  description="Edit course details"
                />
              </Link>
            )}
          </div>
        </div>
        <div className="card-body">
          {courseQuery.isFetching ? (
            <Loading />
          ) : courseQuery.error ? (
            <GeneralError />
          ) : (
            courseQuery.data && (
              <CourseDetailsTable
                course={courseQuery.data}
                courseStages={courseStagesQuery.data}
              />
            )
          )}
        </div>
      </div>
      {courseQuery.data && <SessionsScheduling course={courseQuery.data} />}

      {isAllowed([UserType.ADMIN], profile?.userTypeId as UserType) && (
        <InstructorOnCourse />
      )}
      {isAllowed(
        [UserType.SUPER_ADMIN, UserType.ADMIN, UserType.INSTRUCTOR],
        profile?.userTypeId as UserType
      ) && <LearnersOnCourse />}
    </>
  );
}

export default ViewCourse;
