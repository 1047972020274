import { AppLanguage } from '../const';

const progressKeys = {
  // everything to do with progress
  all: () => ['progress'] as const,
  currentCourseStage: (
    courseId: number | undefined,
    learnerId: number | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...progressKeys.all(),
      courseId,
      learnerId,
      'currentStage',
      displayLocale || AppLanguage.English
    ] as const,
  currentFrameworkStage: (
    frameworkVersionId: number | undefined,
    learnerId: number | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...progressKeys.all(),
      frameworkVersionId,
      learnerId,
      'currentStage',
      displayLocale || AppLanguage.English
    ] as const,
  progressCourseMutation: (courseId: number, learnerId: number) =>
    [...progressKeys.all(), courseId, learnerId] as const,
  progressFrameworkMutation: (
    frameworkVersionId: number | undefined,
    learnerId: number | undefined
  ) => [...progressKeys.all(), frameworkVersionId, learnerId] as const,
  undoProgressFrameworkMutation: (
    frameworkVersionId: number | undefined,
    learnerId: number | undefined
  ) => [...progressKeys.all(), frameworkVersionId, learnerId, 'undo'] as const
};

export default progressKeys;
