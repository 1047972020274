import React, { useContext, useEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { AppLanguage, AppRoute } from '../../const';
import { UserType } from '../../const/user-type';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import {
  getCoursesForLearner,
  getCoursesForProviderInstructor
} from '../../services/api/course.service';
import { components as courseComponents } from '../../types/openapi/CourseService';
import { components } from '../../types/openapi/ResourceService';
import Loading from '../common/Loading';

function UserFrameworkList() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();
  const [frameworkVersions, setFrameworkVersions] = useState<
    components['schemas']['FrameworkVersionDto'][] | null
  >();
  const [coursesQuery] = useQueries({
    queries: [
      {
        queryKey: [
          'courses',
          profile?.providerId,
          profile?.userId,
          displayLocale
        ],
        queryFn: () => {
          if (profile?.userTypeId === UserType.INSTRUCTOR) {
            return getCoursesForProviderInstructor(
              profile?.providerId,
              profile?.userId,
              null,
              null,
              null,
              null,
              null,
              displayLocale
            );
          }
          if (profile?.userTypeId === UserType.LEARNER) {
            return getCoursesForLearner(
              profile?.userId,
              null,
              null,
              null,
              null,
              null,
              displayLocale
            );
          }
          throw Error('not a valid user type');
        },
        refetchOnMount: true
      }
    ]
  });

  useEffect(() => {
    if (coursesQuery.data && coursesQuery.data.courses) {
      const coursesFrameworkVersions: courseComponents['schemas']['FrameworkVersionDto'][] =
        coursesQuery.data.courses.map(
          (course: courseComponents['schemas']['CourseDto']) => {
            return course.frameworkVersion!;
          }
        );

      const uniqueFrameworkVersions = [
        ...new Set(
          coursesFrameworkVersions.map(
            (
              coursesFrameworkVersion: courseComponents['schemas']['FrameworkVersionDto']
            ) => coursesFrameworkVersion.frameworkVersionId
          )
        )
      ].map((id) => {
        return coursesQuery.data.courses?.find(
          (course: courseComponents['schemas']['CourseDto']) =>
            course.frameworkVersionId === id
        )!.frameworkVersion!;
      });
      setFrameworkVersions(uniqueFrameworkVersions);
    }
  }, [coursesQuery.data]);

  if (coursesQuery.isFetching) return <Loading />;

  if (coursesQuery.error && (coursesQuery.error as any).response.status !== 404)
    return <GeneralError />;

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.resources.view',
            defaultMessage: 'Resources'
          })}
        </title>
      </Helmet>
      <div className="card">
        <div className="card-header">
          <h1>
            <FormattedMessage
              id="resources.header"
              defaultMessage="Resources"
              description="Resources"
            />
          </h1>
        </div>
        <div className="card-body">
          <table className="table card-list-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="framework.table.header"
                    defaultMessage="Framework"
                    description="Framework"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="actions.table.header"
                    defaultMessage="Actions"
                    description="Actions"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {frameworkVersions ? (
                frameworkVersions.map(
                  (
                    frameworkVersion: courseComponents['schemas']['FrameworkVersionDto']
                  ) => {
                    return (
                      <tr key={frameworkVersion?.frameworkVersionId}>
                        <td data-title="Name">
                          {`${frameworkVersion?.framework?.name} - ${frameworkVersion.name}`}
                        </td>
                        <td>
                          <Link
                            to={`${frameworkVersion?.frameworkId}/${
                              appStrings[displayLocale as AppLanguage][
                                AppRoute.Versions
                              ]
                            }/${frameworkVersion?.frameworkVersionId}`}
                            className="btn btn-outline-secondary"
                          >
                            <FormattedMessage
                              id="view"
                              defaultMessage="View"
                              description="View"
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <FormattedMessage
                  id="frameworks.none.found"
                  defaultMessage="No frameworks found"
                />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UserFrameworkList;
