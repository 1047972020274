import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from '../../modules/auth/AuthProvider';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { impersonateUser } from '../../services/api/auth.service';

function ImpersonateUserBtn({ userId }: { userId: number }) {
  const authContext = useContext(AuthContext);
  useState<boolean>(false);
  const { handleSetTokenResponse } = authContext;
  const profileContext = useContext(ProfileContext);
  const { clearProfile } = profileContext;

  const getUserDetails = () => {
    impersonateUser(userId).then((data) => {
      clearProfile();
      handleSetTokenResponse(data);
    });
  };

  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={getUserDetails}
    >
      <FormattedMessage
        id="user.impersonate"
        defaultMessage="Impersonate user"
        description="Impersonate user"
      />
    </button>
  );
}

export default ImpersonateUserBtn;
