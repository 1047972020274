/* eslint-disable no-inner-declarations */
import React, { useContext, useEffect, useState } from 'react';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useLearners from '../../hooks/useLearners';
import LearnersList from './LearnersList';
import LearnersListLink from './LearnerListActions';
import { components } from '../../types/openapi/UserService';
import { FilteringQueryParams } from '../../const/filtering-query-params';

function LearnersListByRole({
  profile
}: {
  profile: components['schemas']['UserProvidersDto'];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const intl = useIntl();

  const queryParamsLocation = FilteringQueryParams.Learners;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(pagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(pagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(sorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(filtering)
    );
  }, [pagination, sorting, filtering]);

  const { learnersListQuery } = useLearners({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.learners.manage',
            defaultMessage: 'Manage learners'
          })}
        </title>
      </Helmet>
      <LearnersList
        data={learnersListQuery.data}
        error={learnersListQuery.error}
        isLoading={learnersListQuery.isLoading}
        refetching={learnersListQuery.isRefetching}
        pagination={pagination}
        sorting={sorting}
        filtering={filtering}
        setPagination={setPagination}
        setSorting={setSorting}
        setFiltering={setFiltering}
        queryParamsLocation={queryParamsLocation}
        renderActions={({ row }) => {
          return <LearnersListLink selectedLearner={row.original} />;
        }}
      />
    </>
  );
}

export default LearnersListByRole;
