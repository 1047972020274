import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';
import { useState, useEffect } from 'react';

type StoredTable = {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  sorting: SortingState;
  filtering: ColumnFiltersState;
};

const useStoreTableFilters = ({
  queryParamsLocation
}: {
  queryParamsLocation: string;
}) => {
  const defaultValues: StoredTable = {
    pagination: { pageIndex: 0, pageSize: 10 },
    sorting: [],
    filtering: []
  };

  const initialValuesStr = window.sessionStorage.getItem(queryParamsLocation);
  let initialValues;
  if (initialValuesStr) {
    initialValues = JSON.parse(initialValuesStr) as StoredTable;
  }

  const [pagination, setPagination] = useState<PaginationState>(
    initialValues?.pagination ?? defaultValues.pagination
  );
  const [sorting, setSorting] = useState<SortingState>(
    initialValues?.sorting ?? defaultValues.sorting
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    initialValues?.filtering ?? defaultValues.filtering
  );

  const reset = () => {
    setPagination(defaultValues.pagination);
    setFiltering(defaultValues.filtering);
    setSorting(defaultValues.sorting);
  };

  // side effect - store current state in sessionstorage
  useEffect(() => {
    const values = { pagination, sorting, filtering };
    const str = JSON.stringify(values);
    window.sessionStorage.setItem(`${queryParamsLocation}`, str);
  }, [pagination, sorting, filtering]);

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
    filtering,
    setFiltering,
    reset
  };
};

export default useStoreTableFilters;
