import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/UserService';
import { Loading, GeneralError } from '../common';
import CardHeader from '../ui/CardHeader';
import UserDetailsTable from './UserGeneralDetailsTable';

function ViewUserDashboard({
  isLoading,
  error,
  data
}: {
  isLoading: boolean;
  error: any;
  data: components['schemas']['UserDto'] | undefined;
}) {
  return (
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <div className="card">
          <CardHeader
          // TODO #5 - Edit details button
          >
            <h2>
              <FormattedMessage
                id="details"
                defaultMessage="Details"
                description="Details"
              />
            </h2>
          </CardHeader>

          <div className="card-body">
            {isLoading ? (
              <Loading />
            ) : error ? (
              (error as any)?.response.status === 401 ? (
                <FormattedMessage
                  id="users.error.unauthorised"
                  defaultMessage="You are not authorised to access this user"
                  description="You are not authorised to access this user"
                />
              ) : (
                <GeneralError />
              )
            ) : (
              data && <UserDetailsTable user={data} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewUserDashboard;
