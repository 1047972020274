/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useContext, useEffect, useState } from 'react';
import {
  onlineManager,
  useIsFetching,
  useQueryClient
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import OfflineSwitchBanner from './OfflineSwitchBanner';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import sessionKeys from '../../query-keys/session-key-factory';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useSessionsBetweenDates from '../../hooks/useSessionsBetweenDates';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import { GeneralError } from '../common';
import useDataSyncInfoStore from '../../hooks/state-management/useDataSyncInfoStore';
import { getOfflineDataDownload } from '../../services/api/course.service';

function OfflineSwitcher() {
  const queryClient = useQueryClient();

  const offlineContext = useContext(OfflineContext);
  const { appMode, updateAppMode } = offlineContext;
  const intl = useIntl();
  const isNetworkOnline = useNetworkStatus();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const setLastDownloaded = useDataSyncInfoStore.use.setLastDownloaded();

  const [isDownloadingData, setIsDownloadingData] = useState<boolean>(false);
  const isQueryFetching = useIsFetching();

  if (!profile?.userId) return <GeneralError message="no user id" />;

  const { instructorSessionsTodayQuery } = useSessionsBetweenDates({
    userId: profile?.userId,
    userType: UserType.INSTRUCTOR,
    displayLocale
  });

  // SWITCHING
  const setAppModeOnline = () => {
    // check if network is available to actually go online
    if (isNetworkOnline) {
      // set app mode
      setIsDownloadingData(false);
      updateAppMode(AppMode.ONLINE);
      onlineManager.setOnline(true);
    }
    // should never reach this, but just in case
    else {
      toast.error(
        intl.formatMessage(
          {
            id: 'error.network_connection',
            defaultMessage:
              'Unable to connect to network. Please check your wifi settings and try again'
          },
          { autoClose: false }
        ),
        {
          delay: 200
        }
      );
      updateAppMode(AppMode.OFFLINE);
      onlineManager.setOnline(false);
    }
  };

  // prepare data to go into offline mode
  // this does not actually take the app offline, it sets react-query mode to offline
  const setAppModeOffline = () => {
    if (isNetworkOnline) {
      // set app mode
      onlineManager.setOnline(true);
      updateAppMode(AppMode.OFFLINE);
      setIsDownloadingData(true);
      instructorSessionsTodayQuery.refetch().then((query) => {
        // eslint-disable-next-line no-console
        console.log('Sessions downloaded :-)');
        query.data?.map((courseSession) =>
          queryClient.prefetchQuery({
            queryKey: sessionKeys.offlineData(
              courseSession.courseSessionId!,
              displayLocale
            ),
            queryFn: () =>
              getOfflineDataDownload(courseSession.courseSessionId!),
            retry: 3
          })
        );

        setLastDownloaded(instructorSessionsTodayQuery.dataUpdatedAt);
      });
    }
    // should never be able reach this thorugh the front end, but just in case
    else {
      toast.error(
        intl.formatMessage(
          {
            id: 'error.cannot_download.network_connection',
            defaultMessage:
              'Cannot download data for today. Unable to connect to network. Please check your wifi settings and try again'
          },
          { autoClose: false }
        ),
        {
          delay: 200
        }
      );
    }
  };

  const isSessionsFetching = useIsFetching({
    queryKey: sessionKeys.all(displayLocale)
  });

  useEffect(() => {
    if (isQueryFetching && appMode === AppMode.OFFLINE) {
      // Make sure onlineManager stays online until all the calls are complete
      const timeout = setTimeout(() => {
        onlineManager.setOnline(true);
      }, 1500);
      return () => clearTimeout(timeout);
    }
    // Once all queries have stopped fetching after data download, then set onlineManager to false
    if (!isQueryFetching && appMode === AppMode.OFFLINE) {
      onlineManager.setOnline(false);
      setIsDownloadingData(false);
    }
    return () => false;
  }, [isQueryFetching, appMode]);

  useEffect(() => {
    instructorSessionsTodayQuery.data?.forEach((session) => {
      const { courseSessionId, courseId } = session;
      // set individual sessions query data from instructor today query
      if (courseSessionId) {
        queryClient.setQueryData(
          sessionKeys.session(courseId, courseSessionId, displayLocale),
          session,
          {
            updatedAt: instructorSessionsTodayQuery.dataUpdatedAt
          }
        );
      }
    });
  }, [instructorSessionsTodayQuery.data]);

  // on initial set of network mode, check if app mode has been set
  useEffect(() => {
    // if no app mode set, but network is available then default to online mode
    if (isNetworkOnline !== undefined && appMode === null) {
      setAppModeOnline();
    }
  }, [isNetworkOnline]);

  return (
    <OfflineSwitchBanner
      onSetOnlineMode={() => setAppModeOnline()}
      onSetOfflineMode={() => setAppModeOffline()}
      isFetching={!!isSessionsFetching && !!isNetworkOnline}
      isDownloadingData={isDownloadingData}
    />
  );
}

export default OfflineSwitcher;
