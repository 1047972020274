/* eslint-disable react/no-array-index-key */
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link, useLocation, useMatches } from 'react-router-dom';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

function Breadcrumbs() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const matches = useMatches();
  const location = useLocation();
  const crumbs = matches
    // get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean((match.handle as any)?.crumb));

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb mb-2 ps-0">
        {crumbs.map((crumb) => {
          if (
            `/${displayLocale}/` === crumb.pathname ||
            `/${displayLocale}` === crumb.pathname
          ) {
            return (
              <li
                key={`${crumb.id}-${crumb.pathname}`}
                className="breadcrumb-item"
              >
                <Link to={crumb.pathname}>
                  <span className="visually-hidden">
                    {(crumb.handle as any).crumb(crumb.data)}
                  </span>
                  <FontAwesomeIcon
                    icon={icon({ name: 'house', style: 'solid' })}
                  />
                </Link>
              </li>
            );
          }
          if (location.pathname === crumb.pathname) {
            return (
              <li
                key={`${crumb.id}-${crumb.pathname}`}
                className="breadcrumb-item active"
                aria-current="page"
              >
                {(crumb.handle as any).crumb(crumb.data)}
              </li>
            );
          }
          return (
            <li
              key={`${crumb.id}-${crumb.pathname}`}
              className="breadcrumb-item"
            >
              <Link to={crumb.pathname}>
                {' '}
                {(crumb.handle as any).crumb(crumb.data)}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
export default Breadcrumbs;
