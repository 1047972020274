const noteKeys = {
  // everything to do with notes
  all: () => ['notes'] as const,
  //   list of notes
  list: (
    courseId: number | undefined,
    sessionId: number | undefined,
    userId: number | undefined
  ) => [...noteKeys.all(), courseId, sessionId, userId, 'list'] as const,
  note: (
    courseId: number,
    courseSessionId: number,
    userId: number,
    noteId: number
  ) => [...noteKeys.all(), courseId, courseSessionId, userId, noteId] as const,
  editNote: (noteId: number) => [...noteKeys.all(), noteId, 'edit'] as const,
  addNote: (courseId: number, sessionId: number, userId: number) =>
    [...noteKeys.all(), courseId, sessionId, userId, 'create'] as const,
  removeNote: () => [...noteKeys.all(), 'delete'] as const
};
export default noteKeys;
