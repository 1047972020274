/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { ScheduleSeriesFormValues } from './ScheduleSeriesFormValues.type';
import Days from '../../../const/days.enum';
import dayjs from '../../../modules/dayjs';
import Checkboxes from './Checkboxes';
import { isValidTimeInput } from '../../../utils/date-time';

const DAYS = Object.keys(Days).filter((v) => !Number.isNaN(Number(v)));

function ScheduleSeriesForm({
  onHandleSubmit,
  onCancel
}: {
  onHandleSubmit: (values: ScheduleSeriesFormValues) => void;
  onCancel: () => void;
}) {
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm<ScheduleSeriesFormValues>({
    mode: 'onChange',
    // for DEV
    defaultValues: {
      startDate: undefined,
      numberOfWeeks: 1,
      daysOfWeek: [],
      times: {
        startTime: '',
        endTime: ''
      }
    }
  });

  const isBefore = (startTime: string) => {
    const startTimeDayJS = dayjs(startTime, 'HH:mm');
    const endTime = getValues('times.endTime');
    const endTimeDayJS = dayjs(endTime, 'HH:mm');
    return startTimeDayJS.isBefore(endTimeDayJS, 'minutes');
  };

  const dayOptions = DAYS.map((val) => {
    return {
      value: val,
      label: dayjs()
        .day(Number(val) + 1)
        .format('ddd')
    };
  });

  const onSubmit = (data: ScheduleSeriesFormValues) => {
    onHandleSubmit(data);
  };

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-3">
        <div className="col-6">
          {' '}
          <label htmlFor="starting-date" className="form-label">
            <FormattedMessage
              id="form.starting-date"
              defaultMessage="Start Date"
              description="Starting date for course"
            />
          </label>
          <input
            id="starting-date"
            type="date"
            className="form-control"
            aria-invalid={errors.startDate ? 'true' : 'false'}
            {...register('startDate', { required: true })}
          />
          {errors.startDate?.type === 'required' && (
            <p className="invalid-feedback d-inline" role="alert">
              <FormattedMessage
                id="form.start-date.required"
                defaultMessage="Start Date is required"
                description="Start Date is required"
              />
            </p>
          )}
        </div>
        <div className="col-6">
          <label htmlFor="week-number" className="form-label">
            <FormattedMessage
              id="form.week-number"
              defaultMessage="Number of weeks"
              description="Number of weeks for course"
            />
          </label>
          <input
            type="number"
            id="week-number"
            className="form-control"
            min="1"
            aria-invalid={errors.numberOfWeeks ? 'true' : 'false'}
            {...register('numberOfWeeks', { required: true, min: 1 })}
          />
          {errors.numberOfWeeks?.type === 'required' && (
            <p className="invalid-feedback d-inline" role="alert">
              <FormattedMessage
                id="form.numberOfWeeks.required"
                defaultMessage="Number of weeks is required"
                description="Number of weeks is required"
              />
            </p>
          )}
          {errors.numberOfWeeks?.type === 'min' && (
            <p className="invalid-feedback d-inline" role="alert">
              <FormattedMessage
                id="form.numberOfWeeks.min"
                defaultMessage="weeks must be at least 1"
                description="weeks must be at least 1"
              />
            </p>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="start-time" className="form-label">
            <FormattedMessage
              id="form.days_of_week"
              defaultMessage="Days of week"
              description="Days of week"
            />
          </label>
          <div className="d-flex">
            <Checkboxes
              options={dayOptions}
              control={control}
              name="daysOfWeek"
            />
          </div>
          {errors.daysOfWeek?.type === 'required' && (
            <p className="invalid-feedback d-inline" role="alert">
              <FormattedMessage
                id="form.daysOfWeek.required"
                defaultMessage="At least one day of the week must be selected"
                description="At least one day of the week must be selected"
              />
            </p>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="start-time" className="form-label">
            <FormattedMessage
              id="form.start-time"
              defaultMessage="Start Time"
              description="Starting time of each session"
            />
          </label>
          <input
            type="time"
            id="start-time"
            className="form-control"
            aria-invalid={errors.times?.startTime ? 'true' : 'false'}
            {...register('times.startTime', {
              required: true,
              validate: {
                isValid: (v) => isValidTimeInput(v),
                isBefore: (v) => isBefore(v)
              }
            })}
          />
          {errors.times?.startTime?.type === 'required' && (
            <p className="invalid-feedback d-inline" role="alert">
              <FormattedMessage
                id="form.startTime.required"
                defaultMessage="Start time is required"
                description="Start time is required"
              />
            </p>
          )}
        </div>
        <div className="col-6">
          <label htmlFor="end-time" className="form-label">
            <FormattedMessage
              id="form.end-time"
              defaultMessage="End Time"
              description="ending time of each session"
            />
          </label>
          <input
            type="time"
            id="end-time"
            className="form-control"
            aria-invalid={errors.times?.endTime ? 'true' : 'false'}
            {...register('times.endTime', {
              onChange: () => {
                trigger('times.startTime');
              },
              required: true,
              validate: {
                isValid: (v) => isValidTimeInput(v)
              }
            })}
          />
        </div>
        {errors.times?.startTime?.type === 'isBefore' && (
          <div className="col-12">
            <p className="invalid-feedback d-inline" role="alert">
              <FormattedMessage
                id="form.endTime.isAfter"
                defaultMessage="End time must be after start time"
                description="End time must be after start time"
              />
            </p>
          </div>
        )}
      </div>
      <div className="align-self-center">
        <button
          type="button"
          className="btn btn-secondary me-2"
          onClick={onCancel}
        >
          <FormattedMessage
            id="cancel"
            defaultMessage="Cancel"
            description="Cancel"
          />
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isDirty || !isValid}
        >
          <FormattedMessage
            id="course.schedule.sessions"
            defaultMessage="Schedule Course Sessions"
            description="Schedule Course Sessions"
          />
        </button>
      </div>
    </form>
  );
}

export default ScheduleSeriesForm;
