import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { AppLanguage } from '../const';
import sessionKeys from '../query-keys/session-key-factory';
import {
  getCourseSessionsForLearnerAvailableToAttendForDateRange,
  getCourseSessionsForLearnerForDateRange,
  getCourseSessionsFullForDateRange
} from '../services/api/course.service';
import { mapSessionToEvent } from '../components/timetable/helpers';

// hook for managing learners
// if you are looking to manage a user within a session - try userUseSession hook instead
const useLearnerCourseSessions = ({
  userId,
  providerId,
  startDate,
  endDate,
  displayLocale = AppLanguage.English,
  bookable
}: {
  userId: number | undefined;
  providerId: number | undefined | null;
  startDate: string;
  endDate: string;
  displayLocale: string | undefined;
  bookable: boolean;
}) => {
  if (!userId) {
    throw Error('invalid user id');
  }
  if (!providerId) {
    throw Error('invalid provider id');
  }

  const bookedCourseSessionsForLearnerQuery = useQuery({
    queryKey: sessionKeys.learnerBookedSessionsForDateRange(
      userId,
      startDate,
      endDate,
      displayLocale
    ),
    queryFn: () =>
      getCourseSessionsForLearnerForDateRange(
        userId,
        startDate,
        endDate,
        displayLocale
      ),
    refetchOnMount: true,
    enabled: !!userId
  });

  const bookedSessionsAsEvents =
    bookedCourseSessionsForLearnerQuery?.data?.map((session) => {
      return mapSessionToEvent(session, {
        learnerBookedOnSession: true,
        bookable: false,
        fullyBooked: false,
        userIsInstructor: false
      });
    }) || [];

  const bookableCourseSessionsQuery = useQuery({
    queryKey: sessionKeys.bookableSessionsForDateRange(
      userId,
      dayjs().format('YYYY-MM-DD'),
      endDate,
      displayLocale
    ),
    queryFn: () =>
      getCourseSessionsForLearnerAvailableToAttendForDateRange(
        userId,
        dayjs().format('YYYY-MM-DD'),
        endDate,
        displayLocale
      ),
    refetchOnMount: true,
    enabled: !!userId,
    retry: 0
  });

  const bookableSessionsAsEvents =
    bookableCourseSessionsQuery?.data?.map((session) => {
      const isSessionFullyBooked =
        session.currentLearnersBooked && session.course?.totalNumberOfPeople
          ? session.currentLearnersBooked >= session.course.totalNumberOfPeople
          : false;
      return mapSessionToEvent(session, {
        learnerBookedOnSession: false,
        bookable: true,
        fullyBooked: isSessionFullyBooked,
        userIsInstructor: false
      });
    }) || [];

  const fullyBookedCourseSessionsQuery = useQuery({
    queryKey: sessionKeys.fullyBookedSessionsForDateRange(
      userId,
      providerId,
      dayjs().format('YYYY-MM-DD'),
      endDate,
      displayLocale
    ),
    queryFn: () =>
      getCourseSessionsFullForDateRange(
        userId,
        providerId,
        dayjs().format('YYYY-MM-DD'),
        endDate,
        displayLocale
      ),
    refetchOnMount: true,
    enabled: !!userId && bookable,
    retry: 0
  });

  const fullyBookedSessionsAsEvents =
    fullyBookedCourseSessionsQuery?.data?.map((session) => {
      return mapSessionToEvent(session, {
        learnerBookedOnSession: false,
        bookable: false,
        fullyBooked: true,
        userIsInstructor: false
      });
    }) || [];

  const queryIsLoading =
    bookedCourseSessionsForLearnerQuery.isLoading ||
    (bookableCourseSessionsQuery.isLoading && bookable) ||
    (fullyBookedCourseSessionsQuery.isLoading && bookable);

  const queryError =
    bookedCourseSessionsForLearnerQuery.error ||
    bookableCourseSessionsQuery.error ||
    fullyBookedCourseSessionsQuery.error;

  return {
    bookedCourseSessionsForLearnerQuery,
    bookableCourseSessionsQuery,
    fullyBookedCourseSessionsQuery,
    bookedSessionsAsEvents,
    bookableSessionsAsEvents,
    fullyBookedSessionsAsEvents,
    queryIsLoading,
    queryError
  };
};

export default useLearnerCourseSessions;
