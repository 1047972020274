/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';

import { components } from '../../../types/openapi/AuthService';
import useRequestResetEmail from '../../../hooks/password/useRequestResetEmail';
import Asterisk from '../../Asterisk';
import InlineFormError from '../../ui/InlineFormError';

function PasswordResetRequestForm() {
  const [isEmailSuccess, setIsEmailSuccess] = useState<boolean>();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful }
  } = useForm({ mode: 'onSubmit', defaultValues: { userName: '' } });

  const { sendPasswordResetEmail } = useRequestResetEmail();

  const onSubmit = (
    passwordBody: Pick<components['schemas']['PasswordBody'], 'userName'>
  ) => {
    const { userName } = passwordBody;

    sendPasswordResetEmail.mutate(userName, {
      onSuccess: () => {
        setIsEmailSuccess(true);
      },
      onError: () => {
        setIsEmailSuccess(false);
      }
    });
  };

  return (
    <div className="d-flex flex-column gap-4">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="align-self-center mx-auto d-flex flex-column gap-4 w-100"
        noValidate
      >
        <div>
          <label htmlFor="userName" className="form-label">
            <Asterisk />
            <FormattedMessage
              id="form.label.username"
              defaultMessage="Username"
            />
          </label>
          <input
            id="userName"
            type="text"
            className={`form-control ${errors.userName && 'is-invalid'}`}
            aria-invalid={errors.userName ? 'true' : 'false'}
            aria-required="true"
            autoComplete="false"
            {...register('userName', {
              required: true,
              disabled: isEmailSuccess
            })}
          />
          <div className="invalid-feedback d-inline text-black">
            {errors.userName?.type === 'required' && (
              <InlineFormError
                className="mb-0"
                message={
                  <FormattedMessage
                    id="error.userName.required"
                    defaultMessage="User name is required"
                    description="User name is required"
                  />
                }
              />
            )}
          </div>
        </div>
        <button
          className="btn btn-primary me-auto"
          type="submit"
          disabled={sendPasswordResetEmail.isLoading || isEmailSuccess}
        >
          {sendPasswordResetEmail.isLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              />
              <FormattedMessage
                id="form.passwordEmail.sending"
                defaultMessage="Sending Reset Email..."
              />
            </>
          ) : (
            <FormattedMessage
              id="form.passwordEmail"
              defaultMessage="Send Reset Email"
            />
          )}
        </button>
      </form>
      {isSubmitSuccessful && isEmailSuccess && (
        <div className="alert alert-success" role="alert">
          <FormattedMessage
            id="success.passwordResetEmail"
            defaultMessage="If this username exists, a password reset email will be sent to the email address attached to this account"
          />
        </div>
      )}
    </div>
  );
}

export default PasswordResetRequestForm;
