import { CompanyTypeStrings } from './base-strings';

export const cy: CompanyTypeStrings = {
  'companyType.LocalAuthority': 'Awdurdod Lleol',
  'companyType.SportsNationalGoverningBody':
    'Corff Llywodraethu Cenedlaethol Chwaraeon (NGB)',
  'companyType.LeisureManagementProvider': 'Darparwr Rheoli Hamdden',
  'companyType.LeisureSportsCentre': 'Canolfan Hamdden/Chwaraeon',
  'companyType.University': 'Prifysgol',
  'companyType.IndividualSportActivityClub':
    'Clwb Chwaraeon / Gweithgareddau Unigol',
  'companyType.HealthcareFacility': 'Cyfleuster Gofal Iechyd',
  'companyType.HotelOrHotelChain': 'Gwesty neu Gadwyn Gwesty',
  'companyType.PersonalTrainerWellnessInstructor':
    'Hyfforddwr Personol / Hyfforddwr Lles',
  'companyType.PrivateSportsCoach': 'Hyfforddwr Chwaraeon Preifat',
  'companyType.SportFranchise': 'Masnachfraint Chwaraeon',
  'companyType.School': 'Ysgol',
  'companyType.Other': 'Arall'
};

export default cy;
