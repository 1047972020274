import { AppLanguage } from '../const';

// ADMIN objectives eg non user specific objectives eg for provider admin/resources
// NOT for linked to a user or users
// NOT for stars/achievements
const objectivesKeys = {
  all: () => ['objectives'] as const,
  // resource assets for this framework version
  list: (
    frameworkVersionId: number | undefined,
    stageId: number | undefined,
    userId: number | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...objectivesKeys.all(),
      'list',
      frameworkVersionId,
      stageId,
      displayLocale || AppLanguage.English
    ] as const,
  listForStage: (
    frameworkId: number | null | undefined,
    frameworkVersionId: number | undefined | null,
    stageId: number | undefined,
    displayLocale?: string | undefined
  ) =>
    [
      ...objectivesKeys.all(),
      'list-for-stage',
      frameworkId,
      frameworkVersionId,
      stageId,
      displayLocale || AppLanguage.English
    ] as const,
  objective: (
    frameworkId: number,
    frameworkVersionId: number,
    stageId: number,
    objectiveId: number,
    displayLocale?: AppLanguage
  ) =>
    [
      ...objectivesKeys.all(),
      'objective',
      frameworkId,
      frameworkVersionId,
      stageId,
      objectiveId,
      displayLocale
    ] as const
};

export default objectivesKeys;
