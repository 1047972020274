import React from 'react';
import { Row } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';

import { components } from '../../../../../../types/openapi/UserService';
import getAgeInMonths from '../../../../../../utils/getAgeInMonths';
import getAgeInYears from '../../../../../../utils/getAgeInYears';

function LearnerAgeCell({
  row
}: {
  row: Row<components['schemas']['UserDto']>;
}) {
  if ((row as any).original.dateOfBirth === '0001-01-01T00:00:00') {
    return <span className="text-muted">–</span>;
  }
  const ageInMonths = getAgeInMonths((row as any).original.dateOfBirth);
  if (ageInMonths <= 36) {
    return (
      <p className="py-1 lh-1">
        <FormattedMessage
          id="age.months"
          defaultMessage="{ageInMonths} {months}"
          description="ageInMonths months"
          values={{
            ageInMonths,
            months: (
              <sub className="text-muted small">
                <FormattedMessage
                  id="months"
                  defaultMessage="months"
                  description="months"
                />
              </sub>
            )
          }}
        />
      </p>
    );
  }

  const ageInYears = getAgeInYears((row as any).original.dateOfBirth);
  return <div className="pt-1">{ageInYears}</div>;
}

export default LearnerAgeCell;
