const deserialiseFilterParam = (urlParam: string | null) => {
  if (urlParam) {
    const filterColumns = urlParam.substring(0).split('&');
    // → e.g. ["centre=3", "framework=1"]

    const deserialisedFilterParam = filterColumns.map((el) => {
      const parts = el.split('=');
      return { id: parts[0], value: parts[1] };
    });

    return deserialisedFilterParam;
  }
  return [];
};
export default deserialiseFilterParam;
