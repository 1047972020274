import { Gender } from '../../../const/gender';

type GenderStrings = Record<Gender, string>;
// ids for translation
const GenderTranslationIds: GenderStrings = {
  [Gender.FEMALE]: 'gender.female',
  [Gender.MALE]: 'gender.male',
  [Gender.NONBINARY]: 'gender.nonbinary',
  [Gender.OTHER]: 'gender.other',
  [Gender.NOTSAY]: 'gender.notsay'
};

export default GenderTranslationIds;
