import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Table } from '@tanstack/react-table';
import Pagination from 'react-bootstrap/Pagination';
import DebouncedInput from './filters/inputs/DebouncedInput';

function TablePaginationFooter({
  table,
  totalRows,
  queryParamsLocation
}: {
  table: Table<any>;
  totalRows: number;
  queryParamsLocation: string;
}) {
  const pageLengthOptions = [
    { value: 10, label: 'Show 10' },
    { value: 20, label: 'Show 20' },
    { value: 30, label: 'Show 30' },
    { value: 40, label: 'Show 40' },
    { value: 50, label: 'Show 50' }
  ];

  const storagePageSize = window.sessionStorage.getItem(
    `${queryParamsLocation}.pageSize`
  );

  const [selectedPageLengthOption, setSelectedPageLengthOption] = useState(
    storagePageSize && Number(storagePageSize)
      ? { label: `Show ${storagePageSize}`, value: Number(storagePageSize) }
      : pageLengthOptions[0]
  );

  const defaultPaginationButtons = [
    { index: 0, disabled: false },
    { index: 1, disabled: false },
    { index: 2, disabled: false }
  ];
  let paginationButtons = [...defaultPaginationButtons];

  const generatePageNumber = (index: number) => {
    return index + 1;
  };

  const generateVisibleButtons = () => {
    const currentPageIndex = table.getState().pagination.pageIndex;

    const pageCount = table.getPageCount();
    if (pageCount <= 2) {
      paginationButtons = [
        { index: 0, disabled: false },
        { index: 1, disabled: false },
        { index: 2, disabled: true }
      ];
    } else {
      const lastPageIndex = pageCount - 1;
      if (currentPageIndex >= lastPageIndex - 1) {
        paginationButtons = [
          { index: lastPageIndex - 2, disabled: false },
          { index: lastPageIndex - 1, disabled: false },
          { index: lastPageIndex, disabled: false }
        ];
      } else if (currentPageIndex <= 1) {
        paginationButtons = [...defaultPaginationButtons];
      } else {
        paginationButtons = [
          { index: currentPageIndex - 1, disabled: false },
          { index: currentPageIndex, disabled: false },
          { index: currentPageIndex + 1, disabled: false }
        ];
      }
    }
  };
  generateVisibleButtons();

  useEffect(() => {
    generateVisibleButtons();
  }, [table.getState().pagination.pageIndex]);

  const handleGoToPageChange = (stringValue: string) => {
    if (stringValue) {
      const value = Number(stringValue);
      if (value && value > 0) {
        table.setPageIndex(value - 1);
      } else if (!value) {
        table.setPageIndex(0);
      }
    }
  };

  return (
    <div className="pagination-footer">
      <Pagination>
        <Pagination.First
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
          aria-label="First Page"
        />
        <Pagination.Prev
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          aria-label="Previous Page"
        />

        {paginationButtons.map((button) => {
          return (
            <Pagination.Item
              key={button.index}
              className={
                !button.disabled &&
                button.index !== table.getState().pagination.pageIndex
                  ? 'text-black'
                  : ''
              }
              onClick={() => table.setPageIndex(button.index)}
              active={button.index === table.getState().pagination.pageIndex}
              aria-label={`Page ${generatePageNumber(button.index)}`}
              disabled={button.disabled}
            >
              {generatePageNumber(button.index)}
            </Pagination.Item>
          );
        })}

        <Pagination.Next
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          aria-label="Next Page"
        />
        <Pagination.Last
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
          aria-label="Last Page"
        />
      </Pagination>

      <div className="d-flex align-items-center gap-3">
        <div className="paginate-label" aria-label="Go to page:">
          Go to page:
        </div>
        <DebouncedInput
          type="number"
          debounce={1000}
          onChange={handleGoToPageChange}
          value={(table.getState().pagination.pageIndex + 1).toString()}
        />
      </div>

      <Select
        aria-label="Select Page Length"
        className="select-input"
        menuPlacement="top"
        options={pageLengthOptions}
        defaultValue={selectedPageLengthOption}
        onChange={(selectedOption) => {
          if (selectedOption) {
            setSelectedPageLengthOption(selectedOption);
            table.setPageIndex(0);
            table.setPageSize(Number(selectedOption.value));
          }
        }}
        isSearchable={false}
      />

      <div className="paginate-label" aria-label="Total number of rows">
        {totalRows} Total
      </div>
    </div>
  );
}

export default TablePaginationFooter;
