import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppLanguage } from '../../const';
import frameworkKeys from '../../query-keys/framework-key-factory';
import {
  getFramework,
  updateFramework
} from '../../services/api/framework.service';
import { components } from '../../types/openapi/FrameworkService';
import { Subset } from '../../types/Subset.type';

// hook for managing frameworks
const useFramework = ({
  frameworkId,
  displayLocale = AppLanguage.English
}: {
  frameworkId: number;
  displayLocale: string | undefined;
}) => {
  const queryClient = useQueryClient();

  const frameworkQuery = useQuery({
    queryKey: frameworkKeys.framework(frameworkId, displayLocale),
    queryFn: () => getFramework(frameworkId, displayLocale),
    refetchOnMount: true,
    enabled: !!frameworkId
  });

  const editFramework = useMutation(
    (newFramework: Subset<components['schemas']['FrameworkDto']>) => {
      // optimistic update...
      if (newFramework) {
        queryClient.setQueryData(
          frameworkKeys.framework(frameworkId, displayLocale),
          { ...newFramework }
        );
      }

      return updateFramework(frameworkId, newFramework, displayLocale);
    },
    {
      onSuccess: (editedFramework) => {
        if (editedFramework) {
          queryClient.setQueryData(
            frameworkKeys.framework(frameworkId, displayLocale),
            { ...editedFramework }
          );
        }
        queryClient.invalidateQueries({
          queryKey: frameworkKeys.framework(frameworkId, displayLocale)
        });

        queryClient.invalidateQueries({
          queryKey: frameworkKeys.list(displayLocale)
        });
      },

      onError: (_, oldStage) => {
        // if something goes wrong then reset to the previous data
        console.error(oldStage);

        if (oldStage) {
          queryClient.setQueryData(
            frameworkKeys.framework(frameworkId, displayLocale),
            { ...oldStage }
          );
        }
      },
      onSettled: () => {}
    }
  );

  return {
    frameworkQuery,
    editFramework
  };
};

export default useFramework;
