/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { components } from '../../types/openapi/CourseService';

function TimetableFilters({
  centres,
  frameworks,
  show,
  isAdmin,
  onSubmit,
  onResetFilter,
  handleCloseFilter
}: {
  centres: components['schemas']['CentreDto'][] | undefined;
  frameworks: components['schemas']['FrameworkDto'][] | undefined;
  show: boolean;
  isAdmin: boolean;
  onSubmit: () => void;
  onResetFilter: () => void;
  handleCloseFilter: () => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: searchParams.get('name') || '',
      centreId: searchParams.get('centreId') || undefined,
      frameworkId: searchParams.get('frameworkId') || undefined
    }
  });

  const resetFilter = () => {
    setSearchParams((prev: URLSearchParams) => {
      prev.set('name', '');
      prev.set('centreId', '');
      prev.set('frameworkId', '');
      const originalParams = Object.fromEntries(prev);

      return new URLSearchParams(originalParams);
    });
    reset({
      name: '',
      centreId: '',
      frameworkId: ''
    });
    onResetFilter();
  };

  const handleSubmitForm = (values: any) => {
    setSearchParams((prev: URLSearchParams) => {
      const originalParams = Object.fromEntries(prev);
      return new URLSearchParams({ ...originalParams, ...values });
    });
    onSubmit();
    handleCloseFilter();
  };

  return (
    <Offcanvas show={show} onHide={handleCloseFilter} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {' '}
          <FormattedMessage
            id="sessions.filter"
            defaultMessage="Filter Sessions"
            description="Filter Sessions"
          />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form
          className="d-flex flex-column"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div className="mb-3">
            <label htmlFor="name" className="form-label w-100">
              <FormattedMessage
                id="form.name"
                defaultMessage="Name"
                description="Name"
              />
              <input
                id="name"
                type="text"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.name ? 'true' : 'false'}
                {...register('name')}
              />
            </label>
          </div>
          {!isAdmin && (
            <div className="mb-3">
              <label htmlFor="centreId" className="form-label">
                <FormattedMessage
                  id="form.centre"
                  defaultMessage="Centre"
                  description="Select from list of centres"
                />{' '}
              </label>
              <select
                id="centreId"
                className="form-control"
                aria-invalid={errors.centreId ? 'true' : 'false'}
                {...register('centreId')}
              >
                <option value="" selected>
                  <FormattedMessage
                    id="form.select_centre.placeholder"
                    defaultMessage="Select a centre"
                    description="Select from list of centres"
                  />
                </option>{' '}
                {centres ? (
                  centres.map((centre: components['schemas']['CentreDto']) => {
                    return (
                      <option key={centre.centreId} value={centre.centreId}>
                        {centre.name}
                      </option>
                    );
                  })
                ) : (
                  <FormattedMessage
                    id="centres.none"
                    defaultMessage="No Centres"
                    description="No Centres"
                  />
                )}
              </select>
            </div>
          )}

          <div className="mb-3">
            <label htmlFor="frameworkId" className="form-label">
              <FormattedMessage
                id="form.frameworkId"
                defaultMessage="Framework"
                description="Select from list of frameworks"
              />{' '}
            </label>
            <select
              id="frameworkId"
              className="form-control"
              aria-invalid={errors.frameworkId ? 'true' : 'false'}
              {...register('frameworkId')}
            >
              <option value="" selected>
                <FormattedMessage
                  id="form.select_framework.placeholder"
                  defaultMessage="Select a framework"
                  description="Select from list of frameworks"
                />
              </option>{' '}
              {frameworks ? (
                frameworks.map(
                  (framework: components['schemas']['FrameworkDto']) => {
                    return (
                      <option
                        key={framework.frameworkId}
                        value={framework.frameworkId}
                      >
                        {framework.name}
                      </option>
                    );
                  }
                )
              ) : (
                <FormattedMessage
                  id="frameworks.none"
                  defaultMessage="No Frameworks"
                  description="No Frameworks"
                />
              )}
            </select>
          </div>

          <div className="d-flex gap-2 justify-content-center">
            <button
              type="button"
              className="btn btn-outline-secondary align-self-center"
              disabled={!isValid}
              onClick={() => resetFilter()}
            >
              <FormattedMessage
                id="reset"
                defaultMessage="Reset"
                description="Reset"
              />
            </button>
            <button
              type="submit"
              className="btn btn-primary align-self-center"
              disabled={!isDirty || !isValid}
            >
              <FormattedMessage
                id="sessions.view.button"
                defaultMessage="View sessions"
                description="View sessions"
              />
            </button>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default TimetableFilters;
