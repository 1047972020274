import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/UserService';

function UserDetailsTable({
  user,
  showMedicalConditions
}: {
  user: components['schemas']['UserDto'];
  showMedicalConditions: boolean | undefined;
}) {
  return (
    <table className="table">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="email"
              defaultMessage="Email"
              description="Email"
            />
          </th>
          <td className="text-break" data-gdpr="true">
            {user.email || (
              <span className="text-muted">
                <FormattedMessage
                  id="email.none"
                  defaultMessage="No email provided"
                  description="No email provided"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="telephone"
              defaultMessage="Telephone"
              description="Telephone"
            />
          </th>
          <td data-gdpr="true">
            {user.telephone || (
              <span className="text-muted">
                <FormattedMessage
                  id="telephone.none"
                  defaultMessage="No telephone provided"
                  description="No telephone provided"
                />
              </span>
            )}
          </td>
        </tr>
        {showMedicalConditions && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="notes"
                defaultMessage="Notes"
                description="Notes"
              />
            </th>
            <td data-gdpr="true">
              {user.medicalConditions || (
                <span className="text-muted">
                  <FormattedMessage
                    id="medical_conditions.none"
                    defaultMessage="No Medical Conditions"
                    description="No Medical Conditions"
                  />
                </span>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default UserDetailsTable;
