/* eslint-disable no-unsafe-optional-chaining */
import { IntlShape } from 'react-intl';
import { components } from '../../types/openapi/CourseService';

const buildSelectStageOptions = (
  courses:
    | components['schemas']['StageDto'][]
    | components['schemas']['StageSlimDto'][],
  intl: IntlShape
) => {
  const stageTranslation = intl.formatMessage({
    id: 'stage.select',
    defaultMessage: 'Stage'
  });

  return courses.map(
    (
      _stage:
        | components['schemas']['StageDto']
        | components['schemas']['StageSlimDto']
    ) => {
      return {
        label: `${_stage.name} (${stageTranslation} ${_stage.stageOrder})`,
        value: _stage.stageId
      };
    }
  );
};

export default buildSelectStageOptions;
