/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { components } from '../../types/openapi/UserService';
import Loading from '../common/Loading';
import RolesList from './RolesList';
import GeneralError from '../common/GeneralError';

function RolesListContainer({
  permissions,
  permissionsIsLoading,
  permissionsError
}: {
  permissions: components['schemas']['UserProvidersDto'][] | undefined;
  permissionsIsLoading: boolean;
  permissionsError: any;
}) {
  return (
    <>
      <div className="card border-0 rounded-top-right-lg mb-3 pt-3 w-75 mx-auto">
        <div className="card-header border-0 w-100 text-center role-welcome-message">
          <h1>
            <FormattedMessage
              id="user.welcome.sportPassport"
              defaultMessage="Welcome to Sport Passport."
            />
          </h1>
          <p className="h3">
            <FormattedMessage
              id="profile.today"
              defaultMessage="Today I am a..."
            />
          </p>
        </div>
      </div>

      {permissionsIsLoading ? (
        <Loading />
      ) : permissionsError ? (
        (permissionsError as any).response.data ===
        'User Permissions Not Found' ? (
          <div className="alert alert-warning" role="alert">
            <FormattedMessage
              id="contact.support.no_role"
              defaultMessage="You have not been set up with a role, please contact support"
              description="You have not been set up with a role, please contact support"
            />
          </div>
        ) : (
          <GeneralError />
        )
      ) : permissions ? (
        <RolesList />
      ) : (
        <FormattedMessage
          id="user.roles.not_found"
          defaultMessage="Roles not found"
        />
      )}
    </>
  );
}

export default RolesListContainer;
