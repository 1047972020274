import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { onlineManager } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import OfflineBanner from './OfflineBanner';
import OnlineBanner from './OnlineBanner';

import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import OnlineStatusBanner from './OnlineStatusBanner';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { AppLanguage, AppRoute } from '../../const';

function OfflineSwitchBanner({
  onSetOfflineMode,
  onSetOnlineMode,
  isFetching,
  isDownloadingData
}: {
  onSetOfflineMode: () => void;
  onSetOnlineMode: () => void;
  isFetching: boolean;
  isDownloadingData: boolean;
}) {
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const isNetworkOnline = useNetworkStatus();
  const isOnline = onlineManager.isOnline();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const location = useLocation();

  if (
    location.pathname.includes(
      appStrings[displayLocale as AppLanguage][AppRoute.RoleChange]
    )
  )
    return <div />;

  // network connection is unknown
  if (isNetworkOnline === undefined) {
    return (
      <OnlineStatusBanner
        online={false}
        className="offline"
        renderStatusMessage={() => (
          <FormattedMessage
            id="network.checking.banner"
            defaultMessage="Checking for network..."
            description="Checking for network..."
          />
        )}
        renderButton={() => (
          <span className="mx-auto me-md-0">
            <FontAwesomeIcon
              icon={faRotate}
              className="fa-spin sync-info__large-icon"
            />
          </span>
        )}
      />
    );
  }

  // app mode is is unknown
  if (appMode === undefined || appMode === null) {
    return (
      <OnlineStatusBanner
        online={false}
        className="offline"
        renderStatusMessage={() => (
          <FormattedMessage
            id="appmode.checking.banner"
            defaultMessage="Checking for app mode..."
            description="Checking for app mode..."
          />
        )}
        renderButton={() => (
          <span className="mx-auto me-md-0">
            <FontAwesomeIcon
              icon={faRotate}
              className="fa-spin sync-info__large-icon"
            />
          </span>
        )}
      />
    );
  }

  // Online mode AND connection
  if (isNetworkOnline && appMode === AppMode.ONLINE && isOnline) {
    return (
      <OnlineStatusBanner
        online
        renderStatusMessage={() => <OnlineBanner />}
        renderButton={() => (
          <button
            disabled={!isNetworkOnline}
            type="button"
            onClick={() => onSetOfflineMode()}
            className="offline-switcher__button btn mx-auto me-md-0"
          >
            <FormattedMessage
              id="button.offline"
              defaultMessage="Go offline"
              description="Go offline"
            />
          </button>
        )}
      />
    );
  }

  // Online mode but NO connection
  if (!isNetworkOnline && appMode === AppMode.ONLINE && isOnline) {
    return (
      <OnlineStatusBanner
        online={false}
        className="offline"
        renderStatusMessage={() => (
          <FormattedMessage
            id="network.error.banner"
            defaultMessage="No Network Connection - please check your connection to be able to sync data"
            description="No Network Connection - please check your connection to be able to sync data"
          />
        )}
        renderButton={() => (
          <button
            disabled={!isNetworkOnline}
            type="button"
            className="offline-switcher__button btn mx-auto me-md-0"
          >
            <FormattedMessage
              id="button.offline"
              defaultMessage="Go offline"
              description="Go offline"
            />
          </button>
        )}
      />
    );
  }

  // Offline mode AND connection - so can go back online when ready eg when instructor back in office
  if (isNetworkOnline && appMode === AppMode.OFFLINE && !isOnline) {
    return (
      <OnlineStatusBanner
        online={false}
        className="offline"
        renderStatusMessage={() => <OfflineBanner />}
        renderButton={() => (
          <button
            // can only download data to go offline/upload sync if network connection
            type="button"
            onClick={() => onSetOnlineMode()}
            className="offline-switcher__button btn mx-auto me-md-0"
          >
            <FormattedMessage
              id="button.online"
              defaultMessage="Go online"
              description="Go online"
            />
          </button>
        )}
      />
    );
  }

  // Offline mode but NO connection - so cannot go back online when ready eg when instructor at pool side
  if (!isNetworkOnline && appMode === AppMode.OFFLINE && !isOnline) {
    return (
      <OnlineStatusBanner
        online={false}
        className="offline"
        renderStatusMessage={() => <OfflineBanner />}
        renderButton={() => (
          <div className="align-self-center">
            <FormattedMessage
              id="button.online.error.no.network"
              defaultMessage="Sync not available without network connection"
              description="Sync not available without network connection"
            />
          </div>
        )}
      />
    );
  }

  // loading - between appmode switchs and online manager switch
  if (isFetching || isDownloadingData) {
    return (
      <OnlineStatusBanner
        online={false}
        renderStatusMessage={() => (
          <FormattedMessage
            id="download.in.progress"
            defaultMessage="Download in progress..."
            description="Download in progress..."
          />
        )}
        renderButton={() => (
          <span className="mx-auto me-md-0">
            <FontAwesomeIcon
              icon={faRotate}
              className="fa-spin sync-info__large-icon"
            />
          </span>
        )}
      />
    );
  }
  // should never get to this, but if so then something has gone wrong
  return (
    <OnlineStatusBanner
      online={false}
      renderStatusMessage={() => (
        <FormattedMessage
          id="sync.error"
          defaultMessage="An error has occurred"
          description="sync.error"
        />
      )}
      renderButton={() => (
        <span className="mx-auto me-md-0">
          <FontAwesomeIcon
            icon={faRotate}
            className="fa-spin sync-info__large-icon"
          />
        </span>
      )}
    />
  );
}

export default OfflineSwitchBanner;
