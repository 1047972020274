import dayjs from '../../modules/dayjs';

dayjs.tz.setDefault('Europe/London');
// convert input strings for date and time into a dayJS object
const calculateDateTime = (
  startDate: string,
  startTime: string
): dayjs.Dayjs => {
  const dayjsLocal = dayjs.tz(startDate + startTime);
  return dayjsLocal;
};

export default calculateDateTime;
