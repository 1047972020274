import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ColumnDef, Table } from '@tanstack/react-table';
import { FieldValues } from 'react-hook-form';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Loading, GeneralError } from '../common';
import SelectableTableBody from './SelectableTableBody';
import TableHead from './TableHead';
import TablePaginationFooter from './TablePaginationFooter';
import OffCanvasFilters from './filters/offcanvas/OffCanvasFilters';

function SelectablePaginatedTable({
  table,
  error,
  filtering,
  sorting,
  resetFilters,
  columns,
  dataCount,
  isLoading,
  queryParamsLocation,
  dataId,
  noneFoundMessage,
  showFooter,
  tableId
}: {
  table: Table<any>;
  error: any;
  filtering: any[] | undefined;
  sorting: any;
  resetFilters: () => void;
  columns: ColumnDef<any>[];
  dataCount: number | undefined;
  isLoading: boolean;
  queryParamsLocation: string;
  dataId: string;
  noneFoundMessage: React.ReactElement;
  showFooter: boolean;
  tableId: string | undefined;
}) {
  const [showFilter, setShowFilter] = useState(false);

  const handleCloseFilters = () => setShowFilter(false);
  const handleShowFilters = () => setShowFilter(true);

  const filterSessions = (values: FieldValues) => {
    columns.forEach((column) => {
      if (column.id) {
        const tableColumn = table.getColumn(column.id);
        if (values[column.id]) {
          tableColumn?.setFilterValue(values[column.id]);
        }
        if (column.id === values.sorting) {
          tableColumn?.toggleSorting(false);
        }
      }
    });
  };
  return (
    <>
      <div className="d-flex justify-content-end my-3 ">
        <Button
          variant="outline-secondary"
          className="table-offcanvas-btn"
          onClick={handleShowFilters}
        >
          <FormattedMessage
            id="filter"
            defaultMessage="Filter"
            description="Filter"
          />
          <FontAwesomeIcon
            className="pe-1"
            icon={icon({ name: 'filter', style: 'solid' })}
          />
        </Button>
      </div>
      <OffCanvasFilters
        show={showFilter}
        filtering={filtering}
        sorting={sorting}
        onSubmit={filterSessions}
        resetFilters={resetFilters}
        handleCloseFilter={handleCloseFilters}
        table={table}
        columns={columns}
      />
      <div className="table-responsive">
        <table
          id={tableId}
          className={`table card-list-table ${isLoading ? 'loading' : ''}`}
        >
          <TableHead
            table={table}
            error={error}
            filtering={filtering}
            resetFilters={resetFilters}
            columns={columns}
            dataCount={dataCount}
          />
          <SelectableTableBody table={table} dataId={dataId} />
        </table>
        <div>
          {isLoading ? (
            <Loading />
          ) : error ? (
            <GeneralError error={error} />
          ) : dataCount === 0 ? (
            <section className="alert alert-warning">
              {noneFoundMessage}
            </section>
          ) : (
            showFooter && (
              <TablePaginationFooter
                table={table}
                totalRows={dataCount ?? 0}
                queryParamsLocation={queryParamsLocation}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}

export default SelectablePaginatedTable;
