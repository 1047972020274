const baseStrings = {
  'companyType.LocalAuthority': 'Local Authority',
  'companyType.SportsNationalGoverningBody':
    'Sports National Governing Body (NGB)',
  'companyType.LeisureManagementProvider': 'Leisure Management Provider',
  'companyType.LeisureSportsCentre': 'Leisure/Sports Centre',
  'companyType.University': 'University',
  'companyType.IndividualSportActivityClub': 'Individual Sport / Activity Club',
  'companyType.HealthcareFacility': 'Healthcare Facility',
  'companyType.HotelOrHotelChain': 'Hotel or Hotel Chain',
  'companyType.PersonalTrainerWellnessInstructor':
    'Personal Trainer / Wellness Instructor',
  'companyType.PrivateSportsCoach': 'Private Sports Coach',
  'companyType.SportFranchise': 'Sport Franchise',
  'companyType.School': 'School',
  'companyType.Other': 'Other'
};

export type CompanyTypeStrings = typeof baseStrings;
export const en = baseStrings;
