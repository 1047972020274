import React from 'react';
import { FormattedMessage } from 'react-intl';
import ViewOnlyNotesList from '../notes/ViewOnlyNotesList';

function ViewNotesSection({
  courseId,
  sessionId,
  userId,
  learnerId
}: {
  courseId: number;
  sessionId: number;
  userId: number;
  learnerId: number;
}) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="card border-0 rounded-top-right-lg mb-3">
          <div className="card-header rounded-top-right-lg">
            <h3>
              <FormattedMessage
                id="notes"
                defaultMessage="Notes"
                description="Notes"
              />
            </h3>
          </div>
          <div className="card-body">
            <ViewOnlyNotesList
              courseId={courseId}
              sessionId={sessionId}
              userId={learnerId || userId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewNotesSection;
