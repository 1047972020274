import React from 'react';
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

import { components } from '../../../types/openapi/CourseService';
import Asterisk from '../../Asterisk';
import InlineFormError from '../../ui/InlineFormError';

function FrameworkSelect({
  control,
  frameworks,
  frameworkVersionId,
  errors
}: {
  control: Control<any>;
  frameworks: components['schemas']['FrameworkDto'][];
  frameworkVersionId: number | undefined;
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      description: string;
      frameworkVersionId: number;
      stageIds: {
        stageId: number;
      }[];
      centreId: never;
      facilityId: never;
      providerId: number;
      instructorId: number;
      totalNumberOfPeople: number;
      externalCourseId: string;
    }>
  >;
}) {
  const frameworkOptions = frameworks.map(
    (framework: components['schemas']['FrameworkDto']) => {
      const { frameworkVersion } = framework;
      if (frameworkVersion) {
        return {
          label: framework.name || frameworkVersion.name,
          value: frameworkVersion.frameworkVersionId
        };
      }
      return {};
    }
  );
  return (
    <div className="mb-3">
      <label htmlFor="frameworkVersionId" className="form-label">
        <Asterisk />
        <FormattedMessage
          id="form.framework"
          defaultMessage="Framework"
          description="Select from list of frameworks"
        />{' '}
      </label>
      <Controller
        control={control}
        name="frameworkVersionId"
        rules={{ required: true }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Select
            inputId="frameworkVersionId"
            aria-invalid={error ? 'true' : 'false'}
            onChange={(event) => onChange(event?.value)}
            placeholder={
              <FormattedMessage
                id="form.select_framework.placeholder"
                defaultMessage="Select a framework"
                description="Select from list of frameworks"
              />
            }
            defaultValue={frameworkOptions.find(
              (option) => option.value === frameworkVersionId
            )}
            options={frameworkOptions}
          />
        )}
      />
      {errors.frameworkVersionId?.type === 'required' && (
        <InlineFormError
          message={
            <FormattedMessage
              id="form.frameworkVersionId.required"
              defaultMessage="Framework is required"
              description="Framework is required"
            />
          }
        />
      )}
    </div>
  );
}
export default FrameworkSelect;
