const baseStrings = {
  'usertype.superadmin': 'Super Admin',
  'usertype.sportmanager': 'Sport Manager',
  'usertype.admin': 'Admin',
  'usertype.instructor': 'Instructor',
  'usertype.learner': 'Learner',
  'usertype.groupadmin': 'Group Admin'
};

export type UserTypeStrings = typeof baseStrings;
export const en = baseStrings;
