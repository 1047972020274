/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState
} from '@tanstack/react-table';

import { components } from '../../types/openapi/CourseService';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import PaginatedTable from '../tables/PaginatedTable';
import useTableColumns from '../../hooks/table/useTableColumns';

function CoursesTableForAdmin({
  data,
  error,
  seeCentres,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  isLearnerPage,
  queryParamsLocation,
  renderActions
}: {
  data: components['schemas']['PagedCourseDto'] | undefined | null;
  error: any;
  seeCentres: boolean | undefined;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState | undefined;
  sorting: any[] | undefined;
  filtering: any[] | undefined;
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  isLearnerPage: boolean | undefined;
  queryParamsLocation: string;
  renderActions?: ({
    courseId
  }: {
    courseId: number;
  }) => JSX.Element | ReactNode;
}) {
  const intl = useIntl();

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  const CENTRES_COLUMN = {
    id: 'centre',
    header: intl.formatMessage({
      id: 'centre',
      defaultMessage: 'Centre',
      description: 'Centre'
    }),
    cell: ({ row }: { row: any }) => {
      const course = row.original;
      return `${course?.centre?.name ?? ''}`;
    },
    accessorKey: 'centre.name',
    sortDescFirst: true,
    filterType: 'basicInput'
  };

  const {
    courseNameColumn,
    frameworkColumn,
    stageColumn,
    courseStartDateColumn,
    courseEndDateColumn,
    schoolsColumn
  } = useTableColumns({});

  const columns = [
    courseNameColumn,
    frameworkColumn,
    stageColumn,
    courseStartDateColumn,
    courseEndDateColumn,
    schoolsColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const actions =
          renderActions &&
          renderActions({
            courseId: row.original.courseId
          });
        return actions;
      },
      filterType: undefined
    }
  ];

  if (seeCentres) {
    columns.splice(columns.length - 1, 0, CENTRES_COLUMN);
  }

  const table = useReactTable({
    data: data?.courses ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: appMode !== AppMode.OFFLINE,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,

    pageCount:
      data && data.numberOfCourses
        ? Math.ceil(
            data.numberOfCourses / (pagination ? pagination.pageSize : 10)
          )
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <div>
      {appMode === AppMode.OFFLINE && (
        <div className="d-flex justify-content-end mb-2">
          <span className="alert alert-info flex-grow-1 me-3 mb-0">
            <FormattedMessage
              id="offline.data_unavailable"
              defaultMessage="You are currently in offline mode. Only data for today is available."
              description="You are currently in offline mode. Only data for today is available."
            />
          </span>
        </div>
      )}
      <PaginatedTable
        table={table}
        error={error}
        filtering={filtering}
        sorting={sorting}
        resetFilters={resetFilters}
        columns={columns}
        dataCount={data?.numberOfCourses}
        refetching={refetching}
        isLoading={isLoading}
        queryParamsLocation={queryParamsLocation}
        dataId="courseId"
        noneFoundMessage={
          isLearnerPage ? (
            <FormattedMessage
              id="learner.courses.none"
              defaultMessage="This learner is currently not on any courses"
              description="This learner is currently not on any courses"
            />
          ) : (
            <FormattedMessage
              id="courses.error.none"
              defaultMessage="No courses found"
            />
          )
        }
        showFooter={
          (data?.numberOfCourses ?? 0) > 10 && appMode !== AppMode.OFFLINE
        }
      />
    </div>
  );
}

export default CoursesTableForAdmin;

CoursesTableForAdmin.defaultProps = {
  renderActions: () => {}
};
