import React, { useContext, useEffect, useState } from 'react';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import useProviders from '../../hooks/providers/useProviders';
import ProviderListActions from './ProviderListActions';
import ProvidersTable from './ProvidersTable';

function ProvidersList() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const queryParamsLocation = FilteringQueryParams.Providers;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(pagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(pagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(sorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(filtering)
    );
  }, [pagination, sorting, filtering]);

  const { providersListQuery } = useProviders({
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <div>
      <ProvidersTable
        data={providersListQuery.data}
        error={providersListQuery.error}
        isLoading={providersListQuery.isLoading}
        refetching={providersListQuery.isRefetching}
        pagination={pagination}
        sorting={sorting}
        filtering={filtering}
        setPagination={setPagination}
        setSorting={setSorting}
        setFiltering={setFiltering}
        queryParamsLocation={queryParamsLocation}
        renderProvidersLink={({ provider }) => (
          <ProviderListActions providerId={`${provider.providerId}`} />
        )}
      />
    </div>
  );
}
export default ProvidersList;
