import { useQuery } from '@tanstack/react-query';
import {
  getCentreSchools,
  getProviderSchools
} from '../../services/api/provider.service';
import { FilteringObject } from '../../const/filtering-object';
import schoolKeys from '../../query-keys/schools-key-factory';

const useProviderSchools = ({
  providerId,
  centreId,
  isCentreAdmin,
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  providerId: number | null | undefined;
  centreId: number | null | undefined;
  isCentreAdmin: boolean;
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const schoolsForProviderListQuery = useQuery({
    queryKey: schoolKeys.providerList(
      providerId,
      displayLocale,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ),
    queryFn: () =>
      getProviderSchools(
        providerId,
        displayLocale,
        pageIndex,
        pageSize,
        sorting,
        rawFiltering
      ),
    refetchOnMount: true,
    enabled: !!providerId && !isCentreAdmin && !centreId
  });

  const schoolsForCentreListQuery = useQuery({
    queryKey: schoolKeys.centreList(
      providerId,
      centreId,
      displayLocale,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ),
    queryFn: () =>
      getCentreSchools(
        providerId,
        centreId,
        displayLocale,
        pageIndex,
        pageSize,
        sorting,
        rawFiltering
      ),
    refetchOnMount: true,
    enabled: !!providerId && !!centreId
  });

  if (isCentreAdmin || centreId) {
    return schoolsForCentreListQuery;
  }
  return schoolsForProviderListQuery;
};

export default useProviderSchools;
