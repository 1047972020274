import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../../types/openapi/ProviderService';

function GroupAdmin({
  admins
}: {
  admins: components['schemas']['UserGroupAdminDto'][] | undefined;
}) {
  if (!admins) {
    return <span className="text-muted">&ndash;</span>;
  }

  return (
    <div>
      {admins.length > 0 ? (
        admins.map((groupAdmin: components['schemas']['UserGroupAdminDto']) => {
          return <p key={groupAdmin.userId}>{groupAdmin.user?.username}</p>;
        })
      ) : (
        <FormattedMessage
          id="groupAdmins.none_found"
          defaultMessage="No group admins"
          description="No group admins found"
        />
      )}
    </div>
  );
}

export default GroupAdmin;
