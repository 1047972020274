import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';

import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { isCentreAdmin } from '../utils/permissions';

const ManageFrameworks = lazy(
  () => import('../components/frameworks/ManageFrameworks')
);
const EditFrameworkVersionName = lazy(
  () => import('../components/frameworks/EditFrameworkVersionName')
);
const ViewFramework = lazy(
  () => import('../components/frameworks/ViewFramework')
);
const CloneFrameworkVersion = lazy(
  () => import('../components/frameworks/CloneFrameworkVersion')
);
const CreateFramework = lazy(
  () => import('../components/frameworks/create/CreateFramework')
);
const EditFrameworkVersion = lazy(
  () => import('../components/frameworks/EditFrameworkVersion')
);
const FrameworkProviderAdd = lazy(
  () => import('../components/frameworks/FrameworkProvidersAdd')
);

const ErrorPage = lazy(() => import('../pages/ErrorPage'));

export default function frameworkRoutes(lang: string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Frameworks],
    element: (
      <ProtectedRoute
        allowedRoles={[
          UserType.SPORT_MANAGER,
          UserType.SUPER_ADMIN,
          UserType.ADMIN
        ]}
        allowedProfiles={['providerId']}
        customAllowed={(_profile) => {
          return isCentreAdmin(_profile);
        }}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="frameworks.breadcrumb"
          defaultMessage="Frameworks"
          description="Frameworks"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageFrameworks />,
        errorElement: <ErrorPage />
      },
      {
        path: ':frameworkId',
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="framework.breadcrumb"
              defaultMessage="Framework"
              description="Framework"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ViewFramework />,
            errorElement: <ErrorPage />
          },

          {
            path: `${appStrings[langVal][AppRoute.AddFrameworkProvider]}`,
            element: <FrameworkProviderAdd />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="framework.add_provider.breadcrumb"
                  defaultMessage="Add Provider"
                  description="Add Provider"
                />
              )
            }
          },
          {
            path: `${
              appStrings[langVal][AppRoute.FrameworkVersion]
            }/:frameworkVersionId`,

            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="framework.version"
                  defaultMessage="Framework Version"
                  description="Framework Version"
                />
              )
            },
            children: [
              {
                index: true,
                element: <EditFrameworkVersion />,
                errorElement: <ErrorPage />
              },
              {
                path: `${appStrings[langVal][AppRoute.EditFrameworkVersion]}`,
                element: <EditFrameworkVersionName />,
                errorElement: <ErrorPage />,
                handle: {
                  crumb: () => (
                    <FormattedMessage
                      id="framework.version.edit"
                      defaultMessage="Edit Framework Version"
                      description="Edit Framework Version"
                    />
                  )
                }
              },
              {
                path: `${appStrings[langVal][AppRoute.EditStage]}/:stageId`,
                errorElement: <ErrorPage />,
                handle: {
                  crumb: () => (
                    <FormattedMessage
                      id="stage"
                      defaultMessage="Stage"
                      description="Stage"
                    />
                  )
                }
              }
            ]
          }
        ]
      },

      {
        path: `:frameworkId/${
          appStrings[langVal][AppRoute.CloneFrameworkVersion]
        }/:frameworkVersionId`,
        element: <CloneFrameworkVersion />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="version.clone.header"
              defaultMessage="Clone Framework Version"
              description="Clone Framework Version"
            />
          )
        }
      },
      {
        path: appStrings[langVal][AppRoute.CreateFramework],
        element: <CreateFramework />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="framework.create"
              defaultMessage="Create Framework"
              description="Create Framework"
            />
          )
        }
      }
    ]
  };

  return routes;
}
