import { useMutation, useQueryClient } from '@tanstack/react-query';

import { bookUserOnToCourseSession } from '../../services/api/course.service';
import { components } from '../../types/openapi/UserService';
import courseKeys from '../../query-keys/course-key-factory';
import sessionKeys from '../../query-keys/session-key-factory';

const useAddLearnerToSession = ({
  courseId,
  sessionId,
  displayLocale
}: {
  courseId: number;
  sessionId: number;
  displayLocale: string | undefined;
}) => {
  const queryClient = useQueryClient();

  const bookUserOnToCourseSessionMutation = useMutation(
    (selectedLearner: components['schemas']['UserDto']) => {
      if (selectedLearner.userId) {
        return bookUserOnToCourseSession(
          courseId,
          sessionId,
          selectedLearner.userId
        );
      }
      throw Error('no user ID for learner');
    },
    {
      onSuccess: () => {
        // invalidate learners on this session
        queryClient.invalidateQueries(
          sessionKeys.members(courseId, sessionId, displayLocale)
        );

        // invalidate learners on entire course
        queryClient.invalidateQueries(
          courseKeys.learnersOnCourseList(courseId, displayLocale)
        );
      }
    }
  );

  return {
    bookUserOnToCourseSessionMutation
  };
};

export default useAddLearnerToSession;
