import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import useGroup from '../../hooks/useGroup';
import { updateProviderGroup } from '../../services/api/provider.service';
import { components } from '../../types/openapi/ProviderService';
import groupKeys from '../../query-keys/group-key-factory';

function AddGroupLearnerBtn({
  providerId,
  groupId,
  displayLocale,
  learner
}: {
  providerId: number;
  groupId: number;
  displayLocale: string | undefined;
  learner: components['schemas']['UserDto'];
}) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { groupQuery } = useGroup({ providerId, groupId, displayLocale });

  const { mutate: addLearnerToGroup } = useMutation(
    (editedGroup: any) =>
      updateProviderGroup(
        providerId,
        Number(groupId),
        editedGroup,
        displayLocale
      ),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'group.learner.add.success',
            defaultMessage: 'This learner was successfully added to this group'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.group(providerId, Number(groupId), displayLocale)
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.learner.add.error',
            defaultMessage:
              'There was an error adding this learner to this group'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const handleAddLearnerToGroup = (
    selectedLearner: components['schemas']['UserDto']
  ) => {
    if (groupQuery.data) {
      const { name, description, userGroupAdmins } = groupQuery.data;
      const learnersIds = groupQuery.data?.userGroupMembers?.map(
        (groupMemeber) => groupMemeber.userId
      );
      const updatedLearners = learnersIds?.concat(selectedLearner.userId);
      const editedGroup = {
        groupName: name,
        groupDescription: description,
        groupId: Number(groupId),
        groupAdmins: userGroupAdmins?.map((admin) => admin.userId),
        learners: updatedLearners
      };
      addLearnerToGroup(editedGroup);
    }
  };
  return (
    <button
      type="button"
      className={`btn btn-outline-secondary ${
        groupQuery.data?.userGroupMembers &&
        groupQuery.data.userGroupMembers.find(
          (previouslyAddedLearner: components['schemas']['UserDto']) =>
            previouslyAddedLearner.userId === learner.userId
        ) &&
        'disabled'
      }`}
      onClick={() => handleAddLearnerToGroup(learner)}
    >
      <FormattedMessage id="add" defaultMessage="Add" description="Add" />
    </button>
  );
}

export default AddGroupLearnerBtn;
