/* eslint-disable no-nested-ternary */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
/* eslint-disable react/no-unused-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { PropsValue } from 'react-select';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../common';
// import FrameworkAttendancePercentage from './framework/FrameworkAttendancePercentage';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import useUser from '../../hooks/useUser';
import FrameworkStageObjectivesWrapper from './framework/FrameworkStageObjectivesWrapper';
import useCourseFrameworksForLearner from '../../hooks/courses/useCourseFrameworksForLearner';
import { components } from '../../types/openapi/CourseService';
import OnlineFrameworkProgress from '../offline/onlineModePages/OnlineFrameworkProgress';
// import FrameworkSessionsAttendance from './framework/FrameworkSessionsAttendance';

function ProgressOverview() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);

  const { profile } = profileContext;
  const intl = useIntl();

  const [frameworkVersion, setFrameworkVersion] =
    useState<components['schemas']['FrameworkVersionDto']>();
  const [frameworkVersionOptions, setFrameworkVersionOptions] =
    useState<PropsValue<any>>();

  if (!profile?.userId || !displayLocale) {
    return <GeneralError />;
  }

  const { courseFrameworksForLearnerListQuery } = useCourseFrameworksForLearner(
    {
      userId: profile?.userId,
      displayLocale
    }
  );
  const { userQuery } = useUser({ userId: profile?.userId, displayLocale });

  useEffect(() => {
    if (
      courseFrameworksForLearnerListQuery.data &&
      courseFrameworksForLearnerListQuery.data.length > 0
    ) {
      let idSet = false;

      // check if sso frameworkId has been populated, if so - make sure relevant course is selected
      if (!frameworkVersion) {
        if (
          localStorage.getItem('sso') &&
          localStorage.getItem('sso') === 'true'
        ) {
          const ssoFrameworkId = window.localStorage.getItem('sso_frameworkId');
          if (ssoFrameworkId && JSON.parse(ssoFrameworkId)) {
            const parsedFrameworkId = Number(JSON.parse(ssoFrameworkId));
            const matchingFramework =
              courseFrameworksForLearnerListQuery.data.find(
                (f) => f.frameworkId === parsedFrameworkId
              );
            if (matchingFramework) {
              setFrameworkVersion(matchingFramework);
              idSet = true;
            }
          }
        }
      }

      // set default course to first one in list if none selected eg on first load
      if (!idSet && !frameworkVersion) {
        const defaultFramework = courseFrameworksForLearnerListQuery.data[0];
        setFrameworkVersion(defaultFramework);
      }

      const learnerCourseOptions = courseFrameworksForLearnerListQuery.data.map(
        (_frameworkVersion: components['schemas']['FrameworkVersionDto']) => {
          return {
            label: `${_frameworkVersion.framework?.name} (${_frameworkVersion.name})`,
            value: _frameworkVersion.frameworkVersionId
          };
        }
      );
      if (learnerCourseOptions !== frameworkVersionOptions) {
        setFrameworkVersionOptions(learnerCourseOptions);
      }
    }
  }, [userQuery.data, courseFrameworksForLearnerListQuery.data]);

  const selectedOption: PropsValue<any> | null =
    frameworkVersionOptions?.find(
      (opt: PropsValue<any>) =>
        opt.value === Number(frameworkVersion?.frameworkVersionId)
    ) || null;

  const handleFrameworkChange = (selected: {
    value: number;
    label: string;
  }) => {
    if (
      !frameworkVersion ||
      selected!.value !== frameworkVersion.frameworkVersionId
    ) {
      const matchingFrameworkVersion =
        courseFrameworksForLearnerListQuery.data?.find(
          (f) => f.frameworkVersionId === selected!.value
        );
      setFrameworkVersion(matchingFrameworkVersion);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.progress.framework',
            defaultMessage: 'Framework progress'
          })}
        </title>
      </Helmet>
      <div className="row">
        <div className="col-12">
          <div className="card border-0 rounded-top-right-lg mb-3">
            <div className="card-header rounded-top-right-lg">
              <h1>
                <FormattedMessage
                  id="progress.framework"
                  defaultMessage="Framework Progress"
                  description="Framework Progress"
                />
              </h1>
            </div>
            {courseFrameworksForLearnerListQuery.data &&
              courseFrameworksForLearnerListQuery.data.length > 1 && (
                <div className="card-body">
                  {courseFrameworksForLearnerListQuery && (
                    <>
                      <label
                        className="form-label"
                        id="aria-label"
                        htmlFor="aria-example-input"
                      >
                        <FormattedMessage
                          id="progress.framework-select.label"
                          defaultMessage="Select a framework to view progress"
                        />
                      </label>{' '}
                      <Select
                        key={`prgoress_stage_select_key__${frameworkVersion?.frameworkVersionId}`}
                        aria-labelledby="aria-label"
                        inputId="frameworkVersionId"
                        options={frameworkVersionOptions}
                        defaultValue={null}
                        placeholder="Select Framework"
                        onChange={handleFrameworkChange}
                        value={selectedOption}
                      />
                    </>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-12">
          <div className="card border-0 rounded-top-right-lg mb-3">
            <div className="card-header border-0 rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="progress.stages"
                  defaultMessage="Stages"
                  description="Stages"
                />
              </h2>
            </div>
            <div className="card-body">
              {profile?.userId &&
              frameworkVersion &&
              frameworkVersion.frameworkVersionId ? (
                <OnlineFrameworkProgress
                  userId={profile.userId}
                  frameworkId={frameworkVersion.frameworkId!}
                  frameworkVersionId={frameworkVersion.frameworkVersionId}
                />
              ) : (
                <FormattedMessage
                  id="courses.none.progressOverview.stages"
                  defaultMessage="No stage information available."
                  description="No stage information"
                />
              )}
            </div>
          </div>
          {profile?.userId &&
            frameworkVersion &&
            frameworkVersion.frameworkVersionId && (
              <FrameworkStageObjectivesWrapper
                frameworkId={frameworkVersion.frameworkId!}
                frameworkVersionId={frameworkVersion.frameworkVersionId}
                learnerId={profile.userId}
                isEditable={false}
              />
            )}
        </div>
        {/* <div className="col-12 col-lg-6">
          <div className="card border-0 rounded-top-right-lg mb-3">
            <div className="card-header border-0 rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="learner.attendance"
                  defaultMessage="Attendance to date"
                  description="Attendance to date"
                />
              </h2>
            </div>
            <div className="card-body d-flex">
              {frameworkVersion && frameworkVersion.frameworkVersionId ? (
                <FrameworkAttendancePercentage
                  learnerId={profile.userId}
                  frameworkVersionId={frameworkVersion.frameworkVersionId}
                />
              ) : (
              <FormattedMessage
                id="courses.none.progressOverview.attendance"
                defaultMessage="No attendance information available."
                description="No attendance information"
              />
              )}
            </div>
          </div>
          <div className="card border-0 rounded-top-right-lg mb-3">
            <div className="card-header border-0 rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="sessions"
                  defaultMessage="Sessions"
                  description="Sessions"
                />
              </h2>
            </div>
            <div className="card-body">
              {frameworkVersion && frameworkVersion.frameworkVersionId ? (
                <FrameworkSessionsAttendance
                  frameworkVersionId={frameworkVersion.frameworkVersionId}
                />
              ) : (
                <FormattedMessage
                  id="courses.none.progressOverview.sessions"
                  defaultMessage="No sessions available."
                  description="No sessions"
                />
              )}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ProgressOverview;
