import { useQuery } from '@tanstack/react-query';

import { UserType } from '../const/user-type';
import usersListsKeys from '../query-keys/users-lists-key-factory';
import { getUsersForProviderCentreOfUserType } from '../services/api/user.service';

const useUsersForProviderCentre = ({
  providerId,
  centreId,
  userTypeId,
  pageIndex,
  pageSize,
  sorting,
  filtering,
  lang
}: {
  providerId: number | null | undefined;
  centreId?: number | null | undefined;
  userTypeId: UserType;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  filtering?: any;
  lang?: string;
}) => {
  const usersForProviderCentreQuery = useQuery({
    queryKey: usersListsKeys.list({
      providerId,
      centreId,
      userTypeId,
      pageIndex,
      pageSize,
      sorting,
      filtering,
      lang
    }),
    queryFn: () => {
      return getUsersForProviderCentreOfUserType(
        providerId,
        centreId,
        userTypeId,
        pageIndex,
        pageSize,
        sorting,
        filtering,
        lang
      );
    },
    refetchOnMount: true,
    enabled: !!providerId && !!centreId && userTypeId !== undefined,
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'Users not Found') return false;
      if (failureCount >= 3) return false;
      return false;
    }
  });

  return {
    usersForProviderCentreQuery
  };
};

export default useUsersForProviderCentre;
