import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppLanguage, AppRoute } from '../../../const';
import { appStrings } from '../../../modules/i18n';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { components } from '../../../types/openapi/UserService';
import { Loading, GeneralError } from '../../common';
import CardHeader from '../../ui/CardHeader';
import UserDetailsTable from '../../users/UserGeneralDetailsTable';
import AdminCentres from './AdminCentres';

function ViewAdminDashboard({
  providerId,
  adminId,
  isLoading,
  error,
  data
}: {
  providerId: number;
  adminId: number;
  isLoading: boolean;
  error: any;
  data: components['schemas']['UserDto'] | undefined;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  return (
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <div className="card">
          <CardHeader
          // TODO #5 - Edit details button
          >
            <h2>
              <FormattedMessage
                id="details"
                defaultMessage="Details"
                description="Details"
              />
            </h2>
          </CardHeader>

          <div className="card-body">
            {isLoading ? (
              <Loading />
            ) : error ? (
              (error as any)?.response.status === 401 ? (
                <FormattedMessage
                  id="users.error.unauthorised"
                  defaultMessage="You are not authorised to access this user"
                  description="You are not authorised to access this user"
                />
              ) : (
                <GeneralError />
              )
            ) : (
              data && <UserDetailsTable user={data} />
            )}
          </div>
        </div>
      </div>
      <AdminCentres
        providerId={providerId}
        userId={adminId}
        editCentresLink={`/${displayLocale}/${
          appStrings[displayLocale as AppLanguage][AppRoute.Users]
        }/${adminId}/${
          appStrings[displayLocale as AppLanguage][AppRoute.EditAdminCentres]
        }`}
      />
    </div>
  );
}

export default ViewAdminDashboard;
