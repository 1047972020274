import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { StorageId } from '../../const/storage-id';
import createSelectors from '../../modules/zustand/createSelectors';

/**
 * Hook for managing last synced info
 * This info is available in React Query
 * but does not persist once the query/mutation is garbage collected
 * and can be fiddly to get for mutliple queries
 * so this enables storing the specific information for instructors for longer
 */

type DataSyncInfo = {
  lastDownloaded: number | null;
  lastUploaded: number | null;
};

type DataSyncInfoActions = {
  // number should be a timestamp
  setLastDownloaded: (value: number) => void;
  setLastUploaded: (value: number) => void;

  reset: () => void;
};

const initialState: DataSyncInfo = {
  lastDownloaded: null,
  lastUploaded: null
};

const useDataSyncInfoStoreBase = create<
  DataSyncInfo & DataSyncInfoActions,
  [['zustand/persist', unknown]]
>(
  persist(
    (set) => ({
      ...initialState,
      setLastDownloaded: (value: number) => set({ lastDownloaded: value }),
      setLastUploaded: (value: number) => set({ lastUploaded: value }),
      reset: () => {
        set(initialState);
      }
    }),
    {
      name: StorageId.LAST_DATA_SYNC, // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage) // (optional) by default, 'localStorage' is used
    }
  )
);

const useDataSyncInfoStore = createSelectors(useDataSyncInfoStoreBase);

export default useDataSyncInfoStore;
