/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ColumnDef,
  HeaderGroup,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useParams } from 'react-router-dom';

import { components } from '../../../../../../types/openapi/UserService';
import { components as courseComponents } from '../../../../../../types/openapi/CourseService';
import LearnerAgeCell from './LearnerAgeCell';
import MedicalDetailsCell from './MedicalDetailsCell';
import LearnerCell from './LearnerCell';
import IndeterminateCheckbox from '../../../../../tables/IndeterminateCheckbox';
import ObjectivesCell from './ObjectivesCell';
import useProviderConfig from '../../../../../../hooks/useProviderConfig';
import LearnerNotesCell from './LearnerNotesCell';
import ObjectiveResourceLink from '../../../objectives/ObjectiveResourceLink';
import SortingButton from '../../../../../tables/SortingButton';
import { Loading } from '../../../../../common';
import { CourseUserStars } from '../../../../../../types/CourseUserStars.type';

const isUserDto = (
  value:
    | courseComponents['schemas']['UserDto']
    | courseComponents['schemas']['LearnerDto']
): value is courseComponents['schemas']['UserDto'] => {
  return (
    (value as courseComponents['schemas']['UserDto'])
      .userCourseSessionBookings !== undefined
  );
};

function InstructorLearnerTable({
  learners,
  session,
  course,
  handleMarkObjective,
  handleMarkAttended,
  handleMarkAbsent,
  setSessionIsDirty,
  objectiveSelect,
  stageId,
  objectiveId,
  starsForUsers,
  attendanceColumnId,
  objectivesColumnId,
  visibleColumnHeader,
  isSmallTablet
}: {
  learners:
    | courseComponents['schemas']['UserDto'][]
    | courseComponents['schemas']['LearnerDto'][];
  session:
    | courseComponents['schemas']['CourseSessionDto']
    | courseComponents['schemas']['SessionSlimDto'];
  course:
    | components['schemas']['CourseDto']
    | courseComponents['schemas']['CourseSlimDto']
    | undefined
    | null;
  handleMarkObjective: ({
    learnerId,
    stageId,
    objectiveId,
    numberOfStars
  }: {
    learnerId: number;
    stageId: number;
    objectiveId: number;
    numberOfStars: number;
  }) => any;
  handleMarkAttended: (learnerId: number) => any;
  handleMarkAbsent: (learnerId: number) => any;
  setSessionIsDirty: any;
  objectiveSelect?: JSX.Element;
  stageId: number | undefined;
  objectiveId: number | undefined;
  starsForUsers:
    | CourseUserStars[]
    | courseComponents['schemas']['StarsForUserDto'][]
    | undefined
    | null;
  attendanceColumnId: string;
  objectivesColumnId: string;
  visibleColumnHeader: string | undefined;
  isSmallTablet: boolean;
}) {
  const intl = useIntl();
  const isTouched = useRef(false);
  const { sessionId } = useParams();
  const { notesEnabled } = useProviderConfig({
    providerId: course?.providerId || 0
  });

  const [rowSelection, setRowSelection] = React.useState({});

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'username', desc: true }
  ]);

  // on initial load, set up attendances in table
  useEffect(() => {
    const startingSelection = learners.map((learner) => {
      if (isUserDto(learner))
        return (
          learner.userCourseSessionBookings &&
          learner.userCourseSessionBookings[0].attended
        );

      return learner.isAttended;
    });

    const selectionObj = { ...startingSelection };
    setRowSelection(selectionObj);
  }, []);

  // when selection changes update the map in the hook
  // rowSelection can be em
  useEffect(() => {
    if (rowSelection) {
      learners.forEach((learner, index) => {
        if (learner.userId) {
          if ((rowSelection as any)[index]) {
            handleMarkAttended(learner.userId);
          } else {
            handleMarkAbsent(learner.userId);
          }
        }
      });
    }
  }, [rowSelection]);

  const columns = useMemo<ColumnDef<components['schemas']['UserDto']>[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({
          id: 'name',
          defaultMessage: 'Name',
          description: 'Name'
        }),
        accessorKey: 'username',
        enableSorting: true,
        sortDescFirst: true,
        sortingFn: 'text',
        cell: ({ row }) => (
          <LearnerCell
            row={row}
            currentSessionStartTime={session.sessionStartTime}
          />
        )
      },
      {
        id: 'age',
        header: intl.formatMessage({
          id: 'age',
          defaultMessage: 'Age',
          description: 'Age'
        }),
        enableSorting: true,
        sortDescFirst: true,
        sortingFn: 'datetime',
        accessorKey: 'dateOfBirth',
        cell: ({ row }) => <LearnerAgeCell row={row} />
      },
      {
        id: 'info',
        cell: ({ row }) => <MedicalDetailsCell row={row} />
      },
      {
        id: attendanceColumnId,
        header: () => {
          return (
            <>
              <FormattedMessage
                id="attendance"
                defaultMessage="Attendance"
                description="Attendance"
              />
              <br />
              <div className="fw-normal text-center">
                {
                  Object.entries(rowSelection)
                    .map((el) => el[1])
                    .filter((el) => !!el).length
                }
                /{learners.length}
              </div>
            </>
          );
        },
        cell: ({ row }) => (
          <div className="d-flex pt-1">
            <IndeterminateCheckbox
              label={intl.formatMessage(
                {
                  id: 'attendance_checkbox_label',
                  defaultMessage: '{name} attendance',
                  description: 'Name attendance'
                },
                { name: (row as any).original.fullname }
              )}
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                onChange: row.getToggleSelectedHandler(),
                id: `attendance-${(row as any).original.userId}`,
                className: 'sp-checkbox__trigger visually-hidden'
              }}
            />
          </div>
        )
      },
      notesEnabled
        ? {
            id: 'notes',
            header: intl.formatMessage({
              id: 'notes',
              defaultMessage: 'Notes',
              description: 'Notes'
            }),
            cell: ({ row }) => <LearnerNotesCell row={row} />
          }
        : { id: 'none', header: '', cell: '' },
      {
        id: objectivesColumnId,
        header: () => {
          return (
            <>
              <div className="d-flex">
                {' '}
                <div className="flex-grow-1 text-center">
                  <FormattedMessage
                    id="objectives"
                    defaultMessage="Objectives"
                    description="Objectives"
                  />
                </div>
                {course && (
                  <div>
                    <ObjectiveResourceLink
                      course={course}
                      stageId={Number(stageId)}
                      objectiveId={Number(objectiveId)}
                    />
                  </div>
                )}
              </div>

              {objectiveSelect}
            </>
          );
        },

        cell: ({ row }) =>
          stageId && objectiveId && starsForUsers && course ? (
            <ObjectivesCell
              course={course}
              stageId={stageId}
              sessionId={Number(sessionId)}
              objectiveId={objectiveId}
              row={row}
              starsForUsers={starsForUsers}
              handleMarkObjective={handleMarkObjective}
            />
          ) : (
            <Loading className="refetching-table-cell" />
          )
      }
    ];
  }, [stageId, objectiveId, rowSelection, starsForUsers]);

  const table = useReactTable({
    data: learners as components['schemas']['UserDto'][],
    columns,
    state: {
      rowSelection,
      sorting
    },
    // enable row selection for all rows
    enableRowSelection: true,
    enableSorting: true,
    sortDescFirst: true,
    initialState: {
      columnVisibility: {
        attendance: visibleColumnHeader !== attendanceColumnId
      }
    },
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: (selectedRowsFn) => {
      setSessionIsDirty(true);
      if (!isTouched.current) {
        isTouched.current = true;
      }
      return setRowSelection(selectedRowsFn);
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    debugTable: false
  });

  useEffect(() => {
    const attendanceColumn = table
      .getAllLeafColumns()
      .find((column) => column.id === attendanceColumnId);
    const objectivesColumn = table
      .getAllLeafColumns()
      .find((column) => column.id === objectivesColumnId);
    if (visibleColumnHeader && isSmallTablet) {
      attendanceColumn?.toggleVisibility(
        visibleColumnHeader === attendanceColumnId
      );
      objectivesColumn?.toggleVisibility(
        visibleColumnHeader === objectivesColumnId
      );
    }
    if (!isSmallTablet) {
      attendanceColumn?.toggleVisibility(true);
      objectivesColumn?.toggleVisibility(true);
    }
  }, [visibleColumnHeader, isSmallTablet]);

  return (
    <div className="table-responsive-lg">
      <table className="table">
        <thead className="thead-responsive">
          {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`align-top ${
                      header.id === objectivesColumnId
                        ? 'border-start w-100'
                        : ''
                    } ${header.id === 'name' ? 'w-50' : ''}`}
                  >
                    {header.isPlaceholder ? null : header.column.getCanSort() ? (
                      <SortingButton
                        column={header.column}
                        flexRender={flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      />
                    ) : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: Row<any>) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={`${
                        cell.column.id === objectivesColumnId
                          ? 'border-start'
                          : ''
                      } align-middle text-center`}
                      data-title={cell.column.columnDef.header}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default InstructorLearnerTable;

InstructorLearnerTable.defaultProps = {
  objectiveSelect: null
};
