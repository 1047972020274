/* eslint-disable @typescript-eslint/no-shadow */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
/* eslint-disable no-param-reassign */

import { useQuery } from '@tanstack/react-query';
import { getUserCourseSessionBookingObjectives } from '../services/api/course.service';
import userObjectivesKeys from '../query-keys/user-objectives-key-factory';

// hook for managing user session objectives
const useUserSessionObjectives = ({
  courseId,
  sessionId,
  userId,
  displayLocale
}: {
  courseId: number | undefined;
  sessionId: number | undefined;
  userId: number | undefined;
  displayLocale: string | undefined;
}) => {
  if (!courseId || !sessionId || !userId) {
    throw Error('invalid id');
  }

  // the objectives achieved by a user in a particular session
  const userCourseSessionBookingObjectivesQuery = useQuery({
    queryKey: userObjectivesKeys.userCourseSessionObjectives(
      courseId,
      userId,
      sessionId,
      displayLocale
    ),
    queryFn: () =>
      getUserCourseSessionBookingObjectives(
        courseId,
        userId,
        sessionId,
        displayLocale
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  return {
    userCourseSessionBookingObjectivesQuery
  };
};

export default useUserSessionObjectives;
