/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { Column } from '@tanstack/react-table';

import DebouncedInput from './inputs/DebouncedInput';
import ProviderCentreFilter from './inputs/selects/ProviderCentreFilter';
import FrameworkFilter from './FrameworkFilter';
import UserTypeFilter from './inputs/selects/UserTypeFilter';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { UserType } from '../../../const/user-type';
import UserCentreFilter from './inputs/selects/UserCentreFilter';

function FilterInput({
  column,
  columnDef,
  filterValue,
  initialFilterValue,
  handleBasicInputChange,
  handleSportPassportIdInputChange,
  handleBasicNumericInputChange,
  handleDropdownInputChange
}: {
  column: Column<any, unknown>;
  columnDef: any;
  filterValue: string;
  initialFilterValue: string;
  handleBasicInputChange: (value: string) => void;
  handleSportPassportIdInputChange: (value: string) => void;
  handleBasicNumericInputChange: (value: string) => void;
  handleDropdownInputChange: (value: any) => void;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  switch (columnDef.filterType) {
    case 'basicInput':
      return (
        <>
          <DebouncedInput
            type="text"
            debounce={1000}
            value={filterValue || ''}
            onChange={handleBasicInputChange}
            placeholder="Search..."
            className="w-36 border shadow rounded"
            list={`${column.id}list`}
          />
          <div className="h-1" />
        </>
      );
      break;
    case 'sportPassportIdInput':
      return (
        <>
          <DebouncedInput
            type="text"
            debounce={1000}
            value={filterValue || ''}
            onChange={handleSportPassportIdInputChange}
            placeholder="Search..."
            className="w-36 border shadow rounded"
            list={`${column.id}list`}
          />
          <div className="h-1" />
        </>
      );
      break;
    case 'basicNumericInput':
      return (
        <>
          <DebouncedInput
            type="number"
            debounce={1000}
            value={filterValue || ''}
            onChange={handleBasicNumericInputChange}
            placeholder="Search..."
            className="w-36 border shadow rounded"
            list={`${column.id}list`}
          />
          <div className="h-1" />
        </>
      );
      break;
    case 'dropdown':
      switch (columnDef.id) {
        case 'centre':
          return profile?.userTypeId === UserType.INSTRUCTOR ||
            profile?.userTypeId === UserType.LEARNER
            ? profile && (
                <UserCentreFilter
                  profile={profile}
                  displayLocale={displayLocale}
                  columnDef={columnDef}
                  initialFilterValue={Number(initialFilterValue)}
                  handleDropdownInputChange={handleDropdownInputChange}
                />
              )
            : profile && (
                <ProviderCentreFilter
                  profile={profile}
                  displayLocale={displayLocale}
                  columnDef={columnDef}
                  initialFilterValue={Number(initialFilterValue)}
                  handleDropdownInputChange={handleDropdownInputChange}
                />
              );
          break;
        case 'framework':
          return (
            profile && (
              <FrameworkFilter
                profile={profile}
                displayLocale={displayLocale}
                columnDef={columnDef}
                initialFilterValue={Number(initialFilterValue)}
                handleDropdownInputChange={handleDropdownInputChange}
              />
            )
          );
          break;
        case 'userType':
          return (
            profile && (
              <UserTypeFilter
                columnDef={columnDef}
                initialFilterValue={Number(initialFilterValue)}
                handleDropdownInputChange={handleDropdownInputChange}
              />
            )
          );
          break;
        default:
          return <></>;
      }

      break;
    default:
      return <></>;
      break;
  }
}

export default FilterInput;
