/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { components } from '../../../types/openapi/ProviderService';
import Asterisk from '../../Asterisk';

function MultilingualCentreForm({
  onSubmit,
  defaultLangName,
  defaultLang,
  translationLangName,
  translation,
  formDisabled
}: {
  onSubmit: (value: {
    defaultLang: Pick<
      components['schemas']['CentreDto'],
      'name' | 'description' | 'address'
    >;
    translation: Pick<
      components['schemas']['CentreDto'],
      'name' | 'description' | 'address'
    >;
  }) => void;
  defaultLangName: string;
  defaultLang: Pick<
    components['schemas']['CentreDto'],
    'name' | 'description' | 'address'
  >;
  translationLangName: string;
  translation?: Pick<
    components['schemas']['CentreDto'],
    'name' | 'description' | 'address'
  >;
  formDisabled?: boolean;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: defaultLang.name || '',
      description: defaultLang.description || '',
      translationName: translation?.name || '',
      translationDescription: translation?.description || '',
      address: {
        buildingNumberName: defaultLang.address?.buildingNumberName || '',
        streetName: defaultLang.address?.streetName || '',
        townCity: defaultLang.address?.townCity || '',
        postalCode: defaultLang.address?.postalCode || '',
        county: defaultLang.address?.county || '',
        countryRegion: defaultLang.address?.countryRegion || ''
      },
      translationAddress: {
        buildingNumberName: translation?.address?.buildingNumberName || '',
        streetName: translation?.address?.streetName || '',
        townCity: translation?.address?.townCity || '',
        postalCode: translation?.address?.postalCode || '',
        county: translation?.address?.county || '',
        countryRegion: translation?.address?.countryRegion || ''
      }
    }
  });

  const handleTranslation = (values: any) => {
    const {
      name,
      description,
      address,
      translationName,
      translationDescription,
      translationAddress
    } = values;
    const updatedValues = {
      defaultLang: { name, description, address },
      translation: {
        name: translationName,
        description: translationDescription,
        address: translationAddress
      }
    };
    onSubmit(updatedValues);
  };

  return (
    <form
      className="d-flex flex-column"
      onSubmit={handleSubmit(handleTranslation)}
    >
      <div className="row">
        <div className="col-6">
          <h3>{defaultLangName}</h3>
          <div className="mb-3">
            <label htmlFor="name" className="form-label w-100">
              <Asterisk />
              <FormattedMessage
                id="form.name"
                defaultMessage="Name"
                description="Name"
              />
              <input
                id="name"
                type="text"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.name ? 'true' : 'false'}
                {...register('name', { required: true })}
              />
            </label>
            {errors.name?.type === 'required' && (
              <p className="invalid-feedback d-inline" role="alert">
                <FormattedMessage
                  id="form.name.required"
                  defaultMessage="Name is required"
                  description="Name is required"
                />
              </p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              <FormattedMessage
                id="form.description"
                defaultMessage="Description"
                description="Description"
              />{' '}
            </label>
            <textarea
              id="description"
              className="form-control"
              aria-invalid={errors.description ? 'true' : 'false'}
              {...register('description')}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="buildingNumberName" className="form-label">
              <FormattedMessage
                id="form.buildingNumberName"
                defaultMessage="Building Number/Name"
                description="Building Number/Name"
              />{' '}
            </label>
            <input
              type="text"
              id="address"
              className="form-control"
              autoComplete="off"
              aria-invalid={
                errors.address?.buildingNumberName ? 'true' : 'false'
              }
              {...register('address.buildingNumberName', {
                required: false,
                disabled: true
              })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="streetName" className="form-label">
              <FormattedMessage
                id="form.streetName"
                defaultMessage="Street Name"
                description="Street Name"
              />{' '}
            </label>
            <input
              type="text"
              id="address"
              className="form-control"
              autoComplete="off"
              aria-invalid={errors.address?.streetName ? 'true' : 'false'}
              {...register('address.streetName', { required: false })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="townCity" className="form-label">
              <FormattedMessage
                id="form.townCity"
                defaultMessage="Town/City"
                description="Town/City"
              />{' '}
            </label>
            <input
              type="text"
              id="address"
              className="form-control"
              autoComplete="off"
              aria-invalid={errors.address?.townCity ? 'true' : 'false'}
              {...register('address.townCity', { required: false })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="postalCode" className="form-label">
              <FormattedMessage
                id="form.postalCode"
                defaultMessage="Post Code"
                description="Post Code"
              />{' '}
            </label>
            <input
              type="text"
              id="address"
              className="form-control"
              autoComplete="off"
              aria-invalid={errors.address?.postalCode ? 'true' : 'false'}
              {...register('address.postalCode', {
                required: false,
                disabled: true
              })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="county" className="form-label">
              <FormattedMessage
                id="form.county"
                defaultMessage="County"
                description="County"
              />{' '}
            </label>
            <input
              type="text"
              id="address"
              className="form-control"
              autoComplete="off"
              aria-invalid={errors.address?.county ? 'true' : 'false'}
              {...register('address.county', { required: false })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="countryRegion" className="form-label">
              <FormattedMessage
                id="form.countryRegion"
                defaultMessage="Region"
                description="Region"
              />{' '}
            </label>
            <input
              type="text"
              id="address"
              className="form-control"
              autoComplete="off"
              aria-invalid={errors.address?.countryRegion ? 'true' : 'false'}
              {...register('address.countryRegion', { required: false })}
            />
          </div>
        </div>
        <div className="col-6">
          <h3>{translationLangName}</h3>
          <div className="mb-3">
            <label htmlFor="name" className="form-label w-100">
              <Asterisk />
              <FormattedMessage
                id="form.name"
                defaultMessage="Name"
                description="Name"
              />
              <input
                id="translationName"
                type="text"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.translationName ? 'true' : 'false'}
                {...register('translationName', { required: true })}
              />
            </label>
            {errors.translationName?.type === 'required' && (
              <p className="invalid-feedback d-inline" role="alert">
                <FormattedMessage
                  id="form.name.required"
                  defaultMessage="Name is required"
                  description="Name is required"
                />
              </p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="translationDescription" className="form-label">
              <FormattedMessage
                id="form.description"
                defaultMessage="Description"
                description="Description"
              />{' '}
            </label>
            <textarea
              id="translationDescription"
              className="form-control"
              aria-invalid={errors.description ? 'true' : 'false'}
              {...register('translationDescription')}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="translationBuildingNumberName"
              className="form-label"
            >
              <FormattedMessage
                id="form.buildingNumberName"
                defaultMessage="Building Number/Name"
                description="Building Number/Name"
              />{' '}
            </label>
            <input
              type="text"
              id="translationBuildingNumberName"
              className="form-control"
              autoComplete="off"
              aria-invalid={
                errors.address?.buildingNumberName ? 'true' : 'false'
              }
              {...register('translationAddress.buildingNumberName', {
                required: false,
                disabled: true
              })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="translationStreetName" className="form-label">
              <FormattedMessage
                id="form.streetName"
                defaultMessage="Street Name"
                description="Street Name"
              />{' '}
            </label>
            <input
              type="text"
              id="translationStreetName"
              className="form-control"
              autoComplete="off"
              aria-invalid={
                errors.translationAddress?.streetName ? 'true' : 'false'
              }
              {...register('translationAddress.streetName', {
                required: false
              })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="translationTownCity" className="form-label">
              <FormattedMessage
                id="form.townCity"
                defaultMessage="Town/City"
                description="Town/City"
              />{' '}
            </label>
            <input
              type="text"
              id="translationTownCity"
              className="form-control"
              autoComplete="off"
              aria-invalid={errors.address?.townCity ? 'true' : 'false'}
              {...register('translationAddress.townCity', { required: false })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="translationPostalCode" className="form-label">
              <FormattedMessage
                id="form.postalCode"
                defaultMessage="Post Code"
                description="Post Code"
              />{' '}
            </label>
            <input
              type="text"
              id="translationPostalCode"
              className="form-control"
              autoComplete="off"
              aria-invalid={
                errors.translationAddress?.postalCode ? 'true' : 'false'
              }
              {...register('translationAddress.postalCode', {
                required: false,
                disabled: true
              })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="translationCounty" className="form-label">
              <FormattedMessage
                id="form.county"
                defaultMessage="County"
                description="County"
              />{' '}
            </label>
            <input
              type="text"
              id="translationCounty"
              className="form-control"
              autoComplete="off"
              aria-invalid={
                errors.translationAddress?.county ? 'true' : 'false'
              }
              {...register('translationAddress.county', { required: false })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="translationCountryRegion" className="form-label">
              <FormattedMessage
                id="form.countryRegion"
                defaultMessage="Region"
                description="Region"
              />{' '}
            </label>
            <input
              type="text"
              id="translationCountryRegion"
              className="form-control"
              autoComplete="off"
              aria-invalid={
                errors.translationAddress?.countryRegion ? 'true' : 'false'
              }
              {...register('translationAddress.countryRegion', {
                required: false
              })}
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="btn btn-primary align-self-center"
        disabled={formDisabled || !isDirty || !isValid}
      >
        <FormattedMessage
          id="framework.translation.button"
          defaultMessage="Save translation"
          description="Save translation"
        />
      </button>
    </form>
  );
}

export default MultilingualCentreForm;

MultilingualCentreForm.defaultProps = {
  formDisabled: false,
  translation: {
    name: '',
    description: ''
  }
};
