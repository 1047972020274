/* eslint-disable no-nested-ternary */

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { AuthContext } from '../modules/auth/AuthProvider';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import { appStrings } from '../modules/i18n';
import GeneralError from '../components/common/GeneralError';
import { ProfileContext } from '../modules/profile/ProfileProvider';
import useUser from '../hooks/useUser';
import useUserPermissions from '../hooks/permissions/useUserPermissions';
import filterPermissions from '../utils/filterPermissions';
import { RoleData } from '../types/RoleData.type';
import RolesListWrapper from '../components/roles/RolesListWrapper';
import { AppLanguage, AppRoute } from '../const';

function RoleSelect() {
  // Role page after login - no role selected at this point
  // can't navigate away - should redirect here if user attempts to navigate without selecting a role
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const authContext = useContext(AuthContext);
  const { token, decodedUserFromToken } = authContext;

  const profileContext = useContext(ProfileContext);
  const { profile, setNewProfile } = profileContext;

  const intl = useIntl();

  const navigate = useNavigate();

  // redirect to home after select profile
  const redirectAfterChoose = `/${displayLocale?.toLowerCase()}`;
  const ssoRedirectAfterChoose = `/${displayLocale?.toLowerCase()}/${
    appStrings[displayLocale as AppLanguage][AppRoute.LearnerProgress]
  }`;

  const [userId, setUserId] = useState<number | undefined>(
    Number(decodedUserFromToken?.userId)
  );

  if (!userId) {
    console.error('no user id from token');
    return <GeneralError message="no user id" />;
  }

  const { userQuery } = useUser({
    userId,
    displayLocale
  });

  const { permissionsQuery } = useUserPermissions({
    userId,
    displayLocale
  });

  useEffect(() => {
    // if the user is logged in then get the userId from the token
    if (token && decodedUserFromToken) {
      if (!userId) {
        setUserId(Number(decodedUserFromToken.userId));
      }
    }

    // skip this page if there is only one profile and make it the default
    if (permissionsQuery.data) {
      const filteredPermissionsArr: RoleData | undefined = filterPermissions(
        permissionsQuery.data
      );

      const filteredPermissionsList =
        filteredPermissionsArr &&
        filteredPermissionsArr
          .map((el) => el.permissions)
          .flat()
          .filter((el) => !!el);

      if (filteredPermissionsList && filteredPermissionsList.length <= 1) {
        const newProfile = filteredPermissionsList[0];
        setNewProfile(newProfile);
      }
    }

    // take user to home page after select profile
    if (profile) {
      // if sso, send to progress screen
      if (
        localStorage.getItem('sso') &&
        localStorage.getItem('sso') === 'true'
      ) {
        navigate(ssoRedirectAfterChoose);
      } else {
        navigate(redirectAfterChoose);
      }
    }
  }, [
    profile,
    token,
    decodedUserFromToken,
    permissionsQuery.data,
    userQuery.data
  ]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.select_role',
            defaultMessage: 'Select role'
          })}
        </title>
      </Helmet>
      <RolesListWrapper
        userIsLoading={userQuery.isLoading}
        permissionsIsLoading={permissionsQuery.isLoading}
        userError={userQuery.error}
        permissionsError={permissionsQuery.error}
        userData={userQuery.data}
        permissionsData={permissionsQuery.data}
      />
    </>
  );
}

export default RoleSelect;
