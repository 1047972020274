import { components } from '../../types/openapi/CourseService';

// type guard for checking if a response has the correct value and type
const isObjective = (
  value: components['schemas']['ObjectiveDto'] | undefined
): value is components['schemas']['ObjectiveDto'] => {
  return !!value;
};

export default isObjective;
