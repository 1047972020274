/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useMemo } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ColumnDef,
  PaginationState,
  Row,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table';
import dayjs from 'dayjs';

import IndeterminateCheckbox from './IndeterminateCheckbox';
import { components } from '../../types/openapi/UserService';
import generateSportPassportId from '../../utils/generateSportPassportId';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import SelectablePaginatedTable from './SelectablePaginatedTable';

type TableUser = components['schemas']['UserDto'] & {
  isSelected: boolean;
};

function SelectableUsersTable({
  query,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  preselectedIds,
  queryParamsLocation,
  onRowSelect,
  onRowUnselect,
  handleRowSelectionChange,
  showColumns,
  id
}: {
  query: UseQueryResult<components['schemas']['PagedUserDto']>;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  queryParamsLocation: string;
  preselectedIds?: (number | undefined)[] | undefined | null;
  onRowSelect?: (row: Row<components['schemas']['UserDto']>) => void;
  onRowUnselect?: (row: Row<components['schemas']['UserDto']>) => void;
  handleRowSelectionChange?: (rowSelection: any) => void;
  showColumns: {
    showFullname: boolean;
    showEmail: boolean;
    showDob: boolean;
    showExternalId: boolean;
    showCentre: boolean;
  };
  id?: string;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const intl = useIntl();
  const { showFullname, showEmail, showDob, showExternalId, showCentre } =
    showColumns;
  const { data, error, isLoading } = query;
  const tableData: TableUser[] = data?.users
    ? data?.users?.map((user: components['schemas']['UserDto']) => {
        const isSelected = !!preselectedIds?.find(
          (preselectedUserId) => preselectedUserId === user.userId
        );
        // Add the related permission ID to the centre object for the row
        // Makes it easier to get the matched permission later as it is now on the row
        const newUser: TableUser = {
          ...user,
          isSelected
        };
        return newUser;
      })
    : [];

  const [rowSelection, setRowSelection] = React.useState({});

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  // on initial load, set up selection
  useEffect(() => {
    if (tableData) {
      const startingSelection: (boolean | null | undefined)[] = tableData?.map(
        (user) => !!user.userId && preselectedIds?.includes(user.userId)
      );
      const selectionObj = { ...startingSelection };
      setRowSelection(selectionObj);
    }
  }, []);

  // on changed to pagination, update selection
  useEffect(() => {
    if (tableData) {
      const startingSelection: (boolean | null | undefined)[] = tableData?.map(
        (user) => !!user.userId && preselectedIds?.includes(user.userId)
      );
      const selectionObj = { ...startingSelection };
      setRowSelection(selectionObj);
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, filtering]);

  const columns = useMemo<ColumnDef<components['schemas']['UserDto']>[]>(() => {
    return [
      // name, postcode, region
      // Let's make a column for selection
      {
        id: 'selection',
        header: () => {},
        cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              label={`user-${
                (row as Row<components['schemas']['UserDto']>).original.userId
              }`}
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event: any) => {
                  row.toggleSelected();
                  if ((event.target as any).checked) {
                    onRowSelect!(row);
                  } else {
                    onRowUnselect!(row);
                  }
                },
                id: `user-${
                  (row as Row<components['schemas']['UserDto']>).original.userId
                }`,
                className: 'sp-checkbox__trigger '
              }}
            />
          );
        },
        accessorKey: 'isSelected',
        enableSorting: false
      },
      {
        id: 'fullname',
        header: intl.formatMessage({
          id: 'Name',
          defaultMessage: 'Name',
          description: 'Name'
        }),
        accessorKey: 'fullname',
        sortDescFirst: true,
        filterType: 'basicInput'
      },

      {
        // ID must stay as username case sensitive to match with API filter option
        id: 'username',
        header: intl.formatMessage({
          id: 'username',
          defaultMessage: 'Username',
          description: 'Username'
        }),
        accessorKey: 'username',
        filterType: 'basicInput'
      },
      {
        id: 'email',
        header: intl.formatMessage({
          id: 'email',
          defaultMessage: 'Email',
          description: 'Email'
        }),
        accessorKey: 'email',
        filterType: 'basicInput'
      },
      {
        id: 'dateOfBirth',
        header: intl.formatMessage({
          id: 'dob',
          defaultMessage: 'Date of Birth',
          description: 'Date of Birth'
        }),
        cell: ({ row }) => {
          return row.original.dateOfBirth === '0001-01-01T00:00:00' ? (
            <span className="text-muted">
              <FormattedMessage
                id="dob.none"
                defaultMessage="No Date of Birth Provided"
                description="No Date of Birth Provided"
              />
            </span>
          ) : (
            dayjs(row.original.dateOfBirth).format('DD/MM/YYYY')
          );
        },
        accessorKey: 'dateOfBirth'
      },
      {
        id: 'sportPassportId',
        header: intl.formatMessage({
          id: 'user.sportPassportId',
          defaultMessage: 'Sport Passport Id',
          description: 'Sport Passport Id'
        }),
        cell: ({ row }) => {
          return generateSportPassportId(row.original.sportPassportId);
        },
        accessorKey: 'sportPassportId',
        enableSorting: false,
        filterType: 'sportPassportIdInput'
      },
      {
        id: 'externalId',
        header: intl.formatMessage({
          id: 'user.externalId',
          defaultMessage: 'External Id',
          description: 'External Id'
        }),
        cell: ({ row }: { row: any }) => {
          const learner = row.original;
          // Get first userPermission for the provider that has an external ID (will be same for other permissions of provider)
          const userPermission = learner.userProviders.find(
            (userProvider: components['schemas']['UserProvidersDto']) =>
              userProvider.userTypeId === UserType.LEARNER &&
              userProvider.providerId === profile?.providerId &&
              userProvider.externalId
          );
          if (!userPermission || !userPermission.externalId) {
            return <div className="text-center w-50">&ndash;</div>;
          }
          return userPermission.externalId;
        },
        accessorKey: 'sportPassportId',
        enableSorting: false,
        filterType: 'basicInput'
      },
      {
        id: 'centre',
        header: intl.formatMessage({
          id: 'centre',
          defaultMessage: 'Centre',
          description: 'Centre'
        }),
        cell: ({ row }: { row: any }) => {
          const learner = row.original;
          return (
            <span>
              {learner?.userProviders
                .map((up: any) => up.centre?.name ?? '')
                .filter((up: any) => !!up)
                .join(', ')}
            </span>
          );
        },
        accessorKey: 'centre',
        enableSorting: false,
        sortDescFirst: true,
        filterType: 'dropdown'
      },
      {
        id: 'actions',

        header: intl.formatMessage({
          id: 'actions',
          defaultMessage: 'Actions',
          description: 'Actions'
        }),
        filterType: undefined
      }
    ];
  }, [
    rowSelection,
    tableData,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    filtering
  ]);
  // Use the state and functions returned from useTable to build your UI
  const table = useReactTable({
    data: tableData ?? [],
    columns,
    initialState: {
      columnVisibility: {
        fullname: showFullname,
        email: showEmail,
        dateOfBirth: showDob,
        externalId: showExternalId,
        centre: showCentre
      }
    },
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfUsers
        ? Math.ceil(data.numberOfUsers / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      rowSelection,
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    // Selection
    enableRowSelection: true,
    onRowSelectionChange: (selectedRowsFn) => setRowSelection(selectedRowsFn),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false
  });

  useEffect(() => {
    if (handleRowSelectionChange)
      handleRowSelectionChange(
        table.getSelectedRowModel().flatRows.map((row) => row.original)
      );
  }, [rowSelection]);

  return (
    <SelectablePaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfUsers}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="userId"
      noneFoundMessage={
        <FormattedMessage
          id="users.error.none"
          defaultMessage="No users found"
          description="No users found"
        />
      }
      showFooter={(data?.numberOfUsers ?? 0) > 10}
      tableId={id}
    />
  );
}

export default SelectableUsersTable;

SelectableUsersTable.defaultProps = {
  onRowSelect: () => {},
  onRowUnselect: () => {},
  handleRowSelectionChange: () => {},
  preselectedIds: [],
  id: ''
};
