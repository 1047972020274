import { AppLanguage } from '../const';

const facilityKeys = {
  // everything to do with facilities
  all: (displayLocale: string | undefined) =>
    ['facilitys', displayLocale || AppLanguage.English] as const,
  // list of facilitys
  list: (displayLocale: string | undefined) =>
    [
      ...facilityKeys.all(displayLocale || AppLanguage.English),
      'list'
    ] as const,
  centreList: (
    centreId: number | undefined | null,
    displayLocale: string | undefined
  ) =>
    [
      ...facilityKeys.list(displayLocale || AppLanguage.English),
      'centre',
      centreId
    ] as const,
  facility: (facilityId: number, displayLocale: string | undefined) =>
    [
      ...facilityKeys.all(displayLocale || AppLanguage.English),
      facilityId
    ] as const
};

export default facilityKeys;
