import { useQuery } from '@tanstack/react-query';
import groupKeys from '../query-keys/group-key-factory';
import { getProviderGroup } from '../services/api/provider.service';

// hook for managing a single group
const useGroup = ({
  providerId,
  groupId,
  displayLocale
}: {
  providerId: number;
  groupId: number | null | undefined;
  displayLocale: string | undefined;
}) => {
  if (!groupId) {
    throw Error('invalid group id');
  }

  const groupQuery = useQuery({
    queryKey: groupKeys.group(providerId, groupId, displayLocale),
    queryFn: () => getProviderGroup(providerId, groupId, displayLocale),
    refetchOnMount: false,
    enabled: !!groupId
  });

  return {
    groupQuery
  };
};

export default useGroup;
