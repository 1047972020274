import { useQuery } from '@tanstack/react-query';
import courseKeys from '../../query-keys/course-key-factory';
import { getCoursesForProviderCentre } from '../../services/api/course.service';
import { FilteringObject } from '../../const/filtering-object';

// hook for managing courses
const useCoursesForCentre = ({
  providerId,
  centreId,
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  providerId: number;
  centreId: number;
  displayLocale: string | undefined;
  pageIndex: number | null | undefined;
  pageSize: number | null | undefined;
  sorting: any | null | undefined;
  rawFiltering: any | null | undefined;
}) => {
  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  // eslint-disable-next-line prefer-const
  let filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const coursesForProviderCentreQuery = useQuery({
    queryKey: courseKeys.providerCentreList(
      providerId,
      centreId,
      displayLocale,
      pageIndex,
      pageSize,
      sortField,
      sortDirection,
      filtering
    ),
    queryFn: () => {
      return getCoursesForProviderCentre(
        providerId,
        centreId,
        pageIndex,
        pageSize,
        sortField,
        sortDirection,
        filtering,
        displayLocale
      );
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!centreId && !!providerId
  });

  return {
    coursesForProviderCentreQuery
  };
};

export default useCoursesForCentre;
