import { useEffect, useState } from 'react';

// Gets the network connection status
// So while you can assume that the browser is offline when it returns a false value, you cannot assume that a true value necessarily means that the browser can access the internet.
// @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
function useNetworkStatus() {
  const [isNetworkOnline, setIsNetworkOnline] = useState<boolean>();
  useEffect(() => {
    const initialStatus = navigator.onLine;
    setIsNetworkOnline(initialStatus);

    function handleOnline() {
      setIsNetworkOnline(true);
    }
    function handleOffline() {
      setIsNetworkOnline(false);
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isNetworkOnline;
}

export default useNetworkStatus;
