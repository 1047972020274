/* eslint-disable @typescript-eslint/no-shadow */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React from 'react';
import { FormattedMessage } from 'react-intl';

function InputStar({
  userStarValue,
  inputStarValue,
  objectiveId,
  objectiveStageId,
  userCurrentStageId,
  userId,
  handleChange
}: {
  userStarValue: number;
  inputStarValue: number;
  objectiveId: number;
  objectiveStageId: number;
  userCurrentStageId: number | null;
  userId: number;
  handleChange: ({
    objectiveId,
    star
  }: {
    objectiveId: number;
    star: number;
  }) => void;
}) {
  const htmlId = `${userId}-${objectiveId}-${inputStarValue}`;

  const onClick = () => {
    if (handleChange) {
      handleChange({
        objectiveId,
        star: inputStarValue
      });
    } else {
      console.warn('no handler for change of input present');
    }
  };

  const isStarChecked = userStarValue === inputStarValue;

  const isStarEditable =
    !userCurrentStageId ||
    (userStarValue < inputStarValue &&
      objectiveStageId! < userCurrentStageId!) ||
    objectiveStageId! >= userCurrentStageId!;

  return (
    <span
      className={`star__input-wrapper ${
        userStarValue >= inputStarValue ? 'solid' : ''
      } ${isStarEditable ? 'editable' : ''}`}
      key={htmlId}
    >
      <input
        className="star__input"
        id={htmlId}
        type="checkbox"
        disabled={!isStarEditable}
        name={`stars${objectiveId}`}
        value={inputStarValue}
        checked={isStarChecked}
        onClick={onClick}
        role="radio"
        aria-checked={isStarChecked}
        // tabIndex={0}
      />
      <label htmlFor={htmlId} className="star__label">
        <span className="visually-hidden">
          <FormattedMessage
            id="star"
            defaultMessage="star {number}"
            values={{ number: inputStarValue }}
          />
        </span>
      </label>
    </span>
  );
}

export default InputStar;
