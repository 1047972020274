import React from 'react';
import { useParams } from 'react-router-dom';
import useOfflineDataDownload from '../../../hooks/offline/useOfflineDataDownload';
import { components } from '../../../types/openapi/CourseService';
import { GeneralError } from '../../common';
import InstructorSessionLearners from '../../courses/sessions/session/instructor/InstructorSessionLearners';

function OfflineInstructorSessionLearners({
  instructorId,
  learners
}: {
  instructorId: number;
  learners:
    | components['schemas']['LearnerDto'][]
    | components['schemas']['UserDto'][];
}) {
  const { sessionId } = useParams();
  const {
    offlineDataDownloadQuery,
    course,
    session,
    userStars,
    multipleStagesObjectives
  } = useOfflineDataDownload({
    sessionId: Number(sessionId),
    downloadSessionData: true
  });

  if (session && multipleStagesObjectives)
    return (
      <InstructorSessionLearners
        course={course}
        instructorId={instructorId}
        learners={learners}
        starsForUsers={userStars}
        frameworkVersionId={course?.frameworkVersionId}
        courseStages={course?.courseStages}
        session={session}
        learnerDataUpdatedAt={offlineDataDownloadQuery.dataUpdatedAt}
        multipleStagesObjectives={multipleStagesObjectives}
      />
    );
  return <GeneralError />;
}

export default OfflineInstructorSessionLearners;
