import React from 'react';

function ProgressCircle({
  displayStage,
  strokeDashOffset,
  numberOfStages,
  turns
}: {
  displayStage: number;
  strokeDashOffset: number | undefined;
  numberOfStages: number;
  turns: number;
}) {
  return (
    <div className="progress-circle">
      <div className="progress-circle__text">
        <p>
          Stage <span>{displayStage}</span>
        </p>
      </div>
      <svg
        width="200"
        height="200"
        viewBox="-25 -25 250 250"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth="20px"
          strokeDasharray="565.48px"
          strokeDashoffset="0"
        />
        <circle
          r="90"
          cx="100"
          cy="100"
          stroke="#e40a46"
          strokeWidth="20px"
          strokeDashoffset={`${strokeDashOffset}px`}
          fill="transparent"
          strokeDasharray="565.48px"
        />
      </svg>
      <div className="progress-circle__separators">
        {[...Array(numberOfStages).keys()].map((index: number) => (
          // <Separator turns={index * turns} style={style} />
          <div
            className="line-container"
            style={{
              transform: `rotate(${index * turns}turn)`
            }}
          >
            <div className="line" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProgressCircle;
