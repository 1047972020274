const isErrorTokenExpired = (errMessage: string) => {
  const expired =
    typeof errMessage === 'string'
      ? errMessage.includes('The token is expired') ||
        errMessage.includes('Unauthorised by the Auth Policy due to Exception')
      : false;
  return expired;
};

export default isErrorTokenExpired;
