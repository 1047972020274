import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faRightFromBracket,
  faCircleUser,
  faWarning,
  faLock
} from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import DropdownItem from 'react-bootstrap/DropdownItem';

import { AppLanguage, AppRoute } from '../../../const';
import { AuthContext } from '../../../modules/auth/AuthProvider';
import { appStrings } from '../../../modules/i18n';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import useUserPermissions from '../../../hooks/permissions/useUserPermissions';
import { OfflineContext } from '../../../modules/offline/OfflineProvider';
import AppMode from '../../../modules/offline/app-mode.enum';
import { UserType } from '../../../const/user-type';
import { ModalContext } from '../../../modules/modal/ModalProvider';
import useProviderConfig from '../../../hooks/useProviderConfig';

function UserMenu({
  handleClick,
  isOffCanvas
}: {
  handleClick?: () => void;
  isOffCanvas?: boolean;
}) {
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const authContext = useContext(AuthContext);
  const { decodedUserFromToken } = authContext;

  const { handleLogout } = authContext;
  const profileContext = useContext(ProfileContext);
  const { profile, clearProfile } = profileContext;
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  const queryCache = queryClient.getQueryCache();

  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const navigate = useNavigate();

  const langVal = (displayLocale as AppLanguage) || AppLanguage.English;

  const redirectAfterLogout = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Login]
  }`;

  const { filteredRoles } = useUserPermissions({
    userId: Number(decodedUserFromToken?.userId),
    displayLocale
  });

  const [isNonSSOLogin, setIsNonSSOLogin] = useState<boolean>();

  const {
    learnerNonSSOLogin,
    instructorNonSSOLogin,
    adminNonSSOLogin,
    sportManagerNonSSOLogin,
    providerConfigQuery
  } = useProviderConfig({
    providerId: profile?.providerId
  });

  useEffect(() => {
    if (profile?.userTypeId === UserType.LEARNER) {
      setIsNonSSOLogin(learnerNonSSOLogin);
    }
    if (profile?.userTypeId === UserType.INSTRUCTOR) {
      setIsNonSSOLogin(instructorNonSSOLogin);
    }
    if (profile?.userTypeId === UserType.ADMIN) {
      setIsNonSSOLogin(adminNonSSOLogin);
    }
    if (profile?.userTypeId === UserType.SPORT_MANAGER) {
      setIsNonSSOLogin(sportManagerNonSSOLogin);
    }
  }, [providerConfigQuery.data]);

  const hasMultipleRoles = filteredRoles && filteredRoles?.length > 1;
  const hasMultiplePermissions =
    filteredRoles &&
    filteredRoles?.length === 1 &&
    filteredRoles[0].permissions.length > 1;

  const logout = () => {
    if (handleLogout) {
      handleLogout();
      clearProfile();
      navigate(redirectAfterLogout);
    }
  };

  async function onLogoutConfirm() {
    // queries only are cleared for instructor - mutations can hag around and will hopefully get retried
    // IF they log in again with right credentials
    queryCache.clear();
    logout();
  }

  function openModal() {
    const header = (
      <span>
        <FontAwesomeIcon icon={faWarning} />
        {intl.formatMessage({
          id: 'instructor.sync.warning.modal.header',
          defaultMessage: 'You have unsynced data'
        })}
      </span>
    );
    const modalContent = intl.formatMessage({
      id: 'instructor.sync.warning.modal.body',
      defaultMessage:
        'If you logout without syncing you will lose your unsynced data. Are you sure you want to logout?'
    });

    const confirmText = intl.formatMessage({
      id: 'log_out.button',
      defaultMessage: 'Log out',
      description: 'Log out'
    });
    modal(modalContent, {
      confirm: () => onLogoutConfirm(),
      header,
      confirmText,
      classNames: { header: 'bg-warning' }
    });
  }

  // check if user has any data to sync before logout
  const preLogoutDataCheck = () => {
    if (profile?.userTypeId === UserType.INSTRUCTOR) {
      const mutations = mutationCache.getAll();
      // if still loading then check if actually want to logout
      // if error or success then allow logout
      const loadingMutations = mutations.filter(
        (mut) => mut.state.status === 'loading'
      );

      if (loadingMutations.length) {
        openModal();
      } else {
        logout();
      }
    } else {
      queryClient.clear();
      logout();
    }
  };

  const handleLogoutClick = () => {
    preLogoutDataCheck();
    if (handleClick) handleClick();
  };

  return (
    <ul className="list-unstyled">
      {profile && appMode !== AppMode.OFFLINE && (
        <>
          {' '}
          {(hasMultipleRoles || hasMultiplePermissions) && (
            <DropdownItem
              as="li"
              className="sidebar-item py-2 mt-4 mt-sm-1 border-top border-top-sm-0"
            >
              <NavLink
                className={`d-flex px-3 ${isOffCanvas && 'sidebar-item__link'}`}
                to={`${displayLocale}/${
                  appStrings[displayLocale as AppLanguage][AppRoute.RoleChange]
                }`}
                onClick={handleClick}
              >
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className="sidebar-item__icon me-1 fa-fw align-self-center pb-1  d-block"
                />

                <FormattedMessage
                  id="role.change"
                  defaultMessage="Change Role"
                  description="Change Role"
                />
              </NavLink>
            </DropdownItem>
          )}
          <DropdownItem as="li" className="sidebar-item py-2 ">
            <NavLink
              className={`d-flex px-3 ${isOffCanvas && 'sidebar-item__link'}`}
              to={`${displayLocale}/${
                appStrings[displayLocale as AppLanguage][AppRoute.Profile]
              }`}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faUser}
                className="sidebar-item__icon me-1 fa-fw align-self-center pb-1  d-block"
              />

              <FormattedMessage
                id="profile"
                defaultMessage="Profile"
                description="Profile"
              />
            </NavLink>
          </DropdownItem>
          {isNonSSOLogin && (
            <DropdownItem as="li" className="sidebar-item py-2 ">
              <NavLink
                className={`d-flex px-3 ${isOffCanvas && 'sidebar-item__link'}`}
                to={`${displayLocale}/${
                  appStrings[displayLocale as AppLanguage][
                    AppRoute.ChangePassword
                  ]
                }`}
                onClick={handleClick}
              >
                <FontAwesomeIcon
                  icon={faLock}
                  className="sidebar-item__icon me-1 fa-fw align-self-center pb-1  d-block"
                />
                <FormattedMessage
                  id="passwork.change"
                  defaultMessage="Change Password"
                  description="Change Password"
                />
              </NavLink>
            </DropdownItem>
          )}
        </>
      )}

      <li className="sidebar-item py-2">
        <button
          type="button"
          className="btn p-0 w-100 d-flex px-3 "
          onClick={handleLogoutClick}
        >
          <FontAwesomeIcon
            icon={faRightFromBracket}
            className="sidebar-item__icon me-1 fa-fw align-self-center pb-1 d-block"
          />
          <FormattedMessage
            id="logout"
            defaultMessage="Logout"
            description="logout"
          />{' '}
        </button>
      </li>
    </ul>
  );
}

export default UserMenu;

UserMenu.defaultProps = {
  handleClick: () => {},
  isOffCanvas: false
};
