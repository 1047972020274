const isRefreshErrorTokenExpired = (error: any) => {
  const msg = error.response?.data?.debugMessage;

  const expired =
    typeof msg === 'string' ? msg?.includes('Refresh Token Expired') : false;

  return expired;
};

export default isRefreshErrorTokenExpired;
