/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { UserType } from '../../../const/user-type';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { FilteringQueryParams } from '../../../const/filtering-query-params';
import EditUserCentres from '../EditUserCentres';
import useStoreTableFilters from '../../../hooks/state-management/useStoreTableFilters';
import useProviderCentresForUser from '../../../hooks/centres/useProviderCentresForUser';

function EditAdminCentres() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const { userId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    filtering,
    setFiltering
  } = useStoreTableFilters({
    queryParamsLocation: FilteringQueryParams.AdminCentres
  });

  // Selected centres
  const { centreIds } = useProviderCentresForUser({
    providerId: profile?.providerId,
    userId: Number(userId),
    userTypeId: UserType.ADMIN,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <EditUserCentres
      userId={Number(userId)}
      providerId={profile?.providerId}
      userTypeId={UserType.ADMIN}
      queryParamsLocation={FilteringQueryParams.AdminCentres}
      centreIds={centreIds}
      pagination={pagination}
      sorting={sorting}
      filtering={filtering}
      headingText={
        <FormattedMessage
          id="admin.edit_admin_centres"
          defaultMessage="Edit Admin Centres"
          description="Edit Admin Centres"
        />
      }
      setPagination={setPagination}
      setSorting={setSorting}
      setFiltering={setFiltering}
    />
  );
}

export default EditAdminCentres;
