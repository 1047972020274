/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import { updateProviderGroup } from '../../services/api/provider.service';
import { components } from '../../types/openapi/ProviderService';
import Loading from '../common/Loading';
import GroupForm from './GroupForm';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import useGroup from '../../hooks/useGroup';
import groupKeys from '../../query-keys/group-key-factory';

function EditGroup() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const intl = useIntl();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const queryClient = useQueryClient();

  const navLinkGroup = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Groups]
  }/${groupId}`;

  if (!profile?.providerId) {
    return <GeneralError message="no provider id" />;
  }

  const { groupQuery } = useGroup({
    providerId: profile.providerId,
    groupId: Number(groupId),
    displayLocale
  });

  const { mutate: editGroup, isLoading } = useMutation(
    (editedGroup: components['schemas']['GroupDto']) =>
      updateProviderGroup(
        profile.providerId,
        Number(groupId),
        editedGroup,
        displayLocale
      ),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'group.edit.success',
            defaultMessage: 'Group has been updated'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.group(
            profile.providerId,
            Number(groupId),
            displayLocale
          )
        });
        navigate(navLinkGroup);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.edit.error',
            defaultMessage: 'There was an error editing this group'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (group: components['schemas']['GroupDto']) => {
    const { name, description } = group;
    if (name && description && profile.providerId) {
      const newGroup: any = {
        groupName: name,
        groupDescription: description,
        groupId: Number(groupId),
        groupAdmins: groupQuery.data?.userGroupAdmins?.map(
          (admin) => admin.userId
        ),
        learners: groupQuery.data?.userGroupMembers?.map(
          (learner) => learner.userId
        )
      };
      editGroup(newGroup);
    }
  };
  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.group.edit',
            defaultMessage: 'Edit group'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="group.edit.header"
            defaultMessage="Edit Group"
            description="Edit Group"
          />
        </h2>
      </div>
      <div className="card-body">
        {groupQuery.isLoading ? (
          <Loading />
        ) : groupQuery.error ? (
          <GeneralError />
        ) : groupQuery.data ? (
          <GroupForm
            isEdit
            onSubmit={onSubmit}
            submitButtonMessage={
              <FormattedMessage
                id="group.save_changes.button"
                defaultMessage="Save changes"
                description="Save changes"
              />
            }
            name={groupQuery.data.name}
            description={groupQuery.data.description}
            formDisabled={isLoading}
            queryParamsLocation={FilteringQueryParams.GroupLearners}
            pagination={{ pageIndex: 0, pageSize: 10 }}
            sorting={[]}
            filtering={[]}
          />
        ) : (
          <FormattedMessage
            id="group.error.none"
            defaultMessage="No group found"
            description="No group found"
          />
        )}
      </div>
    </div>
  );
}

export default EditGroup;
