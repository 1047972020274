import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate, faSlash, faWifi } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import SyncInfo from '../offline/SyncInfo';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import Loading from '../common/Loading';
import InstructorAgendaWrapper from '../timetable/InstructorAgendaWrapper';
import sessionKeys from '../../query-keys/session-key-factory';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import PrefetchStars from '../offline/PrefetchStars';

function InstructorDashboard() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const queryClient = useQueryClient();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const isNetworkOnline = useNetworkStatus();

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  const refreshTodaysAgenda = () => {
    queryClient.invalidateQueries({
      queryKey: sessionKeys.todayInstructor(profile.userId, displayLocale)
    });
  };

  return (
    <>
      {/* Prefetching stars so cached for offline mode */}
      <PrefetchStars />
      <div className="dashboard">
        <div className="card mh-vh-70 border-0 rounded-top-right-lg">
          <div className="card-header rounded-top-right-lg d-flex justify-content-between">
            <h2>
              <FormattedMessage
                id="timetable.todays_agenda"
                defaultMessage="Today's Agenda"
                description="Today's Agenda"
              />
            </h2>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={refreshTodaysAgenda}
              disabled={appMode === AppMode.OFFLINE}
            >
              <FormattedMessage
                id="refresh"
                defaultMessage="Refresh"
                description="Refresh"
              />
              <FontAwesomeIcon icon={faRotate} className="ms-2" />
            </button>
          </div>
          <div className="card-body">
            <InstructorAgendaWrapper />
            <div className="d-flex justify-content-center mt-3">
              <Link
                type="button"
                className="btn btn-chevron w-50"
                to={
                  appStrings[displayLocale as AppLanguage][AppRoute.Timetable]
                }
              >
                <FormattedMessage
                  id="timetable.button.learner"
                  defaultMessage="My timetable"
                  description="My timetable"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="card border-0 rounded-top-right-lg">
          <div className="card-header d-flex">
            <div className="flex-grow-1">
              <h2>
                <FormattedMessage
                  id="offline.sync-info"
                  defaultMessage="Sync info"
                  description="Sync info"
                />
              </h2>
            </div>
            <div className="mt-2">
              {isNetworkOnline ? (
                <>
                  <FontAwesomeIcon icon={faWifi} className="me-1" />
                  <FormattedMessage
                    id="network.detected"
                    defaultMessage="Network detected"
                  />
                </>
              ) : (
                <>
                  <span className="fa-stack">
                    <FontAwesomeIcon
                      icon={faSlash}
                      className="me-1 fa-stack-1x"
                    />
                    <FontAwesomeIcon
                      icon={faWifi}
                      className="me-1 fa-stack-1x"
                    />
                  </span>
                  <FormattedMessage
                    id="network.detected.not"
                    defaultMessage="Network not detected"
                  />
                </>
              )}
            </div>
          </div>
          <div className="card-body">
            <SyncInfo />
          </div>
        </div>
      </div>
    </>
  );
}

export default InstructorDashboard;
