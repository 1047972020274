import { useQuery } from '@tanstack/react-query';
import { AppLanguage } from '../const';
import facilityKeys from '../query-keys/facility-key-factory';
import { getProviderCentreFacilities } from '../services/api/provider.service';

// hook for managing facilities
const useFacilities = ({
  providerId,
  centreId,
  displayLocale = AppLanguage.English
}: {
  providerId: number | null | undefined;
  centreId: number | null | undefined;
  displayLocale: string | undefined;
}) => {
  const facilitiesQuery = useQuery({
    queryKey: facilityKeys.centreList(centreId, displayLocale),
    queryFn: () =>
      getProviderCentreFacilities(providerId, centreId!, displayLocale),
    refetchOnMount: `always`,
    enabled: !!centreId
  });

  return {
    facilitiesQuery
  };
};

export default useFacilities;
