import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Event } from 'react-big-calendar';

import CourseDetailsTableShort from '../courses/CourseDetailsTableShort';
import CourseSessionDetailsTable from '../courses/sessions/CourseSessionDetailsTable';

function SessionModalContent({
  event,
  priceOfCourse
}: {
  event: Event;
  priceOfCourse: string | null | undefined;
}) {
  const numberOfSpaces =
    (event as any).session.course.totalNumberOfPeople -
    (event as any).session.currentLearnersBooked;
  return (
    <>
      <div className="card mb-2">
        <div className="card-header">
          <h2>
            <FormattedMessage
              id="session.details"
              defaultMessage="Session Details"
              description="Session Details"
            />
          </h2>
          <div className="d-flex gap-2">
            <span className="badge bg-primary">
              {(event as any).bookable && !(event as any).fullyBooked && (
                <FormattedMessage
                  id="course.spaces_left"
                  defaultMessage="{numberOfSpaces, plural, =0 {no places left} one {# space left} other {# spaces left}}"
                  description="{numberOfSpaces} spaces left"
                  values={{ numberOfSpaces }}
                />
              )}
            </span>
            <span className="badge bg-secondary">
              {(event as any).fullyBooked && (
                <FormattedMessage
                  id="course.full"
                  defaultMessage="Fully Booked"
                  description="Fully Booked"
                />
              )}
            </span>
            {priceOfCourse && (
              <span className="badge bg-primary">{priceOfCourse}</span>
            )}
          </div>
        </div>
        <div className="card-body">
          <CourseSessionDetailsTable session={(event as any).session} />
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h2>
            {' '}
            <FormattedMessage
              id="course"
              defaultMessage="Course"
              description="Course"
            />
          </h2>
        </div>
        <div className="card-body">
          <CourseDetailsTableShort
            course={(event as any).session.course}
            courseStages={(event as any).session.course.courseStages}
            showCentreName={false}
          />
        </div>
      </div>
    </>
  );
}

export default SessionModalContent;
