import { isFramework } from '../../../utils/type-guards';
import { SPCalendarEvent } from '../SPCalendarEvent.type';

const uniqueFrameworks = (allSessions: SPCalendarEvent[]) => {
  return allSessions
    .map((event) => {
      return event.session.course?.frameworkVersion?.framework;
    })
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t?.frameworkId === value?.frameworkId)
    )
    .filter(isFramework);
};

export default uniqueFrameworks;
