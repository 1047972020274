import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { components } from '../../types/openapi/UserService';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { UserType } from '../../const/user-type';
import { AppRoute } from '../../const';
import localiseRoutePath from '../../utils/localiseRoutePath';
import instructorKeys from '../../query-keys/instructor-key-factory';
import useAddUserPermissions from '../../hooks/permissions/useAddUserPermissions';

function AssignInstructorToCentreButton({
  user,
  providerId,
  centreId
}: {
  user: components['schemas']['UserDto'];
  providerId: number;
  centreId: number;
}) {
  const { userId } = user;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const navLinkCentre = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Centres
  )}/${centreId}/`;

  const { addPermissions } = useAddUserPermissions();

  const onAssignInstructor = () => {
    if (!userId) {
      return;
    }
    const updatedPermission: components['schemas']['UserProvidersDto'] = {
      userId,
      providerId,
      userTypeId: UserType.INSTRUCTOR,
      centreId: Number(centreId)
    };

    addPermissions.mutate(
      { userId, permissions: [updatedPermission] },
      {
        onSuccess: () => {
          toast.success(
            intl.formatMessage({
              id: 'instructor.assigned.success',
              defaultMessage:
                'This Instructor was successfully assigned to the centre'
            }),
            { delay: 200 }
          );
          // invalidate list of instructors for centre
          queryClient.invalidateQueries({
            queryKey: instructorKeys.providerCentreList(
              providerId!,
              centreId,
              displayLocale
            )
          });

          navigate(navLinkCentre);
        },

        onError: () => {
          toast.error(
            intl.formatMessage({
              id: 'instructor.assigned.error',
              defaultMessage:
                'There was an error assigning this instructor to the centre'
            }),
            { delay: 200 }
          );
        }
      }
    );

    // addInstructorCentre(updatedPermission);
  };

  return (
    <button
      className="btn btn-outline-secondary"
      type="button"
      onClick={() => onAssignInstructor()}
    >
      <FormattedMessage
        id="instructor.assign"
        defaultMessage="Assign"
        description="Assign instructor to centre"
      />
    </button>
  );
}

export default AssignInstructorToCentreButton;
