import React from 'react';
import { PropsValue } from 'react-select';

import { components } from '../../../../../types/openapi/UserService';
import SelectFilter from './SelectFilter';
import useCentresForUser from '../../../../../hooks/centres/useCentresForUser';

function UserCentreFilter({
  profile,
  displayLocale,
  columnDef,
  initialFilterValue,
  handleDropdownInputChange
}: {
  profile: components['schemas']['UserProvidersDto'];
  displayLocale: string;
  columnDef: any;
  initialFilterValue: number;
  handleDropdownInputChange: (selectedOption: any) => void;
}) {
  const { centresForUserListQuery } = useCentresForUser({
    userId: profile.userId,
    userTypeId: profile.userTypeId,
    displayLocale
  });

  const options: PropsValue<any> = centresForUserListQuery.data
    ?.map((centre) => {
      return {
        value: centre.centreId ?? 0,
        label: centre.name ?? '-'
      };
    })
    .sort((a: { label: string }, b: { label: string }) =>
      a.label.localeCompare(b.label)
    );

  return (
    <SelectFilter
      columnDef={columnDef}
      initialFilterValue={initialFilterValue}
      handleDropdownInputChange={handleDropdownInputChange}
      options={options}
      filteringAccessorKey="centreName"
      columnName="Centre"
      menuPositionRight={0}
    />
  );
}

export default UserCentreFilter;
