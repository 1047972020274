/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

import { components } from '../types/openapi/UserService';
import { updateUser } from '../services/api/user.service';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import UserForm from '../components/users/forms/UserForm';
import Loading from '../components/common/Loading';
import { AuthContext } from '../modules/auth/AuthProvider';
import { UserType } from '../const/user-type';
import useUser from '../hooks/useUser';
import useUserPermissions from '../hooks/permissions/useUserPermissions';
import useProviderConfig from '../hooks/useProviderConfig';
import { ProfileContext } from '../modules/profile/ProfileProvider';
import Unauthorised from './Unauthorised';
import { GeneralError } from '../components/common';
import userKeys from '../query-keys/user-key-factory';

function EditProfile() {
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const authContext = useContext(AuthContext);
  const { decodedUserFromToken } = authContext;
  const userId: number = Number(decodedUserFromToken?.userId);
  const queryClient = useQueryClient();

  const { userQuery } = useUser({
    userId,
    displayLocale
  });

  const { permissionsQuery } = useUserPermissions({
    userId,
    displayLocale
  });

  if (!profile) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'profile.error.please_select',
          defaultMessage: 'Please select a profile'
        })}
      />
    );
  }

  const { providerId } = profile;

  if (!providerId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'error.no_provider_id',
          defaultMessage: 'No valid provider id provided'
        })}
      />
    );
  }

  const { profileEditingEnabled } = useProviderConfig({
    providerId
  });

  if (!profileEditingEnabled) {
    return <Unauthorised />;
  }
  const { mutate: editUser, isLoading } = useMutation(
    (userUpdated: components['schemas']['UserDto']) => updateUser(userUpdated),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'user.edit.success',
            defaultMessage: 'You have successfully edited your account details'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: userKeys.user(userId, displayLocale)
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'self.edit.error',
            defaultMessage: 'There was an error editing your account details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (userUpdated: components['schemas']['UserDto']) => {
    const editedUser: components['schemas']['UserDto'] = {
      firstName: userUpdated.firstName,
      surname: userUpdated.surname,
      email: userUpdated.email,
      telephone: userUpdated.telephone,
      genderId: Number(userUpdated.genderId),
      emergencyContactName: userUpdated.emergencyContactName,
      emergencyPhone1: userUpdated.emergencyPhone1,
      emergencyPhone2: userUpdated.emergencyPhone2,
      medicalConditions: userUpdated.medicalConditions,
      username: userQuery.data?.username,
      joinDate: userQuery.data?.joinDate,
      dateOfBirth: userUpdated.dateOfBirth,
      title: userQuery.data?.title,
      authReference: userQuery.data?.authReference,
      sportPassportId: userQuery.data?.sportPassportId,
      marketingOptOut: userQuery.data?.marketingOptOut,
      isClassifiedDisabled: userQuery.data?.isClassifiedDisabled,
      isDeleted: userQuery.data?.isDeleted,
      mobile: userQuery.data?.mobile,
      hide: userQuery.data?.hide,
      schoolYear: userQuery.data?.schoolYear,
      authId: userQuery.data?.authId,
      urlSlug: userQuery.data?.urlSlug,
      photo: userQuery.data?.photo,
      avatar: userQuery.data?.avatar,
      matchScore: userQuery.data?.matchScore,
      fullname: userQuery.data?.fullname,
      instructorDetails: userQuery.data?.instructorDetails,
      instructorNeedToKnow: userQuery.data?.instructorNeedToKnow,
      userProviders: userQuery.data?.userProviders,
      userCourseSessionBookings: userQuery.data?.userCourseSessionBookings,
      userCourseSessionObjectives: userQuery.data?.userCourseSessionObjectives,
      userFrameworkVersionStages: userQuery.data?.userFrameworkVersionStages,
      userAssociations: userQuery.data?.userAssociations,
      preferredLanguageId: userQuery.data?.preferredLanguageId,
      userId: userQuery.data?.userId,
      addressId: userQuery.data?.addressId
    };
    editUser(editedUser);
  };

  return (
    <div className="">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.profile.edit',
            defaultMessage: 'Edit profile'
          })}
        </title>
      </Helmet>
      <div className="card rounded-0 border-0">
        <div className="card-header">
          <h1>
            <FormattedMessage
              id="profile.edit"
              defaultMessage="Edit Profile"
              description="Edit Profile"
            />
          </h1>
        </div>
        <div className="card-body">
          {userQuery.isFetching ? (
            <Loading />
          ) : userQuery.error ? (
            (userQuery.error as any)?.response.status === 401 ? (
              <FormattedMessage
                id="users.error.unauthorised"
                defaultMessage="You are not authorised to access this user"
                description="You are not authorised to access this user"
              />
            ) : (
              <GeneralError />
            )
          ) : (
            userQuery.data && (
              <UserForm
                onSubmit={onSubmit}
                submitButtonMessage={
                  <FormattedMessage
                    id="save_changes"
                    defaultMessage="Save changes"
                    description="Save changes"
                  />
                }
                formDisabled={isLoading}
                email={userQuery?.data?.email || ''}
                telephone={userQuery?.data?.telephone || ''}
                firstName={userQuery?.data?.firstName || ''}
                surname={userQuery.data?.surname || ''}
                emergencyContactName={
                  userQuery.data?.emergencyContactName || ''
                }
                emergencyPhone1={userQuery.data?.emergencyPhone1 || ''}
                emergencyPhone2={userQuery.data?.emergencyPhone2 || ''}
                medicalConditions={userQuery.data?.medicalConditions || ''}
                genderId={userQuery.data.genderId || undefined}
                dateOfBirth={dayjs(userQuery.data?.dateOfBirth).format(
                  'YYYY-MM-DD'
                )}
                hasEmergencyContactDetails={permissionsQuery.data?.some(
                  (_profile) => _profile.userTypeId === UserType.LEARNER
                )}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
export default EditProfile;
