import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import ManageUsersImpersonation from '../components/impersonate-user/ManageUsersImpersonation';
import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import ProvidersList from '../components/providers/ProvidersList';

export default function userImpersonationRoutes(
  lang: AppLanguage | string | undefined
) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.UserImpersonation],
    element: (
      <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="user.impersonation"
          defaultMessage="User impersonation"
          description="User impersonation"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ProvidersList />,
        errorElement: <ErrorPage />
      },
      {
        path: ':providerId',
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="provider.users"
              defaultMessage="All provider users"
              description="All provider users"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ManageUsersImpersonation />,
            errorElement: <ErrorPage />
          }
        ]
      }
    ]
  };
  return routes;
}
