/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';
import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import { bookGroupOnToCourse } from '../../services/api/course.service';
import { components } from '../../types/openapi/ProviderService';
import GroupsTable from '../groups/GroupsTable';
import Loading from '../common/Loading';
import CourseDetailsTable from './CourseDetailsTable';
import useCourse from '../../hooks/course/useCourse';
import courseKeys from '../../query-keys/course-key-factory';
import useGroups from '../../hooks/useGroups';
import { FilteringQueryParams } from '../../const/filtering-query-params';

function AddGroupToCourse() {
  const { courseId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const intl = useIntl();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const navLinkCourse = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Courses]
  }/${courseId}`;
  const [availableSpaces, setAvailableSpaces] = useState<number>(0);

  const queryParamsLocation = FilteringQueryParams.Groups;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  const { courseQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const { learnersForCourseQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const { groupsListPagedQuery } = useGroups({
    providerId: profile?.providerId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: bookNewGroupOnToCourse, isLoading } = useMutation(
    (group: components['schemas']['GroupDto']) =>
      bookGroupOnToCourse(Number(courseId), group.groupId!),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'course.group.booking.success',
            defaultMessage:
              'This group has successfully been booked on the course'
          }),
          { delay: 200 }
        );
        navigate(navLinkCourse);
        queryClient.invalidateQueries({
          queryKey: courseKeys.course(Number(courseId), displayLocale)
        });
        queryClient.invalidateQueries({
          queryKey: courseKeys.learnersOnCourseList(
            Number(courseId),
            displayLocale
          )
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'course.group.booking.error',
            defaultMessage:
              'There was an error booking this group onto this course'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const handleAddGroupToCourse = (group: components['schemas']['GroupDto']) => {
    bookNewGroupOnToCourse(group);
  };

  useEffect(() => {
    if (
      courseQuery.data &&
      learnersForCourseQuery.data &&
      courseQuery.data.totalNumberOfPeople
    ) {
      setAvailableSpaces(
        courseQuery.data.totalNumberOfPeople -
          learnersForCourseQuery.data.length
      );
    }
  }, [learnersForCourseQuery.data, learnersForCourseQuery.error]);
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.add_group_to.name',
              defaultMessage: 'Add group to: {name}'
            },
            { name: courseQuery.data?.name || '' }
          )}
        </title>
      </Helmet>
      <div className="card">
        <div className="card-header">
          <h1>
            <FormattedMessage
              id="header.add_group_to.name"
              defaultMessage="Add group to: {name}"
              description="Add group to: {name}"
              values={{ name: courseQuery.data?.name || '' }}
            />
          </h1>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>
            <FormattedMessage
              id="course.details"
              defaultMessage="Course details"
              description="Course details"
            />
          </h3>
        </div>
        <div className="card-body">
          {courseQuery.isFetching ? (
            <Loading />
          ) : courseQuery.error ? (
            <GeneralError />
          ) : (
            courseQuery.data && <CourseDetailsTable course={courseQuery.data} />
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>
            <FormattedMessage
              id="groups"
              defaultMessage="Groups"
              description="Groups"
            />
          </h3>
        </div>
        <div className="card-body">
          {groupsListPagedQuery.isFetching ||
          learnersForCourseQuery.isFetching ? (
            <Loading />
          ) : groupsListPagedQuery.error ? (
            <GeneralError />
          ) : (
            groupsListPagedQuery.data &&
            courseQuery.data &&
            courseQuery.data.totalNumberOfPeople && (
              <GroupsTable
                data={groupsListPagedQuery.data}
                renderGroupsLink={({ group }) => {
                  return (
                    <div className="btn-group">
                      <Link
                        to={`/${displayLocale?.toLowerCase()}/${
                          appStrings[langVal][AppRoute.Groups]
                        }/${group.groupId}`}
                        className="btn btn-outline-secondary"
                      >
                        <FormattedMessage
                          id="view"
                          defaultMessage="View"
                          description="View"
                        />
                      </Link>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        disabled={
                          learnersForCourseQuery.failureReason &&
                          (learnersForCourseQuery.failureReason as any).response
                            .data === 'No Members Found Found'
                            ? group.userGroupMembers!.length >
                              courseQuery.data.totalNumberOfPeople!
                            : group.userGroupMembers!.length > availableSpaces
                        }
                        onClick={() => handleAddGroupToCourse(group)}
                      >
                        <FormattedMessage
                          id="course.group.add"
                          defaultMessage="Add to course"
                          description="Add to course"
                        />
                      </button>
                    </div>
                  );
                }}
                pagination={pagination}
                setPagination={setPagination}
                sorting={sorting}
                setSorting={setSorting}
                filtering={filtering}
                setFiltering={setFiltering}
                error={groupsListPagedQuery.error}
                isLoading={groupsListPagedQuery.isLoading}
                refetching={groupsListPagedQuery.isRefetching}
                queryParamsLocation={queryParamsLocation}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}

export default AddGroupToCourse;
