import { lazy } from 'react';

const AccessibilityStatement = lazy(() => import('./AccessibilityStatement'));
const CookiePolicy = lazy(() => import('./CookiePolicy'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));

export {
  AccessibilityStatement,
  CookiePolicy,
  PrivacyPolicy,
  TermsAndConditions
};
