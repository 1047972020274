import { useMutation } from '@tanstack/react-query';
import { setPassword } from '../../services/api/auth.service';
import { components } from '../../types/openapi/AuthService';

// For Admins to reset other's passwords
const useSetPassword = () => {
  const setPasswordMutation = useMutation(
    (body: components['schemas']['PasswordBody']) => {
      return setPassword(body);
    }
  );

  return {
    setPasswordMutation
  };
};

export default useSetPassword;
