/* eslint-disable @typescript-eslint/no-shadow */

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import noteKeys from '../query-keys/note-key-factory';
import { createNote, getNotes } from '../services/api/course.service';
import { components } from '../types/openapi/CourseService';

// hook for managing notes

const useNote = ({
  courseId,
  sessionId,
  userId
}: {
  courseId: number;
  sessionId: number;
  userId: number;
}) => {
  const queryClient = useQueryClient();
  const notesListQuery = useQuery({
    queryKey: noteKeys.list(courseId, sessionId, userId),
    queryFn: () => getNotes(courseId, sessionId, userId),
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'Session User Notes not Found')
        return false;
      if (failureCount >= 3) return false;
      return false;
    },
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!courseId && !!sessionId
  });

  const updateNoteMutation = useMutation({
    mutationKey: noteKeys.addNote(courseId, sessionId, userId),
    // these arguments need to match the default mutation in app.tsx
    onMutate: async ({
      courseId,
      sessionId,
      userId,
      note
    }: {
      courseId: number;
      sessionId: number;
      userId: number;
      note: components['schemas']['NoteDto'];
    }) => {
      await queryClient.cancelQueries({
        queryKey: noteKeys.list(courseId, sessionId, userId)
      });

      // Notes list
      const previousNotesData = queryClient.getQueryData<
        components['schemas']['SessionUserNoteDto'][]
      >(noteKeys.list(courseId, sessionId, userId));

      const newNote: components['schemas']['SessionUserNoteDto'] = {
        note,
        noteId: Number(
          `${courseId}${sessionId}${userId}${
            previousNotesData?.length ? previousNotesData.length : 0
          }`
        )
      };
      let newNotesData;
      if (previousNotesData) {
        newNotesData = [...previousNotesData, newNote];
      } else {
        newNotesData = [newNote];
      }

      if (newNotesData) {
        queryClient.setQueryData(
          noteKeys.list(courseId, sessionId, userId),
          newNotesData
        );
      }
      return { previousNotesData };
    },
    onError: (_, __, context) => {
      // if something goes wrong then reset to the previous data
      if (context) {
        queryClient.setQueryData(
          noteKeys.list(courseId, sessionId, userId),
          context.previousNotesData
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: noteKeys.list(courseId, sessionId, userId),
        refetchType: 'none'
      });
    }
  });

  const createNoteMutation = useMutation(
    (newNote: string) => {
      return createNote(courseId, sessionId, userId, newNote);
    },
    {
      onSuccess: () => {
        // invalidate list of frameworks
        queryClient.invalidateQueries({
          queryKey: noteKeys.list(courseId, sessionId, userId)
        });
      }
    }
  );

  return {
    notesListQuery,
    updateNoteMutation,
    createNoteMutation
  };
};

export default useNote;
