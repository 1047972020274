/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Collapse, ProgressBar } from 'react-bootstrap';
import { Mutation, useQueryClient } from '@tanstack/react-query';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import { GeneralError } from '../common';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import SyncDataBtn from './SyncDataBtn';
import SyncInfoDetails from './SyncInfoDetails';
import useDataSyncInfoStore from '../../hooks/state-management/useDataSyncInfoStore';
import { TIMEZONE } from '../../utils/date-time';
import SyncErrorLog from './enhancedSyncLog/SyncErrorLog';
import ClearErrorsBtn from './enhancedSyncLog/ClearErrorsBtn';
import { UserType } from '../../const/user-type';

// Summary of what data has been synced
// manual refetch of data
function SyncInfo() {
  const queryClient = useQueryClient();

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const mutationCache = queryClient.getMutationCache();
  const mutationsFromCache = mutationCache.getAll();

  const [mutations, setMutations] =
    useState<Mutation<unknown, unknown, void, unknown>[]>(mutationsFromCache);

  const initialSuccessfulMutations = mutationsFromCache.filter(
    (mut) => mut.state.status === 'success'
  );

  const [erroredMutations, setErroredMutations] =
    useState<Mutation<unknown, unknown, void, unknown>[]>();

  const [successfullyRetriedMutations, setSuccessfullyRetriedMutations] =
    useState<Mutation<unknown, unknown, void, unknown>[]>();

  const [progressPercentage, setProgressPercentage] = useState<number>(
    (initialSuccessfulMutations.length / mutations.length) * 100
  );

  const lastUploaded = useDataSyncInfoStore.use.lastUploaded();

  const [open, setOpen] = useState(false);

  const [showSyncComplete, setShowSyncComplete] = useState(
    appMode !== AppMode.OFFLINE &&
      !mutations.filter((mut) => mut.state.status !== 'success').length
  );

  // subscribe to mutation updates
  useEffect(() => {
    const callback = (event: any) => {
      if (event.type === 'updated') {
        const { mutationId } = event.mutation;

        const newMutations = mutations
          .filter((mut) => mut.state.status !== 'success')
          .map((mut) => {
            if (mutationId === mut.mutationId) {
              return event.mutation;
            }
            return mut;
          });

        setMutations([...newMutations]);
        const success = newMutations.filter(
          (mut) => mut.state.status === 'success'
        );
        if (newMutations.length && newMutations.length > 0) {
          setProgressPercentage((success.length / newMutations.length) * 100);
        }
      }
      // mutations are complete and are being removed from mutationCache
      if (event.type === 'removed') {
        const { mutationId } = event.mutation;

        const currentMutations = mutations.filter((mut) => {
          if (mutationId !== mut.mutationId) {
            return event.mutation;
          }
          return mut;
        });

        setMutations([...currentMutations]);
        const success = currentMutations.filter(
          (mut) => mut.state.status === 'success'
        );
        if (currentMutations.length && currentMutations.length > 0) {
          setProgressPercentage(
            (success.length / currentMutations.length) * 100
          );
        }
      }
    };

    const unsubscribe = mutationCache.subscribe(callback);

    return () => unsubscribe();
  }, []);

  if (!profile?.userId) {
    return <GeneralError message="no user id" />;
  }

  useEffect(() => {
    if (mutations) {
      setErroredMutations(
        mutations.filter(
          (mut) =>
            mut.state.status === 'error' &&
            !successfullyRetriedMutations?.find(
              (successfulMutation) =>
                successfulMutation.mutationId === mut.mutationId
            )
        )
      );
    }
  }, [mutations]);

  useEffect(() => {
    if (erroredMutations && erroredMutations.length === 0) {
      setShowSyncComplete(true);
    }
    if (erroredMutations && erroredMutations.length > 0) {
      setShowSyncComplete(false);
    }
  }, [erroredMutations]);

  return (
    <div
      className={`sync-info ${appMode === AppMode.OFFLINE ? 'offline' : ''}`}
    >
      {/* if there are loading mutations, and the user is online, then show the sync button */}
      {!!mutations.length &&
        mutations.some((mut) => mut.state.status === 'loading') &&
        appMode !== AppMode.OFFLINE && (
          <SyncDataBtn
            instructorId={profile.userId}
            isSyncing={progressPercentage > 0 && progressPercentage < 100}
          />
        )}

      {appMode === AppMode.OFFLINE && (
        <div className="alert alert-warning my-2 d-flex w-100">
          <div>
            {' '}
            <FontAwesomeIcon
              icon={faExclamation}
              className="me-1 text-warning"
            />
          </div>
          <div>
            {' '}
            {mutations.filter((mut) => mut.state.status !== 'success')
              .length ? (
              <FormattedMessage
                id="offline.sync.unsynced_data_message.has_data"
                defaultMessage="You have unsynced data. Sync not available in offline mode. Changes will be synced when you go back online."
              />
            ) : (
              <FormattedMessage
                id="offline.sync.unsynced_data_message.has_no_data"
                defaultMessage="No data to sync."
              />
            )}
          </div>
          <br />
        </div>
      )}

      {/* if there are mutations updating, then show status */}
      {!!mutations.length &&
        appMode !== AppMode.OFFLINE &&
        progressPercentage < 100 &&
        !mutations.find((mut) => mut.state.status === 'error') && (
          <ProgressBar
            className="w-100 my-2"
            variant="success"
            animated
            now={progressPercentage}
          />
        )}
      {/* If some mutations are errored, show button */}
      {appMode !== AppMode.OFFLINE &&
        profile.userTypeId === UserType.INSTRUCTOR &&
        erroredMutations &&
        erroredMutations.length > 0 && (
          <>
            <div className="alert alert-danger my-2 d-flex justify-content-between align-items-center w-100">
              <div className="d-flex">
                <div>
                  {' '}
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className="me-1 text-danger"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="offline.sync.errors"
                    defaultMessage="There were errors in the data sync"
                  />
                </div>
              </div>
              {!open && (
                <button
                  onClick={() => setOpen(!open)}
                  aria-controls="mutation-errors"
                  aria-expanded={open}
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  View errors
                </button>
              )}
              {open && (
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setOpen(!open)}
                />
              )}
            </div>
            <Collapse className="w-100" in={open}>
              <div id="mutation-errors">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h3>
                      <FormattedMessage
                        id="sync.log.error"
                        defaultMessage="Sync error log"
                        description="Sync error log"
                      />
                    </h3>
                  </div>
                  <div className="card-body">
                    {/* Log showing mutations that have errored but have not been retried */}
                    <SyncErrorLog
                      erroredMutations={erroredMutations}
                      successfullyRetriedMutations={
                        successfullyRetriedMutations
                      }
                      setSuccessfullyRetriedMutations={
                        setSuccessfullyRetriedMutations
                      }
                    />
                    <div className="d-flex justify-content-center">
                      <ClearErrorsBtn
                        setMutations={setMutations}
                        setErroredMutations={setErroredMutations}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </>
        )}

      {/* when not in offline mode, and all my mutations are in a success state ie none are currently loading, error or idle */}
      {showSyncComplete && appMode !== AppMode.OFFLINE && (
        <div className="alert alert-success my-2 w-100">
          <div className="d-flex">
            <div>
              {' '}
              <FontAwesomeIcon icon={faCheck} className="me-1" />
            </div>
            <div className="flex-grow-1">
              <FormattedMessage
                id="offline.sync.complete"
                defaultMessage="Sync Complete"
                description="Sync Complete"
              />
              <br />
              {lastUploaded && (
                <span className="small text-muted">
                  <FormattedDate
                    value={new Date(lastUploaded)}
                    timeZone={TIMEZONE}
                    year="numeric"
                    month="numeric"
                    day="numeric"
                    hour="numeric"
                    minute="numeric"
                    second="numeric"
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      <SyncInfoDetails instructorId={profile.userId} />
    </div>
  );
}
export default SyncInfo;
