/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { components } from '../../../types/openapi/ProviderService';
import {
  getProviderCentreFacility,
  updateProviderCentreFacility
} from '../../../services/api/provider.service';
import FacilityForm from './FacilitiesForm';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import Loading from '../../common/Loading';
import { GeneralError } from '../../common';
import { AppRoute } from '../../../const';
import localiseRoutePath from '../../../utils/localiseRoutePath';

function EditFacility() {
  const intl = useIntl();
  const { centreId, facilityId } = useParams();
  const navigate = useNavigate();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const userProviderId = profile?.providerId;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const navLinkCentre = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Centres
  )}/${centreId}`;

  const {
    isLoading: facilityLoading,
    error: facilityError,
    data: facilityOriginal
  } = useQuery({
    queryKey: [
      'facility',
      userProviderId,
      Number(centreId),
      Number(facilityId),
      displayLocale
    ],
    queryFn: () =>
      getProviderCentreFacility(
        userProviderId,
        Number(centreId),
        Number(facilityId),
        displayLocale
      ),
    refetchOnMount: `always`
  });

  const { mutate: editFacility, isLoading } = useMutation(
    (facilityUpdated: Partial<components['schemas']['FacilityDto']>) => {
      if (userProviderId) {
        return updateProviderCentreFacility(
          userProviderId,
          Number(centreId),
          Number(facilityId),
          facilityUpdated,
          displayLocale
        );
      }
      throw Error('no provider id');
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'facility.edit.success',
            defaultMessage: 'You have successfully edited this facility'
          }),
          { delay: 200 }
        );
        navigate(navLinkCentre);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'facility.edit.error',
            defaultMessage:
              'There was an error editing this facility with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (
    facilityUpdated: Pick<
      components['schemas']['FacilityDto'],
      'name' | 'description'
    >
  ) => {
    const { name, description } = facilityUpdated;
    if (facilityOriginal) {
      const newUpdatedFacility: Partial<components['schemas']['FacilityDto']> =
        {
          name,
          description,
          facilityId: facilityOriginal.facilityId,
          centreId: facilityOriginal.centreId
        };
      editFacility(newUpdatedFacility);
    }
  };
  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.facility.edit_facility',
            defaultMessage: 'Edit facility'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="facility.edit_facility"
            defaultMessage="Edit facility"
            description="Edit Facility"
          />
        </h1>
      </div>
      <div className="card-body">
        {facilityLoading ? (
          <Loading />
        ) : facilityError ? (
          <GeneralError />
        ) : facilityOriginal ? (
          <FacilityForm
            onSubmit={onSubmit}
            submitButtonMessage={
              <FormattedMessage
                id="save_changes"
                defaultMessage="Save changes"
                description="Save changes"
              />
            }
            formDisabled={isLoading}
            name={facilityOriginal.name}
            description={facilityOriginal.description}
          />
        ) : (
          <FormattedMessage
            id="facility.data.none"
            defaultMessage="No facility data available"
            description="No facility data available"
          />
        )}
      </div>
    </div>
  );
}
export default EditFacility;
