/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { components } from '../../../types/openapi/ProviderService';
import localiseRoutePath from '../../../utils/localiseRoutePath';
import { AppRoute } from '../../../const';
import { ModalContext } from '../../../modules/modal/ModalProvider';
import { deleteProviderCentreFacility } from '../../../services/api/provider.service';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import facilityKeys from '../../../query-keys/facility-key-factory';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

function FacilitiesTable({
  facilities
}: {
  facilities: components['schemas']['FacilityDto'][];
}) {
  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const { centreId } = useParams();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { profile } = useContext(ProfileContext);
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { mutate: deleteFacility } = useMutation(
    ({
      providerId,
      centreId,
      facilityId
    }: {
      providerId: number;
      centreId: number;
      facilityId: number;
    }) => deleteProviderCentreFacility(providerId, centreId, facilityId),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'facility.delete.success',
            defaultMessage: 'Facility has been removed'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: facilityKeys.centreList(Number(centreId), displayLocale)
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'facility.delete.error',
            defaultMessage: 'There was an error removing this facililty'
          }),
          { delay: 200 }
        );
      }
    }
  );

  async function onModalConfirm(
    facility: components['schemas']['FacilityDto']
  ) {
    if (profile?.providerId) {
      deleteFacility({
        providerId: profile?.providerId,
        centreId: facility.centreId,
        facilityId: Number(facility.facilityId)
      });
    }
  }

  const openModal = (facility: components['schemas']['FacilityDto']) => {
    const header = intl.formatMessage(
      {
        id: 'facility.remove.confirm.header',
        defaultMessage: 'Remove Facility: {name}',
        description: 'Remove Facility Confirm Modal Header'
      },
      { name: facility.name }
    );
    const modalContent = intl.formatMessage({
      id: 'facility.remove.confirm.body',
      defaultMessage:
        'Are you sure you want to remove this facility? This action cannot be undone',
      description: 'Remove Facility Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'facility.remove.confirm.button',
      defaultMessage: 'Remove Facility',
      description: 'Remove Facility Confirm Button'
    });
    modal(modalContent, {
      confirm: () => onModalConfirm(facility),
      header,
      confirmText
    });
  };

  return (
    <table className="table card-list-table">
      <thead>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="name"
              defaultMessage="Name"
              description="Name"
            />
          </th>
          <th scope="row">
            <FormattedMessage
              id="externalId"
              defaultMessage="External Id"
              description="External Id"
            />
          </th>
          <th scope="row">
            <FormattedMessage
              id="actions.table.header"
              defaultMessage="Actions"
              description="Actions"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {facilities ? (
          facilities.map((facility: components['schemas']['FacilityDto']) => {
            return (
              <tr key={facility.facilityId}>
                <td data-title="Name">{facility.name}</td>
                <td data-title="External Id">{facility.externalId}</td>
                <td>
                  <div className="btn-group">
                    <Link
                      to={`${localiseRoutePath(AppRoute.EditFacility)}/${
                        facility.facilityId
                      }`}
                      className="btn btn-outline-secondary start"
                    >
                      <FormattedMessage
                        id="edit"
                        defaultMessage="Edit"
                        description="Edit"
                      />
                    </Link>
                    <button
                      type="button"
                      className="btn btn-outline-secondary end"
                      onClick={() => openModal(facility)}
                    >
                      <FormattedMessage
                        id="delete"
                        defaultMessage="Delete"
                        description="Delete"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <FormattedMessage
            id="facilities.none"
            defaultMessage="No Facilities Found"
            description="No Facilities Found"
          />
        )}
      </tbody>
    </table>
  );
}

export default FacilitiesTable;
