import React, { useContext } from 'react';
import useMultipleStageObjectives from '../../../hooks/useMultipleStagesObjectives';
import useStarsForUsersCourseSession from '../../../hooks/useStarsForUsersCourseSession';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { components } from '../../../types/openapi/CourseService';
import getStageIdsFromCourseStages from '../../../utils/getStageIdsFromCourseStages';
import { GeneralError, Loading } from '../../common';
import ObjectivesOverview from '../../courses/sessions/session/instructor/objectives-overview/ObjectivesOverview';

function OnlineObjectivesOverview({
  learners,
  course,
  session
}: {
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][];
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  session:
    | components['schemas']['CourseSessionDto']
    | components['schemas']['SessionSlimDto'];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const sessionWithLearners = { ...session, members: [...learners] };
  const { starsForUsersCourseSessionQuery } = useStarsForUsersCourseSession({
    session: sessionWithLearners
  });
  const courseStageIds = getStageIdsFromCourseStages(course.courseStages);
  // get the data to populate the select
  const { multipleStagesObjectivesQueries } = useMultipleStageObjectives({
    frameworkId: course.frameworkVersion?.frameworkId,
    frameworkVersionId: course.frameworkVersionId,
    displayLocale,
    stageIds: courseStageIds
  });
  if (
    course &&
    starsForUsersCourseSessionQuery.data &&
    multipleStagesObjectivesQueries
  )
    return (
      <ObjectivesOverview
        frameworkId={course.frameworkVersion?.frameworkId}
        frameworkVersionId={course.frameworkVersionId}
        learners={learners}
        starsForUsers={starsForUsersCourseSessionQuery.data}
        courseStages={course.courseStages}
        multipleStagesObjectives={multipleStagesObjectivesQueries.map(
          (query) => {
            const { data } = query;
            if (data)
              return {
                stageId: data[0].stageId,
                stageName: data[0].stage?.name,
                stageOrder: data[0].stage?.stageOrder,
                stageObjectives: data
              };
            return undefined;
          }
        )}
      />
    );
  if (starsForUsersCourseSessionQuery.error) return <GeneralError />;
  return <Loading />;
}

export default OnlineObjectivesOverview;
