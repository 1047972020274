import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  bookUserOnToCourse,
  removeUserFromCourse
} from '../../services/api/course.service';
import { components } from '../../types/openapi/UserService';
import courseKeys from '../../query-keys/course-key-factory';

const useChangeLearnersOnCourse = ({
  courseId,
  displayLocale
}: {
  courseId: number;
  displayLocale: string | undefined;
}) => {
  const queryClient = useQueryClient();

  const bookUserOnToCourseMutation = useMutation(
    (selectedLearner: components['schemas']['UserDto']) => {
      if (selectedLearner.userId) {
        return bookUserOnToCourse(courseId, selectedLearner.userId);
      }
      throw Error('no user ID for learner');
    },
    {
      onSuccess: () => {
        // invalidate course
        queryClient.invalidateQueries({
          queryKey: courseKeys.course(courseId, displayLocale)
        });
        // invalidate course sessions
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === 'sessions' &&
            query.queryKey[2] === courseId &&
            query.queryKey[3] === 'members'
        });
        // learners on course
        queryClient.invalidateQueries(
          courseKeys.learnersOnCourseList(courseId, displayLocale)
        );
      }
    }
  );

  const removeLearnerFromCourseMutation = useMutation(
    (selectedLearner: components['schemas']['UserDto']) => {
      if (selectedLearner.userId) {
        return removeUserFromCourse(
          courseId,
          selectedLearner.userId?.toString()
        );
      }
      throw Error('no user ID for learner');
    },
    {
      onSuccess: () => {
        // invalidate course
        queryClient.invalidateQueries({
          queryKey: courseKeys.course(courseId, displayLocale)
        });
        // invalidate course members on sessions
        queryClient.invalidateQueries({
          predicate: (query) => {
            return (
              query.queryKey[0] === 'sessions' && query.queryKey[2] === courseId
            );
          }
        });
        // learners on full course
        queryClient.invalidateQueries(
          courseKeys.learnersOnCourseList(courseId, displayLocale)
        );
      }
    }
  );

  return {
    bookUserOnToCourseMutation,
    removeLearnerFromCourseMutation
  };
};

export default useChangeLearnersOnCourse;
