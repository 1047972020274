import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../../types/openapi/CourseService';
import AppMode from '../../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../../modules/offline/OfflineProvider';
import OfflineFrameworkProgress from '../../offline/offlineModePages/OfflineFrameworkProgress';
import OnlineFrameworkProgress from '../../offline/onlineModePages/OnlineFrameworkProgress';

function ProgressCard({
  course,
  learner,
  className
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  learner:
    | components['schemas']['UserDto']
    | components['schemas']['LearnerDto'];
  className?: string;
}) {
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  return (
    <div className={`card border-0 rounded-top-right-lg ${className}`}>
      <div className="card-header rounded-top-right-lg">
        <h2>
          <FormattedMessage
            id="learner.progress"
            defaultMessage="Progress"
            description="Progress"
          />
        </h2>
      </div>
      <div className="card-body d-flex flex-column justify-content-center">
        {course.frameworkVersion?.frameworkId &&
          course.frameworkVersionId &&
          learner.userId &&
          (appMode === AppMode.OFFLINE ? (
            <OfflineFrameworkProgress
              frameworkId={course.frameworkVersion?.frameworkId}
              frameworkVersionId={course.frameworkVersionId}
              learner={learner}
            />
          ) : (
            <OnlineFrameworkProgress
              frameworkId={course.frameworkVersion?.frameworkId}
              frameworkVersionId={course.frameworkVersionId}
              userId={learner.userId}
            />
          ))}
      </div>
    </div>
  );
}

export default ProgressCard;

ProgressCard.defaultProps = {
  className: ''
};
