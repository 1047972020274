import React from 'react';
import { CourseUserStars } from '../../../../../../types/CourseUserStars.type';
import { components } from '../../../../../../types/openapi/CourseService';
import { GeneralError } from '../../../../../common';
import ObjectivesOverviewTable from './ObjectivesOverviewTable';

function ObjectivesOverviewTableWrapper({
  frameworkId,
  frameworkVersionId,
  stageId,
  learners,
  starsForUsers,
  objectives
}: {
  frameworkId: number;
  frameworkVersionId: number;
  stageId: number;
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][];
  starsForUsers:
    | CourseUserStars[]
    | components['schemas']['StarsForUserDto'][]
    | undefined
    | null;
  objectives:
    | components['schemas']['ObjectiveDto'][]
    | components['schemas']['ObjectiveSlimDto'][]
    | undefined
    | null;
}) {
  if (objectives && starsForUsers)
    return (
      <ObjectivesOverviewTable
        frameworkId={frameworkId}
        frameworkVersionId={frameworkVersionId}
        stageId={stageId}
        learners={learners}
        objectives={objectives}
        starsForUsers={starsForUsers}
      />
    );

  return <GeneralError />;
}

export default ObjectivesOverviewTableWrapper;
