import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { updateProviderGroup } from '../../services/api/provider.service';

import { components } from '../../types/openapi/UserService';
import { components as providerComponents } from '../../types/openapi/ProviderService';
import UsersTable from '../users/UsersTable';
import groupKeys from '../../query-keys/group-key-factory';
import useGroupAdmins from '../../hooks/useGroupAdmins';

interface Props {
  group: providerComponents['schemas']['GroupDto'];
}

function GroupAdminsInGroup(props: Props) {
  const { group } = props;
  const { name, description, userGroupAdmins, userGroupMembers } = group;
  const { groupId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [showGroupAdminSelect, setShowGroupAdminSelect] =
    useState<boolean>(false);
  const [selectedGroupAdminIds, setSelectedGroupAdminIds] =
    useState<(number | undefined)[]>();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const userProviderId = profile?.providerId;

  const { groupAdminsQuery } = useGroupAdmins({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    displayLocale,
    pageIndex: null,
    pageSize: null,
    sorting: null,
    rawFiltering: null
  });

  const { mutate: addGroupAdminToGroup } = useMutation(
    (editedGroup: any) =>
      updateProviderGroup(
        userProviderId,
        Number(groupId),
        editedGroup,
        displayLocale
      ),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'group.admin.add.success',
            defaultMessage:
              'This group admin was successfully added to this group'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.group(
            profile?.providerId!,
            Number(groupId),
            displayLocale
          )
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.admin.add.error',
            defaultMessage:
              'There was an error adding this group admin to this group'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const handleAddGroupAdminToGroup = () => {
    const adminIds = userGroupAdmins?.map((admin) => admin.userId);
    const updatedGroupAdmins = adminIds?.concat(selectedGroupAdminIds);
    const editedGroup = {
      groupName: name,
      groupDescription: description,
      groupId,
      learners: userGroupMembers?.map((learner) => learner.userId),
      groupAdmins: updatedGroupAdmins
    };
    addGroupAdminToGroup(editedGroup);
  };

  const { mutate: removeGroupAdminFromGroup } = useMutation(
    (editedGroup: any) =>
      updateProviderGroup(
        userProviderId,
        Number(groupId),
        editedGroup,
        displayLocale
      ),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: groupKeys.group(
            profile?.providerId!,
            Number(groupId),
            displayLocale
          )
        });
        toast.success(
          intl.formatMessage({
            id: 'group.admin.remove.success',
            defaultMessage:
              'This admin was successfully removed from this group'
          }),
          { delay: 200 }
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.admin.remove.error',
            defaultMessage:
              'There was an error removing this admin from this group'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const handleRemoveGroupAdmin = (
    removedGroupAdmin: components['schemas']['UserDto']
  ) => {
    const groupAdmins = userGroupAdmins;
    const index = groupAdmins!.findIndex(
      (admin) => admin.userId === removedGroupAdmin.userId
    );
    groupAdmins?.splice(index, 1);
    const editedGroup = {
      groupName: name,
      groupDescription: description,
      groupId,
      learners: userGroupMembers?.map((learner) => learner.userId),
      groupAdmins: groupAdmins?.map((admin) => admin.userId)
    };
    removeGroupAdminFromGroup(editedGroup);
  };
  return (
    <div className="card">
      <div className="card-header d-flex">
        <div className="flex-grow-1">
          <h2>
            <FormattedMessage
              id="group.admins"
              defaultMessage="Group Admins"
              description="Group Admins"
            />
          </h2>
        </div>
        <div>
          {!showGroupAdminSelect && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowGroupAdminSelect(true)}
            >
              <FormattedMessage
                id="admin.group.add"
                defaultMessage="Add admin"
                description="Add admin to group"
              />
            </button>
          )}
        </div>
      </div>

      <div className="card-body">
        <div className="d-flex flex-column align-items-center gap-3 mb-3">
          {showGroupAdminSelect && (
            <>
              <Select
                className="w-100"
                options={groupAdminsQuery.data?.users?.reduce(
                  (filteredAdmins: any, admin) => {
                    if (
                      !userGroupAdmins?.some(
                        (groupAdmin) => groupAdmin.userId === admin.userId
                      )
                    ) {
                      filteredAdmins.push({
                        label: `${admin.username} (${admin.sportPassportId})`,
                        value: admin.userId
                      });
                    }
                    return filteredAdmins;
                  },
                  []
                )}
                placeholder="Search Group Admins"
                isMulti
                onChange={(adminOption) =>
                  setSelectedGroupAdminIds(
                    adminOption?.map((option: any) => option.value)
                  )
                }
              />
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowGroupAdminSelect(false)}
                >
                  <FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"
                    description="Cancel"
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-1"
                  disabled={!selectedGroupAdminIds}
                  onClick={handleAddGroupAdminToGroup}
                >
                  <FormattedMessage
                    id="save_changes"
                    defaultMessage="Save changes"
                    description="Save changes"
                  />
                </button>
              </div>
            </>
          )}
        </div>

        <UsersTable
          areGroupAdmins
          users={userGroupAdmins!.map(
            (groupAdmin) => groupAdmin.user as components['schemas']['UserDto']
          )}
          actions={userGroupAdmins?.map((admin) => {
            return (
              <div className="btn-group">
                <button
                  type="button"
                  disabled={!userGroupAdmins || userGroupAdmins?.length <= 1}
                  className="btn btn-outline-secondary"
                  onClick={() => handleRemoveGroupAdmin(admin)}
                >
                  <FormattedMessage
                    id="group.admin.remove"
                    defaultMessage="Remove"
                    description="Remove"
                  />
                </button>
              </div>
            );
          })}
        />
      </div>
    </div>
  );
}

export default GroupAdminsInGroup;
