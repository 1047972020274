import React, { useContext, useEffect, useState } from 'react';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import GroupsTable from './GroupsTable';
import GroupsListActions from './GroupsListActions';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import useGroups from '../../hooks/useGroups';

function GroupsList() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);

  const queryParamsLocation = FilteringQueryParams.Groups;

  const { profile } = profileContext;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(pagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(pagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(sorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(filtering)
    );
  }, [pagination, sorting, filtering]);

  const { groupsListPagedQuery } = useGroups({
    providerId: profile?.providerId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <div className="card border-0">
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="groups"
            defaultMessage="Groups"
            description="Groups"
          />
        </h2>
      </div>
      <div className="card-body">
        <GroupsTable
          data={groupsListPagedQuery.data}
          error={groupsListPagedQuery.error}
          isLoading={groupsListPagedQuery.isLoading}
          refetching={groupsListPagedQuery.isRefetching}
          pagination={pagination}
          sorting={sorting}
          filtering={filtering}
          setPagination={setPagination}
          setSorting={setSorting}
          setFiltering={setFiltering}
          queryParamsLocation={queryParamsLocation}
          renderGroupsLink={({ group }) => (
            <GroupsListActions
              group={group}
              queryParamsLocation={FilteringQueryParams.Groups}
              linkTo={`${group.groupId}`}
              canDelete={!profile?.groupId}
            />
          )}
        />
      </div>
    </div>
  );
}

export default GroupsList;
