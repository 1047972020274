import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteCourseSessionBookingForUser } from '../../services/api/course.service';
import courseKeys from '../../query-keys/course-key-factory';
import sessionKeys from '../../query-keys/session-key-factory';

const useRemoveLearnerFromSession = ({
  courseId,
  sessionId,
  displayLocale
}: {
  courseId: number;
  sessionId: number;
  displayLocale: string | undefined;
}) => {
  const queryClient = useQueryClient();

  const removeUserFromCourseSessionMutation = useMutation(
    ({ userId }: { userId: number }) => {
      return deleteCourseSessionBookingForUser(courseId, userId, sessionId);
    },
    {
      onSuccess: () => {
        // invalidate learners on this session
        queryClient.invalidateQueries(
          sessionKeys.members(courseId, sessionId, displayLocale)
        );

        // invalidate learners on entire course
        queryClient.invalidateQueries(
          courseKeys.learnersOnCourseList(courseId, displayLocale)
        );
      }
    }
  );

  return {
    removeUserFromCourseSessionMutation
  };
};

export default useRemoveLearnerFromSession;
