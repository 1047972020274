/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useCallback, useState } from 'react';
import jwt_decode from 'jwt-decode';

import { components as authComponents } from '../../types/openapi/AuthService';
import { components as userComponents } from '../../types/openapi/UserService';
import { StorageId } from '../../const/storage-id';

interface MyAuthContext {
  decodedUserFromToken: Partial<userComponents['schemas']['UserDto']> | null;
  token: string | null;
  handleSetTokenResponse: (
    data: authComponents['schemas']['JwtTokenResponse']
  ) => void;
  handleLogout: () => void;
}

export const AuthContext = React.createContext<MyAuthContext>({
  decodedUserFromToken: null,
  token: null,
  handleSetTokenResponse: () => {},
  handleLogout: () => {}
});

// provides methods for getting and setting tokens from localstorage
// provides methods for getting and setting user
// after logging in you must have a token and have a user set in order to access the app
function AuthProvider(props: { children: any }) {
  const { children } = props;

  // // a full user object contains more data than is in the token
  // // should be set from API if data available
  // const [user, setUser] = useState<
  //   userComponents['schemas']['UserDto'] | null
  // >();

  const tokenFn = () => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(StorageId.TOKEN);
      if (item) {
        // Parse stored json or if none return null as no token means user is logged out
        return item || null;
      }
      return null;
    } catch (error) {
      // If error also return null
      console.error(error);
      return null;
    }
  };

  // a logged in user will have a token in localstorage. return the encoded token
  const [token, setToken] = useState<string | null>(tokenFn);

  // a logged in user will have a token with some user details in localstorage
  // decodedUserFromToken - to make clear that this user data comes from reading and decoding the token
  // not from an API call
  const [decodedUserFromToken, setDecodedUserFromToken] = useState<
    userComponents['schemas']['UserDto'] | null
  >(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(StorageId.TOKEN);
      if (item) {
        // decode token
        const decodedToken: any = jwt_decode(item);
        // Parse stored json and store if exists
        return decodedToken || null;
      }
      return null;
    } catch (error) {
      // If error also return null
      console.error(error);
      return null;
    }
  });

  const handleSetTokenResponse = useCallback(
    (value: authComponents['schemas']['JwtTokenResponse']) => {
      try {
        const { token: newToken, refreshToken } = value;
        // Save both tokens to local storage
        window.localStorage.setItem(StorageId.TOKEN, JSON.stringify(newToken));
        window.localStorage.setItem(
          StorageId.REFRESH_TOKEN,
          JSON.stringify(refreshToken)
        );

        // Save token in state - so other components can receive updates when the token changes
        // Save decoded user from token in state
        if (newToken) {
          setToken(tokenFn);
          const decodedUser: any = jwt_decode(newToken);
          // Allow value to be a function so we have same API as useState
          const decodedTokenValueToStore =
            decodedUser instanceof Function
              ? decodedUser(decodedUser)
              : decodedUser;
          setDecodedUserFromToken(decodedTokenValueToStore);
        }
      } catch (error) {
        // handle the error case
        console.error(error);
      }
    },
    []
  );

  const handleLogout = () => {
    localStorage.removeItem(StorageId.TOKEN);
    localStorage.removeItem(StorageId.REFRESH_TOKEN);

    localStorage.removeItem('sso');
    localStorage.removeItem('sso_frameworkId');

    setDecodedUserFromToken(() => null);
    setToken(tokenFn);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        decodedUserFromToken,
        handleSetTokenResponse,
        handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
