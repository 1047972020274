import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppLanguage } from '../../../const';
import useMultipleStageObjectives from '../../../hooks/useMultipleStagesObjectives';
import useSession from '../../../hooks/useSession';
import useStarsForUsersCourseSession from '../../../hooks/useStarsForUsersCourseSession';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { components } from '../../../types/openapi/CourseService';
import getStageIdsFromCourseStages from '../../../utils/getStageIdsFromCourseStages';
import { GeneralError, Loading } from '../../common';
import InstructorSessionLearners from '../../courses/sessions/session/instructor/InstructorSessionLearners';

function OnlineInstructorSessionLearners({
  course,
  instructorId,
  learners,
  session
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  instructorId: number;
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][];
  session:
    | components['schemas']['CourseSessionDto']
    | components['schemas']['SessionSlimDto'];
}) {
  const { sessionId } = useParams();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const sessionWithLearners = { ...session, members: [...learners] };

  const { starsForUsersCourseSessionQuery } = useStarsForUsersCourseSession({
    session: sessionWithLearners
  });

  const { sessionMembersQuery } = useSession({
    courseId: course.courseId!,
    sessionId: Number(sessionId),
    userId: profile?.userId,
    displayLocale: displayLocale || AppLanguage.English
  });

  const courseStageIds = getStageIdsFromCourseStages(course.courseStages);
  // get the data to populate the select
  const { multipleStagesObjectivesQueries } = useMultipleStageObjectives({
    frameworkId: course.frameworkVersion?.frameworkId,
    frameworkVersionId: course.frameworkVersionId,
    displayLocale,
    stageIds: courseStageIds
  });

  if (multipleStagesObjectivesQueries && starsForUsersCourseSessionQuery.data)
    return (
      <InstructorSessionLearners
        course={course}
        instructorId={instructorId}
        learners={learners}
        starsForUsers={starsForUsersCourseSessionQuery.data}
        courseStages={course.courseStages}
        frameworkVersionId={course.frameworkVersionId}
        multipleStagesObjectives={multipleStagesObjectivesQueries.map(
          (query) => {
            const { data } = query;
            if (data)
              return {
                stageId: data[0].stageId,
                stageName: data[0].stage?.name,
                stageOrder: data[0].stage?.stageOrder,
                stageObjectives: data
              };
            return undefined;
          }
        )}
        session={session}
        learnerDataUpdatedAt={sessionMembersQuery.dataUpdatedAt}
      />
    );
  if (starsForUsersCourseSessionQuery.error) return <GeneralError />;
  return <Loading />;
}

export default OnlineInstructorSessionLearners;
