import React from 'react';

function InfoPanel({
  faIcon,
  children
}: {
  faIcon: JSX.Element;
  children: React.ReactNode;
}) {
  return (
    <div className="d-flex rounded bg-light flex-grow-1">
      <div className="p-1 p-md-3">{faIcon}</div>
      <div className="d-flex flex-column">
        <div className="my-auto"> {children}</div>
      </div>
    </div>
  );
}

export default InfoPanel;
