import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Select, { PropsValue } from 'react-select';
import { AppLanguage, AppRoute } from '../../const';

import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../common';
import Loading from '../common/Loading';
import useCourseFrameworksForLearner from '../../hooks/courses/useCourseFrameworksForLearner';
import { components } from '../../types/openapi/CourseService';

function ProgressLinkCard({
  userId,
  renderLatestTrophy
}: {
  userId: number;
  renderLatestTrophy: (
    frameworkVersion: components['schemas']['FrameworkVersionDto']
  ) => JSX.Element | ReactNode;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [frameworkVersion, setFrameworkVersion] =
    useState<components['schemas']['FrameworkVersionDto']>();
  const [frameworkVersionOptions, setFrameworkVersionOptions] =
    useState<PropsValue<any>>();

  const { courseFrameworksForLearnerListQuery } = useCourseFrameworksForLearner(
    {
      userId,
      displayLocale
    }
  );

  useEffect(() => {
    if (
      courseFrameworksForLearnerListQuery.data &&
      courseFrameworksForLearnerListQuery.data.length > 0
    ) {
      // set default course to first one in list if none selected eg on first load
      if (!frameworkVersion) {
        const defaultFramework = courseFrameworksForLearnerListQuery.data[0];
        setFrameworkVersion(defaultFramework);
      }
      const learnerCourseOptions = courseFrameworksForLearnerListQuery.data.map(
        (_frameworkVersion: components['schemas']['FrameworkVersionDto']) => {
          return {
            label: `${_frameworkVersion.framework?.name} (${_frameworkVersion.name})`,
            value: _frameworkVersion.frameworkVersionId
          };
        }
      );
      setFrameworkVersionOptions(learnerCourseOptions);
    }
  }, [courseFrameworksForLearnerListQuery.data]);

  const selectedOption: PropsValue<any> | null =
    frameworkVersionOptions?.find(
      (opt: PropsValue<any>) =>
        opt.value === Number(frameworkVersion?.frameworkVersionId)
    ) || null;

  const handleFrameworkChange = (selected: {
    value: number;
    label: string;
  }) => {
    if (
      !frameworkVersion ||
      selected!.value !== frameworkVersion.frameworkVersionId
    ) {
      const matchingFrameworkVersion =
        courseFrameworksForLearnerListQuery.data?.find(
          (f) => f.frameworkVersionId === selected!.value
        );
      setFrameworkVersion(matchingFrameworkVersion);
    }
  };
  return (
    <div className="card rounded-top-right-lg border-0 mb-3 mb-lg-0">
      <div className="card-header rounded-top-right-lg">
        <h2>
          {' '}
          <FormattedMessage
            id="progress.header"
            defaultMessage="Progress"
            description="Progress"
          />
        </h2>
      </div>
      <div className="card-body">
        {courseFrameworksForLearnerListQuery.isLoading ? (
          <Loading />
        ) : courseFrameworksForLearnerListQuery.error &&
          (courseFrameworksForLearnerListQuery.error as any).response.status !==
            404 ? (
          <GeneralError />
        ) : courseFrameworksForLearnerListQuery.data ? (
          courseFrameworksForLearnerListQuery.data &&
          frameworkVersionOptions &&
          frameworkVersionOptions.length > 1 && (
            <>
              <label
                className="form-label"
                id="aria-label"
                htmlFor="aria-example-input"
              >
                <FormattedMessage
                  id="progress.framework-select.label"
                  defaultMessage="Select a framework to view progress"
                />
              </label>{' '}
              <Select
                aria-labelledby="aria-label"
                className="mb-3 z-index-1"
                inputId="frameworkVersionId"
                options={frameworkVersionOptions}
                defaultValue={null}
                placeholder="Select Course"
                onChange={handleFrameworkChange}
                value={selectedOption}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 })
                }}
              />
            </>
          )
        ) : (
          <p className="alert alert-warning">
            <FormattedMessage
              id="courses.error.none"
              defaultMessage="No courses found"
            />
          </p>
        )}
        {frameworkVersion && renderLatestTrophy(frameworkVersion)}

        <div className="d-flex justify-content-center">
          <Link
            type="button"
            className="btn btn-chevron btn-chevron-navy w-50"
            to={
              appStrings[displayLocale as AppLanguage][AppRoute.LearnerProgress]
            }
          >
            <FormattedMessage
              id="progress.my.button"
              defaultMessage="My progress"
              description="My progress"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProgressLinkCard;
