import { useQueryClient, useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { AppRoute } from '../../../const';
import { UserType } from '../../../const/user-type';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import centreKeys from '../../../query-keys/centre-key-factory';
import { createUser } from '../../../services/api/user.service';
import { components } from '../../../types/openapi/UserService';
import { Subset } from '../../../types/Subset.type';
import localiseRoutePath from '../../../utils/localiseRoutePath';
import UserForm from '../../users/forms/UserForm';

function CreateCentreAdmin() {
  const intl = useIntl();
  const { centreId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const navLinkCentre = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Centres
  )}/${centreId}`;

  const { mutate: createNewAdmin, isLoading } = useMutation(
    (admin: Subset<components['schemas']['UserDto']>) => createUser(admin),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'admin.centre.create.success',
            defaultMessage: 'A new admin has been created for this centre'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: centreKeys.adminsForCentre(Number(centreId), displayLocale)
        });
        navigate(navLinkCentre);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'admin.create.error',
            defaultMessage:
              'There was an error creating an admin with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );
  const onSubmit = (admin: components['schemas']['UserDto']) => {
    const { firstName, surname, email, telephone, dateOfBirth, genderId } =
      admin;
    const newAdmin: Subset<components['schemas']['UserDto']> = {
      firstName,
      surname,
      email,
      telephone,
      genderId: Number(genderId),
      joinDate: new Date().toJSON(),
      dateOfBirth,
      userProviders: [
        {
          providerId: profile?.providerId,
          userTypeId: UserType.ADMIN,
          centreId: Number(centreId)
        }
      ],
      title: 'title'
    };
    createNewAdmin(newAdmin);
  };
  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.admin.create_new',
            defaultMessage: 'Create new admin'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="admin.header.create_new"
            defaultMessage="Create New Admin"
            description="Create New Admin"
          />
        </h2>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            <UserForm
              onSubmit={onSubmit}
              hasEmergencyContactDetails={false}
              isEmailMandatory
              submitButtonMessage={
                <FormattedMessage
                  id="admin.button.create_new"
                  defaultMessage="Create new admin"
                  description="Create new admin"
                />
              }
              formDisabled={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateCentreAdmin;
