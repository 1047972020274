import { useQuery } from '@tanstack/react-query';
import schoolKeys from '../../query-keys/schools-key-factory';
import {
  getCentreSchoolsCount,
  getProviderSchoolsCount
} from '../../services/api/provider.service';

// hook for managing courses
const useSchoolsCount = ({
  providerId,
  centreId,
  enabled,
  isCentreAdmin,
  displayLocale
}: {
  providerId: number | undefined | null;
  centreId: number | undefined | null;
  enabled: boolean;
  isCentreAdmin: boolean;
  displayLocale: string | undefined;
}) => {
  const schoolsCountForProviderQuery = useQuery({
    queryKey: schoolKeys.providerCount(providerId, displayLocale),
    queryFn: () => getProviderSchoolsCount(providerId, displayLocale),
    refetchOnMount: true,
    enabled: !!providerId && enabled && !isCentreAdmin
  });

  const schoolsCountForCentreQuery = useQuery({
    queryKey: schoolKeys.centreCount(providerId, centreId, displayLocale),
    queryFn: () => getCentreSchoolsCount(providerId, centreId, displayLocale),
    refetchOnMount: true,
    enabled: !!providerId && !!centreId && enabled && !!isCentreAdmin
  });

  if (isCentreAdmin) {
    return schoolsCountForCentreQuery;
  }
  return schoolsCountForProviderQuery;
};

export default useSchoolsCount;
