import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { GeneralError } from '../../common';
import Loading from '../../common/Loading';
import ViewLearnerNotesOnCourse from '../../notes/ViewLearnerNotesOnCourse';
import useCourse from '../../../hooks/course/useCourse';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

function EditNotesCard() {
  const { courseId, learnerId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { courseSessionsForCourseQuery: courseSessions } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });
  if (courseSessions.data)
    return (
      <div className="card border-0 rounded-top-right-lg mt-2">
        <div className="card-header rounded-top-right-lg">
          <h2>
            <FormattedMessage
              id="notes.heading"
              defaultMessage="Notes for Learner"
              description="Notes for Learner"
            />
          </h2>
        </div>
        <div className="card-body">
          <ViewLearnerNotesOnCourse
            courseId={Number(courseId)}
            learnerId={Number(learnerId)}
            courseSessions={courseSessions.data}
          />
        </div>
      </div>
    );
  if (courseSessions.isLoading) {
    return <Loading />;
  }
  if (courseSessions.error) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="sessions.error.loading"
            defaultMessage="Cannot load sessions"
            description="Cannot load sessions"
          />
        }
      />
    );
  }
  return <GeneralError />;
}

export default EditNotesCard;
