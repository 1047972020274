import { Messages } from 'react-big-calendar';

export const cy: Required<Messages> = {
  week: 'Wythnos',
  work_week: 'Wythnos gwaith',
  day: 'Dydd',
  month: 'Mis',
  previous: 'Blaenorol',
  next: 'Nesaf',
  today: 'Heddiw',
  agenda: 'Agenda',
  date: 'Dyddiad',
  time: 'Amser',
  event: 'digwyddiad',
  allDay: "trwy'r dydd",
  yesterday: 'Ddoe',
  tomorrow: 'Yfory',
  noEventsInRange: 'dim digwyddiadau mewn ystod',
  showMore: (count: number) => count.toString()
};

export default cy;
