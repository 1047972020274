import React from 'react';
import { FormattedMessage } from 'react-intl';

import { GeneralError } from '../../common';
import CourseDetailsTableShort from '../../courses/CourseDetailsTableShort';
import { components } from '../../../types/openapi/CourseService';

function CourseDetailsCard({
  course,
  courseStages,
  className
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto']
    | undefined;
  courseStages:
    | components['schemas']['CourseStageDto'][]
    | components['schemas']['CourseStageSlimDto'][]
    | undefined
    | null;
  className?: string;
}) {
  if (course) {
    return (
      <div
        className={`card col-sm-12 mb-2 border-0 rounded-top-right-lg ${className}`}
      >
        <div className="card-header rounded-top-right-lg">
          <h2>
            <FormattedMessage
              id="course.details"
              defaultMessage="Course details"
              description="Course details"
            />
          </h2>
        </div>
        <div className="card-body ">
          <CourseDetailsTableShort
            course={course}
            courseStages={courseStages}
            showCourseName
          />
        </div>
      </div>
    );
  }

  return <GeneralError />;
}

export default CourseDetailsCard;

CourseDetailsCard.defaultProps = {
  className: ''
};
