import { AppLanguage, AppRoute } from '../../../const';
import { UserType } from '../../../const/user-type';
import { appStrings } from '../../../modules/i18n';
import { components } from '../../../types/openapi/UserService';
import { SPCalendarEvent } from '../SPCalendarEvent.type';

const getSessionLinkUrl = (
  event: SPCalendarEvent | undefined,
  profile: components['schemas']['UserProvidersDto'] | null | undefined,
  displayLocale: AppLanguage | string | undefined,
  learnerBookedOnCourse: boolean
) => {
  if (profile && event && displayLocale) {
    if (profile.userTypeId === UserType.INSTRUCTOR || !learnerBookedOnCourse) {
      return `/${displayLocale?.toLowerCase()}/${
        appStrings[displayLocale as AppLanguage][AppRoute.Courses]
      }/${(event as any).session.courseId}/${
        appStrings[displayLocale as AppLanguage][AppRoute.ViewSession]
      }/${(event as any).id}`;
    }
    if (profile.userTypeId === UserType.LEARNER) {
      return `/${displayLocale?.toLowerCase()}/${
        appStrings[displayLocale as AppLanguage][AppRoute.LearnerProgress]
      }/${appStrings[displayLocale as AppLanguage][AppRoute.Courses]}/${
        (event as any).session?.courseId
      }/${appStrings[displayLocale as AppLanguage][AppRoute.ViewSession]}/${
        (event as any).id
      }/${appStrings[displayLocale as AppLanguage][AppRoute.Learners]}/${
        profile.userId
      }`;
    }
  }
  throw Error('could not create link, missing event profile or locale');
};

export default getSessionLinkUrl;
