/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import InputStar from '../../../progress/InputStar';
import { components } from '../../../../types/openapi/CourseService';
import { CourseUserStars } from '../../../../types/CourseUserStars.type';
import userObjectivesKeys from '../../../../query-keys/user-objectives-key-factory';
import sessionKeys from '../../../../query-keys/session-key-factory';
import { LangContext } from '../../../../modules/i18n/components/IntlWrapper';

function SingleObjectiveInput({
  course,
  sessionId,
  stageId,
  objectiveUserStars,
  objectiveId,
  objectiveStageId,
  userCurrentStageId,
  userId,
  handleMarkObjective
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  sessionId: number;
  stageId: number;
  objectiveUserStars: components['schemas']['ObjectiveAndStar'] | undefined;
  objectiveId: number;
  objectiveStageId: number;
  userCurrentStageId: number | null;
  userId: number;
  handleMarkObjective: ({
    learnerId,
    stageId,
    objectiveId,
    numberOfStars
  }: {
    learnerId: number;
    stageId: number;
    objectiveId: number;
    numberOfStars: number;
  }) => any;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const NUMBER_OF_STARS = 3;

  const queryClient = useQueryClient();
  const handleChange = ({
    objectiveId,
    star
  }: {
    objectiveId: number;
    star: number;
  }) => {
    // remove star if selecting 1 and already selected
    let newStar = star;
    if (objectiveUserStars?.numberOfStars === 1 && star === 1) {
      newStar = 0;
    }

    // Online course user stars

    const oldOnlineData = queryClient.getQueryData(
      userObjectivesKeys.starsForCourseSessionUsers(
        Number(course.courseId),
        Number(sessionId)
      )
    ) as CourseUserStars[];

    const newOnlineCourseUserStars = oldOnlineData?.map((courseUserStars) => {
      // find this stage
      if (
        courseUserStars.stageId === stageId &&
        courseUserStars.userId === userId
      ) {
        const { objectiveStars } = courseUserStars;
        const newObjectiveStars = objectiveStars.map((objectiveUserStar) => {
          // find this objective
          if (objectiveUserStar.objectiveId === objectiveId) {
            const newObjectiveUserStars = { ...objectiveUserStar };
            newObjectiveUserStars.numberOfStars = newStar;
            return newObjectiveUserStars;
          }
          return objectiveUserStar;
        });
        const newCourseUserStars = { ...courseUserStars };
        newCourseUserStars.objectiveStars = [...newObjectiveStars];
        // const newStageStars = [...newObjectiveStars]
        // return newStageStars;
        return newCourseUserStars;
      }
      return courseUserStars;
    });

    // immediately update local data stored in query
    if (newOnlineCourseUserStars) {
      queryClient.setQueryData(
        userObjectivesKeys.starsForCourseSessionUsers(
          Number(course.courseId),
          Number(sessionId)
        ),
        [...newOnlineCourseUserStars]
      );
    }

    //  Online framework users stars

    const oldOnlineFrameworkStarsData = queryClient.getQueryData(
      userObjectivesKeys.starsForFramework(course.frameworkVersionId, userId)
    ) as CourseUserStars[];

    const newOnlineFrameworkUserStars = oldOnlineFrameworkStarsData?.map(
      (courseUserStars) => {
        // find this stage
        if (
          courseUserStars.stageId === stageId &&
          courseUserStars.userId === userId
        ) {
          const { objectiveStars } = courseUserStars;
          const newObjectiveStars = objectiveStars.map((objectiveUserStar) => {
            // find this objective
            if (objectiveUserStar.objectiveId === objectiveId) {
              const newObjectiveUserStars = { ...objectiveUserStar };
              newObjectiveUserStars.numberOfStars = newStar;
              return newObjectiveUserStars;
            }
            return objectiveUserStar;
          });
          const newCourseUserStars = { ...courseUserStars };
          newCourseUserStars.objectiveStars = [...newObjectiveStars];
          // const newStageStars = [...newObjectiveStars]
          // return newStageStars;
          return newCourseUserStars;
        }
        return courseUserStars;
      }
    );

    // immediately update local data stored in query
    if (newOnlineFrameworkUserStars) {
      queryClient.setQueryData(
        userObjectivesKeys.starsForFramework(course.frameworkVersionId, userId),
        [...newOnlineFrameworkUserStars]
      );
    }

    // Offline course user stars

    const oldOfflineData = queryClient.getQueryData<
      components['schemas']['OfflineDownloadDto']
    >(sessionKeys.offlineData(sessionId, displayLocale));

    const oldOfflineCourseUsersStars = oldOfflineData?.userStars;

    const newOfflineCourseUserStars = oldOfflineCourseUsersStars?.map(
      (courseUserStars) => {
        // find this stage
        if (
          courseUserStars.stageId === stageId &&
          courseUserStars.userId === userId
        ) {
          const { objectiveStars } = courseUserStars;
          if (objectiveStars) {
            const newObjectiveStars = objectiveStars?.map(
              (objectiveUserStar) => {
                // find this objective
                if (objectiveUserStar.objectiveId === objectiveId) {
                  const newObjectiveUserStars = { ...objectiveUserStar };
                  newObjectiveUserStars.numberOfStars = newStar;
                  return newObjectiveUserStars;
                }
                return objectiveUserStar;
              }
            );
            const newCourseUserStars = { ...courseUserStars };
            newCourseUserStars.objectiveStars = [...newObjectiveStars];
            // const newStageStars = [...newObjectiveStars]
            // return newStageStars;
            return newCourseUserStars;
          }
        }
        return courseUserStars;
      }
    );

    const newOfflineData = oldOfflineData;
    if (newOfflineData) {
      newOfflineData.userStars = newOfflineCourseUserStars;
    }
    // immediately update local data stored in query
    if (newOfflineData) {
      queryClient.setQueryData(
        sessionKeys.offlineData(sessionId, displayLocale),
        newOfflineData
      );
    }

    handleMarkObjective({
      learnerId: userId,
      stageId,
      objectiveId,
      numberOfStars: newStar
    });
    // sendMutation(newStar);
  };

  return (
    <fieldset className="stars gap-2 mx-auto">
      {Array.from({ length: NUMBER_OF_STARS }, (v, i) => i).map((e) => {
        const starValue = e + 1;
        return (
          <InputStar
            key={`star ${objectiveId} ${starValue}`}
            userStarValue={objectiveUserStars?.numberOfStars || 0}
            inputStarValue={starValue}
            objectiveId={objectiveId}
            objectiveStageId={objectiveStageId}
            userCurrentStageId={userCurrentStageId}
            userId={userId}
            handleChange={({ objectiveId, star }) =>
              handleChange({ objectiveId, star })
            }
          />
        );
      })}
    </fieldset>
  );
}

export default SingleObjectiveInput;
