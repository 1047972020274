import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getProviderSchool,
  updateProviderSchool
} from '../../services/api/provider.service';
import { components } from '../../types/openapi/ProviderService';
import { Subset } from '../../types/Subset.type';
import schoolKeys from '../../query-keys/schools-key-factory';
import { FilteringObject } from '../../const/filtering-object';
import { getCoursesForSchool } from '../../services/api/course.service';

// hook for managing a single course
const useSchool = ({
  schoolId,
  providerId,
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  schoolId: number;
  providerId: number;
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  if (!schoolId) {
    throw Error('invalid school id');
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const queryClient = useQueryClient();

  const schoolQuery = useQuery({
    queryKey: schoolKeys.school(schoolId, displayLocale),
    queryFn: () => getProviderSchool(providerId, schoolId, displayLocale),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!schoolId && !!providerId
  });

  const updateSchool = useMutation(
    (newSchool: Subset<components['schemas']['SchoolDto']>) => {
      return updateProviderSchool(
        providerId,
        schoolId,
        newSchool,
        displayLocale
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          schoolKeys.school(schoolId, displayLocale),
          data
        );
      }
    }
  );

  const coursesForSchoolQuery = useQuery({
    queryKey: schoolKeys.courseList(
      schoolId,
      displayLocale,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ),
    queryFn: () =>
      getCoursesForSchool(
        schoolId,
        pageIndex,
        pageSize,
        sortField,
        sortDirection,
        filtering,
        displayLocale
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!schoolId
  });

  return {
    schoolQuery,
    updateSchool,
    coursesForSchoolQuery
  };
};

export default useSchool;
