/* eslint-disable no-console */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { Link, useRouteError } from 'react-router-dom';

function ErrorPage() {
  const error = useRouteError();
  const intl = useIntl();
  console.error(error);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.error',
            defaultMessage: 'Error'
          })}
        </title>
      </Helmet>
      <div className="row gap-2 gap-md-0">
        <div className="col-12">
          <h1>
            <FormattedMessage id="error.title" defaultMessage="Uh Oh" />
          </h1>
          <p>
            <FormattedMessage
              id="error.general"
              defaultMessage="Something went wrong"
            />
          </p>
          <div className="row mb-3">
            <div className="col-12">
              <div className="card border-0 rounded-top-right-lg">
                <div className="card-header rounded-top-right-lg">
                  <h2>
                    {' '}
                    <FormattedMessage
                      id="error.page.try.links"
                      defaultMessage="Some useful links:"
                    />
                  </h2>
                </div>
                <div className="card-body">
                  <Link to="/">
                    <FormattedMessage
                      id="home.button"
                      defaultMessage="Home"
                      description="Home"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
