/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import { components } from '../../types/openapi/ProviderService';
import localiseRoutePath from '../../utils/localiseRoutePath';
import Loading from '../common/Loading';
import CentreForm from './CentreForm';
import useCentre from '../../hooks/centre/useCentre';

function EditCentre() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const { centreId } = useParams();
  const navigate = useNavigate();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const userProviderId = profile?.providerId;
  const navLinkCentre = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Centres
  )}/${centreId}`;

  if (!userProviderId) {
    return <GeneralError />;
  }
  const { centreQuery, updateCentre } = useCentre({
    centreId: Number(centreId),
    providerId: userProviderId,
    displayLocale
  });

  const onSubmit = (
    centre: Pick<
      components['schemas']['CentreDto'],
      'name' | 'description' | 'address'
    >
  ) => {
    const { name, description, address } = centre;
    if (centre.name && userProviderId) {
      address!.addressId = centreQuery.data?.address?.addressId;
      const newCentre: Partial<components['schemas']['CentreDto']> = {
        name,
        description,
        address,
        addressId: centreQuery.data?.addressId,
        centreId: Number(centreId),
        providerId: userProviderId,
        facilities: centreQuery.data?.facilities
      };
      updateCentre.mutate(newCentre, {
        onSuccess: () => {
          toast.success(
            intl.formatMessage({
              id: 'centre.edit.success',
              defaultMessage: 'Centre has been updated'
            }),
            { delay: 200 }
          );
          navigate(navLinkCentre);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onError: (error: any) => {
          toast.error(
            intl.formatMessage({
              id: 'centre.edit.error',
              defaultMessage: 'There was an error editing this centre'
            }),
            { delay: 200 }
          );
        }
      });
    }
  };
  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.centre.edit',
            defaultMessage: 'Edit centre'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="centre.edit"
            defaultMessage="Edit Centre"
            description="Edit Centre"
          />
        </h1>
      </div>
      <div className="card-body">
        {centreQuery.isFetching ? (
          <Loading />
        ) : centreQuery.error ? (
          <GeneralError />
        ) : centreQuery.data ? (
          <CentreForm
            onSubmit={onSubmit}
            submitButtonMessage={
              <FormattedMessage
                id="centre.save_changes.button"
                defaultMessage="Save changes"
                description="Save changes"
              />
            }
            name={centreQuery.data.name}
            description={centreQuery.data.description}
            address={centreQuery.data.address}
            formDisabled={centreQuery.isLoading || updateCentre.isLoading}
          />
        ) : (
          <FormattedMessage
            id="centre.error.none"
            defaultMessage="No centre found"
            description="No courses found"
          />
        )}
      </div>
    </div>
  );
}

export default EditCentre;
