import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components, SingleValue } from 'react-select';
import { components as courseComponents } from '../../../../../../types/openapi/CourseService';

function NoOptionsMessage(props: any) {
  return (
    <components.NoOptionsMessage {...props}>
      <span>
        <FormattedMessage
          id="stage.options.none"
          defaultMessage="No options."
          description="No options."
        />{' '}
      </span>
    </components.NoOptionsMessage>
  );
}

function CourseStageSelect({
  frameworkVersionId,
  courseStages,
  onChangeStage
}: {
  frameworkVersionId: number;
  courseStages:
    | courseComponents['schemas']['CourseStageDto'][]
    | courseComponents['schemas']['CourseStageSlimDto'][];
  onChangeStage: (
    stageOption: SingleValue<{
      label: string | undefined;
      value: number | undefined;
    }>
  ) => void;
}) {
  const intl = useIntl();

  const stageOptions = courseStages.map((courseStage) => {
    const { stage } = courseStage;
    if (stage) {
      const { name, stageId } = stage;
      return {
        label: name || '',
        value: stageId
      };
    }
    return {
      label: '',
      value: courseStage.stageId
    };
  });

  const handleChange = (
    stageOption: SingleValue<{
      label: string | undefined;
      value: number | undefined;
    }>
  ) => onChangeStage(stageOption);

  return (
    <Select
      aria-label={intl.formatMessage({
        id: 'stage.select',
        defaultMessage: 'Select Stage'
      })}
      components={{ NoOptionsMessage }}
      defaultValue={stageOptions[0]}
      id="stages"
      isSearchable={false}
      isClearable={false}
      key={`stages_select_key__${frameworkVersionId}`}
      menuPlacement="auto"
      onChange={(newValue) => handleChange(newValue)}
      options={stageOptions}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 })
      }}
    />
  );
}

export default CourseStageSelect;
