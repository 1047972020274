import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { components } from '../../../../types/openapi/CourseService';
import useRemoveLearnerFromSession from '../../../../hooks/session/useRemoveLearnerFromSession';

function RemoveLearnerFromSessionBtn({
  courseId,
  sessionId,
  displayLocale,
  learner
}: {
  courseId: number;
  sessionId: number;
  displayLocale: string | undefined;
  learner: components['schemas']['UserDto'];
}) {
  const intl = useIntl();

  const { removeUserFromCourseSessionMutation } = useRemoveLearnerFromSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    displayLocale
  });

  const handleRemoveLearner = () => {
    removeUserFromCourseSessionMutation.mutate(
      { userId: Number(learner.userId) },
      {
        onSuccess: () => {
          const toastId = 'successToast';
          toast.success(
            intl.formatMessage({
              id: 'session.learner.remove.success',
              defaultMessage:
                'The selected learner has successfully been removed from the session'
            }),
            { delay: 200, toastId }
          );
        },
        onError: (error) => {
          const errorToastId = 'errorToast';
          console.error(error);
          toast.error(
            intl.formatMessage({
              id: 'session.learner.remove.error',
              defaultMessage:
                'There was an error removing this learner from this session'
            }),
            { delay: 200, toastId: errorToastId }
          );
        }
      }
    );
  };
  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      disabled={removeUserFromCourseSessionMutation.isLoading}
      onClick={() => handleRemoveLearner()}
    >
      <FormattedMessage
        id="remove"
        defaultMessage="Remove"
        description="Remove"
      />
    </button>
  );
}

export default RemoveLearnerFromSessionBtn;
