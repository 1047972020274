import { AppLanguage } from '../const';

const learnerKeys = {
  // everything to do with learners (independent of their sessions)
  all: (displayLocale: string | undefined) =>
    ['learners', displayLocale || AppLanguage.English] as const,
  // list of learners
  list: (
    displayLocale: string | undefined,
    pageIndex?: number | null | undefined,
    pageSize?: number | null | undefined,
    sorting?: any | null | undefined,
    filtering?: any | null | undefined
  ) =>
    [
      ...learnerKeys.all(displayLocale || AppLanguage.English),
      pageIndex,
      pageSize,
      sorting,
      filtering,
      'list'
    ] as const,
  dashboard: (displayLocale: string | undefined) =>
    [
      ...learnerKeys.all(displayLocale || AppLanguage.English),
      'dashboard'
    ] as const,
  // list of learners for instructor
  instructorList: (
    instructorId: number,
    displayLocale: string | undefined,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [
      ...learnerKeys.list(displayLocale || AppLanguage.English),
      'instructor',
      instructorId
    ];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  },
  providerList: (
    providerId: number,
    displayLocale: string | undefined,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [
      ...learnerKeys.list(displayLocale || AppLanguage.English),
      'provider',
      providerId
    ];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  },
  providerListDashboard: (
    providerId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...learnerKeys.dashboard(displayLocale || AppLanguage.English),
      'provider',
      providerId
    ] as const,
  providerCentreList: (
    providerId: number,
    centreId: number,
    displayLocale: string | undefined,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [
      ...learnerKeys.list(displayLocale || AppLanguage.English),
      'provider',
      providerId,
      'centre',
      centreId
    ];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  },
  providerCentreListDashboard: (
    providerId: number,
    centreId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...learnerKeys.dashboard(displayLocale || AppLanguage.English),
      'provider',
      providerId,
      'centre',
      centreId
    ] as const,
  learner: (userId: number, displayLocale: string | undefined) =>
    [
      'single-learner',
      ...learnerKeys.all(displayLocale || AppLanguage.English),
      userId
    ] as const
};

export default learnerKeys;
