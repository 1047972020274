import React from 'react';
import { components } from '../../types/openapi/CourseService';

function CourseStagesList({
  courseStages
}: {
  courseStages:
    | components['schemas']['CourseStageDto'][]
    | components['schemas']['CourseStageSlimDto'][];
}) {
  return (
    <>
      {courseStages
        .map((courseStage) => courseStage.stage?.name)
        .filter((el) => !!el)
        .join(', ')}
    </>
  );
}

export default CourseStagesList;
