/* eslint-disable @typescript-eslint/no-shadow */
import React, { Fragment, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ObjectiveWithStars } from '../../../../types/ObjectiveWithStars.type';
import ObjectiveSnakeImage from './ObjectiveSnakeImage';

function Objective({
  objective,
  renderStarElement,
  renderLinkElement
}: {
  objective: ObjectiveWithStars;
  renderStarElement: ({
    userStarValue,
    inputStarValue,
    objective
  }: {
    userStarValue: number;
    inputStarValue: number;
    objective: ObjectiveWithStars;
  }) => JSX.Element | ReactNode;
  renderLinkElement?: ({
    objective
  }: {
    objective: ObjectiveWithStars;
  }) => JSX.Element | ReactNode;
}) {
  const NUMBER_OF_STARS = 3;
  const intl = useIntl();

  return (
    <li
      key={objective.objectiveId}
      className="snake-li"
      data-id={objective.objectiveId}
    >
      <div className="list-item">
        <div className="list-item-snake">
          <ObjectiveSnakeImage imgSrc={objective.imgSrc} />

          <div className="list-item-line" />
        </div>
        <div className="flex-grow-1">
          <div className="list-item-other d-flex flex-column flex-xl-row w-100 justify-content-between">
            <div className="flex-grow-1 w-100">
              <h4>{objective.name}</h4>
              <p>{objective.description}</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="me-4">
                <div className="stars gap-2">
                  {Array.from({ length: NUMBER_OF_STARS }, (v, i) => i).map(
                    (e) => {
                      const starValue = e + 1;
                      return (
                        <Fragment key={e}>
                          {renderStarElement({
                            objective,
                            inputStarValue: starValue,
                            userStarValue: objective.stars
                          })}
                        </Fragment>
                      );
                    }
                  )}
                  <span className="visually-hidden">
                    {intl.formatMessage(
                      {
                        id: 'stars.num-achieved',
                        defaultMessage:
                          '{achievedStars} / {numStars} stars achieved',
                        description: 'num / num stars achieved'
                      },
                      {
                        achievedStars: objective.stars,
                        numStars: NUMBER_OF_STARS
                      }
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {renderLinkElement &&
                renderLinkElement({
                  objective
                })}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
export default Objective;

Objective.defaultProps = {
  renderLinkElement: () => {}
};
