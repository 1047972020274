import { UserTypeStrings } from './base-strings';

export const cy: UserTypeStrings = {
  'usertype.superadmin': 'Uwch gweinyddwr',
  'usertype.sportmanager': 'Rheolwr chwaraeon',
  'usertype.admin': 'Gweinyddwr',
  'usertype.instructor': 'Hyfforddwr',
  'usertype.learner': 'Dysgwr',
  'usertype.groupadmin': 'Gweinyddwr grŵp'
};

export default cy;
