import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { UserType } from '../../const/user-type';
import AppMode from '../../modules/offline/app-mode.enum';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import { appStrings } from '../../modules/i18n';
import { AppLanguage, AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function CoursesListCourseLink({ courseId }: { courseId: number }) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const isNetworkOnline = useNetworkStatus();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;

  return (
    <Link
      to={`${appStrings[langVal][AppRoute.Courses]}/${courseId}`}
      className={`btn btn-outline-secondary ${
        profile?.userTypeId === UserType.INSTRUCTOR &&
        (appMode === AppMode.OFFLINE || !isNetworkOnline) &&
        'disabled'
      }`}
    >
      <FormattedMessage
        id="view.course.progress"
        defaultMessage="View Course Progress"
        description="View Course Progress"
      />
    </Link>
  );
}

export default CoursesListCourseLink;
