import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import OfflineViewLearnerOnCourse from '../offline/offlineModePages/OfflineViewLearnerOnCourse';
import OnlineViewLearnerOnCourse from '../offline/onlineModePages/OnlineViewLearnerOnCourse';

function ViewLearnerOnCourse() {
  const { sessionId } = useParams();

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  if (appMode === AppMode.OFFLINE && !!sessionId)
    return <OfflineViewLearnerOnCourse sessionId={Number(sessionId)} />;

  return <OnlineViewLearnerOnCourse />;
}
export default ViewLearnerOnCourse;
