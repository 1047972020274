import { useIntl } from 'react-intl';
import progressKeys from '../../query-keys/progress-key-factory';
import sessionKeys from '../../query-keys/session-key-factory';
import userObjectivesKeys from '../../query-keys/user-objectives-key-factory';

export default function getMutationType({
  mutationKey,
  frameworkVersionId,
  learnerId,
  sessionId,
  displayLocale
}: {
  mutationKey: string | undefined;
  frameworkVersionId: number | undefined;
  learnerId: number | undefined;
  sessionId: number | undefined;
  displayLocale: string;
}) {
  const intl = useIntl();

  switch (mutationKey) {
    case progressKeys
      .progressFrameworkMutation(frameworkVersionId, learnerId)
      .toString():
      return intl.formatMessage({
        id: 'mutation.progress',
        defaultMessage: 'Progress'
      });
      break;
    case progressKeys
      .undoProgressFrameworkMutation(frameworkVersionId, learnerId)
      .toString():
      return intl.formatMessage({
        id: 'mutation.progress.undo',
        defaultMessage: 'Undo progress'
      });
      break;

    case sessionKeys.attendance(sessionId, displayLocale).toString():
      return intl.formatMessage({
        id: 'mutation.attendance',
        defaultMessage: 'Attendance'
      });
      break;

    case userObjectivesKeys.sessionStars(sessionId).toString():
      return intl.formatMessage({
        id: 'mutation.stars',
        defaultMessage: 'Stars'
      });
      break;

    default:
      return intl.formatMessage({
        id: 'mutation.unknown',
        defaultMessage: 'Unknown'
      });
      break;
  }
}
