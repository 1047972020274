import { useQuery } from '@tanstack/react-query';
import { AppLanguage } from '../../const';
import frameworkKeys from '../../query-keys/framework-key-factory';
import { getCoursesFrameworksForProvider } from '../../services/api/course.service';

// hook for frameworks filter for courses
const useCoursesFrameworks = ({
  providerId,
  displayLocale = AppLanguage.English
}: {
  providerId: number | null | undefined;
  displayLocale: string | undefined;
}) => {
  if (!providerId) {
    throw Error('invalid id');
  }

  const coursesFrameworksQuery = useQuery({
    queryKey: frameworkKeys.coursesFrameworks(providerId, displayLocale),
    queryFn: () => getCoursesFrameworksForProvider(providerId, displayLocale),
    refetchOnMount: true,
    enabled: !!providerId
  });

  return {
    coursesFrameworksQuery
  };
};

export default useCoursesFrameworks;
