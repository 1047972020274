import React from 'react';
import { Control, useController } from 'react-hook-form';
// @see https://react-hook-form.com/docs/usecontroller#example
function Checkboxes({
  options,
  control,
  name
}: {
  options: { value: string | number; label: string }[];
  control: Control<any, any>;
  name: string;
}) {
  const { field } = useController({
    control,
    name
  });
  const [value, setValue] = React.useState(field.value || []);

  return (
    <>
      {options.map((option, index: number) => (
        <div className="form-check p-0">
          <input
            id={`option-${index}`}
            className="btn-check"
            onChange={(e) => {
              const valueCopy = [...value];

              // update checkbox value
              valueCopy[index] = e.target.checked ? e.target.value : null;

              // send data to react hook form
              field.onChange(valueCopy);

              // update local state
              setValue(valueCopy);
            }}
            key={option.value}
            type="checkbox"
            checked={value.includes(option.value)}
            value={option.value}
          />
          <label
            className={`btn btn-outline-secondary ${
              index !== options.length - 1 ? 'border-end-0' : ''
            } ${value.includes(option) ? 'text-white' : ''}`}
            htmlFor={`option-${index}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </>
  );
}

export default Checkboxes;
