import { ColumnDef, Table } from '@tanstack/react-table';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { GeneralError, Loading } from '../common';
import OffCanvasFilters from './filters/offcanvas/OffCanvasFilters';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TablePaginationFooter from './TablePaginationFooter';

function PaginatedTable({
  table,
  error,
  filtering,
  sorting,
  resetFilters,
  columns,
  dataCount,
  refetching,
  isLoading,
  queryParamsLocation,
  dataId,
  noneFoundMessage,
  showFooter
}: {
  table: Table<any>;
  error: any;
  filtering: any[] | undefined;
  sorting: any;
  resetFilters: () => void;
  columns: ColumnDef<any>[];
  dataCount: number | undefined;
  refetching: boolean;
  isLoading: boolean;
  queryParamsLocation: string;
  dataId: string;
  noneFoundMessage: React.ReactElement;
  showFooter: boolean;
}) {
  const [showFilter, setShowFilter] = useState(false);

  const handleCloseFilters = () => setShowFilter(false);
  const handleShowFilters = () => setShowFilter(true);

  const filterSessions = (values: FieldValues) => {
    columns.forEach((column) => {
      if (column.id) {
        const tableColumn = table.getColumn(column.id);
        if (values[column.id]) {
          tableColumn?.setFilterValue(values[column.id]);
        }
        if (column.id === values.sorting) {
          tableColumn?.toggleSorting(false);
        }
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-end my-3 ">
        <Button
          variant="outline-secondary"
          className="table-offcanvas-btn"
          onClick={handleShowFilters}
        >
          <FormattedMessage
            id="filter"
            defaultMessage="Filter"
            description="Filter"
          />
          <FontAwesomeIcon
            className="pe-1"
            icon={icon({ name: 'filter', style: 'solid' })}
          />
        </Button>
      </div>
      <OffCanvasFilters
        show={showFilter}
        filtering={filtering}
        sorting={sorting}
        onSubmit={filterSessions}
        resetFilters={resetFilters}
        handleCloseFilter={handleCloseFilters}
        table={table}
        columns={columns}
      />
      <div className="table-responsive">
        <table className="table card-list-table">
          <TableHead
            table={table}
            error={error}
            filtering={filtering}
            resetFilters={resetFilters}
            columns={columns}
            dataCount={dataCount}
          />
          <TableBody table={table} refetching={refetching} dataId={dataId} />
        </table>
      </div>
      {isLoading ? (
        <Loading />
      ) : error && (error as any)?.response?.status !== 404 ? (
        <GeneralError error={error} />
      ) : dataCount === 0 ||
        (error && (error as any)?.response?.status === 404) ? (
        <section className="alert alert-warning">{noneFoundMessage}</section>
      ) : (
        showFooter && (
          <TablePaginationFooter
            table={table}
            totalRows={dataCount ?? 0}
            queryParamsLocation={queryParamsLocation}
          />
        )
      )}
    </>
  );
}

export default PaginatedTable;
