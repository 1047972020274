import React from 'react';
import { SingleValue } from 'react-select';

import ObjectiveOption from './ObjectiveOption.interface';
import { components } from '../../../../../../types/openapi/CourseService';
import ObjectiveSelect from './ObjectiveSelect';
import { GeneralError } from '../../../../../common';

function ObjectiveSelectWrapper({
  course,
  defaultObjective,
  groupedOptions,
  onChangeObjective
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto']
    | undefined;
  defaultObjective: SingleValue<ObjectiveOption> | undefined;
  groupedOptions: {
    label: string;
    stageId: number | undefined;
    options: {
      value: string | undefined;
      label: string;
      stageId: number | undefined;
    }[];
  }[];
  onChangeObjective: (objective: SingleValue<ObjectiveOption>) => void;
}) {
  if (course && !course.frameworkVersion?.frameworkId) {
    return (
      <GeneralError message="Course framework ID is missing - please contact your provider" />
    );
  }

  return (
    <ObjectiveSelect
      groupedOptions={groupedOptions}
      defaultObjective={defaultObjective}
      onChangeObjective={(selectedObjective) => {
        return onChangeObjective(selectedObjective);
      }}
    />
  );
}

export default ObjectiveSelectWrapper;
