/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl
} from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faBuilding,
  faClock
} from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';

import { ProfileContext } from '../../../../../modules/profile/ProfileProvider';
import { convertDateTime, TIMEZONE } from '../../../../../utils/date-time';
import Loading from '../../../../common/Loading';
import DataLastUpdated from '../../../../offline/DataLastUpdated';
import InfoPanel from '../../../../ui/InfoPanel';
import { GeneralError } from '../../../../common';
import InstructorSessionTabsWrapper from './InstructorSessionTabsWrapper';
import { components } from '../../../../../types/openapi/CourseService';

function InstructorSession({
  session,
  sessionCapacity,
  availableSpaces,
  sessionMembersSorted,
  sessionMembersError,
  sessionMembersIsLoading,
  sessionDataUpdatedAt,
  course,
  courseName,
  centreName,
  facilityName,
  courseStages,
  frameworkName
}: {
  session:
    | components['schemas']['CourseSessionDto']
    | components['schemas']['SessionSlimDto'];
  sessionCapacity: number | undefined;
  availableSpaces: number | undefined;
  sessionMembersSorted:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][]
    | undefined
    | null;
  sessionMembersError: any;
  sessionMembersIsLoading: boolean;
  sessionDataUpdatedAt: number;
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  courseName: string | undefined | null;
  centreName: string | undefined;
  facilityName: string | undefined;
  courseStages:
    | components['schemas']['CourseStageDto'][]
    | components['schemas']['CourseStageSlimDto'][]
    | undefined
    | null;
  frameworkName: string | undefined;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  const displayStartTime = convertDateTime(session?.sessionStartTime);
  const startTimeAsDate = displayStartTime.toDate();
  const displayEndTime = convertDateTime(session?.sessionEndTime);
  const endTimeAsDate = displayEndTime.toDate();
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.course.name',
              defaultMessage: 'Course: {name}'
            },
            { name: courseName }
          )}
        </title>
      </Helmet>
      <div className="card border-0 rounded-top-right-lg mb-2">
        <div className="card-header d-flex">
          <div className="flex-grow-1">
            {' '}
            <h1>
              <FormattedMessage
                id="course.name"
                defaultMessage="Course: {name}"
                description="Course: {name}"
                values={{ name: courseName }}
              />
            </h1>
          </div>
          <div>
            <DataLastUpdated
              dataUpdatedAt={sessionDataUpdatedAt}
              className="text-muted"
            />
          </div>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4">
                <InfoPanel faIcon={<FontAwesomeIcon icon={faClock} />}>
                  <>
                    {' '}
                    <FormattedDate
                      value={startTimeAsDate}
                      timeZone={TIMEZONE}
                    />
                    <br />
                    <FormattedTime
                      value={startTimeAsDate}
                      timeZone={TIMEZONE}
                    />
                    &mdash;
                    <FormattedTime value={endTimeAsDate} timeZone={TIMEZONE} />
                  </>
                </InfoPanel>
              </div>
              <div className="col-12 col-md-4">
                <InfoPanel faIcon={<FontAwesomeIcon icon={faBuilding} />}>
                  <>
                    {' '}
                    {centreName}
                    {facilityName}
                  </>
                </InfoPanel>
              </div>
              <div className="col-12 col-md-4">
                <InfoPanel faIcon={<FontAwesomeIcon icon={faBookOpen} />}>
                  <>
                    {frameworkName}
                    <br />
                    {courseStages?.map((el: any) => el.stage?.name).join(', ')}
                  </>
                </InfoPanel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card border-0 rounded-top-right-lg mb-2">
        <div className="card-header d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h2>
              <FormattedMessage
                id="learners"
                defaultMessage="Learners"
                description="Learners"
              />
            </h2>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <FormattedMessage
                id="session.capacity"
                defaultMessage="{capacity} {availableSpaces}/{sessionCapacity}"
                description="Capacity on course session"
                values={{
                  capacity: (
                    <strong>
                      <FormattedMessage
                        id="capacity"
                        defaultMessage="Capacity"
                        description="Capacity"
                      />
                    </strong>
                  ),
                  availableSpaces,
                  sessionCapacity
                }}
              />
            </div>
          </div>
        </div>

        <div className="card-body">
          {sessionMembersSorted ? (
            <InstructorSessionTabsWrapper
              course={course}
              instructorId={profile.userId}
              session={session}
              learners={sessionMembersSorted}
            />
          ) : sessionMembersError &&
            sessionMembersError.response.status !== 404 ? (
            <GeneralError />
          ) : sessionMembersIsLoading ? (
            <Loading />
          ) : (
            <FormattedMessage
              id="learners.none_booked"
              defaultMessage="No learners booked on this session. Please contact your administrator to add learners"
              description="No learners booked on this session. Please contact your administrator to add learners"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default InstructorSession;
