import React from 'react';
import { components } from '../../types/openapi/ProviderService';

function CentreAddress({
  address
}: {
  address: components['schemas']['AddressDto'] | undefined;
}) {
  if (!address) {
    return <span className="text-muted">&ndash;</span>;
  }
  const {
    postalCode,
    buildingNumberName,
    streetName,
    townCity,
    county,
    countryRegion
  } = address;
  return (
    <address>
      {buildingNumberName && (
        <>
          {buildingNumberName}
          <br />
        </>
      )}
      {streetName && (
        <>
          {streetName}
          <br />
        </>
      )}
      {townCity && (
        <>
          {townCity}
          <br />
        </>
      )}
      {county && (
        <>
          {county}
          <br />
        </>
      )}
      {countryRegion && (
        <>
          {countryRegion}
          <br />
        </>
      )}
      {postalCode && (
        <>
          {postalCode}
          <br />
        </>
      )}
    </address>
  );
}

export default CentreAddress;
