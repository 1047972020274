import React, { Dispatch, SetStateAction } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

function VisibleColumnHeaderToggles({
  visibleColumnHeader,
  attendanceColumnId,
  objectivesColumnId,
  setVisibleColumnHeader
}: {
  visibleColumnHeader: string | undefined;
  attendanceColumnId: string;
  objectivesColumnId: string;
  setVisibleColumnHeader: Dispatch<SetStateAction<string | undefined>>;
}) {
  return (
    <div className="d-flex justify-content-center mb-3">
      <ButtonGroup>
        <ToggleButton
          key={attendanceColumnId}
          id="radio-0"
          type="radio"
          variant="outline-secondary"
          name="radio"
          value={attendanceColumnId}
          checked={visibleColumnHeader === attendanceColumnId}
          onChange={(e) => setVisibleColumnHeader(e.currentTarget.value)}
        >
          <FormattedMessage
            id="attendance.mark"
            defaultMessage="Mark attendance"
            description="Mark attendance"
          />
        </ToggleButton>
        <ToggleButton
          key={objectivesColumnId}
          id="radio-1"
          type="radio"
          variant="outline-secondary"
          name="radio"
          value={objectivesColumnId}
          checked={visibleColumnHeader === objectivesColumnId}
          onChange={(e) => setVisibleColumnHeader(e.currentTarget.value)}
        >
          <FormattedMessage
            id="objectives.mark"
            defaultMessage="Mark objectives"
            description="Mark objectives"
          />
        </ToggleButton>
      </ButtonGroup>
    </div>
  );
}
export default VisibleColumnHeaderToggles;
