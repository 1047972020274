/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { createContext, useCallback, useState } from 'react';
import AppMode from './app-mode.enum';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import { StorageId } from '../../const/storage-id';

interface Offline {
  appMode: AppMode;
  updateAppMode: (mode: AppMode) => void;
}

export const OfflineContext = createContext<Offline>({
  appMode: AppMode.ONLINE,
  updateAppMode: () => {}
});

function OfflineProvider(props: { children: any }) {
  const { children } = props;
  const isOnline = useNetworkStatus();

  const [appMode, setAppMode] = useState<AppMode>(() => {
    try {
      // Get from local storage by key - if app is returning from offline/refresh
      const item = window.sessionStorage.getItem(StorageId.APP_MODE);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : null;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return null;
    }
  });

  const updateAppMode = useCallback((mode: AppMode) => {
    try {
      // Save state
      if (mode === AppMode.ONLINE) {
        // can only set online if actual network connection
        if (isOnline) {
          setAppMode(() => AppMode.ONLINE);
        } else {
          console.error('No connection - cannot set online mode currently');
          setAppMode(() => AppMode.OFFLINE);
          // Save to local storage
          window.sessionStorage.setItem(
            StorageId.APP_MODE,
            JSON.stringify(AppMode.OFFLINE)
          );
        }
      }
      // Save to local storage
      window.sessionStorage.setItem(StorageId.APP_MODE, JSON.stringify(mode));
      setAppMode(mode);
    } catch (error) {
      // handle the error case
      console.error(error);
    }
  }, []);

  return (
    <OfflineContext.Provider
      value={{
        appMode,
        updateAppMode
      }}
    >
      {children}
    </OfflineContext.Provider>
  );
}

export default OfflineProvider;
