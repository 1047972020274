import dayjs from 'dayjs';
// https://day.js.org/docs/en/parse/string
// For consistent results parsing anything other than ISO 8601 strings, you should use String + Format.
// example of what returns from server: 2023-09-04T11:05:00
const SP2_FORMAT = 'YYYY-MM-DD[T]hh:mm:ss';
// convert from server time (assumed UTC) to time/dates to display in the browser (GMT/BST)
const convertDateTime = (dateTime: string | undefined): dayjs.Dayjs => {
  const dayjsLocal = dayjs.utc(dateTime, SP2_FORMAT);

  return dayjsLocal;
};

export default convertDateTime;
