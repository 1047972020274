/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { components } from '../../types/openapi/CourseService';
import { AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import localiseRoutePath from '../../utils/localiseRoutePath';
import ObjectiveSnake from './ObjectiveSnake';
import ReadOnlyStar from './ReadOnlyStar';
import { ObjectiveUserStars } from '../../types/ObjectiveUserStars.type';
import {
  isObjective,
  isUserCourseSessionObjectiveDto
} from '../../utils/type-guards';
import useUserSessionObjectives from '../../hooks/useUserSessionObjectives';
import Loading from '../common/Loading';
import { GeneralError } from '../common';

function AllObjectiveUserStars({
  course,
  sessionId,
  userId
}: {
  course: components['schemas']['CourseDto'];
  sessionId: number;
  userId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [objectivesForSnake, setObjectivesForSnake] = useState<
    components['schemas']['ObjectiveDto'][] | null
  >();

  // stars
  const [userStars, setUserStars] = useState<ObjectiveUserStars[] | null>();

  const { userCourseSessionBookingObjectivesQuery } = useUserSessionObjectives({
    courseId: course.courseId,
    userId,
    sessionId,
    displayLocale
  });

  useEffect(() => {
    if (userCourseSessionBookingObjectivesQuery.data) {
      // Objectives
      const sessionObjectives = new Set();
      const uniqueObjectives = userCourseSessionBookingObjectivesQuery.data
        .filter((obj) => {
          const duplicate = sessionObjectives.has(obj.objectiveId);
          sessionObjectives.add(obj.objectiveId);
          return !duplicate;
        })
        .map((obj) => obj.objective)
        .filter(isObjective);

      setObjectivesForSnake(uniqueObjectives);

      // Stars
      const objectivesWithStars = userCourseSessionBookingObjectivesQuery.data
        .filter(isUserCourseSessionObjectiveDto)
        .reduce(
          (
            allIds: {
              [objectiveId: number]: number;
            },
            obj
          ) => {
            const currCount = allIds[obj.objectiveId!] ?? 0;
            return {
              ...allIds,
              [obj.objectiveId!]: currCount + 1
            };
          },
          {}
        );
      const userCurrentProgressStars: ObjectiveUserStars[] = Object.entries(
        objectivesWithStars
      ).map((entry) => {
        return { objectiveId: Number(entry[0]), numberOfStars: entry[1] };
      });
      setUserStars(userCurrentProgressStars);
    }
  }, [userCourseSessionBookingObjectivesQuery.data]);

  return (
    <>
      {userCourseSessionBookingObjectivesQuery.isFetching ? (
        <Loading />
      ) : userCourseSessionBookingObjectivesQuery.error &&
        (userCourseSessionBookingObjectivesQuery.error as any)?.response
          ?.status !== 404 ? (
        <GeneralError error={userCourseSessionBookingObjectivesQuery.error} />
      ) : objectivesForSnake ? (
        <ObjectiveSnake
          objectives={objectivesForSnake}
          userStars={userStars}
          course={course}
          userId={userId}
          renderStarElement={({ userStarValue, inputStarValue }) => {
            return (
              <ReadOnlyStar
                usersStar={userStarValue}
                starValue={inputStarValue}
              />
            );
          }}
          renderLinkElement={({ objective }) => {
            if (
              course.frameworkVersion?.frameworkId &&
              course.frameworkVersionId &&
              objective.stageId &&
              objective.objectiveId
            )
              return (
                <Link
                  to={`/${displayLocale}/${localiseRoutePath(
                    AppRoute.Resources
                  )}/${course.frameworkVersion.frameworkId}/${localiseRoutePath(
                    AppRoute.Versions
                  )}/${course.frameworkVersionId}/${localiseRoutePath(
                    AppRoute.Stages
                  )}/${objective.stageId}/${localiseRoutePath(
                    AppRoute.InstructorViewObjectives
                  )}/${objective.objectiveId}`}
                  className="btn btn-outline-secondary"
                >
                  <FormattedMessage
                    id="resources.button.view"
                    defaultMessage="View resources"
                    description="View resources"
                  />
                </Link>
              );
            return <></>;
          }}
        />
      ) : (
        <FormattedMessage
          id="stars.session.learner.none"
          defaultMessage="You didn't achieve any stars this session."
          description="You didn't achieve any stars this session."
        />
      )}
    </>
  );
}

export default AllObjectiveUserStars;
