import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppLanguage, AppRoute } from '../../const';
import { UserType } from '../../const/user-type';
import useCourse from '../../hooks/course/useCourse';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ModalContext } from '../../modules/modal/ModalProvider';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import { components } from '../../types/openapi/CourseService';
import { components as userComponents } from '../../types/openapi/UserService';
import isAllowed from '../../utils/permissions/isAllowed';
import localiseRoutePath from '../../utils/localiseRoutePath';
import LearnersTable from '../learners-admin/LearnersTable';
import Loading from '../common/Loading';
import useChangeLearnersOnCourse from '../../hooks/course/useChangeLearnersOnCourse';

function LearnersOnCourse() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const { courseId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const modalCtx = useContext(ModalContext);
  const langVal = displayLocale as AppLanguage;

  const { modal } = modalCtx;

  const [availableSpaces, setAvailableSpaces] = useState<number>(-1);

  const { courseQuery, courseSessionsForCourseQuery, learnersForCourseQuery } =
    useCourse({
      courseId: Number(courseId),
      displayLocale
    });

  const { removeLearnerFromCourseMutation } = useChangeLearnersOnCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const openModal = (learnerToRemove: userComponents['schemas']['UserDto']) => {
    const header = intl.formatMessage(
      {
        id: 'learner.remove.confirm.header',
        defaultMessage: 'Remove Learner: {name}',
        description: 'Remove Learner Confirm Modal Header'
      },
      { name: learnerToRemove.firstName || learnerToRemove.username }
    );
    const modalContent = intl.formatMessage({
      id: 'learner.remove.confirm.body',
      defaultMessage:
        'Are you sure you want to remove this learner from all future course sessions? This action cannot be undone',
      description: 'Remove learner Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'learner.remove.confirm.button',
      defaultMessage: 'Remove Learner',
      description: 'Remove Learner Confirm Button'
    });
    modal(modalContent, {
      confirm: async () => {
        // newRemoveLearnerFromCourse(learnerToRemove);
        removeLearnerFromCourseMutation.mutate(learnerToRemove, {
          onSuccess: () => {
            const toastId = 'successToast';
            toast.success(
              intl.formatMessage({
                id: 'learner.course.removed.success',
                defaultMessage:
                  'The selected learners have been removed from all future course sessions'
              }),
              { delay: 200, toastId }
            );
          },
          onError: (error: any) => {
            const errorToastId = 'errorToast';
            console.error(error);
            toast.error(
              intl.formatMessage({
                id: 'learner.course.removed.error',
                defaultMessage:
                  'There was an error removing the selected learners from the course'
              }),
              { delay: 200, toastId: errorToastId }
            );
          }
        });
      },
      header,
      confirmText
    });
  };

  useEffect(() => {
    if (
      courseQuery.data &&
      learnersForCourseQuery.data &&
      courseQuery.data.totalNumberOfPeople
    ) {
      setAvailableSpaces(
        courseQuery.data.totalNumberOfPeople -
          learnersForCourseQuery.data.length
      );
    }
  }, [
    courseQuery.data,
    courseSessionsForCourseQuery.data,
    learnersForCourseQuery.data,
    availableSpaces
  ]);

  if (!courseSessionsForCourseQuery.data) {
    return (
      <div className="card border-0 rounded-top-right-lg mb-3">
        <div className="card-header d-flex justify-content-between">
          <h2>
            <FormattedMessage
              id="learners"
              defaultMessage="Learners"
              description="Learners"
            />
          </h2>
        </div>
        <div className="card-body">
          <GeneralError
            message={
              <FormattedMessage
                id="sessions.none.add"
                defaultMessage="No Sessions Scheduled. Please add sessions in order to add learners"
                description="No Sessions Scheduled. Please add sessions in order to add learners"
              />
            }
          />
        </div>
      </div>
    );
  }
  return (
    <div className="card border-0 rounded-top-right-lg mb-3">
      <div className="card-header d-flex justify-content-between">
        <h2>
          <FormattedMessage
            id="learners"
            defaultMessage="Learners"
            description="Learners"
          />
        </h2>
        <span>
          {courseQuery.isFetching || learnersForCourseQuery.isFetching ? (
            <Loading />
          ) : courseQuery.error ? (
            <GeneralError />
          ) : courseQuery.data &&
            courseQuery.data.totalNumberOfPeople &&
            learnersForCourseQuery.data ? (
            <FormattedMessage
              id="course.spaces.available"
              defaultMessage="Spaces available: {availableSpaces} / {capacity}"
              description="Spaces on course that are available"
              values={{
                availableSpaces,
                capacity: courseQuery.data.totalNumberOfPeople
              }}
            />
          ) : (
            courseQuery.data && (
              <FormattedMessage
                id="course.spaces.available"
                defaultMessage="Spaces available: {availableSpaces} / {capacity}"
                description="Spaces on course that are available"
                values={{
                  availableSpaces: courseQuery.data.totalNumberOfPeople,
                  capacity: courseQuery.data.totalNumberOfPeople
                }}
              />
            )
          )}
        </span>
      </div>
      <div className="card-body">
        {courseSessionsForCourseQuery.data &&
          profile?.userTypeId === UserType.ADMIN && (
            <div className="d-flex justify-content-end">
              <div className="btn-group gap-2 ">
                <Link
                  type="button"
                  className={
                    availableSpaces <= 0 && !!learnersForCourseQuery.data
                      ? 'btn btn-primary disabled'
                      : 'btn btn-primary'
                  }
                  to={appStrings[langVal][AppRoute.AddLearnerToCourse]}
                >
                  <FormattedMessage
                    id="learner.course.add"
                    defaultMessage="Add learner"
                    description="Add learner to course"
                  />
                </Link>

                <Link
                  type="button"
                  className={
                    availableSpaces <= 0 && !!learnersForCourseQuery.data
                      ? 'btn btn-primary disabled'
                      : 'btn btn-primary'
                  }
                  to={appStrings[langVal][AppRoute.AddGroupToCourse]}
                >
                  <FormattedMessage
                    id="group.course.add"
                    defaultMessage="Add group"
                    description="Add group to course"
                  />
                </Link>
              </div>
            </div>
          )}

        <div className="mt-2">
          {learnersForCourseQuery.data?.length === 0 ? (
            <GeneralError
              message={
                <FormattedMessage
                  id="learner.none.add"
                  defaultMessage="No Learners Assigned to Course. Please Add Learners"
                  description="No Learners Assigned to Course. Please Add Learners"
                />
              }
            />
          ) : learnersForCourseQuery.data &&
            learnersForCourseQuery.data?.length > 0 ? (
            <LearnersTable
              learners={learnersForCourseQuery.data}
              actions={learnersForCourseQuery.data.map(
                (learner: components['schemas']['UserDto']) => {
                  return (
                    <div className="btn-group">
                      {isAllowed(
                        [UserType.INSTRUCTOR],
                        profile?.userTypeId as UserType
                      ) && (
                        <Link
                          to={`${localiseRoutePath(AppRoute.Learners)}/${
                            learner.userId
                          }`}
                          className="btn btn-outline-secondary"
                        >
                          <FormattedMessage
                            id="view"
                            defaultMessage="View"
                            description="View"
                          />
                        </Link>
                      )}
                      {isAllowed(
                        [UserType.ADMIN],
                        profile?.userTypeId as UserType
                      ) && (
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={() => openModal(learner as any)}
                        >
                          <FormattedMessage
                            id="learners.course.remove"
                            defaultMessage="Remove"
                            description="Remove"
                          />
                        </button>
                      )}
                    </div>
                  );
                }
              )}
            />
          ) : learnersForCourseQuery.error ? (
            (learnersForCourseQuery.error as any).response?.data ===
            'No Members Found Found' ? (
              <GeneralError
                message={
                  <FormattedMessage
                    id="learner.none.add"
                    defaultMessage="No Learners Assigned to Course. Please Add Learners"
                    description="No Learners Assigned to Course. Please Add Learners"
                  />
                }
              />
            ) : (
              <GeneralError />
            )
          ) : learnersForCourseQuery.error ? (
            (learnersForCourseQuery.error as any).response?.data ===
            'No Members Found Found' ? (
              <GeneralError
                message={
                  <FormattedMessage
                    id="learner.none.add"
                    defaultMessage="No Learners Assigned to Course. Please Add Learners"
                    description="No Learners Assigned to Course. Please Add Learners"
                  />
                }
              />
            ) : (
              <GeneralError />
            )
          ) : learnersForCourseQuery.isLoading ? (
            <Loading />
          ) : (
            <div>
              <FormattedMessage
                id="learners.course.none.added"
                defaultMessage="No learners have been added to this course"
                description="No learners have been added to this course"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LearnersOnCourse;
