import { Mutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import useSyncLogQueryData from '../../../hooks/useSyncLogQueryData';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import getMutationType from '../../../utils/sync-logs/getMutationType';

function SyncErrorTable({
  mutations,
  caption,
  renderBtns
}: {
  mutations: Mutation<unknown, unknown, void, unknown>[] | undefined;
  caption: JSX.Element;
  renderBtns: (mutation: Mutation) => JSX.Element;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const generateErrorCode = ({
    courseId,
    frameworkVersionId,
    learnerId,
    sessionId,
    stageId,
    responseStatus,
    mutationType
  }: {
    courseId: number | undefined;
    frameworkVersionId: number | undefined;
    learnerId: number | undefined;
    sessionId: number | undefined;
    stageId: number | undefined;
    responseStatus: number | string;
    mutationType: string;
  }) => {
    const codeSections = [
      mutationType.substring(0, 1),
      courseId && `c-${courseId}`,
      sessionId && `se-${sessionId}`,
      frameworkVersionId && `f-${frameworkVersionId}`,
      learnerId && `l-${learnerId}`,
      stageId && `st-${stageId}`,
      responseStatus
    ];

    return <span>{codeSections.filter((section) => !!section).join('-')}</span>;
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped caption-top">
        <caption className="h4 text-black">{caption}</caption>
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="type"
                defaultMessage="Type"
                description="Type"
              />
            </th>
            <th>
              <FormattedMessage
                id="error.data"
                defaultMessage="Data"
                description="Data"
              />
            </th>
            <th>
              <FormattedMessage
                id="time"
                defaultMessage="Time"
                description="Time"
              />
            </th>
            <th>
              <FormattedMessage
                id="error.code"
                defaultMessage="Error code"
                description="Error code"
              />
            </th>
            <th>
              {' '}
              <FormattedMessage id="actions" defaultMessage="Actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {mutations?.map((mutation) => {
            const { mutationKey } = mutation.options;
            const { variables } = mutation.state as any;

            const {
              courseId,
              frameworkVersionId,
              learnerId,
              sessionId,
              stageId,
              courseData,
              sessionData,
              learnerData,
              stagesData
            } = useSyncLogQueryData(variables, displayLocale);

            const mutationType = getMutationType({
              mutationKey: mutationKey?.toString(),
              frameworkVersionId,
              learnerId,
              sessionId,
              displayLocale
            });

            return (
              <tr key={mutation.mutationId}>
                <td>{`${mutationType}`}</td>
                <td>
                  <ul className="list-unstyled">
                    {courseData && <li>{courseData.name}</li>}
                    {sessionData && (
                      <li>
                        {dayjs(sessionData.sessionStartTime).format('HH:mm')}
                      </li>
                    )}
                    {learnerData && (
                      <li>{`${learnerData.firstName} ${learnerData.surname}`}</li>
                    )}
                    {stagesData && (
                      <li>
                        {
                          stagesData.find((stage) => stage.stageId === stageId)
                            ?.name
                        }
                      </li>
                    )}
                  </ul>
                </td>
                <td>{dayjs().format('DD-MM-YYYY HH:mm')}</td>
                <td>
                  {generateErrorCode({
                    courseId,
                    frameworkVersionId,
                    learnerId,
                    sessionId,
                    stageId,
                    responseStatus: (mutation.state.error as any).response
                      ? (mutation.state.error as any).status
                      : 'int',
                    mutationType
                  })}
                </td>
                <td>
                  <div className="btn-group gap-2">{renderBtns(mutation)}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default SyncErrorTable;
