import { AppLanguage } from '../../const';
import { appStrings } from '../../modules/i18n';
import { RouteString } from '../../modules/i18n/routes';

function isNumeric(str: any) {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const getMatchingRoute = ({
  pathname,
  messages,
  language
}: {
  pathname: string;
  messages: any;
  language: string;
}) => {
  /**
   * Get the key of the route the user is currently on
   */
  const route = pathname.substring(4); // remove local part '/en/' from the pathname /en/contact
  const routeParts = route.split('/');

  const routeKeys = routeParts.map((part) => {
    if (isNumeric(part)) {
      return part;
    }
    return Object.keys(messages).find((key) => messages[key] === part);
  });

  /**
   * Find the matching route for the new language
   */
  if (routeKeys.length >= 1) {
    const matchingRoute = routeKeys
      .map((part) => {
        if (isNumeric(part)) {
          return part;
        }
        return appStrings[language as AppLanguage][part as RouteString];
      })
      .join('/');

    /**
     * Return localized route
     */
    return `/${language}/${matchingRoute}`;
  }

  return `/${language}`;
};

export default getMatchingRoute;
