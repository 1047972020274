import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { FilteringQueryParams } from '../../../const/filtering-query-params';
import { UserType } from '../../../const/user-type';
import { isAllowed } from '../../../utils/permissions';
import CentresTable from '../../centres/CentresTable';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import useUserPermissions from '../../../hooks/permissions/useUserPermissions';
import CardHeader from '../../ui/CardHeader';
import { components } from '../../../types/openapi/ProviderService';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { Loading, GeneralError } from '../../common';
import useStoreTableFilters from '../../../hooks/state-management/useStoreTableFilters';
import useAddUserPermissions from '../../../hooks/permissions/useAddUserPermissions';
import useProviderCentresForUser from '../../../hooks/centres/useProviderCentresForUser';

function InstructorCentres({
  providerId,
  userId,
  editCentresLink
}: {
  providerId: number;
  userId: number;
  editCentresLink: string;
}) {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const queryParamsLocation = FilteringQueryParams.InstructorCentres;

  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    filtering,
    setFiltering
  } = useStoreTableFilters({ queryParamsLocation });

  const { permissionsQuery } = useUserPermissions({
    userId,
    displayLocale
  });

  const { deletePermission } = useAddUserPermissions();

  const { centresForUserListQuery } = useProviderCentresForUser({
    providerId,
    userId,
    userTypeId: UserType.INSTRUCTOR,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  const removeCentre = (centre: components['schemas']['CentreDto']) => {
    const centrePermission = permissionsQuery.data?.find(
      (permission) => permission.centreId === centre.centreId
    );
    if (!centrePermission?.userProviderId) {
      toast.error(
        intl.formatMessage({
          id: 'instructor.permissions.centre.permission.remove.error',
          defaultMessage:
            'There was an error removing selected centres from the instructor. Related permission not found'
        }),
        { delay: 200 }
      );
      return;
    }
    deletePermission.mutate(
      { userId, permissionId: centrePermission.userProviderId },
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSuccess: (data) => {
          const toastId = 'removeSuccessToast';
          queryClient.invalidateQueries({
            queryKey: ['centres']
          });
          toast.success(
            intl.formatMessage({
              id: 'instructor.permissions.centre.single.remove.success',
              defaultMessage: 'Selected centre has been removed'
            }),
            { delay: 200, toastId }
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onError: (error: any) => {
          const errorToast = 'removeErrorToast';

          toast.error(
            intl.formatMessage({
              id: 'instructor.permissions.centre.remove.error',
              defaultMessage:
                'There was an error removing selected centres from the instructor'
            }),
            { delay: 200, toastId: errorToast }
          );
        }
      }
    );
  };

  return (
    <div className="card">
      <CardHeader
        badgeContent={
          centresForUserListQuery.data &&
          centresForUserListQuery.data.numberOfCentres
        }
        button={
          isAllowed(
            [UserType.SUPER_ADMIN, UserType.SPORT_MANAGER, UserType.ADMIN],
            profile?.userTypeId as UserType
          ) && (
            <div>
              <Link to={editCentresLink} className="btn btn-primary mx-auto">
                <FormattedMessage
                  id="edit.centres.button"
                  defaultMessage="Edit centres"
                  description="Edit centres"
                />
              </Link>
            </div>
          )
        }
      >
        <h2>
          <FormattedMessage
            id="centres.header"
            defaultMessage="Centres"
            description="Centres"
          />
        </h2>
      </CardHeader>

      <div className="card-body">
        {permissionsQuery.isFetching ? (
          <Loading />
        ) : permissionsQuery.error ? (
          (permissionsQuery.error as any)?.response.status === 401 ? (
            <FormattedMessage
              id="users.error.unauthorised"
              defaultMessage="You are not authorised to access this user"
              description="You are not authorised to access this user"
            />
          ) : (
            <GeneralError />
          )
        ) : (
          permissionsQuery.data &&
          centresForUserListQuery.data &&
          centresForUserListQuery.data?.centres && (
            <CentresTable
              data={centresForUserListQuery.data}
              error={centresForUserListQuery.error}
              isLoading={centresForUserListQuery.isLoading}
              refetching={centresForUserListQuery.isRefetching}
              pagination={pagination}
              sorting={sorting}
              filtering={filtering}
              setPagination={setPagination}
              setSorting={setSorting}
              setFiltering={setFiltering}
              queryParamsLocation={queryParamsLocation}
              renderCentresLink={({ centre }) => (
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => removeCentre(centre)}
                  data-id={centre.centreId}
                >
                  <FormattedMessage id="remove" defaultMessage="Remove" />
                </button>
              )}
            />
          )
        )}
      </div>
    </div>
  );
}

export default InstructorCentres;
