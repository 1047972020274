/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import { AuthContext } from '../modules/auth/AuthProvider';
import GeneralError from '../components/common/GeneralError';
import Loading from '../components/common/Loading';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import useUser from '../hooks/useUser';
import useUserPermissions from '../hooks/permissions/useUserPermissions';
import AccountDetails from '../components/account/AccountDetails';
import UserEmergencyDetailsTable from '../components/users/UserEmergencyDetailsTable';
import { UserType } from '../const/user-type';
import SystemDetailsRow from '../components/account/SystemDetailsRow';

function Profile() {
  // Profile page - should have a profile at this point
  // can navigate away
  const authContext = useContext(AuthContext);
  const { decodedUserFromToken } = authContext;

  const userId = Number(decodedUserFromToken?.userId);

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const intl = useIntl();

  const { userQuery } = useUser({
    userId,
    displayLocale
  });

  const { permissionsQuery } = useUserPermissions({
    userId,
    displayLocale
  });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.profile',
            defaultMessage: 'Profile'
          })}
        </title>
      </Helmet>
      <h1>
        <FormattedMessage
          id="profile"
          defaultMessage="Profile"
          description="Profile"
        />
      </h1>
      <div className="row gap-2 gap-md-0">
        <div className="col-12">
          {userQuery.isFetching || permissionsQuery.isFetching ? (
            <Loading />
          ) : userQuery.error || permissionsQuery.isFetching ? (
            <GeneralError error={userQuery.error} />
          ) : userQuery.data ? (
            <AccountDetails
              user={userQuery.data}
              renderEmergencyContactDetails={() => {
                return permissionsQuery?.data?.some(
                  (_profile) => _profile.userTypeId === UserType.LEARNER
                ) ? (
                  <div className="col-12 col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h3>
                          <FormattedMessage
                            id="emergency_details"
                            defaultMessage="Emergency Contact Details"
                            description="Emergency Contact Details"
                          />
                        </h3>
                      </div>
                      <div className="card-body">
                        <UserEmergencyDetailsTable user={userQuery.data} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                );
              }}
              renderSystemDetails={() => {
                return (
                  <SystemDetailsRow
                    user={userQuery.data}
                    showMedicalConditions={
                      !!permissionsQuery?.data?.some(
                        (_profile) => _profile.userTypeId === UserType.LEARNER
                      )
                    }
                  />
                );
              }}
            />
          ) : (
            <FormattedMessage
              id="user.error.none"
              defaultMessage="No user found"
              description="No user found"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Profile;
