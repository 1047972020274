import Vimeo from '@u-wave/react-vimeo';
import React from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

function VideoEmbed({ video }: { video: string }) {
  if (video.includes('vimeo')) {
    return <Vimeo responsive video={video} />;
  }
  if (video.includes('iframe')) {
    const videoId = video.split('embed/')[1].split('" title')[0];
    return (
      <LiteYouTubeEmbed playerClass="btn lty-playbtn" id={videoId} title="" />
    );
  }
  const videoId = video.split('embed/')[1];
  return (
    <LiteYouTubeEmbed playerClass="btn lty-playbtn" id={videoId} title="" />
  );
}

export default VideoEmbed;
