import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

import { AppLanguage, AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { updateProviderGroup } from '../../services/api/provider.service';
import LearnersTable from '../learners-admin/LearnersTable';
import { components } from '../../types/openapi/UserService';
import { appStrings } from '../../modules/i18n';
import { components as providerComponents } from '../../types/openapi/ProviderService';
import groupKeys from '../../query-keys/group-key-factory';

interface Props {
  group: providerComponents['schemas']['GroupDto'];
}

function LearnersInGroup(props: Props) {
  const { group } = props;
  const { name, description, userGroupAdmins, userGroupMembers } = group;
  const { groupId } = useParams();
  const queryClient = useQueryClient();
  const intl = useIntl();

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const { mutate: removeLearnerFromGroup } = useMutation(
    (editedGroup: any) =>
      updateProviderGroup(
        profile?.providerId,
        Number(groupId),
        editedGroup,
        displayLocale
      ),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: groupKeys.group(
            profile?.providerId,
            Number(groupId),
            displayLocale
          )
        });
        toast.success(
          intl.formatMessage({
            id: 'group.learner.remove.success',
            defaultMessage:
              'This learner was successfully removed from this group'
          }),
          { delay: 200 }
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.learner.remove.error',
            defaultMessage:
              'There was an error removing this learner from this group'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const handleRemoveLearner = (
    removedLearner: components['schemas']['UserDto']
  ) => {
    const learners = userGroupMembers;
    const index = learners!.findIndex(
      (learner) => learner.userId === removedLearner.userId
    );
    learners?.splice(index, 1);
    const editedGroup = {
      groupName: name,
      groupDescription: description,
      groupId: group?.groupId,
      groupAdmins: userGroupAdmins?.map((admin) => admin.userId),
      learners: learners?.map((learner) => learner.userId)
    };
    removeLearnerFromGroup(editedGroup);
  };
  return (
    <div className="card">
      <div className="card-header d-flex">
        <div className="flex-grow-1">
          <h2>
            <FormattedMessage
              id="learners"
              defaultMessage="Learners"
              description="Learners"
            />
          </h2>
        </div>
        <Link
          className="btn btn-primary"
          to={`/${displayLocale}/${
            appStrings[langVal][AppRoute.Groups]
          }/${groupId}/${appStrings[langVal][AppRoute.AddLearnerToGroup]}`}
        >
          <FormattedMessage
            id="learner.group.add"
            defaultMessage="Add learner(s)"
            description="Add learner or learners to group"
          />
        </Link>
      </div>
      <div className="card-body">
        {userGroupMembers && userGroupMembers.length > 0 ? (
          <LearnersTable
            learners={userGroupMembers!.map(
              (learner) => learner.user as components['schemas']['UserDto']
            )}
            actions={userGroupMembers?.map((learner) => {
              return (
                <div className="btn-group">
                  <Link
                    to={`/${displayLocale}/${
                      appStrings[langVal][AppRoute.Learners]
                    }/${learner.userId}`}
                    className="btn btn-outline-secondary"
                  >
                    <FormattedMessage
                      id="view"
                      defaultMessage="View"
                      description="View"
                    />
                  </Link>

                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => handleRemoveLearner(learner)}
                  >
                    <FormattedMessage
                      id="learners.course.remove"
                      defaultMessage="Remove"
                      description="Remove"
                    />
                  </button>
                </div>
              );
            })}
          />
        ) : (
          <span>
            <FormattedMessage
              id="learners.none_found"
              defaultMessage="No learners found"
              description="No learners found"
            />
          </span>
        )}
      </div>
    </div>
  );
}

export default LearnersInGroup;
