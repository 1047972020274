/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  ColumnDef,
  PaginationState,
  Row,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table';

import IndeterminateCheckbox from './IndeterminateCheckbox';
import { components } from '../../types/openapi/ProviderService';
import SelectablePaginatedTable from './SelectablePaginatedTable';

type TableCentre = components['schemas']['CentreDto'] & {
  isSelected: boolean;
  // userProviderId is the ID of the specific permission associated with the user and centre
  userProviderId: number;
  url: string;
};

function SelectableCentresTable({
  data,
  isLoading,
  error,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  userPermissions,
  preselectedCentreIds,
  queryParamsLocation,
  onRowSelect,
  onRowUnselect,
  baseUrl,
  urlAccessor,
  id
}: {
  data: components['schemas']['PagedCentreDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  userPermissions?:
    | components['schemas']['UserProvidersDto'][]
    | undefined
    | null;
  preselectedCentreIds?: (number | undefined)[] | undefined | null;
  queryParamsLocation: string;
  onRowSelect?: (row: Row<components['schemas']['CentreDto']>) => void;
  onRowUnselect?: (row: Row<components['schemas']['CentreDto']>) => void;
  baseUrl?: string;
  urlAccessor?: string;
  id?: string;
}) {
  // const tableData = useMemo(() => [...data?.centres], [data]);
  const intl = useIntl();
  const tableData: TableCentre[] = data?.centres
    ? data?.centres?.map((centre: any) => {
        const permission = userPermissions?.find(
          (permission) => permission.centreId === centre.centreId
        );
        // Add the related permission ID to the centre object for the row
        // Makes it easier to get the matched permission later as it is now on the row
        const newCentre: TableCentre = {
          ...centre,
          isSelected: !!permission,
          userProviderId: permission?.userProviderId,
          url: centre.userProviderId
        };
        return newCentre;
      })
    : [];

  const [rowSelection, setRowSelection] = React.useState({});

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  // on initial load, set up selection
  useEffect(() => {
    if (tableData) {
      const startingSelection: (boolean | null | undefined)[] = tableData?.map(
        (centre) =>
          !!centre.centreId && preselectedCentreIds?.includes(centre.centreId)
      );
      const selectionObj = { ...startingSelection };
      setRowSelection(selectionObj);
    }
  }, []);

  // on changed to pagination, update selection
  useEffect(() => {
    if (tableData) {
      const startingSelection: (boolean | null | undefined)[] = tableData?.map(
        (centre) =>
          !!centre.centreId && preselectedCentreIds?.includes(centre.centreId)
      );
      const selectionObj = { ...startingSelection };
      setRowSelection(selectionObj);
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, filtering]);

  const columns = useMemo<
    ColumnDef<components['schemas']['CentreDto']>[]
  >(() => {
    return [
      // name, postcode, region
      // Let's make a column for selection
      {
        id: 'selection',
        header: () => {},
        cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              label={`centre-${(row as any).original.centreId}`}
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (event: any) => {
                  row.toggleSelected();
                  if ((event.target as any).checked) {
                    onRowSelect!(row);
                  } else {
                    onRowUnselect!(row);
                  }
                },
                id: `centre-${(row as any).original.centreId}`,
                className: 'sp-checkbox__trigger '
              }}
            />
          );
        },
        accessorKey: 'isSelected',
        enableSorting: false
      },
      {
        // ID must stay as centreName case sensitive to match with API filter option
        id: 'centreName',
        header: intl.formatMessage({
          id: 'Name',
          defaultMessage: 'Name',
          description: 'Name'
        }),
        accessorKey: 'name',
        filterType: 'basicInput'
      },
      {
        // ID must stay as postCode case sensitive to match with API filter option
        id: 'postCode',
        header: intl.formatMessage({
          id: 'postcode',
          defaultMessage: 'Postcode',
          description: 'Postcode'
        }),
        accessorKey: 'address.postalCode',
        filterType: 'basicInput'
      },
      {
        id: 'region',
        header: intl.formatMessage({
          id: 'region',
          defaultMessage: 'Region'
        }),
        accessorKey: 'address.countryRegion',
        enableSorting: false
      },
      {
        id: 'action',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: () => (
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary clear-filter"
              onClick={resetFilters}
            >
              <FormattedMessage
                id="filters.clear"
                defaultMessage="Clear filters"
                description="Clear filters"
              />
            </button>
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => {
          return (
            urlAccessor &&
            baseUrl && (
              <div>
                <Link
                  to={`${baseUrl}/${(row.original as any)[urlAccessor]}`}
                  className={`btn btn-outline-secondary ${
                    isLoading ? 'disabled' : ''
                  }`}
                >
                  <FormattedMessage
                    id="centre.view"
                    defaultMessage="View Centre"
                    description="View Centre"
                  />
                </Link>
              </div>
            )
          );
        }
      }
    ];
  }, [
    rowSelection,
    tableData,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    filtering
  ]);
  // Use the state and functions returned from useTable to build your UI
  const table = useReactTable({
    data: tableData ?? [],
    columns,
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfCentres
        ? Math.ceil(data.numberOfCentres / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        rowSelection,
        pagination,
        sorting,
        filtering
      }
    },
    // Selection
    enableRowSelection: true,
    onRowSelectionChange: (selectedRowsFn) => {
      return setRowSelection(selectedRowsFn);
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: false
  });

  return (
    <SelectablePaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfCentres}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="centreId"
      noneFoundMessage={
        <FormattedMessage
          id="centres.error.none"
          defaultMessage="No centres found"
          description="No centres found"
        />
      }
      showFooter={(data?.numberOfCentres ?? 0) > 10}
      tableId={id}
    />
  );
}

export default SelectableCentresTable;

SelectableCentresTable.defaultProps = {
  baseUrl: '',
  urlAccessor: '',
  onRowSelect: () => {},
  onRowUnselect: () => {},
  userPermissions: [],
  preselectedCentreIds: [],
  id: ''
};
