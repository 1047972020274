/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';

import { components } from '../../types/openapi/CourseService';
import buildSelectCourseSessionOptions from '../../utils/react-select-helpers/buildSelectCourseSessionOptions';
import ViewOnlyNotesList from './ViewOnlyNotesList';

type SelectOption = { label: string; value: number };

function ViewLearnerNotesOnCourse({
  courseId,
  learnerId,
  courseSessions
}: {
  courseId: number;
  learnerId: number;
  courseSessions: components['schemas']['CourseSessionDto'][];
}) {
  const { sessionId } = useParams();
  const intl = useIntl();
  const [selectedSessionId, setSelectedSessionId] = useState<number | null>();
  const [sessionOptions, setSessionOptions] = useState<SelectOption[] | null>();

  useEffect(() => {
    if (courseSessions.length > 0) {
      const learnerSessionOptions =
        buildSelectCourseSessionOptions(courseSessions);
      setSessionOptions(learnerSessionOptions as any);
      if (!selectedSessionId) {
        setSelectedSessionId(Number(sessionId));
      }
    } else {
      setSessionOptions(null);
      setSelectedSessionId(null);
      console.warn('course has no sessions');
    }
  }, [courseSessions, selectedSessionId]);

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const isNetworkOnline = useNetworkStatus();

  if (appMode === AppMode.OFFLINE || !isNetworkOnline)
    return (
      <div className="d-flex flex-column">
        <ViewOnlyNotesList
          courseId={courseId}
          sessionId={Number(sessionId)}
          userId={learnerId}
          isOffline
        />
      </div>
    );
  return (
    <>
      {sessionOptions && (
        <div className="mb-3">
          <label htmlFor="courseSessions">
            <FormattedMessage
              id="session.select.placeholder"
              defaultMessage="Select session"
            />
          </label>
          <Select
            inputId="courseSessions"
            options={sessionOptions}
            defaultValue={sessionOptions.find(
              (option) => option.value === Number(sessionId)
            )}
            placeholder={intl.formatMessage({
              id: 'session.select.placeholder',
              defaultMessage: 'Select session'
            })}
            onChange={(newValue) => setSelectedSessionId(newValue?.value)}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 })
            }}
          />
        </div>
      )}

      {selectedSessionId ? (
        <div className="d-flex flex-column">
          <ViewOnlyNotesList
            courseId={courseId}
            sessionId={selectedSessionId}
            userId={learnerId}
          />
        </div>
      ) : (
        <FormattedMessage
          id="session.select.none"
          defaultMessage="No session selected"
        />
      )}
    </>
  );
}

export default ViewLearnerNotesOnCourse;
