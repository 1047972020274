import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UserType } from '../../const/user-type';
import useSessionsBetweenDates from '../../hooks/useSessionsBetweenDates';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError, Loading } from '../common';
// import TodaysSessionsDataDownload from './dataDownload/TodaysSessionsDataDownload';
import { TIMEZONE } from '../../utils/date-time';
import useDataSyncInfoStore from '../../hooks/state-management/useDataSyncInfoStore';

function SyncInfoDetails({ instructorId }: { instructorId: number }) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const lastDownloaded = useDataSyncInfoStore.use.lastDownloaded();

  const now = dayjs();
  const lastDownloadedDayJs = dayjs(Number(lastDownloaded));

  const hasDownloadedToday = lastDownloadedDayJs.isSame(now, 'day');

  const { instructorSessionsTodayQuery } = useSessionsBetweenDates({
    userId: instructorId,
    userType: UserType.INSTRUCTOR,
    displayLocale
  });

  if (instructorSessionsTodayQuery.data) {
    return (
      <div className="alert alert-info w-100 mb-0 my-2">
        <div className="d-flex">
          <div>
            <FontAwesomeIcon icon={faDownload} className="me-1 text-info" />
          </div>
          <div className="flex-grow-1">
            <div>
              {!hasDownloadedToday ? (
                <FormattedMessage
                  id="offline.sync-data.downloadable.sessions.to_download"
                  defaultMessage="{number, plural, =0 {# sessions to download} one {# session to download} other {# sessions to download}}"
                  description="session to download"
                  values={{
                    number: instructorSessionsTodayQuery.data.length
                  }}
                />
              ) : (
                <>
                  <FormattedMessage
                    id="offline.sync-data.downloadable.sessions.downloaded"
                    defaultMessage="{number, plural, =0 {# sessions downloaded} one {# session downloaded} other {# sessions downloaded}}"
                    description="session to download"
                    values={{
                      number: instructorSessionsTodayQuery.data.length
                    }}
                  />
                  <br />
                  {lastDownloaded && (
                    <span className="small text-muted">
                      <FormattedDate
                        value={lastDownloadedDayJs.toDate()}
                        timeZone={TIMEZONE}
                        year="numeric"
                        month="numeric"
                        day="numeric"
                        hour="numeric"
                        minute="numeric"
                        second="numeric"
                      />
                    </span>
                  )}

                  <br />
                  {/* <TodaysSessionsDataDownload
                    sessions={instructorSessionsTodayQuery.data}
                  /> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (instructorSessionsTodayQuery.isLoading) return <Loading />;

  if (
    instructorSessionsTodayQuery.error &&
    (instructorSessionsTodayQuery.error as any)?.response?.status !== 404
  ) {
    return <GeneralError error={instructorSessionsTodayQuery.error} />;
  }

  if (
    instructorSessionsTodayQuery.error &&
    (instructorSessionsTodayQuery.error as any)?.response?.status === 404
  ) {
    return (
      <p>
        <FormattedMessage
          id="sessions.none_found_today"
          defaultMessage="No sessions found for today"
          description="No Sessions Found"
        />
      </p>
    );
  }
  return <GeneralError />;
}

export default SyncInfoDetails;
