import { AppLanguage } from '../const';

const stageKeys = {
  all: () => ['stage'] as const,
  list: (
    frameworkId: number,
    frameworkVersionId: number,
    displayLocale: string | undefined
  ) => [
    ...stageKeys.all(),
    frameworkId,
    frameworkVersionId,
    'list',
    displayLocale || AppLanguage.English
  ],
  stage: (
    frameworkId: number,
    frameworkVersionId: number,
    stageId: number,
    displayLocale: string | undefined
  ) => [
    ...stageKeys.all(),
    frameworkId,
    frameworkVersionId,
    stageId,
    displayLocale || AppLanguage.English
  ]
};

export default stageKeys;
