import { useQuery } from '@tanstack/react-query';
import { UserType } from '../../const/user-type';
import centreKeys from '../../query-keys/centre-key-factory';
import { getUsersForProviderCentreOfUserType } from '../../services/api/user.service';

const useAdminsForCentre = ({
  centreId,
  providerId,
  displayLocale
}: {
  centreId: number;
  providerId: number | null | undefined;
  displayLocale: string | undefined;
}) => {
  const adminsForCentreQuery = useQuery({
    queryKey: centreKeys.adminsForCentre(centreId, displayLocale),
    queryFn: () =>
      getUsersForProviderCentreOfUserType(
        providerId,
        centreId,
        UserType.ADMIN,
        null,
        null,
        null,
        null,
        displayLocale
      ),
    refetchOnMount: true,
    enabled: !!providerId
  });

  return {
    adminsForCentreQuery
  };
};

export default useAdminsForCentre;
