import React from 'react';
import { useParams } from 'react-router-dom';
import useOfflineDataDownload from '../../../hooks/offline/useOfflineDataDownload';
import { components } from '../../../types/openapi/CourseService';
import ObjectivesOverview from '../../courses/sessions/session/instructor/objectives-overview/ObjectivesOverview';

function OfflineObjectivesOverview({
  learners
}: {
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][];
}) {
  const { sessionId } = useParams();

  const { offlineDataDownloadQuery, course, multipleStagesObjectives } =
    useOfflineDataDownload({
      sessionId: Number(sessionId),
      downloadSessionData: true
    });

  return (
    <ObjectivesOverview
      frameworkId={course?.frameworkVersion?.frameworkId}
      frameworkVersionId={course?.frameworkVersionId}
      learners={learners}
      starsForUsers={offlineDataDownloadQuery.data?.userStars}
      courseStages={course?.courseStages}
      multipleStagesObjectives={multipleStagesObjectives}
    />
  );
}

export default OfflineObjectivesOverview;
