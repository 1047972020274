/* eslint-disable prefer-destructuring */
import { Mutation } from '@tanstack/react-query';
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import RetryMutationBtn from './RetryMutationBtn';
import SyncErrorTable from './SyncErrorTable';
import CopyErrorBtn from './CopyErrorBtn';
import EmailSupportDeskBtn from './EmailSupportDeskBtn';

function SyncErrorLog({
  erroredMutations,
  successfullyRetriedMutations,
  setSuccessfullyRetriedMutations
}: {
  erroredMutations: Mutation<unknown, unknown, void, unknown>[];
  successfullyRetriedMutations:
    | Mutation<unknown, unknown, void, unknown>[]
    | undefined;
  setSuccessfullyRetriedMutations: Dispatch<
    SetStateAction<Mutation<unknown, unknown, void, unknown>[] | undefined>
  >;
}) {
  return (
    <SyncErrorTable
      mutations={erroredMutations}
      caption={
        <FormattedMessage
          id="errors"
          defaultMessage="Errors"
          description="Errors"
        />
      }
      renderBtns={(mutation: Mutation) => (
        <>
          <RetryMutationBtn
            mutation={mutation}
            successfullyRetriedMutations={successfullyRetriedMutations}
            setSuccessfullyRetriedMutations={setSuccessfullyRetriedMutations}
          />
          <EmailSupportDeskBtn mutation={mutation} />
          <CopyErrorBtn mutation={mutation} />
        </>
      )}
    />
  );
}

export default SyncErrorLog;
