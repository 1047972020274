import React from 'react';
import { FormattedMessage } from 'react-intl';

function Loading(props: JSX.IntrinsicElements['div']) {
  return (
    <div {...props}>
      <FormattedMessage
        id="loading"
        defaultMessage="Loading"
        description="Loading"
      />
    </div>
  );
}

export default Loading;
