/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

import { GeneralError } from '../../common';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import Loading from '../../common/Loading';
import CourseSessionDetailsTable from './CourseSessionDetailsTable';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { AppLanguage } from '../../../const';
import useSession from '../../../hooks/useSession';
import useCourse from '../../../hooks/course/useCourse';
import SessionPayment from './SessionPayment';
import useProviderConfig from '../../../hooks/useProviderConfig';

function BookSession() {
  const intl = useIntl();
  const { courseId, sessionId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  if (!profile?.userId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'no_user_id',
          defaultMessage: 'No user ID'
        })}
      />
    );
  }
  const { courseQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const { sessionQuery } = useSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    userId: profile.userId,
    displayLocale: displayLocale || AppLanguage.English
  });

  const { providerPaymentEnabled, pricePerCourse } = useProviderConfig({
    providerId: Number(profile.providerId)
  });

  if (sessionQuery.isLoading && courseQuery.isLoading) {
    return <Loading />;
  }

  const sessionStartTime = sessionQuery.data
    ? dayjs(sessionQuery.data.sessionStartTime)
    : undefined;
  const providerId = courseQuery.data?.providerId;

  const isFullyBooked =
    !!sessionQuery.data?.currentLearnersBooked &&
    !!sessionQuery.data.course?.totalNumberOfPeople &&
    sessionQuery.data?.currentLearnersBooked >=
      sessionQuery.data?.course?.totalNumberOfPeople;

  if (!providerId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'no_provider_id',
          defaultMessage: 'No provider ID'
        })}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.book.session.date',
              defaultMessage: 'Book session: {date}'
            },
            { date: intl.formatDate(sessionStartTime?.toDate()) }
          )}
        </title>
      </Helmet>
      <div className="row mb-2">
        <div className="col-12">
          <div className="card border-0 rounded-top-right-lg">
            <div className="card-header d-flex justify-content-between">
              <h1>
                <FormattedMessage
                  id="header.session.date"
                  defaultMessage="Book Session: {date}"
                  description="Book Session: {date}"
                  values={{ date: intl.formatDate(sessionStartTime?.toDate()) }}
                />
              </h1>
              {providerPaymentEnabled && (
                <div>
                  <span className="badge bg-primary">{pricePerCourse}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12 col-lg-6">
          <div className="card border-0 rounded-top-right-lg mb-2 mb-lg-0">
            <div className="card-header">
              <h2>
                <FormattedMessage
                  id="course"
                  defaultMessage="Course"
                  description="Course"
                />
              </h2>
            </div>
            <div className="card-body">
              {sessionQuery.isFetching ? (
                <Loading />
              ) : sessionQuery.error ? (
                <GeneralError />
              ) : (
                sessionQuery.data &&
                sessionQuery.data.course && (
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">
                          <FormattedMessage
                            id="name"
                            defaultMessage="Name"
                            description="Name"
                          />
                        </th>
                        <td>{sessionQuery.data.course.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <FormattedMessage
                            id="description"
                            defaultMessage="Description"
                            description="Description"
                          />
                        </th>
                        <td>{sessionQuery.data.course.description}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <FormattedMessage
                            id="framework.table.header"
                            defaultMessage="Framework"
                            description="Framework"
                          />
                        </th>
                        <td>
                          {courseQuery.data &&
                            courseQuery.data.frameworkVersion?.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card border-0 rounded-top-right-lg">
            <div className="card-header">
              <h2>
                <FormattedMessage
                  id="session"
                  defaultMessage="Session"
                  description="Session"
                />
              </h2>
            </div>
            <div className="card-body">
              {sessionQuery.isFetching ? (
                <Loading />
              ) : sessionQuery.error ? (
                <GeneralError />
              ) : (
                sessionQuery.data && (
                  <CourseSessionDetailsTable session={sessionQuery.data} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card border-0 rounded-top-right-lg">
            <div className="card-header">
              <h2>
                <FormattedMessage
                  id="payment"
                  defaultMessage="Payment"
                  description="Payment"
                />
              </h2>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  {sessionStartTime && (
                    <SessionPayment
                      providerId={providerId}
                      sessionStartTime={sessionStartTime}
                      isFullyBooked={isFullyBooked}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookSession;
