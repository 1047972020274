import { CourseUserStars } from '../../types/CourseUserStars.type';
import useUserStarsForFramework from '../useUserStarsForFramework';

const useIncompleteObjectives = ({
  frameworkVersionId,
  userId,
  displayLocale,
  currentStageId
}: {
  frameworkVersionId: number | undefined;
  userId: number;
  displayLocale: string;
  currentStageId: number | null;
}) => {
  const NUMBER_OF_STARS = 3;
  const { userFrameworkStarsQuery } = useUserStarsForFramework({
    frameworkVersionId,
    userId,
    displayLocale
  });
  const getIncompleteObjectives = (
    currentStageFrameworkStars: CourseUserStars | undefined
  ): number => {
    return (
      currentStageFrameworkStars?.objectiveStars.filter(
        (f) => f.numberOfStars < NUMBER_OF_STARS
      ).length || 0
    );
  };

  const incompleteCount = getIncompleteObjectives(
    userFrameworkStarsQuery.data?.find((f) => f.stageId === currentStageId)
  );
  return { incompleteCount, userFrameworkStarsQuery };
};

export default useIncompleteObjectives;
