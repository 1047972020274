import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { ResourceType } from '../../const/resource-type';
import { components } from '../../types/openapi/FrameworkService';
import { components as resourceComponents } from '../../types/openapi/ResourceService';
import ObjectiveSnakeImage from '../courses/sessions/objectives/ObjectiveSnakeImage';
import VideoEmbed from '../frameworks/objectives/VideoEmbed';
import CustomResourceToggle from './CustomResourceToggle';

function UserViewObjective({
  objective,
  resources,
  translatedResources
}: {
  objective: components['schemas']['ObjectiveDto'];
  resources: resourceComponents['schemas']['ResourceDto'][];
  translatedResources: resourceComponents['schemas']['ResourceDto'][];
}) {
  const { objectiveId, name, description } = objective;
  const objectiveResources = resources.filter(
    (resource) => resource.objectiveId === objectiveId
  );

  const trophy = objectiveResources.find(
    (resource) => resource.resourceTypeId === ResourceType.TROPHY
  );

  const translatedTrophy = translatedResources.find(
    (resource) =>
      resource.objectiveId === objectiveId &&
      resource.resourceTypeId === ResourceType.TROPHY
  );

  const imgSrc = trophy
    ? `${process.env.REACT_APP_BLOB_BASE_URL}/${trophy.resourceString}?${trophy.sasToken}`
    : translatedTrophy
    ? `${process.env.REACT_APP_BLOB_BASE_URL}/${translatedTrophy.resourceString}?${translatedTrophy.sasToken}`
    : '';

  const files = objectiveResources
    .filter((resource) => resource.resourceTypeId === ResourceType.FILE)
    .map((resource) => {
      return {
        fileName: resource.filename,
        fileSrc: `${process.env.REACT_APP_BLOB_BASE_URL}/${resource.resourceString}?${resource.sasToken}`
      };
    });

  const videos = objectiveResources
    .filter((resource) => resource.resourceTypeId === ResourceType.VIDEO)
    .map((resource) => resource.resourceString);

  const numberOfResources = videos.length + files.length;

  return (
    <Card className="resources-accordion">
      <div className="accordion-line" />
      <Card.Header as="div" className="snake-header">
        <div className="list-item w-100">
          <div className="list-item-snake">
            <ObjectiveSnakeImage imgSrc={imgSrc} />
          </div>
          <div className="flex-grow-1">
            <div className="list-item-other d-flex w-100 justify-content-between">
              <div>
                <h4>{name}</h4>
                <p>{description}</p>
                <CustomResourceToggle
                  eventKey={objectiveId.toString()}
                  numberOfResources={numberOfResources}
                />
              </div>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="snake-body">
        <Accordion.Collapse eventKey={objectiveId.toString()}>
          <div>
            {videos &&
              videos.map((video) => {
                return (
                  <div className="resource-video">
                    {video && <VideoEmbed video={video} />}
                  </div>
                );
              })}
            {files &&
              files.map((file) => {
                return (
                  <div className="my-3">
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      color="gray"
                      className="fs-1 mx-3"
                    />
                    <a href={file.fileSrc} download>
                      {file.fileName}
                    </a>
                  </div>
                );
              })}
          </div>
        </Accordion.Collapse>
      </Card.Body>
    </Card>
  );
}

export default UserViewObjective;
