import { useQuery } from '@tanstack/react-query';

import { getCourseSessionBookingsForUser } from '../services/api/course.service';
import sessionKeys from '../query-keys/session-key-factory';
import { filterAttendedSessions, filterPastSessions } from '../utils';
// hook for managing user sessions for a particular course
const useUserCourseSessions = ({
  userId,
  courseId,
  displayLocale
}: {
  userId: number | undefined;
  courseId: number;
  displayLocale: string;
}) => {
  if (!userId) {
    throw Error('invalid id');
  }

  const userCourseSessionsQuery = useQuery({
    queryKey: sessionKeys.sessionsForCourseForUser(
      userId,
      courseId,
      displayLocale
    ),
    queryFn: () =>
      getCourseSessionBookingsForUser(Number(courseId), userId, displayLocale),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!userId
  });

  const totalNumberOfSessions = userCourseSessionsQuery.data?.length || 0;

  const completedSessions =
    userCourseSessionsQuery.data?.filter(filterPastSessions);

  const totalNumberOfCompletedSessions = completedSessions?.length || 0;

  // attendance is only counted for completed sessions
  // future attendance even if marked as true is not included
  const totalSessionsAttended =
    completedSessions?.filter(filterAttendedSessions).length || 0;

  const attendancePercentage = Math.round(
    (totalSessionsAttended / totalNumberOfCompletedSessions) * 100
  );

  return {
    userCourseSessionsQuery,
    totalNumberOfSessions,
    totalNumberOfCompletedSessions,
    totalSessionsAttended,
    attendancePercentage
  };
};

export default useUserCourseSessions;
