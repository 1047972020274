import React, { useContext, useEffect, useState } from 'react';

import useResources from '../../hooks/useResources';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ResourceType } from '../../const/resource-type';
import { GeneralError } from '../common';
import Loading from '../common/Loading';
import VideoEmbed from '../frameworks/objectives/VideoEmbed';

function FeaturedResource({
  frameworkVersionId
}: {
  frameworkVersionId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [featuredResource, setFeaturedResource] = useState<string | null>();

  const { resourcesQuery } = useResources({
    frameworkVersionId,
    displayLocale
  });

  useEffect(() => {
    if (resourcesQuery.data) {
      const videos = resourcesQuery.data.filter(
        (resource) => resource.resourceTypeId === ResourceType.VIDEO
      );
      if (videos.length) {
        setFeaturedResource(videos[0].resourceString);
      }
    }
  }, [resourcesQuery.data]);

  return (
    <>
      {' '}
      {resourcesQuery.isFetching ? (
        <Loading />
      ) : resourcesQuery.error ? (
        <GeneralError />
      ) : resourcesQuery.data ? (
        featuredResource && (
          <div className="dashboard__resource-video my-2">
            <VideoEmbed video={featuredResource} />
          </div>
        )
      ) : (
        'no resources'
      )}
    </>
  );
}

export default FeaturedResource;
