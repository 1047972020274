import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/UserService';

function InstructorDetailsTable({
  user
}: {
  user: components['schemas']['UserDto'];
}) {
  return (
    <table className="table">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="username"
              defaultMessage="Username"
              description="Username"
            />
          </th>
          <td data-gdpr="true">{user.username}</td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="email"
              defaultMessage="Email"
              description="Email"
            />
          </th>
          <td data-gdpr="true">
            {user.email && <a href={`mailto:${user.email}`}>{user.email}</a>}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="phone"
              defaultMessage="Phone"
              description="Phone"
            />
          </th>
          <td data-gdpr="true">
            {' '}
            {user.telephone && (
              <a href={`tel:${user.telephone}`}>{user.telephone}</a>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default InstructorDetailsTable;
