/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/dot-notation */
import axios from 'axios';
import pascalcaseKeys from 'pascalcase-keys';
import { components } from '../../types/openapi/ProviderService';
import { bearerToken } from '../bearer-token';
import camelCaseResponse from '../camel-case-interceptor';
import expiredTokenInterceptor from '../refresh-access-token.interceptor';
import { Subset } from '../../types/Subset.type';
import { FilteringObject } from '../../const/filtering-object';

const BASE_URL = `${process.env.REACT_APP_PROVIDER_API_URL}/api`;

export const providersApi = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

providersApi.interceptors.request.use(
  (config) => {
    const token: string = bearerToken();
    if (token && config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

providersApi.interceptors.response.use(
  (response) => camelCaseResponse(response),
  (error) => expiredTokenInterceptor(providersApi, error)
);

export const getProviders = async (lang?: string) => {
  const params = {
    lang
  };

  const response = await providersApi.get<
    components['schemas']['ProviderDto'][]
  >('providers', { params });

  return response.data;
};

export const getProvider = async (
  providerId: number | null | undefined,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<components['schemas']['ProviderDto']>(
    `providers/${providerId}`,
    { params }
  );

  return response.data;
};

export const getProviderConfig = async (
  providerId: number | undefined | null
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const response = await providersApi.get<
    components['schemas']['ProviderConfigurationDto'][]
  >(`providers/${providerId}/config`);

  return response.data;
};

export const createProvider = async (
  provider: Partial<components['schemas']['ProviderDto']>,
  lang?: string
) => {
  const params = {
    lang
  };
  const providerObj = { provider };
  const body = pascalcaseKeys(providerObj, { deep: true });
  const response = await providersApi.post<
    components['schemas']['ProviderDto']
  >('providers', body, { params });
  return response.data;
};

export const updateProvider = async (
  providerId: number | null | undefined,
  provider: Subset<components['schemas']['ProviderDto']>,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const providerObj = { provider };

  const body = pascalcaseKeys(providerObj, { deep: true });

  const response = await providersApi.put<components['schemas']['ProviderDto']>(
    `providers/${providerId}`,
    body,
    { params }
  );

  return response.data;
};

export const getProviderCentresCount = async (
  providerId: number | null | undefined,
  lang: string | undefined,
  filtering?: any
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    ...filtering,
    lang
  };

  const response = await providersApi.get<number>(
    `providers/${providerId}/centres-count`,
    { params }
  );

  return response.data;
};

export const getProviderGroupsPaged = async (
  providerId: number | null | undefined,
  lang: string | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  rawFiltering?: any
) => {
  if (!providerId) {
    throw Error('no id provided');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await providersApi.get<
    components['schemas']['PagedGroupDto']
  >(`providers/${providerId}/groups`, { params });

  return response.data;
};

export const getProviderCentres = async (
  providerId: number | null | undefined,
  lang: string | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  rawFiltering?: any
) => {
  if (!providerId) {
    throw Error('no id provided');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await providersApi.get<
    components['schemas']['PagedCentreDto']
  >(`providers/${providerId}/centres`, { params });

  return response.data;
};

export const getProviderCentresForUser = async (
  providerId: number | null | undefined,
  userId: number | null | undefined,
  userTypeId: number | null | undefined,
  lang: string | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  rawFiltering?: any
) => {
  if (!providerId) {
    throw Error('no id provided');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await providersApi.get<
    components['schemas']['PagedCentreDto']
  >(`providers/${providerId}/centres/user/${userId}/usertype/${userTypeId}`, {
    params
  });

  return response.data;
};

export const getProviderCentre = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  lang?: string
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }

  if (!lang) {
    throw Error('no lang provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<components['schemas']['CentreDto']>(
    `providers/${providerId}/centres/${centreId}`,
    { params }
  );

  return response.data;
};

export const createProviderCentre = async (
  providerId: number | null | undefined,
  centre: Partial<components['schemas']['CentreDto']>,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const centreObj = { centre };

  const body = pascalcaseKeys(centreObj, { deep: true });

  const response = await providersApi.post<components['schemas']['CentreDto']>(
    `providers/${providerId}/centres`,
    body,
    { params }
  );

  return response.data;
};

export const updateProviderCentre = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  centre: Subset<components['schemas']['CentreDto']>,
  lang?: string
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const centreObj = { centre };

  const body = pascalcaseKeys(centreObj, { deep: true });

  const response = await providersApi.put<components['schemas']['CentreDto']>(
    `providers/${providerId}/centres/${centreId}`,
    body,
    { params }
  );

  return response.data;
};

export const deleteProviderCentre = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }

  const response = await providersApi.delete<
    components['schemas']['CentreDto']
  >(`providers/${providerId}/centres/${centreId}`);

  return response.data;
};

export const deleteProviderCentreFacility = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  facilityId: number | null | undefined
) => {
  if (!providerId || !centreId || !facilityId) {
    throw Error('no id provided');
  }

  const response = await providersApi.delete<
    components['schemas']['FacilityDto']
  >(`providers/${providerId}/centres/${centreId}/facilities/${facilityId}`);

  return response.data;
};

export const getProviderCentreFacilities = async (
  providerId: number | null | undefined,
  centreId: number,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<
    components['schemas']['FacilityDto'][]
  >(`providers/${providerId}/centres/${centreId}/facilities`, { params });

  return response.data;
};

export const getProviderCentreFacility = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  facilityId: number | null | undefined,
  lang?: string
) => {
  if (!providerId || !centreId || !facilityId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<components['schemas']['FacilityDto']>(
    `providers/${providerId}/centres/${centreId}/facilities/${facilityId}`,
    {
      params
    }
  );

  return response.data;
};

export const createProviderCentreFacility = async (
  providerId: number | null | undefined,
  centreId: number,
  facility: Partial<components['schemas']['FacilityDto']>,
  lang?: string
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const facilityObj = { facility };

  const body = pascalcaseKeys(facilityObj, { deep: true });

  const response = await providersApi.post<
    components['schemas']['FacilityDto']
  >(`providers/${providerId}/centres/${centreId}/facilities`, body, { params });

  return response.data;
};

export const updateProviderCentreFacility = async (
  providerId: number | null,
  centreId: number | null,
  facilityId: number | null,
  facility: Partial<components['schemas']['FacilityDto']>,
  lang?: string
) => {
  if (!providerId || !centreId || !facilityId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };
  const facilityObj = { facility };
  const body = pascalcaseKeys(facilityObj, { deep: true });

  const response = await providersApi.put<components['schemas']['FacilityDto']>(
    `providers/${providerId}/centres/${centreId}/facilities/${facilityId}`,
    body,
    { params }
  );

  return response.data;
};

export const createGroup = async (
  providerId: number | null | undefined,
  group: any,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }

  const groupObj = { group };

  const body = pascalcaseKeys(groupObj, { deep: true });

  const params = {
    lang
  };
  const response = await providersApi.post<any>(
    `providers/${providerId}/groups`,
    body,
    { params }
  );

  return response.data;
};

export const getProviderGroups = async (
  providerId: number | null | undefined,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<components['schemas']['GroupDto'][]>(
    `providers/${providerId}/groups`,
    { params }
  );

  return response.data;
};

export const getProviderGroup = async (
  providerId: number | null | undefined,
  groupId: number | null | undefined,
  lang?: string
) => {
  if (!providerId || !groupId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<components['schemas']['GroupDto']>(
    `providers/${providerId}/groups/${groupId}`,
    { params }
  );

  return response.data;
};

export const deleteProviderGroup = async (
  providerId: number | null | undefined,
  groupId: number | null | undefined
) => {
  if (!providerId || !groupId) {
    throw Error('no id provided');
  }

  const response = await providersApi.delete<components['schemas']['GroupDto']>(
    `providers/${providerId}/groups/${groupId}`
  );

  return response.data;
};

export const updateProviderGroup = async (
  providerId: number | null | undefined,
  groupId: number | null | undefined,
  group: Partial<components['schemas']['GroupDto']>,
  lang?: string
) => {
  if (!providerId || !groupId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const groupObj = { group };

  const body = pascalcaseKeys(groupObj, { deep: true });

  const response = await providersApi.put<components['schemas']['GroupDto']>(
    `providers/${providerId}/groups/${groupId}`,
    body,
    { params }
  );

  return response.data;
};

export const getProviderSchools = async (
  providerId: number | null | undefined,
  lang: string | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  rawFiltering?: any
) => {
  if (!providerId) {
    throw Error('no id provided');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await providersApi.get<
    components['schemas']['PagedSchoolDto']
  >(`providers/${providerId}/schools`, { params });

  return response.data;
};

export const getCentreSchools = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  lang: string | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  rawFiltering?: any
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await providersApi.get<
    components['schemas']['PagedSchoolDto']
  >(`providers/${providerId}/centres/${centreId}/schools`, { params });

  return response.data;
};

export const getProvidersList = async (
  lang: string | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sorting?: any | null,
  rawFiltering?: any
) => {
  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await providersApi.get<
    components['schemas']['PagedProviderDto']
  >(`providers/`, { params });

  return response.data;
};

export const getProviderSchoolsCount = async (
  providerId: number | null | undefined,
  lang: string | undefined
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<number>(
    `providers/${providerId}/schools-count`,
    { params }
  );

  return response.data;
};

export const getCentreSchoolsCount = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  lang: string | undefined
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<number>(
    `providers/${providerId}/centres/${centreId}/schools-count`,
    { params }
  );

  return response.data;
};

export const getProviderSchool = async (
  providerId: number | null | undefined,
  schoolId: number | null | undefined,
  lang?: string
) => {
  if (!providerId || !schoolId) {
    throw Error('no id provided');
  }

  if (!lang) {
    throw Error('no lang provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.get<components['schemas']['SchoolDto']>(
    `providers/${providerId}/schools/${schoolId}`,
    { params }
  );

  return response.data;
};

export const createProviderSchool = async (
  providerId: number | null | undefined,
  school: Subset<components['schemas']['SchoolDto']>,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.post<components['schemas']['SchoolDto']>(
    `providers/${providerId}/schools`,
    school,
    { params }
  );

  return response.data;
};

export const updateProviderSchool = async (
  providerId: number | null | undefined,
  schoolId: number | null | undefined,
  school: Subset<components['schemas']['SchoolDto']>,
  lang?: string
) => {
  if (!providerId || !schoolId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await providersApi.put<components['schemas']['SchoolDto']>(
    `providers/${providerId}/schools/${schoolId}`,
    school,
    { params }
  );

  return response.data;
};
