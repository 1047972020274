import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import userObjectivesKeys from '../query-keys/user-objectives-key-factory';
import { updateCourseSessionObjectiveForUserWithOnlyCourseId } from '../services/api/course.service';

// hook for managing user objectives without a session, so changing stars from previous stages
const useUserObjectiveStarsNoSession = ({
  courseId,
  userId,
  frameworkVersionId
}: {
  courseId: number | undefined;
  userId: number | undefined;
  frameworkVersionId: number | undefined;
}) => {
  if (!userId) {
    throw Error('invalid id');
  }
  const queryClient = useQueryClient();
  const intl = useIntl();
  const updateUserObjectiveStarsNoSession = useMutation(
    ({
      objectiveId,
      numberOfStars,
      stageId
    }: {
      objectiveId: number;
      numberOfStars: number;
      stageId: number;
    }) => {
      return updateCourseSessionObjectiveForUserWithOnlyCourseId(
        courseId,
        userId,
        stageId,
        objectiveId,
        numberOfStars
      );
    },
    {
      onError: () => {
        toast.error(
          intl.formatMessage({
            id: 'stars.added.error',
            defaultMessage: 'There was an error adding these stars'
          }),
          { delay: 200 }
        );
      },
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'stars.update.success',
            defaultMessage: 'Stars updated successfully'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: userObjectivesKeys.starsForFramework(
            frameworkVersionId,
            userId
          )
        });
      }
    }
  );

  return {
    updateUserObjectiveStarsNoSession
  };
};

export default useUserObjectiveStarsNoSession;
