import { Column } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import FilterInput from '../FilterInput';

function OffcanvasFilterWrapper({
  column,
  columnDef,
  filtering,
  control
}: {
  column: Column<any, unknown>;
  columnDef: any;
  filtering: any;
  control: Control<any, any>;
}) {
  const getFilterValue = (_filtering: string | any[]): string => {
    return _filtering
      ? typeof _filtering === 'string'
        ? _filtering
        : _filtering?.find((el: { id: string }) => el.id === column.id)?.value
      : '';
  };

  const initialFilterValue = getFilterValue(filtering);

  const { field } = useController({
    control,
    name: column.id || ''
  });

  const [filterValue, setFilterValue] = useState(initialFilterValue);
  // when the filter is an id but you might want to display a more human readable value
  useEffect(() => {
    // filters have been updated externally
    if (filtering) {
      const newFilterValue = getFilterValue(filtering);
      setFilterValue(newFilterValue);
    }
  }, [filtering]);

  const handleBasicInputChange = (value: string) => {
    if (value) {
      if (typeof value === 'string') {
        if (value.length >= 3) {
          setFilterValue(value);
          field.onChange(value);
        }
      } else {
        setFilterValue(value);
      }
    } else if (!value) {
      setFilterValue('');
      field.onChange('');
    }
  };
  const handleSportPassportIdInputChange = (value: string) => {
    if (value) {
      if (value.length === 11) {
        const numbersFromId = value
          .split('')
          .filter((char) => char >= '0' && char <= '9')
          .join('');
        setFilterValue(numbersFromId);
        field.onChange(numbersFromId);
      }
    } else if (!value) {
      setFilterValue('');
      field.onChange('');
    }
  };
  const handleBasicNumericInputChange = (value: string) => {
    if (value) {
      setFilterValue(value);
      field.onChange(value);
    } else if (!value) {
      setFilterValue('');
      field.onChange('');
    }
  };

  const handleDropdownInputChange = (selectedOption: any) => {
    setFilterValue(selectedOption.value);
    field.onChange(selectedOption.value);
  };

  return (
    <FilterInput
      column={column}
      columnDef={columnDef}
      filterValue={filterValue}
      initialFilterValue={filterValue}
      handleBasicInputChange={handleBasicInputChange}
      handleSportPassportIdInputChange={handleSportPassportIdInputChange}
      handleBasicNumericInputChange={handleBasicNumericInputChange}
      handleDropdownInputChange={handleDropdownInputChange}
    />
  );
}

export default OffcanvasFilterWrapper;
