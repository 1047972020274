import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { components } from '../../types/openapi/CourseService';
import { Subset } from '../../types/Subset.type';

type CourseStateActions = {
  setName: (value: string) => void;
  setDescription: (value: string) => void;
  setFrameworkVersionId: (value: number) => void;
  setStageIds: (value: { stageId: number }[]) => void;
  setInstructor: (value: components['schemas']['UserDto']) => void;
  setTotalNumberOfPeople: (value: number) => void;
  setCentre: (value: components['schemas']['CentreDto']) => void;
  setFacilityId: (value: number | undefined) => void;
  setSchool: (value: components['schemas']['SchoolDto']) => void;
  reset: () => void;
};

type StageIds = { stageIds: { stageId: number }[] };

const initialState: StageIds & Subset<components['schemas']['CourseDto']> = {
  name: '',
  description: '',
  frameworkVersionId: undefined,
  stageIds: [],
  instructor: undefined,
  totalNumberOfPeople: undefined,
  centre: undefined,
  facilityId: undefined,
  school: undefined
};

const useCreateCourseStore = create<
  Subset<components['schemas']['CourseDto']> & CourseStateActions & StageIds,
  [['zustand/persist', unknown]]
>(
  persist(
    (set) => ({
      ...initialState,
      setName: (value: string) => set({ name: value }),
      setDescription: (value: string) => set({ description: value }),
      setFrameworkVersionId: (value: number) =>
        set({ frameworkVersionId: value }),
      setStageIds: (value: { stageId: number }[]) => set({ stageIds: value }),
      setInstructor: (value: components['schemas']['UserDto']) =>
        set({ instructor: { ...value } }),
      setTotalNumberOfPeople: (value: number) =>
        set({ totalNumberOfPeople: value }),
      setCentre: (value: components['schemas']['CentreDto']) =>
        set({ centre: { ...value } }),
      setFacilityId: (value: number | undefined) => set({ facilityId: value }),
      setSchool: (value: components['schemas']['SchoolDto']) =>
        set({ school: { ...value } }),
      reset: () => {
        set(initialState);
      }
    }),
    {
      name: 'sp2_form_create-new-course-store', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
    }
  )
);

export default useCreateCourseStore;
