/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import React, { useContext, useEffect } from 'react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import {
  QueryClient,
  MutationCache,
  onlineManager
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';

import { LangContext } from './modules/i18n/components/IntlWrapper';
import createIDBPersister from './modules/persister/IDBPersister';
import sessionKeys from './query-keys/session-key-factory';
import {
  progressLearner,
  undoProgressionOfLearner,
  userDataSync
} from './services/api/course.service';
import { components } from './types/openapi/CourseService';
import progressKeys from './query-keys/progress-key-factory';
import noteKeys from './query-keys/note-key-factory';
import { ProfileContext } from './modules/profile/ProfileProvider';
import userObjectivesKeys from './query-keys/user-objectives-key-factory';
import PrivateRoute from './routing/private-routes';
import PublicRoute from './routing/public-routes';
import HoldingRoute from './routing/holding-routes';
import { AuthContext } from './modules/auth/AuthProvider';
import { AppLanguage } from './const';
import ModalProvider from './modules/modal/ModalProvider';
import { ObjectiveStar } from './hooks/useUserSessionObjectiveStars';
import { StorageId } from './const/storage-id';
import useDataSyncInfoStore from './hooks/state-management/useDataSyncInfoStore';

function App() {
  const langCtx = useContext(LangContext);
  // use app lanaguage
  const { displayLocale } = langCtx;

  const authContext = useContext(AuthContext);
  const { token, handleLogout } = authContext;

  const intl = useIntl();

  const profileContext = useContext(ProfileContext);

  const { profile, clearProfile } = profileContext;

  const persister = createIDBPersister({ idbValidKey: 'SP2_OFFLINE_CACHE' });

  const toastId = React.useRef<any | null>(null);

  const setLastUploaded = useDataSyncInfoStore.use.setLastUploaded();
  // clear up old data from previous version
  window.localStorage.removeItem('lastDataSync');

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        staleTime: 1000 * 60 * 2, // 2 minutes
        retry: 0,
        refetchOnWindowFocus: false
      }
    },
    // configure global cache callbacks to show toast notifications for errors
    mutationCache: new MutationCache({
      onError: (error) => {
        // show error messages
        const errorToastId = 'errorToast';
        console.error(error);
        const errMessage =
          (error as any)?.response?.data || (error as any).message;
        if (errMessage) {
          toast.error(errMessage, { toastId: errorToastId });
        }
      }
    })
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(sessionKeys.all(displayLocale), {
    mutationFn: async ({ courseId, sessionId, attendanceMap, dateTime }) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      // cancel the existing query for getting the course session details for member
      await queryClient.cancelQueries({
        queryKey: sessionKeys.members(courseId, sessionId, displayLocale)
      });

      const userAttendanceUpdates: components['schemas']['SyncAttendance'][] =
        Array.from(attendanceMap.entries()).map((entry: any) => {
          const userAttendance: components['schemas']['SyncAttendance'] = {
            sessionId,
            learnerId: entry[0],
            attended: entry[1],
            dateTime
          };
          return userAttendance;
        });

      const update: components['schemas']['DaySessionSync'] = {
        attendance: [...userAttendanceUpdates],
        objectives: [],
        notes: []
      };
      // update the course session details for member
      return userDataSync(update).then((success) => {
        const now = new Date();
        setLastUploaded(now.getTime());
        const attendanceToastId = 'attendanceToast';

        if (success.status === 'SYNCED') {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(
              intl.formatMessage({
                id: 'attendance.update.success',
                defaultMessage: 'Attendance updated'
              }),
              { toastId: attendanceToastId }
            );
          }
        } else {
          toast.error(success.summary);
        }
      });
    },
    onSuccess: () => {},
    retry: (failureCount, error) => {
      console.error(error);
      // only retry if they have logged in
      return !!profile;
    }
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(userObjectivesKeys.all(), {
    mutationFn: async ({ courseId, sessionId, objectiveStars }) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      // cancel the existing query for getting the course session details for member
      await queryClient.cancelQueries({
        queryKey: userObjectivesKeys.starsForCourseSessionUsers(
          courseId,
          sessionId
        )
      });

      const userObjectiveUpdates: components['schemas']['SyncObjective'][] =
        objectiveStars.map((objectiveStar: ObjectiveStar) => {
          const { objectiveId, numberOfStars, learnerId } = objectiveStar;
          return {
            sessionId,
            objectiveId,
            stars: numberOfStars,
            learnerId
          };
        });

      const update: components['schemas']['DaySessionSync'] = {
        attendance: [],
        objectives: [...userObjectiveUpdates],
        notes: []
      };
      // update the stars against an objective for a learner
      return userDataSync(update).then((success) => {
        // eslint-disable-next-line no-console
        console.log('Objective userdatasync success');
        const now = new Date();
        setLastUploaded(now.getTime());
        const objectivesToastId = 'objectivesToast';

        if (success.status === 'SYNCED') {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(
              intl.formatMessage({
                id: 'objectives.update.success',
                defaultMessage: 'Objectives updated'
              }),
              { toastId: objectivesToastId }
            );
          }
        } else {
          toast.error(success.summary);
        }
      });
    },
    retry: (failureCount, error) => {
      console.error(error);
      // only retry if they have logged in
      return !!profile;
    }
  });

  queryClient.setMutationDefaults(progressKeys.all(), {
    mutationFn: async ({
      frameworkVersionId,
      learnerId,
      // For progressing a learner, will be current stage Id. For unprogressing a learner, will be last completed stageId
      stageId,
      isProgress
    }: {
      frameworkVersionId: number;
      learnerId: number;
      stageId: number;
      isProgress: boolean;
    }) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      // cancel the existing query for getting stage progress details
      await queryClient.cancelQueries({
        queryKey: progressKeys.currentFrameworkStage(
          frameworkVersionId,
          learnerId,
          displayLocale
        )
      });
      if (isProgress)
        // update the stage progress
        return progressLearner(frameworkVersionId, learnerId, stageId).then(
          () => {
            // eslint-disable-next-line no-console
            console.log('Progress learner success');
            const now = new Date();
            setLastUploaded(now.getTime());
          }
        );
      return undoProgressionOfLearner(frameworkVersionId, learnerId, stageId);
    },
    retry: (failureCount, error) => {
      console.error(error);
      // only retry if they have logged in
      return !!profile;
    }
  });

  queryClient.setMutationDefaults(noteKeys.all(), {
    mutationFn: async ({
      courseId,
      sessionId,
      userId,
      note
    }: {
      courseId: number;
      sessionId: number;
      userId: number;
      note: components['schemas']['NoteDto'];
    }) => {
      await queryClient.cancelQueries({
        queryKey: noteKeys.list(courseId, sessionId, userId)
      });
      const newNote: components['schemas']['SyncNote'] = {
        text: note.noteText,
        sessionId,
        learnerId: userId,
        dateTime: dayjs().toISOString()
      };
      const update: components['schemas']['DaySessionSync'] = {
        attendance: [],
        objectives: [],
        notes: [newNote]
      };
      userDataSync(update).then(() => {
        // eslint-disable-next-line no-console
        console.log('Notes userdatasync success');
        const now = new Date();
        setLastUploaded(now.getTime());
      });
    },
    retry: (failureCount, error) => {
      console.error(error);
      // only retry if they have logged in
      return !!profile;
    }
  });

  let router = createBrowserRouter([
    token
      ? PrivateRoute(displayLocale as AppLanguage) // , profile?.providerId)
      : {},
    PublicRoute(displayLocale as AppLanguage, !!profile)
  ]);

  if (
    window.location.origin !== process.env.REACT_APP_BASE_URL &&
    process.env.REACT_APP_SHOW_HOLDING_PAGE != null &&
    process.env.REACT_APP_SHOW_HOLDING_PAGE === 'true'
  ) {
    router = createBrowserRouter([
      {},
      HoldingRoute(displayLocale as AppLanguage)
    ]);
  }

  const checkToken = () => {
    const storageToken = window.localStorage.getItem(StorageId.TOKEN);
    if (!storageToken) {
      console.log('no token');
      handleLogout();
      clearProfile();
    }
  };

  useEffect(() => {
    const interval = setInterval(checkToken, 5000);
    return () => clearInterval(interval);
  });

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        maxAge: 1000 * 60 * 60 * 24, // 24 hours
        buster: '',
        dehydrateOptions: {
          dehydrateMutations: true,
          dehydrateQueries: true
        }
      }}
      onSuccess={() => {
        // don't try syncing unless actually logged in
        if (profile && onlineManager.isOnline()) {
          // resume mutations after initial restore from indexedDB was successful
          queryClient.resumePausedMutations().then(() => {
            queryClient.invalidateQueries();
            // if in online mode then invalidate queries and trigger refetch
            console.log('all paused mutations resumed');
            // if (isOnline) {
            //   queryClient.invalidateQueries();
            // }
            // if in offline mode then keep reading from cache as mutations will not have been successful
          });
        }
      }}
    >
      <HelmetProvider>
        <ModalProvider>
          <a href="#maincontent" className="skip-link d-print-none px-2">
            <FormattedMessage
              id="skip_link"
              defaultMessage=" Skip to main content"
            />
          </a>
          <div className="App">
            <ToastContainer
              position="top-center"
              autoClose={5000}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <RouterProvider router={router} />
          </div>
          <ReactQueryDevtools initialIsOpen />
        </ModalProvider>
      </HelmetProvider>
    </PersistQueryClientProvider>
  );
}

export default App;
