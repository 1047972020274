/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import Unauthorised from '../../pages/Unauthorised';
import GeneralError from '../common/GeneralError';
import useCentreCourseSessions from '../../hooks/useCentreCourseSessions';
import TimetableWrapper from './TimetableWrapper';
import useProviderCentres from '../../hooks/centres/useProviderCentres';
import { Loading } from '../common';

function ProviderAdminTimetable() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  if (!profile?.providerId) {
    return <GeneralError message="no provider id" />;
  }

  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(2, 'week').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().add(2, 'week').format('YYYY-MM-DD')
  );

  const [selectedCentreId, setSelectedCentreId] = useState<number>();

  const { centresForProviderListQuery, defaultFirstCentre } =
    useProviderCentres({
      providerId: profile.providerId,
      displayLocale
    });

  const { courseSessionsForCentreQuery } = useCentreCourseSessions({
    providerId: profile?.providerId,
    centreId: selectedCentreId,
    startDate,
    endDate,
    displayLocale
  });

  useEffect(() => {
    if (defaultFirstCentre) {
      setSelectedCentreId(defaultFirstCentre.centreId);
    }
  }, [centresForProviderListQuery.data]);

  if (profile?.userTypeId !== UserType.ADMIN) {
    return <Unauthorised />;
  }

  return (
    <>
      {centresForProviderListQuery.isLoading ? (
        <Loading />
      ) : centresForProviderListQuery.error &&
        (centresForProviderListQuery.error as any)?.response?.status !== 404 ? (
        <GeneralError error={centresForProviderListQuery.error} />
      ) : centresForProviderListQuery.data &&
        centresForProviderListQuery.data.centres &&
        defaultFirstCentre ? (
        <Select
          className="mb-3"
          onChange={(event) => setSelectedCentreId(event?.value)}
          defaultValue={{
            label: defaultFirstCentre.name,
            value: defaultFirstCentre.centreId
          }}
          options={centresForProviderListQuery.data.centres.map((centre) => {
            return {
              label: centre.name,
              value: centre.centreId
            };
          })}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 })
          }}
        />
      ) : (
        <FormattedMessage
          id="error.no_centres_available"
          defaultMessage="No Centres available to select. Please contact your provider"
          description="No Centres available to select. Please contact your provider"
        />
      )}
      {selectedCentreId && (
        <TimetableWrapper
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          sessions={courseSessionsForCentreQuery.data}
          isLoading={courseSessionsForCentreQuery.isLoading}
          error={courseSessionsForCentreQuery.error}
          selectedCentreId={selectedCentreId}
        />
      )}
    </>
  );
}

export default ProviderAdminTimetable;
