import React from 'react';
import { FormattedMessage } from 'react-intl';

function PasswordCharacterValidationRequirements() {
  return (
    <ul>
      <li>
        {' '}
        <FormattedMessage
          id="form.label.password.requirements.characters.uppercase.a-z"
          defaultMessage="Uppercase characters A-Z (Latin alphabet)"
        />
      </li>
      <li>
        {' '}
        <FormattedMessage
          id="form.label.password.requirements.characters.lowercase.a-z"
          defaultMessage="Lowercase characters a-z (Latin alphabet)"
        />
      </li>
      <li>
        <FormattedMessage
          id="form.label.password.requirements.characters.digits"
          defaultMessage=" Digits 0-9"
        />
      </li>
      <li>
        <FormattedMessage
          id="form.label.password.requirements.characters.special-characters"
          defaultMessage="Special characters (!, $, #, %, etc.)"
        />
      </li>
    </ul>
  );
}

export default PasswordCharacterValidationRequirements;
