import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import { components } from '../../types/openapi/UserService';
import RolesListContainer from './RolesListContainer';
import GeneralError from '../common/GeneralError';

function RolesListWrapper({
  userIsLoading,
  permissionsIsLoading,
  userError,
  permissionsError,
  userData,
  permissionsData
}: {
  userIsLoading: boolean;
  permissionsIsLoading: boolean;
  userError: any;
  permissionsError: any;
  userData: components['schemas']['UserDto'] | undefined;
  permissionsData: components['schemas']['UserProvidersDto'][] | undefined;
}) {
  const intl = useIntl();
  return (
    <div className="py-3">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.select_role',
            defaultMessage: 'Select role'
          })}
        </title>
      </Helmet>
      <div className="container role-select-container rounded-0 border-0 px-3">
        {userIsLoading || permissionsIsLoading ? (
          <div className="d-flex flex-column gap-4">
            <div className="loading-card loading-card-height-lg" />
            <div className="loading-card loading-card-height-xl" />
          </div>
        ) : userError || permissionsError ? (
          <GeneralError />
        ) : userData ? (
          <RolesListContainer
            permissions={permissionsData}
            permissionsIsLoading={permissionsIsLoading}
            permissionsError={permissionsError}
          />
        ) : (
          <FormattedMessage
            id="user.error.none"
            defaultMessage="No user found"
            description="No user found"
          />
        )}
      </div>
    </div>
  );
}

export default RolesListWrapper;
