import React, { useEffect, useRef, useState } from 'react';
import Select, {
  GroupBase,
  SelectInstance,
  SingleValue,
  StylesConfig
} from 'react-select';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClose
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ObjectiveOption from './ObjectiveOption.interface';
import SelectGroupLabel from './SelectGroupLabel';
import { Dropdown, DropdownIndicator } from '../../../../../ui/dropdown-select';
import useKeyPress from '../../../../../../hooks/ui/useKeyPress';
import CardHeader from '../../../../../ui/CardHeader';

function ObjectiveSelect({
  groupedOptions,
  defaultObjective,
  onChangeObjective
}: {
  groupedOptions: {
    label: string;
    stageId: number | undefined;
    options: {
      value: string | undefined;
      label: string;
      stageId: number | undefined;
    }[];
  }[];
  defaultObjective: SingleValue<ObjectiveOption> | undefined;
  onChangeObjective: (objective: SingleValue<ObjectiveOption>) => void;
}) {
  const intl = useIntl();

  let nextObjective: SingleValue<ObjectiveOption> | undefined;
  let prevObjective: SingleValue<ObjectiveOption> | undefined;

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<SelectInstance<any> | null>(null);

  useEffect(() => {
    if (isOpen) {
      selectRef.current?.focusedOptionRef?.focus();
    }
  }, [isOpen]);

  const blur = () => {
    selectRef.current?.blur();
    setIsOpen(false);
  };

  useKeyPress('Escape', blur);

  const handleChange = (value: SingleValue<ObjectiveOption>) => {
    selectRef.current?.blur();
    setIsOpen(false);
    onChangeObjective(value);
  };

  const formatGroupLabel = (group: GroupBase<ObjectiveOption>) => {
    return <SelectGroupLabel group={group} />;
  };

  if (!groupedOptions) {
    return <span>No Options</span>;
  }

  if (defaultObjective && defaultObjective.value) {
    // find next option
    // find current group
    const currentGroupIndex = groupedOptions
      .map((el) => el.stageId)
      .indexOf(defaultObjective.stageId);
    const currentObjectiveIndex = groupedOptions[currentGroupIndex].options
      .map((el) => el.value)
      .indexOf(defaultObjective.value);
    // current objective exists
    if (
      currentGroupIndex !== undefined &&
      currentObjectiveIndex !== undefined
    ) {
      // Set Next Objective
      // current objective is NOT last - so NEXT objective is in this group
      if (
        currentObjectiveIndex <
        groupedOptions[currentGroupIndex].options.length - 1
      ) {
        nextObjective =
          groupedOptions[currentGroupIndex].options[currentObjectiveIndex + 1];
      }
      // try next group (if exists)
      else if (groupedOptions[currentGroupIndex + 1]) {
        const [option] = groupedOptions[currentGroupIndex + 1].options;
        nextObjective = option;
      }
      // this is the end, my friend
      else {
        nextObjective = undefined;
      }
      // Set Prev Objective
      // current objective is NOT first - so PREV objective is in this group
      if (currentObjectiveIndex !== 0) {
        prevObjective =
          groupedOptions[currentGroupIndex].options[currentObjectiveIndex - 1];
      }
      // if the current is the first of a group, try prev group (if exists)
      else if (groupedOptions[currentGroupIndex - 1]) {
        const { options } = groupedOptions[currentGroupIndex - 1];
        const lastOption = options[options.length - 1];
        prevObjective = lastOption;
      }
      // this is the end, my friend
      else {
        prevObjective = undefined;
      }
    }
  }

  const handleNextObjective = () => {
    if (nextObjective) {
      onChangeObjective(nextObjective);
    }
  };

  const handlePrevObjective = () => {
    if (prevObjective) {
      onChangeObjective(prevObjective);
    }
  };

  const selectObjectiveStyles: StylesConfig<any, false> = {
    control: (provided) => ({
      ...provided,
      width: '100%'
      // display: 'none'
    }),
    menu: () => ({
      boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'
    }),
    menuList: () => ({
      maxHeight: '80vh',
      overflowY: 'auto',
      position: 'relative',
      paddingBottom: '4px',
      paddingTop: '4px',
      boxSizing: 'border-box',
      WebkitOverflowScrolling: 'touch'
    })
  };

  return (
    <div className="d-flex">
      {prevObjective && (
        <button
          className="btn btn-light"
          type="button"
          disabled={!prevObjective}
          onClick={() => handlePrevObjective()}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="visually-hidden">
            <FormattedMessage
              id="objective.prev"
              defaultMessage="Previous Objective"
              description="Previous Objective"
            />
          </span>
        </button>
      )}

      <Dropdown
        isOpen={isOpen}
        classNames={{
          blanket: 'position-fixed modal-backdrop show',
          reactSelectDropwdown: 'flex-grow-1'
        }}
        styles={{
          menu: {
            zIndex: 2,
            left: 0,
            top: 0,
            marginTop: '10vh',
            paddingLeft: '2vw',
            paddingRight: '2vw',
            position: 'fixed',
            width: '100%'
          }
        }}
        onClose={() => setIsOpen(false)}
        target={
          <button
            className="btn form-control rounded w-100 border"
            type="button"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div className="d-flex">
              <div className="overflow-hidden w-100">
                <p className="mb-0 text-start">{defaultObjective?.label}</p>
              </div>
              <div className="ps-2 border-start">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </button>
        }
      >
        <div className="card" tabIndex={-1}>
          <CardHeader
            button={
              <button
                type="button"
                className="btn"
                onClick={() => setIsOpen(false)}
              >
                <FontAwesomeIcon icon={faClose} />
                <span className="visually-hidden">
                  {' '}
                  <FormattedMessage id="close" defaultMessage="Close" />
                </span>
              </button>
            }
          >
            <h1>
              <FormattedMessage
                id="objective.select"
                defaultMessage="Select Objective"
              />
            </h1>
          </CardHeader>
          <div className="card-body p-0">
            <Select<ObjectiveOption>
              aria-label={intl.formatMessage({
                id: 'objective.select',
                defaultMessage: 'Select Objective'
              })}
              autoFocus
              backspaceRemovesValue={false}
              className="select-input w-100 p-2 bg-white rounded"
              classNamePrefix="react-select"
              classNames={{ control: () => 'visually-hidden' }}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null
              }}
              controlShouldRenderValue={false}
              defaultValue={defaultObjective}
              formatGroupLabel={formatGroupLabel}
              hideSelectedOptions={false}
              id="objectives"
              isSearchable={false}
              isClearable={false}
              key="objective-select"
              menuIsOpen
              menuPlacement="auto"
              onChange={(newValue) => handleChange(newValue)}
              options={groupedOptions}
              ref={selectRef}
              // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              styles={selectObjectiveStyles}
              // value={selectedOption}
              tabSelectsValue={false}
              tabIndex={-1}
            />
          </div>
        </div>
      </Dropdown>
      {nextObjective && (
        <button
          className="btn btn-light"
          type="button"
          disabled={!nextObjective}
          onClick={() => handleNextObjective()}
        >
          <FontAwesomeIcon icon={faChevronRight} className="ms-1" />
          <span className="visually-hidden">
            <FormattedMessage
              id="objective.next"
              defaultMessage="Next Objective"
              description="Next Objective"
            />
          </span>
        </button>
      )}
    </div>
  );
}

export default ObjectiveSelect;
