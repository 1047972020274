/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import { FormattedMessage } from 'react-intl';
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';

import { components } from '../../types/openapi/UserService';
import PaginatedTable from '../tables/PaginatedTable';

function InstructorsTable({
  data,
  error,
  columns,
  isLoading,
  refetching,
  setPagination,
  setSorting,
  setFiltering,
  pagination,
  sorting,
  filtering,
  queryParamsLocation
}: {
  data: components['schemas']['PagedUserDto'] | undefined | null;
  error: any;
  columns: ColumnDef<any>[];
  isLoading: boolean;
  refetching: boolean;
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  queryParamsLocation: string;
}) {
  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  const table = useReactTable({
    data: data?.users ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfUsers
        ? Math.ceil(data.numberOfUsers / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      dataCount={data?.numberOfUsers}
      columns={columns}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="userId"
      noneFoundMessage={
        <FormattedMessage
          id="instructors.none_found"
          defaultMessage="No instructors"
          description="No instructors found"
        />
      }
      showFooter={(data?.numberOfUsers ?? 0) > 10}
    />
  );
}
export default InstructorsTable;
