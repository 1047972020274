import React from 'react';
import { FormattedMessage } from 'react-intl';
import { components } from '../../../types/openapi/CourseService';
import { GeneralError } from '../../common';
import FrameworkObjectiveSnake from '../../progress/framework/FrameworkObjectiveSnake';
import ReadOnlyStar from '../../progress/ReadOnlyStar';

function OfflineCourseStageObjectivesWrapper({
  learnerId,
  course,
  objectives,
  userStarsForStage
}: {
  learnerId: number;
  course: components['schemas']['CourseSlimDto'];
  objectives: components['schemas']['ObjectiveSlimDto'][] | null | undefined;
  userStarsForStage:
    | components['schemas']['StarsForUserDto'][]
    | null
    | undefined;
}) {
  return (
    <div className="card border-0 rounded-top-right-lg mb-2 mt-2">
      <div className="card-header rounded-top-right-lg">
        <h2>
          <FormattedMessage
            id="objectives"
            defaultMessage="Objectives"
            description="Objectives"
          />
        </h2>
      </div>
      <div className="card-body">
        <p>
          <FormattedMessage
            id="offline.objectives.not-available-uneditable"
            defaultMessage="In offline mode, only learner's current stage data is available and you cannot update stars from this page."
            description="In offline mode, only learner's current stage data is available and you cannot update stars from this page."
          />
        </p>
        {!!objectives && !!userStarsForStage && !!course.frameworkVersionId ? (
          <FrameworkObjectiveSnake
            objectives={objectives}
            userStars={userStarsForStage}
            frameworkVersionId={course.frameworkVersionId}
            userId={learnerId}
            renderStarElement={({ userStarValue, inputStarValue }) => {
              return (
                <ReadOnlyStar
                  usersStar={userStarValue}
                  starValue={inputStarValue}
                />
              );
            }}
          />
        ) : (
          <GeneralError />
        )}
      </div>
    </div>
  );
}

export default OfflineCourseStageObjectivesWrapper;
