import React from 'react';
import { FormattedMessage } from 'react-intl';
import PasswordCharacterValidationRequirements from '../PasswordCharacterValidationRequirements';

function PasswordRequirements() {
  return (
    <div id="password-requirements">
      <p>
        <FormattedMessage
          id="form.label.password.requirements.intro"
          defaultMessage="Your password should:"
        />
      </p>
      <ul>
        <li>
          <FormattedMessage
            id="form.label.password.requirements.minLength"
            defaultMessage="Be eight or more characters long"
          />
        </li>
        <li>
          <FormattedMessage
            id="form.label.password.requirements.hasNoSpaces"
            defaultMessage="Contain no spaces"
          />
        </li>
        <li>
          <FormattedMessage
            id="form.label.password.requirements.characters"
            defaultMessage="Contain characters from three of the following four categories:"
          />

          <PasswordCharacterValidationRequirements />
        </li>
      </ul>
    </div>
  );
}

export default PasswordRequirements;
