/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  OnChangeFn,
  ColumnFiltersState
} from '@tanstack/react-table';
import { components } from '../../types/openapi/ProviderService';
import GroupAdmin from './admins/GroupAdminNames';
import PaginatedTable from '../tables/PaginatedTable';

function GroupsTable({
  data,
  error,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  renderGroupsLink,
  queryParamsLocation
}: {
  data: components['schemas']['PagedGroupDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: OnChangeFn<ColumnFiltersState> | undefined;
  renderGroupsLink?: ({
    group
  }: {
    group: components['schemas']['GroupDto'];
  }) => JSX.Element | ReactNode;
  queryParamsLocation: string;
}) {
  const intl = useIntl();

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    if (setFiltering) {
      setFiltering([]);
    }
  };

  const columns = [
    {
      id: 'groupName',
      header: intl.formatMessage({
        id: 'name',
        defaultMessage: 'Name',
        description: 'Name'
      }),
      cell: ({ row }: { row: any }) => {
        const group = row.original;
        return `${group?.name}`;
      },
      accessorKey: 'groupName',
      filterType: 'basicInput'
    },
    {
      id: 'adminsListFirstName',
      header: intl.formatMessage({
        id: 'adminsListFirstName',
        defaultMessage: 'Group Admin(s)',
        description: 'Group Admin(s)'
      }),
      cell: ({ row }: { row: any }) => {
        const group = row.original;
        if (group) {
          return <GroupAdmin admins={group.userGroupAdmins ?? []} />;
        }

        return intl.formatMessage({
          id: 'admins.none',
          defaultMessage: 'No Admins provided',
          description: 'No Admins provided'
        });
      },
      accessorKey: 'adminsListFirstName',
      filterType: 'basicInput'
    },
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const groupLink =
          renderGroupsLink &&
          renderGroupsLink({
            group: row.original
          });
        return <div>{groupLink}</div>;
      },
      filterType: undefined
    }
  ];

  const table = useReactTable({
    data: data?.groups ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfGroups
        ? Math.ceil(data.numberOfGroups / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfGroups}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="groupId"
      noneFoundMessage={
        <FormattedMessage
          id="groups.error.none"
          defaultMessage="No groups found"
          description="No groups found"
        />
      }
      showFooter={(data?.numberOfGroups ?? 0) > 10}
    />
  );
}

export default GroupsTable;

GroupsTable.defaultProps = {
  renderGroupsLink: () => {}
};
