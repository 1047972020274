import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppLanguage } from '../const';
import learnerKeys from '../query-keys/learner-key-factory';
import {
  getUsersForProviderCentreOfUserType,
  getUsersForProviderCentreOfUserTypeCount,
  getUsersForProviderOfUserType,
  getUsersForProviderOfUserTypeCount
} from '../services/api/user.service';
import { UserType } from '../const/user-type';
import isAllowed from '../utils/permissions/isAllowed';
import { ProfileContext } from '../modules/profile/ProfileProvider';
import { FilteringObject } from '../const/filtering-object';

// hook for managing learners
const useLearners = ({
  providerId,
  centreId,
  displayLocale = AppLanguage.English,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  providerId: number | null | undefined;
  centreId?: number | null | undefined;
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  // eslint-disable-next-line prefer-const
  let filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const learnersListQuery = useQuery({
    queryKey: centreId
      ? learnerKeys.providerCentreList(providerId!, centreId, displayLocale, {
          pageIndex,
          pageSize,
          sorting,
          filtering
        })
      : learnerKeys.providerList(providerId!, displayLocale, {
          pageIndex,
          pageSize,
          sorting,
          filtering
        }),
    queryFn: () => {
      if (centreId) {
        return getUsersForProviderCentreOfUserType(
          providerId,
          centreId,
          UserType.LEARNER,
          pageIndex,
          pageSize,
          sorting,
          filtering,
          displayLocale
        );
      }
      return getUsersForProviderOfUserType(
        providerId,
        UserType.LEARNER,
        pageIndex,
        pageSize,
        sortField,
        sortDirection,
        filtering,
        displayLocale
      );
    },
    refetchOnMount: true,
    enabled:
      !!providerId &&
      isAllowed([UserType.ADMIN], profile?.userTypeId as UserType),
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'Users not Found') return false;
      if (failureCount >= 3) return false;
      return false;
    }
  });

  const learnersListDashboardQuery = useQuery({
    queryKey: centreId
      ? learnerKeys.providerCentreListDashboard(
          providerId!,
          centreId,
          displayLocale
        )
      : learnerKeys.providerListDashboard(providerId!, displayLocale),
    queryFn: () => {
      if (centreId) {
        return getUsersForProviderCentreOfUserTypeCount(
          providerId,
          centreId,
          UserType.LEARNER,
          displayLocale
        );
      }
      return getUsersForProviderOfUserTypeCount(
        providerId,
        UserType.LEARNER,
        displayLocale
      );
    },
    refetchOnMount: true,
    enabled:
      !!providerId &&
      isAllowed([UserType.ADMIN], profile?.userTypeId as UserType),
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'Users not Found') return false;
      if (failureCount >= 3) return false;
      return false;
    }
  });

  return {
    learnersListQuery,
    learnersListDashboardQuery
  };
};

export default useLearners;
