import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';

function DragonGame() {
  const profileContext = useContext(ProfileContext);
  const user = { ...profileContext?.profile?.user };
  const { username, firstName, surname, sportPassportId } = user;
  const intl = useIntl();

  if (!user) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="no_user_id'"
            defaultMessage="No User Id"
            description="No User Id has been passed to the DragonGame component"
          />
        }
      />
    );
  }

  return (
    <div className="ratio ratio-4x3">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.dragon_game',
            defaultMessage: 'Dragon Game'
          })}
        </title>
      </Helmet>
      <iframe
        title="DragonGame"
        src={`https://dragon-game.azurewebsites.net/?username=${username}&scorefirstname=${firstName}&scoresurname=${surname}&sportpassportid=${sportPassportId}`}
        className="w-100 h-100 d-block"
      />
    </div>
  );
}

export default DragonGame;
