import React from 'react';
import { components } from '../../types/openapi/ProviderService';

function ProviderAddress({
  address
}: {
  address: components['schemas']['AddressDto'];
}) {
  const {
    postalCode,
    buildingNumberName,
    streetName,
    townCity,
    county,
    countryRegion
  } = address;
  return (
    <address>
      {buildingNumberName && (
        <>
          {buildingNumberName}
          <br />
        </>
      )}
      {streetName && (
        <>
          {streetName}
          <br />
        </>
      )}
      {townCity && (
        <>
          {townCity}
          <br />
        </>
      )}
      {county && (
        <>
          {county}
          <br />
        </>
      )}
      {countryRegion && (
        <>
          {countryRegion}
          <br />
        </>
      )}
      {postalCode && (
        <>
          {postalCode}
          <br />
        </>
      )}
    </address>
  );
}
export default ProviderAddress;
