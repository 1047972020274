import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TIMEZONE } from '../../utils/date-time';

function DataLastUpdated({
  dataUpdatedAt,
  updatedMessage,
  className
}: {
  dataUpdatedAt: number;
  updatedMessage?: JSX.Element;
  className?: string;
}) {
  const intl = useIntl();
  const updated = updatedMessage || (
    <FormattedMessage
      id="data_last_updated"
      defaultMessage="Data last updated"
    />
  );
  return (
    <span className={`small ${className}`}>
      {' '}
      <FormattedMessage
        id="offline.data_last_synced"
        defaultMessage="{updated}: {date}"
        description="Data last updated at this time"
        values={{
          updated,
          date: intl.formatDate(dataUpdatedAt, {
            timeZone: TIMEZONE,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        }}
      />
    </span>
  );
}

export default DataLastUpdated;

DataLastUpdated.defaultProps = {
  className: '',
  updatedMessage: ''
};
