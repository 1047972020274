// eslint-disable-next-line import/prefer-default-export
export enum UserType {
  SUPER_ADMIN = 1,
  SPORT_MANAGER = 2,
  ADMIN = 3,
  INSTRUCTOR = 4,
  LEARNER = 5,
  GROUP_ADMIN = 6,
  SCHOOL_COORDINATOR = 7
}
