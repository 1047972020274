import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import sessionKeys from '../../query-keys/session-key-factory';
import { getOfflineDataDownload } from '../../services/api/course.service';

const useOfflineDataDownload = ({
  sessionId,
  downloadSessionData
}: {
  sessionId: number;
  downloadSessionData: boolean;
}) => {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const offlineDataDownloadQuery = useQuery({
    queryKey: sessionKeys.offlineData(sessionId, displayLocale),
    queryFn: () => getOfflineDataDownload(sessionId),
    refetchOnMount: true,
    enabled: !!sessionId && downloadSessionData
  });

  const session = offlineDataDownloadQuery.data?.session;

  const course = offlineDataDownloadQuery.data?.courseInfo;

  const courseCapacity =
    offlineDataDownloadQuery.data?.courseInfo?.totalNumberOfPeople;

  const frameworkStages = offlineDataDownloadQuery.data?.frameworkStages;

  const learnerDetails = offlineDataDownloadQuery.data?.learnerDetails;

  const userStars = offlineDataDownloadQuery.data?.userStars;

  const multipleStagesObjectives = course?.courseStages?.map((courseStage) => {
    const { stage } = courseStage;
    return {
      stageId: stage?.stageId,
      stageName: stage?.name,
      stageOrder: stage?.stageOrder,
      stageObjectives: stage?.objectives
    };
  });

  const getCurrentFrameworkStageIdForLearner = (
    learnerId: number | undefined
  ) => {
    if (offlineDataDownloadQuery.data) {
      const learner = learnerDetails?.find((l) => l.userId === learnerId);
      return learner?.currentStageIdOnFramework;
    }
    return undefined;
  };

  const getStageObjectives = (stageId: number | undefined | null) => {
    if (offlineDataDownloadQuery.data)
      return course?.courseStages?.find(
        (courseStage) => courseStage.stageId === stageId
      )?.stage?.objectives;
    return undefined;
  };

  const getCurrentStageFrameworkStars = (learnerId: number | undefined) =>
    userStars
      ?.filter((stars) => stars.userId === learnerId)
      .map((stars) => stars.objectiveStars)
      .flat();

  return {
    offlineDataDownloadQuery,
    getCurrentFrameworkStageIdForLearner,
    getStageObjectives,
    learnerDetails,
    getCurrentStageFrameworkStars,
    frameworkStages,
    session,
    courseCapacity,
    course,
    userStars,
    multipleStagesObjectives
  };
};

export default useOfflineDataDownload;
