import { useQuery } from '@tanstack/react-query';
import providerKeys from '../query-keys/provider-key-factory';
import { getProviderConfig } from '../services/api/provider.service';
import currencyFormatter from '../utils/currencyFormatter';
import { getProviderLanguagesFromConfig } from '../utils/lang';

const useProviderConfig = ({
  providerId
}: {
  providerId: number | undefined | null;
}) => {
  const providerConfigQuery = useQuery({
    queryKey: providerKeys.providerConfig(providerId),
    queryFn: () => getProviderConfig(providerId),
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week - stays the same until manually invalidated or refreshed
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!providerId
  });

  const providerPaymentEnabled =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'PayForCourses')?.value
    );

  const profileEditingEnabled =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'ProfileEditing')?.value
    );

  const notesEnabled =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'EnableNotes')?.value
    );

  // if this is true, Instructors email is required and they will get sent emails
  // but if its false (eg they are EA) then
  // they wont get sent emails even if instructor has an email addresss
  const instructorNonSSOLogin =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'InstructorNonSSOLogin')
        ?.value
    );

  // if this is true, Learners email is required and they will get sent emails
  // but if its false (eg they are EA) then
  // they wont get sent emails even if learner has an email addresss
  const learnerNonSSOLogin =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'LearnerNonSSOLogin')
        ?.value
    );

  const adminNonSSOLogin =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'AdminNonSSOLogin')
        ?.value
    );

  const sportManagerNonSSOLogin =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find(
        (el) => el.key === 'SportManagerNonSSOLogin'
      )?.value
    );

  const nonSSOLogin =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'NonSSOLogin')?.value
    );

  const pricePerCourse =
    providerConfigQuery.data &&
    currencyFormatter.format(
      Number(
        providerConfigQuery.data?.find((el) => el.key === 'PricePerCourse')
          ?.value
      )
    );

  const schoolsAvailable =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'SchoolsAvailable')
        ?.value
    );

  // if no provider languages, then provider user with all available rather than none
  const providerLanguages = getProviderLanguagesFromConfig(
    providerConfigQuery.data
  );

  const learnerBookingsEnabled =
    providerConfigQuery.data &&
    !!Number(
      providerConfigQuery.data?.find((el) => el.key === 'LearnerBookings')
        ?.value
    );

  return {
    providerConfigQuery,
    providerPaymentEnabled,
    profileEditingEnabled,
    notesEnabled,
    instructorNonSSOLogin,
    learnerNonSSOLogin,
    adminNonSSOLogin,
    sportManagerNonSSOLogin,
    nonSSOLogin,
    pricePerCourse,
    schoolsAvailable,
    providerLanguages,
    learnerBookingsEnabled
  };
};

export default useProviderConfig;
