import { Mutation, useQueryClient } from '@tanstack/react-query';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalContext } from '../../../modules/modal/ModalProvider';

function ClearErrorsBtn({
  setMutations,
  setErroredMutations
}: {
  setMutations: Dispatch<
    SetStateAction<Mutation<unknown, unknown, void, unknown>[]>
  >;
  setErroredMutations: Dispatch<
    SetStateAction<Mutation<unknown, unknown, void, unknown>[] | undefined>
  >;
}) {
  const intl = useIntl();

  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();
  const clearData = () => {
    mutationCache.clear();
    setErroredMutations([]);
    setMutations([]);
  };

  async function onModalConfirm() {
    clearData();
  }

  const openModal = () => {
    const header = intl.formatMessage({
      id: 'errors.clear',
      defaultMessage: 'Clear errors',
      description: 'Clear errors'
    });

    const modalContent = intl.formatMessage({
      id: 'errors.clear.confirm.body',
      defaultMessage:
        'Before clearing these errors, please ensure you have made a note of the error code or have sent the error to the support desk.'
    });
    const confirmText = intl.formatMessage({
      id: 'errors.clear.confirm.button',
      defaultMessage: 'Clear errors',
      description: 'Clear errors'
    });

    modal(modalContent, {
      header,
      confirmText,
      confirm: () => onModalConfirm()
    });
  };
  return (
    <button className="btn btn-primary" onClick={openModal} type="button">
      <FormattedMessage id="error.log.clear" defaultMessage="Clear error log" />
    </button>
  );
}
export default ClearErrorsBtn;
