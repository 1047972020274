import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';

import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import Timetable from '../components/timetable/Timetable';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { UserType } from '../const/user-type';

export default function timetableRoutes(
  lang: AppLanguage | string | undefined
) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Timetable],
    element: (
      <ProtectedRoute
        allowedRoles={[UserType.INSTRUCTOR, UserType.LEARNER, UserType.ADMIN]}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="timetable"
          defaultMessage="Timetable"
          description="Timetable"
        />
      )
    },
    children: [
      {
        index: true,
        element: <Timetable />,
        errorElement: <ErrorPage />
      }
    ]
  };
  return routes;
}
