import { components } from '../types/openapi/CourseService';

const isCourseStageSlim = (
  value:
    | components['schemas']['CourseStageDto']
    | components['schemas']['CourseStageSlimDto']
): value is components['schemas']['CourseStageDto'] => {
  return !!value;
};

const getStageIdsFromCourseStages = (
  courseStages:
    | components['schemas']['CourseStageDto'][]
    | components['schemas']['CourseStageSlimDto'][]
    | undefined
    | null
) => {
  if (courseStages && isCourseStageSlim(courseStages[0]))
    return (
      (courseStages as components['schemas']['CourseStageSlimDto'][])
        ?.filter((el) => !!el.stage)
        .map((el) => Number(el.stage?.stageId)) || []
    );

  return (
    (courseStages as components['schemas']['CourseStageDto'][])
      ?.filter((el) => !!el.stage)
      .map((el) => Number(el.stage?.stageId)) || []
  );
};

export default getStageIdsFromCourseStages;
