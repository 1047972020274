import { IntlShape } from 'react-intl';
import { UserType } from '../../../const/user-type';
import { components } from '../../../types/openapi/UserService';
import { SPCalendarEvent } from '../SPCalendarEvent.type';

const getConfirmText = ({
  event,
  intl,
  profile
}: {
  event: SPCalendarEvent;
  intl: IntlShape;
  profile: components['schemas']['UserProvidersDto'] | null | undefined;
}) => {
  if (profile?.userTypeId === UserType.ADMIN) {
    return intl.formatMessage({
      id: 'course.view.button',
      defaultMessage: 'View course',
      description: 'View course'
    });
  }
  if (event.bookable && !event.fullyBooked) {
    return intl.formatMessage({
      id: 'session.book',
      defaultMessage: 'Book Session',
      description: 'Book Session'
    });
  }
  if (event.bookable) {
    return intl.formatMessage({
      id: 'session.book',
      defaultMessage: 'Book Session',
      description: 'Book Session'
    });
  }
  if (event.fullyBooked) {
    return intl.formatMessage({
      id: 'session.full',
      defaultMessage: 'Session full',
      description: 'Session full'
    });
  }
  return intl.formatMessage({
    id: 'session.view.button',
    defaultMessage: 'View session',
    description: 'View session'
  });
};
export default getConfirmText;
