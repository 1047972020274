import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import { AppLanguage } from '../const';

function LanguageRedirect() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  // if app root then redirect to home page
  return (
    <div>
      {displayLocale ? (
        <Navigate to={`${displayLocale}`} replace />
      ) : (
        <Navigate to={`/${AppLanguage.English}`} replace />
      )}
    </div>
  );
}

export default LanguageRedirect;
