import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import {
  Calendar,
  Views,
  Event,
  Culture,
  DateLocalizer,
  View
} from 'react-big-calendar';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import SessionModalContent from './SessionModalContent';
import { cy } from '../../modules/i18n/calendar/cy';
import { ModalContext } from '../../modules/modal/ModalProvider';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import SessionAgenda from './SessionAgenda';
import { getSessionLinkUrl, getSessionBookingLinkUrl } from './helpers';
import { SPCalendarEvent } from './SPCalendarEvent.type';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import dayjsLocalizerTimezone from '../../modules/calendar/dayjsLocalizerTimezone';
import { TIMEZONE } from '../../utils/date-time';
import useMediaQuery from '../../hooks/ui/useMediaQuery';
import useProviderConfig from '../../hooks/useProviderConfig';
import { UserType } from '../../const/user-type';
import getCourseLinkUrl from './helpers/getCourseLinktUrl';
import getLinkUrl from './helpers/getLinkUrl';
import getConfirmText from './helpers/getConfirmText';
import { GeneralError } from '../common';

// set up project defaults for calendar
function SportPassportCalendar({
  events,
  setStartDate,
  setEndDate,
  isLoading,
  error
}: {
  events: Event[] | undefined;
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  error: any;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const langCtx = useContext(LangContext);
  const { displayLocale, locale } = langCtx;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const localizer = dayjsLocalizerTimezone(dayjs);
  const navigate = useNavigate();
  const intl = useIntl();

  const { providerPaymentEnabled, pricePerCourse } = useProviderConfig({
    providerId: Number(profile?.providerId)
  });

  // Small devices (landscape phones, 576px and up)
  const isMobile = useMediaQuery('(max-width: 576px)');

  const defaultView = isMobile
    ? Views.AGENDA
    : appMode === AppMode.OFFLINE
    ? Views.DAY
    : Views.WEEK;

  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;

  const [calendarView, setCalendarView] = useState<View>();
  const [isMonthView, setIsMonthView] = useState<boolean>(false);

  const handleOnViewChange = (view: View) => {
    setIsMonthView(view === 'month');
    setCalendarView(view);
  };

  const handleSelectEvent = useCallback(
    (event: Event) => {
      const url = getLinkUrl({
        event: event as SPCalendarEvent,
        profile,
        displayLocale,
        learnerBookedOnCourse: !(event as SPCalendarEvent).bookable
      });
      const confirmText = getConfirmText({
        event: event as SPCalendarEvent,
        intl,
        profile
      });

      const cancelText = <FormattedMessage id="close" defaultMessage="Close" />;

      // only open popup on day/week/month views
      if (
        (calendarView && calendarView !== 'agenda') ||
        defaultView !== 'agenda'
      ) {
        const header = <h1>{event.title}</h1>;
        const modalContent = (
          <SessionModalContent
            event={event}
            priceOfCourse={providerPaymentEnabled ? pricePerCourse : undefined}
          />
        );
        modal(modalContent, {
          confirm: async () => {
            navigate(url);
          },
          header,
          confirmText,
          cancelText,
          hideFooter:
            (event as SPCalendarEvent).fullyBooked &&
            !(event as SPCalendarEvent).learnerBookedOnSession &&
            !(event as SPCalendarEvent).bookable
        });
      }
    },
    [calendarView, defaultView]
  );

  const lang: any = {
    en: null,
    'en-GB': null,
    cy: {
      ...cy,
      showMore: (total: number) =>
        intl.formatMessage(
          {
            id: 'show_more',
            defaultMessage: '{total} more',
            description: '{total} more'
          },
          { total }
        )
    }
  };
  // React Big Calendar customisations

  const { components } = useMemo(
    () => ({
      components: {
        agenda: {
          event: (event: Event) => {
            const url = (event as any).event.bookable
              ? getSessionBookingLinkUrl(
                  (event as any).event,
                  profile,
                  displayLocale
                )
              : profile?.userTypeId === UserType.ADMIN
              ? getCourseLinkUrl((event as any).event, profile, displayLocale)
              : getSessionLinkUrl(
                  (event as any).event,
                  profile,
                  displayLocale,
                  true
                );
            return SessionAgenda({
              event,
              url
            });
          }
        }
      }
    }),
    [profile]
  );

  const { defaultDate, formats, scrollToTime } = useMemo(() => {
    dayjs.tz.setDefault(TIMEZONE);
    return {
      defaultDate: new Date(),
      formats: {
        timeGutterFormat: (
          date: Date,
          culture?: Culture,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          localizer?: DateLocalizer
        ) => {
          const formattedDateTime =
            localizer?.format(date, 'hh:mm a', culture) || '';
          return formattedDateTime;
        },
        dayFormat: (
          date: Date,
          culture?: Culture,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          localizer?: DateLocalizer
        ) => {
          const formattedDay =
            (isMobile
              ? localizer?.format(date, 'dd', culture)
              : localizer?.format(date, 'ddd D', culture)) || '';
          return formattedDay;
        }
      },
      scrollToTime: dayjs().hour(8).toDate()
    };
  }, [isMobile]);

  const eventPropGetter = useCallback(
    (event: any) => ({
      ...(event.bookable && {
        className: 'session--bookable'
      }),
      ...(event.fullyBooked && {
        className: 'session--fully-booked'
      })
    }),
    []
  );

  const { messages } = useMemo(() => {
    const newLocale = displayLocale || 'en';
    return {
      messages: lang[newLocale]
    };
  }, [displayLocale]);

  const onRangeChange = (
    range:
      | Date[]
      | {
          start: Date;
          end: Date;
        }
  ) => {
    // if in day or week view
    if (Array.isArray(range)) {
      const start = range[0];
      const end = range.length > 1 ? range[range.length - 1] : range[0];
      setStartDate(dayjs(start).format('YYYY-MM-DD'));
      setEndDate(dayjs(end).format('YYYY-MM-DD'));
    }
    // if in month or agenda view
    else {
      const { start, end } = range;
      setStartDate(dayjs(start).format('YYYY-MM-DD'));
      setEndDate(dayjs(end).format('YYYY-MM-DD'));
    }
  };

  return (
    <>
      {error && (error as any).response?.status !== 404 && (
        <GeneralError error={error} />
      )}
      <div
        style={{ height: isMonthView ? '100%' : '600px' }}
        className={isLoading ? 'timetable-loading' : undefined}
      >
        <Calendar
          components={components}
          className="flex-grow-1 mh-vh-70"
          culture={locale}
          messages={messages}
          defaultView={defaultView}
          defaultDate={defaultDate}
          scrollToTime={scrollToTime}
          events={events}
          eventPropGetter={eventPropGetter}
          formats={formats}
          localizer={localizer}
          onRangeChange={onRangeChange}
          onSelectEvent={handleSelectEvent}
          step={15}
          timeslots={4}
          view={calendarView || defaultView}
          onView={(view: View) => handleOnViewChange(view)}
          popup
        />
      </div>
    </>
  );
}

export default SportPassportCalendar;
