/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';

import { components } from '../../../../../../types/openapi/UserService';
import { components as courseComponents } from '../../../../../../types/openapi/CourseService';
import SingleObjectiveInput from '../../../objectives/SingleObjectiveInput';
import useFrameworkProgress from '../../../../../../hooks/useFrameworkProgress';
import { LangContext } from '../../../../../../modules/i18n/components/IntlWrapper';

function ObjectivesCell({
  row,
  course,
  sessionId,
  objectiveId,
  stageId,
  starsForUsers,
  handleMarkObjective
}: {
  row: Row<components['schemas']['UserDto']>;
  course:
    | components['schemas']['CourseDto']
    | courseComponents['schemas']['CourseSlimDto'];
  sessionId: number;
  objectiveId: number;
  stageId: number;
  starsForUsers: courseComponents['schemas']['StarsForUserDto'][];
  handleMarkObjective: ({
    learnerId,
    stageId,
    objectiveId,
    numberOfStars
  }: {
    learnerId: number;
    stageId: number;
    objectiveId: number;
    numberOfStars: number;
  }) => any;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const userId = ((row as any).original as components['schemas']['UserDto'])
    .userId!;
  const courseUserStars = starsForUsers.filter(
    (courseStars) => !!courseStars && courseStars.userId === userId
  );

  const objectiveUserStars = courseUserStars
    .find(
      (courseUserStar) =>
        courseUserStar &&
        courseUserStar.stageId &&
        courseUserStar.stageId === stageId
    )
    ?.objectiveStars?.find((star) => star.objectiveId === objectiveId);

  const { currentStageId } = useFrameworkProgress({
    frameworkVersionId: course.frameworkVersionId,
    learnerId: userId,
    displayLocale
  });

  const isStarEditable =
    !currentStageId ||
    (objectiveUserStars &&
      objectiveUserStars.numberOfStars &&
      objectiveUserStars.numberOfStars < 3 &&
      stageId < currentStageId) ||
    stageId >= currentStageId;

  return (
    <div>
      <div className="d-flex flex-grow-1">
        {stageId && objectiveId && (
          <SingleObjectiveInput
            course={course}
            userId={userId}
            sessionId={Number(sessionId)}
            stageId={stageId}
            objectiveStageId={stageId}
            userCurrentStageId={currentStageId}
            objectiveUserStars={objectiveUserStars}
            objectiveId={objectiveId}
            handleMarkObjective={handleMarkObjective}
          />
        )}
      </div>

      {!isStarEditable && (
        <span className="small text-muted">
          <FormattedMessage
            id="objective.uneditable"
            defaultMessage="Objective not editable"
          />
        </span>
      )}
    </div>
  );
}

export default ObjectivesCell;
