/* eslint-disable no-underscore-dangle */
import { AxiosInstance } from 'axios';

import { refreshAccessToken } from './api/auth.service';
import isErrorTokenExpired from '../utils/auth/isErrorTokenExpired';
import isRefreshErrorTokenExpired from '../utils/auth/isRefreshErrorTokenExpired';
import isErrorBothTokensExpired from '../utils/auth/isErrorBothTokensExpired';
import isErrorTokensRemoved from '../utils/auth/isErrorTokensRemoved';
import { StorageId } from '../const/storage-id';

export default async function expiredTokenInterceptor(
  instance: AxiosInstance,
  error: {
    config: any;
    response: { data: string; status: number };
  }
) {
  const originalRequest = error.config;
  const errMessage = error.response?.data as string;
  const expired = isErrorTokenExpired(errMessage);
  const refreshExpired = isRefreshErrorTokenExpired(error);
  const bothTokensExpired = isErrorBothTokensExpired(error);
  const tokensRemoved = isErrorTokensRemoved(errMessage);

  // don't check for tokens on login - the user won't have any tokens at this point yet
  if (originalRequest.url !== '/auth/login' && error.response) {
    // An error has occurred because the refresh Token is expired - logout as user will have to get a new refresh token
    if (error.response.status === 401 && refreshExpired) {
      sessionStorage.removeItem(StorageId.PROFILE);
      window.location.href = `/`;
      return Promise.reject(error);
    }
    // An error has occurred because both tokens have expired
    if (error.response.status === 401 && bothTokensExpired) {
      sessionStorage.removeItem(StorageId.PROFILE);
      window.location.href = `/`;
      return Promise.reject(error);
    }
    // // An error has occurred because both tokens have been manually removed
    if (
      error.response.status === 401 &&
      tokensRemoved &&
      !originalRequest._retry
    ) {
      sessionStorage.removeItem(StorageId.PROFILE);
      return Promise.reject(error).then(() => {
        window.location.href = `/`;
      });
    }
    // An error has occurred because the access Token is expired but refresh token is still valid
    if (error.response.status === 401 && expired && !originalRequest._retry) {
      originalRequest._retry = true;
      // try to get a new token from the auth service
      try {
        const result = await refreshAccessToken();

        const { token } = result;
        window.localStorage.setItem(StorageId.TOKEN, JSON.stringify(token));

        // try the original request with the new token again
        return await instance(originalRequest);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
}
