import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import ErrorPage from '../pages/ErrorPage';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';

const ManageReports = lazy(() => import('../components/reports/ManageReports'));

export default function reportRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Reports],
    element: (
      <ProtectedRoute
        allowedRoles={[UserType.SUPER_ADMIN, UserType.SPORT_MANAGER]}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="report.breadcrumb"
          defaultMessage="Report"
          description="Report"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageReports />,
        errorElement: <ErrorPage />
      }
    ]
  };
  return routes;
}
