import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { AppRoute } from '../../const';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import isAllowed from '../../utils/permissions/isAllowed';
import localiseRoutePath from '../../utils/localiseRoutePath';
import GroupsList from './GroupsList';
import GroupAdminsListPaged from './admins/GroupAdminsListPaged';

function ManageGroups() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.groups.and.admins.manage',
            defaultMessage: 'Manage groups and group admins'
          })}
        </title>
      </Helmet>
      <div className="card border-0 rounded-top-right-lg ">
        <div className="card-header d-flex">
          <div className="flex-grow-1">
            <h1>
              <FormattedMessage
                id="groups.and.admins.manage"
                defaultMessage="Manage Groups and Group Admins"
                description="Manage Groups and Group Admins"
              />
            </h1>
          </div>

          {isAllowed(
            [UserType.SPORT_MANAGER, UserType.SUPER_ADMIN, UserType.ADMIN],
            profile?.userTypeId as UserType
          ) && (
            <div className="d-flex gap-2">
              <Link
                to={localiseRoutePath(AppRoute.CreateGroup)}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="group.link.create_new"
                  defaultMessage="Create new group"
                  description="Create new group"
                />
              </Link>
              <Link
                to={localiseRoutePath(AppRoute.CreateGroupAdmin)}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="group.admin.link.create_new"
                  defaultMessage="Create new group admin"
                  description="Create new group admin"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="card-body d-flex flex-column gap-2">
        <div>
          <GroupsList />
        </div>
        <div>
          <GroupAdminsListPaged
            providerId={profile?.providerId}
            profile={profile}
          />
        </div>
      </div>
    </>
  );
}

export default ManageGroups;
