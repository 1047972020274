import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { components } from '../../types/openapi/UserService';
import { components as courseComponents } from '../../types/openapi/CourseService';
import GenderTranslationIds from '../../modules/i18n/gender/gender-translation-ids';
import { Gender } from '../../const/gender';
import getFormattedDateOfBirth from '../../utils/getFormattedDateOfBirth';
import generateSportPassportId from '../../utils/generateSportPassportId';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';

function UserDetailsTable({
  user
}: {
  user:
    | components['schemas']['UserDto']
    | courseComponents['schemas']['LearnerDto'];
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  const isLearnerDto = (
    value:
      | courseComponents['schemas']['UserDto']
      | courseComponents['schemas']['LearnerDto']
  ): value is courseComponents['schemas']['LearnerDto'] => {
    return (
      (value as courseComponents['schemas']['LearnerDto']).externalLearnerId !==
      undefined
    );
  };
  return (
    <table className="table">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="passportid"
              defaultMessage="Sport Passport Id"
              description="Sport Passport Id"
            />
          </th>
          <td>
            {user.sportPassportId ? (
              generateSportPassportId(Number(user.sportPassportId))
            ) : (
              <span className="text-muted">
                <FormattedMessage
                  id="id.sport_passport.none"
                  defaultMessage="No Sport Passport Id provided"
                  description="No Sport Passport Id provided"
                />
              </span>
            )}
          </td>
        </tr>
        {profile?.userTypeId === UserType.ADMIN && (
          <tr>
            <th scope="row">
              <FormattedMessage
                id="externalId"
                defaultMessage="External Id"
                description="External Id"
              />
            </th>
            <td>
              {isLearnerDto(user) ? (
                user.externalLearnerId
              ) : user.userProviders && user.userProviders[0].externalId ? (
                user.userProviders[0].externalId
              ) : (
                <span className="text-muted">
                  <FormattedMessage
                    id="externalId.none"
                    defaultMessage="No External Id provided"
                    description="No External Id provided"
                  />
                </span>
              )}
            </td>
          </tr>
        )}

        <tr>
          <th scope="row">
            <FormattedMessage
              id="username"
              defaultMessage="Username"
              description="Username"
            />
          </th>
          <td data-gdpr="true">
            {user.username || (
              <span className="text-muted">
                <FormattedMessage
                  id="username.none"
                  defaultMessage="No Username Provided"
                  description="No Username Provided"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="name"
              defaultMessage="Name"
              description="Name"
            />
          </th>
          <td data-gdpr="true">
            {user.fullname ? (
              `${user.fullname}`
            ) : (
              <span className="text-muted">
                <FormattedMessage
                  id="name.none"
                  defaultMessage="No name provided"
                  description="No name provided"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="dob"
              defaultMessage="Date of Birth"
              description="Date of Birth"
            />
          </th>
          <td data-gdpr="true">
            {user.dateOfBirth === '0001-01-01T00:00:00' ? (
              <span className="text-muted">
                <FormattedMessage
                  id="dob.none"
                  defaultMessage="No Date of Birth Provided"
                  description="No Date of Birth Provided"
                />
              </span>
            ) : (
              dayjs(user.dateOfBirth).format('DD/MM/YYYY')
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage id="age" defaultMessage="Age" description="Age" />
          </th>
          <td data-gdpr="true">
            {user.dateOfBirth === '0001-01-01T00:00:00' ? (
              <span className="text-muted">
                <FormattedMessage
                  id="age.none"
                  defaultMessage="No Age Provided"
                  description="No Age Provided"
                />
              </span>
            ) : (
              getFormattedDateOfBirth(`${user.dateOfBirth}`)
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="gender"
              defaultMessage="Gender"
              description="Gender"
            />
          </th>
          <td data-gdpr="true">
            {user.genderId ? (
              <span>
                {intl.formatMessage({
                  id: GenderTranslationIds[user.genderId as Gender]
                })}
              </span>
            ) : (
              <span className="text-muted">
                <FormattedMessage
                  id="gender.none"
                  defaultMessage="No Gender Provided"
                  description="No Gender Provided"
                />
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="phone-number"
              defaultMessage="Phone number"
              description="Phone number"
            />
          </th>
          <td data-gdpr="true">
            {user.telephone || (
              <span className="text-muted">
                <FormattedMessage
                  id="phone_number.none"
                  defaultMessage="No Phone Number Provided"
                  description="No Phone Number Provided"
                />
              </span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default UserDetailsTable;
