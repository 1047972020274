/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';
import { AppLanguage, AppRoute } from '../../../../const';
import { appStrings } from '../../../../modules/i18n';
import { LangContext } from '../../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../../modules/profile/ProfileProvider';
import { GeneralError } from '../../../common';
import { createCourseSessionBookingForGroup } from '../../../../services/api/course.service';
import { components } from '../../../../types/openapi/ProviderService';
import GroupsTable from '../../../groups/GroupsTable';
import Loading from '../../../common/Loading';
import CourseDetailsTable from '../../CourseDetailsTable';
import sessionKeys from '../../../../query-keys/session-key-factory';
import useSession from '../../../../hooks/useSession';
import useCourse from '../../../../hooks/course/useCourse';
import useGroups from '../../../../hooks/useGroups';
import { FilteringQueryParams } from '../../../../const/filtering-query-params';

function AddGroupToCourseSession() {
  const { courseId, sessionId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const intl = useIntl();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const navLinkCourseSession = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Courses]
  }/${courseId}/${appStrings[langVal][AppRoute.ViewSession]}/${sessionId}`;

  const queryParamsLocation = FilteringQueryParams.Groups;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  const [availableSpaces, setAvailableSpaces] = useState<number>(0);

  const { sessionQuery, sessionMembersQuery } = useSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    userId: profile.userId,
    displayLocale: displayLocale || AppLanguage.English
  });

  const { courseQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const { groupsListPagedQuery } = useGroups({
    providerId: profile.providerId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: bookNewGroupOnToCourseSession, isLoading } = useMutation(
    (group: components['schemas']['GroupDto']) =>
      createCourseSessionBookingForGroup(
        Number(courseId),
        Number(sessionId),
        group.groupId!
      ),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'course.session.group.booking.success',
            defaultMessage:
              'This group has successfully been booked on the course session'
          }),
          { delay: 200 }
        );
        navigate(navLinkCourseSession);
        queryClient.invalidateQueries({
          queryKey: sessionKeys.session(
            Number(courseId),
            Number(sessionId),
            displayLocale
          )
        });
        queryClient.invalidateQueries({
          queryKey: sessionKeys.members(
            Number(courseId),
            Number(sessionId),
            displayLocale
          )
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'course.session.group.booking.error',
            defaultMessage:
              'There was an error booking this group onto this course session'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const handleAddGroupToCourseSession = (
    group: components['schemas']['GroupDto']
  ) => {
    bookNewGroupOnToCourseSession(group);
  };

  useEffect(() => {
    if (
      sessionQuery.data &&
      sessionMembersQuery.data &&
      sessionQuery.data.course?.totalNumberOfPeople
    ) {
      setAvailableSpaces(
        sessionQuery.data.course.totalNumberOfPeople -
          sessionMembersQuery.data.length
      );
    }
  }, [sessionMembersQuery.data, sessionMembersQuery.error]);
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.course.colon.name',
              defaultMessage: 'Course: {name}'
            },
            { name: courseQuery.data?.name || '' }
          )}
        </title>
      </Helmet>
      <div className="card">
        <div className="card-header">
          <h2>
            <FormattedMessage
              id="course.colon.name"
              defaultMessage="Course: {name}"
              description="Course: {name}"
              values={{ name: courseQuery.data?.name || '' }}
            />
          </h2>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>
            <FormattedMessage
              id="course.details"
              defaultMessage="Course details"
              description="Course details"
            />
          </h3>
        </div>
        <div className="card-body">
          {courseQuery.isFetching ? (
            <Loading />
          ) : courseQuery.error ? (
            <GeneralError />
          ) : (
            courseQuery.data && <CourseDetailsTable course={courseQuery.data} />
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>
            <FormattedMessage
              id="groups"
              defaultMessage="Groups"
              description="Groups"
            />
          </h3>
        </div>
        <div className="card-body">
          {groupsListPagedQuery.isFetching || sessionMembersQuery.isFetching ? (
            <Loading />
          ) : groupsListPagedQuery.error ? (
            <GeneralError />
          ) : (
            groupsListPagedQuery.data?.groups &&
            courseQuery.data &&
            courseQuery.data.totalNumberOfPeople && (
              <GroupsTable
                data={groupsListPagedQuery.data}
                renderGroupsLink={({ group }) => {
                  return (
                    <div className="btn-group">
                      <Link
                        to={`/${displayLocale?.toLowerCase()}/${
                          appStrings[langVal][AppRoute.Groups]
                        }/${group.groupId}`}
                        className="btn btn-outline-secondary"
                      >
                        <FormattedMessage
                          id="view"
                          defaultMessage="View"
                          description="View"
                        />
                      </Link>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        disabled={
                          sessionMembersQuery.failureReason &&
                          (sessionMembersQuery.failureReason as any).response
                            .data === 'No Members Found Found'
                            ? group.userGroupMembers!.length >
                              courseQuery.data.totalNumberOfPeople!
                            : group.userGroupMembers!.length > availableSpaces
                        }
                        onClick={() => handleAddGroupToCourseSession(group)}
                      >
                        <FormattedMessage
                          id="session.group.add_to_course_session"
                          defaultMessage="Add to course session"
                          description="Add to course session"
                        />
                      </button>
                    </div>
                  );
                }}
                pagination={pagination}
                setPagination={setPagination}
                sorting={sorting}
                setSorting={setSorting}
                filtering={filtering}
                setFiltering={setFiltering}
                error={groupsListPagedQuery.error}
                isLoading={groupsListPagedQuery.isLoading}
                refetching={groupsListPagedQuery.isRefetching}
                queryParamsLocation={queryParamsLocation}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}

export default AddGroupToCourseSession;
