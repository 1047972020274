import { UserType } from '../const/user-type';
import { components } from '../types/openapi/UserService';
import { RoleData } from '../types/RoleData.type';

const filterPermissions = (
  permissions: components['schemas']['UserProvidersDto'][] | undefined
) => {
  if (!permissions) return undefined;
  // return every provider admin permission
  const providerAdminPermissions = permissions.filter(
    (permission) =>
      permission.userTypeId === UserType.ADMIN && !permission.centreId
  );
  // return every centre admin permission
  const centreAdminPermissions = permissions.filter(
    (permission) =>
      permission.userTypeId === UserType.ADMIN &&
      permission?.centreId &&
      !providerAdminPermissions.find(
        (providerPermission) =>
          providerPermission.providerId === permission.providerId
      )
  );
  // return only one instructor permission
  const firstInstructorPermission =
    permissions.filter(
      (permission) => permission.userTypeId === UserType.INSTRUCTOR
    )[0] || undefined;
  // return only one learner permission
  const firstLearnerPermission =
    permissions.filter(
      (permission) => permission.userTypeId === UserType.LEARNER
    )[0] || undefined;

  // return only one sport manager permission
  const firstSportManagerPermission =
    permissions.filter(
      (permission) => permission.userTypeId === UserType.SPORT_MANAGER
    )[0] || undefined;

  // return only one super user permission
  const firstSuperUserPermission =
    permissions.filter(
      (permission) => permission.userTypeId === UserType.SUPER_ADMIN
    )[0] || undefined;

  const filteredPermissions: RoleData = [
    {
      userType: UserType.ADMIN,
      permissions: providerAdminPermissions.concat(centreAdminPermissions)
    },
    {
      userType: UserType.INSTRUCTOR,
      permissions: firstInstructorPermission && [firstInstructorPermission]
    },
    {
      userType: UserType.LEARNER,
      permissions: firstLearnerPermission && [firstLearnerPermission]
    },
    {
      userType: UserType.SPORT_MANAGER,
      permissions: firstSportManagerPermission && [firstSportManagerPermission]
    },
    {
      userType: UserType.SUPER_ADMIN,
      permissions: firstSuperUserPermission && [firstSuperUserPermission]
    }
  ];

  return filteredPermissions;
};

export default filterPermissions;
