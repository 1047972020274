import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { appStrings } from '../../../modules/i18n';
import { AppLanguage, AppRoute } from '../../../const';
import AVAILABLE_LOCALES from '../../../const/available-locales';
import LanguageSwitchNavDropdown from '../../../components/layout/header/LanguageSwitcherNavDropdown';
import Redlogo from '../../../assets/images/logos/sport-passport-brand-primary.png';
import WhiteLogo from '../../../assets/images/logos/sport-passport-logo-white.png';
import eventEnded from '../../../utils/date-time/launchHelper';

function PublicHeader() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  return (
    <header className="header public-header sticky-top px-3">
      <Navbar expand="lg" className="gap-1">
        <div className="text-start logo">
          <Link to="/">
            <img
              style={{ width: '163.655px' }}
              className="logo"
              src={WhiteLogo}
              alt={intl.formatMessage({
                id: 'sport_passport',
                defaultMessage: 'Sport Passport'
              })}
            />
          </Link>
        </div>

        <div className="d-flex align-items-center flex-grow-1 justify-content-end gap-3">
          <Link
            className="text-light fw-bold about-us fs-4 d-none d-md-block"
            to="/"
          >
            <FormattedMessage
              id="landingpage.overview"
              defaultMessage="Overview"
            />
          </Link>
          <Link
            className="text-light fw-bold about-us fs-4 d-none d-md-block"
            to={`/${displayLocale?.toLowerCase()}/${
              appStrings[displayLocale as AppLanguage][AppRoute.About]
            }`}
          >
            <FormattedMessage
              id="landingpage.aboutus"
              defaultMessage="About Us"
            />
          </Link>
          {!eventEnded() && (
            <>
              <Link
                lang="en"
                className="text-light fw-bold about-us fs-4 d-none d-md-block"
                to={`/${displayLocale?.toLowerCase()}/${
                  appStrings[displayLocale as AppLanguage][AppRoute.LaunchEvent]
                }`}
              >
                <FormattedMessage
                  id="landingpage.launchEvent"
                  defaultMessage="Launch Event UK"
                />
              </Link>
              <Link
                lang="cy"
                className="text-light fw-bold about-us fs-4 d-none d-md-block"
                to={`/${displayLocale?.toLowerCase()}/${
                  appStrings[displayLocale as AppLanguage][
                    AppRoute.LaunchEventCy
                  ]
                }`}
              >
                <FormattedMessage
                  id="landingpage.launchEventCy"
                  defaultMessage="Launch Event Wales / Digwyddiad Lansio Cymru"
                />
              </Link>
            </>
          )}

          <LanguageSwitchNavDropdown providerLanguages={AVAILABLE_LOCALES} />

          <Button
            className="d-block d-md-none btn-lg"
            variant="primary"
            onClick={handleShow}
          >
            <FontAwesomeIcon icon={icon({ name: 'bars', style: 'solid' })} />

            <span className="visually-hidden">
              <FormattedMessage id="menu.toggle" defaultMessage="Toggle Menu" />
            </span>
          </Button>

          <Link
            to={`/${displayLocale?.toLowerCase()}/${
              appStrings[displayLocale as AppLanguage][AppRoute.Contact]
            }`}
            className="btn btn-primary btn-lg fw-bold d-none d-md-block"
          >
            <FormattedMessage
              id="landingpage.bookademo"
              defaultMessage="Book a demo"
            />
          </Link>

          <Link
            to={`/${displayLocale?.toLowerCase()}/${
              appStrings[displayLocale as AppLanguage][AppRoute.Login]
            }`}
            className="btn btn-primary btn-lg fw-bold d-none d-md-block"
          >
            <FormattedMessage id="landingpage.login" defaultMessage="Login" />
          </Link>
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <Link to="/" className="flex-grow-1 text-start">
                <img className="logo" src={Redlogo} alt="" />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="d-flex flex-column align-items-start gap-4">
              <NavLink
                to={`/${displayLocale?.toLowerCase()}/${
                  appStrings[displayLocale as AppLanguage][AppRoute.Contact]
                }`}
                className="btn btn-primary btn-lg fw-bold"
                onClick={handleClose}
              >
                <FormattedMessage
                  id="landingpage.bookademo"
                  defaultMessage="Book a demo"
                />
              </NavLink>
              <NavLink
                to={`/${displayLocale?.toLowerCase()}/${
                  appStrings[displayLocale as AppLanguage][AppRoute.Login]
                }`}
                className="btn btn-primary btn-lg fw-bold"
                onClick={handleClose}
              >
                <FormattedMessage
                  id="landingpage.login"
                  defaultMessage="Login"
                />
              </NavLink>
              <NavLink
                className="fw-bold text-decoration-none fs-4"
                to="/"
                onClick={handleClose}
              >
                <FormattedMessage
                  id="landingpage.overview"
                  defaultMessage="Overview"
                />
              </NavLink>
              <NavLink
                className="fw-bold text-decoration-none fs-4"
                to={`/${displayLocale?.toLowerCase()}/${
                  appStrings[displayLocale as AppLanguage][AppRoute.About]
                }`}
                onClick={handleClose}
              >
                <FormattedMessage
                  id="landingpage.aboutus"
                  defaultMessage="About Us"
                />
              </NavLink>
              {!eventEnded() && (
                <>
                  <NavLink
                    lang="en"
                    className="fw-bold text-decoration-none fs-4"
                    to={`/${displayLocale?.toLowerCase()}/${
                      appStrings[displayLocale as AppLanguage][
                        AppRoute.LaunchEvent
                      ]
                    }`}
                    onClick={handleClose}
                  >
                    <FormattedMessage
                      id="landingpage.launchEvent"
                      defaultMessage="Launch Event UK"
                    />
                  </NavLink>
                  <NavLink
                    lang="cy"
                    className="fw-bold text-decoration-none fs-4"
                    to={`/${displayLocale?.toLowerCase()}/${
                      appStrings[displayLocale as AppLanguage][
                        AppRoute.LaunchEventCy
                      ]
                    }`}
                    onClick={handleClose}
                  >
                    <FormattedMessage
                      id="landingpage.launchEventCy"
                      defaultMessage="Launch Event Wales / Digwyddiad Lansio Cymru"
                    />
                  </NavLink>
                </>
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </Navbar>
    </header>
  );
}

export default PublicHeader;
