import React, { ReactNode } from 'react';

function LoadingButton({
  isLoading,
  loadingMessage,
  buttonMessage,
  onClick,
  className,
  type = 'button'
}: {
  loadingMessage: JSX.Element;
  buttonMessage: JSX.Element | ReactNode;
  isLoading: boolean;
  onClick?: () => void;
  className?: string;
  type?: 'reset' | 'button' | 'submit' | undefined;
}) {
  const buttonType = type || 'submit';
  return (
    <button
      // Button MUST have a type - default here is submit
      /* eslint-disable react/button-has-type */
      type={buttonType}
      className={`btn ${className || 'btn-primary'}`}
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <>
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          />
          {loadingMessage}
        </>
      ) : (
        buttonMessage
      )}
    </button>
  );
}

export default LoadingButton;

LoadingButton.defaultProps = {
  type: 'submit',
  className: '',
  onClick: () => {}
};
