import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import ViewSession from '../components/courses/sessions/session/ViewSession';
import ViewCourse from '../components/courses/ViewCourse';
import MainContainer from '../components/layout/MainContainer';
import ViewLearnerOnCourse from '../components/learners-admin/ViewLearnerOnCourse';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import BookSession from '../components/courses/sessions/BookSession';
import AddGroupToCourseSession from '../components/courses/sessions/session/AddGroupToCourseSession';
import EditCourse from '../components/courses/EditCourse';
import AddGroupToCourse from '../components/courses/AddGroupToCourse';
import AddLearnerToCourse from '../components/courses/AddLearnerToCourse';
import AddLearnerToCourseSession from '../components/courses/sessions/session/AddLearnerToCourseSession';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import { UserType } from '../const/user-type';
import Step4 from '../components/courses/create-course/Step4';

const ManageCourses = lazy(
  () => import('../components/courses/manage/ManageCourses')
);

const CreateCourse = lazy(
  () => import('../components/courses/create-course/CreateCourse')
);

const Step1 = lazy(() => import('../components/courses/create-course/Step1'));
const Step2 = lazy(() => import('../components/courses/create-course/Step2'));
const Step3 = lazy(() => import('../components/courses/create-course/Step3'));

const Success = lazy(
  () => import('../components/courses/create-course/Success')
);

export default function coursesRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;

  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Courses],
    element: (
      <ProtectedRoute
        allowedRoles={[
          UserType.SUPER_ADMIN,
          UserType.ADMIN,
          UserType.INSTRUCTOR,
          UserType.LEARNER
        ]}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="courses.breadcrumb"
          defaultMessage="Courses"
          description="Courses"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageCourses />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.CreateCourse],
        element: (
          <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}>
            <CreateCourse />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="courses.create"
              defaultMessage="Create Course"
              description="Create Course"
            />
          )
        },
        children: [
          {
            index: true,
            element: <Step1 />,
            errorElement: <ErrorPage />
          },
          {
            path: appStrings[langVal][AppRoute.Step2],
            element: <Step2 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="Step2"
                  defaultMessage="Step 2"
                  description="Step 2"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.Step3],
            element: <Step3 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="Step3"
                  defaultMessage="Step 3"
                  description="Step 3"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.Step4],
            element: <Step4 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="Step4"
                  defaultMessage="Step 4"
                  description="Step 4"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.Success],
            element: <Success />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="success"
                  defaultMessage="Success"
                  description="Success"
                />
              )
            }
          }
        ]
      },
      {
        path: `:courseId`,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="course"
              defaultMessage="Course"
              description="Course"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ViewCourse />,
            errorElement: <ErrorPage />
          },

          {
            path: `${appStrings[langVal][AppRoute.ViewSession]}/:sessionId`,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="session"
                  defaultMessage="Session"
                  description="Session"
                />
              )
            },
            children: [
              {
                index: true,
                element: <ViewSession />,
                errorElement: <ErrorPage />
              },
              {
                path: `${appStrings[langVal][AppRoute.Learners]}/:learnerId`,
                element: <ViewLearnerOnCourse />,
                handle: {
                  crumb: () => (
                    <FormattedMessage
                      id="session.learner"
                      defaultMessage="Learner"
                      description="Learner"
                    />
                  )
                }
              },
              {
                path: `${
                  appStrings[langVal][AppRoute.AddGroupToCourseSession]
                }`,
                element: <AddGroupToCourseSession />,
                handle: {
                  crumb: () => (
                    <FormattedMessage
                      id="session.group.add"
                      defaultMessage="Add Group"
                      description="Add Group "
                    />
                  )
                }
              },
              {
                path: `${
                  appStrings[langVal][AppRoute.AddLearnerToCourseSession]
                }`,
                element: <AddLearnerToCourseSession />,
                handle: {
                  crumb: () => (
                    <FormattedMessage
                      id="session.learner.add"
                      defaultMessage="Add Learner"
                      description="Add Learner"
                    />
                  )
                }
              }
            ]
          },
          {
            path: appStrings[langVal][AppRoute.EditCourse],
            element: <EditCourse />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="course.edit"
                  defaultMessage="Edit Course"
                  description="Edit Course"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.AddLearnerToCourse]}`,
            element: <AddLearnerToCourse />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="learners.add"
                  defaultMessage="Add Learners"
                  description="Add Learners"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.AddGroupToCourse]}`,
            element: <AddGroupToCourse />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="group.add"
                  defaultMessage="Add Group"
                  description="Add Group"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.BookSession]}/:sessionId`,
            element: <BookSession />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="session.book"
                  defaultMessage="Book Session"
                  description="Book Session"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.Learners]}/:learnerId`,
            element: <ViewLearnerOnCourse />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="learner"
                  defaultMessage="Learner"
                  description="Learner"
                />
              )
            }
          }
        ]
      }
    ]
  };

  return routes;
}
