/* eslint-disable no-inner-declarations */
import React, { useContext } from 'react';

import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import Unauthorised from '../../pages/Unauthorised';
import LearnersListAdmin from './LearnersListAdmin';
import LearnersListInstructor from './LearnersListInstructor';

function LearnersListByRole() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  if (
    profile?.userTypeId === UserType.SPORT_MANAGER ||
    profile?.userTypeId === UserType.ADMIN ||
    profile?.userTypeId === UserType.SUPER_ADMIN
  ) {
    return <LearnersListAdmin profile={profile} />;
  }

  if (profile?.userTypeId === UserType.INSTRUCTOR) {
    return <LearnersListInstructor profile={profile} />;
  }

  return <Unauthorised />;
}

export default LearnersListByRole;
