import { useQuery } from '@tanstack/react-query';
import { getUser } from '../services/api/user.service';
import learnerKeys from '../query-keys/learner-key-factory';

const useLearner = ({
  userId,
  displayLocale
}: {
  userId: number;
  displayLocale: string | undefined;
}) => {
  if (!userId) {
    throw Error('invalid user id');
  }

  const learnerQuery = useQuery({
    queryKey: learnerKeys.learner(userId, displayLocale),
    queryFn: () => getUser(Number(userId)),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!userId
  });

  return {
    learnerQuery
  };
};

export default useLearner;
