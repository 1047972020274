import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import CreateNote from './CreateNote';
import NotesList from './NotesList';
import ViewOnlyNotesList from './ViewOnlyNotesList';

function NotesModalContent({
  courseId,
  sessionId,
  userId,
  closeModal
}: {
  courseId: number;
  sessionId: number;
  userId: number;
  closeModal: () => void;
}) {
  const [showCreateForm, setShowCreateForm] = useState<boolean>();
  const [showEditId, setShowEditId] = useState<number>(0);

  const addNewNote = () => setShowCreateForm(true);

  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const isNetworkOnline = useNetworkStatus();

  if (appMode === AppMode.OFFLINE || !isNetworkOnline)
    return (
      <>
        <ViewOnlyNotesList
          courseId={courseId}
          sessionId={sessionId}
          userId={userId}
          isOffline
        />

        <div className="d-flex justify-content-center">
          <div className="btn-group gap-1">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="close" defaultMessage="Close" />
            </button>
          </div>
        </div>
      </>
    );
  return (
    <>
      {!showCreateForm && (
        <>
          <NotesList
            courseId={courseId}
            sessionId={sessionId}
            userId={userId}
            showEditId={showEditId}
            setShowEditId={setShowEditId}
          />
          <div className="d-flex justify-content-center">
            <div className="btn-group gap-1">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => closeModal()}
              >
                <FormattedMessage id="close" defaultMessage="Close" />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addNewNote}
              >
                <FormattedMessage
                  id="note.create"
                  defaultMessage="Add new note"
                  description="Add new note"
                />
              </button>
            </div>
          </div>
        </>
      )}

      {showCreateForm && (
        <CreateNote
          courseId={courseId}
          sessionId={sessionId}
          userId={userId}
          setShowCreateForm={setShowCreateForm}
        />
      )}
    </>
  );
}

export default NotesModalContent;
