import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Accordion from 'react-bootstrap/Accordion';
import Loading from '../common/Loading';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { AppLanguage } from '../../const';
import useResources from '../../hooks/useResources';
import useFrameworkVersion from '../../hooks/frameworkVersion/useFrameworkVersion';

import { GeneralError } from '../common';
import useStageObjectives from '../../hooks/useStageObjectives';
import UserViewObjective from './UserViewObjective';

function UserViewObjectives() {
  const { frameworkId, frameworkVersionId, stageId, objectiveId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const otherLanguage =
    displayLocale === AppLanguage.English
      ? AppLanguage.Welsh
      : AppLanguage.English;
  const intl = useIntl();

  const { resourcesQuery } = useResources({
    frameworkVersionId: Number(frameworkVersionId),
    displayLocale
  });

  const { resourcesQuery: translatedResourceQuery } = useResources({
    frameworkVersionId: Number(frameworkVersionId),
    displayLocale: otherLanguage
  });

  const { frameworkVersionQuery: frameworkVersion } = useFrameworkVersion({
    frameworkId: Number(frameworkId),
    frameworkVersionId: Number(frameworkVersionId),
    displayLocale
  });

  const { stageObjectivesQuery: objectives } = useStageObjectives({
    frameworkId: Number(frameworkId),
    frameworkVersionId: Number(frameworkVersionId),
    stageId: Number(stageId),
    displayLocale
  });

  if (objectives.isFetching) return <Loading />;

  if (objectives.error)
    return <GeneralError message={(objectives.error as any).response?.data} />;
  return (
    <div className="card border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.objectives',
            defaultMessage: 'Objectives'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="objectives"
            defaultMessage="Objectives"
            description="Objectives"
          />
        </h1>
      </div>
      <div className="card-body">
        <table className="table">
          <tbody>
            <tr>
              <th scope="row">
                <FormattedMessage
                  id="frameworks.name"
                  defaultMessage="Name"
                  description="Name"
                />
              </th>
              <td>{frameworkVersion.data?.name}</td>
            </tr>
            <tr>
              <th scope="row">
                <FormattedMessage
                  id="framework.version.date"
                  defaultMessage="Created"
                  description="Date framework version was created"
                />
              </th>
              <td>
                {dayjs(frameworkVersion.data?.createdDate).format('DD/MM/YYYY')}
              </td>
            </tr>
          </tbody>
        </table>
        {objectives.isFetching ? (
          <Loading />
        ) : objectives.error ? (
          <GeneralError />
        ) : (
          <Accordion className="snake-ol" defaultActiveKey={objectiveId}>
            {objectives.data &&
            resourcesQuery.data &&
            translatedResourceQuery.data ? (
              objectives.data
                .map((objective) => (
                  <UserViewObjective
                    objective={objective}
                    resources={resourcesQuery.data}
                    translatedResources={translatedResourceQuery.data}
                  />
                ))
                .sort((a: any, b: any) => (a.order < b.order ? -1 : 1))
            ) : (
              <FormattedMessage
                id="objectives.stage.none"
                defaultMessage="No objectives available for this stage"
                description="No objectives available for this stage"
              />
            )}
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default UserViewObjectives;
