import { useQuery } from '@tanstack/react-query';
import { getObjectivesByFrameworkVersion } from '../services/api/course.service';
import objectivesKeys from '../query-keys/objectives-key-factory';

// hook for managing resources
const useUserObjectives = ({
  frameworkVersionId,
  stageId,
  userId,
  displayLocale
}: {
  frameworkVersionId: number | undefined;
  stageId: number | undefined;
  userId: number | undefined;
  displayLocale: string | undefined;
}) => {
  if (!frameworkVersionId || !userId) {
    throw Error('invalid id');
  }

  // all the objectives achieved by a user on a particular framework
  const userObjectivesQuery = useQuery({
    queryKey: objectivesKeys.list(
      frameworkVersionId,
      stageId,
      userId,
      displayLocale
    ),
    queryFn: () =>
      getObjectivesByFrameworkVersion(
        frameworkVersionId,
        stageId,
        userId,
        displayLocale
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!frameworkVersionId && !!stageId,
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'Objectives not Found') return false;
      if (failureCount >= 3) return false;
      return false;
    }
  });

  return {
    userObjectivesQuery
  };
};

export default useUserObjectives;
