/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  OnChangeFn,
  ColumnFiltersState
} from '@tanstack/react-table';
import { components } from '../../types/openapi/ProviderService';
import ProviderAddress from './ProviderAddress';
import PaginatedTable from '../tables/PaginatedTable';
import useTableColumns from '../../hooks/table/useTableColumns';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';

function ProvidersTable({
  data,
  error,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation,
  renderProvidersLink
}: {
  data: components['schemas']['PagedProviderDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: OnChangeFn<ColumnFiltersState> | undefined;
  queryParamsLocation: string;
  renderProvidersLink?: ({
    provider
  }: {
    provider: components['schemas']['ProviderDto'];
  }) => JSX.Element | ReactNode;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    if (setFiltering) {
      setFiltering([]);
    }
  };

  const { postcodeColumn } = useTableColumns({});

  const columns = [
    {
      id: 'providerId',
      header: intl.formatMessage({
        id: 'id',
        defaultMessage: 'Id',
        description: 'Id'
      }),
      cell: ({ row }: { row: any }) => {
        const provider = row.original;
        return `${provider?.providerId}`;
      },
      accessorKey: 'providerId',
      filterType: 'basicInput'
    },
    {
      id: 'providerName',
      header: intl.formatMessage({
        id: 'name',
        defaultMessage: 'Name',
        description: 'Name'
      }),
      cell: ({ row }: { row: any }) => {
        const provider = row.original;
        return `${provider?.name}`;
      },
      accessorKey: 'providerName',
      filterType: 'basicInput'
    },
    {
      id: 'address',
      header: intl.formatMessage({
        id: 'address',
        defaultMessage: 'Address',
        description: 'Address'
      }),
      cell: ({ row }: { row: any }) => {
        const provider = row.original;
        if (provider) {
          return <ProviderAddress address={provider.address} />;
        }

        return intl.formatMessage({
          id: 'address.none',
          defaultMessage: 'No Address provided',
          description: 'No Address provided'
        });
      },
      filterType: undefined
    },
    postcodeColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const providerLink =
          renderProvidersLink &&
          renderProvidersLink({
            provider: row.original
          });
        return <div>{providerLink}</div>;
      },
      filterType: undefined
    }
  ];

  const table = useReactTable({
    data: data?.providers ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfProviders
        ? Math.ceil(data.numberOfProviders / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    initialState: {
      columnVisibility: {
        providerId: profile?.userTypeId === UserType.SUPER_ADMIN
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfProviders}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="providerId"
      noneFoundMessage={
        <FormattedMessage
          id="providers.error.none"
          defaultMessage="No providers found"
          description="No providers found"
        />
      }
      showFooter={(data?.numberOfProviders ?? 0) > 10}
    />
  );
}

export default ProvidersTable;

ProvidersTable.defaultProps = {
  renderProvidersLink: () => {}
};
