/* eslint-disable no-nested-ternary */
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';

import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import TimetableFilters from './TimetableFilters';
import { components } from '../../types/openapi/CourseService';
import SportPassportCalendar from './SportPassportCalendar';
import CalendarToolbar from './CalendarToolbar';
import { SPCalendarEvent } from './SPCalendarEvent.type';
import { EventFilters } from './helpers/EventFilters.type';
import {
  filterEvents,
  mapSessionToEvent,
  uniqueCentres,
  uniqueFrameworks
} from './helpers';

import { OfflineContext } from '../../modules/offline/OfflineProvider';
import AppMode from '../../modules/offline/app-mode.enum';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';

function TimetableWrapper({
  setStartDate,
  setEndDate,
  sessions,
  isLoading,
  error,
  selectedCentreId
}: {
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  sessions: components['schemas']['CourseSessionDto'][] | undefined;
  isLoading: boolean;
  error: any;
  selectedCentreId?: number | undefined;
}) {
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const [searchParams, setSearchParams] = useSearchParams();

  const [showFilter, setShowFilter] = useState(false);

  const handleCloseFilters = () => setShowFilter(false);
  const handleShowFilters = () => setShowFilter(true);

  const [allEvents, setAllEvents] = useState<SPCalendarEvent[] | undefined>();
  // events is the filtered subset of allEvents
  const [events, setEvents] = useState<SPCalendarEvent[] | undefined>();

  const allUniqueCentresForSessions = allEvents?.length
    ? uniqueCentres(allEvents)
    : [];

  const allUniqueFrameworksForSessions = allEvents?.length
    ? uniqueFrameworks(allEvents)
    : [];

  const filterSessions = () => {
    const name = searchParams.get('name');
    const centreId = searchParams.get('centreId');
    const frameworkId = searchParams.get('frameworkId');
    if (allEvents) {
      const filterParams: EventFilters = {
        bookable: false,
        name: name || '',
        centreId: Number(centreId),
        frameworkId: Number(frameworkId)
      };
      const filteredEvents = filterEvents(allEvents, filterParams);
      setEvents(filteredEvents);
    }
  };

  useEffect(() => {
    setAllEvents([]);
    setEvents([]);
    setSearchParams((prev: URLSearchParams) => {
      prev.set('name', '');
      prev.set('centreId', '');
      prev.set('frameworkId', '');
      const originalParams = Object.fromEntries(prev);

      return new URLSearchParams(originalParams);
    });
    filterSessions();
  }, [selectedCentreId]);

  useEffect(() => {
    if (sessions) {
      setAllEvents([]);
      const newEvents = sessions?.map((session) => {
        return mapSessionToEvent(session, {
          learnerBookedOnSession: false,
          bookable: false,
          fullyBooked: false,
          userIsInstructor: true
        });
      });
      setAllEvents(newEvents);
    }
  }, [sessions]);

  // apply filters when params change
  useEffect(() => {
    if (searchParams) {
      filterSessions();
    }
  }, [searchParams]);

  useEffect(() => {
    if (allEvents) {
      if (searchParams) {
        filterSessions();
      } else {
        setEvents(allEvents);
      }
    }
  }, [allEvents]);

  return (
    <div>
      <div className="row">
        {profile?.userTypeId === UserType.INSTRUCTOR &&
          appMode === AppMode.OFFLINE && (
            <span className="mb-2">
              <FormattedMessage
                id="offline.data_unavailable"
                defaultMessage="You are currently in offline mode. Only data for today is available."
                description="You are currently in offline mode. Only data for today is available."
              />
            </span>
          )}

        <div className="col-12 pb-3">
          <CalendarToolbar onClickFilters={handleShowFilters} />
        </div>
        <TimetableFilters
          centres={allUniqueCentresForSessions}
          frameworks={allUniqueFrameworksForSessions}
          show={showFilter}
          isAdmin={profile?.userTypeId === UserType.ADMIN}
          onSubmit={filterSessions}
          onResetFilter={filterSessions}
          handleCloseFilter={handleCloseFilters}
        />
        <SportPassportCalendar
          events={events}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </div>
  );
}

export default TimetableWrapper;

TimetableWrapper.defaultProps = {
  selectedCentreId: undefined
};
