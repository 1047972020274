/* eslint-disable no-nested-ternary */
import React, { ReactNode } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

function CalendarToolbar({
  renderShowBookableSessions,
  onClickFilters
}: {
  renderShowBookableSessions?: () => JSX.Element | ReactNode;
  onClickFilters: () => void;
}) {
  const [searchParams] = useSearchParams();

  return (
    <>
      <div className="btn-group gap-2">
        {renderShowBookableSessions && renderShowBookableSessions()}
        <Button variant="outline-secondary" onClick={onClickFilters}>
          <FormattedMessage
            id="filter"
            defaultMessage="Filter"
            description="Filter"
          />
          <FontAwesomeIcon
            className="pe-1"
            icon={icon({ name: 'filter', style: 'solid' })}
          />
        </Button>
      </div>
      {(searchParams.get('name') ||
        searchParams.get('centreId') ||
        searchParams.get('frameworkId')) && (
        <span className="badge bg-primary ms-1 ">
          <FormattedMessage
            id="filter.applied"
            defaultMessage="Filter Applied"
            description="Filter Applied"
          />
        </span>
      )}
    </>
  );
}

export default CalendarToolbar;

CalendarToolbar.defaultProps = {
  renderShowBookableSessions: () => {}
};
