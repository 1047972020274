/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient, Mutation } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalContext } from '../../modules/modal/ModalProvider';

function SyncLog() {
  const queryClient = useQueryClient();
  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;

  const intl = useIntl();
  const mutationCache = queryClient.getMutationCache();
  const allMutations = mutationCache.getAll();

  const [mutations, setMutations] =
    useState<Mutation<unknown, unknown, void, unknown>[]>(allMutations);

  // subscribe to mutation updates
  useEffect(() => {
    const callback = (event: any) => {
      if (event.type === 'updated') {
        const { mutationId } = event.mutation;

        const newMutations = mutations.map((mut) => {
          if (mutationId === mut.mutationId) {
            return event.mutation;
          }
          return mut;
        });

        setMutations([...newMutations]);
      }
    };

    const unsubscribe = mutationCache.subscribe(callback);

    return () => unsubscribe();
  }, []);

  const openModal = (mutation: Mutation<unknown, unknown, void, unknown>) => {
    const header = intl.formatMessage({
      id: 'error',
      defaultMessage: 'Error',
      description: 'Error'
    });
    const modalContent = (
      <p className="text-break">{JSON.stringify(mutation.state.error)}</p>
    );

    modal(modalContent, {
      header,
      hideFooter: true,
      dialogClassName: 'modal-lg'
    });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="sync.log"
            defaultMessage="Sync Log"
            description="Sync Log"
          />
        </h2>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="id"
                    defaultMessage="ID"
                    description="ID"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="status.table.header"
                    defaultMessage="Status"
                    description="Status"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="paused.table.header"
                    defaultMessage="Paused"
                    description="Paused"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="error.table.header"
                    defaultMessage="Error"
                    description="Error"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="course.ID"
                    defaultMessage="Course ID"
                    description="Course ID"
                  />
                </th>
                <th>
                  {' '}
                  <FormattedMessage
                    id="session.ID"
                    defaultMessage="Session ID"
                    description="Session ID"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="data"
                    defaultMessage="data"
                    description="data"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {mutations?.map((mutation) => {
                console.log(mutation.state.variables);
                return (
                  <tr key={mutation.mutationId}>
                    <td>{mutation.mutationId}</td>
                    <td>
                      {mutation.state.status &&
                      mutation.state.status === 'success' ? (
                        <span className="badge bg-success">
                          {mutation.state.status}
                        </span>
                      ) : mutation.state.status === 'error' ? (
                        <span className="badge bg-danger">
                          {mutation.state.status}
                        </span>
                      ) : (
                        <span className="badge bg-secondary">
                          {mutation.state.status}
                        </span>
                      )}
                    </td>
                    <td>{mutation.state.isPaused ? 'true' : 'false'}</td>
                    <td>
                      {!!mutation.state.error && (
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={() => openModal(mutation)}
                        >
                          {' '}
                          <FormattedMessage
                            id="error.open.popover"
                            defaultMessage="See full error"
                            description="See full error"
                          />
                        </button>
                      )}
                    </td>
                    <td>{(mutation.state.variables as any)?.courseId}</td>
                    <td>{(mutation.state.variables as any)?.sessionId}</td>
                    <td>
                      {/* attendance */}
                      {(mutation.state.variables as any)?.attendanceMap &&
                        JSON.stringify(
                          Array.from(
                            (mutation.state.variables as any)
                              ?.attendanceMap as Map<number, boolean>
                          ).map(([k, v]) => {
                            return { [k]: v };
                          })
                        )}
                      {/* stars */}
                      {(mutation.state.variables as any)?.objectiveId && (
                        <>
                          Objective{' '}
                          {(mutation.state.variables as any)?.objectiveId}
                          /learner {(mutation.state.variables as any)?.userId}/
                          {(mutation.state.variables as any)?.numberOfStars}{' '}
                          stars
                        </>
                      )}
                      {/* progress */}
                      {(mutation.state.variables as any)?.currentStageId && (
                        <>
                          courseId {(mutation.state.variables as any)?.courseId}
                          /learnerId{' '}
                          {
                            (mutation.state.variables as any)?.learnerId
                          }/StageId{' '}
                          {(mutation.state.variables as any)?.currentStageId}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SyncLog;
