/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { AppLanguage } from '../const';
import progressKeys from '../query-keys/progress-key-factory';
import { getStageForFrameworkForLearner } from '../services/api/course.service';
import { components } from '../types/openapi/CourseService';
import stageKeys from '../query-keys/stage-key-factory';
import userObjectivesKeys from '../query-keys/user-objectives-key-factory';
import sessionKeys from '../query-keys/session-key-factory';

export type ProgressAction = { type: 'incremented_stage'; userId: number };

const useFrameworkProgress = ({
  frameworkVersionId,
  learnerId,
  displayLocale = AppLanguage.English
}: {
  frameworkVersionId: number | undefined;
  learnerId: number | undefined;
  displayLocale: string | undefined;
}) => {
  const queryClient = useQueryClient();

  const { sessionId } = useParams();

  const currentFrameworkStageQuery = useQuery({
    queryKey: progressKeys.currentFrameworkStage(
      frameworkVersionId,
      learnerId,
      displayLocale
    ),
    queryFn: () =>
      getStageForFrameworkForLearner(
        frameworkVersionId,
        learnerId,
        displayLocale
      ),
    refetchOnMount: true,
    retry: false
  });

  const currentStageId = currentFrameworkStageQuery?.data?.stageId || null;

  const [stageOrder, setStageOrder] = useState<number | undefined>();

  // this completes a stage on a framework
  const updateStageOrder = useMutation({
    mutationKey: progressKeys.progressFrameworkMutation(
      frameworkVersionId,
      learnerId
    ),
    retry: 0,
    // these arguments need to match the default mutation in app.tsx
    onMutate: async ({
      frameworkVersionId,
      frameworkId,
      learnerId,
      // eslint-disable-next-line
      stageId,
      // eslint-disable-next-line
      isProgress
    }: {
      frameworkVersionId: number;
      frameworkId: number;
      learnerId: number;
      stageId: number | undefined | null;
      isProgress: boolean;
    }) => {
      const previousOnlineData = queryClient.getQueryData<
        components['schemas']['StageDto']
      >(
        progressKeys.currentFrameworkStage(
          frameworkVersionId,
          learnerId,
          displayLocale
        )
      );

      const previousOfflineData = queryClient.getQueryData<
        components['schemas']['OfflineDownloadDto']
      >(sessionKeys.offlineData(Number(sessionId), displayLocale));

      const stagesData =
        queryClient.getQueryData<components['schemas']['StageDto'][]>(
          stageKeys.list(frameworkId, frameworkVersionId, displayLocale)
        ) ||
        (queryClient.getQueryData<components['schemas']['OfflineDownloadDto']>(
          sessionKeys.offlineData(Number(sessionId), displayLocale)
        )?.frameworkStages as unknown as components['schemas']['StageSlimDto']);

      let newStageData;

      let copiedStagesData;
      // deep copy previous data
      if (stagesData) {
        copiedStagesData = JSON.parse(
          JSON.stringify(stagesData)
        ) as components['schemas']['StageDto'][];
      }
      // remove local state so that server state is taken instead
      setStageOrder(undefined);

      if (stageOrder) {
        const newStage = copiedStagesData?.find(
          (courseStage: components['schemas']['StageDto']) =>
            courseStage.stageOrder === stageOrder
        );

        if (newStage) {
          newStageData = { ...newStage };
        }
      }
      // update the cached query for current stage
      queryClient.setQueryData(
        progressKeys.currentFrameworkStage(
          frameworkVersionId,
          learnerId,
          displayLocale
        ),
        { ...newStageData }
      );

      const newStageId = newStageData?.stageId;

      const newOfflineData = previousOfflineData;

      if (
        newOfflineData &&
        newOfflineData.learnerDetails &&
        newOfflineData.learnerDetails.find(
          (learner) => learner.userId === learnerId
        )
      ) {
        newOfflineData.learnerDetails.find(
          (learner) => learner.userId === learnerId
        )!.currentStageIdOnFramework = newStageId;
      }

      queryClient.setQueryData(
        sessionKeys.offlineData(Number(sessionId), displayLocale),
        newOfflineData
      );

      return { previousOnlineData, previousOfflineData };
    },
    onError: (_, __, context) => {
      // if something goes wrong then reset to the previous data
      if (context) {
        queryClient.setQueryData(
          progressKeys.currentFrameworkStage(
            frameworkVersionId,
            learnerId,
            displayLocale
          ),
          context.previousOnlineData
        );
        queryClient.setQueryData(
          sessionKeys.offlineData(Number(sessionId), displayLocale),
          context.previousOfflineData
        );
      }
    },
    onSettled: () => {
      // eslint-disable-next-line
      console.log('settled');
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        progressKeys.currentFrameworkStage(
          frameworkVersionId,
          learnerId,
          displayLocale
        ),
        data
      );

      queryClient.invalidateQueries({
        queryKey: userObjectivesKeys.starsForFramework(
          frameworkVersionId,
          learnerId
        )
      });
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'user-objectives' &&
          query.queryKey[5] === learnerId
      });
    }
  });

  return {
    currentFrameworkStageQuery,
    currentStageId,
    updateStageOrder,
    setStageOrder,
    stageOrder
  };
};

export default useFrameworkProgress;
