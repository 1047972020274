import React from 'react';
import { FormattedMessage } from 'react-intl';
import UserSystemDetailsTable from '../users/UserSystemDetailsTable';
import { components } from '../../types/openapi/UserService';

function SystemDetailsRow({
  user,
  showMedicalConditions
}: {
  user: components['schemas']['UserDto'];
  showMedicalConditions: boolean;
}) {
  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <div className="card mb-3 mb-lg-0">
          <div className="card-header">
            <h3>
              <FormattedMessage
                id="system_details"
                defaultMessage="System Details"
                description="System Details"
              />
            </h3>
          </div>
          <div className="card-body">
            <UserSystemDetailsTable
              user={user}
              showMedicalConditions={showMedicalConditions}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemDetailsRow;
