import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { UserType } from '../../../const/user-type';
import { FilteringQueryParams } from '../../../const/filtering-query-params';
import EditUserCentres from '../../centres/EditUserCentres';
import useStoreTableFilters from '../../../hooks/state-management/useStoreTableFilters';
import useProviderCentresForUser from '../../../hooks/centres/useProviderCentresForUser';

function EditLearnerCentres() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const { userId, learnerId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    filtering,
    setFiltering
  } = useStoreTableFilters({
    queryParamsLocation: FilteringQueryParams.LearnerCentres
  });

  const { centreIds } = useProviderCentresForUser({
    providerId: profile?.providerId,
    userId: Number(userId || learnerId),
    userTypeId: UserType.LEARNER,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <EditUserCentres
      userId={Number(userId || learnerId)}
      providerId={profile?.providerId}
      userTypeId={UserType.LEARNER}
      queryParamsLocation={FilteringQueryParams.LearnerCentres}
      centreIds={centreIds}
      pagination={pagination}
      sorting={sorting}
      filtering={filtering}
      headingText={
        <FormattedMessage
          id="learner.edit_learner_centres"
          defaultMessage="Edit Learner Centres"
          description="Edit Learner Centres"
        />
      }
      setPagination={setPagination}
      setSorting={setSorting}
      setFiltering={setFiltering}
    />
  );
}

export default EditLearnerCentres;
