import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Dayjs } from 'dayjs';

import useProviderConfig from '../../../hooks/useProviderConfig';
import useStripeUrlForPayment from '../../../hooks/useStripeUrlForPayment';
import useUserSessionBooking from '../../../hooks/useUserSessionBooking';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { AppLanguage, AppRoute } from '../../../const';
import { appStrings } from '../../../modules/i18n';
import { GeneralError } from '../../common';
import Loading from '../../common/Loading';
import SessionPaymentLink from './SessionPaymentLink';

function SessionPayment({
  providerId,
  isFullyBooked,
  sessionStartTime
}: {
  providerId: number;
  isFullyBooked: boolean;
  sessionStartTime: Dayjs;
}) {
  if (isFullyBooked) {
    return (
      <FormattedMessage
        id="session.fully_booked"
        defaultMessage="Sorry, this session is now fully booked"
        description="Sorry, this session is now fully booked"
      />
    );
  }
  const { courseId, sessionId } = useParams();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const navigate = useNavigate();

  const redirect = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal as AppLanguage][AppRoute.Courses]
  }/${courseId}/${appStrings[langVal][AppRoute.ViewSession]}/${sessionId}`;

  const { providerPaymentEnabled } = useProviderConfig({
    providerId
  });

  if (!providerPaymentEnabled) {
    return (
      <FormattedMessage
        id="session.payment_not_enable"
        defaultMessage="Please contact the provider directly for bookings and payment"
        description="Please contact the provider directly for bookings and payment"
      />
    );
  }

  const { courseSessionBookingForUserQuery } = useUserSessionBooking({
    courseId: Number(courseId),
    courseSessionId: Number(sessionId),
    userId: profile?.userId!,
    displayLocale
  });

  const hasPaid = courseSessionBookingForUserQuery.data?.hasPaid;

  if (hasPaid) {
    navigate(redirect);
  }

  const { stripePaymentUrlQuery } = useStripeUrlForPayment({
    courseId: Number(courseId),
    courseSessionId: Number(sessionId),
    userId: profile?.userId!,
    providerId,
    startDate: sessionStartTime?.format('DD-MM-YYYY'),
    displayLocale
  });

  if (stripePaymentUrlQuery.isLoading) {
    return <Loading />;
  }

  if (stripePaymentUrlQuery.error) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="payment.link_not_available"
            defaultMessage="Payment link not available"
            description="Payment link not available"
          />
        }
      />
    );
  }
  return (
    <SessionPaymentLink
      providerId={providerId}
      sessionStartTime={sessionStartTime}
    />
  );
}
export default SessionPayment;
