import { get, set, del } from 'idb-keyval';
import {
  PersistedClient,
  Persister
} from '@tanstack/react-query-persist-client';
import { asyncThrottle } from './asyncThrottle';

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
function createIDBPersister({
  idbValidKey = 'reactQuery',
  throttleTime = 1000
}) {
  return {
    persistClient: asyncThrottle(
      async (client: PersistedClient) => {
        set(idbValidKey, client);
      },
      { interval: throttleTime }
    ),
    restoreClient: async () => {
      try {
        return await get<PersistedClient>(idbValidKey);
      } catch (e) {
        return Promise.resolve('catch');
      }
    },
    removeClient: async () => {
      await del(idbValidKey);
    }
  } as Persister;
}

export default createIDBPersister;
