/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { components } from '../../../types/openapi/CourseService';
import useUserStarsForFramework from '../../../hooks/useUserStarsForFramework';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

import ReadOnlyStar from '../../progress/ReadOnlyStar';
import useResources from '../../../hooks/useResources';
import { ResourceType } from '../../../const/resource-type';
import { ObjectiveWithStars } from '../../../types/ObjectiveWithStars.type';
import TrophySnakeObjectives from './TrophySnakeObjectives';
import Loading from '../../common/Loading';

function TrophySnake({
  frameworkVersionId,
  userId,
  objectives
}: {
  frameworkVersionId: number;
  userId: number;
  objectives: components['schemas']['ObjectiveDto'][] | undefined;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [objectiveWithStars, setObjectiveWithStars] =
    useState<ObjectiveWithStars>();

  const [previousObjectiveImgSrc, setPreviousObjectiveImgSrc] =
    useState<string>();

  const [isFinalObjectiveOfStage, setIsFinalObjectiveOfStage] =
    useState<boolean>(false);

  const { userFrameworkStarsQuery } = useUserStarsForFramework({
    frameworkVersionId,
    userId,
    displayLocale
  });

  const { resourcesQuery } = useResources({
    frameworkVersionId,
    displayLocale
  });

  useEffect(() => {
    if (resourcesQuery.data && userFrameworkStarsQuery.data) {
      const threeStarsArr = userFrameworkStarsQuery.data
        .map((objectiveStar) => {
          return objectiveStar.objectiveStars.map((star) => star);
        })
        .flat()
        // get any completed objectives
        .filter((objectiveUserStars) => objectiveUserStars.numberOfStars === 3);

      if (threeStarsArr.length > 0 && objectives) {
        const sortedObjectives = objectives.sort((a, b) =>
          a.order < b.order ? -1 : 1
        );
        // last objective with stars for current stage
        const latestObjectiveWithStars =
          threeStarsArr[threeStarsArr.length - 1];
        const latestObjective = objectives.find(
          (obj) => obj.objectiveId === latestObjectiveWithStars.objectiveId
        );

        const trophies = resourcesQuery.data.filter(
          (resource) => resource.resourceTypeId === ResourceType.TROPHY
        );
        // if no latest trophy for the stage then the user will just see their progress
        if (latestObjective) {
          const img = trophies.find(
            (trophy: any) => trophy.objectiveId === latestObjective.objectiveId
          );

          const imgSrc = img
            ? `${process.env.REACT_APP_BLOB_BASE_URL}/${img.resourceString}?${img.sasToken}`
            : '';

          const newObjectiveWithStars = {
            ...latestObjective,
            stars: latestObjectiveWithStars.numberOfStars,
            frameworkVersionId,
            userId,
            imgSrc
          };
          if (
            newObjectiveWithStars.objectiveId ===
            sortedObjectives[sortedObjectives.length - 1].objectiveId
          ) {
            setIsFinalObjectiveOfStage(true);
          }
          setObjectiveWithStars(newObjectiveWithStars);

          // get trophy for previous completed objective

          const indexLatestObjective = sortedObjectives.findIndex(
            (objective) => objective.objectiveId === latestObjective.objectiveId
          );

          const previousObjective = sortedObjectives[indexLatestObjective - 1];

          if (previousObjective) {
            const previousObjectiveImg = trophies.find(
              (trophy: any) =>
                trophy.objectiveId === previousObjective.objectiveId
            );

            const previousObjImgSrc = previousObjectiveImg
              ? `${process.env.REACT_APP_BLOB_BASE_URL}/${previousObjectiveImg.resourceString}?${previousObjectiveImg.sasToken}`
              : '';

            setPreviousObjectiveImgSrc(previousObjImgSrc);
          }
        }
      }
      // if learner hasn't completed any objectives, set latest objective as first one of stage
      if (threeStarsArr.length === 0 && objectives) {
        const sortedObjectives = objectives.sort((a, b) =>
          a.objectiveId > b.objectiveId ? 1 : -1
        );

        const firstObjectiveOfStage = sortedObjectives[0];
        const newObjectiveWithStars = {
          ...firstObjectiveOfStage,
          stars: 0,
          frameworkVersionId,
          userId,
          imgSrc: ''
        };
        if (
          newObjectiveWithStars.objectiveId ===
          sortedObjectives[sortedObjectives.length - 1].objectiveId
        ) {
          setIsFinalObjectiveOfStage(true);
        }
        setObjectiveWithStars(newObjectiveWithStars);
      }
    }
  }, [userFrameworkStarsQuery.data, resourcesQuery.data]);

  return (
    <>
      {userFrameworkStarsQuery.isLoading || resourcesQuery.isLoading ? (
        <Loading />
      ) : objectiveWithStars ? (
        <TrophySnakeObjectives
          previousObjectiveImgSrc={previousObjectiveImgSrc}
          latestObjective={objectiveWithStars}
          isLatestObjectiveFinal={isFinalObjectiveOfStage}
          renderStarElement={({ userStarValue, inputStarValue }) => {
            if (objectiveWithStars.stars > 0)
              return (
                <ReadOnlyStar
                  usersStar={userStarValue}
                  starValue={inputStarValue}
                />
              );
            return <></>;
          }}
        />
      ) : (
        // Should only reach here if learner has been progressed without completing all objectives for stage
        <FormattedMessage
          id="progress.info.none"
          defaultMessage="No progress information is available"
          description="No progress information is available"
        />
      )}
    </>
  );
}

export default TrophySnake;
