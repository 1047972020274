import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { createProviderCentreFacility } from '../../../services/api/provider.service';
import { components } from '../../../types/openapi/ProviderService';
import FacilityForm from './FacilitiesForm';
import { AppRoute } from '../../../const';
import localiseRoutePath from '../../../utils/localiseRoutePath';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';

function CreateFacility() {
  const intl = useIntl();
  const navigate = useNavigate();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const userProviderId = profile?.providerId;

  const params = useParams();
  const { centreId } = params;

  const navLinkCentre = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Centres
  )}/${centreId}`;

  const { mutate: createNewFacility, isLoading } = useMutation(
    (facility: Partial<components['schemas']['FacilityDto']>) => {
      if (userProviderId) {
        return createProviderCentreFacility(
          userProviderId,
          Number(centreId),
          facility,
          displayLocale
        );
      }
      throw Error('no provider id');
    },
    {
      onSuccess: (data: components['schemas']['FacilityDto']) => {
        const { name } = data;
        toast.success(
          intl.formatMessage(
            {
              id: 'facility.create.success',
              defaultMessage: 'A new facility {name} has been created'
            },
            { name }
          ),
          { delay: 200 }
        );
        navigate(navLinkCentre);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'facility.create.error',
            defaultMessage:
              'There was an error creating a facility with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (
    facility: Pick<components['schemas']['FacilityDto'], 'name' | 'description'>
  ) => {
    const { name, description } = facility;
    if (facility.name && userProviderId) {
      const newFacility: Partial<components['schemas']['FacilityDto']> = {
        name,
        description
      };
      createNewFacility(newFacility);
    }
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.facility.create_new',
            defaultMessage: 'Create new facility'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="facility.header.create_new"
            defaultMessage="Create New Facility"
            description="Create New Facility"
          />
        </h1>
      </div>
      <div className="card-body">
        <FacilityForm
          onSubmit={onSubmit}
          submitButtonMessage={
            <FormattedMessage
              id="facility.button.create_new"
              defaultMessage="Create new facility"
              description="Create new facility"
            />
          }
          formDisabled={isLoading}
        />
      </div>
    </div>
  );
}

export default CreateFacility;
