/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import { components } from '../../types/openapi/UserService';
import { components as courseComponents } from '../../types/openapi/CourseService';
import { ModalContext } from '../../modules/modal/ModalProvider';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useFrameworkProgress from '../../hooks/useFrameworkProgress';
import { GeneralError } from '../common';

function StageCompletionBtn({
  learner,
  frameworkId,
  frameworkVersionId,
  stages,
  currentStage,
  incompleteCount,
  isFinished,
  queriesLoading
}: {
  learner:
    | components['schemas']['UserDto']
    | courseComponents['schemas']['LearnerDto'];
  frameworkId: number;
  frameworkVersionId: number;
  stages: components['schemas']['StageDto'][];
  // currentStage may be null if the user has has finished all stages of a framework
  currentStage: components['schemas']['StageDto'] | null;
  incompleteCount: number;
  isFinished: boolean;
  queriesLoading: boolean | undefined;
}) {
  const modalCtx = useContext(ModalContext);
  const { userId: learnerId } = learner;

  if (!learnerId) return <GeneralError />;

  const { modal } = modalCtx;
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  // all stages will have an ID unless the user has completed the course/framework
  const currentStageId = currentStage ? currentStage.stageId : null;

  const { updateStageOrder, setStageOrder } = useFrameworkProgress({
    frameworkVersionId,
    learnerId,
    displayLocale
  });

  const sendMutation = () => {
    if (!currentStageId) {
      console.warn('You have completed the framework');
      return;
    }
    // if there is not current stage, it is because the learner is on the first stage
    // or the last stage
    // if last, they cannot progress further
    // if first, then set the current stage to the second available stage
    const nextStageOrder = currentStage
      ? currentStage.stageOrder + 1
      : stages[1].stageOrder;

    setStageOrder(nextStageOrder);
    updateStageOrder.mutate(
      {
        frameworkVersionId,
        frameworkId,
        learnerId,
        stageId: currentStageId,
        isProgress: true
      },
      {
        onSuccess: () => {
          toast.success(
            intl.formatMessage({
              id: 'progress.success',
              defaultMessage: 'Learner has completed the stage'
            })
          );
        }
      }
    );
  };

  const openModal = () => {
    const header = intl.formatMessage(
      {
        id: 'learner.progress.confirm.header',
        defaultMessage: 'Complete Stage: {name}',
        description: 'Complete Stage Confirm Modal Header'
      },
      { name: learner.fullname || learner.username }
    );
    const modalContent = intl.formatMessage({
      id: 'learner.progress.confirm.body',
      defaultMessage: 'Are you sure you want to complete this stage?',
      description: 'Complete Stage Confirm Modal Body Message'
    });
    const confirmText = intl.formatMessage({
      id: 'learner.progress.confirm.button',
      defaultMessage: 'Complete Stage',
      description: 'Complete Stage Confirm Button'
    });

    modal(modalContent, {
      confirm: async () => {
        sendMutation();
      },
      header,
      confirmText
    });
  };

  return (
    <button
      className="btn btn-circle-primary"
      type="button"
      onClick={openModal}
      disabled={queriesLoading || incompleteCount > 0 || isFinished}
    >
      <FontAwesomeIcon icon={faCirclePlus} />
      <span className="visually-hidden">
        <FormattedMessage
          id="learner.progress.button"
          defaultMessage="Plus - Complete stage"
          description="Plus - Complete stage"
        />
      </span>
    </button>
  );
}

export default StageCompletionBtn;
