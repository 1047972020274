/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AppLanguage, AppRoute } from '../../../const';
import { UserType } from '../../../const/user-type';
import isAllowed from '../../../utils/permissions/isAllowed';
import { LangContext } from '../../i18n/components/IntlWrapper';
import { ProfileContext } from '../../profile/ProfileProvider';
import { AuthContext } from '../AuthProvider';
import { isAllowedProfile } from '../../../utils/permissions';
import { components } from '../../../types/openapi/UserService';
import { appStrings } from '../../i18n';

function ProtectedRoute({
  allowedRoles,
  allowedProfiles,
  children,
  customAllowed
}: {
  allowedRoles?: UserType[];
  allowedProfiles?: string[];
  children: any;
  // for any custom restrictions on a profile beyond simple role/profile checking
  customAllowed?: (
    profile: components['schemas']['UserProvidersDto']
  ) => boolean;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const authContext = useContext(AuthContext);
  const { decodedUserFromToken, token } = authContext;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const hasCustomRestriction =
    profile && customAllowed && customAllowed(profile);

  // if no token at all - get out!
  if (!token) {
    console.warn('PROTECTED ROUTE: no token set');
    return <Navigate to="/" replace />;
  }

  // if no user from token - get out!
  if (!decodedUserFromToken) {
    console.warn('PROTECTED ROUTE: no user set');

    return (
      <Navigate
        to={`/${displayLocale?.toLowerCase()}/${
          appStrings[displayLocale as AppLanguage][AppRoute.Login]
        }`}
      />
    );
  }

  // if token but no user set...eg refresh or another error
  if (token && !decodedUserFromToken) {
    console.warn('token BUT no user');
    return (
      <Navigate
        to={`/${displayLocale?.toLowerCase()}/${
          appStrings[displayLocale as AppLanguage][AppRoute.Login]
        }`}
      />
    );
  }

  // if you are logged in so have a token and user
  // but not the role for the page you are trying to view
  // stay logged in but unauthorised to view page
  if (
    token &&
    decodedUserFromToken &&
    !!allowedRoles?.length &&
    profile &&
    !isAllowed(allowedRoles, profile.userTypeId as UserType)
  ) {
    return (
      <Navigate
        to={`/${displayLocale?.toLowerCase()}/${
          appStrings[displayLocale as AppLanguage][AppRoute.Unauthorised]
        }`}
        replace
      />
    );
  }
  // if user role is allowed but there are restrictions on whether selected profile has permissions
  if (
    profile &&
    !isAllowedProfile(allowedProfiles, profile) &&
    hasCustomRestriction
  ) {
    return (
      <Navigate
        to={`/${displayLocale?.toLowerCase()}/${
          appStrings[displayLocale as AppLanguage][AppRoute.Unauthorised]
        }`}
        replace
      />
    );
  }

  return children || <Outlet />;
}

ProtectedRoute.defaultProps = {
  allowedRoles: [],
  allowedProfiles: [],
  customAllowed: () => false
};

export default ProtectedRoute;
