import dayjs from 'dayjs';
import { components } from '../types/openapi/CourseService';

// return session that has finished ie the session end time has passed
// we won't be showing attendance for sessions that are in progress (as it may be recorded offline and not synced yet)
const filterPastSessions = (
  session: components['schemas']['UserCourseSessionBookingDto']
) => {
  const now = dayjs();
  return (
    session.courseSession &&
    dayjs(session.courseSession.sessionEndTime).isBefore(now)
  );
};

export default filterPastSessions;
