/* eslint-disable @typescript-eslint/no-shadow */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { useReducer, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { AppLanguage } from '../const';
import sessionKeys from '../query-keys/session-key-factory';
import {
  getCourseSession,
  getMembersForCourseSession
} from '../services/api/course.service';
import { components } from '../types/openapi/CourseService';

export type AttendanceAction =
  | { type: 'set_attendance_true'; userId: number }
  | { type: 'set_attendance_false'; userId: number };

// hook for managing sessions
// if you are looking to manage a single user within a session - try userUseSession hook instead
const useSession = ({
  courseId,
  sessionId,
  displayLocale,
  // eslint-disable-next-line
  userId
}: {
  courseId: number;
  sessionId: number;
  displayLocale: string | AppLanguage | undefined;
  userId?: number;
}) => {
  const [sessionIsDirty, setSessionIsDirty] = useState<boolean>(false);
  const [sessionIsTouched, setSessionIsTouched] = useState<boolean>(false);

  const reducer = (state: Map<number, boolean>, action: AttendanceAction) => {
    switch (action.type) {
      case 'set_attendance_false': {
        const copy = new Map(state);
        copy.set(action.userId, false);
        return copy;
      }
      case 'set_attendance_true': {
        const copy = new Map(state);
        copy.set(action.userId, true);
        return copy;
      }
      default:
        return state;
    }
  };

  const queryClient = useQueryClient();

  const sessionQuery = useQuery({
    queryKey: sessionKeys.session(courseId, sessionId, displayLocale),
    queryFn: () => getCourseSession(courseId, sessionId, displayLocale),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    staleTime: 1000 * 60 * 60 * 24 * 7 // 1 week - stays the same until manually invalidated or refreshed
  });

  const sessionMembersQuery = useQuery({
    queryKey: sessionKeys.members(courseId, sessionId, displayLocale),
    queryFn: () =>
      getMembersForCourseSession(courseId, sessionId, displayLocale),
    // the query will refetched on mount if the data is stale
    // data will only be stale if manually invalidated (eg a successful mutation) while staleTime is Infinity
    refetchOnMount: true,
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'No Members Found Found') return false;
      if (failureCount >= 1) return false;
      return false;
    },
    // offlineFirst - get data from PWA cache /
    // always - In this mode, TanStack Query will always fetch and ignore the online / offline state.
    // This is likely the mode you want to choose if you use TanStack Query in an environment
    // where you don't need an active network connection for your Queries to work
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    staleTime: Infinity // cannot be made stale until manually invalidated or refreshed,
  });

  const sessionMembersQuerySorted = sessionMembersQuery.data?.sort((a, b) =>
    a.username!.localeCompare(b.username!, displayLocale, {
      ignorePunctuation: true,
      sensitivity: 'base'
    })
  );

  // use user sync api to update all attendances at same time
  const updateMembersSessionAttendances = useMutation({
    mutationKey: sessionKeys.attendance(sessionId, displayLocale),
    retry: 0,
    // these arguments need to match the default mutation in App.tsx
    onMutate: async ({
      courseId,
      sessionId,
      attendanceMap,
      // eslint-disable-next-line
      dateTime
    }: {
      courseId: number;
      sessionId: number;
      attendanceMap: Map<number | undefined, boolean | null | undefined>;
      dateTime: string; // ISO String
    }) => {
      setSessionIsDirty(false);

      const previousOnlineData = queryClient.getQueryData<
        components['schemas']['UserDto'][]
      >(sessionKeys.members(courseId, sessionId, displayLocale));

      const newOnlineData = previousOnlineData?.map((user) => {
        const userAttendance = attendanceMap.get(user.userId) || false;
        // modify
        const userToModify = { ...user };
        try {
          if (
            !!userToModify?.userCourseSessionBookings &&
            userToModify?.userCourseSessionBookings[0]
          ) {
            userToModify.userCourseSessionBookings[0].attended = userAttendance;
          }

          return userToModify;
        } catch (error) {
          // userCourseSessionBookings doesnt exist or has changed
          console.error(error);
        }

        // return unmodified
        return user;
      });

      // remove local state so that server state is taken instead
      // setAttendances(undefined);

      if (newOnlineData) {
        // update the cached query for members
        queryClient.setQueryData(
          sessionKeys.members(courseId, sessionId, displayLocale),
          [...newOnlineData]
        );
      }

      const previousOfflineData = queryClient.getQueryData<
        components['schemas']['OfflineDownloadDto']
      >(sessionKeys.offlineData(sessionId, displayLocale));

      const newOfflineLearnerData = previousOfflineData?.learnerDetails?.map(
        (user) => {
          const userAttendance = attendanceMap.get(user.userId) || false;
          // modify
          const userToModify = { ...user };

          userToModify.isAttended = userAttendance;

          return userToModify;
        }
      );

      const newOfflineData = previousOfflineData;

      if (newOfflineData) {
        newOfflineData.learnerDetails = newOfflineLearnerData;
      }

      // remove local state so that server state is taken instead
      // setAttendances(undefined);

      if (newOfflineData) {
        // update the cached query for members
        queryClient.setQueryData(
          sessionKeys.offlineData(sessionId, displayLocale),
          newOfflineData
        );
      }

      return { previousOnlineData, previousOfflineData };
    },
    onError: (_, __, context) => {
      // if something goes wrong then reset to the previous data
      console.error(context?.previousOnlineData);
      if (context) {
        queryClient.setQueryData(
          sessionKeys.members(courseId, sessionId, displayLocale),
          context.previousOnlineData
        );
        queryClient.setQueryData(
          sessionKeys.offlineData(sessionId, displayLocale),
          context.previousOfflineData
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: sessionKeys.members(courseId, sessionId, displayLocale)
      });
    }
  });

  const getAttendances = () => {
    const membersData = queryClient.getQueryData<
      components['schemas']['UserDto'][]
    >(sessionKeys.members(courseId, sessionId, displayLocale));
    if (membersData) {
      const queryDataAttendanceMap = new Map(
        membersData.map((i) => [
          i.userId!,
          i.userCourseSessionBookings![0].attended || false
        ])
      );
      return queryDataAttendanceMap;
    }
    console.warn('no learners so no attendences');
    return new Map();
  };

  const [state, dispatch] = useReducer(reducer, new Map(), getAttendances);

  const availableSpaces =
    sessionQuery &&
    sessionQuery.data &&
    sessionQuery.data.course &&
    sessionQuery.data.course.totalNumberOfPeople
      ? sessionQuery.data.course.totalNumberOfPeople -
        (sessionMembersQuery?.data?.length || 0)
      : undefined;

  const sessionCapacity =
    sessionQuery && sessionQuery.data && sessionQuery.data.course
      ? sessionQuery.data.course.totalNumberOfPeople || 0
      : 0;

  return {
    sessionQuery,
    sessionMembersQuery,
    sessionMembersQuerySorted,
    updateMembersSessionAttendances,
    attendances: state !== undefined ? state : getAttendances(),
    sessionCapacity,
    availableSpaces,
    dispatch,
    sessionIsDirty,
    setSessionIsDirty,
    sessionIsTouched,
    setSessionIsTouched
  };
};

export default useSession;
