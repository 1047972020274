import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { AppLanguage, AppRoute } from '../../const';
import { UserType } from '../../const/user-type';
import useUserPermissions from '../../hooks/permissions/useUserPermissions';

import useProviderConfig from '../../hooks/useProviderConfig';
import useUser from '../../hooks/useUser';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import UserTypeTranslationIds from '../../modules/i18n/user-types/user-type-translation-ids';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import ViewAdminDashboard from '../centres/admins/ViewAdminDashboard';
import { GeneralError } from '../common';
import ViewInstructorDashboard from '../instructors-admin/view-instructor/ViewInstructorDashboard';
import ViewLearnerDashboard from '../learners-admin/view-learner/ViewLearnerDashboard';
import ViewUserDashboard from './ViewUserDashboard';

function ViewUser() {
  const { userId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const providerId = profile?.providerId;
  const intl = useIntl();
  const { userQuery } = useUser({ userId: Number(userId), displayLocale });

  if (!providerId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'error.no_provider_id',
          defaultMessage: 'No valid provider id provided'
        })}
      />
    );
  }

  const { filteredRoles } = useUserPermissions({
    userId: Number(userId),
    displayLocale
  });

  const [selectedRole, setSelectedRole] = useState<number | undefined>();

  useEffect(() => {
    if (filteredRoles && filteredRoles.length && !selectedRole) {
      setSelectedRole(filteredRoles[0].userType);
    }
  }, [filteredRoles]);

  const onChange = (value: number | undefined) => {
    setSelectedRole(value);
  };

  const { profileEditingEnabled } = useProviderConfig({
    providerId
  });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.user.name',
              defaultMessage: 'User: {name}'
            },
            { name: userQuery.data?.username || '' }
          )}
        </title>
      </Helmet>
      <div className="container-fluid g-0">
        <div className="card">
          <div className="card-header d-flex flex-wrap flex-md-nowrap justify-content-between">
            <h1 data-gdpr="true">
              <FormattedMessage
                id="user.name"
                defaultMessage="User: {name}"
                description="User {name}"
                values={{ name: userQuery.data?.username || '' }}
              />
            </h1>
            {filteredRoles && filteredRoles.length > 1 && (
              <Select
                inputId="userTypeId"
                className="w-100 w-md-25"
                onChange={(event) => onChange(event?.value)}
                defaultValue={{
                  label: `${intl.formatMessage({
                    id: UserTypeTranslationIds[
                      filteredRoles[0].userType as UserType
                    ]
                  })}`,
                  value: filteredRoles[0].userType
                }}
                options={filteredRoles?.map((role) => {
                  return {
                    label: `${intl.formatMessage({
                      id: UserTypeTranslationIds[role.userType as UserType]
                    })}`,
                    value: role.userType
                  };
                })}
              />
            )}
          </div>
        </div>
        {selectedRole === UserType.LEARNER ? (
          <ViewLearnerDashboard
            learnerId={Number(userId)}
            providerId={providerId}
            isLoading={userQuery.isLoading}
            error={userQuery.error}
            data={userQuery.data}
            profileEditingEnabled={profileEditingEnabled}
            editCentresLink={`${
              appStrings[displayLocale as AppLanguage][
                AppRoute.EditLearnerCentres
              ]
            }`}
          />
        ) : selectedRole === UserType.INSTRUCTOR ? (
          <ViewInstructorDashboard
            instructorId={Number(userId)}
            providerId={providerId}
            isLoading={userQuery.isLoading}
            error={userQuery.error}
            data={userQuery.data}
            profileEditingEnabled={profileEditingEnabled}
            editCentresLink={`${
              appStrings[displayLocale as AppLanguage][
                AppRoute.EditInstructorCentres
              ]
            }`}
          />
        ) : selectedRole === UserType.ADMIN ? (
          <ViewAdminDashboard
            adminId={Number(userId)}
            providerId={providerId}
            isLoading={userQuery.isLoading}
            error={userQuery.error}
            data={userQuery.data}
          />
        ) : (
          <ViewUserDashboard
            isLoading={userQuery.isLoading}
            error={userQuery.error}
            data={userQuery.data}
          />
        )}
      </div>
    </>
  );
}
export default ViewUser;
