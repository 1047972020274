import { flexRender, HeaderGroup, Table } from '@tanstack/react-table';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ResourceType } from '../../../../../../const/resource-type';
import useOfflineDataDownload from '../../../../../../hooks/offline/useOfflineDataDownload';
import useResources from '../../../../../../hooks/useResources';
import useStageObjectives from '../../../../../../hooks/useStageObjectives';
import { LangContext } from '../../../../../../modules/i18n/components/IntlWrapper';
import { ModalContext } from '../../../../../../modules/modal/ModalProvider';
import { components } from '../../../../../../types/openapi/FrameworkService';
import { Loading } from '../../../../../common';
import SortingButton from '../../../../../tables/SortingButton';
import ObjectiveSnakeImage from '../../../objectives/ObjectiveSnakeImage';

function ObjectivesOverviewTableHead({
  frameworkId,
  frameworkVersionId,
  stageId,
  table
}: {
  frameworkId: number;
  frameworkVersionId: number;
  stageId: number;
  table: Table<any>;
}) {
  const { sessionId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;

  const intl = useIntl();

  const { stageObjectivesQuery } = useStageObjectives({
    frameworkId,
    frameworkVersionId,
    stageId,
    displayLocale
  });

  const { getStageObjectives } = useOfflineDataDownload({
    sessionId: Number(sessionId),
    downloadSessionData: true
  });

  function openModal(objective: components['schemas']['ObjectiveDto']) {
    const header = intl.formatMessage(
      {
        id: 'objective.colon.name',
        defaultMessage: 'Objective: {name}',
        description: 'Objective: {name}'
      },
      { name: objective.name }
    );

    const modalContent = objective.description;

    modal(modalContent, {
      header,
      hideFooter: true
    });
  }

  const { resourcesQuery } = useResources({
    frameworkVersionId,
    displayLocale
  });

  const trophies = resourcesQuery.data?.filter(
    (resource) => resource.resourceTypeId === ResourceType.TROPHY
  );

  const stageObjectives =
    stageObjectivesQuery.data || getStageObjectives(stageId);

  if (stageObjectives)
    return (
      <thead className="thead-responsive">
        {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              let objective: components['schemas']['ObjectiveDto'] | undefined;
              let imgSrc: string = '';
              if (header.id.match(/objective-\d+/g)) {
                const objectiveId = (header.column.columnDef as any)
                  .accessorKey;
                objective = (
                  stageObjectives as components['schemas']['ObjectiveDto'][]
                )?.find((obj) => obj.objectiveId === Number(objectiveId));
                if (trophies) {
                  const trophyImg = trophies.find(
                    (trophy: any) => trophy.objectiveId === Number(objectiveId)
                  );
                  imgSrc = trophyImg
                    ? `${process.env.REACT_APP_BLOB_BASE_URL}/${trophyImg.resourceString}?${trophyImg.sasToken}`
                    : '';
                }
              }
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : header.column.getCanSort() ? (
                    <SortingButton
                      column={header.column}
                      flexRender={flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    />
                  ) : (
                    <>
                      <div className="list-item-line" />{' '}
                      <button
                        type="button"
                        className="btn objective-header"
                        onClick={() => {
                          if (objective) openModal(objective);
                        }}
                      >
                        <div>
                          {' '}
                          <ObjectiveSnakeImage imgSrc={imgSrc} isSmall />
                        </div>
                        <span className="visually-hidden">
                          <FormattedMessage
                            id="objective.details.view"
                            defaultMessage="View objective details"
                          />
                        </span>
                      </button>
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
    );
  return <Loading />;
}

export default ObjectivesOverviewTableHead;
