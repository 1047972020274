import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="container-fluid">
      <div className="row gap-2 gap-md-0">
        <div className="col-12">
          <div className="card rounded-top-right-lg border-0 bg-white d-inline-block my-3 p-3">
            <h1 className="mb-0">
              <FormattedMessage
                id="page.not.found"
                defaultMessage="The page you are looking for does not exist"
              />
            </h1>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="card border-0 rounded-top-right-lg">
                <div className="card-header rounded-top-right-lg">
                  <h2>
                    {' '}
                    <FormattedMessage
                      id="page.not.found.try.links"
                      defaultMessage="Some useful links:"
                    />
                  </h2>
                </div>
                <div className="card-body">
                  <Link to="/">
                    <FormattedMessage
                      id="home.link"
                      defaultMessage="Home"
                      description="Home"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
