import React, { useContext, useEffect } from 'react';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import UsersList from '../users/UsersList';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function ManageUsersImpersonation() {
  const intl = useIntl();
  const { providerId } = useParams();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const navigate = useNavigate();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;

  const redirectAfterImpersonation = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.RoleSelect]
  }`;
  useEffect(() => {
    if (!profile) {
      navigate(redirectAfterImpersonation);
    }
  }, [profile]);
  return (
    <div className="card border-0 rounded-top-right-lg ">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.users.impersonate.manage',
            defaultMessage: 'Manage users impersonation'
          })}
        </title>
      </Helmet>
      <CardHeader>
        <h1>
          <FormattedMessage
            id="users.impersonate.manage"
            defaultMessage="Manage users impersonation"
            description="Manage users impersonation"
          />
        </h1>
      </CardHeader>

      <div className="card-body">
        <UsersList providerId={Number(providerId)} />
      </div>
    </div>
  );
}
export default ManageUsersImpersonation;
