import React from 'react';
import DownChevron from './DownChevron';

function DropdownIndicator() {
  return (
    <div className="px-2">
      <DownChevron />
    </div>
  );
}

export default DropdownIndicator;
