import React, { useState } from 'react';
import { PropsValue } from 'react-select';
import { ColumnFilter } from '@tanstack/react-table';

import useProviderCentres from '../../../../../hooks/centres/useProviderCentres';
import { components } from '../../../../../types/openapi/UserService';
import SelectFilter from './SelectFilter';

function ProviderCentreFilter({
  profile,
  displayLocale,
  columnDef,
  initialFilterValue,
  handleDropdownInputChange
}: {
  profile: components['schemas']['UserProvidersDto'];
  displayLocale: string;
  columnDef: any;
  initialFilterValue: number;
  handleDropdownInputChange: (selectedOption: any) => void;
}) {
  const [tableFiltering, setFiltering] = useState<ColumnFilter[]>(
    JSON.parse(window.sessionStorage.getItem(`table.filters`) ?? '[]')
  );

  const { centresForProviderListQuery } = useProviderCentres({
    providerId: profile?.providerId,
    displayLocale,
    rawFiltering: tableFiltering
  });

  const options: PropsValue<any> = centresForProviderListQuery.data?.centres
    ?.map((centre) => {
      return {
        value: centre.centreId ?? 0,
        label: centre.name ?? '-'
      };
    })
    .sort((a: { label: string }, b: { label: string }) =>
      a.label.localeCompare(b.label)
    );

  return (
    <SelectFilter
      columnDef={columnDef}
      initialFilterValue={initialFilterValue}
      handleDropdownInputChange={handleDropdownInputChange}
      options={options}
      setFiltering={setFiltering}
      filteringAccessorKey="centreName"
      columnName="Centre"
      menuPositionRight={0}
    />
  );
}

export default ProviderCentreFilter;
