/* eslint-disable no-nested-ternary */
import React from 'react';

import BookableTimetableWrapper from './BookableTimetableWrapper';

function LearnerTimetable({
  userId,
  providerId
}: {
  userId: number;
  providerId: number | undefined | null;
}) {
  // TODO - when viewing bookable sessions is in provider config, uncomment
  // if ("provider is EA")
  //   return <TimetableWrapper/>
  return <BookableTimetableWrapper userId={userId} providerId={providerId} />;
}

export default LearnerTimetable;
