/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import UsersTablePaged from './UsersTablePaged';
import useUsers from '../../hooks/useUsers';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { UserType } from '../../const/user-type';
import ImpersonateUserBtn from '../impersonate-user/ImpersonateUserBtn';

function UsersList({ providerId }: { providerId: number }) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const queryParamsLocation = FilteringQueryParams.Users;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  const { usersListQuery } = useUsers({
    providerId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(pagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(pagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(sorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(filtering)
    );
  }, [pagination, sorting, filtering]);

  return (
    <UsersTablePaged
      data={usersListQuery.data}
      error={usersListQuery.error}
      isLoading={usersListQuery.isLoading}
      refetching={usersListQuery.isRefetching}
      pagination={pagination}
      sorting={sorting}
      filtering={filtering}
      setPagination={setPagination}
      setSorting={setSorting}
      setFiltering={setFiltering}
      queryParamsLocation={queryParamsLocation}
      renderActions={(user) => {
        return (
          <div className="btn-group-vertical">
            <Link to={`${user.userId}`} className="btn btn-outline-secondary">
              <FormattedMessage
                id="view"
                defaultMessage="View"
                description="View"
              />
            </Link>
            {profile?.userTypeId === UserType.SUPER_ADMIN && user.userId && (
              <ImpersonateUserBtn userId={user.userId} />
            )}
          </div>
        );
      }}
    />
  );
}

export default UsersList;
