import React, { ReactNode } from 'react';
import Menu from './Menu';
import Blanket from './Blanket';

function Dropdown({
  children,
  classNames,
  styles,
  isOpen,
  target,
  onClose
}: {
  children?: ReactNode;
  classNames?: {
    blanket?: string;
    reactSelectDropwdown?: string;
    menu?: string;
  };
  styles?: any;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) {
  return (
    <div
      data-id="react-select-dropdown"
      className={classNames?.reactSelectDropwdown}
    >
      {target}
      {isOpen ? (
        <Menu className={classNames?.menu} style={styles?.menu}>
          {children}
        </Menu>
      ) : null}
      {isOpen ? (
        <Blanket onClick={onClose} className={classNames?.blanket} />
      ) : null}
    </div>
  );
}
export default Dropdown;

Dropdown.defaultProps = {
  children: null,
  classNames: null,
  styles: {}
};
