import { SPCalendarEvent } from '../SPCalendarEvent.type';
import { components } from '../../../types/openapi/UserService';
import { AppLanguage } from '../../../const';
import getSessionBookingLinkUrl from './getSessionBookingLinkUrl';
import getSessionLinkUrl from './getSessionLinkUrl';
import { UserType } from '../../../const/user-type';
import getCourseLinkUrl from './getCourseLinktUrl';

const getLinkUrl = ({
  event,
  profile,
  displayLocale,
  learnerBookedOnCourse
}: {
  event: SPCalendarEvent | undefined;
  profile: components['schemas']['UserProvidersDto'] | null | undefined;
  displayLocale: AppLanguage | string | undefined;
  learnerBookedOnCourse: boolean;
}) => {
  if (profile?.userTypeId === UserType.ADMIN) {
    return getCourseLinkUrl(event as SPCalendarEvent, profile, displayLocale);
  }

  if (
    (event as SPCalendarEvent).bookable &&
    !(event as SPCalendarEvent).fullyBooked
  ) {
    return getSessionBookingLinkUrl(
      event as SPCalendarEvent,
      profile,
      displayLocale
    );
  }

  return getSessionLinkUrl(
    event as SPCalendarEvent,
    profile,
    displayLocale,
    learnerBookedOnCourse
  );
};

export default getLinkUrl;
