/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

import Loading from '../common/Loading';
import { components } from '../../types/openapi/CourseService';
import NoteListItem from './NoteListItem';
import useNote from '../../hooks/useNote';

function NotesList({
  courseId,
  sessionId,
  userId,
  showEditId,
  setShowEditId
}: {
  courseId: number;
  sessionId: number;
  userId: number;
  showEditId: number;
  setShowEditId?: Dispatch<SetStateAction<number>>;
}) {
  const { notesListQuery } = useNote({
    courseId,
    sessionId,
    userId
  });

  if (notesListQuery.isLoading) {
    return <Loading />;
  }

  if (
    (notesListQuery.error as any)?.response.data ===
    'Session User Notes not Found'
  ) {
    return (
      <p className="mb-3">
        <FormattedMessage
          id="notes.session.none.found"
          defaultMessage="No learner notes have been added for this session"
          description="No learner notes have been added for this session"
        />
      </p>
    );
  }

  if (!notesListQuery.data) {
    return (
      <p>
        {' '}
        <FormattedMessage
          id="notes.none.found"
          defaultMessage="No notes"
          description="No notes found"
        />
      </p>
    );
  }

  if (notesListQuery.data) {
    return (
      <ul className="list-unstyled notes-list">
        {notesListQuery.data.map(
          (note: components['schemas']['SessionUserNoteDto']) => {
            return (
              <NoteListItem
                courseId={courseId}
                sessionId={sessionId}
                userId={userId}
                showEditId={showEditId}
                setShowEditId={setShowEditId}
                note={note}
              />
            );
          }
        )}
      </ul>
    );
  }

  return <Loading />;
}

export default NotesList;
