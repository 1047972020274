/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Loading from '../common/Loading';
import { GeneralError } from '../common';
import { components } from '../../types/openapi/CourseService';
import useNote from '../../hooks/useNote';
import ViewOnlyNoteListItem from './ViewOnlyNoteListItem';

function ViewOnlyNotesList({
  courseId,
  sessionId,
  userId,
  isOffline
}: {
  courseId: number;
  sessionId: number;
  userId: number;
  isOffline?: boolean;
}) {
  const { notesListQuery } = useNote({
    courseId,
    sessionId,
    userId
  });

  if (notesListQuery.data) {
    return (
      <ul className="list-unstyled mb-0">
        {notesListQuery.data.map(
          (note: components['schemas']['SessionUserNoteDto']) => {
            return (
              note.note?.noteText &&
              note.noteId && (
                <ViewOnlyNoteListItem
                  noteText={note.note.noteText}
                  noteId={note.noteId}
                  instructorName={note.courseSession?.instructor?.firstName}
                />
              )
            );
          }
        )}
      </ul>
    );
  }

  if (notesListQuery.isFetching) {
    return <Loading />;
  }

  if (notesListQuery.error) {
    if (
      (notesListQuery.error as any).response.data ===
      'Session User Notes not Found'
    ) {
      return (
        <FormattedMessage
          id="notes.none.added"
          defaultMessage="No learner notes have been added for this session."
          description="No learner notes have been added for this session."
        />
      );
    }
    return <GeneralError error={notesListQuery.error} />;
  }

  if (isOffline === true) {
    return (
      <FormattedMessage
        id="notes.offline.create"
        defaultMessage="Notes can only be added in online mode."
        description="Notes can only be added in online mode."
      />
    );
  }

  return <GeneralError />;
}

export default ViewOnlyNotesList;

ViewOnlyNotesList.defaultProps = {
  isOffline: false
};
