import React, { useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';

import '@formatjs/intl-datetimeformat/polyfill-force';
import '@formatjs/intl-datetimeformat/locale-data/cy';
import '@formatjs/intl-datetimeformat/locale-data/en-GB';
import '@formatjs/intl-datetimeformat/add-all-tz';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/cy';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/cy';

import Cymraeg from '../../../lang/cy-GB.json';
import English from '../../../lang/en-GB.json';

import { en, cy } from '../routes';
import { en as userTypeEn, cy as userTypeCy } from '../user-types';
import { en as attendanceEn, cy as attendanceCy } from '../attendance';
import { en as genderEn, cy as genderCy } from '../gender';
import { en as companyTypeEn, cy as companyTypeCy } from '../company-type';
import { AppLanguage, AppLanguageSpecificLocale } from '../../../const';

const allEnglish = {
  ...English,
  ...en,
  ...userTypeEn,
  ...attendanceEn,
  ...genderEn,
  ...companyTypeEn
};
const allWelsh = {
  ...Cymraeg,
  ...cy,
  ...userTypeCy,
  ...attendanceCy,
  ...genderCy,
  ...companyTypeCy
};
interface MyLangContext {
  locale?: string;
  displayLocale: string;
  updateLocale?: (newLocale: string) => void;
}

export const LangContext = React.createContext<MyLangContext>({
  locale: AppLanguageSpecificLocale.EnglishGB,
  displayLocale: AppLanguage.English
});

const userLang = navigator.language;

let lang:
  | Record<string, string>
  | Record<string, MessageFormatElement[]>
  | undefined;

let initialLang: AppLanguage;
let initialSpecificLocale: AppLanguageSpecificLocale;

// eslint-disable-next-line no-console
console.log(`Your browser language is set to ${userLang}`);

switch (userLang) {
  case AppLanguage.Welsh:
  case AppLanguageSpecificLocale.WelshGB:
    lang = allWelsh;
    initialLang = AppLanguage.Welsh;
    initialSpecificLocale = AppLanguageSpecificLocale.WelshGB;
    break;
  default:
    lang = allEnglish;
    initialLang = AppLanguage.English;
    initialSpecificLocale = AppLanguageSpecificLocale.EnglishGB;
    break;
}

function IntlWrapper(props: { children: any }) {
  const { children } = props;

  const [locale, setLocale] = useState(initialSpecificLocale);
  const [displayLocale, setDisplayLocale] = useState(initialLang);
  const [messages, setMessages] = useState(lang);

  function setLocaleToLang(newLocale: string) {
    switch (newLocale) {
      case AppLanguage.Welsh:
      case AppLanguageSpecificLocale.WelshGB:
        setLocale(AppLanguageSpecificLocale.WelshGB);
        document.documentElement.lang = AppLanguageSpecificLocale.WelshGB;
        setDisplayLocale(AppLanguage.Welsh);
        setMessages(allWelsh);
        break;
      default:
        setLocale(AppLanguageSpecificLocale.EnglishGB);
        document.documentElement.lang = AppLanguageSpecificLocale.EnglishGB;
        setDisplayLocale(AppLanguage.English);
        setMessages(allEnglish);
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function updateLocale(newLocale: string) {
    if (!newLocale) {
      console.error('language undefined');
      return;
    }
    setLocaleToLang(newLocale);
  }

  return (
    // disabled rule as we do want the app to re-render on language change
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LangContext.Provider value={{ locale, displayLocale, updateLocale }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
}

export default IntlWrapper;
