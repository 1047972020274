/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import useStoreTableFilters from '../../hooks/state-management/useStoreTableFilters';
import EditUserCentres from '../centres/EditUserCentres';
import useProviderCentresForUser from '../../hooks/centres/useProviderCentresForUser';

function EditInstructorCentres() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const { userId, instructorId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    filtering,
    setFiltering
  } = useStoreTableFilters({
    queryParamsLocation: FilteringQueryParams.InstructorCentres
  });

  // Selected centres
  const { centreIds } = useProviderCentresForUser({
    providerId: profile?.providerId,
    userId: Number(userId || instructorId),
    userTypeId: UserType.INSTRUCTOR,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <EditUserCentres
      userId={Number(userId || instructorId)}
      providerId={profile?.providerId}
      userTypeId={UserType.INSTRUCTOR}
      queryParamsLocation={FilteringQueryParams.InstructorCentres}
      centreIds={centreIds}
      pagination={pagination}
      sorting={sorting}
      filtering={filtering}
      headingText={
        <FormattedMessage
          id="instructor.edit_instructor_centres"
          defaultMessage="Edit Instructor Centres"
          description="Edit Instructor Centres"
        />
      }
      setPagination={setPagination}
      setSorting={setSorting}
      setFiltering={setFiltering}
    />
  );
}

export default EditInstructorCentres;
