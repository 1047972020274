/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState
} from '@tanstack/react-table';

import { components } from '../../types/openapi/ProviderService';
import PaginatedTable from '../tables/PaginatedTable';
import useTableColumns from '../../hooks/table/useTableColumns';

function SchoolsTable({
  data,
  error,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation,
  renderLink
}: {
  data: components['schemas']['PagedSchoolDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  queryParamsLocation: string;
  renderLink?: ({
    school
  }: {
    school: components['schemas']['SchoolDto'];
  }) => JSX.Element | ReactNode;
}) {
  const intl = useIntl();

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  const { centreAddressColumn, postcodeColumn } = useTableColumns({});

  const columns = [
    {
      id: 'schoolName',
      header: intl.formatMessage({
        id: 'name',
        defaultMessage: 'Name',
        description: 'Name'
      }),
      cell: ({ row }: { row: any }) => {
        const school = row.original;
        return `${school?.name}`;
      },
      accessorKey: 'schoolName',
      filterType: 'basicInput'
    },
    centreAddressColumn,
    postcodeColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const link =
          renderLink &&
          renderLink({
            school: row.original
          });
        return <div>{link}</div>;
      },
      filterType: undefined
    }
  ];

  const table = useReactTable({
    data: data?.schools ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfSchools
        ? Math.ceil(data.numberOfSchools / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfSchools}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="schoolId"
      noneFoundMessage={
        <FormattedMessage
          id="schools.error.none"
          defaultMessage="No schools found"
          description="No schools found"
        />
      }
      showFooter={(data?.numberOfSchools ?? 0) > 10}
    />
  );
}

export default SchoolsTable;

SchoolsTable.defaultProps = {
  renderLink: () => {}
};
