/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import StageObjectives from '../StageObjectives';

function FrameworkStageObjectives({
  frameworkVersionId,
  stageId,
  userId,
  isEditable
}: {
  frameworkVersionId: number;
  stageId: number;
  userId: number;
  isEditable: boolean;
}) {
  return (
    <>
      {frameworkVersionId && (
        <StageObjectives
          frameworkVersionId={frameworkVersionId}
          stageId={stageId}
          userId={userId}
          isEditable={isEditable}
        />
      )}
    </>
  );
}

export default FrameworkStageObjectives;
