/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { components } from '../../types/openapi/ProviderService';
import Asterisk from '../Asterisk';
import { Subset } from '../../types/Subset.type';
import InlineFormError from '../ui/InlineFormError';

function CentreForm({
  onSubmit,
  submitButtonMessage,
  name,
  description,
  address,
  formDisabled,
  helpText
}: {
  onSubmit: (
    value: Pick<
      components['schemas']['CentreDto'],
      'name' | 'description' | 'address'
    >
  ) => void;
  submitButtonMessage: any;
  address?: Subset<components['schemas']['AddressDto']> | undefined | null;
  name?: string;
  description?: string;
  formDisabled?: boolean;
  helpText?: {
    name?: string | undefined;
    description?: string | undefined;
    postalCode?: string | undefined;
    buildingNumberName?: string | undefined;
    streetName?: string | undefined;
    townCity?: string | undefined;
    county?: string | undefined;
    countryRegion?: string | undefined;
  };
}) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: name || '',
      description: description || '',
      address: {
        buildingNumberName: address?.buildingNumberName || '',
        streetName: address?.streetName || '',
        townCity: address?.townCity || '',
        postalCode: address?.postalCode || '',
        county: address?.county || '',
        countryRegion: address?.countryRegion || ''
      }
    }
  });

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid g-0">
        <section className="row">
          <div className="col-6">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                <Asterisk />
                <FormattedMessage
                  id="form.name"
                  defaultMessage="Name"
                  description="Name"
                />
              </label>
              <input
                id="name"
                type="text"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.name ? 'true' : 'false'}
                {...register('name', { required: true })}
              />

              {errors.name?.type === 'required' && (
                <InlineFormError
                  message={
                    <FormattedMessage
                      id="form.name.required"
                      defaultMessage="Name is required"
                      description="Name is required"
                    />
                  }
                />
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                <FormattedMessage
                  id="form.description"
                  defaultMessage="Description"
                  description="Description"
                />{' '}
              </label>
              <textarea
                id="description"
                className="form-control"
                aria-invalid={errors.description ? 'true' : 'false'}
                {...register('description', { required: false })}
              />
              {helpText && helpText.description && (
                <div id="descriptionHelpBlock" className="form-text">
                  {helpText.description}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="buildingNumberName" className="form-label">
                <FormattedMessage
                  id="form.buildingNumberName"
                  defaultMessage="Building Number/Name"
                  description="Building Number/Name"
                />{' '}
              </label>
              <input
                type="text"
                id="buildingNumberName"
                className="form-control"
                autoComplete="off"
                aria-invalid={
                  errors.address?.buildingNumberName ? 'true' : 'false'
                }
                {...register('address.buildingNumberName', { required: false })}
              />
              {helpText && helpText.buildingNumberName && (
                <div id="buildingNumberNameHelpBlock" className="form-text">
                  {helpText.buildingNumberName}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="streetName" className="form-label">
                <FormattedMessage
                  id="form.streetName"
                  defaultMessage="Street Name"
                  description="Street Name"
                />{' '}
              </label>
              <input
                type="text"
                id="streetName"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.address?.streetName ? 'true' : 'false'}
                {...register('address.streetName', { required: false })}
              />
              {helpText && helpText.streetName && (
                <div id="streetNameHelpBlock" className="form-text">
                  {helpText.streetName}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="townCity" className="form-label">
                <FormattedMessage
                  id="form.townCity"
                  defaultMessage="Town/City"
                  description="Town/City"
                />{' '}
              </label>
              <input
                type="text"
                id="townCity"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.address?.townCity ? 'true' : 'false'}
                {...register('address.townCity', { required: false })}
              />
              {helpText && helpText.townCity && (
                <div id="townCityHelpBlock" className="form-text">
                  {helpText.townCity}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="postalCode" className="form-label">
                <FormattedMessage
                  id="form.postalCode"
                  defaultMessage="Post Code"
                  description="Post Code"
                />{' '}
              </label>
              <input
                type="text"
                id="postalCode"
                className="form-control w-auto"
                autoComplete="off"
                aria-invalid={errors.address?.postalCode ? 'true' : 'false'}
                {...register('address.postalCode', { required: false })}
              />
              {helpText && helpText.postalCode && (
                <div id="postalCodeHelpBlock" className="form-text">
                  {helpText.postalCode}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="county" className="form-label">
                <FormattedMessage
                  id="form.county"
                  defaultMessage="County"
                  description="County"
                />{' '}
              </label>
              <input
                type="text"
                id="county"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.address?.county ? 'true' : 'false'}
                {...register('address.county', { required: false })}
              />
              {helpText && helpText.county && (
                <div id="countyHelpBlock" className="form-text">
                  {helpText.county}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="countryRegion" className="form-label">
                <FormattedMessage
                  id="form.countryRegion"
                  defaultMessage="Region"
                  description="Region"
                />{' '}
              </label>
              <input
                type="text"
                id="countryRegion"
                className="form-control"
                autoComplete="off"
                aria-invalid={errors.address?.countryRegion ? 'true' : 'false'}
                {...register('address.countryRegion', { required: false })}
              />
              {helpText && helpText.countryRegion && (
                <div id="countryRegionHelpBlock" className="form-text">
                  {helpText.countryRegion}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>

      <button
        type="submit"
        className="btn btn-primary me-auto"
        disabled={formDisabled}
      >
        {submitButtonMessage}
      </button>
    </form>
  );
}

export default CentreForm;

CentreForm.defaultProps = {
  name: '',
  description: '',
  address: {
    postalCode: '',
    buildingNumberName: '',
    streetName: '',
    townCity: '',
    county: '',
    countryRegion: ''
  },
  formDisabled: false,
  helpText: {
    name: '',
    description: '',
    postalCode: '',
    buildingNumberName: '',
    streetName: '',
    townCity: '',
    county: '',
    countryRegion: ''
  }
};
