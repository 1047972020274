import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserType } from '../const/user-type';
import { ProfileContext } from '../modules/profile/ProfileProvider';
import instructorKeys from '../query-keys/instructor-key-factory';
import {
  getUsersForProviderCentreOfUserType,
  getUsersForProviderOfUserType
} from '../services/api/user.service';
import isAllowed from '../utils/permissions/isAllowed';
import { FilteringObject } from '../const/filtering-object';

// hook for managing learners, instructors, centres summaries within provider
const useInstructors = ({
  providerId,
  centreId,
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  providerId: number;
  centreId?: number | null | undefined;
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  if (!providerId && profile?.userTypeId === UserType.ADMIN) {
    throw Error('invalid id');
  }

  let sortField: string | null = null;
  let sortDirection: string | null = null;

  if (sorting && sorting[0]) {
    sortField = sorting[0].id;
    sortDirection = sorting[0].desc ? 'DESC' : 'ASC';
  }

  // eslint-disable-next-line prefer-const
  let filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const instructorsListQuery = useQuery({
    queryKey: centreId
      ? instructorKeys.providerCentreList(
          providerId!,
          centreId,
          displayLocale,
          {
            pageIndex,
            pageSize,
            sorting,
            filtering
          }
        )
      : instructorKeys.providerList(providerId, displayLocale, {
          pageIndex,
          pageSize,
          sorting,
          filtering
        }),
    queryFn: () => {
      if (centreId) {
        return getUsersForProviderCentreOfUserType(
          providerId,
          centreId,
          UserType.INSTRUCTOR,
          pageIndex,
          pageSize,
          sorting,
          filtering,
          displayLocale
        );
      }
      return getUsersForProviderOfUserType(
        providerId,
        UserType.INSTRUCTOR,
        pageIndex,
        pageSize,
        sortField,
        sortDirection,
        filtering,
        displayLocale
      );
    },
    refetchOnMount: true,
    enabled:
      !!providerId &&
      isAllowed([UserType.ADMIN], profile?.userTypeId as UserType),
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'Users not Found') return false;
      if (error?.message === 'token or refreshtoken missing') return false;
      if (failureCount >= 3) return false;
      return false;
    }
  });

  return {
    instructorsListQuery
  };
};

export default useInstructors;
