import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { AppRoute } from '../../const';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import localiseRoutePath from '../../utils/localiseRoutePath';
import CentresList from './CentresList';
import isCentreAdmin from '../../utils/permissions/isCentreAdmin';

function ManageCentres() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  return (
    <div className="card border-0 rounded-top-right-lg ">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.centres.manage',
            defaultMessage: 'Manage centres'
          })}
        </title>
      </Helmet>
      <div className="card-header d-flex">
        <div className="flex-grow-1">
          <h1>
            <FormattedMessage
              id="centres.manage"
              defaultMessage="Manage Centres"
              description="Manage Centres"
            />
          </h1>
        </div>
        <div>
          {profile && !isCentreAdmin(profile) && (
            <Link
              to={localiseRoutePath(AppRoute.CreateCentre)}
              className="btn btn-primary"
            >
              <FormattedMessage
                id="centre.link.create_new"
                defaultMessage="Create new Centre"
                description="Create new Centre"
              />
            </Link>
          )}
        </div>
      </div>
      <div className="card-body">
        <CentresList />
      </div>
    </div>
  );
}

export default ManageCentres;
