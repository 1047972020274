/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactNode } from 'react';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Column } from '@tanstack/react-table';

function SortingButton({
  column,
  flexRender
}: {
  column: Column<any, unknown>;
  flexRender?: Element | ReactNode;
}) {
  return (
    <div>
      <>{flexRender}</>
      <button
        onClick={column.getToggleSortingHandler()}
        className="btn fw-bold p-0 sorting-button d-flex"
        type="button"
      >
        <div>
          {{
            asc: (
              <>
                <FontAwesomeIcon className="ms-1" icon={faSortUp} />
                <span className="visually-hidden">
                  <FormattedMessage
                    id="sorting.button.clear"
                    defaultMessage="Clear sorting"
                    description="Clear sorting"
                  />
                </span>
              </>
            ),
            desc: (
              <>
                <FontAwesomeIcon className="ms-1" icon={faSortDown} />
                <span className="visually-hidden">
                  <FormattedMessage
                    id="sorting.button.ascending"
                    defaultMessage="Sort ascending"
                    description="Sort ascending"
                  />
                </span>
              </>
            ),
            false: (
              <>
                <FontAwesomeIcon className="ms-1" icon={faSort} />
                <span className="visually-hidden">
                  <FormattedMessage
                    id="sorting.button.descending"
                    defaultMessage="Sort descending"
                    description="Sort descending"
                  />
                </span>
              </>
            )
          }[column.getIsSorted() as string] ?? null}
        </div>
      </button>
    </div>
  );
}

export default SortingButton;

SortingButton.defaultProps = {
  flexRender: <div />
};
