import React, { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FormattedMessage } from 'react-intl';

function CustomResourceToggle({
  eventKey,
  numberOfResources
}: {
  eventKey: string;
  numberOfResources: number;
}) {
  if (numberOfResources === 0) {
    return null;
  }

  const decoratedOnClick = useAccordionButton(eventKey);

  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={decoratedOnClick}
    >
      {isCurrentEventKey ? (
        <FormattedMessage
          id="objective.resources.hide"
          defaultMessage="{numberOfResources, plural, =0 {no resources} one {Hide Resource} other {Hide Resources}}"
          description="Hide Resources"
          values={{ numberOfResources }}
        />
      ) : (
        <FormattedMessage
          id="objective.resources.view.button"
          defaultMessage="{numberOfResources, plural, =0 {No resources} one {View resource} other {View resources}}"
          description="View resources"
          values={{ numberOfResources }}
        />
      )}
    </button>
  );
}

export default CustomResourceToggle;
