import { components } from '../../../types/openapi/CourseService';
import { SPCalendarEvent } from '../SPCalendarEvent.type';
import { convertDateTime } from '../../../utils/date-time';

const mapSessionToEvent = (
  session: components['schemas']['CourseSessionDto'],
  options: {
    learnerBookedOnSession: boolean;
    bookable: boolean;
    fullyBooked: boolean;
    userIsInstructor: boolean;
  }
): SPCalendarEvent => {
  const { learnerBookedOnSession, bookable, fullyBooked, userIsInstructor } =
    options;
  const { sessionStartTime, sessionEndTime } = session;

  // session times on server are in UTC
  // calendar expects times in UTC
  // the calendar will handle conversion to the calendar timezone;
  const utcStartTime = convertDateTime(sessionStartTime);
  const startTimeAsDate = utcStartTime.toDate();
  const utcEndTime = convertDateTime(sessionEndTime);
  const endTimeAsDate = utcEndTime.toDate();

  if (session.courseSessionId) {
    return {
      id: session.courseSessionId,
      title: session.course?.name,
      start: startTimeAsDate,
      end: endTimeAsDate,
      desc: session.course?.description,
      learnerBookedOnSession,
      bookable,
      fullyBooked,
      session,
      userIsInstructor
    };
  }
  throw Error('no course session id');
};

export default mapSessionToEvent;
