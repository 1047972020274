/* eslint-disable no-unsafe-optional-chaining */
import { IntlShape } from 'react-intl';
import { components } from '../../types/openapi/CourseService';

const buildSelectFrameworkStageOptions = (
  courses: components['schemas']['StageDto'][],
  intl: IntlShape
) => {
  const stageTranslation = intl.formatMessage({
    id: 'stage.select',
    defaultMessage: 'Stage'
  });

  return courses.map((_stage: components['schemas']['StageDto']) => {
    return {
      label: `${_stage.name} (${stageTranslation} ${_stage.stageOrder})`,
      value: _stage.stageId
    };
  });
};

export default buildSelectFrameworkStageOptions;
