import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SingleValue } from 'react-select';
import { CourseUserStars } from '../../../../../../types/CourseUserStars.type';
import { components } from '../../../../../../types/openapi/CourseService';
import { components as frameworkComponents } from '../../../../../../types/openapi/FrameworkService';
import { GeneralError } from '../../../../../common';
import ObjectivesOverviewTableWrapper from './ObjectivesOverviewTableWrapper';
import CourseStageSelect from './CourseStageSelect';

function ObjectivesOverview({
  frameworkId,
  frameworkVersionId,
  learners,
  starsForUsers,
  courseStages,
  multipleStagesObjectives
}: {
  frameworkId: number | undefined | null;
  frameworkVersionId: number | undefined;
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][];
  starsForUsers:
    | CourseUserStars[]
    | components['schemas']['StarsForUserDto'][]
    | undefined
    | null;
  courseStages:
    | components['schemas']['CourseStageDto'][]
    | components['schemas']['CourseStageSlimDto'][]
    | undefined
    | null;
  multipleStagesObjectives:
    | (
        | {
            stageId: number | undefined;
            stageOrder: number | undefined;
            stageName: string | undefined | null;
            stageObjectives:
              | frameworkComponents['schemas']['ObjectiveDto'][]
              | components['schemas']['ObjectiveSlimDto'][]
              | undefined
              | null;
          }
        | undefined
      )[]
    | undefined;
}) {
  const [selectedStageId, setSelectedStageId] = useState<number>();
  const onChangeStage = (
    stageOption: SingleValue<{
      label: string | undefined;
      value: number | undefined;
    }>
  ) => {
    setSelectedStageId(stageOption?.value);
  };

  if (courseStages) {
    if (!selectedStageId) {
      setSelectedStageId(courseStages[0].stageId);
    }
  }

  if (frameworkId && frameworkVersionId)
    return (
      <div className="card-body">
        <div>
          <h3>
            <FormattedMessage id="stages.header" defaultMessage="Stages" />
          </h3>
          {courseStages && (
            <CourseStageSelect
              frameworkVersionId={frameworkVersionId}
              courseStages={courseStages}
              onChangeStage={onChangeStage}
            />
          )}
        </div>
        {selectedStageId ? (
          <ObjectivesOverviewTableWrapper
            frameworkId={frameworkId}
            frameworkVersionId={frameworkVersionId}
            stageId={selectedStageId}
            learners={learners}
            starsForUsers={starsForUsers}
            objectives={
              multipleStagesObjectives?.find(
                (multipleStageObjective) =>
                  multipleStageObjective?.stageId === selectedStageId
              )?.stageObjectives
            }
          />
        ) : (
          <GeneralError />
        )}
      </div>
    );
  return <GeneralError message="No framework Id" />;
}

export default ObjectivesOverview;
