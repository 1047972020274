export enum AppLanguage {
  English = 'en',
  Welsh = 'cy'
}

export enum ProviderConfigAppLanguage {
  'English' = 'en',
  'Welsh' = 'cy'
}
export type AppLanguages = keyof typeof AppLanguage;

export enum AppLanguageSpecificLocale {
  EnglishGB = 'en-GB',
  WelshGB = 'cy'
}

export default AppLanguage;
