/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import Loading from '../common/Loading';
import { GeneralError } from '../common';
import CentreDetailsTable from './CentreDetailsTable';
import FacilitiesTable from './facilities/FacilitiesTable';
import UsersTable from '../users/UsersTable';
import localiseRoutePath from '../../utils/localiseRoutePath';
import { AppRoute } from '../../const';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import useFacilities from '../../hooks/useFacilities';
import CentreInstructorsCard from './centre/CentreInstructorsCard';
import CentreCoursesCard from './centre/CentreCoursesCard';
import useProviderConfig from '../../hooks/useProviderConfig';
import useCentre from '../../hooks/centre/useCentre';
import useAdminsForCentre from '../../hooks/centre/useAdminsForCentre';

function ViewCentre() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const { centreId } = useParams();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  const userProviderId = profile?.providerId;

  const { providerLanguages } = useProviderConfig({
    providerId: Number(userProviderId)
  });

  const { centreQuery } = useCentre({
    providerId: Number(userProviderId),
    centreId: Number(centreId),
    displayLocale
  });

  const { adminsForCentreQuery: admins } = useAdminsForCentre({
    centreId: Number(centreId),
    providerId: profile?.providerId,
    displayLocale
  });

  const { facilitiesQuery: facilities } = useFacilities({
    providerId: profile?.providerId,
    centreId: Number(centreId),
    displayLocale
  });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.centre.name',
              defaultMessage: 'Centre: {name}'
            },
            { name: centreQuery.data?.name || '' }
          )}
        </title>
      </Helmet>
      <div className="card">
        <div className="card-header d-flex">
          <div className="flex-grow-1">
            <h1>
              <FormattedMessage
                id="centre.name"
                defaultMessage="Centre: {name}"
                description="Centre"
                values={{ name: centreQuery.data?.name || '' }}
              />
            </h1>
          </div>
          {providerLanguages.length > 1 && (
            <div>
              <Link
                to={`${localiseRoutePath(AppRoute.ManageTranslation)}`}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="manage.translation"
                  defaultMessage="Manage Translation"
                  description="Manage Translation"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h2>
            <FormattedMessage
              id="centre.details"
              defaultMessage="Details"
              description="Details"
            />
          </h2>
        </div>
        <div className="card-body">
          {centreQuery.isLoading ? (
            <Loading />
          ) : centreQuery.error ? (
            <GeneralError />
          ) : (
            centreQuery.data && <CentreDetailsTable centre={centreQuery.data} />
          )}
        </div>
        <div className="card-footer d-flex">
          <Link
            to={`${localiseRoutePath(AppRoute.EditCentre)}`}
            className="btn btn-primary mx-auto"
          >
            <FormattedMessage
              id="centre.edit.details.button"
              defaultMessage="Edit centre details"
              description="Edit centre details"
            />
          </Link>{' '}
        </div>
      </div>
      {/* courses */}
      {profile?.providerId && centreId && (
        <CentreCoursesCard
          userProviderId={profile.providerId}
          centreId={Number(centreId)}
        />
      )}
      {/* instructors */}
      {profile?.providerId && centreId && (
        <CentreInstructorsCard
          userProviderId={profile.providerId}
          centreId={Number(centreId)}
        />
      )}

      <div className="card">
        <div className="card-header">
          <h2>
            <FormattedMessage
              id="facilities"
              defaultMessage="Facilities"
              description="Facilities"
            />
          </h2>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <Link
              to={`${localiseRoutePath(AppRoute.CreateFacility)}`}
              className="btn btn-primary"
            >
              <FormattedMessage
                id="facilities.add_new"
                defaultMessage="Add new facility"
                description="Add new facility"
              />
            </Link>
          </div>
          {facilities.isFetching ? (
            <Loading />
          ) : centreQuery.error ? (
            <GeneralError />
          ) : facilities.data && facilities.data.length > 0 ? (
            <FacilitiesTable facilities={facilities.data} />
          ) : (
            <FormattedMessage
              id="facilities.error.none"
              defaultMessage="No facilities found"
              description="No facilities found"
            />
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-header d-flex">
          <div className="flex-grow-1">
            <h2>
              <FormattedMessage
                id="centre.admins"
                defaultMessage="Admins"
                description="Admins"
              />
            </h2>
          </div>
          {!profile?.centreId && (
            <Link
              to={`${localiseRoutePath(AppRoute.CreateCentreAdmin)}`}
              className="btn btn-primary mx-auto"
            >
              <FormattedMessage
                id="admin.create"
                defaultMessage="Create admin"
                description="Create admin"
              />
            </Link>
          )}
        </div>
        <div className="card-body">
          {admins.isFetching ? (
            <Loading />
          ) : admins.error ? (
            (admins.error as any)?.response?.status === 404 ? (
              <FormattedMessage
                id="admins.error.none"
                defaultMessage="No Admins found"
                description="No Admins found"
              />
            ) : (
              <GeneralError />
            )
          ) : admins.data ? (
            <UsersTable users={admins.data.users} />
          ) : (
            <FormattedMessage
              id="admins.error.none"
              defaultMessage="No Admins found"
              description="No Admins found"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ViewCentre;
