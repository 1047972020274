/* eslint-disable @typescript-eslint/no-shadow */
import React, { Fragment, ReactNode } from 'react';
import { ObjectiveWithStars } from '../../../types/ObjectiveWithStars.type';
import ObjectiveSnakeImage from '../../courses/sessions/objectives/ObjectiveSnakeImage';

function TrophySnakeObjectives({
  previousObjectiveImgSrc,
  latestObjective,
  isLatestObjectiveFinal,
  renderStarElement
}: {
  previousObjectiveImgSrc?: string;
  latestObjective: ObjectiveWithStars;
  isLatestObjectiveFinal: boolean;
  renderStarElement: ({
    userStarValue,
    inputStarValue,
    objective
  }: {
    userStarValue: number;
    inputStarValue: number;
    objective: ObjectiveWithStars;
  }) => JSX.Element | ReactNode;
}) {
  const NUMBER_OF_STARS = 3;

  const isLatestObjectiveCompleted = latestObjective.stars === NUMBER_OF_STARS;

  const isPreviousObjective = previousObjectiveImgSrc !== undefined;
  return (
    <div key={latestObjective.objectiveId}>
      <div className="d-flex flex-column align-items-center">
        <div className="w-100">
          <div
            className={`d-flex justify-content-evenly w-100 trophy-snake align-items-center ${
              !isPreviousObjective && 'no-previous-objective'
            } ${isLatestObjectiveFinal && 'no-next-objective'}`}
          >
            {isPreviousObjective && (
              <ObjectiveSnakeImage imgSrc={previousObjectiveImgSrc} />
            )}
            <ObjectiveSnakeImage
              imgSrc={latestObjective.imgSrc}
              isLatest
              isUnavailable={!isLatestObjectiveCompleted}
            />
            {!isLatestObjectiveFinal && (
              <ObjectiveSnakeImage imgSrc={undefined} isUnavailable />
            )}
          </div>
        </div>

        <div className="flex-grow-1">
          <div className=" d-flex flex-column w-100 justify-content-between">
            <div className="d-flex align-items-center">
              <div className="stars my-3">
                {Array.from({ length: NUMBER_OF_STARS }, (v, i) => i).map(
                  (e) => {
                    const starValue = e + 1;
                    return (
                      <Fragment key={e}>
                        {renderStarElement({
                          objective: latestObjective,
                          inputStarValue: starValue,
                          userStarValue: latestObjective.stars
                        })}
                      </Fragment>
                    );
                  }
                )}
              </div>
            </div>
            <div className="text-center ">
              <h5 className="badge bg-brand-amber text-black">
                {latestObjective.name}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TrophySnakeObjectives;

TrophySnakeObjectives.defaultProps = {
  previousObjectiveImgSrc: undefined
};
