/* eslint-disable @typescript-eslint/no-shadow */
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { AppLanguage } from '../../const';

function OnlineStatusBanner({
  online,
  className,
  renderStatusMessage,
  renderButton
}: {
  online: boolean;
  className?: string;
  renderStatusMessage: ({
    online
  }: {
    online: boolean;
  }) => JSX.Element | ReactNode;
  renderButton: ({ online }: { online: boolean }) => JSX.Element | ReactNode;
}) {
  const location = useLocation();
  return (
    <div className={`offline-switcher p-2 ${className}`}>
      <div className="m-auto">
        <div className="align-self-center">
          {renderStatusMessage({ online })}
        </div>
      </div>

      {(location.pathname === `/${AppLanguage.English}` ||
        location.pathname === `/${AppLanguage.English}/` ||
        location.pathname === `/${AppLanguage.Welsh}` ||
        location.pathname === `/${AppLanguage.Welsh}/`) && (
        <div className="d-flex">{renderButton({ online })}</div>
      )}
    </div>
  );
}

export default OnlineStatusBanner;

OnlineStatusBanner.defaultProps = {
  className: ''
};
