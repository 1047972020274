/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { components } from '../../../types/openapi/ProviderService';
import Asterisk from '../../Asterisk';

function FacilityForm({
  onSubmit,
  submitButtonMessage,
  name,
  description,
  formDisabled,
  helpText
}: {
  onSubmit: (
    value: Pick<components['schemas']['FacilityDto'], 'name' | 'description'>
  ) => void;
  submitButtonMessage: any;
  name?: string;
  description?: string;
  formDisabled?: boolean;
  helpText?: {
    name: string | undefined;
    description: string | undefined;
  };
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: name || '',
      description: description || ''
    }
  });

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label w-100">
          <Asterisk />
          <FormattedMessage
            id="form.name"
            defaultMessage="Name"
            description="Name"
          />
          <input
            id="name"
            type="text"
            className="form-control"
            autoComplete="off"
            aria-invalid={errors.name ? 'true' : 'false'}
            {...register('name', { required: true })}
          />
        </label>
        {helpText && helpText.name && (
          <div id="nameHelpBlock" className="form-text">
            {helpText.name}
          </div>
        )}
        {errors.name?.type === 'required' && (
          <p className="invalid-feedback d-inline" role="alert">
            <FormattedMessage
              id="form.name.required"
              defaultMessage="Name is required"
              description="Name is required"
            />
          </p>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          <FormattedMessage
            id="form.description"
            defaultMessage="Description"
            description="Description"
          />{' '}
        </label>
        <textarea
          id="description"
          className="form-control"
          aria-invalid={errors.description ? 'true' : 'false'}
          {...register('description', { required: false })}
        />
        {helpText && helpText.description && (
          <div id="descriptionHelpBlock" className="form-text">
            {helpText.description}
          </div>
        )}
      </div>
      <button
        type="submit"
        className="btn btn-primary align-self-center"
        disabled={formDisabled || !isDirty || !isValid}
      >
        {submitButtonMessage}
      </button>
    </form>
  );
}

export default FacilityForm;

FacilityForm.defaultProps = {
  name: '',
  description: '',
  formDisabled: false,
  helpText: {
    name: '',
    description: ''
  }
};
