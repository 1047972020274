import { useQuery } from '@tanstack/react-query';
import sessionKeys from '../query-keys/session-key-factory';
import { getCourseSessionsForCentreForDateRange } from '../services/api/course.service';

const useCentreCourseSessions = ({
  providerId,
  centreId,
  startDate,
  endDate,
  displayLocale
}: {
  providerId: number;
  centreId: number | undefined;
  startDate: string;
  endDate: string;
  displayLocale: string;
}) => {
  const courseSessionsForCentreQuery = useQuery({
    queryKey: sessionKeys.centreCourseSessionsForDateRange(
      providerId,
      centreId,
      startDate,
      endDate,
      displayLocale
    ),
    queryFn: () =>
      getCourseSessionsForCentreForDateRange(
        providerId,
        centreId,
        startDate,
        endDate,
        displayLocale
      ),
    enabled: !!providerId && !!centreId
  });
  return {
    courseSessionsForCentreQuery
  };
};

export default useCentreCourseSessions;
