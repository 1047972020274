import dayjs from 'dayjs';
import { components } from '../../types/openapi/CourseService';

const buildSelectCourseSessionOptions = (
  courseSessions: components['schemas']['CourseSessionDto'][]
) => {
  return courseSessions.map(
    (_courseSession: components['schemas']['CourseSessionDto']) => {
      return {
        label: dayjs(_courseSession.sessionStartTime).format('DD/MM/YYYY'),
        value: _courseSession.courseSessionId
      };
    }
  );
};

export default buildSelectCourseSessionOptions;
