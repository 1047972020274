/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  OnChangeFn,
  ColumnFiltersState
} from '@tanstack/react-table';
import { components } from '../../types/openapi/ProviderService';
import PaginatedTable from '../tables/PaginatedTable';
import useTableColumns from '../../hooks/table/useTableColumns';

function CentresTable({
  data,
  error,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation,
  renderCentresLink
}: {
  data: components['schemas']['PagedCentreDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  queryParamsLocation: string;
  setFiltering: OnChangeFn<ColumnFiltersState> | undefined;
  renderCentresLink?: ({
    centre
  }: {
    centre: components['schemas']['CentreDto'];
  }) => JSX.Element | ReactNode;
}) {
  const intl = useIntl();

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    if (setFiltering) {
      setFiltering([]);
    }
  };

  const {
    centreNameColumn,
    centreAddressColumn,
    postcodeColumn,
    centreExternalIdColumn
  } = useTableColumns({});

  const columns = [
    centreNameColumn,
    centreAddressColumn,
    postcodeColumn,
    centreExternalIdColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const centreLink =
          renderCentresLink &&
          renderCentresLink({
            centre: row.original
          });
        return <div>{centreLink}</div>;
      },
      filterType: undefined
    }
  ];

  const table = useReactTable({
    data: data?.centres ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfCentres
        ? Math.ceil(data.numberOfCentres / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfCentres}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="centreId"
      noneFoundMessage={
        <FormattedMessage
          id="centres.error.none"
          defaultMessage="No centres found"
          description="No centres found"
        />
      }
      showFooter={(data?.numberOfCentres ?? 0) > 10}
    />
  );
}

export default CentresTable;

CentresTable.defaultProps = {
  renderCentresLink: () => {}
};
