/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { components } from '../../../types/openapi/UserService';
import { getUser, updateUser } from '../../../services/api/user.service';
import { AppLanguage, AppRoute } from '../../../const';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import Loading from '../../common/Loading';
import { GeneralError } from '../../common';
import { appStrings } from '../../../modules/i18n';
import groupKeys from '../../../query-keys/group-key-factory';
import UserForm from '../../users/forms/UserForm';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';

function EditGroupAdmin() {
  const intl = useIntl();

  const { groupAdminId } = useParams();

  const navigate = useNavigate();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const navLinkGroupAdmins = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Groups]
  }`;

  const queryClient = useQueryClient();

  const [groupAdminQuery] = useQueries({
    queries: [
      {
        queryKey: groupKeys.groupAdmin(Number(groupAdminId), displayLocale),
        queryFn: () => getUser(Number(groupAdminId)),
        refetchOnMount: true
      }
    ]
  });

  const { mutate: editGroupAdmin, isLoading } = useMutation(
    (groupAdminUpdated: components['schemas']['UserDto']) =>
      updateUser(groupAdminUpdated),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'group.admin.edit.success',
            defaultMessage: 'You have successfully edited this group admin'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.groupAdmin(Number(groupAdminId), displayLocale)
        });
        queryClient.invalidateQueries({
          queryKey: groupKeys.groupAdmins(
            Number(profile?.providerId),
            displayLocale
          )
        });
        navigate(navLinkGroupAdmins);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.admin.edit.error',
            defaultMessage:
              'There was an error editing this group admin with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (groupAdminUpdated: components['schemas']['UserDto']) => {
    const editedGroupAdmin: components['schemas']['UserDto'] = {
      firstName: groupAdminUpdated.firstName,
      surname: groupAdminUpdated.surname,
      email: groupAdminUpdated.email,
      telephone: groupAdminUpdated.telephone,
      genderId: Number(groupAdminUpdated.genderId),
      dateOfBirth: groupAdminUpdated.dateOfBirth,
      userId: groupAdminQuery.data?.userId,
      sportPassportId: groupAdminQuery.data?.sportPassportId
    };
    editGroupAdmin(editedGroupAdmin);
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.group.admin.edit_admin',
              defaultMessage: 'Edit Group Admin: {username}'
            },
            { username: groupAdminQuery.data?.username || '' }
          )}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="group.admin.edit_admin.header"
            defaultMessage="Edit Group Admin: {username}"
            description="Edit Group Admin"
            values={{ username: groupAdminQuery.data?.username || '' }}
          />
        </h1>
      </div>
      <div className="card-body">
        {groupAdminQuery.isFetching ? (
          <Loading />
        ) : groupAdminQuery.error ? (
          (groupAdminQuery.error as any)?.response.status === 401 ? (
            <FormattedMessage
              id="users.error.unauthorised"
              defaultMessage="You are not authorised to access this user"
              description="You are not authorised to access this user"
            />
          ) : (
            <GeneralError />
          )
        ) : (
          groupAdminQuery.data && (
            <UserForm
              onSubmit={onSubmit}
              hasEmergencyContactDetails={false}
              isEmailMandatory
              submitButtonMessage={
                <FormattedMessage
                  id="save_changes"
                  defaultMessage="Save changes"
                  description="Save changes"
                />
              }
              formDisabled={isLoading}
              email={groupAdminQuery?.data?.email || ''}
              telephone={groupAdminQuery?.data?.telephone || ''}
              firstName={groupAdminQuery?.data?.firstName || ''}
              surname={groupAdminQuery.data.surname || ''}
              emergencyContactName={
                groupAdminQuery.data.emergencyContactName || ''
              }
              emergencyPhone1={groupAdminQuery.data.emergencyPhone1 || ''}
              emergencyPhone2={groupAdminQuery.data.emergencyPhone2 || ''}
              medicalConditions={groupAdminQuery.data.medicalConditions || ''}
              genderId={groupAdminQuery.data.genderId || undefined}
              dateOfBirth={dayjs(groupAdminQuery.data.dateOfBirth).format(
                'YYYY-MM-DD'
              )}
            />
          )
        )}
      </div>
    </div>
  );
}
export default EditGroupAdmin;
