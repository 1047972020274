import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DropdownItem from 'react-bootstrap/DropdownItem';

import { ProviderConfigAppLanguage } from '../../../const';
import getMatchingRoute from '../../../utils/lang/getMatchingRoute';

function LanguageSwitcher({
  languages
}: {
  languages: ProviderConfigAppLanguage[];
}) {
  const { pathname } = useLocation();
  const { messages } = useIntl();

  return (
    <ul className="list-unstyled mb-0">
      {languages.map((lang) => {
        const languageNames = new Intl.DisplayNames([lang], {
          type: 'language'
        });
        return (
          <DropdownItem key={lang} as="li" className="p-1">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? 'pending' : isActive ? 'active' : ''
              }
              to={getMatchingRoute({
                pathname,
                messages,
                language: lang
              })}
            >
              {({ isActive }) => (
                <div className="d-flex">
                  <span
                    className={
                      isActive ? 'd-inline-block opacity-100' : 'opacity-0'
                    }
                  >
                    <FontAwesomeIcon
                      icon={icon({ name: 'check', style: 'solid' })}
                      className="align-self-center pe-2"
                    />
                  </span>
                  <span lang={lang}> {languageNames.of(lang)}</span>
                </div>
              )}
            </NavLink>
          </DropdownItem>
          // <li key={lang}>

          // </li>
        );
      })}
    </ul>
  );
}

export default LanguageSwitcher;
