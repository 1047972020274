/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import ProgressCircleWrapper from '../ProgressCircleWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { UserType } from '../../../const/user-type';
import StageCompletionBtn from '../StageCompletionBtn';
import { isAllowed } from '../../../utils/permissions';
import UndoStageCompletionBtn from '../UndoStageCompletionBtn';
import { components } from '../../../types/openapi/FrameworkService';
import { components as courseComponents } from '../../../types/openapi/CourseService';

function FrameworkProgress({
  stages,
  currentStageId,
  frameworkId,
  frameworkVersionId,
  learner,
  incompleteCount,
  queriesLoading
}: {
  stages: components['schemas']['StageDto'][];
  currentStageId: number | null | undefined;
  frameworkId: number;
  frameworkVersionId: number;
  learner:
    | components['schemas']['UserDto']
    | courseComponents['schemas']['LearnerDto'];
  incompleteCount: number;
  queriesLoading?: boolean;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const currentStage =
    stages.find((stage) => stage.stageId === currentStageId) || null;

  const isFinished = !currentStage;

  const lastCompletedStageIndex =
    stages && currentStageId
      ? stages.map((stage) => stage.stageId).indexOf(currentStageId)
      : stages.length;

  return (
    <>
      <div className="d-flex justify-content-evenly align-items-center">
        {profile?.userTypeId !== undefined &&
          learner &&
          isAllowed(
            [UserType.INSTRUCTOR, UserType.ADMIN],
            profile?.userTypeId
          ) && (
            <UndoStageCompletionBtn
              frameworkId={frameworkId}
              frameworkVersionId={frameworkVersionId}
              learner={learner}
              stages={stages}
              currentStage={currentStage}
              displayLocale={displayLocale}
              lastCompletedStageIndex={lastCompletedStageIndex}
            />
          )}
        <ProgressCircleWrapper
          numberOfStages={stages.length || 0}
          lastCompletedStageIndex={lastCompletedStageIndex || 0}
          isFinished={isFinished}
        />
        {learner &&
          profile?.userTypeId !== undefined &&
          isAllowed(
            [UserType.INSTRUCTOR, UserType.ADMIN],
            profile?.userTypeId
          ) && (
            <StageCompletionBtn
              learner={learner}
              frameworkId={frameworkId}
              frameworkVersionId={frameworkVersionId}
              stages={stages}
              currentStage={currentStage}
              incompleteCount={incompleteCount}
              isFinished={isFinished}
              queriesLoading={queriesLoading}
            />
          )}
      </div>

      {/* on framework */}
      {!isFinished &&
        incompleteCount > 0 &&
        profile?.userTypeId !== undefined &&
        isAllowed(
          [UserType.INSTRUCTOR, UserType.ADMIN],
          profile?.userTypeId
        ) && (
          <div className="alert alert-info">
            <p>
              <FormattedMessage
                id="learner.progress.incomplete"
                defaultMessage="{incompleteCount, plural, =0 {Unable to progress learner, this learner has {incompleteCount} incomplete objectives in their current stage.} one {Unable to progress learner, this learner has # incomplete objective in their current stage.} other {Unable to progress learner, this learner has # incomplete objectives in their current stage.}}"
                values={{ incompleteCount }}
              />
            </p>
          </div>
        )}
    </>
  );
}

export default FrameworkProgress;

FrameworkProgress.defaultProps = {
  queriesLoading: false
};
