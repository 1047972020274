import React, { ReactNode, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';

function ResourcesLinkCard({
  renderFeaturedResource
}: {
  renderFeaturedResource: () => JSX.Element | ReactNode;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  return (
    <div className="card rounded-top-right-lg border-0 mb-3 mb-lg-0">
      <div className="card-header rounded-top-right-lg">
        <h2>
          {' '}
          <FormattedMessage
            id="resources.header"
            defaultMessage="Resources"
            description="Resources"
          />
        </h2>
      </div>
      <div className="card-body">
        <p>
          <FormattedMessage
            id="resources.description"
            defaultMessage="Descriptions and video clips for all stages and objectives"
            description="Descriptions and video clips for all stages and objectives"
          />
        </p>
        {renderFeaturedResource()}

        <div className="d-flex justify-content-center mt-3">
          <Link
            type="button"
            className="btn btn-chevron btn-chevron-navy w-50"
            to={appStrings[displayLocale as AppLanguage][AppRoute.Resources]}
          >
            <FormattedMessage
              id="resources.button"
              defaultMessage="Resources"
              description="Resources"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ResourcesLinkCard;
