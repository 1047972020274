import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import SummaryInfo from './SummaryInfo';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useFrameworks from '../../hooks/frameworks/useFrameworks';
import useProviderConfig from '../../hooks/useProviderConfig';

function AdminDashboard() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { schoolsAvailable } = useProviderConfig({
    providerId: Number(profile?.providerId)
  });

  const { frameworksListQuery } = useFrameworks({
    displayLocale,
    providerId: profile?.providerId
  });

  return (
    <div className="dashboard">
      <div className="row mb-3">
        <div className="col-12">
          <div className="card border-0 rounded-top-right-lg ">
            <div className="card-header rounded-top-right-lg">
              <h2>
                {profile?.centreId ? (
                  <FormattedMessage
                    id="centre.summary"
                    defaultMessage="{centre} Summary"
                    description="Summary for centre"
                    values={{ centre: (profile as any).centre.name }}
                  />
                ) : (
                  <FormattedMessage
                    id="provider.summary"
                    defaultMessage="{provider} Summary"
                    description="Summary for provider"
                    values={{ provider: (profile as any).provider.name }}
                  />
                )}
              </h2>
            </div>
            <div className="card-body ">
              <SummaryInfo
                frameworksListQuery={frameworksListQuery}
                schoolsAvailable={schoolsAvailable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
