import {
  ColumnDef,
  flexRender,
  HeaderGroup,
  Table
} from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import FilterInputWrapper from './filters/FilterInputWrapper';
import SortingButton from './SortingButton';

function TableHead({
  table,
  error,
  filtering,
  resetFilters,
  columns,
  dataCount
}: {
  table: Table<any>;
  error: any;
  filtering: any[] | undefined;
  resetFilters: () => void;
  columns: ColumnDef<any>[];
  dataCount: number | undefined;
}) {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : filtering &&
                  filtering.length === 0 &&
                  (error || (dataCount && dataCount <= 10)) ? (
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                ) : (
                  <>
                    <div>
                      <div className="d-flex justify-content-between align-items-end">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                        {header.column.getCanSort() && (
                          <SortingButton column={header.column} />
                        )}
                      </div>

                      {header.column.getCanFilter() ? (
                        <div>
                          <FilterInputWrapper
                            column={header.column}
                            columnDef={columns.find(
                              (f) => f.id === header.column.id
                            )}
                            filtering={filtering}
                          />
                        </div>
                      ) : null}
                    </div>

                    {header.column.id === 'actions' &&
                      !(
                        filtering &&
                        filtering.length === 0 &&
                        dataCount &&
                        dataCount <= 10
                      ) && (
                        <button
                          type="button"
                          className="btn btn-outline-secondary clear-filter"
                          onClick={resetFilters}
                        >
                          <FormattedMessage
                            id="filters.clear"
                            defaultMessage="Clear filters"
                            description="Clear filters"
                          />
                        </button>
                      )}
                  </>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}
export default TableHead;
