import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { components } from '../../types/openapi/FrameworkService';
import StageSelect from './StageSelect';
import { buildSelectStageOptions } from '../../utils/react-select-helpers';
import { GeneralError } from '../common';
import { components as courseComponents } from '../../types/openapi/CourseService';

function StageOptionsWrapper({
  frameworkVersionId,
  stages,
  currentStageId,
  onChange
}: {
  frameworkVersionId: number;
  stages:
    | components['schemas']['StageDto'][]
    | courseComponents['schemas']['StageSlimDto'][];
  // currentStageID may be null if the user has not started a framework
  // OR has finished all stages
  currentStageId: number | null | undefined;
  onChange: (value: number) => void;
}) {
  const intl = useIntl();

  if (!stages?.length) {
    console.warn('course has no stages');
    return <GeneralError />;
  }

  const stageOptions = buildSelectStageOptions(stages, intl);

  const [matchingStage, setMatchingStage] = useState<
    { label: string; value: number | undefined } | undefined
  >(stageOptions.find((f) => f.value === currentStageId));

  if (!currentStageId && matchingStage?.value) {
    onChange(matchingStage?.value);
  }

  useEffect(() => {
    if (currentStageId) {
      setMatchingStage(stageOptions.find((f) => f.value === currentStageId));
    }
  }, [currentStageId]);

  return (
    <StageSelect
      frameworkVersionId={frameworkVersionId}
      stageOptions={stageOptions}
      defaultStageOption={matchingStage || stageOptions[0]}
      onChange={onChange}
    />
  );
}

export default StageOptionsWrapper;
