import { AppLanguage } from '../const';

const instructorKeys = {
  // everything to do with instructors (independent of their sessions)
  all: (displayLocale: string | undefined) =>
    ['instructors', displayLocale || AppLanguage.English] as const,
  // list of instructors
  list: (displayLocale: string | undefined) =>
    [
      ...instructorKeys.all(displayLocale || AppLanguage.English),
      'list'
    ] as const,
  providerList: (
    providerId: number,
    displayLocale: string | undefined,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [
      ...instructorKeys.list(displayLocale || AppLanguage.English),
      'provider',
      providerId
    ];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  },

  providerCentreList: (
    providerId: number,
    centreId: number,
    displayLocale: string | undefined,
    filters?: {
      pageIndex?: number | null | undefined;
      pageSize?: number | null | undefined;
      sorting?: any | null | undefined;
      filtering?: any | null | undefined;
    }
  ) => {
    const arrKeys: any[] = [
      ...instructorKeys.list(displayLocale || AppLanguage.English),
      'provider',
      providerId,
      'centre',
      centreId
    ];
    if (filters) {
      arrKeys.push(filters);
    }
    return arrKeys;
  },
  instructor: (
    userId: number | null | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...instructorKeys.all(displayLocale || AppLanguage.English),
      userId
    ] as const
};

export default instructorKeys;
