import React, { useContext } from 'react';
import useStages from '../../../hooks/useStages';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { components } from '../../../types/openapi/FrameworkService';

function StageSelectWrapper({
  renderControl,
  frameworkId,
  frameworkVersionId
}: {
  frameworkId: number;
  frameworkVersionId: number;
  renderControl: (stages: components['schemas']['StageDto'][]) => JSX.Element;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  // dependent on framework being set
  const { stagesQuery } = useStages({
    frameworkId,
    frameworkVersionId,
    displayLocale
  });

  return <>{renderControl(stagesQuery.data || [])}</>;
}

export default StageSelectWrapper;
