import { lazy } from 'react';

const LandingPage = lazy(() => import('./LandingPage'));
const ContactPage = lazy(() => import('./ContactPage'));
const AboutPage = lazy(() => import('./AboutPage'));
const LaunchEventPage = lazy(() => import('./LaunchEventPage'));
const LaunchEventPageCy = lazy(() => import('./LaunchEventPageCy'));

export {
  LandingPage,
  ContactPage,
  AboutPage,
  LaunchEventPage,
  LaunchEventPageCy
};
