import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useOfflineDataDownload from '../../../hooks/offline/useOfflineDataDownload';
import { GeneralError } from '../../common';
import CourseDetailsCard from '../../learners-admin/learner-on-course/CourseDetailsCard';
import ProgressCard from '../../learners-admin/learner-on-course/ProgressCard';
import UserEmergencyDetailsTable from '../../users/UserEmergencyDetailsTable';
import UserDetailsTable from '../../users/UserGeneralDetailsTable';
import OfflineCourseStageObjectivesWrapper from './OfflineCourseStageObjectivesWrapper';

function OfflineViewLearnerOnCourse({ sessionId }: { sessionId: number }) {
  const { learnerId } = useParams();
  const intl = useIntl();

  const {
    offlineDataDownloadQuery,
    course,
    userStars,
    getStageObjectives,
    getCurrentFrameworkStageIdForLearner,
    learnerDetails
  } = useOfflineDataDownload({
    sessionId: Number(sessionId),
    downloadSessionData: true
  });
  const currentFrameworkStageId = getCurrentFrameworkStageIdForLearner(
    Number(learnerId)
  );

  if (!offlineDataDownloadQuery.data) return <GeneralError />;

  const learner = learnerDetails?.find((l) => l.userId === Number(learnerId));

  const objectivesForCurrentFrameworkStage = getStageObjectives(
    currentFrameworkStageId
  );

  if (!learner || !course) return <GeneralError />;

  const userStarsForStage = userStars?.filter(
    (stars) =>
      stars.userId === learner.userId &&
      stars.stageId === currentFrameworkStageId
  );

  return (
    <>
      <Helmet>
        <title>
          {learner.username ||
            intl.formatMessage({
              id: 'title.learner',
              defaultMessage: 'Learner'
            })}
          {}
        </title>
      </Helmet>
      <div className="row">
        <div className="col-12">
          <div className="card col-12 rounded-top-right-lg border-0 mb-2">
            <div className="card-header rounded-top-right-lg border-0">
              <h2 data-gdpr="true">
                <FormattedMessage
                  id="learner.name"
                  defaultMessage="Learner: {name}"
                  description="Learner {name}"
                  values={{ name: learner.fullname || '' }}
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 g-md-2">
        <div className="col-12 col-lg-4 d-flex">
          <CourseDetailsCard
            course={course}
            courseStages={course?.courseStages}
            className="mb-2 mb-md-0 w-100"
          />
        </div>

        <div className="col col-12 col-md-6 col-lg-4 d-flex">
          <ProgressCard
            className="mb-2 mb-md-0 w-100"
            course={course}
            learner={learner}
          />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <div className="card rounded-top-right-lg h-100 border-0">
            <div className="card-header rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="learner.attendance"
                  defaultMessage="Attendance to date"
                  description="Attendance to date"
                />
              </h2>
            </div>
            <div className="card-body d-flex justify-content-center align-items-center">
              <p>
                <FormattedMessage
                  id="offline.attendance_data_unavailable"
                  defaultMessage="You are currently in offline mode. Attendance data is not available."
                  description="You are currently in offline mode. Attendance data is not available."
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <OfflineCourseStageObjectivesWrapper
            learnerId={Number(learnerId)}
            course={course}
            objectives={objectivesForCurrentFrameworkStage}
            userStarsForStage={userStarsForStage}
          />
        </div>
      </div>

      <div className="row g-2">
        <div className="col col-12 col-lg-6">
          <div className="card h-100 border-0 rounded-top-right-lg">
            <div className="card-header rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="details"
                  defaultMessage="Details"
                  description="Details"
                />
              </h2>
            </div>
            <div className="card-body">
              <UserDetailsTable user={learner} />
            </div>
          </div>
        </div>
        <div className="col col-12 col-lg-6 ">
          <div className="card border-0 h-100 rounded-top-right-lg">
            <div className="card-header rounded-top-right-lg">
              <h2>
                <FormattedMessage
                  id="emergency_details"
                  defaultMessage="Emergency Contact Details"
                  description="Emergency Contact Details"
                />
              </h2>
            </div>
            <div className="card-body ">
              <UserEmergencyDetailsTable user={learner} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfflineViewLearnerOnCourse;
