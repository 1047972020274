import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

function FooterLayout({
  footerLinks,
  secondaryLinks,
  versionInfo
}: {
  footerLinks: JSX.Element | ReactNode;
  secondaryLinks?: JSX.Element | ReactNode;
  versionInfo?: JSX.Element | ReactNode;
}) {
  return (
    <footer className="footer d-flex" role="contentinfo">
      <div className="container py-3">
        <div className="row">
          <h2 className="visually-hidden">
            {' '}
            <FormattedMessage
              id="link.support_links"
              defaultMessage="Support links"
            />
          </h2>
          {footerLinks}
        </div>
        <div className="row">
          <div className="col-6">
            <small>
              &copy; Sport Passport {new Date().getFullYear()} &nbsp;
              {versionInfo}
            </small>
          </div>
          {secondaryLinks}
        </div>
      </div>
    </footer>
  );
}

export default FooterLayout;

FooterLayout.defaultProps = {
  secondaryLinks: '',
  versionInfo: ''
};
