import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProfileInfo from './ProfileInfo';

function NavUserInfo() {
  return (
    <div className="nav-user-info d-flex py-1 px-2">
      <div className="flex-grow-1 text-truncate">
        <ProfileInfo />
      </div>

      <div className="d-flex align-items-center text-primary">
        <FontAwesomeIcon icon={icon({ name: 'user', style: 'solid' })} />
      </div>
    </div>
  );
}

export default NavUserInfo;
