const isErrorTokensRemoved = (errMessage: string) => {
  const removed =
    typeof errMessage === 'string'
      ? errMessage.includes(
          'Unauthorised by the Auth Policy due to Exception [Missing Bearing Token]'
        )
      : false;
  return removed;
};

export default isErrorTokensRemoved;
