import { useMutation } from '@tanstack/react-query';
import { requestResetEmail } from '../../services/api/auth.service';

// hook for managing frameworks
const useRequestResetEmail = () => {
  const sendPasswordResetEmail = useMutation((userName: string) => {
    return requestResetEmail(userName);
  });

  return {
    sendPasswordResetEmail
  };
};

export default useRequestResetEmail;
