/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../common';
import CourseSessionDetailsTable from '../courses/sessions/CourseSessionDetailsTable';
import Loading from '../common/Loading';

import { AppLanguage } from '../../const';

import useSession from '../../hooks/useSession';
import useCourse from '../../hooks/course/useCourse';

import AllObjectiveUserStars from './AllObjectivesUserStars';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import CourseStagesList from '../courses/CourseStagesList';
import useProviderConfig from '../../hooks/useProviderConfig';
import ViewNotesSection from './ViewNotesSection';
import useUserSessionBooking from '../../hooks/useUserSessionBooking';
import AttendanceStatus from '../../const/attendance-status.enum';

function LearnerSessionProgress() {
  const { courseId, sessionId, learnerId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  const [startDate, setStartDate] = useState<string>();

  const profileContext = useContext(ProfileContext);

  const { profile } = profileContext;
  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  if (!profile || !profile.providerId) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="profile.none.selected"
            defaultMessage="No Profile Selected"
            description="No Profile Selected"
          />
        }
      />
    );
  }

  const { notesEnabled } = useProviderConfig({
    providerId: profile.providerId
  });

  const { sessionQuery } = useSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    userId: profile.userId,
    displayLocale: displayLocale || AppLanguage.English
  });

  const { courseQuery, courseStagesQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale: displayLocale || AppLanguage.English
  });

  const { courseSessionBookingForUserQuery, attendanceStatus } =
    useUserSessionBooking({
      courseId: Number(courseId),
      courseSessionId: Number(sessionId),
      userId: profile.userId,
      displayLocale
    });

  useEffect(() => {
    if (sessionQuery.data) {
      const sessionStartTime = dayjs(sessionQuery.data.sessionStartTime);
      setStartDate(sessionStartTime.format('DD/MM/YYYY'));
    }
  }, [sessionQuery.data]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.session.date',
              defaultMessage: 'Session: {date}'
            },
            { date: startDate }
          )}
        </title>
      </Helmet>
      <div className="card border-0 rounded-top-right-lg mb-3">
        <div className="card-header rounded-top-right-lg d-flex align-items-center justify-content-between gap-3 flex-wrap py-3">
          <h1>
            <FormattedMessage
              id="session.id"
              defaultMessage="Session: {date}"
              description="Session: {date}"
              values={{ date: startDate }}
            />
          </h1>

          {courseSessionBookingForUserQuery.isFetching ? (
            <Loading />
          ) : courseSessionBookingForUserQuery.error ? (
            <GeneralError />
          ) : (
            courseSessionBookingForUserQuery.data &&
            attendanceStatus !== AttendanceStatus.FUTURE &&
            (attendanceStatus === AttendanceStatus.ABSENT ? (
              <span className="badge bg-secondary">
                <FormattedMessage
                  id="attendance.session.absent"
                  defaultMessage="You did not attend this session"
                  description="You did not attend this session"
                />
              </span>
            ) : (
              <span className="badge bg-primary">
                <FormattedMessage
                  id="attendance.session.attended"
                  defaultMessage="You attended this session"
                  description="You attended this session"
                />
              </span>
            ))
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card border-0 rounded-top-right-lg mb-3">
            <div className="card-header rounded-top-right-lg">
              <h3>
                <FormattedMessage
                  id="course"
                  defaultMessage="Course"
                  description="Course"
                />
              </h3>
            </div>
            <div className="card-body">
              {sessionQuery.isFetching ? (
                <Loading />
              ) : sessionQuery.error ? (
                <GeneralError />
              ) : (
                sessionQuery.data &&
                sessionQuery.data.course && (
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">
                          <FormattedMessage
                            id="name"
                            defaultMessage="Name"
                            description="Name"
                          />
                        </th>
                        <td>{sessionQuery.data.course.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <FormattedMessage
                            id="description"
                            defaultMessage="Description"
                            description="Description"
                          />
                        </th>
                        <td>{sessionQuery.data.course.description}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <FormattedMessage
                            id="framework.table.header"
                            defaultMessage="Framework"
                            description="Framework"
                          />
                        </th>
                        <td>
                          {courseQuery.data &&
                            courseQuery.data.frameworkVersion?.name}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <FormattedMessage
                            id="stages"
                            defaultMessage="Stages"
                            description="Stages"
                          />
                        </th>
                        <td>
                          {courseStagesQuery.data && (
                            <CourseStagesList
                              courseStages={courseStagesQuery.data}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card rounded-top-right-lg mb-3">
            <div className="card-header rounded-top-right-lg">
              <h3>
                <FormattedMessage
                  id="session"
                  defaultMessage="Session"
                  description="Session"
                />
              </h3>
            </div>
            <div className="card-body">
              {sessionQuery.isFetching ? (
                <Loading />
              ) : sessionQuery.error ? (
                <GeneralError />
              ) : (
                sessionQuery.data && (
                  <CourseSessionDetailsTable session={sessionQuery.data} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {notesEnabled && (
        <ViewNotesSection
          courseId={Number(courseId)}
          sessionId={Number(sessionId)}
          userId={profile?.userId}
          learnerId={Number(learnerId)}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3>
                <FormattedMessage
                  id="objectives"
                  defaultMessage="Objectives"
                  description="Objectives"
                />
              </h3>
            </div>
            <div className="card-body">
              {courseQuery.isFetching ? (
                <Loading />
              ) : courseQuery.error ? (
                <GeneralError error={courseQuery.error} />
              ) : courseQuery.data && profile?.userId ? (
                <AllObjectiveUserStars
                  course={courseQuery.data}
                  sessionId={Number(sessionId)}
                  userId={profile?.userId}
                />
              ) : (
                <FormattedMessage
                  id="objectives.none"
                  defaultMessage="No objectives could be found"
                  description="No objectives could be found"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LearnerSessionProgress;
