import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleValue } from 'react-select';
import dayjs from 'dayjs';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppLanguage } from '../../../../../const';
import usePrompt from '../../../../../hooks/ui/usePrompt';
import useSession from '../../../../../hooks/useSession';
import { LangContext } from '../../../../../modules/i18n/components/IntlWrapper';
import { OfflineContext } from '../../../../../modules/offline/OfflineProvider';
import AppMode from '../../../../../modules/offline/app-mode.enum';

import Loading from '../../../../common/Loading';
import DataLastUpdated from '../../../../offline/DataLastUpdated';
import InstructorLearnerTable from './instructor-table/InstructorLearnerTable';
import ObjectiveOption from './select-objective/ObjectiveOption.interface';
import { components } from '../../../../../types/openapi/CourseService';
import { components as frameworkComponents } from '../../../../../types/openapi/FrameworkService';
import ObjectiveSelectWrapper from './select-objective/ObjectiveSelectWrapper';
import { GeneralError } from '../../../../common';
import useUserSessionObjectiveStars from '../../../../../hooks/useUserSessionObjectiveStars';
import { CourseUserStars } from '../../../../../types/CourseUserStars.type';
import VisibleColumnHeaderToggles from './instructor-table/VisibleColumnHeaderToggles';
import useMediaQuery from '../../../../../hooks/ui/useMediaQuery';

function InstructorSessionLearners({
  course,
  instructorId,
  learners,
  starsForUsers,
  frameworkVersionId,
  courseStages,
  session,
  learnerDataUpdatedAt,
  multipleStagesObjectives
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto']
    | undefined;
  instructorId: number;
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][]
    | undefined;
  starsForUsers:
    | CourseUserStars[]
    | components['schemas']['StarsForUserDto'][]
    | undefined
    | null;
  frameworkVersionId: number | undefined | null;
  courseStages:
    | components['schemas']['CourseStageDto'][]
    | components['schemas']['CourseStageSlimDto'][]
    | undefined
    | null;
  session:
    | components['schemas']['CourseSessionDto']
    | components['schemas']['SessionSlimDto'];
  learnerDataUpdatedAt: number;
  multipleStagesObjectives: (
    | {
        stageId: number | undefined;
        stageOrder: number | undefined;
        stageName: string | undefined | null;
        stageObjectives:
          | frameworkComponents['schemas']['ObjectiveDto'][]
          | components['schemas']['ObjectiveSlimDto'][]
          | undefined
          | null;
      }
    | undefined
  )[];
}) {
  const { courseId, sessionId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const DEBOUNCE_TIME = appMode === AppMode.ONLINE ? 500 : 4000;

  const [objectiveValue, setObjectiveValue] = useState<
    SingleValue<ObjectiveOption> | undefined
  >();

  const [objectivesSaving, setObjectivesSaving] = useState<boolean>(false);

  const isCourseStageSlim = (
    value:
      | components['schemas']['CourseStageDto']
      | components['schemas']['CourseStageSlimDto']
  ): value is components['schemas']['CourseStageDto'] => {
    return !!value;
  };

  const {
    updateMembersSessionAttendances,
    setSessionIsDirty,
    sessionIsDirty,
    attendances,
    dispatch
  } = useSession({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    userId: instructorId,
    displayLocale: displayLocale || AppLanguage.English
  });

  const {
    objectiveStars,
    updateUserSessionObjectiveStars,
    dispatch: objectiveDispatch
  } = useUserSessionObjectiveStars({
    frameworkVersionId,
    courseId: course?.courseId,
    sessionId: Number(sessionId)
  });

  const groupedOptions = multipleStagesObjectives?.map((stageAndObjectives) => {
    if (stageAndObjectives) {
      const { stageId, stageName, stageOrder, stageObjectives } =
        stageAndObjectives;
      if (stageObjectives)
        return {
          label:
            stageName ||
            intl.formatMessage(
              {
                id: 'stage.number',
                defaultMessage: 'Stage {number}'
              },
              { number: stageOrder }
            ),
          stageId,
          options: stageObjectives
            .map((objective) => {
              return {
                value: objective.objectiveId?.toString(10),
                label: `${objective.name || objective.title}: ${
                  objective.description
                }`,
                stageId: objective.stageId,
                order: objective.order
              };
            })
            .sort((a: any, b: any) => (a.order < b.order ? -1 : 1))
        };
    }

    return {
      label: intl.formatMessage(
        {
          id: 'stage.error',
          defaultMessage: 'Stage error'
        },
        { number: 0 }
      ),
      stageId: 0,
      options: []
    };
  }) || [
    {
      label: intl.formatMessage(
        {
          id: 'stage.error',
          defaultMessage: 'Stage error'
        },
        { number: 0 }
      ),
      stageId: 0,
      options: []
    }
  ];
  let defaultObjective: SingleValue<ObjectiveOption> | undefined;

  const [group] = groupedOptions;
  if (group) {
    const [options] = group.options;
    defaultObjective = options;
  }

  usePrompt(
    intl.formatMessage({
      id: 'instructor.session.leave.alert',
      defaultMessage:
        'Saving data in progress. Please wait. Leaving this page now will result in your data not being saved. Are you sure you want to leave?'
    }),
    sessionIsDirty || objectivesSaving
  );

  const sendMutation = () => {
    if (attendances && !!attendances.size && sessionIsDirty) {
      setSessionIsDirty(false);
      updateMembersSessionAttendances.mutate({
        courseId: Number(courseId),
        sessionId: Number(sessionId),
        attendanceMap: attendances,
        dateTime: dayjs().toISOString()
      });
    }
  };

  const sendObjectivesMutation = () => {
    if (objectiveStars && objectiveStars.length > 0) {
      setObjectivesSaving(false);

      updateUserSessionObjectiveStars.mutate({
        courseId: Number(courseId),
        sessionId: Number(sessionId),
        objectiveStars
      });
    }
  };

  useEffect(
    () => {
      if (attendances) {
        // Wait before updating
        // wait much longer in offline mode to bundle updates
        const timeout = setTimeout(() => {
          sendMutation();
        }, DEBOUNCE_TIME);
        // If the hook is called again, cancel the previous timeout
        // This creates a debounce instead of a delay
        return () => clearTimeout(timeout);
      }
      return () => false;
    },
    // Run the hook every time the user changes the attendances
    [attendances]
  );

  // and the same for objectives
  useEffect(
    () => {
      if (objectiveStars) {
        // Wait before updating
        // wait much longer in offline mode to bundle updates
        const timeout = setTimeout(() => {
          sendObjectivesMutation();
        }, DEBOUNCE_TIME);
        // If the hook is called again, cancel the previous timeout
        // This creates a debounce instead of a delay
        return () => clearTimeout(timeout);
      }
      return () => false;
    },
    // Run the hook every time the user changes the objectives
    [objectiveStars]
  );

  const isSmallTablet = useMediaQuery('(max-width: 690px)');

  const [visibleColumnHeader, setVisibleColumnHeader] = useState<
    string | undefined
  >();

  const attendanceColumnId = 'attendance';
  const objectivesColumnId = 'objectives';

  if (isSmallTablet && !visibleColumnHeader) {
    setVisibleColumnHeader(attendanceColumnId);
  }

  if (learners && learners.length > 0) {
    return (
      <>
        <div className="card-body">
          {isSmallTablet && (
            <VisibleColumnHeaderToggles
              attendanceColumnId={attendanceColumnId}
              objectivesColumnId={objectivesColumnId}
              visibleColumnHeader={visibleColumnHeader}
              setVisibleColumnHeader={setVisibleColumnHeader}
            />
          )}
          {(!isSmallTablet ||
            (isSmallTablet && visibleColumnHeader === objectivesColumnId)) && (
            <div>
              <h3>
                <FormattedMessage
                  id="objectives.header"
                  defaultMessage="Objectives"
                />
                /
                {courseStages && isCourseStageSlim(courseStages[0])
                  ? (
                      courseStages as components['schemas']['CourseStageSlimDto'][]
                    ).find((stage) => stage.stageId === objectiveValue?.stageId)
                      ?.stage?.name ||
                    (
                      courseStages as components['schemas']['CourseStageSlimDto'][]
                    ).find(
                      (stage) => stage.stageId === defaultObjective?.stageId
                    )?.stage?.name
                  : (
                      courseStages as components['schemas']['CourseStageDto'][]
                    ).find((stage) => stage.stageId === objectiveValue?.stageId)
                      ?.stage?.name ||
                    (
                      courseStages as components['schemas']['CourseStageDto'][]
                    ).find(
                      (stage) => stage.stageId === defaultObjective?.stageId
                    )?.stage?.name}
              </h3>
              {groupedOptions ? (
                <ObjectiveSelectWrapper
                  defaultObjective={objectiveValue || defaultObjective}
                  groupedOptions={groupedOptions}
                  course={course}
                  onChangeObjective={(selectedObjective) => {
                    return setObjectiveValue(
                      (
                        objective: SingleValue<ObjectiveOption> | undefined
                      ) => ({
                        ...objective,
                        ...selectedObjective
                      })
                    );
                  }}
                />
              ) : (
                <div className="loading-card">
                  <Loading />
                </div>
              )}
            </div>
          )}

          <InstructorLearnerTable
            learners={learners}
            session={session}
            course={course}
            handleMarkObjective={({
              learnerId,
              stageId,
              objectiveId,
              numberOfStars
            }) => {
              setObjectivesSaving(true);
              objectiveDispatch({
                type: 'add_objective_star',
                objectiveStar: {
                  learnerId,
                  stageId,
                  objectiveId,
                  numberOfStars
                }
              });
            }}
            handleMarkAttended={(learnerId: number) =>
              dispatch({
                type: 'set_attendance_true',
                userId: learnerId
              })
            }
            handleMarkAbsent={(learnerId: number) =>
              dispatch({
                type: 'set_attendance_false',
                userId: learnerId
              })
            }
            setSessionIsDirty={setSessionIsDirty}
            stageId={objectiveValue?.stageId || defaultObjective?.stageId}
            objectiveId={
              Number(objectiveValue?.value) || Number(defaultObjective?.value)
            }
            starsForUsers={starsForUsers}
            attendanceColumnId={attendanceColumnId}
            objectivesColumnId={objectivesColumnId}
            visibleColumnHeader={visibleColumnHeader}
            isSmallTablet={isSmallTablet}
          />
        </div>
        <div className="card-footer">
          <span className="small text-muted">Learner Data:</span>

          <DataLastUpdated
            dataUpdatedAt={learnerDataUpdatedAt}
            className="text-muted"
          />
          <span className="mx-1">|</span>
          <small className="text-muted">
            {appMode === AppMode.ONLINE
              ? sessionIsDirty
                ? 'saving data...'
                : 'data is synced'
              : sessionIsDirty || objectivesSaving
              ? 'saving changes...'
              : 'changes will be synced when back online'}
          </small>
        </div>
        {appMode === AppMode.OFFLINE &&
          (sessionIsDirty || objectivesSaving) && (
            <div className="fixed-top alert alert-info mb-0 text-center">
              <FormattedMessage
                id="saving"
                defaultMessage="Saving changes"
                description="Saving changes"
              />
              <FontAwesomeIcon icon={faRotate} className="fa-spin ms-2" />
            </div>
          )}{' '}
      </>
    );
  }

  return (
    <div className="card-body">
      <GeneralError
        message={
          <FormattedMessage
            id="learners.none_booked"
            defaultMessage="No learners booked on this session. Please contact your administrator to add learners"
            description="No learners booked on this session. Please contact your administrator to add learners"
          />
        }
      />
    </div>
  );
}

export default InstructorSessionLearners;
