/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { components } from '../../../types/openapi/UserService';
import { createUser } from '../../../services/api/user.service';
import { AppRoute, AppLanguage } from '../../../const';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { UserType } from '../../../const/user-type';
import UserForm from '../../users/forms/UserForm';
import { appStrings } from '../../../modules/i18n';
import { Subset } from '../../../types/Subset.type';
import groupKeys from '../../../query-keys/group-key-factory';

function CreateGroupAdmin() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;
  const navigate = useNavigate();
  const navLinkGroups = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Groups]
  }`;

  const { mutate: createNewGroupAdmin, isLoading } = useMutation(
    (admin: Subset<components['schemas']['UserDto']>) => createUser(admin),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'group.admin.create.success',
            defaultMessage:
              'A new group admin has been created for this provider'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: groupKeys.groupAdmins(profile?.providerId, displayLocale)
        });
        navigate(navLinkGroups);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'group.admin.create.error',
            defaultMessage:
              'There was an error creating a group admin with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (admin: components['schemas']['UserDto']) => {
    const { firstName, surname, email, telephone, dateOfBirth, genderId } =
      admin;
    const newGroupAdmin: Subset<components['schemas']['UserDto']> = {
      firstName,
      surname,
      email,
      telephone,
      genderId: Number(genderId),
      joinDate: new Date().toJSON(),
      dateOfBirth,
      title: 'title',
      userProviders: [
        {
          providerId: profile?.providerId,
          userTypeId: UserType.GROUP_ADMIN,
          centreId: profile?.centreId
        }
      ]
    };
    createNewGroupAdmin(newGroupAdmin);
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.group.create_new_group_admin',
            defaultMessage: 'Create new group admin'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="group.create_new_group_admin"
            defaultMessage="Create New Group Admin"
            description="Create New Group Admin"
          />
        </h1>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            <UserForm
              onSubmit={onSubmit}
              hasEmergencyContactDetails={false}
              isEmailMandatory
              submitButtonMessage={
                <FormattedMessage
                  id="group.admin.button.create_new"
                  defaultMessage="Create new group admin"
                  description="Create new group admin"
                />
              }
              formDisabled={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateGroupAdmin;
