/* eslint-disable @typescript-eslint/no-shadow */
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { components } from '../../types/openapi/CourseService';
import NoteForm from './NoteForm';
import useNote from '../../hooks/useNote';
import noteKeys from '../../query-keys/note-key-factory';

function CreateNote({
  courseId,
  sessionId,
  userId,
  setShowCreateForm
}: {
  courseId: number;
  sessionId: number;
  userId: number;
  setShowCreateForm: Dispatch<SetStateAction<boolean | undefined>>;
}) {
  const intl = useIntl();
  const { createNoteMutation } = useNote({
    courseId,
    sessionId,
    userId
  });
  const queryClient = useQueryClient();

  const sendMutation = (note: components['schemas']['NoteDto']) => {
    if (note.noteText)
      createNoteMutation.mutate(note.noteText, {
        onSuccess: () => {
          toast.success(
            intl.formatMessage({
              id: 'note.create.success',
              defaultMessage: 'A new note has been created'
            }),
            { delay: 200 }
          );
          queryClient.invalidateQueries({
            queryKey: noteKeys.list(courseId, sessionId, userId)
          });
          if (setShowCreateForm) setShowCreateForm(false);
        },
        onError: () => {
          toast.success(
            intl.formatMessage({
              id: 'note.create.error',
              defaultMessage: 'There was an error with creating this note'
            }),
            { delay: 200 }
          );
        }
      });
  };

  const onSubmit = (note: components['schemas']['NoteDto']) => {
    sendMutation(note);
  };

  return (
    <div className="notes-list ">
      <div className="notes-list__item flex-column">
        <FontAwesomeIcon
          icon={faCommentAlt}
          className="notes-list__icon align-self-start mb-3"
        />
        <NoteForm
          onSubmit={onSubmit}
          setShowNoteForm={setShowCreateForm}
          submitButtonMessage={
            <FormattedMessage
              id="save"
              defaultMessage="Save"
              description="Save"
            />
          }
        />
      </div>
    </div>
  );
}

export default CreateNote;
