import React, { useContext, useEffect, useState } from 'react';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import CentresTable from './CentresTable';
import CentreListActions from './CentreListActions';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import useProviderCentres from '../../hooks/centres/useProviderCentres';

function CentresList() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);

  const queryParamsLocation = FilteringQueryParams.Centres;

  const { profile } = profileContext;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(pagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(pagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(sorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(filtering)
    );
  }, [pagination, sorting, filtering]);

  const { centresForProviderListQuery } = useProviderCentres({
    providerId: profile?.providerId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <div>
      <CentresTable
        data={centresForProviderListQuery.data}
        error={centresForProviderListQuery.error}
        isLoading={centresForProviderListQuery.isLoading}
        refetching={centresForProviderListQuery.isRefetching}
        pagination={pagination}
        sorting={sorting}
        filtering={filtering}
        setPagination={setPagination}
        setSorting={setSorting}
        setFiltering={setFiltering}
        queryParamsLocation={queryParamsLocation}
        renderCentresLink={({ centre }) => (
          <CentreListActions
            centre={centre}
            queryParamsLocation={FilteringQueryParams.Centres}
            linkTo={`${centre.centreId}`}
            canDelete={!profile?.centreId}
          />
        )}
      />
    </div>
  );
}

export default CentresList;
