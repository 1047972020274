/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PaginationState,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';

import { components } from '../../../types/openapi/UserService';
import PaginatedTable from '../../tables/PaginatedTable';
import useTableColumns from '../../../hooks/table/useTableColumns';

function GroupAdminsTable({
  data,
  error,
  isLoading,
  refetching,
  setPagination,
  setSorting,
  setFiltering,
  pagination,
  sorting,
  filtering,
  renderActions,
  queryParamsLocation
}: {
  data: components['schemas']['PagedUserDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  renderActions: (
    instructor: components['schemas']['UserDto']
  ) => JSX.Element | ReactNode;
  queryParamsLocation: string;
}) {
  const intl = useIntl();

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  const { usernameColumn, emailColumn, sportPassportColumn } = useTableColumns(
    {}
  );

  const columns = [
    usernameColumn,
    emailColumn,
    sportPassportColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        return <div>{renderActions && renderActions(row.original)}</div>;
      },
      filterType: undefined
    }
  ];

  const table = useReactTable({
    data: data?.users ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfUsers
        ? Math.ceil(data.numberOfUsers / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfUsers}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="userId"
      noneFoundMessage={
        <FormattedMessage
          id="groups.admins.error.none"
          defaultMessage="No group admins found"
          description="No group admins found"
        />
      }
      showFooter={(data?.numberOfUsers ?? 0) > 10}
    />
  );
}
export default GroupAdminsTable;
