import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { components } from '../../types/openapi/CourseService';
import useChangeLearnersOnCourse from '../../hooks/course/useChangeLearnersOnCourse';
import courseKeys from '../../query-keys/course-key-factory';

function AddLearnerToCourseBtn({
  courseId,
  displayLocale,
  learner,
  learnersForCourse
}: {
  courseId: number;
  displayLocale: string | undefined;
  learner: components['schemas']['UserDto'];
  learnersForCourse: components['schemas']['UserDto'][];
}) {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { bookUserOnToCourseMutation } = useChangeLearnersOnCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const isBtnDisabled =
    learnersForCourse &&
    !!learnersForCourse.find(
      (previouslyAddedLearner: components['schemas']['UserDto']) =>
        previouslyAddedLearner.userId === learner.userId
    );

  const handleAddLearnerToCourse = (
    selectedLearner: components['schemas']['UserDto']
  ) => {
    bookUserOnToCourseMutation.mutate(selectedLearner, {
      onSuccess: () => {
        const toastId = 'successToast';
        toast.success(
          intl.formatMessage({
            id: 'course.learner.booking.success',
            defaultMessage:
              'The selected learners have successfully been booked onto the course'
          }),
          { delay: 200, toastId }
        );
        queryClient.invalidateQueries(
          courseKeys.learnersOnCourseList(Number(courseId), displayLocale)
        );
      },
      onError: (error: any) => {
        const errorToastId = 'errorToast';
        console.error(error);
        toast.error(
          intl.formatMessage({
            id: 'course.learner.booking.error',
            defaultMessage:
              'There was an error booking the selected learners onto this course'
          }),
          { delay: 200, toastId: errorToastId }
        );
      }
    });
  };
  return (
    <button
      type="button"
      disabled={bookUserOnToCourseMutation.isLoading || isBtnDisabled}
      className="btn btn-outline-secondary"
      onClick={() => handleAddLearnerToCourse(learner)}
    >
      <FormattedMessage id="add" defaultMessage="Add" description="Add" />
    </button>
  );
}

export default AddLearnerToCourseBtn;
