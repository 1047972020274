import { AppLanguage } from '../const';

// user specific objectives eg user stars and objectives linked to a user or users
const userObjectivesKeys = {
  all: () => ['user-objectives'] as const,
  user: (userId: number | undefined) => ['user', userId] as const,
  course: (courseId: number | undefined) => ['course', courseId] as const,
  framework: (courseId: number | undefined) => ['framework', courseId] as const,
  session: (sessionId: number | undefined) => ['session', sessionId] as const,
  userList: (userIds: number[]) =>
    [...userObjectivesKeys.all(), 'user', userIds] as const,
  // resource assets for this framework version
  list: (
    frameworkVersionId: number | undefined,
    stageId: number | undefined,
    userId: number | undefined,
    displayLocale: string | undefined
  ) =>
    [
      ...userObjectivesKeys.user(userId),
      'list',
      frameworkVersionId,
      stageId,
      displayLocale || AppLanguage.English
    ] as const,
  userCourseSessionObjectives: (
    courseId: number,
    userId: number,
    sessionId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...userObjectivesKeys.all(),
      'objectives',
      ...userObjectivesKeys.course(courseId),
      ...userObjectivesKeys.user(userId),
      ...userObjectivesKeys.session(sessionId),
      displayLocale || AppLanguage.English
    ] as const,
  userListStarsForSession: (
    courseId: number,
    sessionId: number,
    displayLocale: string | undefined
  ) =>
    [
      ...userObjectivesKeys.all(),
      'stars-list',
      ...userObjectivesKeys.course(courseId),
      ...userObjectivesKeys.session(sessionId),
      displayLocale || AppLanguage.English
    ] as const,
  sessionStars: (sessionId: number | undefined) =>
    [
      ...userObjectivesKeys.all(),
      'session-stars',
      ...userObjectivesKeys.session(sessionId)
    ] as const,
  // ALL the stars for every session on a course
  starsForCourse: (courseId: number | undefined, userId: number | undefined) =>
    [
      ...userObjectivesKeys.all(),
      'course-stars',
      ...userObjectivesKeys.course(courseId),
      ...userObjectivesKeys.user(userId)
    ] as const,
  starsForFramework: (
    frameworkVersionId: number | undefined,
    userId: number | undefined
  ) =>
    [
      ...userObjectivesKeys.all(),
      'framework-stars',
      ...userObjectivesKeys.framework(frameworkVersionId),
      ...userObjectivesKeys.user(userId)
    ] as const,
  // ALL the stars for every session on a course
  starsForCourseSession: (
    courseId: number | undefined,
    sessionId: number,
    userId: number | undefined
  ) =>
    [
      ...userObjectivesKeys.all(),
      'single-user-stars',
      ...userObjectivesKeys.course(courseId),
      ...userObjectivesKeys.session(sessionId),
      ...userObjectivesKeys.user(userId)
    ] as const,
  starsForCourseSessionUsers: (courseId: number, sessionId: number) =>
    [
      'all-users-stars',
      ...userObjectivesKeys.course(courseId),
      ...userObjectivesKeys.session(sessionId)
    ] as const
};

export default userObjectivesKeys;
