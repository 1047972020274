/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import { updateCourse } from '../../services/api/course.service';
import { components } from '../../types/openapi/CourseService';
import Loading from '../common/Loading';
import CourseForm from './CourseForm';
import useCourse from '../../hooks/course/useCourse';
import courseKeys from '../../query-keys/course-key-factory';
import sessionKeys from '../../query-keys/session-key-factory';

function EditCourse() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = displayLocale as AppLanguage;

  const intl = useIntl();
  const { courseId } = useParams();
  const navigate = useNavigate();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const queryClient = useQueryClient();

  const userProviderId = profile?.providerId;
  const navLinkCourse = `/${displayLocale?.toLowerCase()}/${
    appStrings[langVal][AppRoute.Courses]
  }/${courseId}`;

  const { courseQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const { mutate: editCourse, isLoading } = useMutation(
    (editedCourse: Partial<components['schemas']['CourseDto']>) =>
      updateCourse(Number(courseId), editedCourse, displayLocale),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'course.edit.success',
            defaultMessage: 'Course has been updated'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: courseKeys.course(Number(courseId), displayLocale)
        });
        queryClient.invalidateQueries({
          queryKey: sessionKeys.course(Number(courseId), displayLocale)
        });

        navigate(navLinkCourse);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'course.edit.error',
            defaultMessage: 'There was an error editing this course'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (
    course: Pick<
      components['schemas']['CourseDto'],
      'description' | 'name' | 'totalNumberOfPeople' | 'courseId'
    >
  ) => {
    if (
      course.name &&
      course.description &&
      userProviderId &&
      courseQuery.data
    ) {
      const {
        centreId,
        courseId,
        externalCourseId,
        frameworkVersionId,
        instructorId,
        providerId,
        facilityId
      } = courseQuery.data;
      const newCourse = {
        name: course.name,
        description: course.description,
        totalNumberOfPeople: course.totalNumberOfPeople,
        centreId,
        courseId,
        externalCourseId,
        frameworkVersionId,
        instructorId,
        providerId,
        facilityId
      };
      editCourse(newCourse);
    }
  };
  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.course.edit',
            defaultMessage: 'Edit course'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="course.edit"
            defaultMessage="Edit Course"
            description="Edit Course"
          />
        </h2>
      </div>
      <div className="card-body">
        {courseQuery.isFetching ? (
          <Loading />
        ) : courseQuery.error ? (
          <GeneralError />
        ) : courseQuery.data ? (
          <CourseForm
            onSubmit={onSubmit}
            submitButtonMessage={
              <FormattedMessage
                id="course.save_changes.button"
                defaultMessage="Save changes"
                description="Save changes"
              />
            }
            name={courseQuery.data.name!}
            description={courseQuery.data.description!}
            totalNumberOfPeople={
              courseQuery.data.totalNumberOfPeople || undefined
            }
            formDisabled={isLoading}
            isEdit
          />
        ) : (
          <FormattedMessage
            id="course.error.none"
            defaultMessage="No course found"
            description="No course found"
          />
        )}
      </div>
    </div>
  );
}

export default EditCourse;
