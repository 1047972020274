import React from 'react';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

function OnlineBanner() {
  return (
    <>
      <FontAwesomeIcon icon={faWifi} className="me-1" />
      <FormattedMessage
        id="mode.online"
        defaultMessage="You are currently in online teaching mode"
        description="You are currently in online teaching mode"
      />
    </>
  );
}

export default OnlineBanner;
