import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import sessionKeys from '../query-keys/session-key-factory';
import {
  getCourseSessionsForInstructorForDate,
  getCourseSessionsForLearnerForDateRange
} from '../services/api/course.service';
import { UserType } from '../const/user-type';
import { mapSessionToEvent } from '../components/timetable/helpers';

// hook for managing sessions
// if you are looking to manage a user within a session - try userUseSession hook instead
const useSessionsBetweenDates = ({
  userId,
  userType,
  displayLocale,
  isOnline,
  startDate,
  endDate
}: {
  userId: number;
  userType: UserType;
  displayLocale: string | undefined;
  isOnline?: boolean;
  startDate?: string;
  endDate?: string;
}) => {
  const instructorSessionsTodayQuery = useQuery({
    queryKey: sessionKeys.todayInstructor(userId, displayLocale),
    queryFn: () =>
      getCourseSessionsForInstructorForDate(
        userId,
        dayjs().format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
        displayLocale
      ),
    cacheTime: dayjs().endOf('day').diff(dayjs()), // until 23:59 tonight
    staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week - stays the same until manually invalidated or refreshed
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: userType === UserType.INSTRUCTOR
  });

  const instructorSessionsDateRangeQuery = useQuery({
    queryKey: sessionKeys.instructorSessionsForDateRange(
      userId,
      startDate,
      endDate,
      displayLocale
    ),
    queryFn: () =>
      getCourseSessionsForInstructorForDate(
        userId,
        startDate,
        endDate,
        displayLocale
      ),
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week - stays the same until manually invalidated or refreshed
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: userType === UserType.INSTRUCTOR && !!startDate && !!endDate
  });

  const learnerSessionsTodayQuery = useQuery({
    queryKey: sessionKeys.todayLearner(userId!, displayLocale),
    queryFn: () =>
      getCourseSessionsForLearnerForDateRange(
        userId,
        dayjs().format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
        displayLocale
      ),
    refetchOnMount: true,
    enabled: !!userId && userType === UserType.LEARNER && isOnline
  });

  // sessions in the event format for calendar
  const instructorSessionsTodayEvents = instructorSessionsTodayQuery.data?.map(
    (session) => {
      return mapSessionToEvent(session, {
        learnerBookedOnSession: false,
        bookable: false,
        fullyBooked: false,
        userIsInstructor: true
      });
    }
  );

  return {
    instructorSessionsTodayQuery,
    instructorSessionsDateRangeQuery,
    learnerSessionsTodayQuery,
    instructorSessionsTodayEvents
  };
};

export default useSessionsBetweenDates;
