import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import React, { Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ColumnFiltersState,
  PaginationState,
  Row,
  SortingState
} from '@tanstack/react-table';
import { toast } from 'react-toastify';

import useProviderCentres from '../../hooks/centres/useProviderCentres';
import useAddUserPermissions from '../../hooks/permissions/useAddUserPermissions';
import useUserPermissions from '../../hooks/permissions/useUserPermissions';
import useUser from '../../hooks/useUser';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { components } from '../../types/openapi/ProviderService';
import SelectableCentresTable from '../tables/SelectableCentresTable';
import InfoPanel from '../ui/InfoPanel';
import centreKeys from '../../query-keys/centre-key-factory';

function EditUserCentres({
  userId,
  providerId,
  userTypeId,
  queryParamsLocation,
  centreIds,
  pagination,
  sorting,
  filtering,
  headingText,
  setPagination,
  setSorting,
  setFiltering
}: {
  userId: number;
  providerId: number | null | undefined;
  userTypeId: number;
  queryParamsLocation: string;
  centreIds: number[];
  pagination: PaginationState;
  sorting: SortingState;
  filtering: ColumnFiltersState;
  headingText: ReactNode;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  setFiltering: Dispatch<SetStateAction<ColumnFiltersState>>;
}) {
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const queryClient = useQueryClient();

  const { userQuery } = useUser({ userId: Number(userId), displayLocale });

  // All available centres from provider
  const { centresForProviderListQuery } = useProviderCentres({
    providerId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  const { permissionsQuery } = useUserPermissions({
    userId: Number(userId),
    displayLocale
  });
  const { addPermissions, deletePermission } = useAddUserPermissions();

  const handleAddPermission = (
    centre: Row<components['schemas']['CentreDto']>
  ) => {
    const permissionBody: components['schemas']['UserProvidersDto'] = {
      userId: Number(userId),
      userTypeId,
      centreId: (centre.original as any).centreId,
      providerId,
      userTypeName: null,
      frameworkId: null,
      isDeleted: false
    };
    addPermissions.mutate(
      { userId: Number(userId), permissions: [permissionBody] },
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: centreKeys.all(displayLocale)
          });
          const toastId = 'addSuccessToast';
          toast.success(
            intl.formatMessage({
              id: 'user.permissions.centre.success',
              defaultMessage: 'Assigned centres for this user have been updated'
            }),
            { delay: 200, toastId }
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars

        onError: (error: any) => {
          const errorToast = 'addErrorToast';
          console.error(error);
          toast.error(
            intl.formatMessage({
              id: 'user.permissions.centre.error',
              defaultMessage:
                'There was an error updating assigned centres for this user'
            }),
            { delay: 200, toastId: errorToast }
          );
        }
      }
    );
    // addInstructorCentre(permissionBody);
  };

  const handleRemovePermission = (
    centre: Row<components['schemas']['CentreDto']>
  ) => {
    deletePermission.mutate(
      {
        userId: Number(userId),
        permissionId: (centre.original as any).userProviderId
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSuccess: (data) => {
          const toastId = 'removeSuccessToast';
          queryClient.invalidateQueries({
            queryKey: centreKeys.all(displayLocale)
          });
          toast.success(
            intl.formatMessage({
              id: 'user.permissions.centre.remove.success',
              defaultMessage: 'Selected centres are being removed for this user'
            }),
            { delay: 200, toastId }
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onError: (error: any) => {
          const errorToast = 'removeErrorToast';
          console.error(error);
          toast.error(
            intl.formatMessage({
              id: 'user.permissions.centre.remove.error',
              defaultMessage:
                'There was an error removing selected centres from the user'
            }),
            { delay: 200, toastId: errorToast }
          );
        }
      }
    );
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.user.edit_user_centres',
            defaultMessage: 'Edit centres'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>{headingText}</h1>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            <InfoPanel faIcon={<FontAwesomeIcon icon={faUser} />}>
              <span data-gdpr="true">
                {userQuery.data ? (
                  userQuery.data.username
                ) : (
                  <FormattedMessage
                    id="user.data.none"
                    defaultMessage="No user data available"
                    description="No user data available"
                  />
                )}
              </span>
            </InfoPanel>
          </div>
          <div className="col-12 col-md-6">
            <InfoPanel faIcon={<FontAwesomeIcon icon={faBuilding} />}>
              <FormattedMessage
                id="assigned.centres.total"
                defaultMessage="Assigned Centres: {selected}/{total}"
                description="Assigned Centres: {selected}/{total}"
                values={{
                  selected: centreIds.length,
                  total: centresForProviderListQuery.data?.numberOfCentres
                }}
              />
            </InfoPanel>
          </div>
        </div>
        {centresForProviderListQuery.data &&
          centresForProviderListQuery.data.centres && (
            <SelectableCentresTable
              data={centresForProviderListQuery.data}
              userPermissions={permissionsQuery.data}
              preselectedCentreIds={centreIds}
              error={centresForProviderListQuery.error}
              onRowSelect={handleAddPermission}
              onRowUnselect={handleRemovePermission}
              urlAccessor="centreId"
              pagination={pagination}
              sorting={sorting}
              filtering={filtering}
              setPagination={setPagination}
              setSorting={setSorting}
              setFiltering={setFiltering}
              queryParamsLocation={queryParamsLocation}
              isLoading={
                centresForProviderListQuery.isFetching ||
                permissionsQuery.isFetching ||
                addPermissions.isLoading ||
                deletePermission.isLoading
              }
            />
          )}
      </div>
    </div>
  );
}

export default EditUserCentres;
