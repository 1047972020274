/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

import { components } from '../../types/openapi/UserService';
import { getUser, updateUser } from '../../services/api/user.service';
import localiseRoutePath from '../../utils/localiseRoutePath';
import { AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import Loading from '../common/Loading';
import { GeneralError } from '../common';
import learnerKeys from '../../query-keys/learner-key-factory';
import UserForm from '../users/forms/UserForm';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import useProviderConfig from '../../hooks/useProviderConfig';
import userKeys from '../../query-keys/user-key-factory';

function EditLearner() {
  const intl = useIntl();
  const { learnerId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const { learnerNonSSOLogin } = useProviderConfig({
    providerId: Number(profile?.providerId)
  });

  const navLinkLearner = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Learners
  )}/${learnerId}`;

  const [learner] = useQueries({
    queries: [
      {
        queryKey: learnerKeys.learner(Number(learnerId), displayLocale),
        queryFn: () => getUser(Number(learnerId)),
        refetchOnMount: `always`
      }
    ]
  });

  const { mutate: editLearner, isLoading } = useMutation(
    (learnerUpdated: components['schemas']['UserDto']) =>
      updateUser(learnerUpdated),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'learner.edit.success',
            defaultMessage: 'You have successfully edited this learner'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: learnerKeys.learner(Number(learnerId), displayLocale)
        });
        queryClient.invalidateQueries({
          queryKey: userKeys.user(Number(learnerId), displayLocale)
        });
        navigate(navLinkLearner);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'learner.edit.error',
            defaultMessage:
              'There was an error editing this learner with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (learnerUpdated: components['schemas']['UserDto']) => {
    const editedLearner: components['schemas']['UserDto'] = {
      firstName: learnerUpdated.firstName,
      surname: learnerUpdated.surname,
      email: learnerUpdated.email,
      telephone: learnerUpdated.telephone,
      genderId: Number(learnerUpdated.genderId),
      emergencyContactName: learnerUpdated.emergencyContactName,
      emergencyPhone1: learnerUpdated.emergencyPhone1,
      emergencyPhone2: learnerUpdated.emergencyPhone2,
      medicalConditions: learnerUpdated.medicalConditions,
      dateOfBirth: learnerUpdated.dateOfBirth,
      userId: learner.data?.userId,
      sportPassportId: learner.data?.sportPassportId
    };
    editLearner(editedLearner);
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.learner.edit_learner',
              defaultMessage: 'Edit learner: {username}'
            },
            { username: learner.data?.username || '' }
          )}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="learner.edit_learner"
            defaultMessage="Edit learner: {username}"
            description="Edit learner"
            values={{ username: learner.data?.username || '' }}
          />
        </h1>
      </div>
      <div className="card-body">
        {learner.isFetching ? (
          <Loading />
        ) : learner.error ? (
          (learner.error as any)?.response.status === 401 ? (
            <FormattedMessage
              id="users.error.unauthorised"
              defaultMessage="You are not authorised to access this user"
              description="You are not authorised to access this user"
            />
          ) : (
            <GeneralError />
          )
        ) : (
          learner.data && (
            <UserForm
              onSubmit={onSubmit}
              submitButtonMessage={
                <FormattedMessage
                  id="save_changes"
                  defaultMessage="Save changes"
                  description="Save changes"
                />
              }
              formDisabled={isLoading}
              email={learner?.data?.email || ''}
              telephone={learner?.data?.telephone || ''}
              firstName={learner?.data?.firstName || ''}
              surname={learner.data?.surname || ''}
              emergencyContactName={learner.data?.emergencyContactName || ''}
              emergencyPhone1={learner.data?.emergencyPhone1 || ''}
              emergencyPhone2={learner.data?.emergencyPhone2 || ''}
              medicalConditions={learner.data?.medicalConditions || ''}
              genderId={learner.data.genderId || undefined}
              dateOfBirth={dayjs(learner.data?.dateOfBirth).format(
                'YYYY-MM-DD'
              )}
              hasEmergencyContactDetails
              isEmailMandatory={learnerNonSSOLogin}
            />
          )
        )}
      </div>
    </div>
  );
}
export default EditLearner;
