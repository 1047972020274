/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { SPCalendarEvent } from './SPCalendarEvent.type';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../common';
import Loading from '../common/Loading';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import TimetableFilters from './TimetableFilters';
import SportPassportCalendar from './SportPassportCalendar';
import CalendarToolbar from './CalendarToolbar';
import { filterEvents, uniqueCentres, uniqueFrameworks } from './helpers';
import { EventFilters } from './helpers/EventFilters.type';
import Unauthorised from '../../pages/Unauthorised';
import useLearnerCourseSessions from '../../hooks/useLearnerCourseSessions';

function BookableTimetableWrapper({
  userId,
  providerId
}: {
  userId: number;
  providerId: number | undefined | null;
}) {
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  // dates for booked courses
  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(1, 'week').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().add(1, 'week').format('YYYY-MM-DD')
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [showFilter, setShowFilter] = useState(false);

  const handleCloseFilters = () => setShowFilter(false);
  const handleShowFilters = () => setShowFilter(true);

  const bookable = searchParams.get('bookable');
  const bookableBool = JSON.parse(bookable || 'false');

  const {
    bookedCourseSessionsForLearnerQuery,
    bookableCourseSessionsQuery,
    bookedSessionsAsEvents,
    bookableSessionsAsEvents,
    fullyBookedSessionsAsEvents,
    fullyBookedCourseSessionsQuery,
    queryIsLoading,
    queryError
  } = useLearnerCourseSessions({
    userId,
    providerId,
    startDate,
    endDate,
    displayLocale,
    bookable: bookableBool
  });

  const [combinedEvents, setCombinedEvents] = useState<SPCalendarEvent[]>([
    ...bookedSessionsAsEvents,
    ...bookableSessionsAsEvents,
    ...fullyBookedSessionsAsEvents
  ]);

  const allUniqueCentresForSessions = combinedEvents?.length
    ? uniqueCentres(combinedEvents)
    : [];

  const allUniqueFrameworksForSessions = combinedEvents?.length
    ? uniqueFrameworks(combinedEvents)
    : [];

  const [events, setEvents] = useState<SPCalendarEvent[]>([
    ...bookedSessionsAsEvents,
    ...bookableSessionsAsEvents,
    ...fullyBookedSessionsAsEvents
  ]);

  const filterSessions = () => {
    const name = searchParams.get('name');
    const centreId = searchParams.get('centreId');
    const frameworkId = searchParams.get('frameworkId');
    if (combinedEvents) {
      const filterParams: EventFilters = {
        bookable: JSON.parse(bookable || 'false'),
        name: name || '',
        centreId: Number(centreId),
        frameworkId: Number(frameworkId)
      };
      setEvents(() => filterEvents(combinedEvents, filterParams));
    }
  };

  // combine the events when async requests complete or update
  useEffect(() => {
    setCombinedEvents(() => [
      ...bookedSessionsAsEvents,
      ...bookableSessionsAsEvents,
      ...fullyBookedSessionsAsEvents
    ]);
  }, [
    JSON.stringify(bookedSessionsAsEvents),
    JSON.stringify(bookableSessionsAsEvents),
    JSON.stringify(fullyBookedSessionsAsEvents)
  ]);

  useEffect(() => {
    if (
      bookableSessionsAsEvents &&
      bookableSessionsAsEvents.length > 0 &&
      !searchParams.get('bookable')
    ) {
      setSearchParams({ bookable: 'false' });
    }
  }, [bookableSessionsAsEvents.length]);

  // when combined events updates, apply the filter
  useEffect(() => {
    filterSessions();
  }, [JSON.stringify(combinedEvents)]);

  // apply filters when params change
  useEffect(() => {
    if (searchParams) {
      filterSessions();
    }
  }, [searchParams]);

  const toggleBookableSessions = () => {
    setSearchParams((prev: URLSearchParams) => {
      const newBookableParam = { bookable: JSON.stringify(!bookableBool) };
      const originalParams = Object.fromEntries(prev);
      const newParams = { ...originalParams, ...newBookableParam };
      return new URLSearchParams(newParams);
    });
  };

  if (profile?.userTypeId !== UserType.LEARNER) {
    return <Unauthorised />;
  }

  if (events) {
    return (
      <div className="row">
        <div className="col-12 pb-3">
          <CalendarToolbar
            renderShowBookableSessions={() => {
              return (
                <Button
                  variant="primary"
                  onClick={toggleBookableSessions}
                  disabled={!bookableSessionsAsEvents?.length}
                >
                  {bookableSessionsAsEvents?.length ? (
                    JSON.parse(searchParams.get('bookable') || 'false') ? (
                      <FormattedMessage
                        id="sessions.hide_bookable"
                        defaultMessage="Hide Bookable Sessions"
                        description="Hide Bookable Sessions"
                      />
                    ) : (
                      <FormattedMessage
                        id="sessions.show_bookable"
                        defaultMessage="Show Bookable Sessions"
                        description="Show Bookable Sessions"
                      />
                    )
                  ) : (
                    <FormattedMessage
                      id="sessions.no_bookable"
                      defaultMessage="No Bookable Sessions"
                      description="No Bookable Sessions"
                    />
                  )}
                </Button>
              );
            }}
            onClickFilters={handleShowFilters}
          />
        </div>
        <TimetableFilters
          centres={allUniqueCentresForSessions}
          frameworks={allUniqueFrameworksForSessions}
          show={showFilter}
          isAdmin={false}
          onSubmit={filterSessions}
          onResetFilter={filterSessions}
          handleCloseFilter={handleCloseFilters}
        />
        <SportPassportCalendar
          events={events}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          isLoading={queryIsLoading}
          error={queryError}
        />
      </div>
    );
  }
  // loading
  if (
    bookedCourseSessionsForLearnerQuery.isLoading ||
    bookableCourseSessionsQuery.isLoading ||
    fullyBookedCourseSessionsQuery.isLoading
  ) {
    return (
      <div className="row">
        <div className="col-12 pb-3">
          <Loading />
        </div>
      </div>
    );
  }
  // any non-404 errors
  if (
    bookedCourseSessionsForLearnerQuery.error &&
    (bookedCourseSessionsForLearnerQuery.error as any).response?.status !==
      404 &&
    bookableCourseSessionsQuery.error &&
    (bookableCourseSessionsQuery.error as any).response?.status !== 404 &&
    fullyBookedCourseSessionsQuery.error &&
    (fullyBookedCourseSessionsQuery.error as any).response?.status !== 404
  ) {
    return (
      <div className="row">
        <div className="col-12 pb-3">
          <GeneralError />
        </div>
      </div>
    );
  }
  return (
    <GeneralError
      message={intl.formatMessage({
        id: 'timetable_error',
        defaultMessage: 'Cannot get events for calendar. Please try again'
      })}
    />
  );
}

export default BookableTimetableWrapper;
