import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { components as userComponents } from '../../types/openapi/UserService';
import { components as courseComponents } from '../../types/openapi/CourseService';
import isAllowed from '../../utils/permissions/isAllowed';
import getFormattedDateOfBirth from '../../utils/getFormattedDateOfBirth';
import generateSportPassportId from '../../utils/generateSportPassportId';

function LearnersTable({
  learners,
  actions,
  attendance
}: {
  learners:
    | userComponents['schemas']['UserDto'][]
    | courseComponents['schemas']['UserDto'][];
  actions?: any[];
  attendance?: any[];
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  return (
    <table className="table card-list-table">
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="name"
              defaultMessage="Name"
              description="Name"
            />
          </th>
          <th>
            <FormattedMessage
              id="passport_id"
              defaultMessage="Passport Id"
              description="Sport Passport Id"
            />
          </th>
          <th>
            <FormattedMessage
              id="dob"
              defaultMessage="Date of Birth"
              description="Date of Birth"
            />
          </th>
          <th>
            <FormattedMessage id="age" defaultMessage="Age" description="Age" />
          </th>
          {isAllowed([UserType.INSTRUCTOR], profile?.userTypeId as UserType) &&
            !!attendance?.length && (
              <th>
                <FormattedMessage
                  id="attendance"
                  defaultMessage="Attendance"
                  description="Attendance"
                />
              </th>
            )}
          {!!actions?.length && (
            <th>
              <FormattedMessage
                id="actions.table.header"
                defaultMessage="Actions"
                description="Actions"
              />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {learners.map(
          (
            learner:
              | userComponents['schemas']['UserDto']
              | courseComponents['schemas']['UserDto'],
            index
          ) => {
            return (
              <tr key={learner.userId}>
                <td data-title="Username" data-gdpr="true">
                  {learner.username}
                </td>
                <td data-title="Sport Passport Id">
                  {learner.sportPassportId ? (
                    generateSportPassportId(learner.sportPassportId)
                  ) : (
                    <span className="text-muted">
                      <FormattedMessage
                        id="id.sport_passport.none"
                        defaultMessage="No Sport Passport Id provided"
                        description="No Sport Passport Id provided"
                      />
                    </span>
                  )}
                </td>
                <td data-title="Date of birth" data-gdpr="true">
                  {learner.dateOfBirth === '0001-01-01T00:00:00' ? (
                    <span className="text-muted">
                      <FormattedMessage
                        id="dob.none"
                        defaultMessage="No Date of Birth Provided"
                        description="No Date of Birth Provided"
                      />
                    </span>
                  ) : (
                    dayjs(learner.dateOfBirth).format('DD/MM/YYYY')
                  )}
                </td>
                <td data-gdpr="true">
                  {learner.dateOfBirth === '0001-01-01T00:00:00' ? (
                    <span className="text-muted">
                      <FormattedMessage
                        id="age.none"
                        defaultMessage="No Age Provided"
                        description="No Age Provided"
                      />
                    </span>
                  ) : (
                    getFormattedDateOfBirth(`${learner.dateOfBirth}`)
                  )}
                </td>

                {isAllowed(
                  [UserType.INSTRUCTOR],
                  profile?.userTypeId as UserType
                ) &&
                  !!attendance?.length && (
                    <td data-title="Attendance">{attendance[index]}</td>
                  )}
                {isAllowed(
                  [UserType.INSTRUCTOR, UserType.ADMIN],
                  profile?.userTypeId as UserType
                ) &&
                  !!actions?.length && <td>{actions[index]}</td>}
              </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
}

export default LearnersTable;

LearnersTable.defaultProps = {
  actions: [],
  attendance: []
};
