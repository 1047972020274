import React, { useContext } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import AppMode from '../../../../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../../../../modules/offline/OfflineProvider';
import { components } from '../../../../../types/openapi/CourseService';
import OfflineInstructorSessionLearners from '../../../../offline/offlineModePages/OfflineInstructorSessionLearners';
import OfflineObjectivesOverview from '../../../../offline/offlineModePages/OfflineObjectivesOverview';
import OnlineInstructorSessionLearners from '../../../../offline/onlineModePages/OnlineInstructorSessionLearners';
import OnlineObjectivesOverview from '../../../../offline/onlineModePages/OnlineObjectivesOverview';

function InstructorSessionTabsWrapper({
  course,
  instructorId,
  session,
  learners
}: {
  course:
    | components['schemas']['CourseDto']
    | components['schemas']['CourseSlimDto'];
  instructorId: number;
  session:
    | components['schemas']['CourseSessionDto']
    | components['schemas']['SessionSlimDto'];
  learners:
    | components['schemas']['UserDto'][]
    | components['schemas']['LearnerDto'][];
}) {
  const intl = useIntl();
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  return (
    <Tabs
      defaultActiveKey="session-management"
      id="instructor-session-tabs"
      className="instructor-session-tabs"
    >
      <Tab
        title={intl.formatMessage({
          id: 'tabs.session-management',
          defaultMessage: 'Session management'
        })}
        eventKey="session-management"
      >
        {appMode === AppMode.OFFLINE ? (
          <OfflineInstructorSessionLearners
            instructorId={instructorId}
            learners={learners}
          />
        ) : (
          <OnlineInstructorSessionLearners
            course={course}
            instructorId={instructorId}
            learners={learners}
            session={session}
          />
        )}
      </Tab>
      <Tab
        title={intl.formatMessage({
          id: 'tabs.objective-overview',
          defaultMessage: 'Objectives overview'
        })}
        eventKey="objective-overview"
      >
        {appMode === AppMode.OFFLINE ? (
          <OfflineObjectivesOverview learners={learners} />
        ) : (
          <OnlineObjectivesOverview
            learners={learners}
            course={course}
            session={session}
          />
        )}
      </Tab>
    </Tabs>
  );
}

export default InstructorSessionTabsWrapper;
