/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { AuthContext } from '../modules/auth/AuthProvider';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import useUser from '../hooks/useUser';
import useUserPermissions from '../hooks/permissions/useUserPermissions';
import RolesListWrapper from '../components/roles/RolesListWrapper';

function RoleChange() {
  // Role page - should have a role at this point
  // can navigate away
  const authContext = useContext(AuthContext);
  const { decodedUserFromToken } = authContext;

  const userId = Number(decodedUserFromToken?.userId);

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  const { userQuery } = useUser({
    userId,
    displayLocale
  });

  const { permissionsQuery } = useUserPermissions({
    userId,
    displayLocale
  });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.select_role',
            defaultMessage: 'Select role'
          })}
        </title>
      </Helmet>
      <RolesListWrapper
        userIsLoading={userQuery.isLoading}
        permissionsIsLoading={permissionsQuery.isLoading}
        userError={userQuery.error}
        permissionsError={permissionsQuery.error}
        userData={userQuery.data}
        permissionsData={permissionsQuery.data}
      />
    </>
  );
}

export default RoleChange;
