import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  addUserPermissions,
  deleteUserPermissions
} from '../../services/api/user.service';
import userKeys from '../../query-keys/user-key-factory';
import { components } from '../../types/openapi/ProviderService';

const useAddUserPermissions = () => {
  const [currentUserId, setCurrentUserId] = useState<number>();

  const queryClient = useQueryClient();

  // for one user at a time
  const addPermissions = useMutation(
    ({
      userId,
      permissions
    }: {
      userId: number;
      permissions: components['schemas']['UserProvidersDto'][];
    }) => {
      setCurrentUserId(userId);
      return addUserPermissions(userId, [...permissions]);
    },
    {
      onSuccess: () => {
        // invalidate current permissions for user
        if (currentUserId) {
          queryClient.invalidateQueries({
            queryKey: userKeys.permissions(currentUserId)
          });
        }
      }
    }
  );

  const deletePermission = useMutation(
    ({ userId, permissionId }: { userId: number; permissionId: number }) => {
      setCurrentUserId(userId);
      return deleteUserPermissions(userId, permissionId);
    },
    {
      onSuccess: () => {
        // invalidate current permissions for user
        if (currentUserId) {
          queryClient.invalidateQueries({
            queryKey: userKeys.permissions(currentUserId)
          });
        }
      }
    }
  );

  return {
    addPermissions,
    deletePermission
  };
};

export default useAddUserPermissions;
