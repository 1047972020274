import { AppLanguage } from '../const';
import { UserType } from '../const/user-type';

const usersListsKeys = {
  // list of users
  list: ({
    providerId,
    centreId,
    userTypeId,
    pageIndex,
    pageSize,
    sorting,
    filtering,
    lang
  }: {
    providerId: number | null | undefined;
    centreId?: number | null | undefined;
    userTypeId: UserType;
    pageIndex?: number | null | undefined;
    pageSize?: number | null | undefined;
    sorting?: any | null | undefined;
    filtering?: any;
    lang?: string;
  }) =>
    [
      'users-list',
      providerId,
      centreId,
      userTypeId,
      pageIndex,
      pageSize,
      sorting,
      filtering,
      lang || AppLanguage.English
    ] as const
};

export default usersListsKeys;
