import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppLanguage, AppRoute } from '../../../const';
import { appStrings } from '../../../modules/i18n';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { components } from '../../../types/openapi/UserService';
import { Loading, GeneralError } from '../../common';
import CardHeader from '../../ui/CardHeader';
import UserDetailsTable from '../../users/UserGeneralDetailsTable';
import InstructorCentres from './InstructorCentres';

function ViewInstructorDashboard({
  instructorId,
  providerId,
  isLoading,
  error,
  data,
  profileEditingEnabled,
  editCentresLink
}: {
  instructorId: number;
  providerId: number;
  isLoading: boolean;
  error: any;
  data: components['schemas']['UserDto'] | undefined;
  profileEditingEnabled: boolean | undefined;
  editCentresLink: string;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="card">
            <CardHeader
              button={
                profileEditingEnabled && (
                  <Link
                    to={`/${displayLocale}/${
                      appStrings[displayLocale as AppLanguage][
                        AppRoute.Instructors
                      ]
                    }/${instructorId}/${
                      appStrings[displayLocale as AppLanguage][
                        AppRoute.EditInstructor
                      ]
                    }`}
                    className="btn btn-primary mx-auto"
                  >
                    <FormattedMessage
                      id="edit.details.button"
                      defaultMessage="Edit details"
                      description="Edit details"
                    />
                  </Link>
                )
              }
            >
              <h2>
                <FormattedMessage
                  id="details"
                  defaultMessage="Details"
                  description="Details"
                />
              </h2>
            </CardHeader>

            <div className="card-body">
              {isLoading ? (
                <Loading />
              ) : error ? (
                (error as any)?.response.status === 401 ? (
                  <FormattedMessage
                    id="users.error.unauthorised"
                    defaultMessage="You are not authorised to access this user"
                    description="You are not authorised to access this user"
                  />
                ) : (
                  <GeneralError />
                )
              ) : (
                data && <UserDetailsTable user={data} />
              )}
            </div>
          </div>
        </div>
      </div>
      <InstructorCentres
        providerId={providerId}
        userId={Number(instructorId)}
        editCentresLink={editCentresLink}
      />
    </>
  );
}

export default ViewInstructorDashboard;
