import { GenderStrings } from './base-strings';

export const cy: GenderStrings = {
  'gender.male': 'Gwryw',
  'gender.female': 'Benyw',
  'gender.nonbinary': 'Anneuaidd',
  'gender.other': 'Arall',
  'gender.notsay': 'Gwell gennyf beidio â dweud'
};

export default cy;
