import { components } from '../../types/openapi/UserService';

const isAllowedProfile = (
  allowedProfile: string[] | undefined,
  profile: components['schemas']['UserProvidersDto']
) => {
  if (!profile || !allowedProfile) {
    return false;
  }
  return allowedProfile.every((profileProperty) => {
    return !!profile[profileProperty as keyof typeof profile];
  });
};

export default isAllowedProfile;
