import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import FrameworkStageOptionsWrapper from './FrameworkStageOptionsWrapper';
import FrameworkStageObjectives from './FrameworkStageObjectives';
import useStages from '../../../hooks/useStages';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { GeneralError, Loading } from '../../common';
import useFrameworkProgress from '../../../hooks/useFrameworkProgress';

function FrameworkStageObjectivesWrapper({
  frameworkId,
  frameworkVersionId,
  learnerId,
  isEditable
}: {
  frameworkId: number;
  frameworkVersionId: number;
  learnerId: number;
  isEditable: boolean;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const [stageId, setStageId] = useState<number | null>();

  const { stagesQuery } = useStages({
    frameworkId,
    frameworkVersionId,
    displayLocale
  });

  const handleStageChange = (value: number) => {
    setStageId(value);
  };

  const { currentFrameworkStageQuery } = useFrameworkProgress({
    frameworkVersionId,
    learnerId,
    displayLocale
  });

  useEffect(() => {
    if (currentFrameworkStageQuery.data) {
      setStageId(currentFrameworkStageQuery.data.stageId);
    }
  }, [currentFrameworkStageQuery.data]);

  if (!stagesQuery.data) {
    return (
      <GeneralError
        message={
          <FormattedMessage
            id="course.not_loaded"
            defaultMessage="Could not load course"
          />
        }
      />
    );
  }
  if (stagesQuery.data) {
    return (
      <div className="card border-0 rounded-top-right-lg mb-2 mt-2">
        <div className="card-header rounded-top-right-lg">
          <h2>
            <FormattedMessage
              id="objectives"
              defaultMessage="Objectives"
              description="Objectives"
            />
          </h2>
        </div>
        <div className="card-body" data-stage-id={stageId}>
          {!!frameworkId && !!frameworkVersionId && !!learnerId && (
            <FrameworkStageOptionsWrapper
              frameworkId={frameworkId}
              frameworkVersionId={frameworkVersionId}
              learnerId={learnerId}
              onChange={handleStageChange}
            />
          )}

          {!!frameworkId &&
            !!frameworkVersionId &&
            !!profile?.userId &&
            !!stageId && (
              <FrameworkStageObjectives
                frameworkVersionId={frameworkVersionId}
                stageId={stageId}
                userId={learnerId || profile?.userId}
                isEditable={isEditable}
              />
            )}
        </div>
      </div>
    );
  }
  return <Loading />;
}
export default FrameworkStageObjectivesWrapper;
