import React from 'react';
import { FieldErrorsImpl } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import PasswordCharacterValidationRequirements from './PasswordCharacterValidationRequirements';

function PasswordErrorSummary({
  errors,
  errorSummary,
  summaryHeader
}: {
  errors: Partial<
    FieldErrorsImpl<{
      password: string;
      passwordConfirm: string;
      username: string;
      currentPassword: string;
    }>
  >;
  errorSummary: React.RefObject<HTMLDivElement>;
  summaryHeader: any;
}) {
  return (
    <div
      className="error-summary alert alert-danger bg-white"
      ref={errorSummary}
      tabIndex={-1}
      role="group"
      aria-labelledby="error-summary-title"
      hidden
    >
      <h2 id="error-summary-title">{summaryHeader}</h2>
      <ol>
        {errors.password?.type === 'required' && (
          <li>
            <a href="#password">
              <FormattedMessage
                id="form.password.required"
                defaultMessage="Password is required"
                description="Password is required"
              />
            </a>
          </li>
        )}
        {errors.password?.type === 'minLength' && (
          <li>
            <a href="#password">
              <FormattedMessage
                id="form.password.error.minLength"
                defaultMessage="Password should be eight or more characters long"
                description="Password should be eight or more characters long"
              />
            </a>
          </li>
        )}

        {errors.password?.type === 'hasNoSpaces' && (
          <li>
            <a href="#password">
              <FormattedMessage
                id="form.password.error.hasNoSpaces"
                defaultMessage="Password should contain no spaces"
                description="Password should contain no spaces"
              />
            </a>
          </li>
        )}

        {errors.password?.type === 'passesCharacterValidationRules' && (
          <li>
            <a href="#password">
              <FormattedMessage
                id="form.label.password.error.characters"
                defaultMessage="Password should contain characters from three of the following
                  four categories:"
              />

              <PasswordCharacterValidationRequirements />
            </a>
          </li>
        )}
        {errors.passwordConfirm?.type === 'required' && (
          <li>
            <a href="#password-confirm">
              <FormattedMessage
                id="form.password.confirm.required"
                defaultMessage="Confirm password is required"
                description="Confirm password is required"
              />
            </a>
          </li>
        )}
        {errors.passwordConfirm?.type === 'isEqual' && (
          <li>
            <a href="#password-confirm">
              <FormattedMessage
                id="form.password.isNotEqual"
                defaultMessage="Passwords must match"
                description="Passwords must match"
              />
            </a>
          </li>
        )}
      </ol>
    </div>
  );
}

export default PasswordErrorSummary;
