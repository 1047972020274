import { useIntl } from 'react-intl';
import React from 'react';
import dayjs from 'dayjs';

import generateSportPassportId from '../../utils/generateSportPassportId';
import { components } from '../../types/openapi/UserService';
import getFormattedUserType from '../../utils/getFormattedUserType';
import CentreAddress from '../../components/centres/CentreAddress';

const useTableColumns = ({
  providerId
}: {
  providerId?: number | null | undefined;
}) => {
  const intl = useIntl();

  const usernameColumn = {
    id: 'username',
    header: intl.formatMessage({
      id: 'name',
      defaultMessage: 'Name',
      description: 'Name'
    }),
    cell: ({ row }: { row: any }) => {
      const user = row.original;
      return <span data-gdpr="true">{user?.username}</span>;
    },
    accessorKey: 'username',
    filterType: 'basicInput'
  };

  const emailColumn = {
    id: 'email',
    header: intl.formatMessage({
      id: 'email',
      defaultMessage: 'Email',
      description: 'Email'
    }),
    cell: ({ row }: { row: any }) => {
      const user = row.original;
      if (!user.email) {
        return intl.formatMessage({
          id: 'email.none',
          defaultMessage: 'No email provided',
          description: 'No email provided'
        });
      }
      return <span data-gdpr="true">{user?.email}</span>;
    },
    accessorKey: 'email',
    filterType: 'basicInput'
  };

  const dobColumn = {
    id: 'dateOfBirth',
    header: intl.formatMessage({
      id: 'dateOfBirth',
      defaultMessage: 'Date of Birth',
      description: 'Date of Birth'
    }),
    cell: ({ row }: { row: any }) => {
      const user = row.original;
      if (user.dateOfBirth === '0001-01-01T00:00:00') {
        return intl.formatMessage({
          id: 'dob.none',
          defaultMessage: 'No Date of Birth Provided',
          description: 'No Date of Birth Provided'
        });
      }
      return (
        <span data-gdpr="true">
          {dayjs(user.dateOfBirth).format('DD/MM/YYYY')}
        </span>
      );
    },
    accessorKey: 'dateOfBirth',
    sortDescFirst: true,
    filterType: undefined
  };
  const sportPassportColumn = {
    id: 'sportPassportId',
    header: intl.formatMessage({
      id: 'user.sportPassportId',
      defaultMessage: 'Sport Passport Id',
      description: 'Sport Passport Id'
    }),
    cell: ({ row }: { row: any }) => {
      const user = row.original;
      if (!user.sportPassportId) {
        return intl.formatMessage({
          id: 'id.sport_passport.none',
          defaultMessage: 'No Sport Passport Id provided',
          description: 'No Sport Passport Id provided'
        });
      }
      return generateSportPassportId(user.sportPassportId);
    },
    accessorKey: 'sportPassportId',
    filterType: 'sportPassportIdInput',
    enableSorting: false
  };

  const userExternalIdColumn = {
    id: 'externalId',
    header: intl.formatMessage({
      id: 'user.externalId',
      defaultMessage: 'External Id',
      description: 'External Id'
    }),
    cell: ({ row }: { row: any }) => {
      const user = row.original;
      // Get first userPermission for the provider that has an external ID (will be same for other permissions of provider)
      const userPermission = user.userProviders.find(
        (userProvider: components['schemas']['UserProvidersDto']) =>
          userProvider.providerId === providerId && userProvider.externalId
      );
      if (!userPermission || !userPermission.externalId) {
        return <div className="text-center w-50">&ndash;</div>;
      }
      return userPermission.externalId;
    },
    accessorKey: 'externalId',
    filterType: 'basicInput',
    enableSorting: false
  };

  const userTypeColumn = {
    id: 'userType',
    header: intl.formatMessage({
      id: 'userType',
      defaultMessage: 'User Type',
      description: 'User Type'
    }),
    cell: ({ row }: { row: any }) => {
      const user: components['schemas']['UserDto'] = row.original;
      if (user.userProviders) {
        const userTypesArr = user.userProviders.map(
          (up: any) => up.userTypeId ?? ''
        );
        const uniqueUserTypes = [...new Set(userTypesArr)];
        return `${uniqueUserTypes
          .map((userTypeId) => getFormattedUserType(userTypeId))
          .join(', ')}`;
      }
      return <div className="text-center w-50">&ndash;</div>;
    },
    accessorKey: 'userType',
    enableSorting: false,
    filterType: 'dropdown'
  };

  const centreColumn = {
    id: 'centre',
    header: intl.formatMessage({
      id: 'centre',
      defaultMessage: 'Centre',
      description: 'Centre'
    }),
    cell: ({ row }: { row: any }) => {
      const user = row.original;
      return `${user?.userProviders
        .map((up: any) => up.centre?.name ?? '')
        .filter((up: any) => !!up)
        .join(', ')}`;
    },
    accessorKey: 'centre',
    enableSorting: false,
    filterType: 'dropdown'
  };

  const centreNameColumn = {
    id: 'centreName',
    header: intl.formatMessage({
      id: 'name',
      defaultMessage: 'Name',
      description: 'Name'
    }),
    cell: ({ row }: { row: any }) => {
      const centre = row.original;
      return `${centre?.name}`;
    },
    accessorKey: 'centreName',
    filterType: 'basicInput'
  };

  const centreAddressColumn = {
    id: 'address',
    header: intl.formatMessage({
      id: 'address',
      defaultMessage: 'Address',
      description: 'Address'
    }),
    cell: ({ row }: { row: any }) => {
      const centre = row.original;
      if (centre) {
        return <CentreAddress address={centre.address} />;
      }
      return <span className="text-muted">&ndash;</span>;
    },
    filterType: undefined
  };

  const postcodeColumn = {
    id: 'postCode',
    header: intl.formatMessage({
      id: 'postcode',
      defaultMessage: 'Postcode',
      description: 'Postcode'
    }),
    cell: ({ row }: { row: any }) => {
      return `${row.original?.address?.postalCode}`;
    },
    accessorKey: 'postCode',
    filterType: 'basicInput'
  };

  const centreExternalIdColumn = {
    id: 'externalId',
    header: intl.formatMessage({
      id: 'externalId',
      defaultMessage: 'External Id',
      description: 'External Id'
    }),
    cell: ({ row }: { row: any }) => {
      const centre = row.original;
      if (centre?.externalId) {
        return `${centre?.externalId}`;
      }
      return intl.formatMessage({
        id: 'externalId.none',
        defaultMessage: 'No External Id provided',
        description: 'No External Id provided'
      });
    },
    accessorKey: 'externalId',
    enableSorting: false,
    filterType: 'basicInput'
  };

  const courseNameColumn = {
    id: 'courseName',
    header: intl.formatMessage({
      id: 'name',
      defaultMessage: 'Name',
      description: 'Name'
    }),
    cell: ({ row }: { row: any }) => {
      const course = row.original;
      return `${course?.name}`;
    },
    accessorKey: 'name',
    sortDescFirst: true,
    filterType: 'basicInput'
  };

  const frameworkColumn = {
    id: 'framework',
    header: intl.formatMessage({
      id: 'framework',
      defaultMessage: 'Framework',
      description: 'Framework'
    }),
    cell: ({ row }: { row: any }) => {
      const course = row.original;
      return `${course?.frameworkVersion?.framework?.name}`;
    },
    accessorKey: 'framework',
    filterType: 'dropdown'
  };

  const stageColumn = {
    id: 'stage',
    header: intl.formatMessage({
      id: 'stage.plural_in_brackets',
      defaultMessage: 'Stage(s)',
      description: 'Stage(s)'
    }),
    cell: ({ row }: { row: any }) => {
      const course = row.original;
      const stageList = course?.courseStages.flatMap((a: any) =>
        a.stage.name != null ? a.stage.name : []
      );
      const formattedList = stageList.join(', ');
      return `${formattedList}`;
    },
    filterType: undefined
  };

  const courseStartDateColumn = {
    id: 'courseStartDate',
    header: intl.formatMessage({
      id: 'startDate',
      defaultMessage: 'Start Date',
      description: 'Start Date'
    }),
    cell: ({ row }: { row: any }) => {
      const startSession = row.original
        .courseSessions[0] as components['schemas']['CourseSessionDto'];
      if (startSession) {
        const sessionStartTime = dayjs(startSession.sessionStartTime);
        return <span>{sessionStartTime.format('DD/MM/YYYY')}</span>;
      }
      return <span className="text-muted">&ndash;</span>;
    },
    accessorKey: 'startDate',
    filterType: undefined
  };

  const courseEndDateColumn = {
    id: 'courseEndDate',
    header: intl.formatMessage({
      id: 'endDate',
      defaultMessage: 'End Date',
      description: 'End Date'
    }),
    cell: ({ row }: { row: any }) => {
      const endSession = row.original.courseSessions[
        row.original.courseSessions.length - 1
      ] as components['schemas']['CourseSessionDto'];
      if (endSession) {
        const sessionStartTime = dayjs(endSession.sessionStartTime);
        return <span>{sessionStartTime.format('DD/MM/YYYY')}</span>;
      }
      return <span className="text-muted">&ndash;</span>;
    },
    accessorKey: 'endDate',
    filterType: undefined
  };

  const schoolsColumn = {
    id: 'school',
    header: intl.formatMessage({
      id: 'school',
      defaultMessage: 'School',
      description: 'School'
    }),
    cell: ({ row }: { row: any }) => {
      const course = row.original;
      return course.school
        ? `${course.school.name} - ${course.school.address?.postalCode || ''}`
        : '';
    },
    accessorKey: 'school.name',
    sortDescFirst: true,
    filterType: 'basicInput'
  };

  return {
    usernameColumn,
    sportPassportColumn,
    userExternalIdColumn,
    userTypeColumn,
    centreColumn,
    centreNameColumn,
    centreAddressColumn,
    postcodeColumn,
    centreExternalIdColumn,
    courseNameColumn,
    frameworkColumn,
    stageColumn,
    courseStartDateColumn,
    courseEndDateColumn,
    emailColumn,
    dobColumn,
    schoolsColumn
  };
};

export default useTableColumns;
