import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import parse from 'html-react-parser';

function ViewOnlyNoteListItem({
  noteText,
  noteId,
  instructorName
}: {
  noteText: string;
  noteId: number;
  instructorName: string | null | undefined;
}) {
  return (
    <li key={noteId} className="mb-3 d-flex flex-column notes-list__item">
      <div className="d-flex mb-3">
        <FontAwesomeIcon icon={faCommentAlt} className="notes-list__icon" />
        <span className="fw-bold mt-2">{instructorName}</span>
      </div>
      <div>{parse(noteText)}</div>
    </li>
  );
}

export default ViewOnlyNoteListItem;
