import dayjs from 'dayjs';

export default function getFormattedDateOfBirth(
  usersDateOfBirth: string
): string {
  const usersDateOfBirthConverted = dayjs(usersDateOfBirth);

  const months = usersDateOfBirthConverted.diff(Date.now(), 'month');

  const years = usersDateOfBirthConverted.diff(Date.now(), 'year');

  const dob =
    Math.abs(months) <= 36
      ? `${Math.abs(months)} months`
      : `${Math.abs(years)} years`;
  return dob;
}
