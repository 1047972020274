/* eslint-disable no-inner-declarations */
import React, { useContext, useEffect, useState } from 'react';
import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useLearnersForInstructors from '../../hooks/useLearnersForInstructors';
import LearnersList from './LearnersList';
import LearnersListLink from './LearnerListActions';
import { components } from '../../types/openapi/UserService';
import { FilteringQueryParams } from '../../const/filtering-query-params';

function LearnersListInstructorOnline({
  profile
}: {
  profile: components['schemas']['UserProvidersDto'];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const queryParamsLocation = FilteringQueryParams.Learners;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(pagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(pagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(sorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(filtering)
    );
  }, [pagination, sorting, filtering]);

  const { learnersForInstructorListQuery } = useLearnersForInstructors({
    userId: profile?.userId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });

  return (
    <LearnersList
      data={learnersForInstructorListQuery.data}
      error={learnersForInstructorListQuery.error}
      isLoading={learnersForInstructorListQuery.isLoading}
      refetching={learnersForInstructorListQuery.isRefetching}
      pagination={pagination}
      sorting={sorting}
      filtering={filtering}
      setPagination={setPagination}
      setSorting={setSorting}
      setFiltering={setFiltering}
      queryParamsLocation={queryParamsLocation}
      renderActions={({ row }) => {
        return <LearnersListLink selectedLearner={row.original} />;
      }}
    />
  );
}

export default LearnersListInstructorOnline;
