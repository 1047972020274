import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import ErrorPage from '../pages/ErrorPage';
import DragonGame from '../components/dragon-game/DragonGame';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';

export default function gameRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.DragonGame],
    element: (
      <ProtectedRoute allowedRoles={[UserType.LEARNER]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="game.breadcrumb"
          defaultMessage="Game"
          description="Game"
        />
      )
    },
    children: [
      {
        index: true,
        element: <DragonGame />,
        errorElement: <ErrorPage />
      }
    ]
  };
  return routes;
}
