import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';

import MainContainer from '../components/layout/MainContainer';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import ErrorPage from '../pages/ErrorPage';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';

const ManageSchools = lazy(() => import('../components/schools/ManageSchools'));
const CreateSchoolAdmin = lazy(
  () => import('../components/schools/CreateSchoolAdmin')
);
const CreateSchool = lazy(
  () => import('../components/schools/create-school/CreateSchool')
);

const AddCentreToSchool = lazy(
  () => import('../components/schools/AddCentreToSchool')
);

const Step1 = lazy(() => import('../components/schools/create-school/Step1'));
const Step2 = lazy(() => import('../components/schools/create-school/Step2'));
const Step3 = lazy(() => import('../components/schools/create-school/Step3'));
const Success = lazy(
  () => import('../components/schools/create-school/Success')
);

const ViewSchool = lazy(() => import('../components/schools/ViewSchool'));
const EditSchool = lazy(() => import('../components/schools/EditSchool'));

export default function schoolsRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Schools],
    element: (
      <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}>
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="schools.breadcrumb"
          defaultMessage="Schools"
          description="Schools"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageSchools />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.CreateSchool],
        element: <CreateSchool />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="school.create"
              defaultMessage="Create School"
              description="Create School"
            />
          )
        },
        children: [
          {
            index: true,
            element: <Step1 />,
            errorElement: <ErrorPage />
          },
          {
            path: appStrings[langVal][AppRoute.Step2],
            element: <Step2 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="Step2"
                  defaultMessage="Step 2"
                  description="Step 2"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.Step3],
            element: <Step3 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage id="step3" defaultMessage="Step 3" />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.Success],
            element: <Success />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="success"
                  defaultMessage="Success"
                  description="Success"
                />
              )
            }
          }
        ]
      },
      {
        path: ':schoolId',
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="school"
              defaultMessage="School"
              description="School"
            />
          )
        },
        children: [
          {
            index: true,
            element: <ViewSchool />,
            errorElement: <ErrorPage />
          },
          {
            path: appStrings[langVal][AppRoute.EditSchool],
            element: <EditSchool />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="school.edit"
                  defaultMessage="Edit School"
                  description="Edit School"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.CreateSchoolAdmin]}`,
            element: <CreateSchoolAdmin />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="breadcrumb.admin.create"
                  defaultMessage="Create Admin"
                  description="Create Admin"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.AddCentreToSchool]}`,
            element: <AddCentreToSchool />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="school_centres.add"
                  defaultMessage="Add new centre"
                />
              )
            }
          }
        ]
      }
    ]
  };
  return routes;
}
