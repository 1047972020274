/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useCallback, useState } from 'react';
import { StorageId } from '../../const/storage-id';
import { components as userComponents } from '../../types/openapi/UserService';

interface MyProfileContext {
  profile: userComponents['schemas']['UserProvidersDto'] | null;
  setNewProfile: (
    profile: userComponents['schemas']['UserProvidersDto']
  ) => void;
  clearProfile: () => void;
}

export const ProfileContext = React.createContext<MyProfileContext>({
  profile: null,
  setNewProfile: () => {},
  clearProfile: () => {}
});

// provides methods for get, set and clear a user selected profile from localStorage
// will be saved between page refreshes
// will not transfer to a new tab or browser window
// so can open multiple profiles in separate tabs
// cannot use "open in new tab" and keep currnt profile
function ProfileProvider(props: { children: any }) {
  const { children } = props;

  const [profile, setProfile] = useState<
    userComponents['schemas']['UserProvidersDto'] | null
  >(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(StorageId.PROFILE);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : null;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return null;
    }
  });

  const setNewProfile = useCallback(
    (value: userComponents['schemas']['UserProvidersDto']) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(profile) : value;
        // Save state
        setProfile(valueToStore);
        // Save to local storage
        window.localStorage.setItem(StorageId.PROFILE, JSON.stringify(value));
      } catch (error) {
        // handle the error case
        console.error(error);
      }
    },
    []
  );

  const clearProfile = useCallback(() => {
    setProfile(() => null);
    localStorage.removeItem(StorageId.PROFILE);
  }, []);

  return (
    <ProfileContext.Provider value={{ setNewProfile, profile, clearProfile }}>
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
