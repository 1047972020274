import { useQuery } from '@tanstack/react-query';
import { getStageObjectives } from '../services/api/framework.service';
import objectivesKeys from '../query-keys/objectives-key-factory';

// hook for stages
const useStageObjectives = ({
  frameworkId,
  frameworkVersionId,
  stageId,
  displayLocale
}: {
  frameworkId: number;
  frameworkVersionId: number;
  stageId: number;
  displayLocale: string | undefined;
}) => {
  const stageObjectivesQuery = useQuery({
    queryKey: objectivesKeys.listForStage(
      frameworkId,
      frameworkVersionId,
      stageId,
      displayLocale
    ),
    queryFn: () =>
      getStageObjectives(
        frameworkId,
        frameworkVersionId,
        stageId,
        displayLocale
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!frameworkId && !!frameworkVersionId
  });

  const objectivesCount = stageObjectivesQuery.data?.length || 0;

  return {
    stageObjectivesQuery,
    objectivesCount
  };
};

export default useStageObjectives;
