/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { ScheduleSeriesFormValues } from './ScheduleSeriesFormValues.type';
import { isValidTimeInput } from '../../../utils/date-time';

function ScheduleSingleSessionForm({
  onHandleSubmit
}: {
  onHandleSubmit: (
    values: Pick<ScheduleSeriesFormValues, 'startDate' | 'times'>
  ) => void;
}) {
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, isDirty, isValid }
  } = useForm<Pick<ScheduleSeriesFormValues, 'startDate' | 'times'>>({
    mode: 'onBlur',
    defaultValues: {
      times: {
        startTime: '',
        endTime: ''
      },
      startDate: undefined
    }
  });

  const isBefore = (startTime: string) => {
    const startTimeDayJS = dayjs(startTime, 'HH:mm');
    const endTime = getValues('times.endTime');
    const endTimeDayJS = dayjs(endTime, 'HH:mm');
    return startTimeDayJS.isBefore(endTimeDayJS, 'minutes');
  };

  const onSubmit = () => {
    const values = getValues();
    onHandleSubmit(values);
  };

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
      <table className="table card-list-table">
        <tbody>
          <tr>
            <td className="w-25">
              <input
                id="starting-date"
                type="date"
                className="form-control"
                aria-invalid={errors.startDate ? 'true' : 'false'}
                {...register('startDate', { required: true })}
              />
            </td>
            <td className="w-25">
              <input
                type="time"
                id="start-time"
                className="form-control"
                aria-invalid={errors.times?.startTime ? 'true' : 'false'}
                {...register('times.startTime', {
                  required: true,
                  validate: {
                    isValid: (v) => isValidTimeInput(v),
                    isBefore: (v) => isBefore(v)
                  }
                })}
              />
            </td>
            <td className="w-25">
              <input
                type="time"
                id="end-time"
                className="form-control"
                aria-invalid={errors.times?.endTime ? 'true' : 'false'}
                {...register('times.endTime', {
                  onChange: () => {
                    trigger('times.startTime');
                  },
                  required: true,
                  validate: {
                    isValid: (v) => isValidTimeInput(v)
                  }
                })}
              />
            </td>
            <td className="w-25">
              <div className="d-flex">
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!isDirty || !isValid}
                  >
                    <FormattedMessage
                      id="course.schedule.session.add"
                      defaultMessage="Add"
                      description="Add"
                    />
                  </button>
                </div>

                {errors.times?.startTime?.type === 'isBefore' && (
                  <div className="ps-2">
                    <p className="invalid-feedback d-inline" role="alert">
                      <FormattedMessage
                        id="form.endTime.isAfter"
                        defaultMessage="End time must be after start time"
                        description="End time must be after start time"
                      />
                    </p>
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}

export default ScheduleSingleSessionForm;
