import React, { useRef, useEffect } from 'react';

function IndeterminateCheckbox({
  label,
  indeterminate = false,
  ref,
  checked,
  disabled,
  onChange,
  id,
  className
}: {
  label: string | number;
  checked: boolean;
  onChange: any;
  id: string;
  disabled?: boolean;
  ref?: any;
  indeterminate?: boolean;
  className?: string;
}) {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="sp-checkbox-wrapper mx-auto">
      <label className="sp-checkbox" htmlFor={id}>
        <input
          ref={resolvedRef}
          className={`sp-checkbox__trigger visually-hidden ${className}`}
          type="checkbox"
          value=""
          {...{ id, indeterminate, onChange, disabled, checked }}
        />
        <span className="sp-checkbox__symbol">
          <svg
            aria-hidden="true"
            className="icon-checkbox"
            width="28px"
            height="28px"
            viewBox="0 0 28 28"
            version="1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 14l8 7L24 7" />
          </svg>
        </span>
        <p className="sp-checkbox__textwrapper visually-hidden"> {label}</p>
      </label>
    </div>
  );
}

export default IndeterminateCheckbox;

IndeterminateCheckbox.defaultProps = {
  disabled: false,
  indeterminate: false,
  ref: '',
  className: ''
};
