import { AppLanguage, AppRoute } from '../../../const';
import { UserType } from '../../../const/user-type';
import { appStrings } from '../../../modules/i18n';
import { components } from '../../../types/openapi/UserService';
import { SPCalendarEvent } from '../SPCalendarEvent.type';

const getCourseLinkUrl = (
  event: SPCalendarEvent | undefined,
  profile: components['schemas']['UserProvidersDto'] | null | undefined,
  displayLocale: AppLanguage | string | undefined
) => {
  if (profile && event && displayLocale) {
    if (profile.userTypeId === UserType.ADMIN) {
      return `/${displayLocale?.toLowerCase()}/${
        appStrings[displayLocale as AppLanguage][AppRoute.Courses]
      }/${(event as any).session.courseId}`;
    }
  }
  throw Error('could not create link, missing event profile or locale');
};

export default getCourseLinkUrl;
