import React, { useContext } from 'react';
import { Row } from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { faBan, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';

import { components } from '../../../../../../types/openapi/UserService';
import { ModalContext } from '../../../../../../modules/modal/ModalProvider';
import useNote from '../../../../../../hooks/useNote';
import { GeneralError } from '../../../../../common';
import NotesModalContent from '../../../../../notes/NotesModalContent';

function LearnerNotesCell({
  row
}: {
  row: Row<components['schemas']['UserDto']>;
}) {
  const modalCtx = useContext(ModalContext);
  const { modal, closeModal } = modalCtx;
  const intl = useIntl();
  const { courseId, sessionId } = useParams();
  if (!row.original.userId) {
    <GeneralError />;
  }

  const { notesListQuery } = useNote({
    courseId: Number(courseId),
    sessionId: Number(sessionId),
    userId: row.original.userId!
  });

  function openModal(learner: components['schemas']['UserDto']) {
    const header = intl.formatMessage(
      {
        id: 'learner.colon.name',
        defaultMessage: 'Learner: {name}',
        description: 'Learner: {name}'
      },
      { name: learner.username }
    );

    const modalContent = (
      <NotesModalContent
        courseId={Number(courseId)}
        sessionId={Number(sessionId)}
        userId={Number(learner.userId)}
        closeModal={closeModal}
      />
    );

    modal(modalContent, {
      header,
      hideFooter: true,
      dialogClassName: 'modal-lg'
    });
  }
  const learner = (row as any).original as components['schemas']['UserDto'];
  // notes not allowed - user should never see this as should be disabled at provider config level
  if (notesListQuery.error?.response?.status === 403) {
    return (
      <button
        className="btn text-primary bg-white border-0"
        type="button"
        disabled
      >
        <FontAwesomeIcon icon={faBan} />
      </button>
    );
  }
  return (
    <button
      className="btn text-primary bg-white border-0 pt-1 notes-button"
      type="button"
      onClick={() => openModal(learner)}
      aria-label={intl.formatMessage(
        {
          id: 'aria-label.notes',
          defaultMessage: '{name} notes',
          description: 'Name notes'
        },
        { name: learner.fullname }
      )}
    >
      <span className="fa-stack fa-1x">
        <FontAwesomeIcon className="fa-stack-1x fs-2" icon={faMessage} />
        <strong className="fa-stack text-white">
          {notesListQuery.data ? notesListQuery.data.length : ''}
        </strong>
      </span>
    </button>
  );
}

export default LearnerNotesCell;
