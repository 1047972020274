/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import useProviderConfig from '../../../hooks/useProviderConfig';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { components } from '../../../types/openapi/UserService';

type ContextType = {
  profile: components['schemas']['UserProvidersDto'] | null;
  learnerNonSSOLogin: boolean;
};

function CreateLearner() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  const { learnerNonSSOLogin } = useProviderConfig({
    providerId: Number(profile?.providerId)
  });

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.learner.create_new_learner',
            defaultMessage: 'Create new learner'
          })}
        </title>
      </Helmet>
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="header.learner.create_new_learner"
            defaultMessage="Create New Learner"
            description="Create New Learner"
          />
        </h2>
      </div>
      <div className="card-body">
        <Outlet context={{ profile, learnerNonSSOLogin }} />
      </div>
    </div>
  );
}
export default CreateLearner;

export function useProfile() {
  return useOutletContext<ContextType>();
}
