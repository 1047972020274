/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AppLanguage } from '../../const';
import frameworkKeys from '../../query-keys/framework-key-factory';
import {
  getFrameworkVersionForFramework,
  updateFrameworkVersion
} from '../../services/api/framework.service';
import { components } from '../../types/openapi/FrameworkService';
import { Subset } from '../../types/Subset.type';

// hook for managing frameworks
const useFrameworkVersion = ({
  frameworkId,
  frameworkVersionId,
  displayLocale = AppLanguage.English
}: {
  frameworkId: number;
  frameworkVersionId: number;
  displayLocale: string | undefined;
}) => {
  const queryClient = useQueryClient();

  const frameworkVersionQuery = useQuery({
    queryKey: frameworkKeys.version(
      frameworkId,
      frameworkVersionId,
      displayLocale
    ),
    queryFn: () =>
      getFrameworkVersionForFramework(
        frameworkId,
        frameworkVersionId,
        displayLocale
      ),
    refetchOnMount: true,
    enabled: !!frameworkId
  });

  const updateVersion = useMutation(
    (
      newFrameworkVersion: Subset<components['schemas']['FrameworkVersionDto']>
    ) => {
      // optimistic update...
      if (newFrameworkVersion) {
        queryClient.setQueryData(
          frameworkKeys.version(
            frameworkId,
            frameworkVersionId,

            displayLocale
          ),
          { ...newFrameworkVersion }
        );
      }

      return updateFrameworkVersion(
        frameworkId,
        frameworkVersionId,
        newFrameworkVersion,
        displayLocale
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: frameworkKeys.version(
            frameworkId,
            frameworkVersionId,
            displayLocale
          )
        });
      },

      onError: (_, oldVersion) => {
        // if something goes wrong then reset to the previous data
        console.error(oldVersion);

        if (oldVersion) {
          queryClient.setQueryData(
            frameworkKeys.version(
              frameworkId,
              frameworkVersionId,

              displayLocale
            ),
            { ...oldVersion }
          );
        }
      }
    }
  );

  return {
    frameworkVersionQuery,
    updateVersion
  };
};

export default useFrameworkVersion;
