import React, { useState } from 'react';
import { useIntersectionObserver } from '@uidotdev/usehooks';

function Menu(props: JSX.IntrinsicElements['div']) {
  const { style, className } = props;
  const [ref, entry] = useIntersectionObserver({
    threshold: 1,
    root: null,
    rootMargin: '0px'
  });
  const [right, setRight] = useState<string | number | undefined>();

  // If the dropdown is going off screen, try the other orientation
  if (entry?.isIntersecting === false) {
    if (!right) {
      setRight('0px');
    }
  }

  const defaultStyle = { zIndex: 2, right };
  const defaultClassName = 'bg-white rounded shadow';

  return (
    <div
      data-id="react-select-menu"
      ref={ref as any}
      className={className || defaultClassName}
      style={style || defaultStyle}
      {...props}
    />
  );
}

export default Menu;
