import { useQuery } from '@tanstack/react-query';
import providerKeys from '../../query-keys/provider-key-factory';
import { getProvidersList } from '../../services/api/provider.service';
import { FilteringObject } from '../../const/filtering-object';

const useProviders = ({
  displayLocale,
  pageIndex,
  pageSize,
  sorting,
  rawFiltering
}: {
  displayLocale: string | undefined;
  pageIndex?: number | null | undefined;
  pageSize?: number | null | undefined;
  sorting?: any | null | undefined;
  rawFiltering?: any | null | undefined;
}) => {
  const filtering: FilteringObject = {};
  if (rawFiltering) {
    rawFiltering.forEach((filter: any) => {
      filtering[filter.id] = filter.value;
    });
  }

  const providersListQuery = useQuery({
    queryKey: providerKeys.list(
      displayLocale,
      pageIndex,
      pageSize,
      sorting,
      filtering
    ),
    queryFn: () =>
      getProvidersList(
        displayLocale,
        pageIndex,
        pageSize,
        sorting,
        rawFiltering
      ),
    refetchOnMount: true
  });

  return {
    providersListQuery
  };
};

export default useProviders;
