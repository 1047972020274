// import dayjs from 'dayjs';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppLanguage, AppRoute } from '../../const';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import AppMode from '../../modules/offline/app-mode.enum';
import { OfflineContext } from '../../modules/offline/OfflineProvider';
import sessionKeys from '../../query-keys/session-key-factory';
import { components } from '../../types/openapi/CourseService';
import { convertDateTime, TIMEZONE } from '../../utils/date-time';
import { GeneralError } from '../common';
import QueryStatus from './download-statuses/QueryStatus';

function InstructorAgenda({
  sessions
}: {
  sessions: components['schemas']['CourseSessionDto'][];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;

  const getOnlineViewLink = (
    session: components['schemas']['CourseSessionDto']
  ) =>
    `/${displayLocale?.toLowerCase()}/${
      appStrings[displayLocale as AppLanguage][AppRoute.Courses]
    }/${session.courseId}/${
      appStrings[displayLocale as AppLanguage][AppRoute.ViewSession]
    }/${session.courseSessionId}`;

  const getOfflineViewLink = (
    session: components['schemas']['CourseSessionDto']
  ) =>
    `/${displayLocale?.toLowerCase()}/${
      appStrings[displayLocale as AppLanguage][AppRoute.OfflineSession]
    }/${session.courseSessionId}`;

  const queryClient = useQueryClient();

  return (
    <div>
      <table className="border w-100">
        <thead className="border-bottom border-2">
          <th className="p-2">
            <FormattedMessage id="time" defaultMessage="Time" />
          </th>
          <th className="p-2">
            <FormattedMessage id="session" defaultMessage="Session" />
          </th>
        </thead>
        <tbody>
          {sessions.map((session) => {
            if (!session.courseSessionId) return <GeneralError />;
            const displayStartTime = convertDateTime(session.sessionStartTime);
            const startTimeAsDate = displayStartTime.toDate();
            const displayEndTime = convertDateTime(session.sessionEndTime);
            const endTimeAsDate = displayEndTime.toDate();

            const queryKey = sessionKeys.offlineData(
              session.courseSessionId,
              displayLocale
            );

            const isQueryFetching = useIsFetching(queryKey);

            const queryData = queryClient.getQueryData(queryKey);
            const isLinkDisabled =
              appMode === AppMode.OFFLINE && (!!isQueryFetching || !queryData);
            return (
              <tr className="border-bottom">
                <td className="p-2 align-top">
                  <FormattedTime value={startTimeAsDate} timeZone={TIMEZONE} />
                  &mdash;
                  <FormattedTime value={endTimeAsDate} timeZone={TIMEZONE} />
                </td>
                <td className="border-start p-2">
                  <div className="d-flex flex-column align-items-start">
                    <span className="fw-bold fs-2 mb-3">
                      {session.course?.name}
                    </span>
                    {session.courseSessionId && (
                      <ul className="list-unstyled">
                        <QueryStatus
                          queryKey={queryKey}
                          detailsText={
                            <FormattedMessage
                              id="session"
                              defaultMessage="Session"
                            />
                          }
                        />
                      </ul>
                    )}

                    <div className="btn-group">
                      {}
                      <Link
                        className={`btn btn-secondary ${
                          isLinkDisabled ? 'disabled' : ''
                        }`}
                        style={{
                          pointerEvents: isLinkDisabled ? 'none' : 'auto'
                        }}
                        aria-disabled={isLinkDisabled}
                        to={
                          appMode === AppMode.OFFLINE
                            ? getOfflineViewLink(session)
                            : getOnlineViewLink(session)
                        }
                      >
                        <FormattedMessage id="view" defaultMessage="View" />
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default InstructorAgenda;
