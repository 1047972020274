import React from 'react';
import { GroupBase } from 'react-select';
import ObjectiveOption from './ObjectiveOption.interface';

function SelectGroupLabel({ group }: { group: GroupBase<ObjectiveOption> }) {
  return (
    <div className="d-flex">
      <span className="flex-grow-1">{group.label}</span>
      <span className="badge bg-light text-dark">{group.options.length}</span>
    </div>
  );
}

export default SelectGroupLabel;
