/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-globals */
import React, { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { components } from '../../../types/openapi/UserService';
import { Gender } from '../../../const/gender';
import GenderTranslationIds from '../../../modules/i18n/gender/gender-translation-ids';
import Asterisk from '../../Asterisk';
import InlineFormError from '../../ui/InlineFormError';

interface Props {
  onSubmit: (
    value: Pick<components['schemas']['UserDto'], 'email' | 'telephone'>
  ) => void;
  submitButtonMessage: any;
  firstName?: string | null | undefined;
  surname?: string | null | undefined;
  dateOfBirth?: string | null | undefined;
  genderId?: number | null | undefined;
  email?: string | null | undefined;
  telephone?: string | null | undefined;
  emergencyContactName?: string | null | undefined;
  emergencyPhone1?: string | null | undefined;
  emergencyPhone2?: string | null | undefined;
  medicalConditions?: string | null | undefined;
  formDisabled?: boolean;
  hasEmergencyContactDetails?: boolean;
  renderButtons?: ({
    formDisabled
  }: {
    formDisabled: boolean;
  }) => JSX.Element | ReactNode;
  isEmailMandatory?: boolean;
}

function UserForm(props: Props) {
  const {
    formDisabled,
    submitButtonMessage,
    onSubmit,
    firstName,
    surname,
    dateOfBirth,
    genderId,
    email,
    telephone,
    emergencyContactName,
    emergencyPhone1,
    emergencyPhone2,
    medicalConditions,
    hasEmergencyContactDetails,
    renderButtons,
    isEmailMandatory
  } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstName,
      surname,
      dateOfBirth,
      genderId: genderId?.toString(),
      email,
      telephone,
      emergencyContactName,
      emergencyPhone1,
      emergencyPhone2,
      medicalConditions
    }
  });

  const handleChange = (e: any) => {
    setValue('genderId', e.target.value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };
  const intl = useIntl();

  return (
    <form
      className="d-flex flex-column"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <section>
        <div>
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              <Asterisk />
              <FormattedMessage
                id="form.first-name"
                defaultMessage="First Name"
                description="firstName"
              />
            </label>
            <input
              id="firstName"
              type="text"
              className="form-control"
              aria-invalid={errors.firstName ? 'true' : 'false'}
              {...register('firstName', { required: true })}
            />
            {errors.firstName?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.first-name.required"
                    defaultMessage="First Name is required"
                    description="First Name is required"
                  />
                }
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="surname" className="form-label  ">
              <Asterisk />
              <FormattedMessage
                id="form.surname"
                defaultMessage="Surname"
                description="Surname"
              />
            </label>
            <input
              id="surname"
              type="text"
              className="form-control"
              aria-invalid={errors.surname ? 'true' : 'false'}
              {...register('surname', { required: true })}
            />
            {errors.surname?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.surname.required"
                    defaultMessage="Surname is required"
                    description="Surname is required"
                  />
                }
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="dateOfBirth" className="form-label  ">
              <Asterisk />
              <FormattedMessage
                id="form.dateOfBirth"
                defaultMessage="Date of Birth"
                description="Date of Birth"
              />
            </label>
            <input
              id="dateOfBirth"
              type="date"
              className="form-control w-auto"
              aria-invalid={errors.dateOfBirth ? 'true' : 'false'}
              {...register('dateOfBirth', { required: true })}
            />
            {errors.dateOfBirth?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.dob.required"
                    defaultMessage="Date of Birth is required"
                    description="Date of Birth is required"
                  />
                }
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="gender" className="form-label  ">
              <Asterisk />
              <FormattedMessage
                id="form.gender"
                defaultMessage="Gender"
                description="Gender"
              />
            </label>
            <select
              className="form-control w-auto"
              {...register('genderId', { required: true })}
              onChange={handleChange}
              id="gender"
              aria-invalid={errors.genderId ? 'true' : 'false'}
            >
              <option value="" disabled selected hidden>
                <FormattedMessage
                  id="form.gender.select"
                  defaultMessage="Select a gender"
                  description="Select a gender"
                />
              </option>
              {Object.keys(Gender)
                .filter((v) => !isNaN(Number(v)))
                .map((key) => {
                  return (
                    <option value={key}>
                      {intl.formatMessage({
                        id: GenderTranslationIds[Number(key) as Gender]
                      })}
                    </option>
                  );
                })}
            </select>
            {errors.genderId?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.gender.required"
                    defaultMessage="Gender is required"
                    description="Gender is required"
                  />
                }
              />
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label ">
              {isEmailMandatory && <Asterisk />}
              <FormattedMessage
                id="form.email"
                defaultMessage="Email"
                description="Email"
              />
            </label>
            <input
              id="email"
              type="email"
              className="form-control"
              autoComplete="off"
              aria-invalid={errors.email ? 'true' : 'false'}
              {...register('email', {
                required: isEmailMandatory,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: intl.formatMessage({
                    id: 'email.error.invalid_format',
                    defaultMessage: 'Please enter a valid email'
                  })
                }
              })}
            />
            {errors.email?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.email.required"
                    defaultMessage="Email is required"
                    description="Email is required"
                  />
                }
              />
            )}
            {errors.email && errors.email.message && (
              <InlineFormError message={errors.email.message} />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="telephone" className="form-label">
              <FormattedMessage
                id="form.telephone"
                defaultMessage="Phone Number"
                description="Phone number"
              />{' '}
            </label>
            <input
              id="telephone"
              type="tel"
              className="form-control"
              aria-invalid={errors.telephone ? 'true' : 'false'}
              {...register('telephone', { required: false })}
            />
          </div>
        </div>
      </section>

      {hasEmergencyContactDetails && (
        <section>
          <div>
            <h3>
              {' '}
              <FormattedMessage
                id="form.emergency-details"
                defaultMessage="Emergency Contact Details"
                description="Emergency Contact Details"
              />
            </h3>
            <div className="mb-3">
              <label htmlFor="emergencyContactName" className="form-label ">
                <FormattedMessage
                  id="form.emergency-name"
                  defaultMessage="Name"
                  description="Name"
                />
                <input
                  id="emergencyContactName"
                  type="text"
                  className="form-control w-auto"
                  aria-invalid={errors.emergencyContactName ? 'true' : 'false'}
                  {...register('emergencyContactName', { required: false })}
                />
              </label>
            </div>
            <div className="mb-3">
              <label htmlFor="emergencyPhone1" className="form-label ">
                <FormattedMessage
                  id="form.emergency-phone1"
                  defaultMessage="Phone number 1"
                  description="Phone number 1"
                />
                <input
                  id="emergencyPhone1"
                  type="text"
                  className="form-control w-auto"
                  aria-invalid={errors.emergencyPhone1 ? 'true' : 'false'}
                  {...register('emergencyPhone1', { required: false })}
                />
              </label>
            </div>
            <div className="mb-3">
              <label htmlFor="emergencyPhone2" className="form-label ">
                <FormattedMessage
                  id="form.emergency-phone2"
                  defaultMessage="Phone number 2"
                  description="Phone number 2"
                />

                <input
                  id="emergencyPhone2"
                  type="text"
                  className="form-control w-auto"
                  aria-invalid={errors.emergencyPhone2 ? 'true' : 'false'}
                  {...register('emergencyPhone2', { required: false })}
                />
              </label>
            </div>
          </div>
          <div>
            <h3>
              {' '}
              <FormattedMessage
                id="form.other-details"
                defaultMessage="Other Details"
                description="Other Details"
              />
            </h3>
            <div className="mb-3">
              <label htmlFor="medicalConditions" className="form-label ">
                <FormattedMessage
                  id="form.medical-details"
                  defaultMessage="Medical Details"
                  description="Medical Details"
                />

                <textarea
                  id="medicalConditions"
                  className="form-control w-auto"
                  aria-invalid={errors.medicalConditions ? 'true' : 'false'}
                  {...register('medicalConditions', { required: false })}
                />
              </label>
            </div>
          </div>
        </section>
      )}

      {/* ovverride the default submit button eg Back/cancel */}
      {(renderButtons && renderButtons({ formDisabled: !!formDisabled })) || (
        <button
          type="submit"
          className="btn btn-primary me-auto"
          disabled={formDisabled}
        >
          {submitButtonMessage}
        </button>
      )}
    </form>
  );
}

export default UserForm;

UserForm.defaultProps = {
  firstName: '',
  surname: '',
  dateOfBirth: '',
  email: '',
  genderId: undefined,
  telephone: '',
  emergencyContactName: '',
  emergencyPhone1: '',
  emergencyPhone2: '',
  medicalConditions: '',
  formDisabled: false,
  hasEmergencyContactDetails: false,
  renderButtons: () => {},
  isEmailMandatory: false
};
