/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { PaginationState } from '@tanstack/react-table';

import { components } from '../../types/openapi/ProviderService';
import { components as userComponents } from '../../types/openapi/UserService';
import Asterisk from '../Asterisk';
import SelectableUsersTable from '../tables/SelectableUsersTable';
import useLearners from '../../hooks/useLearners';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function GroupForm({
  onSubmit,
  submitButtonMessage,
  name,
  description,
  formDisabled,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation,
  providerGroupAdmins,
  isEdit,
  helpText
}: {
  onSubmit: (
    value: Pick<
      components['schemas']['GroupDto'],
      'name' | 'description' | 'userGroupAdmins' | 'userGroupMembers'
    >
  ) => void;
  submitButtonMessage: any;
  name?: string | null;
  description?: string | null;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination?: any | undefined;
  setSorting?: any | undefined;
  setFiltering?: any | undefined;
  queryParamsLocation: string;
  providerGroupAdmins?: userComponents['schemas']['UserDto'][] | null;
  formDisabled?: boolean;
  isEdit?: boolean;
  helpText?: {
    name: string | undefined;
    description: string | undefined;
  };
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: name || '',
      description: description || '',
      groupAdmins: [],
      learners: []
    }
  });

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const TABLE_ID = 'create-group-learners-table';

  const { learnersListQuery } = useLearners({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    displayLocale,
    pageIndex: pagination?.pageIndex,
    pageSize: pagination?.pageSize,
    sorting,
    rawFiltering: filtering
  });

  const [selectedLearners, setSelectedLearners] = useState<
    components['schemas']['UserDto'][]
  >([]);

  const addLearner = (learner: components['schemas']['UserDto']) => {
    setSelectedLearners([...selectedLearners, learner]);
  };

  const removeLearner = (learner: components['schemas']['UserDto']) => {
    const updatedSelectedLearners = selectedLearners.filter(
      (selectedLearner) => selectedLearner.userId !== learner.userId
    );
    setSelectedLearners(updatedSelectedLearners);
  };

  const onGroupSubmit = (
    value: Pick<
      components['schemas']['GroupDto'],
      'name' | 'description' | 'userGroupAdmins'
    >
  ) => {
    const newGroup = { ...value, userGroupMembers: selectedLearners };
    onSubmit(newGroup);
  };

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit(onGroupSubmit)}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label w-100">
          <Asterisk />
          <FormattedMessage
            id="form.name"
            defaultMessage="Name"
            description="Name"
          />
          <input
            id="name"
            type="text"
            className="form-control"
            autoComplete="off"
            aria-invalid={errors.name ? 'true' : 'false'}
            {...register('name', { required: true })}
          />
        </label>
        {helpText && helpText.name && (
          <div id="nameHelpBlock" className="form-text">
            {helpText.name}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          <Asterisk />
          <FormattedMessage
            id="form.description"
            defaultMessage="Description"
            description="Description"
          />{' '}
        </label>
        <textarea
          id="description"
          className="form-control"
          aria-invalid={errors.description ? 'true' : 'false'}
          {...register('description', { required: true })}
        />
        {helpText && helpText.description && (
          <div id="descriptionHelpBlock" className="form-text">
            {helpText.description}
          </div>
        )}
      </div>
      {!isEdit && (
        <>
          <div className="mb-3">
            <label htmlFor="group-admin-select" className="form-label">
              <Asterisk />
              <FormattedMessage
                id="form.group.admin.select"
                defaultMessage="Select Group Admin(s)"
                description="Group Admin select"
              />{' '}
            </label>
            <Controller
              control={control}
              name="groupAdmins"
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Select
                  isMulti
                  inputId="group-admin-select"
                  placeholder={
                    <FormattedMessage
                      id="form.group.user.search"
                      defaultMessage="Search by name or passport id"
                      description="Search by name or passport id"
                    />
                  }
                  options={providerGroupAdmins!.map(
                    (groupAdmin: userComponents['schemas']['UserDto']) => {
                      return {
                        label: groupAdmin.username,
                        value: groupAdmin.userId
                      };
                    }
                  )}
                  onChange={onChange}
                  data-gdpr="true"
                />
              )}
            />
          </div>

          <div className="mb-3">
            <p className="form-label">
              <Asterisk />
              <FormattedMessage
                id="form.group.learner.select"
                defaultMessage="Select Group Learners"
                description="Learner select"
              />{' '}
            </p>
            <SelectableUsersTable
              query={learnersListQuery}
              preselectedIds={selectedLearners.map((learner) => learner.userId)}
              id={TABLE_ID}
              onRowSelect={(row) => {
                addLearner(row.original);
              }}
              onRowUnselect={(row) => {
                if (row.original.userId) {
                  removeLearner(row.original);
                }
              }}
              pagination={pagination}
              sorting={sorting}
              filtering={filtering}
              setPagination={setPagination}
              setSorting={setSorting}
              setFiltering={setFiltering}
              queryParamsLocation={queryParamsLocation}
              showColumns={{
                showFullname: false,
                showCentre: true,
                showDob: true,
                showEmail: false,
                showExternalId: true
              }}
            />
          </div>
        </>
      )}

      <button
        type="submit"
        className="btn btn-primary align-self-center"
        disabled={formDisabled || !isDirty || !isValid}
      >
        {submitButtonMessage}
      </button>
    </form>
  );
}

export default GroupForm;

GroupForm.defaultProps = {
  name: '',
  description: '',
  formDisabled: false,
  setPagination: undefined,
  setSorting: undefined,
  setFiltering: undefined,
  providerGroupAdmins: [],
  isEdit: false,
  helpText: {
    name: '',
    description: ''
  }
};
