import React, { useContext } from 'react';
import {
  faSchool,
  faFile,
  faAddressBook
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSchool from '../../../hooks/schools/useSchool';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { GeneralError, Loading } from '../../common';
import CentreAddress from '../../centres/CentreAddress';
import InfoPanel from '../../ui/InfoPanel';

function LearnerSchool({
  providerId,
  schoolId
}: {
  readonly providerId: number;
  readonly schoolId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { schoolQuery } = useSchool({ schoolId, providerId, displayLocale });
  return (
    <div className="card-body">
      {schoolQuery.isLoading ? (
        <Loading />
      ) : schoolQuery.error ? (
        <GeneralError />
      ) : (
        schoolQuery.data && (
          <div className="d-flex gap-3">
            <InfoPanel faIcon={<FontAwesomeIcon icon={faSchool} />}>
              {schoolQuery.data.name}
            </InfoPanel>
            <InfoPanel faIcon={<FontAwesomeIcon icon={faFile} />}>
              {schoolQuery.data.description}
            </InfoPanel>
            <InfoPanel faIcon={<FontAwesomeIcon icon={faAddressBook} />}>
              <CentreAddress address={schoolQuery.data.address} />
            </InfoPanel>
          </div>
        )
      )}
    </div>
  );
}

export default LearnerSchool;
