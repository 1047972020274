import React from 'react';

function Blanket(props: JSX.IntrinsicElements['div']) {
  return (
    <div
      className="position-fixed"
      style={{ zIndex: 1, bottom: 0, left: 0, top: 0, right: 0 }}
      {...props}
    />
  );
}

export default Blanket;
