import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { UserType } from '../../const/user-type';
import isAllowed from '../../utils/permissions/isAllowed';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { removeUserPermission } from '../../services/api/user.service';
import { components as userComponents } from '../../types/openapi/UserService';
import { ModalContext } from '../../modules/modal/ModalProvider';
import learnerKeys from '../../query-keys/learner-key-factory';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';

function LearnerListActions({ selectedLearner }: { selectedLearner: any }) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const userProviderId = profile?.providerId;

  const modalCtx = useContext(ModalContext);
  const { modal } = modalCtx;

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const intl = useIntl();
  const queryClient = useQueryClient();

  const { mutate: newRemoveLearnerPermission } = useMutation(
    (learnerPermission: any) => removeUserPermission(learnerPermission),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'learner.removed.success',
            defaultMessage: 'This learner was successfully removed'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: learnerKeys.all(displayLocale)
        });
      },
      onError: () => {
        toast.error(
          intl.formatMessage({
            id: 'learner.removed.error',
            defaultMessage: 'There was an error removing this learner'
          }),
          { delay: 200 }
        );
      }
    }
  );

  async function onModalConfirm(learner: userComponents['schemas']['UserDto']) {
    if (profile && profile.centreId) {
      learner.userProviders!.forEach((userProvider) => {
        if (
          userProvider.userTypeId === UserType.LEARNER &&
          userProvider.providerId === Number(userProviderId) &&
          userProvider.centreId === profile.centreId
        ) {
          const learnerPermission: userComponents['schemas']['UserProvidersDto'] =
            {
              userId: learner.userId!,
              userProviderId: userProvider.userProviderId
            };
          newRemoveLearnerPermission(learnerPermission);
        }
      });
    } else
      learner.userProviders!.forEach((userProvider) => {
        if (
          userProvider.userTypeId === UserType.LEARNER &&
          userProvider.providerId === Number(userProviderId)
        ) {
          const learnerPermission: userComponents['schemas']['UserProvidersDto'] =
            {
              userId: learner.userId!,
              userProviderId: userProvider.userProviderId
            };
          newRemoveLearnerPermission(learnerPermission);
        }
      });
  }

  function openModal(learner: userComponents['schemas']['UserDto']) {
    const header = intl.formatMessage(
      {
        id: 'learner.delete.confirm.header',
        defaultMessage: 'Delete Learner: {name}',
        description: 'Delete Learner Confirm Modal Header'
      },
      { name: learner.username }
    );

    const modalContent = intl.formatMessage({
      id: 'learner.delete.confirm.body',
      defaultMessage:
        'Are you sure you want to delete this learner? This action cannot be undone',
      description: 'Delete Learner Confirm Modal Body Message'
    });

    const confirmText = intl.formatMessage({
      id: 'learner.delete.confirm.button',
      defaultMessage: 'Delete Learner',
      description: 'Delete learner Confirm Button'
    });

    modal(modalContent, {
      confirm: () => onModalConfirm(learner),
      header,
      confirmText
    });
  }

  return (
    <div className="btn-group-vertical">
      <Link
        to={`${selectedLearner.userId}`}
        className="btn btn-outline-secondary"
      >
        <FormattedMessage id="view" defaultMessage="View" description="View" />
      </Link>
      {isAllowed([UserType.ADMIN], profile?.userTypeId as UserType) && (
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => openModal(selectedLearner)}
        >
          <FormattedMessage
            id="learner.delete"
            defaultMessage="Remove learner"
            description="Remove learner from provider"
          />
        </button>
      )}
    </div>
  );
}

export default LearnerListActions;
