/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { components } from '../../types/openapi/UserService';
import { getUser, updateUser } from '../../services/api/user.service';
import { AppRoute } from '../../const';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import localiseRoutePath from '../../utils/localiseRoutePath';
import Loading from '../common/Loading';
import { GeneralError } from '../common';
import instructorKeys from '../../query-keys/instructor-key-factory';
import UserForm from '../users/forms/UserForm';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import useProviderConfig from '../../hooks/useProviderConfig';
import userKeys from '../../query-keys/user-key-factory';

function EditInstructor() {
  const intl = useIntl();

  const { instructorId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const { instructorNonSSOLogin } = useProviderConfig({
    providerId: Number(profile?.providerId)
  });

  const navLinkInstructor = `/${displayLocale?.toLowerCase()}/${localiseRoutePath(
    AppRoute.Instructors
  )}/${instructorId}`;

  const [instructor] = useQueries({
    queries: [
      {
        queryKey: instructorKeys.instructor(
          Number(instructorId),
          displayLocale
        ),
        queryFn: () => getUser(Number(instructorId)),
        refetchOnMount: `always`
      }
    ]
  });

  const { mutate: editInstructor, isLoading } = useMutation(
    (instructorUpdated: components['schemas']['UserDto']) =>
      updateUser(instructorUpdated),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'instructor.edit.success',
            defaultMessage: 'You have successfully edited this instructor'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: instructorKeys.instructor(
            Number(instructorId),
            displayLocale
          )
        });
        queryClient.invalidateQueries({
          queryKey: userKeys.user(Number(instructorId), displayLocale)
        });
        navigate(navLinkInstructor);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'instructor.edit.error',
            defaultMessage:
              'There was an error editing this instructor with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (instructorUpdated: components['schemas']['UserDto']) => {
    const editedInstructor: components['schemas']['UserDto'] = {
      firstName: instructorUpdated.firstName,
      surname: instructorUpdated.surname,
      email: instructorUpdated.email,
      telephone: instructorUpdated.telephone,
      genderId: Number(instructorUpdated.genderId),
      dateOfBirth: instructorUpdated.dateOfBirth,
      userId: instructor.data?.userId,
      sportPassportId: instructor.data?.sportPassportId
    };
    editInstructor(editedInstructor);
  };

  return (
    <div className="card rounded-0 border-0">
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.instructor.edit_instructor',
              defaultMessage: 'Edit instructor: {username}'
            },
            { username: instructor.data?.username || '' }
          )}
        </title>
      </Helmet>
      <div className="card-header">
        <h1>
          <FormattedMessage
            id="instructor.edit_instructor"
            defaultMessage="Edit Instructor: {username}"
            description="Edit instructor"
            values={{ username: instructor.data?.username || '' }}
          />
        </h1>
      </div>
      <div className="card-body">
        {instructor.isFetching ? (
          <Loading />
        ) : instructor.error ? (
          (instructor.error as any)?.response.status === 401 ? (
            <FormattedMessage
              id="users.error.unauthorised"
              defaultMessage="You are not authorised to access this user"
              description="You are not authorised to access this user"
            />
          ) : (
            <GeneralError />
          )
        ) : (
          instructor.data && (
            <UserForm
              onSubmit={onSubmit}
              submitButtonMessage={
                <FormattedMessage
                  id="save_changes"
                  defaultMessage="Save changes"
                  description="Save changes"
                />
              }
              formDisabled={isLoading}
              email={instructor?.data?.email || ''}
              telephone={instructor?.data?.telephone || ''}
              firstName={instructor?.data?.firstName || ''}
              surname={instructor.data.surname || ''}
              emergencyContactName={instructor.data.emergencyContactName || ''}
              emergencyPhone1={instructor.data.emergencyPhone1 || ''}
              emergencyPhone2={instructor.data.emergencyPhone2 || ''}
              medicalConditions={instructor.data.medicalConditions || ''}
              genderId={instructor.data.genderId || undefined}
              dateOfBirth={dayjs(instructor.data.dateOfBirth).format(
                'YYYY-MM-DD'
              )}
              isEmailMandatory={instructorNonSSOLogin}
            />
          )
        )}
      </div>
    </div>
  );
}
export default EditInstructor;
