import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useUserObjectives from '../../../hooks/useUserObjectives';
import { components } from '../../../types/openapi/CourseService';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { GeneralError } from '../../common';
import Loading from '../../common/Loading';
import TrophySnake from './TrophySnake';
// import useStageObjectives from '../../hooks/useStageObjectives';

function TrophySnakeWrapper({
  frameworkStages,
  stage,
  userId,
  frameworkVersionId
}: {
  userId: number;
  frameworkVersionId: number;
  frameworkStages: components['schemas']['StageDto'][];
  stage: components['schemas']['StageDto'];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const [currentStageId, setCurrentStageId] = useState<number>();

  useEffect(() => {
    // we need the current stage (not the last completed stage)
    if (frameworkStages && stage) {
      const { stageOrder } = stage;
      const stageToMarkAsComplete = frameworkStages.find(
        (frameworkStage) => frameworkStage.stageOrder === stageOrder
      );
      if (stageToMarkAsComplete !== undefined) {
        setCurrentStageId(stageToMarkAsComplete.stageId);
      }
    }
  }, [stage, frameworkStages]);

  const { userObjectivesQuery } = useUserObjectives({
    frameworkVersionId,
    stageId: currentStageId,
    userId,
    displayLocale
  });

  return (
    <div>
      {' '}
      {userObjectivesQuery.isFetching ? (
        <Loading />
      ) : userObjectivesQuery.error &&
        (userObjectivesQuery.error as any).response.status !== 404 ? (
        <GeneralError />
      ) : userObjectivesQuery.data ? (
        <TrophySnake
          frameworkVersionId={frameworkVersionId}
          userId={userId}
          objectives={userObjectivesQuery.data}
        />
      ) : (
        <p>
          {' '}
          <FormattedMessage
            id="objectives.none"
            defaultMessage="No objectives could be found"
            description="No objectives could be found"
          />
        </p>
      )}
    </div>
  );
}

export default TrophySnakeWrapper;
