import { Mutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { enhancedLogEmail } from '../../../services/api/user.service';

function EmailSupportDeskBtn({ mutation }: { mutation: Mutation }) {
  const intl = useIntl();

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const errorObj = {
    instructorId: profile?.userId,
    timestamp: dayjs().format(),
    message: (mutation.state.error as any).message,
    url: (mutation.state.error as any).config.url,
    method: (mutation.state.error as any).config.method,
    variables: (mutation.state as any).variables,
    authorization: (mutation.state.error as any).config.headers.Authorization
  };

  const emailSupportDesk = () => {
    enhancedLogEmail(JSON.stringify(errorObj))
      .then(() =>
        toast.success(
          intl.formatMessage({
            id: 'email.support.success',
            defaultMessage: 'Successfully submitted to support desk'
          }),
          { delay: 200 }
        )
      )
      //   eslint-disable-next-line
          .catch(() =>
        toast.error(
          intl.formatMessage({
            id: 'email.support.error',
            defaultMessage: 'There was an error sending to support desk'
          }),
          { delay: 200 }
        )
      );
  };

  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={emailSupportDesk}
    >
      {' '}
      <FormattedMessage
        id="error.email"
        defaultMessage="Send to support desk"
        description="Send to support desk"
      />
    </button>
  );
}
export default EmailSupportDeskBtn;
