/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */

import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { components } from '../../types/openapi/CourseService';
import NoteForm from './NoteForm';
import noteKeys from '../../query-keys/note-key-factory';
import { updateNote } from '../../services/api/course.service';

function EditNote({
  courseId,
  sessionId,
  userId,
  noteId,
  oldNoteText,
  setShowEditId
}: {
  courseId: number;
  sessionId: number;
  userId: number;
  noteId: number;
  oldNoteText: string;
  setShowEditId: Dispatch<SetStateAction<number>>;
}) {
  const queryClient = useQueryClient();
  const intl = useIntl();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: editNote, isLoading } = useMutation(
    (noteUpdated: components['schemas']['NoteDto']) => {
      const { noteId, noteText } = noteUpdated;
      return updateNote(noteId, noteText);
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data) => {
        toast.success(
          intl.formatMessage({
            id: 'note.edit.success',
            defaultMessage: 'You have successfully edited this note'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: noteKeys.list(courseId, sessionId, userId)
        });
        setShowEditId(0);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'note.edit.error',
            defaultMessage:
              'There was an error editing this note with these details'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (
    noteUpdated: Pick<components['schemas']['NoteDto'], 'noteText'>
  ) => {
    const { noteText } = noteUpdated;
    const newUpdatedNote: components['schemas']['NoteDto'] = {
      noteId,
      noteText
    };
    editNote(newUpdatedNote);
  };

  return (
    <NoteForm
      onSubmit={onSubmit}
      setShowEditId={setShowEditId}
      submitButtonMessage={
        <FormattedMessage
          id="save_changes"
          defaultMessage="Save changes"
          description="Save changes"
        />
      }
      noteText={oldNoteText}
    />
  );
}
export default EditNote;
