import React from 'react';
import { faSlash, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

function OfflineBanner() {
  return (
    <>
      <span className="fa-stack">
        <FontAwesomeIcon icon={faSlash} className="me-1 fa-stack-1x" />
        <FontAwesomeIcon icon={faWifi} className="me-1 fa-stack-1x" />
      </span>
      <FormattedMessage
        id="mode.offline"
        defaultMessage="You are currently in offline teaching mode"
        description="You are currently in offline teaching mode"
      />
    </>
  );
}

export default OfflineBanner;
