import { Mutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

function CopyErrorBtn({ mutation }: { mutation: Mutation }) {
  const intl = useIntl();

  const errorObj = {
    timestamp: dayjs().format(),
    message: (mutation.state.error as any).message,
    url: (mutation.state.error as any).config.url,
    method: (mutation.state.error as any).config.method,
    variables: (mutation.state as any).variables,
    authorization: (mutation.state.error as any).config.headers.Authorization
  };

  async function copyError(text: string) {
    navigator.clipboard.writeText(text).then(() =>
      toast.success(
        intl.formatMessage({
          id: 'copy.success',
          defaultMessage: 'Error copied'
        }),
        { delay: 200 }
      )
    );
  }

  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => copyError(JSON.stringify(errorObj))}
    >
      {' '}
      <FormattedMessage
        id="error.copy"
        defaultMessage="Copy error"
        description="Copy error"
      />
    </button>
  );
}

export default CopyErrorBtn;
