import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProfileInfo from './ProfileInfo';

function NavUserInfoMobile() {
  return (
    <div className="nav-user-info rounded d-flex py-1 px-2">
      <div className="flex-grow-1 d-flex gap-2 border-bottom pb-2 ">
        <div className="d-flex align-items-center p-2">
          {' '}
          <FontAwesomeIcon
            className="text-primary"
            icon={icon({ name: 'user', style: 'solid' })}
          />
        </div>
        <div>
          <ProfileInfo />
        </div>
      </div>
    </div>
  );
}

export default NavUserInfoMobile;
