import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import TrophySnakeWrapper from './TrophySnakeWrapper';
import useFrameworkProgress from '../../../hooks/useFrameworkProgress';
import useStages from '../../../hooks/useStages';
import { components } from '../../../types/openapi/CourseService';
import OnlineFrameworkProgress from '../../offline/onlineModePages/OnlineFrameworkProgress';

function LatestTrophies({
  userId,
  frameworkVersion
}: {
  userId: number;
  frameworkVersion: components['schemas']['FrameworkVersionDto'];
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { currentFrameworkStageQuery } = useFrameworkProgress({
    frameworkVersionId: frameworkVersion.frameworkVersionId,
    learnerId: userId,
    displayLocale
  });

  const { stagesQuery } = useStages({
    frameworkId: frameworkVersion.frameworkId!,
    frameworkVersionId: frameworkVersion.frameworkVersionId!,
    displayLocale
  });

  return (
    <div>
      <h3 className="mb-4">
        {frameworkVersion.framework?.name} ({frameworkVersion.name})
      </h3>
      {frameworkVersion.frameworkId && frameworkVersion.frameworkVersionId && (
        <OnlineFrameworkProgress
          userId={userId}
          frameworkId={frameworkVersion.frameworkId}
          frameworkVersionId={frameworkVersion.frameworkVersionId}
        />
      )}
      <hr />
      <h4 className="mb-4">
        <FormattedMessage
          id="learner.header.trophies"
          defaultMessage="My Trophies"
          description="My Trophies"
        />
      </h4>

      {currentFrameworkStageQuery.data &&
        stagesQuery.data &&
        frameworkVersion.frameworkVersionId && (
          <TrophySnakeWrapper
            userId={userId}
            frameworkVersionId={frameworkVersion.frameworkVersionId}
            frameworkStages={stagesQuery.data}
            stage={currentFrameworkStageQuery.data}
          />
        )}
      <hr />
    </div>
  );
}

export default LatestTrophies;
