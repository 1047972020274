import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';

import CoursesTableForAdmin from '../../courses/CoursesTableForAdmin';
import ViewCentreViewLink from '../ViewCentreCentreLink';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import useCoursesForCentre from '../../../hooks/courses/useCoursesForCentre';
import { FilteringQueryParams } from '../../../const/filtering-query-params';

function CentreCoursesCard({
  userProviderId,
  centreId
}: {
  userProviderId: number;
  centreId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const [coursesPagination, setCoursesPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const [coursesSorting, setCoursesSorting] = useState<SortingState>([]);
  const [coursesFiltering, setCoursesFiltering] = useState<ColumnFiltersState>(
    []
  );

  const { coursesForProviderCentreQuery } = useCoursesForCentre({
    providerId: userProviderId,
    centreId: Number(centreId),
    pageIndex: coursesPagination.pageIndex,
    pageSize: coursesPagination.pageSize,
    sorting: coursesSorting,
    rawFiltering: coursesFiltering,
    displayLocale
  });

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          <FormattedMessage
            id="courses.header"
            defaultMessage="Courses"
            description="Courses"
          />
        </h2>
      </div>
      <div className="card-body">
        <CoursesTableForAdmin
          data={coursesForProviderCentreQuery.data}
          error={coursesForProviderCentreQuery.error}
          seeCentres={false}
          isLoading={coursesForProviderCentreQuery.isLoading}
          refetching={coursesForProviderCentreQuery.isRefetching}
          pagination={coursesPagination}
          sorting={coursesSorting}
          filtering={coursesFiltering}
          setPagination={setCoursesPagination}
          setSorting={setCoursesSorting}
          setFiltering={setCoursesFiltering}
          queryParamsLocation={FilteringQueryParams.CentreCourses}
          isLearnerPage={false}
          renderActions={({ courseId }) => {
            return <ViewCentreViewLink courseId={courseId} />;
          }}
        />
      </div>
    </div>
  );
}

export default CentreCoursesCard;
