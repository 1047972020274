import React from 'react';
import { RouteObject } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { AppLanguage, AppRoute } from '../const';
import MainContainer from '../components/layout/MainContainer';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import EditProfile from '../pages/EditProfile';
import Profile from '../pages/Profile';
import SystemDiagnostics from '../pages/SystemDiagnostics';
import Unauthorised from '../pages/Unauthorised';
import Payment from '../pages/Payment';
import RoleChange from '../pages/RoleChange';
import RoleSelect from '../pages/RoleSelect';
import ChangePassword from '../pages/ChangePassword';

export default function accountRoutes(lang: string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject[] = [
    {
      path: appStrings[langVal][AppRoute.SystemDiagnostics],
      element: <SystemDiagnostics />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="system_diagnostics.header"
            defaultMessage="System Diagnostics"
            description="System Diagnostics"
          />
        )
      }
    },

    {
      path: appStrings[langVal][AppRoute.Payment],
      element: <Payment />,
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[langVal][AppRoute.Profile],
      element: <MainContainer />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="profile"
            defaultMessage="Profile"
            description="Profile"
          />
        )
      },

      children: [
        {
          path: appStrings[langVal][AppRoute.EditProfile],
          element: <EditProfile />,
          errorElement: <ErrorPage />,
          handle: {
            crumb: () => (
              <FormattedMessage
                id="profile.edit"
                defaultMessage="Edit Profile"
                description="Edit Profile"
              />
            )
          }
        },
        {
          index: true,
          element: <Profile />,
          errorElement: <ErrorPage />
        }
      ]
    },

    {
      path: `${appStrings[langVal][AppRoute.RoleChange]}`,
      element: <RoleChange />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="profile.select"
            defaultMessage="Select Profile"
            description="Select Profile"
          />
        )
      }
    },
    {
      path: `${appStrings[langVal][AppRoute.RoleSelect]}`,
      element: <RoleSelect />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="profile.select"
            defaultMessage="Select Profile"
            description="Select Profile"
          />
        )
      }
    },
    {
      path: appStrings[langVal][AppRoute.ChangePassword],
      element: <MainContainer />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="passwork.change"
            defaultMessage="Change Password"
            description="Change Password"
          />
        )
      },

      children: [
        {
          index: true,
          element: <ChangePassword />,
          errorElement: <ErrorPage />
        }
      ]
    },
    {
      path: appStrings[langVal][AppRoute.Unauthorised],
      element: <Unauthorised />,
      errorElement: <ErrorPage />
    }
  ];
  return routes;
}
