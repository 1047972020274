import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { AppRoute } from '../../../const';
import AppMode from '../../../modules/offline/app-mode.enum';
import localiseRoutePath from '../../../utils/localiseRoutePath';
import { components } from '../../../types/openapi/CourseService';
import useNetworkStatus from '../../../hooks/useNetworkStatus';
import { OfflineContext } from '../../../modules/offline/OfflineProvider';

function ViewSessionLink({
  session
}: {
  session: components['schemas']['CourseSessionDto'];
}) {
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const networkOnline = useNetworkStatus();
  return (
    <Link
      to={`${localiseRoutePath(AppRoute.ViewSession)}/${
        session.courseSessionId
      }`}
      className={`btn btn-outline-secondary ${
        (appMode === AppMode.OFFLINE || !networkOnline) &&
        !dayjs(session.sessionStartTime).isSame(dayjs(), 'day') &&
        'disabled'
      }`}
    >
      <FormattedMessage
        id="course.button.view-session"
        defaultMessage="View session"
        description="View session of a course"
      />
    </Link>
  );
}

export default ViewSessionLink;
