/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import useProviderConfig from '../../../hooks/useProviderConfig';
import useUser from '../../../hooks/useUser';
import { GeneralError } from '../../common';
import ViewLearnerDashboard from './ViewLearnerDashboard';
import { AppLanguage, AppRoute } from '../../../const';
import { appStrings } from '../../../modules/i18n';

function ViewLearner() {
  const { learnerId } = useParams();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const providerId = profile?.providerId;

  if (!providerId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'error.no_provider_id',
          defaultMessage: 'No valid provider id provided'
        })}
      />
    );
  }

  const { profileEditingEnabled } = useProviderConfig({
    providerId
  });

  const { userQuery } = useUser({ userId: Number(learnerId), displayLocale });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              id: 'title.learner.name',
              defaultMessage: 'Learner: {name}'
            },
            { name: userQuery.data?.username || '' }
          )}
        </title>
      </Helmet>
      <div className="container-fluid g-0">
        <div className="card">
          <div className="card-header">
            <h1 data-gdpr="true">
              <FormattedMessage
                id="learner.name"
                defaultMessage="Learner: {name}"
                description="Learner {name}"
                values={{ name: userQuery.data?.username || '' }}
              />
            </h1>
          </div>
        </div>
        <ViewLearnerDashboard
          learnerId={Number(learnerId)}
          providerId={providerId}
          isLoading={userQuery.isLoading}
          error={userQuery.error}
          data={userQuery.data}
          profileEditingEnabled={profileEditingEnabled}
          editCentresLink={`${
            appStrings[displayLocale as AppLanguage][AppRoute.EditCentres]
          }`}
        />
      </div>
    </>
  );
}
export default ViewLearner;
