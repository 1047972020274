import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { UserType } from '../../../const/user-type';
import UserTypeTranslationIds from '../../../modules/i18n/user-types/user-type-translation-ids';
import { GeneralError } from '../../common';
import Loading from '../../common/Loading';
import useUser from '../../../hooks/useUser';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';

function ProfileInfo() {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const role = profile?.userTypeId as UserType;
  const intl = useIntl();

  if (!profile)
    return (
      <p className="text-muted mb-0">
        {' '}
        <FormattedMessage
          id="role.none"
          defaultMessage="No Role Selected"
          description="No Role Selected"
        />
      </p>
    );

  if (!profile?.userId) {
    console.error('no user id');
    return <Loading />;
  }

  const { userQuery } = useUser({
    userId: profile?.userId,
    displayLocale
  });

  if (userQuery.isLoading) return <Loading />;

  if (userQuery.error) return <GeneralError error={userQuery.error} />;

  return (
    <>
      <p className="text-black mb-0" data-gdpr="true">
        {userQuery.data?.username}
      </p>
      {profile
        ? profile?.userTypeId && (
            <p className="text-muted mb-0">
              {intl.formatMessage({
                id: UserTypeTranslationIds[profile.userTypeId as UserType]
              })}
              /
              {role === UserType.ADMIN &&
                profile.centreId &&
                (profile as any).centre.name}
              {role === UserType.ADMIN &&
                profile.frameworkId &&
                (profile as any).framework.name}
              {role === UserType.ADMIN &&
                !profile.centreId &&
                !profile.frameworkId &&
                (profile as any).provider.name}
            </p>
          )
        : (userQuery.data?.userProviders?.length &&
            userQuery.data?.userProviders?.length > 1) ?? (
            <p className="text-muted mb-0">
              <FormattedMessage
                id="profile.set.none"
                defaultMessage="Set Profile"
                description="Set profile"
              />
            </p>
          )}
    </>
  );
}

export default ProfileInfo;
