/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { IconLookup } from '@fortawesome/fontawesome-common-types';
import {
  faPersonChalkboard,
  faUsersLine,
  faPeopleGroup,
  faBuilding,
  faPuzzlePiece,
  faUniversity,
  faSchool
} from '@fortawesome/free-solid-svg-icons';
import { UseQueryResult } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppLanguage, AppRoute } from '../../const';
import { UserType } from '../../const/user-type';
import useProvider from '../../hooks/useProvider';
import { appStrings } from '../../modules/i18n';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import isAllowed from '../../utils/permissions/isAllowed';
import Loading from '../common/Loading';
import useProviders from '../../hooks/providers/useProviders';
import useLearners from '../../hooks/useLearners';
import useInstructors from '../../hooks/useInstructors';
import useActiveCoursesCount from '../../hooks/courses/useActiveCoursesCount';
import { components } from '../../types/openapi/FrameworkService';
import { isCentreAdmin } from '../../utils/permissions';
import useSchoolsCount from '../../hooks/schools/useSchoolsCount';
import BrandColours from '../../const/brand-colours.enum';

interface SummaryInfoItem {
  faIcon: IconLookup | any;
  text: any;
  url: string;
  allowedRoles?: UserType[];
  allowedProfile: boolean;
  isFetching: boolean;
  error: any;
  linkText: string | JSX.Element;
  colour: BrandColours;
}

function SummaryInfo({
  frameworksListQuery,
  schoolsAvailable
}: {
  frameworksListQuery: UseQueryResult<components['schemas']['FrameworkDto'][]>;
  schoolsAvailable?: boolean;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const langVal = (displayLocale as AppLanguage) || AppLanguage.English;

  const { instructorsListQuery } = useInstructors({
    providerId: Number(profile?.providerId),
    centreId: profile?.centreId,
    displayLocale
  });

  const { centresForProviderListForSummaryQuery } = useProvider({
    providerId: profile?.providerId,
    displayLocale
  });

  const { learnersListDashboardQuery } = useLearners({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    displayLocale
  });

  const { providersListQuery } = useProviders({ displayLocale });
  const { activeCoursesCountQuery } = useActiveCoursesCount({
    providerId: profile?.providerId,
    userType: profile?.userTypeId as UserType,
    displayLocale
  });

  const schoolsCountQuery = useSchoolsCount({
    providerId: profile?.providerId,
    centreId: profile?.centreId,
    enabled: !!schoolsAvailable,
    isCentreAdmin: !!profile && isCentreAdmin(profile),
    displayLocale
  });

  const summaryInfoItems: SummaryInfoItem[] = [
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Frameworks],
      faIcon: faPuzzlePiece,
      text: (
        <FormattedMessage
          id="summary.frameworks"
          defaultMessage="{number} Frameworks"
          description="Number of frameworks"
          values={{
            number: frameworksListQuery.data?.length || 0
          }}
        />
      ),
      isFetching: frameworksListQuery.isFetching,
      error: frameworksListQuery.error,
      allowedRoles: [UserType.SPORT_MANAGER, UserType.ADMIN],
      allowedProfile: !!profile && !isCentreAdmin(profile),
      linkText: (
        <FormattedMessage
          id="view.all.frameworks"
          defaultMessage="View all frameworks"
          description="View all frameworks"
        />
      ),
      colour: BrandColours.NAVY
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Providers],
      faIcon: faUniversity,
      text: (
        <FormattedMessage
          id="summary.providers"
          defaultMessage="{number} Providers"
          description="Number of providers"
          values={{
            number: providersListQuery.data?.numberOfProviders || 0
          }}
        />
      ),
      isFetching: providersListQuery.isFetching,
      error: providersListQuery.error,
      allowedRoles: [UserType.SPORT_MANAGER],
      allowedProfile: true,
      linkText: (
        <FormattedMessage
          id="view.all.providers"
          defaultMessage="View all providers"
          description="View all providers"
        />
      ),
      colour: BrandColours.PINK
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Centres],
      faIcon: faBuilding,
      text: (
        <FormattedMessage
          id="summary.centres"
          defaultMessage="{number} Centres"
          description="Number of centres"
          values={{
            number: centresForProviderListForSummaryQuery.data || 0
          }}
        />
      ),
      isFetching: centresForProviderListForSummaryQuery.isFetching,
      error: centresForProviderListForSummaryQuery.error,
      allowedRoles: [UserType.ADMIN],
      allowedProfile: !!profile && !isCentreAdmin(profile),
      linkText: (
        <FormattedMessage
          id="view.all.centres"
          defaultMessage="View all centres"
          description="View all centres"
        />
      ),
      colour: BrandColours.AMBER
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Schools],
      faIcon: faSchool,
      text: (
        <FormattedMessage
          id="summary.schools"
          defaultMessage="{number} Schools"
          description="Number of schools"
          values={{
            number: schoolsCountQuery.data || 0
          }}
        />
      ),
      isFetching: schoolsCountQuery.isFetching,
      error: schoolsCountQuery.error,
      allowedRoles: [UserType.ADMIN],
      allowedProfile: !!schoolsAvailable,
      linkText: (
        <FormattedMessage
          id="view.all.schools"
          defaultMessage="View all schools"
          description="View all schools"
        />
      ),
      colour: BrandColours.GREEN
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Instructors],
      faIcon: faUsersLine,
      text: (
        <FormattedMessage
          id="summary.instructors"
          defaultMessage="{number} Instructors"
          description="Number of Instructors"
          values={{
            number: instructorsListQuery.data?.numberOfUsers || 0
          }}
        />
      ),
      isFetching: instructorsListQuery.isFetching,
      error: instructorsListQuery.error,
      allowedRoles: [UserType.ADMIN],
      allowedProfile: true,
      linkText: (
        <FormattedMessage
          id="view.all.instructors"
          defaultMessage="View all instructors"
          description="View all instructors"
        />
      ),
      colour: BrandColours.PINK
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Learners],
      faIcon: faPeopleGroup,
      text: (
        <FormattedMessage
          id="summary.learners"
          defaultMessage="{number} Learners"
          description="Number of learners"
          values={{
            number: learnersListDashboardQuery.data || 0
          }}
        />
      ),
      isFetching: learnersListDashboardQuery.isFetching,
      error: learnersListDashboardQuery.error,
      allowedRoles: [UserType.ADMIN],
      allowedProfile: true,
      linkText: (
        <FormattedMessage
          id="view.all.learners"
          defaultMessage="View all learners"
          description="View all learners"
        />
      ),
      colour: BrandColours.NAVY
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Courses],
      faIcon: faPersonChalkboard,
      text: (
        <FormattedMessage
          id="summary.courses"
          defaultMessage="{number} Courses"
          description="Number of courses"
          values={{
            number: activeCoursesCountQuery.data || 0
          }}
        />
      ),
      isFetching: activeCoursesCountQuery.isFetching,
      error: activeCoursesCountQuery.error,
      allowedRoles: [UserType.ADMIN],
      allowedProfile: true,
      linkText: (
        <FormattedMessage
          id="view.all.courses"
          defaultMessage="View all courses"
          description="View all courses"
        />
      ),
      colour: BrandColours.GREEN
    }
  ];
  return (
    <div className="d-flex justify-content-center summary-info">
      {summaryInfoItems
        .filter((item) => {
          return (
            item.allowedRoles &&
            isAllowed(item.allowedRoles, profile?.userTypeId as UserType) &&
            item.allowedProfile
          );
        })
        .map((item, index) => {
          const { url, faIcon, text, isFetching, error, linkText, colour } =
            item;
          return (
            <div className="summary-info__box " key={index}>
              {isFetching ? (
                <Loading />
              ) : error && (error as any).response?.status !== 404 ? (
                <GeneralError />
              ) : (
                <>
                  <div className={`icon-circle bg-brand-${colour}`}>
                    <FontAwesomeIcon icon={faIcon} />
                  </div>
                  <span className="my-3 fw-bold">{text}</span>
                  <Link to={url} className="btn btn-chevron">
                    {linkText}
                  </Link>
                </>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default SummaryInfo;

SummaryInfo.defaultProps = {
  schoolsAvailable: false
};
