/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode, useContext } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import {
  PaginationState,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';

import { components } from '../../types/openapi/UserService';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import PaginatedTable from '../tables/PaginatedTable';
import useTableColumns from '../../hooks/table/useTableColumns';

function UsersTablePaged({
  data,
  error,
  isLoading,
  refetching,
  setPagination,
  setSorting,
  setFiltering,
  pagination,
  sorting,
  filtering,
  queryParamsLocation,
  renderActions
}: {
  data: components['schemas']['PagedUserDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  setPagination: any;
  setSorting: any;
  setFiltering: any;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  queryParamsLocation: string;
  renderActions: (
    user: components['schemas']['UserDto']
  ) => JSX.Element | ReactNode;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const intl = useIntl();

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    setFiltering([]);
  };

  const {
    usernameColumn,
    sportPassportColumn,
    userExternalIdColumn,
    userTypeColumn,
    centreColumn
  } = useTableColumns({ providerId: profile?.providerId });

  const columns = [
    usernameColumn,
    sportPassportColumn,
    userExternalIdColumn,
    userTypeColumn,
    centreColumn,
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        return <div>{renderActions && renderActions(row.original)}</div>;
      },
      filterType: undefined
    }
  ];

  const table = useReactTable({
    data: data?.users ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfUsers
        ? Math.ceil(data.numberOfUsers / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfUsers}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="userId"
      noneFoundMessage={
        <FormattedMessage
          id="users.none_found"
          defaultMessage="No users"
          description="No users found"
        />
      }
      showFooter={(data?.numberOfUsers ?? 0) > 10}
    />
  );
}
export default UsersTablePaged;
