/* eslint-disable consistent-return */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import Select, { PropsValue, SelectInstance } from 'react-select';
import { ColumnFilter } from '@tanstack/react-table';

import { Dropdown, DropdownIndicator } from '../../../../ui/dropdown-select';
import selectStyles from '../../../../ui/dropdown-select/SelectStyles';
import useKeyPress from '../../../../../hooks/ui/useKeyPress';

function SelectFilter({
  columnDef,
  initialFilterValue,
  handleDropdownInputChange,
  options,
  setFiltering,
  filteringAccessorKey,
  columnName,
  menuPositionRight
}: {
  columnDef: any;
  initialFilterValue: number;
  handleDropdownInputChange: (selectedOption: any) => void;
  options: PropsValue<any>;
  setFiltering?: Dispatch<SetStateAction<ColumnFilter[]>>;
  filteringAccessorKey?: string;
  columnName: string;
  menuPositionRight?: number;
}) {
  const [inputValue, setInputValue] = useState<string>('');

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<SelectInstance<any> | null>(null);

  const blur = () => {
    selectRef.current?.blur();
    setIsOpen(false);
  };

  useKeyPress('Escape', blur);

  const handleChange = (selectedOption: any) => {
    selectRef.current?.blur();
    setIsOpen(false);
    handleDropdownInputChange(selectedOption);
  };

  React.useEffect(() => {
    if (setFiltering && filteringAccessorKey) {
      const timeout = setTimeout(() => {
        setFiltering([{ id: filteringAccessorKey, value: inputValue }]);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [inputValue]);

  const [selectedOption, setSelectedOption] =
    useState<PropsValue<any> | null>();

  useEffect(() => {
    // filters have been updated externally
    if (options) {
      setSelectedOption(
        options.find(
          (opt: PropsValue<any>) => opt.value === Number(initialFilterValue)
        )
      );
    }
  }, [options]);

  const handleInputChange = (input: any) => {
    setInputValue(input);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      classNames={{
        blanket: 'position-fixed',
        reactSelectDropwdown: 'flex-grow-1',
        menu: 'bg-white rounded shadow'
      }}
      styles={{
        menu: {
          zIndex: 2,
          right: menuPositionRight,
          position: 'absolute',
          width: '400px'
        }
      }}
      onClose={() => setIsOpen(false)}
      target={
        <button
          className="btn form-control rounded w-100 border text-muted"
          type="button"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className="d-flex justify-content-between">
            <div className="overflow-hidden">
              {' '}
              <span className="d-sm-block text-nowrap">
                {selectedOption
                  ? `${selectedOption.label}`
                  : `Select a ${columnName}`}
              </span>
            </div>
            <div className="border-start ps-2">
              {' '}
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </button>
      }
    >
      <Select
        ref={selectRef}
        aria-label={`Select ${columnName}`}
        autoFocus
        backspaceRemovesValue={false}
        className="select-input"
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        key={columnDef.id}
        menuIsOpen
        menuPlacement="auto"
        options={options}
        value={selectedOption}
        tabSelectsValue={false}
        styles={selectStyles}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </Dropdown>
  );
}

export default SelectFilter;

SelectFilter.defaultProps = {
  setFiltering: () => {},
  filteringAccessorKey: '',
  menuPositionRight: undefined
};
